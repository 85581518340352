import { useEffect } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Checkbox } from '@/components'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormReturn,
  useFieldArray,
} from 'react-hook-form'
import { Add } from '@mui/icons-material'
import JAutocomplete from '@/components/atoms/JAutocomplete'
import {
  Catalog_Malpractice_Insurance,
  Catalog_Us_State,
  useCatalogUsStateQuery,
  useMalpracticeInsuranceQuery,
} from '@/gql/appApi'
import JTextField from '@/components/atoms/JTextField'
import { INSURANCE_OPTIONS_WITH_AMOUNT } from '@/constants'
import { ProfessionalLicenseFormValues, getDefaultValues } from './config'
import JMalpracticeInsurance from '../atoms/JMalpracticeInsurance'

type UseProfessionalLicensesFormReturn = UseFormReturn<{
  professionalLicenses: ProfessionalLicenseFormValues[]
}>

type ProfessionalLicenseProps = {
  control: Control<{
    professionalLicenses: ProfessionalLicenseFormValues[]
  }>
  register: UseFormRegister<{
    professionalLicenses: ProfessionalLicenseFormValues[]
  }>
  errors: FieldErrors<{
    professionalLicenses: ProfessionalLicenseFormValues[]
  }>
  idx: number
  numProfessionalLicenses: number
  states: Catalog_Us_State[]
  statesLoading: boolean
  malpracticeInsurance: Catalog_Malpractice_Insurance[]
  malpracticeInsuranceLoading: boolean
  onClickRemove: () => void
  watch: UseProfessionalLicensesFormReturn['watch']
  setValue: UseProfessionalLicensesFormReturn['setValue']
}

const ProfessionalLicense = ({
  control,
  register,
  idx,
  numProfessionalLicenses,
  states,
  statesLoading,
  onClickRemove,
  malpracticeInsurance,
  malpracticeInsuranceLoading,
  watch,
  setValue,
}: ProfessionalLicenseProps) => {
  const { name: barNumber } = register(`professionalLicenses.${idx}.barNumber`)
  const { name: state } = register(`professionalLicenses.${idx}.state`)
  const { name: isActive } = register(`professionalLicenses.${idx}.isActive`)
  const { name: isDisciplined } = register(
    `professionalLicenses.${idx}.isDisciplined`
  )
  const { name: isSued } = register(`professionalLicenses.${idx}.isSued`)
  const { name: malpracticeInsuranceId } = register(
    `professionalLicenses.${idx}.malpracticeInsuranceId`
  )
  const { name: insuranceExpirationDate } = register(
    `professionalLicenses.${idx}.insuranceExpirationDate`
  )

  const malpracticeInsuranceIdValue = watch(
    `professionalLicenses.${idx}.malpracticeInsuranceId`
  )

  const insuranceOptionValues: string[] = Object.values(
    INSURANCE_OPTIONS_WITH_AMOUNT
  )
  const showExpirationDatePicker = insuranceOptionValues.includes(
    malpracticeInsuranceIdValue || ''
  )

  useEffect(() => {
    if (!showExpirationDatePicker) {
      setValue(`professionalLicenses.${idx}.insuranceExpirationDate`, null)
      control.unregister(`professionalLicenses.${idx}.insuranceExpirationDate`)
    }
  }, [control, idx, setValue, showExpirationDatePicker])

  return (
    <>
      <Grid
        container
        rowSpacing={1.5}
        columnSpacing={3}
        sx={{
          mb: 1,
        }}
      >
        {numProfessionalLicenses > 1 && (
          <Grid
            item
            xs={12}
            mb={1.5}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1">License #{idx + 1}</Typography>
            <Button onClick={onClickRemove} color="error">
              Remove License
            </Button>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <JTextField
            name={barNumber}
            control={control}
            label="Bar Number"
            placeholder="Bar Number"
            variant="outlined"
            fullWidth
            InputProps={{ inputProps: { maxLength: 10 } }}
            fitTextField
            rules={{
              required: 'Bar Number is required.',
              maxLength: {
                value: 10,
                message: 'Bar Number should not exceed 10 characters',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <JAutocomplete
            control={control}
            name={state}
            options={states}
            label="State"
            getOptionLabel={option => `${option.code} - ${option.name}`}
            disabled={statesLoading}
            fullWidth
            fitTextField
            rules={{
              required: 'Please select a State.',
              validate: {
                required: (value, formValues) => {
                  for (
                    let validationIdx = 0;
                    validationIdx < formValues.professionalLicenses.length;
                    // eslint-disable-next-line no-plusplus
                    validationIdx++
                  ) {
                    const isCurrentLicense = validationIdx === idx

                    const isRedundantStateLicense =
                      !isCurrentLicense &&
                      value ===
                        formValues.professionalLicenses[validationIdx].state

                    if (isRedundantStateLicense) {
                      return 'Cannot use more than one license per state'
                    }
                  }

                  return true
                },
              },
            }}
          />
        </Grid>
        <JMalpracticeInsurance
          control={control}
          options={malpracticeInsurance}
          malpracticeName={malpracticeInsuranceId}
          expirationDateName={insuranceExpirationDate}
          showExpirationDatePicker={showExpirationDatePicker}
          loading={malpracticeInsuranceLoading}
        />
      </Grid>
      <Controller
        control={control}
        name={isActive}
        render={({ field: { onChange, value, ...field }, fieldState }) => (
          <Checkbox
            {...field}
            checked={value}
            checkboxProps={{
              isCentered: true,
              error: fieldState.invalid,
              helperText: fieldState?.error?.message,
              label: 'This license is currently active *',
            }}
            onChange={onChange}
          />
        )}
        rules={{
          required: 'License must be active',
        }}
      />
      <Controller
        control={control}
        name={isDisciplined}
        render={({ field: { onChange, value, ...field }, fieldState }) => (
          <Checkbox
            {...field}
            checked={value}
            checkboxProps={{
              isCentered: true,
              error: fieldState.invalid,
              helperText: fieldState?.error?.message,
              label: 'I have been disciplined by the bar',
            }}
            onChange={onChange}
          />
        )}
      />
      <Controller
        control={control}
        name={isSued}
        render={({ field: { onChange, value, ...field }, fieldState }) => (
          <Checkbox
            {...field}
            checked={value}
            checkboxProps={{
              isCentered: true,
              error: fieldState.invalid,
              helperText: fieldState?.error?.message,
              label: 'I have been sued for malpractice',
            }}
            onChange={onChange}
          />
        )}
      />
    </>
  )
}

export interface ProfessionalLicensesFormProps {
  formHookProps: UseFormReturn<{
    professionalLicenses: ProfessionalLicenseFormValues[]
  }>
  onSubmit?: (newValues: {
    professionalLicenses: ProfessionalLicenseFormValues[]
  }) => void
  /**
   * Set this to false if the caller will render the enclosing form element.
   */
  wrapInForm?: boolean
}

// eslint-disable-next-line react/no-unstable-nested-components
export const ProfessionalLicensesForm = ({
  formHookProps,
  onSubmit,
  wrapInForm = true,
}: ProfessionalLicensesFormProps) => {
  const { data: statesData, loading: statesLoading } = useCatalogUsStateQuery({
    variables: { sort: 'code' },
  })

  const { data: insuranceData, loading: insuranceLoading } =
    useMalpracticeInsuranceQuery({
      variables: { sort: 'order' },
    })

  const states: Array<Catalog_Us_State> = statesData?.catalog_us_state ?? []

  const malpracticeInsurance: Array<Catalog_Malpractice_Insurance> =
    insuranceData?.catalog_malpractice_insurance ?? []

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = formHookProps

  const { fields, append, remove } = useFieldArray({
    name: 'professionalLicenses',
    control,
  })

  const handleAddClick = () => {
    append(getDefaultValues())
  }

  const handleClickRemove = (idx: number) => {
    remove(idx)
  }

  const formBody = (
    <>
      {fields.map((professionalLicenseValues, idx) => (
        <ProfessionalLicense
          // eslint-disable-next-line react/no-array-index-key
          key={`${idx}_${professionalLicenseValues.barNumber}`}
          control={control}
          register={register}
          idx={idx}
          numProfessionalLicenses={fields.length}
          errors={errors}
          states={states}
          statesLoading={statesLoading}
          onClickRemove={() => handleClickRemove(idx)}
          malpracticeInsurance={malpracticeInsurance}
          malpracticeInsuranceLoading={insuranceLoading}
          watch={watch}
          setValue={setValue}
        />
      ))}
      <Grid pb={4} mt={3}>
        <Button startIcon={<Add />} variant="outlined" onClick={handleAddClick}>
          Add Another License
        </Button>
      </Grid>
    </>
  )

  return wrapInForm ? (
    <form onSubmit={onSubmit && handleSubmit(onSubmit)}>{formBody}</form>
  ) : (
    formBody
  )
}

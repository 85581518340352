import { useForm } from 'react-hook-form'
import Box from '@mui/material/Box'
import ErrorMessage from '@/components/Messagges/ErrorMessage'
import ConfirmationModal, { ConfirmationModalProps } from '../ConfirmationModal'
import JUser from '../../atoms/JUser'
import JAutocomplete from '../../atoms/JAutocomplete'
import { IJUser } from '../../atoms/JUser/types'
import JTextField from '../../atoms/JTextField'
import useInviteToCaseModal from './useInviteToCaseModal'

export interface InviteToCaseModalProps
  extends Omit<ConfirmationModalProps, 'title' | 'description'> {
  profile: IJUser
  attorneyId: string
  onSubmit: () => void
}

const InviteToCaseModal = ({
  profile,
  AcceptButtonProps,
  onSubmit,
  attorneyId: prospectAttorneyId,
  ...props
}: InviteToCaseModalProps) => {
  const {
    cases,
    loading,
    createCaseLoading,
    error,
    casePurchasesLoading,
    handleSubmitModal,
  } = useInviteToCaseModal({
    onSubmit,
    prospectAttorneyId,
  })

  const { control, handleSubmit } = useForm()
  return (
    <ConfirmationModal
      title="Invite To Case"
      description="Send invitations to any of your public cases. Avoid spamming and only select users who you think may be interested in the case. All users you select will be able to make proposals, even those who are non-vetted."
      AcceptButtonProps={{
        children: 'Send Invitation',
        onClick: handleSubmit(handleSubmitModal),
        disabled: createCaseLoading,
        ...AcceptButtonProps,
      }}
      {...props}
    >
      <JUser
        avatar={profile.avatar}
        mainText={profile.mainText}
        secondaryText={profile.secondaryText}
        isVetted={profile?.isVetted}
        isQualified={profile?.isQualified}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5, mt: 4 }}>
        <JAutocomplete
          loading={loading || casePurchasesLoading}
          control={control}
          name="caseId"
          options={cases || []}
          label="Search your Cases..."
          getOptionLabel={option => option.name}
          rules={{
            validate: (caseId: string) => caseId !== undefined,
          }}
        />
        <JTextField
          multiline
          name="message"
          control={control}
          minRows={3}
          placeholder="Write a message to the other lawyer..."
        />
        {error ? (
          <ErrorMessage
            title="There was a problem submitting your request"
            content={error}
            sx={{
              // NOTE: undefined overrides a maxWidth in ErrorMessage that is too small
              maxWidth: undefined,
              m: 'auto',
              width: '100%',
              '& .MuiAlert-root': {
                alignItems: 'flex-start',
              },
            }}
          />
        ) : null}
      </Box>
    </ConfirmationModal>
  )
}

export default InviteToCaseModal

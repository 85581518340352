import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const LawmaticsIcon = ({ height = 48, width = 48, ...props }: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="480"
      height="480"
      viewBox="0 0 480 480"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="480"
        height="480"
        rx="40"
        fill="url(#paint0_radial_22940_50051)"
      />
      <path
        d="M193.512 306.243C183.697 306.243 175.73 298.276 175.845 288.462C175.845 286.961 176.076 285.46 176.423 283.959L203.904 181.31C206.444 171.842 216.143 166.299 225.611 168.839C235.079 171.38 240.622 181.079 238.081 190.547L210.601 293.08C208.522 300.817 201.479 306.243 193.512 306.243Z"
        fill="#FFC017"
      />
      <path
        d="M249.744 306.244C248.128 306.244 246.627 306.013 245.126 305.667C235.658 303.127 230.115 293.428 232.54 283.959L269.258 147.017C271.798 137.549 281.497 132.007 290.966 134.547C300.434 137.087 305.976 146.786 303.436 156.254L266.833 293.081C264.755 300.817 257.712 306.244 249.744 306.244Z"
        fill="#FF8800"
      />
      <path
        d="M305.861 306.244C304.245 306.244 302.744 306.013 301.242 305.666C291.774 303.126 286.232 293.427 288.657 283.959L334.497 112.839C337.152 103.486 346.967 97.944 356.435 100.715C365.557 103.371 370.984 112.724 368.79 121.961L322.95 293.081C320.872 300.817 313.828 306.244 305.861 306.244Z"
        fill="#C23D3D"
      />
      <path
        d="M291.312 360.512H193.628C153.793 360.512 121.463 328.297 121.463 288.462C121.463 282.111 122.271 275.876 123.887 269.756L138.436 215.603C140.976 206.135 150.675 200.593 160.143 203.017C169.612 205.558 175.154 215.257 172.614 224.725L158.065 278.878C152.754 298.392 164.416 318.598 183.929 323.794C187.047 324.603 190.28 325.064 193.513 325.064H291.197C301.011 325.295 308.748 333.493 308.517 343.192C308.401 352.661 300.78 360.281 291.312 360.512Z"
        fill="#1F344C"
      />
      <defs>
        <radialGradient
          id="paint0_radial_22940_50051"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(240 240) rotate(90) scale(870 1102.5)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#C1C1C1" />
        </radialGradient>
      </defs>
    </svg>
  </SvgIcon>
)

export default LawmaticsIcon

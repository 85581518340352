import { Attorney_Case_Mp_Preference } from '@/gql/systemApi'
import useAttorneyId from '@/hooks/useAttorneyId'
import { getImagePath } from '@/utils'
import { useCallback, useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import {
  CASE_PURCHASE_STATUS,
  CASE_STATUS_FLOW,
  IMAGE_SIZES,
  MP_VISIBILITY,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
  USER_ROLES,
} from '@/constants'
import { useNavigate } from 'react-router-dom'
import { ReferralType, TCardUser } from '@/components/JReferralCard/types'
import {
  GetCasesFollowingDocument,
  useDeleteCaseMpPreferenceItemMutation,
  useGetCasesFollowingQuery,
} from '@/gql/appApi'
import useGetMyRoleAndOrg from '@/hooks/useGetMyRoleAndOrg'
import { isQualifiedMembership } from '@/utils/membership'
import { useFeatureFlags } from '@/optimizely/useFeatureFlags'
import { FeatureFlag } from '@/optimizely/types'
import { CaseEntry, CasePurchase } from '../../../types'

dayjs.extend(relativeTime)
dayjs.extend(utc)

export type NegotiationType = 'Referral' | 'Proposal'

const useReferralItems = ({ refetch }: { refetch?: () => void }) => {
  const featureFlags = useFeatureFlags()
  const isCaseStatusFlowSimplificationEnabled =
    featureFlags[FeatureFlag.CASE_STATUS_FLOW_SIMPLIFICATION]
  const {
    isAdmin,
    isCaseManager,
    getIsSameOrg,
    loading: usersMeLoading,
    userId,
  } = useGetMyRoleAndOrg()
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = useRef(getAttorneyId())
  const casePurchaseDurationUnits = import.meta.env
    .VITE_CASE_PURCHASE_EXPIRATION_TIME_UNITS
  const casePurchaseDuration = casePurchaseDurationUnits
    ? Number(casePurchaseDurationUnits)
    : 5

  const currentDate = dayjs.utc()

  const { data: casesFollowingData, loading: followingLoading } =
    useGetCasesFollowingQuery({
      variables: {
        filter: {
          _and: [
            {
              attorney_id: {
                id: {
                  _eq: attorneyId.current,
                },
              },
              case_mp_preference_id: {
                name: {
                  _eq: 'Following',
                },
              },
            },
          ],
        },
      },
      fetchPolicy: 'network-only',
    })
  const navigate = useNavigate()
  const [casesFollowing, setCasesFollowing] = useState<
    Partial<Attorney_Case_Mp_Preference[]>
  >([])
  const [error, setError] = useState('')
  const [deletePreference, { loading: deleteLoading }] =
    useDeleteCaseMpPreferenceItemMutation({
      refetchQueries: [GetCasesFollowingDocument, 'getCasesFollowing'],
    })

  useEffect(() => {
    if (casesFollowingData) {
      setCasesFollowing(casesFollowingData?.attorney_case_mp_preference)
    }
  }, [casesFollowingData])

  const goToCaseProfile = useCallback(
    (caseId: string) => {
      navigate(`/case/${caseId}`)
    },
    [navigate]
  )

  const getStatusName = useCallback((statusId: string) => {
    if (CASE_PURCHASE_STATUS.Proposed === statusId) {
      return 'Proposed'
    }
    if (CASE_PURCHASE_STATUS.Counteroffer === statusId) {
      return 'Counteroffer'
    }
    if (CASE_PURCHASE_STATUS.Reject === statusId) {
      return 'Rejected'
    }
    if (CASE_PURCHASE_STATUS.Accept === statusId) {
      return 'Accepted'
    }
    return 'Proposed'
  }, [])

  const generateProposalDateText = useCallback((date: string) => {
    // Posted on Posted on Mar 28, 2024 at 12:00pm
    const formattedDate = dayjs(date).format('MMM DD, YYYY')
    const formattedTime = dayjs(date).format('hh:mma')
    const dateText = dayjs(date).fromNow()
    return `${dateText} (${formattedDate} at ${formattedTime})`
  }, [])

  const generatePostedOnText = useCallback((date: string) => {
    // Posted on Posted on Mar 28, 2024 at 12:00pm
    const formattedDate = dayjs(date).format('MMM DD, YYYY')
    const formattedTime = dayjs(date).format('hh:mma')
    return `Posted on ${formattedDate} at ${formattedTime}`
  }, [])

  const getAttorneyFromSameOrg = useCallback(
    (caseEntry: CaseEntry) => {
      const prospectAttorneyIds = caseEntry?.case_purchase?.map(
        purchase => purchase?.prospect_attorney
      )

      const filteredAttorneyIds = prospectAttorneyIds.filter(att =>
        getIsSameOrg(att?.organization_id || '')
      )

      return filteredAttorneyIds[0]
    },
    [getIsSameOrg]
  )

  const getCardUsers = useCallback(
    (caseEntry: CaseEntry) => {
      if (!caseEntry?.collaborators?.length && !isCaseManager && !isAdmin) {
        return []
      }

      const collaborators: TCardUser[] = []

      const cleanCollaborators = caseEntry?.collaborators.filter(
        collaborator => collaborator !== null
      )

      if (!isCaseManager) {
        const filteredCollaborators = cleanCollaborators.filter(
          collaborator => collaborator?.attorney_id !== attorneyId.current
        )
        const newCollaborators = filteredCollaborators.map(
          collaborator =>
            ({
              id: collaborator?.attorney_id,
              name: `${collaborator?.first_name} ${collaborator?.last_name}`,
              avatarUrl: getImagePath({
                id: collaborator?.avatar ?? null,
                size: IMAGE_SIZES.Small,
              }),
              userRole:
                collaborator?.role !== USER_ROLES.CaseManagerUser
                  ? 'Collaborator'
                  : 'Case Manager',
              isVetted: collaborator?.is_vetted,
              isQualified: isQualifiedMembership(collaborator?.membership_type),
            } as TCardUser)
        )
        collaborators.push(...newCollaborators)
      }

      const finalCollaborators: TCardUser[] = []

      if (caseEntry?.case_purchase?.length && !isCaseManager) {
        const createdByActivityIds = caseEntry?.case_purchase?.map(
          purchase => purchase?.case_purchase_activities?.created_by
        )
        const createdByIds = caseEntry?.case_purchase?.map(
          purchase => purchase?.created_by
        )
        const prospectAttorneyIds = caseEntry?.case_purchase?.map(
          purchase => purchase?.prospect_attorney_id
        )

        const allAttorneyIds = [
          ...new Set([
            ...createdByActivityIds,
            ...createdByIds,
            ...prospectAttorneyIds,
          ]),
        ]

        const filteredCollaborators = collaborators?.filter(
          collaborator => !allAttorneyIds.includes(collaborator?.id)
        )

        finalCollaborators.push(...filteredCollaborators)
      } else {
        finalCollaborators.push(...collaborators)
      }

      const attorneys: TCardUser[] = []

      if (isCaseManager || isAdmin) {
        let attorneyToBeAdded = caseEntry?.attorney
        const isSameOrganization = getIsSameOrg(
          caseEntry?.attorney?.organization_id || ''
        )
        if (!isSameOrganization) {
          attorneyToBeAdded = getAttorneyFromSameOrg(caseEntry)
        }

        if (
          attorneyToBeAdded?.attorney_id &&
          attorneyToBeAdded?.attorney_id !== attorneyId.current
        ) {
          const attorney = [
            {
              id: attorneyToBeAdded?.attorney_id,
              name: `${attorneyToBeAdded?.first_name} ${attorneyToBeAdded?.last_name}`,
              avatarUrl: getImagePath({
                id: attorneyToBeAdded?.avatar ?? null,
                size: IMAGE_SIZES.Small,
              }),
              userRole: 'Attorney',
              isVetted: attorneyToBeAdded?.is_vetted,
              isQualified: isQualifiedMembership(
                attorneyToBeAdded?.membership_type
              ),
            } as TCardUser,
          ]
          attorneys.push(...attorney)
        }
      }
      const users = [...attorneys, ...finalCollaborators]
      return users
    },
    [getAttorneyFromSameOrg, getIsSameOrg, isAdmin, isCaseManager]
  )

  const getActiveWaterfall = useCallback(
    (waterfall: CaseEntry['waterfall']) =>
      waterfall?.find(
        item => item?.current_item_order === item?.waterfall_order
      ),
    []
  )

  const getReferralType = useCallback(
    (caseEntry: CaseEntry) => {
      if (caseEntry?.mp_visibility === MP_VISIBILITY.Priority) {
        if (caseEntry?.case_purchase?.length === 0) {
          return 'waterfall'
        }
        const activeWaterfall = getActiveWaterfall(caseEntry?.waterfall)

        if (!activeWaterfall) {
          return getStatusName(
            caseEntry?.case_purchase?.[0]?.status || ''
          ).toLowerCase() as ReferralType
        }
        const statusName = getStatusName(activeWaterfall?.status || '')
        if (
          (statusName === 'Proposed' || statusName === 'Counteroffer') &&
          getIsSameOrg(
            activeWaterfall?.prospect_attorney?.organization_id || ''
          )
        ) {
          return 'waterfall'
        }
        return statusName.toLowerCase() as ReferralType
      }
      if (caseEntry?.case_purchase?.length === 0) {
        return ''
      }
      const hasAccepted = caseEntry?.case_purchase?.some(
        purchase =>
          purchase?.case_purchase_activities?.activity_entry_status ===
          CASE_PURCHASE_STATUS.Accept
      )
      if (hasAccepted) {
        return 'accepted'
      }
      const caseActivityStatusId =
        caseEntry?.case_purchase?.[0]?.case_purchase_activities
          ?.activity_entry_status
      const statusName = getStatusName(caseActivityStatusId)

      return statusName.toLowerCase() as ReferralType
    },
    [getIsSameOrg, getStatusName, getActiveWaterfall]
  )

  const getPayments = useCallback((caseEntry: CaseEntry) => {
    const hasPayment = caseEntry?.case_purchase?.some(
      purchase => purchase?.payments?.length
    )
    if (hasPayment) {
      return caseEntry?.case_purchase?.find(
        purchase => purchase?.payments?.length
      )
    }
    return null
  }, [])

  const getCurrentUserPaid = useCallback(
    (casePurchase: CasePurchase) => {
      if (!casePurchase?.payments) {
        return false
      }
      return casePurchase.payments.some(
        payment =>
          getIsSameOrg(payment.attorney?.organization_id || '') &&
          (payment.payment_status_id === PAYMENT_STATUS.Uncaptured ||
            payment.payment_status_id === PAYMENT_STATUS.Completed)
      )
    },
    [getIsSameOrg]
  )

  const getCaseManagerOrAdminStatus = useCallback(
    (type: ReferralType | '', caseEntry: CaseEntry, isAdminRole: boolean) => {
      if (caseEntry?.case_purchase?.length === 0) {
        if (caseEntry?.data_room?.length > 0) {
          return 'dataroom'
        }
        return !isAdminRole ? 'assigned' : type
      }

      const includesReferralSentOrProposalSent = caseEntry?.case_purchase?.some(
        negotiation => {
          const activityCreatedById =
            negotiation?.case_purchase_activities?.created_by_attorney
              ?.attorney_id
          let representingAttorneyId = attorneyId.current
          const ownerIsSameOrg = getIsSameOrg(
            caseEntry?.attorney?.organization_id || ''
          )
          representingAttorneyId = ownerIsSameOrg
            ? caseEntry?.attorney?.attorney_id
            : negotiation?.prospect_attorney?.attorney_id

          const isCaseOwner =
            caseEntry?.attorney?.attorney_id === representingAttorneyId

          const isProposalSent =
            activityCreatedById === representingAttorneyId &&
            caseEntry?.attorney_id !== representingAttorneyId
          const isReferralSent =
            isCaseOwner && activityCreatedById === representingAttorneyId

          return isReferralSent || isProposalSent
        }
      )

      const includesReferralReceivedOrProposalReceived =
        caseEntry?.case_purchase?.some(negotiation => {
          const activityCreatedById =
            negotiation?.case_purchase_activities?.created_by_attorney
              ?.attorney_id
          let representingAttorneyId = attorneyId.current
          const ownerIsSameOrg = getIsSameOrg(
            caseEntry?.attorney?.organization_id || ''
          )
          representingAttorneyId = ownerIsSameOrg
            ? caseEntry?.attorney?.attorney_id
            : negotiation?.prospect_attorney?.attorney_id

          const isCaseOwner =
            caseEntry?.attorney?.attorney_id === representingAttorneyId

          const isReferralReceived =
            !isCaseOwner &&
            activityCreatedById !== representingAttorneyId &&
            negotiation?.prospect_attorney?.attorney_id ===
              representingAttorneyId

          const isProposalReceived =
            isCaseOwner && !(activityCreatedById === representingAttorneyId)

          return isReferralReceived || isProposalReceived
        })
      if (
        includesReferralSentOrProposalSent &&
        !includesReferralReceivedOrProposalReceived
      ) {
        if (type === 'accepted' || type === 'agreement') {
          return type
        }
        if (!isAdminRole) {
          return 'assigned'
        }
        return ''
      }
      return type
    },
    [getIsSameOrg]
  )

  const getIsInvited = useCallback(
    (caseEntry: CaseEntry, negotiation: CasePurchase) => {
      const caseCreatedById = caseEntry?.attorney?.attorney_id
      const casePurchaseCreatedById =
        negotiation?.created_by_attorney?.attorney_id
      const prospectOrgId = negotiation?.prospect_attorney?.organization_id
      const prospectIsSameOrg = getIsSameOrg(prospectOrgId || '')
      const isInvitedToPublicCase =
        caseCreatedById === casePurchaseCreatedById && prospectIsSameOrg
      const isPrivate = caseEntry?.mp_visibility === MP_VISIBILITY.Priority
      const isInvitedToPrivateCase = isPrivate && prospectIsSameOrg
      if (isInvitedToPublicCase || isInvitedToPrivateCase) {
        return true
      }
      return false
    },
    [getIsSameOrg]
  )
  const getCaseManagerOwnership = useCallback((currentCase: CaseEntry) => {
    const isPartOfCollaborators = currentCase?.collaborators?.some(
      collaborator => collaborator?.attorney_id === attorneyId.current
    )
    if (isPartOfCollaborators) {
      return isPartOfCollaborators
    }
    const isPartOfProspectCollaborators =
      currentCase?.prospect_collaborators?.some(
        prospectCollaborator =>
          prospectCollaborator?.attorney_id === attorneyId.current
      )
    return isPartOfProspectCollaborators
  }, [])

  const getIsOwnerAsCaseManagerOrAdmin = useCallback(
    (caseEntry: CaseEntry, isCaseManagerWithPermission: boolean) => {
      if (isCaseManager || isAdmin) {
        const ownerIsSameOrg = getIsSameOrg(
          caseEntry?.attorney?.organization_id || ''
        )
        if (ownerIsSameOrg) {
          return isCaseManagerWithPermission || isAdmin
        }
      }
      return false
    },
    [getIsSameOrg, isAdmin, isCaseManager]
  )

  const getNextExpirationJobTime = useCallback((date: dayjs.Dayjs) => {
    const dateUTC = date.utc()

    // Times set in Directus are in UTC and were set by the BE to be at 02:00 and 14:00 UTC
    const expirationJobAt2AM = dateUTC.startOf('day').add(2, 'hour')
    const expirationJobAt2PM = expirationJobAt2AM.add(12, 'hours')

    if (dateUTC.isBefore(expirationJobAt2AM)) {
      return expirationJobAt2AM
    }
    if (dateUTC.isBefore(expirationJobAt2PM)) {
      return expirationJobAt2PM
    }

    return expirationJobAt2AM.add(1, 'day')
  }, [])

  const getProposalExpirationDate = useCallback(
    (activityCreatedAt: CasePurchase['created_ts']) => {
      const proposalExpirationDate = dayjs
        .utc(activityCreatedAt)
        .add(casePurchaseDuration, 'days')

      const actualExpirationDate = getNextExpirationJobTime(
        proposalExpirationDate
      )

      const isWithin24Hours =
        actualExpirationDate.diff(currentDate, 'hour') <= 24

      return isWithin24Hours ? actualExpirationDate.toDate() : null
    },
    [casePurchaseDuration, currentDate, getNextExpirationJobTime]
  )

  const getCaseParams = useCallback(
    (caseEntry: CaseEntry) => {
      const negotiations = caseEntry?.case_purchase
      const negotiationAmount = caseEntry?.case_purchase?.length || 0
      const isPrivate = caseEntry?.mp_visibility === MP_VISIBILITY.Priority
      const cardUsers = getCardUsers(caseEntry)
      const dataroom = caseEntry?.data_room
      const leads = caseEntry?.leads
      const isCaseOwner =
        caseEntry?.attorney?.attorney_id === attorneyId.current
      let type = '' as ReferralType | ''
      type = getReferralType(caseEntry)
      let showActionsForCaseManagerWithOwnership = true
      if (isCaseManager || isAdmin) {
        type = getCaseManagerOrAdminStatus(type, caseEntry, isAdmin)
        if (isCaseManager) {
          showActionsForCaseManagerWithOwnership = Boolean(
            getCaseManagerOwnership(caseEntry)
          )
        }
      }
      let referralType: ReferralType =
        !type && dataroom.length > 0
          ? 'dataroom'
          : (type.toLowerCase() as ReferralType)

      const datePosted = generatePostedOnText(caseEntry?.created_ts)

      const isCaseSigned = caseEntry?.status === CASE_STATUS_FLOW.Signed

      const isAgreement =
        referralType === 'agreement' ||
        referralType === 'accepted' ||
        isCaseSigned

      let casePurchaseWithPayment: CasePurchase | undefined
      let bothPartiesPaid = false
      if (isAgreement) {
        const casePurchase = getPayments(caseEntry)
        if (casePurchase) {
          casePurchaseWithPayment = casePurchase
          const currentUserPaid = getCurrentUserPaid(casePurchase)
          if (currentUserPaid) {
            referralType = 'acceptedByCurrentUser'
          } else {
            referralType = 'acceptedByCounterpart'
          }
          bothPartiesPaid =
            (casePurchase?.payments?.length ?? 0) >= 2 ||
            // With the new case status flow, once the case is accepted, no more payments are required
            !!isCaseStatusFlowSimplificationEnabled
        }
      }

      const caseMpPreferenceId = casesFollowing.find(
        item => item?.case_id?.id === caseEntry?.id
      )?.id

      const isSameOrganization = getIsSameOrg(
        caseEntry?.attorney?.organization_id || ''
      )

      const isFollowingOnly =
        caseEntry?.following && !!caseMpPreferenceId && !isSameOrganization

      let fee
      if (!isFollowingOnly || negotiationAmount > 0) {
        fee = caseEntry?.fee
      }
      if (isAgreement) {
        fee = isCaseSigned
          ? caseEntry?.fee
          : caseEntry?.case_purchase?.[0]?.case_purchase_activities?.fee
      }

      const isCaseOwnerAsCaseManagerOrAdmin = getIsOwnerAsCaseManagerOrAdmin(
        caseEntry,
        showActionsForCaseManagerWithOwnership
      )
      const showInviteToCase =
        (isCaseOwner || isCaseOwnerAsCaseManagerOrAdmin) && !isPrivate

      const representingAttorneyId =
        isCaseOwnerAsCaseManagerOrAdmin && !isCaseOwner
          ? {
              attorneyId: caseEntry?.attorney?.attorney_id,
              userId: caseEntry?.attorney?.user_id,
            }
          : undefined

      const showDataroomRequests =
        dataroom.length > 0 && showActionsForCaseManagerWithOwnership

      const showLeads =
        leads.length > 0 &&
        (isAgreement || isCaseOwner || isCaseOwnerAsCaseManagerOrAdmin) &&
        showActionsForCaseManagerWithOwnership

      const isInitiatingAttorney =
        caseEntry?.case_purchase?.[0]?.prospect_attorney_id ===
        caseEntry?.case_purchase?.[0]?.created_by_attorney?.attorney_id

      return {
        referralType,
        isPrivate,
        cardUsers,
        negotiations,
        negotiationAmount,
        dataroom,
        leads,
        showInviteToCase,
        datePosted,
        isAgreement,
        caseMpPreferenceId,
        isFollowingOnly,
        fee,
        casePurchaseWithPayment,
        bothPartiesPaid,
        showActionsForCaseManagerWithOwnership,
        representingAttorneyId,
        showDataroomRequests,
        showLeads,
        isCaseOwner,
        isInitiatingAttorney,
      }
    },
    [
      getCardUsers,
      getReferralType,
      isCaseManager,
      isAdmin,
      generatePostedOnText,
      casesFollowing,
      getIsSameOrg,
      getCaseManagerOrAdminStatus,
      getPayments,
      getCurrentUserPaid,
      getCaseManagerOwnership,
      getIsOwnerAsCaseManagerOrAdmin,
      isCaseStatusFlowSimplificationEnabled,
    ]
  )

  const getNegotiationParams = useCallback(
    (
      negotiation: CasePurchase | undefined,
      newCase: CaseEntry,
      casePurchaseWithPayment?: CasePurchase
    ) => {
      const casePurchaseStatus = getStatusName(negotiation?.status ?? '') as
        | 'Proposed'
        | 'Counteroffer'
        | 'Rejected'
        | 'Accepted'
      const activityCreatedById =
        negotiation?.case_purchase_activities?.created_by_attorney?.attorney_id
      const isSigned = newCase?.status === CASE_STATUS_FLOW.Signed
      const fee = isSigned
        ? newCase?.fee
        : negotiation?.case_purchase_activities?.fee
      let representingAttorneyId: string | null | undefined = attorneyId.current
      let representingAttorneyUserId: string | undefined
      let activityCreatedForCaseManagerOrAdmin
      if (isCaseManager || isAdmin) {
        const ownerIsSameOrg = getIsSameOrg(
          newCase?.attorney?.organization_id || ''
        )
        const activityOwnerIsSameOrg = getIsSameOrg(
          negotiation?.case_purchase_activities?.created_by_attorney
            ?.organization_id || ''
        )
        representingAttorneyId = ownerIsSameOrg
          ? newCase?.attorney?.attorney_id
          : negotiation?.prospect_attorney?.attorney_id

        representingAttorneyUserId = ownerIsSameOrg
          ? newCase?.attorney?.user_id
          : negotiation?.prospect_attorney?.user_id

        activityCreatedForCaseManagerOrAdmin = activityOwnerIsSameOrg
          ? attorneyId.current || ''
          : activityCreatedById
      }
      const isCaseOwner =
        newCase?.attorney?.attorney_id === representingAttorneyId
      const isInvited = negotiation ? getIsInvited(newCase, negotiation) : false
      const profile = isCaseOwner
        ? negotiation?.prospect_attorney
        : newCase?.attorney
      const dateText = generateProposalDateText(negotiation?.created_ts ?? '')

      const proposalExpirationDate = getProposalExpirationDate(
        negotiation?.status_ts ?? ''
      )
      const isReferralReceived =
        !isCaseOwner &&
        activityCreatedById !== representingAttorneyId &&
        negotiation?.prospect_attorney?.attorney_id === representingAttorneyId
      const isProposalSent =
        activityCreatedById === representingAttorneyId &&
        newCase?.attorney_id !== representingAttorneyId
      const isProposalReceived =
        isCaseOwner && !(activityCreatedById === representingAttorneyId)
      const isReferralSent =
        isCaseOwner && activityCreatedById === representingAttorneyId
      let feeText = 'Referral/Co-Counsel Fee Requested'
      if (isSigned) {
        feeText = 'Proposal Accepted'
      } else if (isReferralReceived) {
        feeText = 'Referral/Co-Counsel Fee Requested'
      } else if (isProposalSent) {
        feeText = 'Proposal Sent'
      } else if (isProposalReceived) {
        feeText = 'Proposal Received'
      } else if (isReferralSent) {
        feeText = 'Proposal Sent'
      } else if (casePurchaseStatus === 'Accepted') {
        feeText = 'Proposal Accepted'
      }
      const type: NegotiationType =
        isReferralReceived || isProposalSent ? 'Referral' : 'Proposal'

      const caseTaken = negotiation?.status === CASE_PURCHASE_STATUS.Blocked

      const paymentStatus = {
        currentUserPaid: false,
        counterpartPaid: false,
        bothPartiesPaid: false,
      }
      if (casePurchaseWithPayment && casePurchaseWithPayment?.payments) {
        if (casePurchaseWithPayment?.payments?.length >= 2) {
          paymentStatus.bothPartiesPaid = true
          paymentStatus.currentUserPaid = true
          paymentStatus.counterpartPaid = true
        }
      }
      if (
        casePurchaseWithPayment &&
        casePurchaseWithPayment.id === negotiation?.id &&
        casePurchaseWithPayment?.payments?.length === 1
      ) {
        const currentUserPaid = getCurrentUserPaid(casePurchaseWithPayment)
        paymentStatus.currentUserPaid = currentUserPaid
        paymentStatus.counterpartPaid = !currentUserPaid
      }

      // In the old flow, the `casePurchaseWithPayment` is only generated when the case is accepted
      // or there is an agreement. In the new flow, we need to check if the current user paid to
      // avoid sending the prospect to the payment page again when the case is accepted (if they sent a prepaid proposal).
      if (isCaseStatusFlowSimplificationEnabled) {
        const currentUserPaid = negotiation
          ? getCurrentUserPaid(negotiation)
          : false
        paymentStatus.currentUserPaid = currentUserPaid
      }

      const stripePaymentAmount = Number(
        negotiation?.payments?.[0]?.amount ?? 0
      )

      const isStripePaymentMethod =
        negotiation?.payments?.[0]?.payment_method === PAYMENT_METHOD.Stripe

      const amountToPay =
        isStripePaymentMethod && stripePaymentAmount > 0
          ? stripePaymentAmount
          : 0

      const caseStatus = newCase?.status

      return {
        casePurchaseStatus,
        fee,
        isCaseOwner,
        profile,
        activityCreatedById:
          isCaseManager || isAdmin
            ? activityCreatedForCaseManagerOrAdmin
            : activityCreatedById,
        dateText,
        type,
        caseTaken,
        feeText,
        representingAttorneyId,
        representingAttorneyUserId,
        paymentStatus,
        isInvited,
        caseStatus,
        amountToPay,
        proposalExpirationDate,
      }
    },
    [
      generateProposalDateText,
      getCurrentUserPaid,
      getIsInvited,
      getIsSameOrg,
      getStatusName,
      isAdmin,
      isCaseManager,
      isCaseStatusFlowSimplificationEnabled,
      getProposalExpirationDate,
    ]
  )

  const onStopWatching = useCallback(
    async (deleteAttorneyCaseMpPreferenceItemId: string) => {
      try {
        const result = await deletePreference({
          variables: {
            deleteAttorneyCaseMpPreferenceItemId,
          },
        })
        if (refetch) {
          refetch()
        }

        if (result.data?.delete_attorney_case_mp_preference_item?.id) {
          return
        }

        if (result.errors) {
          setError(result.errors[0].message)
        }
      } catch {
        setError(
          'An error occurred while removing the Watching preference. Please try again later.'
        )
      }
    },
    [deletePreference, refetch]
  )

  return {
    goToCaseProfile,
    getCaseParams,
    getNegotiationParams,
    onStopWatching,
    loading: deleteLoading || followingLoading,
    rolesLoading: usersMeLoading,
    userId,
    setError,
    error,
    isCaseStatusFlowSimplificationEnabled,
  }
}

export default useReferralItems

import { FC, useMemo } from 'react'
import { CaseEntry } from '@/pages/MyReferralsV2/types'
import { CaseChatProvider } from '@/providers/CaseChatProvider'
import { useReferral } from './useReferral'
import { BuyerReferral } from './BuyerReferral'
import { SellerReferral } from './SellerReferral'
import { ReferralContext } from './ReferralContext'

export interface ReferralProps {
  caseEntry: CaseEntry
}

export const Referral: FC<ReferralProps> = ({ caseEntry }) => {
  const referralContextValue = useReferral({
    caseEntry,
  })

  const {
    caseParams: { isCaseOwner },
    isUserSeller,
  } = referralContextValue

  const memoizedReferralContextValue = useMemo(
    () => referralContextValue,
    [referralContextValue]
  )

  return (
    <CaseChatProvider
      caseId={caseEntry.id}
      isCasePage={false}
      caseTitle={caseEntry.name}
      isCaseOwner={isCaseOwner}
    >
      <ReferralContext.Provider value={memoizedReferralContextValue}>
        {isUserSeller ? <SellerReferral /> : <BuyerReferral />}
      </ReferralContext.Provider>
    </CaseChatProvider>
  )
}

import { CaseStatusFriendlyName } from '@/constants'
import { ICaseNegotiationItemUser } from './types'

export const getCaseNegotiationItemMessage = (
  isMe: boolean | undefined,
  status: CaseStatusFriendlyName,
  user: ICaseNegotiationItemUser
) => {
  // You or John Doe
  const userName = isMe ? 'You' : `${user.first_name} ${user.last_name}`

  // You have, John Doe has
  const baseMsg = `${userName} ${isMe ? 'have' : 'has'}`

  // You have sent a proposal, John Doe has received a proposal
  let action = isMe ? 'sent' : 'received'

  if (
    !isMe &&
    ['Proposed', 'Counteroffer', 'PendingPaymentAuthorization'].includes(status)
  ) {
    action = 'sent'
  }

  if (status === 'Proposed') {
    return `${baseMsg} ${action} a proposal`
  }

  if (status === 'PendingPaymentAuthorization') {
    return `${baseMsg} ${action} a proposal (pending payment authorization)`
  }

  if (['DeclinedAndAvaliable', 'DeclinedAndRejected'].includes(status)) {
    return `${baseMsg} declined the case. Client not signed`
  }

  if (status === 'Signed') {
    return `${baseMsg} confirmed the client is signed`
  }

  // You have accepted the proposal, John Doe has accepted the proposal
  if (status === 'Accepted' || status === 'Rejected') {
    return `${baseMsg} ${status.toLowerCase()} the proposal`
  }

  if (status === 'Withdrawn') {
    return `${baseMsg} withdrawn the proposal`
  }

  // You have sent a proposal, John Doe has sent a proposal
  return `${baseMsg} ${action} a ${status.toLowerCase()}`
}

import { useContext } from 'react'
import { CreateCaseContext } from '@/components/Case/CreateCaseContext/CreateCaseContext'
import CreateCaseSectionLayout from '../CreateCaseSectionLayout'
import CreateCaseFileUploader from './CreateCaseFileUploader'

const CreateCaseDataroom = () => {
  const { isEdit } = useContext(CreateCaseContext)

  if (isEdit) {
    return null
  }

  return (
    <CreateCaseSectionLayout
      title="Data Room"
      subtitle="Any files you upload for this case are only viewable by you and any attorney(s) you grant access to."
      sx={{
        mb: 5,
        borderBottom: `1px solid`,
        borderColor: 'divider',
      }}
    >
      <CreateCaseFileUploader />
    </CreateCaseSectionLayout>
  )
}

export default CreateCaseDataroom

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import SvgIcon from '@mui/material/SvgIcon'
import { FC } from 'react'

interface ICaseInvitedTagProps {
  title: string
}

const CaseInvitedTag: FC<ICaseInvitedTagProps> = ({
  title,
}: ICaseInvitedTagProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: theme => theme.spacing(1),
      alignSelf: 'stretch',
    }}
  >
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path d="M15.5 5H11L16 12L11 19H15.5L20.5 12L15.5 5Z" fill="#05DF9D" />
        <path d="M8.5 5H4L9 12L4 19H8.5L13.5 12L8.5 5Z" fill="#05DF9D" />
      </svg>
    </SvgIcon>
    <Typography variant="subtitle2">{title}</Typography>
  </Box>
)

export default CaseInvitedTag

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined'
import CloseIcon from '@mui/icons-material/Close'
import { useFieldArray, useFormContext } from 'react-hook-form'
import useTheme from '@mui/material/styles/useTheme'
import { alpha } from '@mui/material'
import { useSizes } from '@/hooks/useSizes'
import SelectPracticeAreas from '../SelectPracticeAreas/SelectPracticeAreas'
import SelectDefaultAttorney from '../SelectDefaultAttorney/SelectDefaultAttorney'
import {
  PracticeAreaMappingFormValues,
  TValidAttorneysAndJurisdictions,
} from '../usePracticeAreaMapping'
import SelectJurisdiction from '../SelectJurisdiction/SelectJurisdiction'

interface AttorneyToPracticeAreaProps {
  validJurisdictionsAndAttorneys: TValidAttorneysAndJurisdictions
}

const AttorneyToPracticeArea = ({
  validJurisdictionsAndAttorneys,
}: AttorneyToPracticeAreaProps) => {
  const theme = useTheme()
  const { isLessMd } = useSizes()
  const { control, register, watch } =
    useFormContext<PracticeAreaMappingFormValues>()
  const { fields, append, remove } = useFieldArray({
    name: 'assignedAttorneys',
    control,
  })

  const { validJurisdictions, validAttorneys } = validJurisdictionsAndAttorneys

  const hasMultipleJurisdictions = validJurisdictions.length > 1

  const handleAddClick = () => {
    append({
      assignedAttorneyId: '',
      attorneyToPracticeAreas: [],
      jurisdictionId: !hasMultipleJurisdictions ? validJurisdictions[0].id : '',
    })
  }

  const handleClickRemove = (idx: number) => {
    remove(idx)
  }

  return (
    <>
      {fields.map((assignedAttorneys, idx) => {
        const { name: assignedAttorneyIdName } = register(
          `assignedAttorneys.${idx}.assignedAttorneyId`
        )
        const { name: attorneyToPracticeAreasName } = register(
          `assignedAttorneys.${idx}.attorneyToPracticeAreas`
        )
        const { name: jurisdictionIdName } = register(
          `assignedAttorneys.${idx}.jurisdictionId`
        )
        const assignedAttorneyId = watch(assignedAttorneyIdName)

        return (
          <Box
            key={`field-${assignedAttorneys?.id}`}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
              mb: {
                xs: 3,
                md: 2,
              },
            }}
          >
            {hasMultipleJurisdictions && (
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  mr: {
                    xs: 0,
                    md: 2,
                  },
                }}
              >
                <SelectJurisdiction
                  key={`${assignedAttorneys?.id}_${assignedAttorneys?.jurisdictionId}`}
                  fieldName={jurisdictionIdName}
                  validJurisdictions={validJurisdictions}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={hasMultipleJurisdictions ? 3.5 : 4}
              sx={{
                mt: {
                  xs: 3,
                  md: 0,
                },
              }}
            >
              <SelectDefaultAttorney
                key={`${assignedAttorneys?.id}_${assignedAttorneys?.assignedAttorneyId}`}
                fieldName={assignedAttorneyIdName}
                rules={{
                  required: 'Please select an attorney',
                }}
                index={idx}
                validAttorneys={validAttorneys}
                excludeSelected
              />
            </Grid>
            <Grid item sm={12} md={hasMultipleJurisdictions ? 5.5 : 7}>
              <SelectPracticeAreas
                key={`${assignedAttorneys?.id}_${assignedAttorneys?.attorneyToPracticeAreas}`}
                fieldName={attorneyToPracticeAreasName}
                index={idx}
                assignedAttorneyId={assignedAttorneyId}
              />
            </Grid>
            <Button
              onClick={() => handleClickRemove(idx)}
              sx={{
                m: 0,
                mt: {
                  xs: 2,
                  md: 0,
                },
                pr: 0.5,
                ml: {
                  xs: 0,
                  md: 0.5,
                },
                color: alpha(theme.palette.common.black, 0.87),
                alignSelf: { xs: 'flex-end', md: 'center' },
              }}
              startIcon={
                <CloseIcon
                  sx={{
                    height: 16,
                    color: alpha(theme.palette.common.black, 0.87),
                  }}
                />
              }
            >
              {isLessMd ? 'Remove' : ''}
            </Button>
          </Box>
        )
      })}
      <Button
        onClick={handleAddClick}
        startIcon={<PersonAddAlt1OutlinedIcon sx={{ height: 16 }} />}
        sx={{
          alignSelf: 'flex-start',
        }}
      >
        Add another Attorney
      </Button>
    </>
  )
}

export default AttorneyToPracticeArea

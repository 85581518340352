import { CasePurchase } from '@/pages/MyReferralsV2/types'
import legacyUseNegotiations from '@/pages/MyReferralsV2/NegotiationItem/hooks/useNegotiations'
import useLegacyReferralItems from '@/pages/MyReferralsV2/components/ReferralItems/hooks/useReferralItems'
import { useContext } from 'react'
import { MODAL_TYPES } from '@/types'
import { ReferralContext } from './ReferralContext'
import { MyReferralsContext } from '../MyReferralsContext'

interface UseClientSignatureConfirmationProps {
  casePurchase: CasePurchase
}

export const useClientSignatureConfirmation = ({
  casePurchase,
}: UseClientSignatureConfirmationProps) => {
  const { refetch } = useContext(MyReferralsContext)
  const { caseEntry, isUserSeller } = useContext(ReferralContext)
  const { getNegotiationParams, getCaseParams, userId } =
    useLegacyReferralItems({
      refetch,
    })
  const caseParams = getCaseParams(caseEntry)
  const { casePurchaseWithPayment } = caseParams

  const negotiationParams = getNegotiationParams(
    casePurchase,
    caseEntry,
    casePurchaseWithPayment
  )

  const {
    activityCreatedById = '',
    isCaseOwner,
    isInvited,
    paymentStatus,
    representingAttorneyId,
    fee: offeredFee,
    amountToPay,
  } = negotiationParams

  const otherSideAttorney = isUserSeller
    ? casePurchase.prospect_attorney
    : caseEntry.attorney

  const noPaymentRequired =
    isInvited || isCaseOwner || paymentStatus.currentUserPaid

  const negotiationProps = legacyUseNegotiations({
    activityCreatedById,
    caseId: caseEntry.id,
    casePurchaseId: casePurchase.id,
    isCaseOwner,
    isInvited,
    noPaymentRequired,
    paymentStatus,
    representingAttorneyId: representingAttorneyId ?? '',
    userId,
    userProfile: otherSideAttorney,
  })

  const {
    handleClientRejected,
    handleClientRejectedSubmit,
    handleClientSigned,
    handleClientSignedModalClose,
    handleClientSignedSubmit,
    handleRejectionModalClose,
    rejectionModalIsOpen,
    signedModalOpen,
    response,
    setResponse,
  } = negotiationProps

  const clientSigningResponseModalTypes: string[] = [
    MODAL_TYPES.CLIENT_SIGNED_CONFIRMATION,
    MODAL_TYPES.CLIENT_SIGNED_REJECTED,
  ]

  const isClientSigningResponseModal = clientSigningResponseModalTypes.includes(
    response.type ?? ''
  )

  const matchFeeContentOnClientSigning =
    response.type === MODAL_TYPES.CLIENT_SIGNED_CONFIRMATION &&
    (amountToPay > 0
      ? `A match fee of $${amountToPay} has been or will be charged to the card you provided. Thank you for using Attorney Share.`
      : 'Thank you for using Attorney Share. ')

  const handleResponseModalClose = async () => {
    await refetch()
  }

  return {
    handleClientRejected,
    handleClientRejectedSubmit,
    handleClientSigned,
    handleClientSignedModalClose,
    handleClientSignedSubmit,
    handleRejectionModalClose,
    handleResponseModalClose,
    isClientSigningResponseModal,
    matchFeeContentOnClientSigning,
    offeredFee,
    rejectionModalIsOpen,
    representingAttorneyId,
    response,
    setResponse,
    signedModalOpen,
  }
}

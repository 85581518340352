import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import JRadioCard from '@/components/atoms/JRadioCard'
import { useContext } from 'react'
import { CreateCaseContext } from '@/components/Case/CreateCaseContext/CreateCaseContext'
import WaterfallReferrals from './components/WaterfallReferrals/WaterfallReferrals'
import useMarketplaceVisibility from './useMarketplaceVisibility'
import CreateCaseSectionLayout from '../CreateCaseSectionLayout'

const MarketplaceVisibility = () => {
  const {
    handleSelectVisibility,
    isEdit,
    marketplaceVisibilityError,
    mktpVisibilityErrorRef,
    selectedCard,
  } = useContext(CreateCaseContext)

  const {
    hasPermissionToCreateWaterfalls,
    loading,
    marketplaceVisibilityDisabled,
    orgId,
    waterfallLists,
  } = useMarketplaceVisibility()

  if (isEdit) return null

  if (loading) {
    return (
      <Box
        sx={({ palette }) => ({
          display: {
            xs: 'flex-column',
            md: 'flex',
          },
          marginTop: 5,
          paddingBottom: 2.5,
          borderBottom: `1px solid ${palette.secondary.main}`,
        })}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (marketplaceVisibilityDisabled) return null

  const isPriority = selectedCard === 'priority'

  return (
    <CreateCaseSectionLayout
      title="Marketplace Visibility"
      subtitle="Choose whether you want the case to be visible to all Attorney Share members or only to a selected group of users."
      sx={{
        mb: 5,
        pb: 3,
        borderBottom: `1px solid`,
        borderColor: 'divider',
      }}
    >
      <Box
        ref={mktpVisibilityErrorRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: {
            xs: 2.5,
            md: 0,
          },
        }}
      >
        <JRadioCard
          label="Public"
          description="Post publicly in the Marketplace"
          selected={selectedCard === 'public'}
          onChange={() => handleSelectVisibility('public')}
        />
        <JRadioCard
          label="Waterfall Referrals&trade;"
          description="Invite a selected waterfall first, before the case is posted publicly in the Marketplace"
          selected={isPriority}
          onChange={() => handleSelectVisibility('priority')}
          sx={{
            mt: 2,
          }}
        />
      </Box>
      {marketplaceVisibilityError && (
        <Alert
          sx={{
            marginBottom: 1,
          }}
          severity="error"
        >
          {marketplaceVisibilityError}
        </Alert>
      )}
      {isPriority && (
        <WaterfallReferrals
          hasPermissionToCreateWaterfalls={hasPermissionToCreateWaterfalls}
          orgId={orgId}
          waterfallLists={waterfallLists}
        />
      )}
    </CreateCaseSectionLayout>
  )
}

export default MarketplaceVisibility

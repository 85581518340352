import React from 'react'
import Fee from '@/components/Fee'
import JUser from '@/components/atoms/JUser'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { getCaseNegotiationItemMessage } from './getCaseNegotiationItemMessage'
import { ICaseNegotiationItem } from './types'

const CaseNegotiationItem: React.FC<ICaseNegotiationItem> = ({
  user,
  date,
  avatar,
  fee,
  comment,
  isMe,
  status,
  isVetted,
  isQualified,
}: ICaseNegotiationItem) => {
  const message = getCaseNegotiationItemMessage(isMe, status, user)

  return (
    <Grid container>
      <Grid container direction="row" gap={1.5}>
        <JUser
          mainText={message}
          label={date}
          avatar={avatar && avatar}
          isVetted={isVetted}
          isQualified={isQualified}
        />
        {fee !== 0 && <Fee variant="outlined" fee={fee} />}
      </Grid>
      {comment && (
        <Grid item xs={12} sx={{ padding: '1.25rem 0rem 2rem 2.5rem' }}>
          <Typography variant="body2">{comment && comment}</Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default CaseNegotiationItem

import { ICreateNewCaseProps } from './types'
import { CreateCaseProvider } from '../CreateCaseContext/CreateCaseContext'
import CaseForm from './CaseForm'

const CreateNewCase = (props: ICreateNewCaseProps) => (
  <CreateCaseProvider {...props}>
    <CaseForm />
  </CreateCaseProvider>
)

export default CreateNewCase

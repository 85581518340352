import { GetOrganizationConnectedQuery } from '@/gql/appApi'
import { USER_ADMINS_ROLES } from '@/constants'
import { LCMS_IDS } from '../../constants'

interface IGetClioConnectionData {
  organizationData?: GetOrganizationConnectedQuery
  userId: string
}

export const getClioConnectionData = ({
  organizationData,
  userId,
}: IGetClioConnectionData) => {
  const clioLcmsConnections =
    organizationData?.organization_connected_lcms?.filter(
      connection =>
        connection?.case_management_software_id?.id === LCMS_IDS.CLIO
    ) || []

  // Check if an admin from the Org is connected to Clio
  const hasAdminConnectedToClio = clioLcmsConnections.some(connection =>
    USER_ADMINS_ROLES.includes(connection?.created_by?.role?.id || '')
  )

  // Find current user's connection (Clio connections are unique per user)
  const isCurrentUserConnectedToClio = clioLcmsConnections.some(
    connection => connection?.created_by?.id === userId
  )

  return {
    clioLcmsConnections,
    hasAdminConnectedToClio,
    isCurrentUserConnectedToClio,
  }
}

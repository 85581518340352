import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import { useSizes } from '@/hooks/useSizes'
import { WithdrawProposalButton } from '@/components/WithdrawProposalButton/WithdrawProposalButton'

interface RenderAgreementProps {
  currentUserWithValidPayment: boolean
  buttonLabel: string
  fullButtonWidth?: boolean
  onPaymentClick: () => void
  casePurchaseId: string
}

const AgreementContent = ({
  currentUserWithValidPayment,
  buttonLabel,
  fullButtonWidth,
  onPaymentClick,
  casePurchaseId,
}: RenderAgreementProps) => {
  const { isGreaterMd, isLessMd } = useSizes()

  if (!currentUserWithValidPayment) {
    return (
      <>
        {!isLessMd && <Divider sx={{ width: '100%' }} />}
        <Button
          variant="contained"
          size="large"
          fullWidth={!isGreaterMd || fullButtonWidth}
          onClick={onPaymentClick}
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {buttonLabel}
        </Button>
      </>
    )
  }

  return (
    <>
      <WithdrawProposalButton casePurchaseId={casePurchaseId} />
      {!isLessMd && <Divider sx={{ width: '100%' }} />}
      <Typography variant="caption" color="text.disabled">
        Waiting for counterpart response
      </Typography>
    </>
  )
}

export default AgreementContent

import { useSizes } from '@/hooks/useSizes'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { Controller, FieldValues } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import { TJDatePickerProps } from './types'

const styles = {
  '& .MuiOutlinedInput-root': {
    height: '48px',
    '& input': {
      height: 'calc(100% - 12px)',
      padding: '6px 14px',
    },
  },
  '& .MuiInputLabel-root': {
    top: 0,
    left: 0,
    position: 'absolute',
    transform: 'translate(14px, 12px) scale(1)',
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(14px, -8px) scale(0.75)',
  },
}

const FitDatePicker = styled(DesktopDatePicker<Date>)(() => styles)
const FitMobileDatePicker = styled(MobileDatePicker<Date>)(() => styles)

const JDatePicker = <FV extends FieldValues>({
  name,
  control,
  label,
  rules,
  fitStyle = false,
  ...rest
}: TJDatePickerProps<FV>) => {
  const { isLessMd } = useSizes()
  const textInputLabel = `${label ?? ''}${rules ? ' *' : ''}`

  const DesktopDatePickerComponent = fitStyle
    ? FitDatePicker
    : DesktopDatePicker

  const MobileDatePickerComponent = fitStyle
    ? FitMobileDatePicker
    : MobileDatePicker

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        rules={typeof rules === 'string' ? { required: rules } : rules}
        render={({ field: { ref, ...field }, fieldState: { error } }) => {
          if (!isLessMd) {
            return (
              <DesktopDatePickerComponent
                label={textInputLabel}
                inputRef={ref}
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: 'outlined',
                    error: !!error,
                    helperText: error?.message,
                  },
                }}
                {...rest}
                {...field}
              />
            )
          }
          return (
            <MobileDatePickerComponent
              label={textInputLabel}
              inputRef={ref}
              sx={{ width: '100%' }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: 'outlined',
                  error: !!error,
                  helperText: error?.message,
                },
              }}
              {...rest}
              {...field}
            />
          )
        }}
      />
    </LocalizationProvider>
  )
}
export default JDatePicker

import request from '@/axios/config'
import setAuthToken from '@/axios/setAuthToken'
import {
  SessionKey,
  sessionStorageService,
} from '@/services/SessionStorage/SessionStorageService'
import { useCallback } from 'react'

export const useFileDownload = () => {
  const token = sessionStorageService.getItem(SessionKey.TOKEN)

  const validateToken = useCallback(() => {
    if (!token) {
      throw new Error('Token is missing')
    }
    setAuthToken(token)
  }, [token])

  const download = useCallback(
    async (fileId: string, caseName?: string) => {
      validateToken()
      try {
        const response = await request.get(`/assets/${fileId}?download`, {
          responseType: 'blob',
        })

        let filename = `case_dataroom_${caseName || 'file'}`
        const contentDisposition = response.headers['content-disposition']

        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          )
          if (filenameMatch && filenameMatch[1]) {
            filename = filenameMatch[1].replace(/['"]/g, '')
          }
        } else {
          const mimeType = response.data.type
          const extension = mimeType.split('/').pop()
          // We currently only support zip and rar files
          // and rar files are downloaded as octet-stream type.
          if (extension === 'octet-stream') {
            filename += '.rar'
          } else {
            filename += '.zip'
          }
        }

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()

        if (link.parentNode !== null) {
          link.parentNode.removeChild(link)
        }
        window.URL.revokeObjectURL(url)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },
    [validateToken]
  )

  return { download }
}

import Typography from '@mui/material/Typography'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Link from '@mui/material/Link'
import useTheme from '@mui/material/styles/useTheme'

import Header from '@/components/Modals/ModalHeader/ModalHeader'
import ModalContent from '@/components/Modals/ModalContent/ModalContent'
import { ZENDESK_HELP_URL } from '@/constants'

export interface ExistingOrganizationModalProps extends DialogProps {
  organizationName: string
}

const ExistingOrganizationModal = ({
  children,
  organizationName,
  ...rest
}: ExistingOrganizationModalProps) => {
  const theme = useTheme()

  return (
    <Dialog
      scroll="paper"
      maxWidth="xs"
      {...rest}
      sx={{
        '& .MuiTypography-root + button': {
          // NOTE: This hides the close button without affecting the dialog layout.
          visibility: 'hidden',
          pointerEvents: 'none',
        },
      }}
    >
      <Header title="Existing Organization" />
      <DialogContent>
        <ModalContent
          sx={{
            '& p': {
              mb: 2,
            },
          }}
        >
          <Typography variant="body1">
            <Typography component="span" fontWeight="bold">
              {organizationName}
            </Typography>{' '}
            is already using Attorney Share. We&apos;ve informed the admin of
            the account that you&apos;re interested in joining, and they can
            send you an invitation.
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            If you are a member of {organizationName} please do not proceed with
            creating the account.
          </Typography>
          <Typography variant="body1">
            If you&apos;re not part of this organization, please contact us via
            our{' '}
            <Link
              href={ZENDESK_HELP_URL}
              target="_blank"
              rel="noreferrer"
              sx={{
                textDecorationColor: theme.palette.primary.main,
              }}
            >
              Help Center
            </Link>
            .
          </Typography>
        </ModalContent>
      </DialogContent>
    </Dialog>
  )
}

export default ExistingOrganizationModal

import {
  MEMBERSHIP_TYPES,
  USER_ADMINS_ROLES,
  USER_ATTORNEY_ROLES,
  USER_ROLES,
} from '@/constants'

import { useUsersMeQuery } from '@/gql/systemApi'
import useOrganizationId from '@/hooks/organization/useOrganizationId'
import { useGetVettedAttorneysInOrgQuery } from '@/gql/appApi'

const useCaseManager = () => {
  const { data: userMe } = useUsersMeQuery()
  const { id } = useOrganizationId()
  const userId = userMe?.users_me?.id

  const {
    data: vettedAttorneysData,
    loading: vettedAttorneysLoading,
    error: vettedAttorneysError,
  } = useGetVettedAttorneysInOrgQuery({
    variables: {
      filter: {
        _and: [
          {
            _or: [
              {
                membership_type: {
                  id: {
                    _eq: MEMBERSHIP_TYPES.Vetted,
                  },
                },
              },
              {
                membership_type: {
                  id: {
                    _eq: MEMBERSHIP_TYPES.Qualified,
                  },
                },
              },
            ],
            user_id: {
              current_organization: {
                organization_id: {
                  id: {
                    _eq: id,
                  },
                },
              },
            },
          },
          {
            user_id: {
              id: {
                _neq: userId,
              },
            },
          },
          {
            user_id: {
              role: {
                id: {
                  _in: USER_ATTORNEY_ROLES,
                },
              },
            },
          },
        ],
      },
    },
    fetchPolicy: 'network-only',
    skip: !userId || !id,
  })

  const vettedAttorneys = vettedAttorneysData?.attorney?.map(attorney => ({
    id: attorney?.id,
    userId: attorney?.user_id?.id,
    first_name: attorney?.profiles?.[0]?.first_name,
    last_name: attorney?.profiles?.[0]?.last_name,
    avatar_id: attorney?.user_id?.avatar?.id,
    is_vetted: attorney?.is_vetted,
    is_qualifed: attorney?.membership_type?.id === MEMBERSHIP_TYPES.Qualified,
  }))

  const isAdmin = USER_ADMINS_ROLES.includes(userMe?.users_me?.role?.id || '')
  const isCaseManager =
    userMe?.users_me?.role?.id === USER_ROLES.CaseManagerUser

  const loading = vettedAttorneysLoading

  return {
    loggedUserId: userId,
    vettedAttorneys,
    isAdmin,
    isCaseManager,
    loading,
    error: vettedAttorneysError,
  }
}

export default useCaseManager

import MUIListItem from '@mui/material/ListItem'
import { styled } from '@mui/material/styles'

export const ListItem = styled(MUIListItem, {
  shouldForwardProp: prop => prop !== 'reverseRow',
})<{
  reverseRow: boolean
}>`
  padding: 0;
  flex-direction: ${({ reverseRow }) => (reverseRow ? 'row-reverse' : 'row')};
  gap: ${({ theme }) => theme.spacing(1)};
`

import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import FilterListIcon from '@mui/icons-material/FilterList'
import Search from '@/components/Search'
import { ConfirmationModal } from '@/components'
import { useFilters } from './useFilters'

export const Filters = () => {
  const {
    applyFilters,
    caseTypes,
    clearFilters,
    counterparts,
    filters,
    isLessMd,
    jurisdictions,
    loading,
    modalOpen,
    onChangeFilters,
    searchProps,
    setModalOpen,
  } = useFilters()

  const renderFilters = () => (
    <>
      <Autocomplete
        sx={{
          mr: 2,
          mb: {
            xs: 2.5,
            md: 0,
          },
          width: '100%',
          minWidth: '150px',
          maxWidth: { xs: 'auto', md: '250px' },
        }}
        value={caseTypes.find(item => item.name === filters.caseType) || null}
        options={caseTypes}
        getOptionLabel={option => option?.name || ''}
        onChange={(_, optionData) =>
          onChangeFilters({ caseType: optionData?.name || '' })
        }
        renderInput={params => (
          <TextField {...params} variant="outlined" label="Case Type" />
        )}
        disabled={loading}
      />
      <Autocomplete
        sx={{
          mr: 2,
          mb: {
            xs: 2.5,
            md: 0,
          },
          width: '100%',
          minWidth: '150px',
          maxWidth: { xs: 'auto', md: '250px' },
        }}
        value={
          jurisdictions.find(item => item.name === filters.jurisdiction) || null
        }
        options={jurisdictions}
        getOptionLabel={option => option?.name || ''}
        onChange={(_, optionData) =>
          onChangeFilters({ jurisdiction: optionData?.name || '' })
        }
        renderInput={params => (
          <TextField {...params} variant="outlined" label="Jurisdiction" />
        )}
        disabled={loading}
      />
      <Autocomplete
        sx={{
          mb: {
            xs: 2.5,
            md: 0,
          },
          width: '100%',
          minWidth: '150px',
          maxWidth: { xs: 'auto', md: '250px' },
        }}
        value={
          counterparts.find(item => item.id === filters.counterpart) || null
        }
        options={counterparts}
        getOptionLabel={option => option?.name || ''}
        onChange={(_, optionData) =>
          onChangeFilters({ counterpart: optionData?.id?.toString() || '' })
        }
        renderInput={params => (
          <TextField {...params} variant="outlined" label="Counterpart" />
        )}
        disabled={loading}
      />
    </>
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: {
          xs: 'space-between',
          md: 'flex-start',
        },
        mt: {
          xs: 1.5,
          md: 4,
        },
        mb: {
          xs: 2.5,
          md: 4,
        },
      }}
    >
      <Search
        {...searchProps}
        sx={{ width: '100%', minWidth: '200px', mr: 1 }}
      />
      {!isLessMd ? (
        renderFilters()
      ) : (
        <>
          <Button onClick={() => setModalOpen(true)}>
            <FilterListIcon />
          </Button>
          {modalOpen && (
            <ConfirmationModal
              open={modalOpen}
              title="Filter Cases"
              AcceptButtonProps={{
                children: 'Apply Filters',
                onClick: applyFilters,
              }}
              onClose={() => setModalOpen(false)}
              fullScreen
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  mt: 2,
                }}
              >
                {renderFilters()}
                <Button
                  variant="text"
                  onClick={clearFilters}
                  size="small"
                  sx={{ width: 'fit-content', alignSelf: 'center' }}
                >
                  Clear filters
                </Button>
              </Box>
            </ConfirmationModal>
          )}
        </>
      )}
    </Box>
  )
}

import { useGetOrganizationIdQuery } from '@/gql/systemApi'

const useOrganizationId = () => {
  const { data: organization, loading, error } = useGetOrganizationIdQuery()

  return {
    id: organization?.users_me?.current_organization?.[0]?.organization_id?.id,
    loading,
    error,
  }
}

export default useOrganizationId

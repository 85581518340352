import { ALPHANUMERIC_REGEXP } from '@/constants'
import { object, string } from 'yup'

const signInSchema = object({
  email: string()
    .email()
    .nonNullable()
    .required('Please enter a valid email address'),
  password: string()
    .min(8)
    .max(25)
    .matches(ALPHANUMERIC_REGEXP, 'Must be alphanumeric')
    .nonNullable()
    .required('Please enter a password'),
}).required()

const defaultValues = {
  email: '',
  password: '',
}

export { defaultValues, signInSchema }

// import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

// import { useCaseMessageTrigger } from '@/components/GetStreamChat/useCaseMessageTrigger'
// import { useContext } from 'react'
// import { ReferralContext } from './ReferralContext'

export const CaseManagerPermissionRequest = () => (
  // const { caseEntry } = useContext(ReferralContext)
  // const { handleTriggerClick } = useCaseMessageTrigger()

  // const handleButtonClick = () => {
  //  handleTriggerClick({
  //    membersToMessage: [
  //      caseEntry.attorney.user_id,
  //      ...(caseEntry.ownerships ?? []),
  //    ],
  //  })
  // }

  <Box
    display="flex"
    flexDirection={{ xs: 'column', md: 'row' }}
    p={2}
    justifyContent="center"
    alignItems="baseline"
    gap={2}
    textAlign="center"
  >
    <Typography m={{ xs: 'auto', md: 0 }}>
      <Typography
        component="strong"
        display={{ xs: 'block', md: 'inline' }}
        fontWeight="bold"
        mb={{ xs: 1, md: 0 }}
      >
        Attorney permission is required.
      </Typography>{' '}
      <Typography component="span">
        Ask the attorney for approval to act on their behalf for this case.
      </Typography>
    </Typography>
    {/* TODO: Uncomment this as part of https://attorney.atlassian.net/browse/MP-2617
      <Button
        variant="outlined"
        onClick={handleButtonClick}
        sx={{ m: { xs: 'auto', md: 0 } }}
      >
        Ask for Permission
      </Button>
*/}
  </Box>
)

import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Typography from '@mui/material/Typography'

import { styled } from '@mui/material'

interface ICheckboxLabelProps {
  error?: boolean
}
interface ICheckboxLabelControlProps {
  isCentered?: boolean
}

const CheckboxLabelControl = styled(FormControlLabel, {
  shouldForwardProp: prop => prop !== 'isCentered',
})<ICheckboxLabelControlProps>(({ isCentered = false }) => ({
  display: 'flex',
  alignItems: isCentered ? 'center' : 'flex-start',
  marginLeft: 0,
  marginRight: 0,
}))

const CheckboxLabel = styled(Typography, {
  shouldForwardProp: prop => prop !== 'error',
})<ICheckboxLabelProps>(({ theme, error = false }) => ({
  color: error ? theme.palette.error.main : theme.palette.text.primary,
  textAlign: 'left',
  ...theme.typography.body1,

  '& a': {
    color: error ? theme.palette.error.main : theme.palette.primary.main,
  },
}))

const CheckboxLabelError = styled(FormHelperText)`
  margin-bottom: 0.5rem;
  margin-left: 3rem;
  margin-top: 0.25rem;
`

export { CheckboxLabel, CheckboxLabelControl, CheckboxLabelError }

import { useSnackBar } from '@/hooks/useSnackBar'
import { Dispatch, SetStateAction } from 'react'

import {
  useRemoveIntegrationItem,
  useSetupLitify,
} from '@/utils/api/integrations'
import textContent from '../../constants'
import { connectIntegration, sanitizedInstanceUrl } from '../shared/utils'

export interface UseLitifyProps {
  code_challenge: string
  code: string
  instance_url: string
  connectLitify: boolean
  litifyRemoveStatus: boolean
  organizationId?: string
  storageInstanceUrl?: string | null
  storageCodeVerifier?: string
  setInstanceUrl: Dispatch<SetStateAction<string>>
}

const useLitifyIntegrationItem = ({
  code_challenge,
  code,
  instance_url,
  connectLitify,
  litifyRemoveStatus,
  organizationId,
  storageInstanceUrl,
  storageCodeVerifier,
  setInstanceUrl,
}: UseLitifyProps) => {
  const { INTEGRATION_V2 } = textContent
  const { alert, showAlert, handleClose } = useSnackBar()

  const connectLitifyIntegration = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const instanceUrl = sanitizedInstanceUrl(instance_url)

    return connectIntegration({
      event,
      instanceUrl,
      codeChallenge: code_challenge,
      oauthUrl: import.meta.env.VITE_LCMS_LITIFY_OAUTH_URL,
      showAlert,
      setInstanceUrl,
    })
  }

  const { data: removeLitifyData, isLoading: removeLitifyIsLoading } =
    useRemoveIntegrationItem(String(organizationId), litifyRemoveStatus)

  const config = {
    code,
    code_verifier: String(storageCodeVerifier),
    instance_url: String(storageInstanceUrl),
  }

  const {
    data: connectLitifyIntegrationData,
    isLoading: isLitifyIntegrationLoading,
  } = useSetupLitify(
    {
      organization_id: String(organizationId),
      lcms: INTEGRATION_V2.LITIFY.NAME.toLowerCase(),
      config,
    },
    litifyRemoveStatus,
    connectLitify
  )

  return {
    connectLitifyIntegration,
    connectLitifyIntegrationData,
    isLitifyIntegrationLoading,
    removeLitifyData,
    removeLitifyIsLoading,
    alert,
    handleClose,
    showAlert,
  }
}

export default useLitifyIntegrationItem

import Grid from '@mui/material/Grid'

import Typography from '@mui/material/Typography'

import JAvatar from '@/components/atoms/JAvatar/'
import Box from '@mui/material/Box'
import { useSizes } from '@/hooks/useSizes'
import { getImagePath } from '@/utils'
import has from 'lodash/has'
import { generatePath, useNavigate } from 'react-router-dom'
import { RoutesData } from '@/router/RoutesData'
import isUndefined from 'lodash/isUndefined'
import { IMAGE_SIZES } from '@/constants'
import CaseMessageButton from '@/components/GetStreamChat/CaseMessageButton'

type AttorneyRelationType =
  | 'on-behalf-of'
  | 'counterpart'
  | 'attorney'
  | 'case-owner'
  | ''

interface AttorneyRelationProps {
  caseOwner: any
  showSendMessage?: boolean
  relationshipType?: AttorneyRelationType
  title?: string
}

const AttorneyCase = ({
  caseOwner,
  showSendMessage = false,
  relationshipType = '',
  title,
}: AttorneyRelationProps) => {
  const { isLessMd } = useSizes()
  const navigate = useNavigate()

  if (isUndefined(caseOwner)) return null

  const { firstName, lastName, avatar, isVetted, isQualified, organization } =
    caseOwner

  const fullName = `${firstName} ${lastName}`
  const avatarSrc = getImagePath({ id: avatar, size: IMAGE_SIZES.Small })

  const renderText = (type: AttorneyRelationType) => {
    if (title) {
      return title
    }

    switch (type) {
      case 'on-behalf-of':
        return 'On behalf of'
      case 'counterpart':
        return 'Counterpart'
      case 'case-owner':
        return 'Case Owner'
      default:
        return 'Attorney'
    }
  }

  const onClickProfile = () => {
    const path = generatePath(RoutesData.RProfile.path, {
      id: caseOwner?.id,
    })
    navigate(path)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          variant="caption"
          display="block"
          color="text.disabled"
          sx={{
            marginBottom: '.5rem',
          }}
        >
          {renderText(relationshipType)}
        </Typography>
      </Grid>
      <Box
        sx={{
          display: isLessMd ? 'flex' : 'block',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={onClickProfile}
        >
          <JAvatar
            src={avatarSrc}
            sx={{
              avatar: {
                width: 48,
                height: 48,
              },
            }}
            alt={fullName}
            isVetted={isVetted}
            isQualified={isQualified}
          />
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 700, align: 'left' }}>
              {fullName}
            </Typography>
            <Typography variant="body2">{organization}</Typography>
          </Box>
        </Box>
        {showSendMessage && has(caseOwner, 'id') ? (
          <CaseMessageButton
            sx={{
              marginTop: isLessMd ? 0 : 2,
              width: isLessMd ? 'auto' : '100%',
            }}
          />
        ) : null}
      </Box>
    </Grid>
  )
}

export default AttorneyCase

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useSizes } from '@/hooks/useSizes'
import { useNavigate } from 'react-router-dom'
import LogoBar from '@/pages/CreateAccount/shared/LogoBar'

const InvalidExpiredLink = ({ isExpired }: { isExpired?: boolean }) => {
  const { isLessMd } = useSizes()
  const navigate = useNavigate()

  const goToSignIn = () => {
    navigate('/signIn')
  }

  const title = isExpired ? 'Your recovery link has expired.' : 'Invalid Link'
  const subtitle = isExpired
    ? 'It looks like your link has expired. Please retry the process to reset your password.'
    : 'The link provided is invalid. Please reach out to our Help Center for assistance in resolving this issue.'

  return (
    <>
      <LogoBar />
      <Box
        sx={theme => ({
          marginTop: {
            xs: 0,
          },
          maxWidth: {
            md: theme.spacing(74),
          },
          '&::-webkit-scrollbar': { display: 'none' },
        })}
      >
        <Typography
          variant={isLessMd ? 'h5' : 'h3'}
          sx={{ mb: 5, textAlign: 'left', pl: { xs: 2, md: 0 } }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{ textAlign: 'left', pl: { xs: 2, md: 0 } }}
        >
          {subtitle}
        </Typography>
        <Box
          sx={{
            mt: { xs: 5, md: 10 },
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-end' },
          }}
        >
          <Button
            onClick={goToSignIn}
            size="large"
            variant="contained"
            sx={{ width: 290 }}
          >
            Back to Sign In
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default InvalidExpiredLink

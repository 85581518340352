/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef, useState } from 'react'
import {
  TCreateProfileEducationForm,
  educationDefaultValues,
} from '@/pages/CreateProfile/Education/types'
import useUpdateAttorneyProfile from '@/pages/CreateProfile/hooks/useUpdateAttorneyProfile'
import useAttorneyId from '@/hooks/useAttorneyId'
import {
  TCreateOrganizationProfile,
  profileDefaultValues,
} from '@/pages/CreateAccount/CreateOrganization/types'
import useAttorneyEmail from '@/hooks/useAttorneyEmail'
import useOrganization from '@/hooks/useOrganization'
import { getSignInRedirectionByRole } from '@/pages/SignIn/utils'
import { useGetDelegatedUsersByCaseManagerLazyQuery } from '@/gql/systemApi'
import get from 'lodash/get'
import { PAYMENT_WALL_ENABLED } from '@/constants'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import { useStripe } from '@/hooks/useStripe'
import { communicationPreferencesDefaultValues } from '@/components/CommunicationPreferences/hooks/useCommunicationPreferences'
import { TCommunicationPreferencesFormValues } from '@/components/CommunicationPreferences/types'
import useCreateOrgProfileFlow from './useCreateOrgProfileFlow'

export type TOrgProfileFormState = {
  practiceAreas: string[]
  education: TCreateProfileEducationForm
  profile: TCreateOrganizationProfile
  communicationPreferences: TCommunicationPreferencesFormValues
}

interface IUseOrganizationProfile {
  hasLicense: boolean
}

export const useOrganizationProfile = ({
  hasLicense,
}: IUseOrganizationProfile) => {
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = useRef(getAttorneyId())
  const { getAttorneyEmail } = useAttorneyEmail()
  const attorneyEmail = useRef(getAttorneyEmail())
  const { updateAttorneyProfile, loading: profileLoading } =
    useUpdateAttorneyProfile()
  const {
    updateOrganizationUser,
    getOrganizationData,
    loading: orgLoading,
  } = useOrganization()
  const [error, setError] = useState('')
  const [paymentLoading, setIsPaymentLoading] = useState(false)
  const { state, next, back, goToPayment, goToCommunicationPreferences } =
    useCreateOrgProfileFlow({
      hasLicense,
    })
  const [formState, setFormState] = useState<TOrgProfileFormState>({
    practiceAreas: [],
    education: educationDefaultValues,
    profile: profileDefaultValues,
    communicationPreferences: communicationPreferencesDefaultValues,
  })
  const [getUserMeWithRoleData] = useGetDelegatedUsersByCaseManagerLazyQuery()
  const { createCheckoutSubscriptionSession } = useStripe()

  const loading = profileLoading || orgLoading

  const updateFormState = (
    key: keyof TOrgProfileFormState,
    value: TOrgProfileFormState[keyof TOrgProfileFormState]
  ) => {
    setFormState(prevState => ({ ...prevState, [key]: value }))
  }

  const submitAndContinue = async (
    key: keyof TOrgProfileFormState,
    data: any
  ) => {
    updateFormState(key, data)
    next()
  }

  const handleOnSkip = useCallback(async () => {
    if (PAYMENT_WALL_ENABLED) {
      goToPayment()
    } else {
      goToCommunicationPreferences()
    }
  }, [goToPayment, goToCommunicationPreferences])

  const handleProfileSubmit = useCallback(
    async (key?: keyof TOrgProfileFormState, data?: any) => {
      let finalData = cloneDeep(formState)

      if (key && data) {
        finalData = merge(formState, { [key]: data })
      }

      const updateResult = await updateAttorneyProfile({
        practiceAreas: finalData.practiceAreas,
        education: finalData.education,
        personalInfo: {
          bio: finalData.profile.bio || '',
        },
        communicationPreferences: finalData.communicationPreferences,
      })

      if (updateResult.id) {
        if (finalData.profile.position) {
          const organizationDataResult = await getOrganizationData()
          if (!organizationDataResult.organizationUserItemId) {
            setError(
              organizationDataResult?.error || 'Organization User ID not found'
            )
            return
          }
          const updateOrgUserResult = await updateOrganizationUser({
            organizationUserItemId:
              organizationDataResult.organizationUserItemId || '',
            positionName: finalData.profile.position,
            positionBio: finalData.profile.bio || '',
          })
          if (updateOrgUserResult.updateOrganizationUserErrors) {
            setError(updateOrgUserResult.updateOrganizationUserErrors.base)
            return
          }
        }
        if (PAYMENT_WALL_ENABLED) {
          setIsPaymentLoading(true)
          const userMe = await getUserMeWithRoleData()
          if (attorneyId.current && attorneyEmail.current) {
            await createCheckoutSubscriptionSession({
              attorney_id: attorneyId.current,
              price_type: 'subscription',
              customer_email: attorneyEmail.current,
              redirect_url: getSignInRedirectionByRole(
                get(userMe, 'data.users_me.role.id', '')
              ),
            })
          } else {
            setError('Attorney ID or Email not found')
          }
        }
        setIsPaymentLoading(false)
      }
      if (updateResult.updateAttorneyProfileErrors) {
        setError(updateResult.updateAttorneyProfileErrors.base)
      }
    },
    [
      formState,
      getOrganizationData,
      getUserMeWithRoleData,
      updateAttorneyProfile,
      updateOrganizationUser,
      createCheckoutSubscriptionSession,
    ]
  )

  return {
    state,
    formState,
    next,
    back,
    handleOnSkip,
    updateFormState,
    submitAndContinue,
    loading,
    handleProfileSubmit,
    paymentLoading,
    setError,
    error,
  }
}

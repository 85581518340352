import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useSizes } from '@/hooks/useSizes'

type VerificationAlertProps = {
  onResendVerificationEmail: () => void
  onCloseAlert: () => void
}

const VerificationAlert = ({
  onResendVerificationEmail,
  onCloseAlert,
}: VerificationAlertProps) => {
  const { isLessMd } = useSizes()

  return (
    <Alert
      {...(isLessMd && {
        icon: false,
      })}
      variant="standard"
      severity="error"
      onClose={onCloseAlert}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={onCloseAlert}
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      sx={({ spacing, palette }) => ({
        background: palette.error.light,
        position: 'relative',
        pr: spacing(2),
        alignItems: 'flex-start',
      })}
    >
      <Typography variant="subtitle1" color="error.dark">
        Your email hasn&apos;t been verified yet.
      </Typography>
      <Typography variant="subtitle2" color="error.dark" sx={{ mt: 2 }}>
        Please check your email inbox for a message with the subject line
        &quot;AttorneyShare Email verification required&quot;.
      </Typography>
      <Typography variant="body2" color="error.dark" sx={{ mt: 2 }}>
        Don&apos;t forget to check your spam folder as well. If you can&apos;t
        find the email, you can{' '}
        <button
          style={{
            background: 'none',
            border: 'none',
            padding: 0,
            color: 'inherit',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={onResendVerificationEmail}
          type="button"
        >
          resend the verification email
        </button>{' '}
        to complete the process.
      </Typography>
    </Alert>
  )
}

export default VerificationAlert

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useContext, useState } from 'react'
import useTheme from '@mui/material/styles/useTheme'
import { PhoneNumber } from '@/components/PhoneNumber/PhoneNumber'
import CommentOutlined from '@mui/icons-material/CommentOutlined'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { useCaseMessageTrigger } from '@/components/GetStreamChat/useCaseMessageTrigger'
import { useSizes } from '@/hooks/useSizes'
import {
  CASE_PURCHASE_STATUS,
  CASE_STATUS_FLOW,
  FEE_AGREEMENT_STATUS,
} from '@/constants'
import FeeAgreements from '@/pages/Case/components/FeeAgreements/FeeAgreements'
import { Case_File } from '@/gql/systemApi'
import { FeeAgreementHeader } from '@/pages/Case/components/FeeAgreements/FeeAgreementHeader'
import { ReferralContext } from './ReferralContext'
import { ReferralAttorneyAvatar } from './ReferralAttorneyAvatar'
import { ClientSignatureConfirmation } from './ClientSignatureConfirmation'
import { MyReferralsContext } from '../MyReferralsContext'

interface ContactInformationProps {
  showCounterpart?: boolean
  showInAccordion?: boolean
}

export const ContactInformation = ({
  showCounterpart,
  showInAccordion,
}: ContactInformationProps) => {
  const theme = useTheme()
  const [isExpanded, setIsExpanded] = useState(false)
  const { isLessSm, isLessMd } = useSizes()
  const { refetch } = useContext(MyReferralsContext)
  const { caseEntry, counterpart, isUserSeller } = useContext(ReferralContext)
  const { handleTriggerClick } = useCaseMessageTrigger()

  const handleExpandToggleClick = () => {
    setIsExpanded(!isExpanded)
  }

  const handleMessageButtonClick = () => {
    if (!counterpart) {
      throw new TypeError(`counterpart is undefined`)
    }

    handleTriggerClick({
      membersToMessage: [counterpart.user_id, ...(caseEntry.ownerships ?? [])],
    })
  }

  const columnSpacing = 6

  if (!showCounterpart && caseEntry.leads.length === 0) {
    return null
  }

  const [casePurchase] = caseEntry.case_purchase

  const isOfferAccepted = casePurchase?.status === CASE_PURCHASE_STATUS.Accept
  const isCaseMatched = caseEntry.status === CASE_STATUS_FLOW.Matched
  const isCaseSigned = caseEntry.status === CASE_STATUS_FLOW.Signed

  const showSignatureConfirmation =
    isOfferAccepted && isCaseMatched && !isUserSeller

  const signedCasePurchase =
    casePurchase?.status === CASE_PURCHASE_STATUS.Signed

  const showFeeAgreement =
    signedCasePurchase &&
    Boolean(
      casePurchase?.fee_agreement_status !==
        FEE_AGREEMENT_STATUS.CreatedPriorToFeeAgreements
    )

  const feeAgreementFiles =
    casePurchase?.case_purchase_activities.files?.flatMap(file =>
      Object.values(file).filter(
        filteredFile =>
          filteredFile.metadata.is_fee_agreement &&
          filteredFile.metadata.case_purchase_id === casePurchase?.id
      )
    ) ?? []

  const isFeeAgreementSigned = [
    String(FEE_AGREEMENT_STATUS.FeeAgreementUploaded),
    String(FEE_AGREEMENT_STATUS.SignedAndWillNotBeUploaded),
  ].includes(casePurchase?.fee_agreement_status ?? '')

  const handleRefetch = async () => {
    await refetch()
  }

  const feeAgreements = showFeeAgreement && (
    <>
      {isLessMd && (
        <Grid item xs ml="auto">
          <FeeAgreementHeader
            isSigned={isFeeAgreementSigned}
            signedStatus={
              isFeeAgreementSigned ? 'Agreement Signed' : 'Not Signed yet'
            }
            titleTypographyProps={{
              sx: {
                textTransform: 'none',
                color: theme.palette.text.disabled,
              },
              variant: 'button',
            }}
            statusTypographyProps={{
              sx: {
                textTransform: 'none',
              },
              variant: 'button',
            }}
            sx={{
              alignItems: 'end',
              ml: 'auto',
              minHeight: theme.spacing(3),
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} md={4} ml="auto">
        <FeeAgreements
          caseId={caseEntry.id}
          casePurchaseId={casePurchase?.id}
          feeAgreementFiles={feeAgreementFiles.map(
            ({ id, filename_download }): Case_File => ({
              // NOTE: This isn't actually used, but it is needed to conform to the type definition
              id,
              file_id: {
                id,
                filename_download,
                // NOTE: This isn't actually used, but it is needed to conform to the type definition
                storage: '',
              },
            })
          )}
          isOwner={isUserSeller}
          feeAgreementStatus={casePurchase?.fee_agreement_status ?? null}
          showHeader={false}
          onRefetch={handleRefetch}
          sx={{
            mt: 0,
            py: 0,
            border: 'none',
          }}
          verticalFileUploader
        />
      </Grid>
    </>
  )

  const counterpartInfo = showCounterpart && counterpart && (
    <Grid item xs={12} md={2.5}>
      <ReferralAttorneyAvatar attorney={counterpart} showOrganization />

      <Box mt={2} pl={6}>
        <Button
          variant="outlined"
          sx={{ background: theme.palette.common.white }}
          onClick={handleMessageButtonClick}
          size="small"
        >
          <CommentOutlined sx={{ fontSize: theme.spacing(1.5), mr: 1 }} />
          Message
        </Button>
      </Box>
    </Grid>
  )

  const leadsInfo = (
    <>
      {caseEntry.leads.length > 0 && isLessMd && (
        <Grid item xs={12}>
          <Typography
            variant="button"
            textTransform="none"
            color={theme.palette.text.disabled}
          >
            Client Contact Info
          </Typography>
        </Grid>
      )}
      {caseEntry.leads.map(({ first_name, last_name, email, phone }) => (
        <Grid
          key={`${email}_${first_name}_${last_name}_${phone}`}
          item
          xs={12}
          sm={6}
          md={2.5}
        >
          <Typography variant="subtitle1" fontSize={theme.spacing(1.75)}>
            {first_name} {last_name}
          </Typography>
          {typeof email === 'string' && (
            <Typography
              variant="body2"
              color={theme.palette.text.secondary}
              mb={0.5}
            >
              {email}
            </Typography>
          )}
          {typeof phone === 'string' && (
            <Typography
              variant="body2"
              color={theme.palette.text.secondary}
              mb={0.5}
            >
              <PhoneNumber>{phone}</PhoneNumber>
            </Typography>
          )}
        </Grid>
      ))}
    </>
  )

  const clientSignature = showSignatureConfirmation && casePurchase && (
    <Grid
      item
      xs
      display="flex"
      justifyContent={{ xs: 'center', md: 'flex-end' }}
    >
      <ClientSignatureConfirmation casePurchase={casePurchase} />
    </Grid>
  )

  const showLeadsFirst =
    isLessSm && !isUserSeller && (isCaseMatched || isCaseSigned)

  const content = (
    <>
      <Grid container columnSpacing={columnSpacing} my={1}>
        {showCounterpart && counterpart && !isLessMd && (
          <Grid item xs={12} md={2.5}>
            <Typography
              variant="button"
              textTransform="none"
              color={theme.palette.text.disabled}
              fontSize={theme.spacing(1.5)}
            >
              Attorney Contact Info
            </Typography>
          </Grid>
        )}
        {caseEntry.leads.length > 0 && !isLessMd && (
          <Grid item xs>
            <Typography
              variant="button"
              textTransform="none"
              color={theme.palette.text.disabled}
              fontSize={theme.spacing(1.5)}
            >
              Client Contact Info
            </Typography>
          </Grid>
        )}
        {showFeeAgreement && !isLessMd && (
          <FeeAgreementHeader
            isSigned={isFeeAgreementSigned}
            signedStatus={
              isFeeAgreementSigned ? 'Agreement Signed' : 'Not Signed yet'
            }
            titleTypographyProps={{
              sx: {
                textTransform: 'none',
                color: theme.palette.text.disabled,
                fontSize: theme.spacing(1.5),
              },
              variant: 'button',
            }}
            statusTypographyProps={{
              sx: {
                textTransform: 'none',
                fontSize: theme.spacing(1.5),
              },
              variant: 'button',
            }}
            sx={{
              alignItems: 'end',
              ml: 'auto',
            }}
            component={Grid}
            {...{
              item: true,
              xs: 12,
              md: 4,
            }}
          />
        )}
      </Grid>
      <Grid container columnSpacing={columnSpacing} rowGap={2} pb={2}>
        {showLeadsFirst ? (
          <>
            {leadsInfo}
            {clientSignature}
            {counterpartInfo}
            {feeAgreements}
          </>
        ) : (
          <>
            {counterpartInfo}
            {leadsInfo}
            {clientSignature}
            {feeAgreements}
          </>
        )}
      </Grid>
    </>
  )

  if (showInAccordion) {
    return (
      <Accordion
        expanded={isExpanded}
        disableGutters
        elevation={0}
        square
        sx={{
          background: 'none',
          '&::before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary
          onClick={handleExpandToggleClick}
          sx={{
            flexDirection: 'row-reverse',
            px: 1,
            py: 2,
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              transform: 'rotate(180deg)',
            },
            '& .MuiAccordionSummary-content': {
              my: 0.5,
            },
          }}
          expandIcon={
            <KeyboardArrowDown
              sx={{
                cursor: 'pointer',
              }}
            />
          }
        >
          <Typography variant="subtitle2" paddingLeft={1}>
            Leads Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>{content}</AccordionDetails>
      </Accordion>
    )
  }

  return <Box>{content}</Box>
}

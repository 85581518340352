import { useMemo } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { useSizes } from '@/hooks/useSizes'

import NoVettedOptions from '@/pages/Case/components/NoVettedOptions/NoVettedOptions'

import CaseMobileTopOptions from '@/pages/Case/components/Sidebar/CaseSidebar/CaseMobileTopOptions'
import { ISidebarProps } from '../CaseSidebar/types'

const MobileTopOptions = ({
  showCaseOptions,
  sidebarProps,
}: {
  showCaseOptions: boolean
  sidebarProps: ISidebarProps
}) => {
  const { screenSize } = useSizes()
  const mobileLayout = screenSize !== 'desktop'

  const Content = useMemo(() => {
    if (
      !sidebarProps ||
      (!showCaseOptions &&
        (sidebarProps.isVetted || sidebarProps.isQualified)) ||
      sidebarProps?.optionsLoading
    ) {
      return (
        <CircularProgress
          sx={{
            alignSelf: 'center',
            justifySelf: 'center',
          }}
        />
      )
    }

    if (showCaseOptions) {
      return <CaseMobileTopOptions {...sidebarProps} />
    }

    return null
  }, [showCaseOptions, sidebarProps])

  if (!mobileLayout) return null

  return Content ? (
    <Grid container>
      <Paper
        variant="outlined"
        square
        sx={{
          border: '0',
          background: 'none',
          width: '100%',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Stack spacing={2}>{Content}</Stack>
        </Box>
      </Paper>
    </Grid>
  ) : (
    <NoVettedOptions {...sidebarProps} />
  )
}

export default MobileTopOptions

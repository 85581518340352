import {
  useRemoveIntegrationItem,
  useSetupLawmatics,
} from '@/utils/api/integrations'
import textContent from '../../constants'

export interface UseLawmaticsProps {
  code: string
  connectLawmatics: boolean
  lawmaticsRemoveStatus: boolean
  organizationId?: string
}

const useLawmaticsIntegrationItem = ({
  code,
  connectLawmatics,
  lawmaticsRemoveStatus,
  organizationId,
}: UseLawmaticsProps) => {
  const { INTEGRATION_V2 } = textContent

  const connectLawmaticsIntegration = () => {
    const url = import.meta.env.VITE_LCMS_LAWMATICS_OAUTH_URL
    window.open(url, '_self', 'noopener,noreferrer')
  }

  const { data: removeLawmaticsData, isLoading: removeLawmaticsIsLoading } =
    useRemoveIntegrationItem(String(organizationId), lawmaticsRemoveStatus)

  const config = {
    code,
  }

  const {
    data: connectLawmaticsIntegrationData,
    isLoading: isLawmaticsIntegrationLoading,
  } = useSetupLawmatics(
    {
      organization_id: String(organizationId),
      lcms: INTEGRATION_V2.LAWMATICS.NAME.toLowerCase(),
      config,
    },
    lawmaticsRemoveStatus,
    connectLawmatics
  )

  return {
    connectLawmaticsIntegration,
    connectLawmaticsIntegrationData,
    isLawmaticsIntegrationLoading,
    removeLawmaticsData,
    removeLawmaticsIsLoading,
  }
}

export default useLawmaticsIntegrationItem

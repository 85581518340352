import { ResponseModal, NonVettedModal, SendProposalModal } from '@/components'
import Box from '@mui/material/Box'

import Stack from '@mui/material/Stack'
import SidebarMessage from '@/pages/Case/components/Sidebar/components/SidebarMessage'
import AllowCaseManagers from '@/pages/Case/components/Sidebar/components/AllowCaseManagers'

import { getFullname } from '@/utils/profile'
import { JCaseType, ProfileType } from '@/types'
import BulkInviteToCaseModal from '@/components/Modals/BulkInviteToCaseModal'

import PublicCasesButtons from '@/pages/Case/components/PublicCasesButtons/PublicCasesButtons'
import PrivateCasesButtons from '@/pages/Case/components/PrivateCasesButtons/PrivateCasesButtons'
import OwnerCaseButtons from '@/pages/Case/components/OwnerCaseButtons/OwnerCaseButtons'
import AgreementCaseButtons from '@/pages/Case/components/AgreementCaseButtons/AgreementCaseButtons'
import {
  MP_VISIBILITY,
  CASE_ACCESS_TYPES,
  IMAGE_SIZES,
  CONTACT_TYPES,
  FEE_AGREEMENT_STATUS,
} from '@/constants'
import { isQualifiedMembership } from '@/utils/membership'
import { useFeatureFlags } from '@/optimizely/useFeatureFlags'
import { FeatureFlag } from '@/optimizely/types'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import initApollo from '@/apollo'
import {
  GetActivityPurchaseDocument,
  GetCasesDocument,
  GetActivityPurchaseFromMyOrgDocument,
} from '@/gql/appApi'

import get from 'lodash/get'

import {
  getCaseManagerList,
  waterfallToUser,
} from '@/pages/Case/components/Sidebar/CaseSidebar/CaseSidebarUtils'
import MatchFeesModal from '@/components/Modals/MatchFeesModal'
import { ClientSignedConfirmationModal } from '@/components/Modals/ClientSignedConfirmationModal'
import AgreementRejectionConfirmationModal from '@/components/Modals/AgreementRejectionConfirmationModal'
import { isNil } from 'lodash'
import { CardContent } from '@mui/material'
import CaseMessageButton from '@/components/GetStreamChat/CaseMessageButton'
import { getImagePath } from '@/utils'
import AttorneyCase from '../components/AttorneyCase'
import FreePricingAlert from '../components/FreePricingAlert'
import Leads from '../../Leads/Leads'
import { ISidebarProps } from './types'
import SignatureCard from './SignatureCard'
import FeeAgreements from '../../FeeAgreements/FeeAgreements'

const CaseSidebar = (sidebarProps: ISidebarProps) => {
  const {
    accessType,
    actionSubtitle,
    actionTitle,
    agreementParams,
    attorneyId,
    caseActivity,
    caseInfo,
    caseIsTakenByMyOrg,
    caseOwner,
    caseOwnerWithOrg,
    chatCaseAttorneyId,
    currentUserAttorneyId,
    dataCase,
    genericModalOpen,
    getCaseInformationProps,
    getConfirmationModalProps,
    handleAgreementRejectionConfirmationModalClosed,
    handleApplyForMembership,
    handleClientRejected,
    handleClientRejectedSubmit,
    handleClientSigned,
    handleClientSignedConfirmationModalClose,
    handleClientSignedSubmit,
    hasAdminAccess,
    iamProspectAttorneyInExternalCase,
    iamProspectInCase,
    inviteToCaseModalOpen,
    isAdmin,
    isAdminAttorney,
    isAttorneyLastActivity,
    isAttorneyOwner,
    isCaseFromSomeoneInMyOrganization,
    isCaseManager,
    isCaseManagerWithAccess,
    isCaseManagerWithNoAccess,
    isCaseOwner,
    isCaseStatusFlowSimplificationEnabled,
    isMatched,
    isNonVettedAdminOrCaseMngWithJhqMembersInOrg,
    isPendingPayment,
    isPrivateCase,
    isQualified,
    isSameOrganization,
    isSigned,
    isVetted,
    leads,
    loadingCreateCaseMutation,
    loggedUserId,
    loggedUserOrganizationId,
    modalOpen,
    modalType,
    noRestrictedPermission,
    nonVettedModal,
    onBehalfOfAttorneyIdPendingPayment,
    onBehalfOfUserIdPendingPayment,
    onSubmit,
    onSubmitGenericModal,
    pendingPaymentFee,
    prospectAttorneyProfile,
    rejectedByCounterpart,
    rejectedModalOpen,
    responseModal,
    setGenericOpenModal,
    setInviteToCaseModalOpen,
    setOpenModal,
    setResponseModal,
    showAllowCaseManagerActions,
    showClientSigningConfirmation,
    showInvitationPricing,
    showNonVettedModal,
    signedModalOpen,
    vettedAttorneys,
    isInitiatingAttorney,
    matchFeeContentOnClientSigning,
    feeAgreementFiles,
    feeAgreementStatus,
  } = sidebarProps
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
  const featureFlags = useFeatureFlags()
  const isNegotiationFlowImprovementEnabled =
    featureFlags[FeatureFlag.NEGOTIATION_FLOW_IMPROVEMENTS]
  const caseId = caseInfo?.id
  const caseManagerListInCase = getCaseManagerList(
    isCaseOwner
      ? get(
          dataCase,
          'case_purchase_activity[0].case_purchase_id.case_id.ownerships',
          []
        )
      : get(
          dataCase,
          'case_purchase_activity[0].case_purchase_id.ownerships',
          []
        )
  )

  const waterfallProfile = waterfallToUser(
    get(
      dataCase,
      'case_purchase_activity[0].case_purchase_id.prospect_attorney_id.profiles[0]'
    )
  )
  const isPublicCase = MP_VISIBILITY.Public === caseInfo?.mp_visibility?.id

  let inviteToCaseOnBehalfOf: string | undefined
  let inviteToCaseOnBehalfOfUserId: string | undefined

  if (isAdminAttorney && currentUserAttorneyId !== chatCaseAttorneyId) {
    inviteToCaseOnBehalfOf = chatCaseAttorneyId
  }

  if (isCaseManager) {
    inviteToCaseOnBehalfOf = chatCaseAttorneyId
    inviteToCaseOnBehalfOfUserId = caseOwnerWithOrg?.userId
  }

  const isOwnerAsAdminOrCaseManager =
    accessType === CASE_ACCESS_TYPES.Owner &&
    (hasAdminAccess || isCaseManagerWithAccess)

  const showAgreements = agreementParams && showAllowCaseManagerActions

  const casePurchaseId =
    dataCase?.case_purchase_activity?.[0]?.case_purchase_id.id

  const isInvitedOrWaterfall =
    accessType === CASE_ACCESS_TYPES.Invited ||
    accessType === CASE_ACCESS_TYPES.Waterfall

  const handleRefetch = async () => {
    await initApollo().refetchQueries({
      include: [
        GetActivityPurchaseDocument,
        'case_purchase_activity',
        GetCasesDocument,
        'case',
        GetActivityPurchaseFromMyOrgDocument,
        'case_purchase_activity',
      ],
    })
  }

  if (isLargeScreen && isSigned && isCaseOwner) {
    return (
      <CardContent>
        <SignatureCard
          name={`${agreementParams?.profileToDisplay?.first_name} ${agreementParams?.profileToDisplay?.last_name}`}
          organization={String(
            agreementParams?.profileToDisplay?.attorney_id?.user_id
              ?.current_organization?.[0]?.organization_id?.profiles?.[0]?.name
          )}
          avatar={
            getImagePath({
              id:
                agreementParams?.profileToDisplay?.attorney_id?.user_id?.avatar
                  ?.id ?? '',
              size: IMAGE_SIZES.Small,
            }) || ''
          }
          email={String(
            agreementParams?.profileToDisplay?.attorney_id?.user_id?.email
          )}
          phone={
            agreementParams?.profileToDisplay?.contacts?.find(
              contact => contact?.type?.id === CONTACT_TYPES.PhoneContactType
            )?.value || ''
          }
          fee={caseInfo?.fee || 0}
          leads={leads ?? []}
        />
        {feeAgreementStatus !==
          FEE_AGREEMENT_STATUS.CreatedPriorToFeeAgreements && (
          <FeeAgreements
            caseId={caseInfo?.id}
            casePurchaseId={casePurchaseId}
            feeAgreementFiles={feeAgreementFiles}
            isOwner={isCaseOwner}
            feeAgreementStatus={feeAgreementStatus}
            onRefetch={handleRefetch}
          />
        )}
        <CaseMessageButton sx={{ mt: 2 }} />
        <AllowCaseManagers
          caseId={caseId}
          collaborators={caseManagerListInCase}
          loggedUserOrganizationId={loggedUserOrganizationId ?? ''}
          isFromSameOrganization={isCaseFromSomeoneInMyOrganization}
          isCaseOwner={isCaseOwner}
          casePurchaseId={casePurchaseId}
        />
      </CardContent>
    )
  }

  return (
    <>
      <Stack spacing={1.25} p={2}>
        {isLargeScreen && !showInvitationPricing && actionTitle && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex_start',
              gap: '0.5rem',
              alignSelf: 'stretch',
            }}
          >
            <SidebarMessage
              title={actionTitle}
              subtitle={actionSubtitle}
              finalizedAgreement={Boolean(
                showAgreements && agreementParams?.finalizedAgreement
              )}
              isMatched={isMatched && isCaseStatusFlowSimplificationEnabled}
              isSigned={isSigned && isCaseStatusFlowSimplificationEnabled}
            />
          </Box>
        )}
        {isLargeScreen && showInvitationPricing && (
          <FreePricingAlert isPrivate={isPrivateCase} />
        )}
        {isLargeScreen && iamProspectAttorneyInExternalCase && (
          <Leads leadInfo={leads} sx={{ pb: 2 }} />
        )}
        {feeAgreementStatus !==
          FEE_AGREEMENT_STATUS.CreatedPriorToFeeAgreements &&
          isLargeScreen &&
          isSigned &&
          !isCaseOwner && (
            <FeeAgreements
              caseId={caseInfo?.id}
              casePurchaseId={casePurchaseId}
              feeAgreementFiles={feeAgreementFiles}
              isOwner={isCaseOwner}
              feeAgreementStatus={feeAgreementStatus}
              onRefetch={handleRefetch}
            />
          )}
        {showAgreements ? (
          <AgreementCaseButtons
            profile={{
              avatar:
                agreementParams?.profileToDisplay?.attorney_id?.user_id?.avatar
                  ?.id || null,
              mainText:
                getFullname(agreementParams?.profileToDisplay as ProfileType) ||
                undefined,
              secondaryText:
                agreementParams?.profileToDisplay?.attorney_id?.user_id
                  ?.current_organization?.[0]?.organization_id?.profiles?.[0]
                  ?.name,
              isVetted: Boolean(
                agreementParams?.profileToDisplay?.attorney_id?.is_vetted
              ),
              isQualified: isQualifiedMembership(
                agreementParams?.profileToDisplay?.attorney_id?.membership_type
                  ?.id
              ),
            }}
            agreements={agreementParams}
            setGenericOpenModal={setGenericOpenModal}
            showClientSigningConfirmation={showClientSigningConfirmation}
            handleClientSigned={handleClientSigned}
            handleClientRejected={handleClientRejected}
            attorneyTitle={isCaseOwner ? undefined : 'Case Owner'}
            casePurchaseId={casePurchaseId}
            fee={isNegotiationFlowImprovementEnabled ? caseInfo?.fee || 0 : 0}
          />
        ) : (
          <>
            {(isAttorneyOwner || isOwnerAsAdminOrCaseManager) && (
              <OwnerCaseButtons
                setModalInviteToCaseOpen={setInviteToCaseModalOpen}
                hasAdminAccess={hasAdminAccess}
                iamProspectInCase={iamProspectInCase}
                hasCaseManagerAccess={isCaseManagerWithAccess}
                profile={caseOwnerWithOrg}
                waterfallProfile={waterfallProfile}
                isPrivateCase={isPrivateCase}
                fee={
                  isNegotiationFlowImprovementEnabled ? caseInfo?.fee || 0 : 0
                }
              />
            )}
            {isCaseManagerWithNoAccess && isCaseFromSomeoneInMyOrganization && (
              <AttorneyCase caseOwner={caseOwnerWithOrg} showSendMessage />
            )}
            {accessType === CASE_ACCESS_TYPES.NoAccess &&
              (!hasAdminAccess ||
                (hasAdminAccess && iamProspectAttorneyInExternalCase)) &&
              (noRestrictedPermission ? (
                <PublicCasesButtons
                  caseActivity={caseActivity}
                  isAttorneyLastActivity={
                    isAttorneyLastActivity || rejectedByCounterpart
                  }
                  accessType={accessType}
                  setOpenModal={setOpenModal}
                  isVetted={isVetted}
                  isQualified={isQualified}
                  setGenericOpenModal={setGenericOpenModal}
                  caseInfo={caseInfo}
                  showNonVettedModal={showNonVettedModal}
                  caseOwnerAttorneyId={caseOwner?.attorney_id?.id}
                  chatCaseAttorneyId={chatCaseAttorneyId}
                  isSameOrganization={isSameOrganization}
                  profile={caseOwnerWithOrg}
                  loading={loadingCreateCaseMutation}
                  isNonVettedAdminOrCaseMngWithJhqMembersInOrg={
                    isNonVettedAdminOrCaseMngWithJhqMembersInOrg
                  }
                  caseIsTakenByMyOrg={caseIsTakenByMyOrg}
                  prospectAttorneyProfile={prospectAttorneyProfile}
                  isPendingPayment={isPendingPayment}
                  attorneyTitle={isCaseOwner ? undefined : 'Case Owner'}
                  casePurchaseId={casePurchaseId}
                  fee={caseInfo?.fee || 0}
                />
              ) : (
                <AttorneyCase
                  caseOwner={prospectAttorneyProfile}
                  relationshipType="attorney"
                  showSendMessage
                />
              ))}
            {isInvitedOrWaterfall &&
              (!isCaseManagerWithNoAccess ? (
                <>
                  <PrivateCasesButtons
                    caseActivity={caseActivity}
                    isAttorneyLastActivity={
                      isAttorneyLastActivity || rejectedByCounterpart
                    }
                    setGenericOpenModal={setGenericOpenModal}
                    profile={caseOwnerWithOrg}
                    loading={loadingCreateCaseMutation}
                    attorneyTitle={isCaseOwner ? undefined : 'Case Owner'}
                    fee={caseInfo?.fee || 0}
                  />
                  {isLargeScreen && currentUserAttorneyId !== attorneyId && (
                    <AttorneyCase
                      caseOwner={prospectAttorneyProfile}
                      relationshipType="attorney"
                    />
                  )}
                </>
              ) : (
                isLargeScreen && (
                  <>
                    {isPublicCase && (
                      <AttorneyCase
                        caseOwner={caseOwnerWithOrg}
                        relationshipType="counterpart"
                      />
                    )}
                    <AttorneyCase
                      caseOwner={prospectAttorneyProfile}
                      relationshipType="attorney"
                      showSendMessage
                    />
                  </>
                )
              ))}
          </>
        )}
        {isLargeScreen &&
          ((accessType === CASE_ACCESS_TYPES.NoAccess &&
            iamProspectAttorneyInExternalCase &&
            (isCaseManagerWithAccess || hasAdminAccess)) ||
            ((hasAdminAccess || isCaseManagerWithAccess) &&
              showAgreements)) && (
            <AttorneyCase
              caseOwner={
                !iamProspectAttorneyInExternalCase
                  ? caseOwnerWithOrg
                  : prospectAttorneyProfile
              }
              relationshipType={
                hasAdminAccess && showAgreements ? 'attorney' : 'on-behalf-of'
              }
            />
          )}
        {isLargeScreen && showAllowCaseManagerActions && (
          <AllowCaseManagers
            caseId={caseId}
            collaborators={caseManagerListInCase}
            isFromSameOrganization={isCaseFromSomeoneInMyOrganization}
            loggedUserOrganizationId={loggedUserOrganizationId ?? ''}
            isCaseOwner={isCaseOwner}
            casePurchaseId={casePurchaseId}
          />
        )}
        {isLargeScreen && !iamProspectAttorneyInExternalCase && (
          <Leads leadInfo={leads} />
        )}
      </Stack>
      {nonVettedModal && (
        <NonVettedModal
          open={nonVettedModal}
          onClose={() => showNonVettedModal(true)}
          AcceptButtonProps={{
            onClick: () => {
              handleApplyForMembership()
              showNonVettedModal(false)
            },
          }}
        />
      )}
      {genericModalOpen && (
        <MatchFeesModal
          type={modalType}
          ConfirmationModalProps={getConfirmationModalProps()}
          CaseInformationProps={getCaseInformationProps()}
          onSubmit={onSubmitGenericModal}
          isCaseOwner={isCaseOwner}
          isInitiatingAttorney={isInitiatingAttorney}
        />
      )}
      {modalOpen.open &&
        modalOpen.jCase &&
        modalOpen.fee !== undefined &&
        modalOpen.fee !== null && (
          <SendProposalModal
            isInvited={accessType === CASE_ACCESS_TYPES.Invited}
            open={modalOpen.open}
            onClose={() =>
              setOpenModal({ open: false, jCase: null, fee: null })
            }
            onSubmit={onSubmit}
            jCase={modalOpen.jCase}
            fee={modalOpen.fee}
            vettedAttorneys={vettedAttorneys}
            isAdmin={isAdmin}
            isCaseManager={isCaseManager}
            isPublicCase={isPublicCase}
            onlyOnBehalf={isNonVettedAdminOrCaseMngWithJhqMembersInOrg}
            loggedUserId={loggedUserId}
            pendingPayment={{
              isPendingPayment,
              casePurchaseId,
              onBehalfOfAttorneyId: onBehalfOfAttorneyIdPendingPayment,
              onBehalfOfUserId: onBehalfOfUserIdPendingPayment,
              pendingPaymentFee,
            }}
          />
        )}
      {responseModal.open && (
        <ResponseModal
          open={responseModal.open}
          type={responseModal.type}
          onClose={() => setResponseModal({ open: false, type: null })}
          bodyContent={matchFeeContentOnClientSigning}
        />
      )}
      {inviteToCaseModalOpen && (
        <BulkInviteToCaseModal
          jCase={caseInfo as JCaseType}
          open={inviteToCaseModalOpen}
          onSubmit={() => setInviteToCaseModalOpen(false)}
          onClose={() => setInviteToCaseModalOpen(false)}
          onBehalfOf={inviteToCaseOnBehalfOf}
          onBehalfOfUserId={inviteToCaseOnBehalfOfUserId}
        />
      )}
      {signedModalOpen && (
        <ClientSignedConfirmationModal
          onClose={handleClientSignedConfirmationModalClose}
          open={signedModalOpen}
          onSubmitResponse={handleClientSignedSubmit}
          casePurchaseId={casePurchaseId}
          onBehalfOfAttorneyId={attorneyId}
          fee={
            (!isNil(dataCase?.case_purchase_activity[0].fee)
              ? dataCase?.case_purchase_activity[0].fee
              : dataCase?.case_purchase_activity[0].case_purchase_id?.case_id
                  ?.fee) ?? 0
          }
        />
      )}
      {rejectedModalOpen && (
        <AgreementRejectionConfirmationModal
          onClose={handleAgreementRejectionConfirmationModalClosed}
          open={rejectedModalOpen}
          onSubmitResponse={handleClientRejectedSubmit}
          casePurchaseId={casePurchaseId}
          onBehalfOfAttorneyId={attorneyId}
          fee={
            (!isNil(dataCase?.case_purchase_activity[0].fee)
              ? dataCase?.case_purchase_activity[0].fee
              : dataCase?.case_purchase_activity[0].case_purchase_id?.case_id
                  ?.fee) ?? 0
          }
        />
      )}
    </>
  )
}

export default CaseSidebar

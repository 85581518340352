import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  TTokenValidationResponse,
  useTokenValidation,
} from '@/hooks/useTokenValidation'
import { USER_ROLES } from '@/constants'
import { useAxios } from '@/axios/config'
import { AxiosError } from 'axios'
import { CreationFlowState } from '../types'
import { defaultValues as createAccountDefaultValues } from '../InitialStep/config'
import { CreateAccountData } from '../InitialStep/types'

type InvitesResponse = {
  message: string
  firstName: string
  lastName: string
  organization: {
    name: string
    email_domain: string
  }
  domainExists: boolean
  domainWhitelisted: boolean
}

type InvitesErrorResponse = InvitesResponse

const useCreateAccount = () => {
  const [searchParams] = useSearchParams()
  const paramToken = searchParams.get('token')
  const { validateToken, isTokenExpired } = useTokenValidation()
  const [createAccountValues, setCreateAccountValues] =
    useState<CreateAccountData>(createAccountDefaultValues)
  const [flow, setFlow] = useState<CreationFlowState>('INITIAL_STEP')
  const [error, setError] = useState('')
  const [accountType, setAccountType] =
    useState<CreationFlowState>('ORGANIZATION')
  const [loading, setIsLoading] = useState<boolean>(true)
  const [token, setToken] = useState<string>('')
  const [tokenData, setTokenData] = useState<TTokenValidationResponse>(
    {} as TTokenValidationResponse
  )

  const [
    { response: checkPhoneRespose, error: checkPhoneError },
    validatePhoneNumber,
  ] = useAxios({})

  useEffect(() => {
    if (paramToken) {
      setToken(paramToken)
    }
  }, [paramToken])

  const handleCreateAccountForm = useCallback(
    (newValues: CreateAccountData) => {
      setCreateAccountValues(prevState => ({
        ...prevState,
        ...newValues,
        email: newValues.email?.toLowerCase(),
      }))
    },
    []
  )

  useEffect(() => {
    if (!token) {
      setIsLoading(false)
      return
    }
    if (token) {
      setIsLoading(true)
      validateToken({
        tokenToValidate: token,
        type: 'invite',
      })
        .then(data => {
          if (data) {
            setTokenData(data)
            if (isTokenExpired(data.exp)) {
              setFlow('EXPIRED_TOKEN')
              return
            }
            if (data.role === USER_ROLES.BasicUser) {
              setAccountType('INVITED')
              return
            }
            if (
              data.role === USER_ROLES.AdminAttorneyUser ||
              data.role === USER_ROLES.AdminUser
            ) {
              setAccountType('ADMIN')
              return
            }
            if (data.role === USER_ROLES.CaseManagerUser) {
              setAccountType('CASE_MANAGER')
              return
            }
            setFlow('INVALID_TOKEN')
          }
          setFlow('INVALID_TOKEN')
        })
        .catch(err => {
          setError(err)
          setFlow('INVALID_TOKEN')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [token, validateToken, isTokenExpired])

  useEffect(() => {
    if (
      token &&
      tokenData.email &&
      tokenData.email.toLowerCase() !== createAccountValues.email.toLowerCase()
    ) {
      handleCreateAccountForm({
        ...createAccountValues,
        email: tokenData.email,
        token,
      })
    }
  }, [tokenData, handleCreateAccountForm, createAccountValues, token])

  const handleFlowNavigation = useCallback((step: CreationFlowState) => {
    setFlow(step)
  }, [])

  const checkPhoneNumber = useCallback(
    async (phone: string) => {
      try {
        const response = await validatePhoneNumber(
          {
            url: `/checkphone/${phone}`,
            method: 'GET',
            validateStatus: status => status === 200 || status === 409,
          },
          {
            useCache: false,
          }
        )

        if (!response?.data?.result)
          throw new Error('Something went wrong. Please try again.')

        if (response?.data?.result !== 'OK') {
          throw new Error(response?.data?.result)
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        throw err
      }
    },
    [validatePhoneNumber, checkPhoneError, checkPhoneRespose]
  )

  const [{ error: checkForInvitesError }, checkForInvites] = useAxios<
    InvitesResponse,
    {
      email: string
      firstName: string
      lastName: string
    },
    InvitesErrorResponse
  >({
    url: '/invites',
    method: 'POST',
  })

  const showExistingOrganizationModal =
    checkForInvitesError instanceof AxiosError &&
    checkForInvitesError?.response?.data.domainExists === true &&
    checkForInvitesError?.response?.data.domainWhitelisted === false

  return {
    flow,
    tokenData,
    accountType,
    createAccountValues,
    checkPhoneNumber,
    checkForInvites,
    checkForInvitesError,
    showExistingOrganizationModal,
    handleCreateAccountForm,
    setFlow,
    handleFlowNavigation,
    loading,
    error,
  }
}

export default useCreateAccount

import { useEffect, useState } from 'react'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { Attorney_Profile, useAttorneyNameAndOrgLazyQuery } from '@/gql/appApi'
import { USER_ROLES } from '@/constants'
import useOrganizationId from '@/hooks/organization/useOrganizationId'
import { useDebounceValue } from 'usehooks-ts'

type TUseAttorneySearchProps = {
  attorneyValue: Attorney_Profile | null
  attorneysSelected?: Attorney_Profile[]
  searchLimit?: number
}

const useAttorneySearch = ({
  attorneyValue,
  attorneysSelected = [],
  searchLimit = 100,
}: TUseAttorneySearchProps) => {
  const [searchTerm, setSearchTerm] = useState(
    attorneyValue
      ? `${attorneyValue.first_name} ${attorneyValue.last_name}`
      : ''
  )
  const [debouncedSearchTerm] = useDebounceValue(searchTerm, 250)
  const [attorneyProfile, setAttorney] = useState<Attorney_Profile | null>(
    attorneyValue
  )
  const [options, setOptions] = useState<Attorney_Profile[]>([])
  const [filteredOptions, setFilteredOptions] = useState<Attorney_Profile[]>([])
  const { id: orgId } = useOrganizationId()

  const [getAttorneyById, { data, loading }] = useAttorneyNameAndOrgLazyQuery()

  useEffect(() => {
    if (orgId) {
      getAttorneyById({
        variables: {
          search: debouncedSearchTerm.toLowerCase(),
          sort: 'first_name',
          limit: searchLimit,
          filter: {
            _and: [
              {
                attorney_id: {
                  user_id: {
                    current_organization: {
                      organization_id: {
                        id: {
                          _neq: orgId,
                        },
                      },
                    },
                  },
                },
              },
              {
                _or: [
                  {
                    attorney_id: {
                      user_id: {
                        role: {
                          id: {
                            //  SAdminAtt
                            _eq: USER_ROLES.SAdminAttorneyUser,
                          },
                        },
                      },
                    },
                  },
                  {
                    attorney_id: {
                      user_id: {
                        role: {
                          id: {
                            // admin att
                            _eq: USER_ROLES.AdminAttorneyUser,
                          },
                        },
                      },
                    },
                  },
                  {
                    attorney_id: {
                      user_id: {
                        role: {
                          id: {
                            // basic
                            _eq: USER_ROLES.BasicUser,
                          },
                        },
                      },
                    },
                  },
                ],
              },
            ],
          },
        },
      })
    }
  }, [orgId, getAttorneyById, debouncedSearchTerm, searchLimit])

  useEffect(() => {
    if (data) {
      const newOptions: Attorney_Profile[] = []
      data.attorney_profile?.forEach(attorney => {
        if (attorney && attorney.first_name !== 'undefined') {
          // eslint-disable-next-line
          // @ts-expect-error
          newOptions.push(attorney)
        }
      })
      setOptions(newOptions)
      setFilteredOptions(newOptions)
    }
  }, [data])

  useEffect(() => {
    if (attorneysSelected.length > 0) {
      const newOptions = options.filter(
        att =>
          !attorneysSelected.find(
            a => a?.id === att.id && !(attorneyProfile?.id === a.id)
          )
      )
      setFilteredOptions(newOptions)
    }
  }, [attorneysSelected, attorneyProfile, options])

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: Attorney_Profile) =>
      `${option.first_name} ${option.last_name}`,
  })

  return {
    attorneyProfile,
    setAttorney,
    filteredOptions,
    setFilteredOptions,
    searchTerm,
    setSearchTerm,
    loading,
    filterOptions,
  }
}

export default useAttorneySearch

import FilesUploaderForm from '@/components/DataRoom/FilesUploader/FilesUploaderForm'
import { Case_File, Maybe } from '@/gql/systemApi'
import noop from 'lodash/noop'
import { FEE_AGREEMENT_STATUS } from '@/constants'
import Box, { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FeeAgreementFiles from './FeeAgreementsFiles/FeeAgreementFiles'
import FeeAgreementSwitch from './FeeAgreementSwitch/FeeAgreementSwitch'
import { FeeAgreementHeader } from './FeeAgreementHeader'

interface TFeeAgreementsProps extends BoxProps {
  feeAgreementFiles?: Maybe<Maybe<Case_File>[]>
  caseId: string
  casePurchaseId: string
  isOwner: boolean
  feeAgreementStatus: string | null
  showHeader?: boolean
  verticalFileUploader?: boolean
  onRefetch: () => Promise<void>
}

const FeeAgreements = ({
  feeAgreementFiles,
  caseId,
  casePurchaseId,
  isOwner,
  feeAgreementStatus,
  onRefetch,
  showHeader = true,
  sx,
  verticalFileUploader = false,
  ...rest
}: TFeeAgreementsProps) => {
  const fileUploaded =
    feeAgreementStatus === FEE_AGREEMENT_STATUS.FeeAgreementUploaded
  const signedWithNoFile =
    feeAgreementStatus === FEE_AGREEMENT_STATUS.SignedAndWillNotBeUploaded

  const isSigned = fileUploaded || signedWithNoFile
  const signedStatus = isSigned ? 'Agreement Signed' : 'Not Signed yet'

  const showFileUploader = !signedWithNoFile && isOwner

  const refetchCaseData = async () => {
    await onRefetch()
  }

  return (
    <Box
      sx={[
        {
          mt: 2,
          py: 2,
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderColor: 'divider',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {showHeader && (
        <FeeAgreementHeader isSigned={isSigned} signedStatus={signedStatus} />
      )}
      {!isOwner && !isSigned && (
        <Typography component="p" variant="caption" sx={{ my: 1 }}>
          Your counterpart hasn&apos;t confirmed that the agreement is signed
          yet
        </Typography>
      )}
      {!isOwner && signedWithNoFile && (
        <Typography component="p" variant="caption" sx={{ my: 1 }}>
          Your counterpart indicated that the agreement was signed but has not
          uploaded a copy of the document
        </Typography>
      )}
      <FeeAgreementFiles
        isOwner={isOwner}
        caseId={caseId}
        casePurchaseId={casePurchaseId}
        files={feeAgreementFiles as unknown as Case_File[]}
        refetchCaseData={refetchCaseData}
      />
      {showFileUploader && (
        <FilesUploaderForm
          caseId={caseId}
          casePurchaseId={casePurchaseId}
          refetchCaseData={refetchCaseData}
          onAddExternal={noop}
          vertical={verticalFileUploader}
        />
      )}
      {(!fileUploaded || signedWithNoFile) && isOwner && (
        <FeeAgreementSwitch
          casePurchaseId={casePurchaseId}
          isSignedWithNoFile={signedWithNoFile}
        />
      )}
    </Box>
  )
}

export default FeeAgreements

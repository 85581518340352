/* eslint-disable no-return-assign */
import { GetActivityPurchaseQuery } from '@/gql/appApi'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'

const shouldAddActivity = (proposals: any[]) => {
  let i = 0
  let isAccepted = false
  const validProposals = []
  while (i < proposals.length && !isAccepted) {
    const lastProposal = proposals[i][0]
    const status = lastProposal?.activity_entry_status?.name
    if (status === 'Accepted') {
      isAccepted = true
    } else if (status !== 'Rejected') {
      validProposals.push(lastProposal)
    }
    i += 1
  }
  return isAccepted ? null : validProposals
}

/**
 * @param data: an array of purchase activities with proposals
 * @returns
 * { [case_key]: case_purchase[ activity_purchase_ordered_by_time_stamp [] ] }
 */
export const orderPurchaseActivityByTimeStamp = (
  data: GetActivityPurchaseQuery,
  showAll: boolean
) => {
  // group by case
  const caseGroup = groupBy(
    data.case_purchase_activity,
    purchaseActivity => purchaseActivity.case_purchase_id?.case_id?.id
  )

  // group by case purchase
  const purchaseActivityGroup: { [key: string]: any } = {}
  Object.keys(caseGroup).forEach(
    key =>
      (purchaseActivityGroup[key] = Object.values(
        groupBy(
          caseGroup[key],
          purchaseActivity => purchaseActivity.case_purchase_id?.id
        )
      ))
  )

  // order purchase activity by time_stamp
  Object.keys(purchaseActivityGroup).forEach(caseKey => {
    Object.keys(purchaseActivityGroup[caseKey]).forEach(
      (purchaseActivityKey: string) => {
        purchaseActivityGroup[caseKey][purchaseActivityKey] = orderBy(
          purchaseActivityGroup[caseKey][purchaseActivityKey],
          [item => item.created_ts],
          ['desc', 'asc']
        )
      }
    )
  })

  if (showAll) {
    return purchaseActivityGroup
  }
  // remove cases with negotation closed
  const actualPurchaseActivity: { [key: string]: any } = {}
  Object.keys(purchaseActivityGroup).forEach(caseKey => {
    const proposals = purchaseActivityGroup?.[caseKey]
    const newProposals = shouldAddActivity(proposals)
    if (newProposals) {
      actualPurchaseActivity[caseKey] = newProposals
    }
  })

  return actualPurchaseActivity
}

export default orderPurchaseActivityByTimeStamp

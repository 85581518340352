import { alpha, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import { Controller, useFormContext } from 'react-hook-form'

const EnableWaterfallSwitch = () => {
  const { control } = useFormContext()
  const theme = useTheme()
  return (
    <Box>
      <Controller
        name="enabledForAttorneys"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Switch
                {...field}
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
                sx={{
                  alignSelf: 'flex-start',
                }}
              />
            }
            label={
              <>
                <Typography variant="body2">
                  Attorneys in your law firm will be able to use this Waterfall
                  when creating new cases
                </Typography>
                <Typography
                  variant="body2"
                  color={alpha(theme.palette.common.black, 0.6)}
                  sx={{ mt: 1 }}
                >
                  Uncheck if it will only be used for automatically referring
                  cases from your Case Management integration
                </Typography>
              </>
            }
          />
        )}
      />
    </Box>
  )
}

export default EnableWaterfallSwitch

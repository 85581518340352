import ConfirmationModal, {
  ConfirmationModalProps as IConfirmationModalProps,
} from '../ConfirmationModal'
import { DiscardChangesModalTypes } from './useDiscardChangesModal'

export interface DiscardChangesModalProps {
  ConfirmationModalProps: Omit<IConfirmationModalProps, 'children'>
  type: DiscardChangesModalTypes
  onSubmit: (form: any) => void
}

export interface SendDiscardChangesModalProps {
  ConfirmationModalProps: Omit<IConfirmationModalProps, 'children'>
  onSubmit: (form: any) => void
}

const DiscardChangesModal = ({
  ConfirmationModalProps,
  onSubmit,
}: SendDiscardChangesModalProps) => {
  const { open, AcceptButtonProps, title, description, ...modalRest } =
    ConfirmationModalProps

  return (
    <ConfirmationModal
      title={title}
      description={description}
      open={open}
      AcceptButtonProps={{
        onClick: onSubmit,
        ...AcceptButtonProps,
      }}
      {...modalRest}
    />
  )
}

export default DiscardChangesModal

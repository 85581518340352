import Chip, { ChipProps } from '@mui/material/Chip'
import useTheme from '@mui/material/styles/useTheme'

interface ICaseHeaderChipProps extends ChipProps {
  textColor?: string
  iconColor?: string
}

const CaseHeaderChip = ({
  textColor,
  iconColor,
  ...rest
}: ICaseHeaderChipProps) => {
  const theme = useTheme()
  return (
    <Chip
      variant="outlined"
      sx={{
        height: '28px',
        backgroundColor: theme.palette.background.page,
        color: textColor,
        typography: 'body2',
        '& .MuiSvgIcon-root': {
          color: iconColor,
        },
        '& .MuiChip-label': {
          pl: 1,
          letterSpacing: 0,
        },
      }}
      {...rest}
    />
  )
}

export default CaseHeaderChip

import {
  useRemoveIntegrationItem,
  useSetupClio,
} from '@/utils/api/integrations'

export enum ClioIntegrationSource {
  ADMIN_PANEL = 'admin',
  ACCOUNT_SETTINGS = 'accountsettings',
}
export interface UseClioIntegrationItemProps {
  code: string
  connectClio: boolean
  clioRemoveStatus: boolean
  organizationId: string
  clioName: string
  source?: ClioIntegrationSource
}

const useClioIntegrationItem = ({
  code,
  connectClio,
  clioRemoveStatus,
  organizationId,
  clioName,
  source = ClioIntegrationSource.ADMIN_PANEL,
}: UseClioIntegrationItemProps) => {
  const environment = import.meta.env.VITE_VERCEL_ENV ?? 'development'
  const domainExtension = environment === 'production' ? 'com' : 'info'
  const clioRedirectURL = `https://app.attorneyshare.${domainExtension}/${source}/integrations?src=clio`

  const connectClioIntegration = () => {
    const url =
      source === ClioIntegrationSource.ACCOUNT_SETTINGS
        ? import.meta.env.VITE_LCMS_CLIO_OAUTH_URL_ASETTINGS
        : import.meta.env.VITE_LCMS_CLIO_OAUTH_URL
    window.open(url, '_self', 'noopener,noreferrer')
  }

  const { data: removeClioData, isLoading: removeClioIsLoading } =
    useRemoveIntegrationItem(organizationId, clioRemoveStatus)

  const config = {
    code,
    redirect_url: clioRedirectURL,
  }

  const {
    data: connectClioIntegrationData,
    isLoading: isClioIntegrationLoading,
  } = useSetupClio(
    {
      organization_id: organizationId,
      lcms: clioName,
      config,
    },
    clioRemoveStatus,
    connectClio
  )

  return {
    connectClioIntegration,
    connectClioIntegrationData,
    isClioIntegrationLoading,
    removeClioData,
    removeClioIsLoading,
  }
}

export default useClioIntegrationItem

import { useCallback, useEffect, useMemo, useState } from 'react'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { Attorney_Profile } from '@/gql/appApi'

type TUseSelectedDefaultAttorneyProps = {
  excludedAttorneys: Set<string | null>
  defaultAttorney?: string | null
  excludeSelected?: boolean
  selectedJurisdiction?: string
  validAttorneys: Map<string, Attorney_Profile[]>
}

const useSelectedDefaultAttorney = ({
  excludedAttorneys,
  defaultAttorney = null,
  excludeSelected = false,
  selectedJurisdiction = '',
  validAttorneys,
}: TUseSelectedDefaultAttorneyProps) => {
  const [inputValue, setInputValue] = useState('')
  const [attorneyProfile, setAttorneyProfile] =
    useState<Attorney_Profile | null>(null)
  const [options, setOptions] = useState<Attorney_Profile[]>([])
  const [filteredOptions, setFilteredOptions] = useState<Attorney_Profile[]>([])
  const [attorneySelected, setAttorneySelected] =
    useState<Attorney_Profile | null>(null)

  const getAttorneysAvailable = useCallback(
    (orgAttorneysByJurisdiction: Map<string, Attorney_Profile[]>) => {
      const uniqueAttorneysMap = new Map<string, Attorney_Profile>()

      orgAttorneysByJurisdiction.forEach(attorneys => {
        attorneys.forEach(attorney => {
          if (attorney && !uniqueAttorneysMap.has(attorney.id)) {
            uniqueAttorneysMap.set(attorney.id, attorney)
          }
        })
      })

      return Array.from(uniqueAttorneysMap.values())
    },
    []
  )

  const attorneyAvailable = useMemo(
    () =>
      excludeSelected
        ? validAttorneys.get(selectedJurisdiction)
        : getAttorneysAvailable(validAttorneys),
    [
      excludeSelected,
      getAttorneysAvailable,
      selectedJurisdiction,
      validAttorneys,
    ]
  )

  useEffect(() => {
    if (attorneyAvailable) {
      const newOptions: Attorney_Profile[] = []
      attorneyAvailable?.forEach(attorney => {
        if (attorney && attorney.first_name !== 'undefined') {
          newOptions.push(attorney)
        }
      })
      const newExcludedOptions = newOptions.filter(
        att =>
          att?.attorney_id?.id && !excludedAttorneys.has(att.attorney_id.id)
      )
      setOptions(newOptions)
      setFilteredOptions(newExcludedOptions)
    }
  }, [attorneyAvailable, excludedAttorneys])

  useEffect(() => {
    if (attorneySelected) {
      const newOptions = options.filter(
        att =>
          attorneySelected?.id === att.id &&
          !(attorneyProfile?.id === attorneySelected.id)
      )
      setFilteredOptions(newOptions)
    }
  }, [attorneySelected, attorneyProfile, options])

  // Used to map selected attorneys coming from the backend
  useEffect(() => {
    if (defaultAttorney) {
      const selectedAttorneyProfile = options.find(
        att => att.attorney_id?.id === defaultAttorney
      )
      if (selectedAttorneyProfile) {
        setAttorneySelected(selectedAttorneyProfile)
        setAttorneyProfile(selectedAttorneyProfile)
        setInputValue(
          `${selectedAttorneyProfile.first_name} ${selectedAttorneyProfile.last_name}`
        )
      }
    }
  }, [defaultAttorney, options])

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: Attorney_Profile) =>
      `${option.first_name} ${option.last_name}`,
  })

  return {
    attorneyProfile,
    setAttorney: setAttorneyProfile,
    filteredOptions,
    setFilteredOptions,
    filterOptions,
    inputValue,
    setInputValue,
    attorneySelected,
    setAttorneySelected,
  }
}

export default useSelectedDefaultAttorney

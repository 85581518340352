import { useEffect, useState, useCallback } from 'react'
import uniqBy from 'lodash/uniqBy'

import { useGetMyHiresLazyQuery } from '@/gql/appApi'
import useAttorneyId from '@/hooks/useAttorneyId'
import { ProfileType } from '@/types'
import { getFullname } from '@/utils/profile'

export interface UseMyHiresProps {
  searchText?: string
}

const useMyHires = ({ searchText = '' }: UseMyHiresProps) => {
  const { getAttorneyId } = useAttorneyId()
  const [myHires, setMyHires] = useState<ProfileType[]>([])
  const [myHiresFiltered, setMyHiresFiltered] = useState<ProfileType[]>([])
  const attorneyId = getAttorneyId()

  const [getMyHiresQuery, { data, loading, error }] = useGetMyHiresLazyQuery()

  useEffect(() => {
    getMyHiresQuery({
      variables: {
        filter: {
          _or: [
            {
              prospect_attorney_id: {
                profiles: {
                  attorney_id: {
                    id: {
                      _eq: attorneyId,
                    },
                  },
                },
              },
            },
            {
              case_id: {
                attorney_id: {
                  id: {
                    _eq: attorneyId,
                  },
                },
              },
            },
          ],
        },
      },
      fetchPolicy: 'network-only',
    })
  }, [attorneyId, getMyHiresQuery])

  const getMyHires = useCallback(() => {
    let newHires = data?.case_purchase?.map(casePurchase => {
      if (
        casePurchase.prospect_attorney_id?.profiles?.[0]?.attorney_id?.id ===
        attorneyId
      ) {
        return casePurchase.case_id?.attorney_id?.profiles?.[0]
      }
      return casePurchase.prospect_attorney_id?.profiles?.[0]
    })
    newHires = uniqBy(newHires, 'attorney_id.id')
    return newHires
  }, [data])

  useEffect(() => {
    if (data && data?.case_purchase?.length > 0) {
      const newHires = getMyHires()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setMyHires(newHires)
    }
  }, [data, getMyHires])

  useEffect(() => {
    if (myHires.length > 0) {
      const filtered = myHires.filter(att =>
        getFullname(att).toLowerCase().includes(searchText.toLowerCase())
      )
      setMyHiresFiltered(filtered)
    }
  }, [myHires, searchText])

  return {
    myHires: myHiresFiltered,
    loading,
    error,
  }
}

export default useMyHires

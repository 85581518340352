import { useEffect, useState } from 'react'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { Attorney_Profile, useAttorneyNameAndOrgLazyQuery } from '@/gql/appApi'
import useOrganizationId from '@/hooks/organization/useOrganizationId'
import { USER_ATTORNEY_ROLES } from '@/constants'
import { useDebounceValue } from 'usehooks-ts'

type TUseWaterfallMembersProps = {
  waterfallMembers?:
    | {
        listOrder: number
        attorneyId: string
      }[]
  jurisdictionId?: string
}

const useWaterfallMembers = ({
  waterfallMembers,
  jurisdictionId,
}: TUseWaterfallMembersProps) => {
  const [selectedUsers, setSelectedUsers] = useState<Attorney_Profile[]>([])
  const { id: orgId } = useOrganizationId()
  const [getWaterfallAttorneys, { data, loading }] =
    useAttorneyNameAndOrgLazyQuery()
  const [hasSelectedInitialValues, setHasSelectedInitialValues] =
    useState(false)
  const [inputValue, setInputValue] = useState('')
  const [attorneyProfiles, setAttorneyProfiles] = useState<Attorney_Profile[]>(
    []
  )
  const [options, setOptions] = useState<Attorney_Profile[]>([])

  const [debouncedSearchText] = useDebounceValue(inputValue, 500)

  useEffect(() => {
    if (orgId) {
      getWaterfallAttorneys({
        fetchPolicy: 'network-only',
        variables: {
          search: debouncedSearchText.toLowerCase(),
          sort: 'first_name',
          filter: {
            _and: [
              {
                attorney_id: {
                  user_id: {
                    current_organization: {
                      organization_id: {
                        id: {
                          _neq: orgId,
                        },
                      },
                    },
                  },
                },
                // Currently allowing to query for all attorneys, but in the future, we might want to filter by jurisdiction only
                // This is set up this way to allow 'legacy' waterfalls from obtaining all attorneys which could be from different jurisdictions
                ...(jurisdictionId && {
                  licenses: {
                    license_state: {
                      id: {
                        _eq: jurisdictionId,
                      },
                    },
                  },
                }),
              },
              {
                attorney_id: {
                  user_id: {
                    role: {
                      id: {
                        _in: USER_ATTORNEY_ROLES,
                      },
                    },
                  },
                },
              },
            ],
          },
        },
      })
    }
  }, [orgId, getWaterfallAttorneys, debouncedSearchText, jurisdictionId])

  useEffect(() => {
    if (data) {
      const newOptions = new Map<string, Attorney_Profile>()
      data.attorney_profile?.forEach(attorney => {
        if (
          attorney &&
          attorney?.first_name !== 'undefined' &&
          attorney?.attorney_id?.id
        ) {
          newOptions.set(attorney.attorney_id.id, attorney as Attorney_Profile)
        }
      })
      if (waterfallMembers && !hasSelectedInitialValues) {
        const selected = waterfallMembers
          .map(member => newOptions.get(member.attorneyId))
          .filter(Boolean) as Attorney_Profile[]
        setSelectedUsers(selected)
        setAttorneyProfiles(selected)
        setHasSelectedInitialValues(true)
      }
      setOptions([...newOptions.values()])
    }
  }, [data, hasSelectedInitialValues, waterfallMembers])

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: Attorney_Profile) =>
      `${option.first_name} ${option.last_name}`,
  })

  const loadingSelectedUsers = waterfallMembers && !hasSelectedInitialValues

  return {
    attorneyProfiles,
    setAttorneyProfiles,
    options,
    loading,
    loadingSelectedUsers,
    filterOptions,
    setInputValue,
    setSelectedUsers,
    selectedUsers,
  }
}

export default useWaterfallMembers

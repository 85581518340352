import { FC } from 'react'
import {
  Paper,
  Table,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { useHits } from 'react-instantsearch'
import AlgoliaHitList from './AlgoliaHitList'
import AlgoliaPagination from './AlgoliaPagination'
import NoResultsDisplay from './NoResultsDisplay'

const HeaderTypography: FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = useTheme()
  return (
    <Typography variant="subtitle2" color={theme.palette.text.disabled}>
      {children}
    </Typography>
  )
}

const DesktopMarketplaceContent: FC = () => {
  const { items } = useHits()

  if (items.length === 0) {
    return <NoResultsDisplay />
  }

  return (
    <Paper>
      <Table
        sx={{
          tableLayout: 'fixed',
          width: '100%',
        }}
      >
        <TableHead>
          <TableRow sx={{ '& th': { borderBottom: 'none' } }}>
            <TableCell
              sx={{
                width: '50%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <HeaderTypography>Name</HeaderTypography>
            </TableCell>
            <TableCell
              sx={{
                width: '20%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <HeaderTypography>Case Type</HeaderTypography>
            </TableCell>
            <TableCell sx={{ width: '15%' }}>
              <HeaderTypography>Jurisdiction</HeaderTypography>
            </TableCell>
            <TableCell sx={{ width: '7.5%', textAlign: 'center' }}>
              <HeaderTypography>Fee</HeaderTypography>
            </TableCell>
            <TableCell
              sx={{
                width: '20%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <HeaderTypography>Process Stage</HeaderTypography>
            </TableCell>
            <TableCell sx={{ width: '10%', textAlign: 'right' }}>
              <HeaderTypography>Posted</HeaderTypography>
            </TableCell>
          </TableRow>
        </TableHead>
        <AlgoliaHitList />
        <TableFooter>
          <TableRow>
            <TableCell colSpan={6}>
              <AlgoliaPagination />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  )
}

export default DesktopMarketplaceContent

import { createContext, ReactNode, useEffect, useState } from 'react'
import { FlagValues } from '@vercel/flags/react'
import Cookies from 'js-cookie'
import { useUsersMeQuery } from '@/gql/systemApi'
import { useQuery } from '@apollo/client'
import { SignInQueryResult } from '@/apollo/AuthRoute'
import { IS_LOGGED_IN } from '@/apollo/queries'
import { datadogRum } from '@datadog/browser-rum'
import { Client } from '@optimizely/optimizely-sdk'
import createOptimizelyInstance from './optimizelyConfig'
import { FeatureFlag } from './types'

interface IOptimizelyContext {
  optimizely: Client | null
  areFeatureFlagsLoaded: boolean
  featureFlags: Partial<Record<FeatureFlag, boolean>>
}

const initialContextValue: IOptimizelyContext = {
  optimizely: null,
  areFeatureFlagsLoaded: false,
  featureFlags: {},
}

export const OptimizelyContext =
  createContext<IOptimizelyContext>(initialContextValue)

export const OptimizelyProvider = ({ children }: { children: ReactNode }) => {
  const [contextValue, setContextValue] = useState(initialContextValue)
  const { data } = useQuery<SignInQueryResult>(IS_LOGGED_IN)
  const isLoggedIn = Boolean(data?.isLoggedIn)
  const skipQuery = !isLoggedIn
  const { data: userMe, loading: usersLoading } = useUsersMeQuery({
    skip: skipQuery,
  })
  const userId = userMe?.users_me?.id ?? '0'

  useEffect(() => {
    ;(async () => {
      if (contextValue.areFeatureFlagsLoaded || usersLoading) {
        return
      }

      try {
        const optimizely = await createOptimizelyInstance

        const flagKeys = Object.values(FeatureFlag)

        const rawFeatureFlags: Partial<Record<FeatureFlag, boolean>> =
          flagKeys.reduce(
            (acc: Partial<Record<FeatureFlag, boolean>>, flagKey) => {
              acc[flagKey] = optimizely?.isFeatureEnabled(
                flagKey,
                userId.toString(),
                {
                  userId: userId.toString(),
                }
              )

              return acc
            },
            {}
          )

        const rawVercelOverrides = Cookies.get('vercel-flag-overrides')
        let vercelOverrides = {}

        if (rawVercelOverrides) {
          try {
            vercelOverrides = JSON.parse(rawVercelOverrides)
          } catch (e: unknown) {
            if (e instanceof Error) {
              // eslint-disable-next-line no-console
              console.error(e.message)
            }
          }
        }

        const featureFlags = { ...rawFeatureFlags, ...vercelOverrides }

        setContextValue({
          optimizely,
          featureFlags,
          areFeatureFlagsLoaded: true,
        })
      } catch (error) {
        console.error('Failed to initialize Optimizely:', error)
        const optimizelyError = new Error('Failed to initialize Optimizely')
        datadogRum.addError(optimizelyError, {
          isLoggedIn: Boolean(data?.isLoggedIn),
          userId: userMe?.users_me?.id ?? '0',
          skipQuery,
          hasUserMeData: !!userMe,
          isLoading: usersLoading,
          originalError: error,
        })
      }
    })()
  }, [
    contextValue.areFeatureFlagsLoaded,
    userId,
    usersLoading,
    userMe,
    data,
    skipQuery,
  ])

  if (!contextValue.areFeatureFlagsLoaded) {
    return null
  }

  return (
    <OptimizelyContext.Provider value={contextValue}>
      <FlagValues values={contextValue.featureFlags} />
      {children}
    </OptimizelyContext.Provider>
  )
}

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const SalesforceIcon = ({
  height = 48,
  width = 48,
  ...props
}: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z"
        fill="#00A1E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4342 13.6646C13.9429 13.4194 14.6193 13.1697 14.9372 13.11C16.9121 12.7388 18.9056 13.3184 20.3311 14.678L20.9983 15.3145L21.4482 14.9582C23.4205 13.3961 26.0936 13.2753 28.1174 14.6569C28.7247 15.0715 29.6864 16.0758 29.8096 16.4241C29.8364 16.5001 30.1103 16.4522 30.5461 16.2956C31.1151 16.0909 31.4892 16.047 32.6272 16.0509C33.827 16.0549 34.1201 16.0955 34.7942 16.3508C37.6384 17.4278 39.3947 19.9145 39.3893 22.8565C39.3858 24.7866 38.7273 26.3839 37.3806 27.7293C35.9776 29.131 34.1644 29.8291 32.2193 29.7165L31.2896 29.6627L30.8833 30.2161C30.3586 30.9308 29.283 31.6441 28.2621 31.9542C27.3391 32.2346 26.1891 32.2071 25.2868 31.8832C24.9282 31.7545 24.7548 31.7365 24.7254 31.825C24.5648 32.3067 23.6196 33.4615 23.0104 33.9202C21.8369 34.8038 21.2057 34.9983 19.5043 34.9999C18.0706 35.0013 18.0517 34.9978 17.1478 34.5662C16.0647 34.049 14.9768 33.0147 14.5342 32.0811L14.2468 31.4748H13.2438C11.0298 31.4748 9.41323 30.473 8.43577 28.4952C8.0015 27.6166 8 27.6085 8 26.1563C8 24.7043 8.00162 24.6959 8.43531 23.8182C8.76102 23.1591 9.06857 22.7529 9.65604 22.2054L10.4413 21.4736L10.2395 20.8377C9.98116 20.0244 9.96578 18.4929 10.2065 17.5592C10.6415 15.8723 11.8302 14.438 13.4342 13.6646Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
)

export default SalesforceIcon

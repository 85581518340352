import Box from '@mui/material/Box'
import { yupResolver } from '@hookform/resolvers/yup'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { FC, useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSnackBar } from '@/hooks/useSnackBar'
import JSnackBar from '@/components/atoms/JSnackBar'
import PasswordField from '@/components/PasswordField/PasswordField'
import AuthLayout from '@/components/shared/AuthLayout'
import { BEToNiceErrorMapping } from '@/utils/constants'
import useAuthentication from '@/hooks/useAuthentication'
import { styled } from '@mui/material/styles'
import LogoBar from '@/pages/CreateAccount/shared/LogoBar'

import { useResendEmailVerification } from '@/hooks/useResendEmailVerification'
import { useCheckValidationAndInvitationLazyQuery } from '@/gql/appApi'
import { gql, useApolloClient, useQuery } from '@apollo/client'
import { useGetDelegatedUsersByCaseManagerLazyQuery } from '@/gql/systemApi'
import { RoutesData } from '@/router/RoutesData'
import get from 'lodash/get'
import CreateAccountField from '@/components/CreateAccountField/CreateAccountField'
import { useStreamChat } from '@/hooks/useStreamChat'
import { useSizes } from '@/hooks/useSizes'
import { IS_LOGGED_IN } from '@/apollo/queries'
import { SignInQueryResult } from '@/apollo/AuthRoute'
import { sessionStorageService } from '@/services/SessionStorage/SessionStorageService'
import useOnMount from '@mui/utils/useOnMount'
import { defaultValues, signInSchema } from './config'
import VerificationAlert from './VerificationAlert'
import { getSignInRedirectionByRole } from './utils'

interface ISignInProps {
  onSignInSuccess?: () => void
  loading?: boolean
  verificationEmail?: string
}

const StyledForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const SignIn: FC<ISignInProps> = ({
  onSignInSuccess,
  loading,
  verificationEmail,
}) => {
  const { showAlert, alert, handleClose } = useSnackBar()
  const navigate = useNavigate()
  const location = useLocation()
  const [verificationError, setVerificationError] = useState(false)
  const { signInAttorney, loading: signInLoading } = useAuthentication()
  const { resendVerificationEmail, isLoading: resendEmailLoading } =
    useResendEmailVerification()
  const [checkForValidationAndInvitation, { loading: loadingChecks }] =
    useCheckValidationAndInvitationLazyQuery()
  const client = useApolloClient()
  const [getUserMeWithRoleData] = useGetDelegatedUsersByCaseManagerLazyQuery()
  // Local query to check if user is logged in instead of using localStorage
  const { data } = useQuery<SignInQueryResult>(IS_LOGGED_IN)
  const isLoggedIn = Boolean(data?.isLoggedIn)
  const { connectUser } = useStreamChat(!isLoggedIn)
  const { isLessMd } = useSizes()

  useOnMount(() => {
    sessionStorageService.clear()
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
    getValues,
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(signInSchema),
  })

  useEffect(() => {
    if (verificationEmail) {
      setValue('email', verificationEmail)
    }
  }, [verificationEmail, setValue])

  const isLoading =
    signInLoading || loading || loadingChecks || resendEmailLoading

  const onSubmit = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => {
    const result = await signInAttorney({
      email,
      password,
      checkForEmailValidation: true,
    })

    const lowerCaseEmail = email.toLowerCase()

    if (result.success) {
      const validationResult = await checkForValidationAndInvitation({
        variables: {
          filter: {
            email: {
              _eq: lowerCaseEmail,
            },
            validation_ts: {
              _nnull: null,
            },
          },
          attorneyInviteFilter: {
            email: {
              _eq: lowerCaseEmail,
            },
          },
        },
      })

      if (
        !validationResult?.data?.user_email_validation?.length &&
        !validationResult?.data?.attorney_invite?.length
      ) {
        // Clear local storage to avoid users from manually navigating to a path
        sessionStorageService.clear()
        setVerificationError(true)
        return
      }

      // Update Apollo cache only if user has verified their email
      client.writeQuery({
        query: gql`
          query UpdateLoggedIn {
            isLoggedIn
          }
        `,
        data: {
          isLoggedIn: true,
        },
      })

      connectUser()

      if (onSignInSuccess) {
        onSignInSuccess()
      } else {
        const redirectUrl =
          location.state?.from?.pathname +
          location.state?.from?.search +
          location.state?.from?.hash
        const userMe = await getUserMeWithRoleData()
        navigate(
          redirectUrl ||
            getSignInRedirectionByRole(get(userMe, 'data.users_me.role.id', ''))
        )
        window.scrollTo(0, 0)
        window.scrollTo(0, 0)
      }
    }

    if (!result?.signInErrors) return

    showAlert({
      severity: 'error',
      message: `${
        BEToNiceErrorMapping['Invalid user credentials.'] ||
        result?.signInErrors?.server
      }`,
    })
  }

  const handleNextClick = async () => {
    const isValid = await trigger()
    if (isValid) {
      await handleSubmit(onSubmit)()
    }
  }

  const currentEmail = getValues('email')

  const onResendEmail = useCallback(async () => {
    if (!currentEmail) return
    const result = await resendVerificationEmail({
      email: currentEmail.toLocaleLowerCase(),
    })
    if (result === 'success') {
      showAlert({
        severity: 'success',
        message: 'Email sent successfully',
      })
    } else {
      showAlert({
        severity: 'error',
        message: 'Error re-sending email',
      })
    }
    // Close alert and clear local storage to avoid
    // spam clicking the resend button
    setVerificationError(false)
  }, [currentEmail, resendVerificationEmail, showAlert])

  const SignInButton = (
    <Button
      onClick={handleNextClick}
      variant="contained"
      type="submit"
      size="large"
      sx={theme => ({
        width: {
          xs: '100%',
          md: theme.spacing(33.75),
        },
      })}
      disabled={isLoading}
    >
      Sign In
    </Button>
  )
  return (
    <AuthLayout>
      <Box
        sx={theme => ({
          maxWidth: theme.spacing(73.75),
          mr: { md: theme.spacing(2.5) },
          p: { xs: theme.spacing(0.5, 1), md: 0 },
          display: { md: 'flex' },
          flexDirection: { md: 'column' },
          justifyContent: { md: 'center' },
          minHeight: '100%',
        })}
      >
        <LogoBar
          withActionOption={!onSignInSuccess}
          buttonHref="/createAccount"
          text="Don't have an account? "
          btnContent="Create Account"
        />
        <Typography
          sx={theme => ({
            mb: theme.spacing(4),
          })}
          variant={isLessMd ? 'h6' : 'h5'}
        >
          Access your Account
        </Typography>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <CreateAccountField
                {...field}
                label="Email *"
                error={!!errors.email}
                helperText={errors.email?.message || ' '}
                variant="outlined"
                fullWidth
                margin="normal"
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <PasswordField
                {...field}
                error={errors.password?.message}
                label="Password *"
              />
            )}
          />
          {verificationError && (
            <VerificationAlert
              onResendVerificationEmail={onResendEmail}
              onCloseAlert={() => setVerificationError(false)}
            />
          )}
          <Box
            mt={2.5}
            mb={2}
            sx={{
              justifyContent: {
                display: 'flex',
                xs: 'center',
                md: 'flex-end',
                position: {
                  xs: 'fixed',
                  md: 'initial',
                },
              },
            }}
          >
            {SignInButton}
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: {
                  xs: '100%',
                  md: '50%',
                },
                justifyContent: 'center',
              }}
            >
              <Button
                variant="text"
                onClick={() => navigate(RoutesData.RResetPassword.path)}
                style={{ cursor: 'pointer' }}
              >
                Forgot Password?
              </Button>
            </Box>
          </Box>
        </StyledForm>
      </Box>
      <JSnackBar
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        horizontal="center"
        vertical="bottom"
        handleClose={handleClose}
      />
    </AuthLayout>
  )
}

export default SignIn

import TextField, { TextFieldProps } from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'

export type SearchProps = TextFieldProps

const Search = ({ sx, InputProps, ...props }: SearchProps) => (
  <TextField
    sx={{
      width: '100%',
      backgroundColor: theme => theme.palette.common.white,
      borderRadius: '4px',
      ...sx,
    }}
    placeholder="Search..."
    size="medium"
    {...props}
    InputProps={{
      ...InputProps,
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />
)
export default Search

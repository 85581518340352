// Import necessary dependencies
import React from 'react'
import { MessageOutlined } from '@mui/icons-material'
import { Button, useTheme } from '@mui/material'
import { StyledBadge } from '@/components/GetStreamChat/StyledBadge'
import { SidebarHeader } from '@/pages/Case/CaseV2/types'

// Define props interface for the MessageButton component
interface MessageButtonProps {
  messageChannels: SidebarHeader['message_channels']
  sendMessageAction?: boolean
}

export const MessageButton: React.FC<MessageButtonProps> = ({
  messageChannels,
  sendMessageAction,
}) => {
  const theme = useTheme()

  // Determine if the button should be displayed
  const showButton =
    (messageChannels && messageChannels.length > 0) || sendMessageAction

  // Early return if button shouldn't be shown
  if (!showButton) return null

  // Determine if this is a single message channel scenario
  const isSingleMessageChannel =
    sendMessageAction || (messageChannels && messageChannels.length === 1)
  const text = isSingleMessageChannel ? 'Send Message' : 'Case Messages'

  // Configure badge display for multiple message channels
  const showBadge = messageChannels && messageChannels.length > 1
  const badgeContent = showBadge ? messageChannels.length : undefined

  // Handle button click based on message channel type
  const handleClick = () => {
    if (isSingleMessageChannel) {
      console.log('clicked send message')
    } else {
      console.log('clicked case messages')
    }
  }

  return (
    <Button
      variant="outlined"
      fullWidth
      size="large"
      startIcon={<MessageOutlined fontSize="small" />}
      onClick={handleClick}
      sx={{ height: theme.spacing(6) }}
    >
      {text}
      {showBadge && (
        <StyledBadge
          badgeContent={badgeContent}
          color="secondary"
          sx={{ ml: 2.5 }}
        />
      )}
    </Button>
  )
}

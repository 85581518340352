import Layout from '@/components/Layout'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { RoutesData } from '@/router/RoutesData'
import { useTheme } from '@mui/material/styles'
import MyReferralsV2Content from './MyReferralsV2'

const MyReferralsV2 = () => {
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <Layout
      sx={{
        paddingTop: theme.spacing(4),
        width: {
          md: '100%',
        },
        flex: {
          md: 1,
        },
        overflow: 'auto',
        paddingBottom: theme.spacing(16),
        '&::-webkit-scrollbar': { display: 'none' },
      }}
    >
      <Container>
        <Grid container justifyContent="space-between">
          <Grid item xs={5}>
            <Typography
              variant="h3"
              sx={{ mb: 1, textAlign: 'left' }}
              id="createNewCaseTitle"
            >
              Referrals
            </Typography>
          </Grid>
          <Grid item xs={5} sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate(RoutesData.RCreateNewCase.path)}
              sx={{ width: 'fit-content', marginRight: 1 }}
            >
              Create New Case
            </Button>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'space-between' },
            marginBottom: 2.25,
            marginTop: 2.5,
            width: '100%',
          }}
        >
          <MyReferralsV2Content />
        </Box>
      </Container>
    </Layout>
  )
}

export default MyReferralsV2

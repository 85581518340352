import { useCallback, useContext, useState } from 'react'
import { TIME_UNIT } from '@/constants'
import { useGetCatalogWaterfallDurationQuery } from '@/gql/appApi'
import { CreateCaseContext } from '@/components/Case/CreateCaseContext/CreateCaseContext'
import { CreateCaseWaterfallList } from '../../types'

interface IUseWaterfallReferralsProps {
  hasPermissionToCreateWaterfalls: boolean
  waterfallLists: {
    orgWaterfalls: CreateCaseWaterfallList[]
    attorneyWaterfalls: CreateCaseWaterfallList[]
  }
}

const useWaterfallReferrals = ({
  hasPermissionToCreateWaterfalls,
  waterfallLists,
}: IUseWaterfallReferralsProps) => {
  const {
    methods: { control, setValue },
  } = useContext(CreateCaseContext)
  const [selectedOption, setSelectedOption] = useState<'waterfall' | 'user'>(
    'waterfall'
  )

  const { data: timeLimitCatalog, loading: loadingTimeLimitOptions } =
    useGetCatalogWaterfallDurationQuery()

  const handleChangeOption = useCallback(
    (option: 'waterfall' | 'user') => {
      setValue('assigneesId', ['', '', ''])
      setValue('timeLimit', 2)
      setValue('timeUnitId', TIME_UNIT.Hours)
      setSelectedOption(option)
    },
    [setValue]
  )

  const assignByWaterfall = selectedOption === 'waterfall'
  const assignByUser = selectedOption === 'user'

  // Conditions:
  // 1. User has no permission to create waterfalls but Org waterfalls exist
  // or assign by waterfall is selected and org waterfalls exist
  // or assign by waterfall is selected and user has permission to create waterfalls and org or attorney waterfalls exist
  const showOrgWaterfallsOnly =
    (!hasPermissionToCreateWaterfalls &&
      waterfallLists.orgWaterfalls.length > 0) ||
    (assignByWaterfall && waterfallLists.orgWaterfalls.length > 0) ||
    (assignByWaterfall &&
      hasPermissionToCreateWaterfalls &&
      (waterfallLists.orgWaterfalls.length > 0 ||
        waterfallLists.attorneyWaterfalls.length > 0))

  // 2. User has permissions but no waterfalls exist
  const showSelectUsersOnly =
    hasPermissionToCreateWaterfalls &&
    waterfallLists.orgWaterfalls.length === 0 &&
    waterfallLists.attorneyWaterfalls.length === 0

  // 3. User has permission to create waterfalls and has waterfalls
  const showAllWaterfallOptions =
    hasPermissionToCreateWaterfalls && !showSelectUsersOnly

  const lists = hasPermissionToCreateWaterfalls
    ? waterfallLists.orgWaterfalls.concat(waterfallLists.attorneyWaterfalls)
    : waterfallLists.orgWaterfalls

  return {
    assignByWaterfall,
    assignByUser,
    control,
    handleChangeOption,
    lists,
    loadingTimeLimitOptions,
    showOrgWaterfallsOnly,
    showSelectUsersOnly,
    showAllWaterfallOptions,
    timeLimitOptions: timeLimitCatalog?.catalog_waterfall_duration || [],
  }
}

export default useWaterfallReferrals

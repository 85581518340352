export enum FeatureFlag {
  MARKETPLACE_LEAD_DOCKET = 'marketplace_lead_docket_integration_enabled',
  MARKETPLACE_LAWMATICS = 'marketplace_lawmatics_integration_enabled',
  MARKETPLACE_LITIFY = 'marketplace_litify_integration_enabled',
  CASE_STATUS_FLOW_SIMPLIFICATION = 'marketplace_case_status_flow_simplification_enabled',
  SALESFORCE_INTEGRATION = 'marketplace_salesforce_integration_enabled',
  CLIO_INTEGRATION = 'marketplace_clio_integration_enabled',
  NEGOTIATION_FLOW_IMPROVEMENTS = 'marketplace_1870_negotiation_flow_improvements_enabled',
  NOTIFICATION_BANNER = 'marketplace_notification_banner_enabled',
  SESSION_CONTAMINATION_PREVENTION = 'marketplace_session_contamination_prevention_enabled',
  MY_REFERRALS_V3 = 'marketplace_my_referrals_v3_enabled',
  MARKETPLACE_CASE_PAGE_V2 = 'marketplace_case_page_v2_enabled',
  MY_CASE_INTEGRATION = 'marketplace_mycase_integration_enabled',
}

export const hexColorWithTransparency = (
  hexColor = '',
  opacity = 0
): string => {
  // Allow either full hex (#000000) or short form hex (#000)
  if (hexColor.length !== 4 && hexColor.length !== 7) {
    return ''
  }
  let hexAlpha = Math.round(Math.min(Math.max(opacity, 0), 1) * 255).toString(
    16
  )

  // add leading zero (toString returns without leading zero)
  if (hexAlpha.length === 1) {
    hexAlpha = `0${hexAlpha}`
  }

  return `${hexColor}${hexAlpha.toUpperCase()}`
}

export function getAppHeights() {
  const staticNavigationBar = document.getElementById('static-navigation-bar')
  const staticNavigationBarHeight = staticNavigationBar
    ? `${staticNavigationBar.offsetHeight}px`
    : '0px'

  const contentHeight = staticNavigationBar
    ? `100vh - ${staticNavigationBarHeight}`
    : '100vh'

  return { contentHeight, staticNavigationBarHeight }
}

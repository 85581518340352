import { useEffect, useMemo, useState } from 'react'
import {
  useGetAttorneysProfileByIdQuery,
  useGetMarketplacePreferencesQuery,
} from '@/gql/appApi'
import useAttorneyId from '@/hooks/useAttorneyId'
import { CASE_PREFERENCE, LICENSE_STATUS } from '@/constants'

export const useAlgoliaMarketplace = () => {
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = getAttorneyId()
  const [hiddenCaseIds, setHiddenCaseIds] = useState<string[]>([])

  const { data: preferencesData } = useGetMarketplacePreferencesQuery()

  useEffect(() => {
    if (preferencesData?.attorney_case_mp_preference) {
      const hiddenIds = preferencesData.attorney_case_mp_preference.reduce<
        string[]
      >((acc, pref) => {
        if (
          pref.case_mp_preference_id?.id === CASE_PREFERENCE.Hidden &&
          pref.case_id?.id != null
        ) {
          acc.push(pref.case_id.id)
        }
        return acc
      }, [])
      setHiddenCaseIds(hiddenIds)
    }
  }, [preferencesData])

  const { data: myAttorneyProfileData, loading: isAttorneyProfileLoading } =
    useGetAttorneysProfileByIdQuery({
      variables: {
        filter: {
          attorney_id: {
            id: {
              _eq: attorneyId,
            },
          },
        },
        licenseFilter: {
          status: {
            id: {
              _eq: LICENSE_STATUS.VALIDATED,
            },
          },
        },
      },
    })

  const myJurisdictions = useMemo(
    () =>
      myAttorneyProfileData?.attorney_profile?.[0]?.licenses
        ?.map(license => license?.license_state?.name)
        .filter((name): name is string => Boolean(name)) || [],
    [myAttorneyProfileData]
  )

  const myPracticeAreas = useMemo(
    () =>
      myAttorneyProfileData?.attorney_profile?.[0]?.practice_areas
        ?.map(area => area?.practice_area_id?.name)
        .filter((name): name is string => Boolean(name)) || [],
    [myAttorneyProfileData]
  )

  const optionalFilters = useMemo(() => {
    // Boosts the score of results which only match the jurisdiction - Score: 10
    const jurisdictionFilters = myJurisdictions.map(
      state => `catalog_us_state_name:${state}<score=10>`
    )

    // Boosts the score of results which only match the practice area - Score: 5
    const practiceAreaFilters = myPracticeAreas.map(
      area => `catalog_practice_area:${area}<score=5>`
    )

    // Boosts the score of results which match both jurisdiction and practice area - Score: 20
    const combinedFilters = myJurisdictions.flatMap(state =>
      myPracticeAreas.map(
        area =>
          `(catalog_us_state_name:${state} AND catalog_practice_area:${area})<score=20>`
      )
    )

    return [...combinedFilters, ...jurisdictionFilters, ...practiceAreaFilters]
  }, [myJurisdictions, myPracticeAreas])

  const myOrganizationsFilters = useMemo(() => {
    const myOrganizationIds =
      myAttorneyProfileData?.attorney_profile?.[0]?.attorney_id?.user_id?.current_organization?.map(
        org => org?.organization_id?.id
      ) || []

    if (myOrganizationIds.length === 0) {
      return ''
    }

    return myOrganizationIds
      .map(organizationId => `NOT organization_id:'${organizationId}'`)
      .join(' AND ')
  }, [myAttorneyProfileData])

  const hiddenCasesFilters = useMemo(
    () => hiddenCaseIds.map(id => `NOT objectID:${id}`).join(' AND '),
    [hiddenCaseIds]
  )

  return {
    optionalFilters,
    myOrganizationsFilters,
    isAttorneyProfileLoading,
    hiddenCasesFilters,
  }
}

import { useStreamChat } from '@/hooks/useStreamChat'
import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'
import { Channel as ChannelType } from 'stream-chat'
import {
  Channel,
  Chat,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from 'stream-chat-react'
import 'stream-chat-react/dist/css/v2/index.css'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { isChannelOption } from '@/context/ChatClientContext'
import { useUsersMeQuery } from '@/gql/systemApi'
import { theme } from '@/styles/theme'
import ChatChannelHeader from './ChatChannelHeader'

const FallbackChatChannel = ({ onBackClick }: { onBackClick: () => void }) => (
  <Box
    sx={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      height: '100%',
    }}
  >
    <Box
      sx={{
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4,
      }}
    >
      <Typography variant="body1" textAlign="center">
        The channel you are trying to access is not available or you are not a
        member of it.
      </Typography>
      <Button onClick={onBackClick} variant="contained" color="primary">
        Go to channels
      </Button>
    </Box>
  </Box>
)

const ChatChannel = () => {
  const {
    client,
    activeChannelId,
    setActiveChannelId,
    getChannelById,
    setSelectedTab,
    isChannelArchived,
  } = useStreamChat()

  const { loading: userMeLoading } = useUsersMeQuery()
  const [activeChannel, setActiveChannel] = useState<ChannelType | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let isMounted = true
    const fetchChannel = async () => {
      setLoading(true)
      if (!activeChannelId || !client) {
        setActiveChannel(null)
        setLoading(false)
        return
      }

      try {
        const channel = await getChannelById(activeChannelId)
        if (!channel) throw new Error('Channel not found')
        if (isMounted) {
          // Set the selected tab based on the channel type for better "go back" UX
          const channelType = channel?.data?.channel_type
          if (isChannelOption(channelType)) setSelectedTab(channelType)

          setActiveChannel(channel)
        }
      } catch (error) {
        console.error('Failed to fetch channel', error)
      } finally {
        if (isMounted) {
          setLoading(false)
        }
      }
    }

    if (!userMeLoading) fetchChannel()

    return () => {
      isMounted = false
    }
  }, [client, activeChannelId, getChannelById, setSelectedTab, userMeLoading])

  const handleBackClick = () => {
    setActiveChannelId(null)
    setActiveChannel(null)

    const url = new URL(window.location.href)
    url.searchParams.delete('activeChannelId')
    window.history.replaceState({}, '', url.toString())
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 2,
          background: theme.palette.common.white,
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (!client) {
    console.error('Stream Chat: Client is not available')
    return <FallbackChatChannel onBackClick={handleBackClick} />
  }

  if (!activeChannelId) {
    console.error('Stream Chat: No active channel ID provided')
    return <FallbackChatChannel onBackClick={handleBackClick} />
  }

  if (!activeChannel) {
    console.error('Stream Chat: Unable to load chat channel: ', activeChannelId)
    return <FallbackChatChannel onBackClick={handleBackClick} />
  }

  const isChatDisabled = isChannelArchived(activeChannel)
  const chatDisabledMessage =
    'This channel has been archived and is now read-only.'

  return (
    <Chat client={client}>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          height: '100%',
          '& .str-chat': {
            '--str-chat__primary-color': theme.palette.secondary.main,
            '--str-chat__message-textarea-background-color':
              theme.palette.grey[100],
          },
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 2,
        }}
      >
        <Channel channel={activeChannel}>
          <Window>
            <ChatChannelHeader channel={activeChannel} />
            <Box
              sx={{
                flex: 1,
                height: '100%',
                display: 'flex',
                flexShrink: 1,
                flexDirection: 'column',
                overflowY: 'auto',
              }}
            >
              <MessageList />
            </Box>
            <MessageInput
              audioRecordingEnabled
              grow
              focus
              disabled={isChatDisabled}
              getDefaultValue={() =>
                isChatDisabled ? chatDisabledMessage : ''
              }
              hideSendButton={isChatDisabled}
              noFiles={isChatDisabled}
            />
          </Window>
          <Thread />
        </Channel>
      </Box>
    </Chat>
  )
}

export default ChatChannel

import { alpha, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const EmptyWaterfalls = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '400px',
      }}
    >
      <Typography
        variant="subtitle1"
        color={alpha(theme.palette.common.black, 0.38)}
        sx={{
          mt: 2,
        }}
      >
        No Waterfalls have been created yet
      </Typography>
    </Box>
  )
}
export default EmptyWaterfalls

import { useCallback, useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Collapse from '@mui/material/Collapse'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/material/styles/useTheme'

import JSnackBar from '@/components/atoms/JSnackBar'

import { useRemoveIntegrationItem } from '@/utils/api/integrations'
import { useSnackBar } from '@/hooks/useSnackBar'
import LeadDocketCollapsableContent from './LeadDocketCollapsableContent'
import { IntegrationItemProps } from '../../types'

import textContent, { INTEGRATION_LINKS } from '../../constants'
import IntegrationItemCardContent from '../shared/IntegrationItemCardContent'
import IntegrationItemExpandableButton from '../shared/IntegrationItemExpandableButton'
import AdditionalInstructions from '../shared/AdditionalInstructions'

const LeadDocketIntegrationItem = ({
  icon,
  name,
  subtitle,
  loading,
  organizationId,
  isConnectedToOtherLcms,
  isConnected,
  refetchLcmsData,
  removeIntegrationItem,
  isConnectingToLcms,
  connectingToLcms,
}: IntegrationItemProps) => {
  const { INTEGRATION_V2 } = textContent
  const theme = useTheme()
  const isSmallSize = useMediaQuery('(min-width:900px)')

  const [expanded, setExpanded] = useState(false)
  const [removeStatus, setRemoveStatus] = useState<boolean>(false)

  const { alert, handleClose } = useSnackBar()

  const { data: removeLeadDocketData, isLoading: removeLeadDocketIsLoading } =
    useRemoveIntegrationItem(organizationId, removeStatus)

  const updateLeadDocketConnection = useCallback(
    (connected: boolean) => {
      if (connected) {
        setExpanded(false)
      }
    },
    [setExpanded]
  )

  const disconnectFromLeadDocket = () => {
    removeIntegrationItem({
      integrationName: name,
      onSubmit: () => {
        setRemoveStatus(true)
      },
    })
  }

  const renderLeadDocketButtons = () => {
    if (
      loading ||
      isConnectingToLcms ||
      (removeLeadDocketIsLoading && !removeStatus)
    ) {
      return (
        <Button
          disabled
          sx={{
            [theme.breakpoints.up('md')]: {
              width: '200px',
            },
          }}
          variant="outlined"
          size="large"
        >
          Loading...
        </Button>
      )
    }

    if (
      isConnected ||
      removeLeadDocketData?.statusCode === 404 ||
      removeLeadDocketData?.statusCode === 500
    ) {
      return (
        <Button
          sx={{
            [theme.breakpoints.up('md')]: {
              width: '200px',
            },
          }}
          variant="outlined"
          size="large"
          onClick={disconnectFromLeadDocket}
          disabled={removeStatus}
        >
          Disconnect
        </Button>
      )
    }
    return (
      <IntegrationItemExpandableButton
        expanded={expanded}
        setExpanded={setExpanded}
        isConnectedToOtherLcms={isConnectedToOtherLcms}
      />
    )
  }

  const leadDocketName =
    expanded && isSmallSize ? INTEGRATION_V2.LEAD_DOCKET.NAME_EXPANDED : name

  useEffect(() => {
    if (
      removeStatus &&
      !removeLeadDocketIsLoading &&
      removeLeadDocketData?.message === 'deleted'
    ) {
      setRemoveStatus(false)
      connectingToLcms(false)
      refetchLcmsData()
    }
  }, [
    connectingToLcms,
    refetchLcmsData,
    removeLeadDocketData,
    removeLeadDocketIsLoading,
    removeStatus,
  ])

  return (
    <Card
      sx={{
        margin: theme.spacing(0, 0, 4),
        padding: theme.spacing(2),
        boxShadow: 2,
      }}
    >
      <IntegrationItemCardContent
        actions={renderLeadDocketButtons()}
        icon={icon}
        name={`${isConnected ? 'Connected to ' : ''}${leadDocketName}`}
        subtitle={
          <AdditionalInstructions
            isConnected={isConnected}
            linkUrl={INTEGRATION_LINKS.LEAD_DOCKET_INSTRUCTIONS_URL}
            subtitle={subtitle}
          />
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <LeadDocketCollapsableContent
          refetchLcmsData={refetchLcmsData}
          name={name}
          organizationId={organizationId}
          updateLeadDocketConnection={updateLeadDocketConnection}
          connectingToLcms={connectingToLcms}
        />
      </Collapse>
      <JSnackBar
        vertical="bottom"
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={handleClose}
      />
    </Card>
  )
}

export default LeadDocketIntegrationItem

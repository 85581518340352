/* eslint-disable no-nested-ternary */
import { useCallback } from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import JReferralCard from '@/components/JReferralCard'
import { CaseChatProvider } from '@/providers/CaseChatProvider'
import { CASE_STATUS_FLOW } from '@/constants'
import { ReferralType } from '@/components/JReferralCard/types'
import DataroomRequests from '../DataroomRequests'
import NegotiationItem from '../../NegotiationItem'
import { CaseEntry, CasePurchase } from '../../types'
import useReferralItems from './hooks/useReferralItems'
import CaseWatching from './CaseWatching'
import MyReferralsLeads from './MyReferralsLeads'

export interface IReferralItemsProps {
  data: CaseEntry[]
  keyId?: string
  needsYourAttention?: boolean
  refetch?: () => Promise<void>
  isRefetching?: boolean
}

interface INegotiationItemProps {
  negotiations: CasePurchase[]
  newCase: CaseEntry
  isAgreement: boolean
  isWatchingOnly?: boolean
  casePurchaseWithPayment?: CasePurchase
  isPrivate?: boolean
  isInitiatingAttorney: boolean
}

const ReferralItems = ({
  data,
  keyId = '',
  needsYourAttention,
  refetch,
  isRefetching,
}: IReferralItemsProps) => {
  const {
    goToCaseProfile,
    getCaseParams,
    getNegotiationParams,
    onStopWatching,
    userId,
    loading,
    rolesLoading,
    setError,
    error,
    isCaseStatusFlowSimplificationEnabled,
  } = useReferralItems({ refetch })

  const renderNegotations = useCallback(
    ({
      negotiations,
      newCase,
      isAgreement,
      isWatchingOnly,
      casePurchaseWithPayment,
      isPrivate,
      isInitiatingAttorney,
    }: INegotiationItemProps) => {
      const sellerUserIds = newCase?.ownerships || []
      if (isWatchingOnly) {
        return (
          <CaseChatProvider
            caseId={newCase.id}
            members={sellerUserIds}
            isCasePage={false}
            caseTitle={newCase.name}
            isCaseOwner={false}
          >
            <CaseWatching caseEntry={newCase} refetch={refetch} />
          </CaseChatProvider>
        )
      }
      return negotiations.map(lastNegotiation => {
        const {
          casePurchaseStatus,
          dateText,
          activityCreatedById,
          type,
          fee,
          profile,
          isCaseOwner,
          caseTaken,
          feeText,
          representingAttorneyId,
          representingAttorneyUserId,
          paymentStatus,
          isInvited,
          caseStatus,
          amountToPay,
          proposalExpirationDate,
        } = getNegotiationParams(
          lastNegotiation,
          newCase,
          casePurchaseWithPayment
        )

        if (!profile) {
          return null
        }

        return (
          <div key={`negotiation-${newCase.id}-${lastNegotiation.id}`}>
            <CaseChatProvider
              caseId={newCase.id}
              members={sellerUserIds}
              isCasePage={false}
              caseTitle={newCase.name}
              isCaseOwner={isCaseOwner}
            >
              <NegotiationItem
                casePurchaseId={lastNegotiation.id}
                profile={profile}
                fee={fee ?? 0}
                casePurchaseStatus={casePurchaseStatus}
                caseTitle={newCase?.name}
                caseId={newCase?.id}
                activityCreatedById={activityCreatedById || ''}
                isCaseOwner={isCaseOwner}
                dateReceived={dateText}
                type={type}
                caseTaken={caseTaken}
                onSuccess={refetch}
                feeText={isAgreement ? 'Proposal Accepted' : feeText}
                representingAttorneyId={representingAttorneyId || ''}
                representingAttorneyUserId={representingAttorneyUserId}
                paymentStatus={paymentStatus}
                isRefetching={isRefetching}
                userId={userId}
                isInvited={isInvited}
                isPrivate={isPrivate}
                caseStatus={caseStatus}
                isInitiatingAttorney={isInitiatingAttorney}
                amountToPay={amountToPay}
                isCaseStatusFlowSimplificationEnabled={
                  isCaseStatusFlowSimplificationEnabled
                }
                proposalExpirationDate={proposalExpirationDate}
                {...(isAgreement && {
                  agreement: <Box sx={{ display: 'none' }}>Content</Box>,
                })}
              />
            </CaseChatProvider>
          </div>
        )
      })
    },
    [
      getNegotiationParams,
      isRefetching,
      refetch,
      userId,
      isCaseStatusFlowSimplificationEnabled,
    ]
  )

  const renderCases = useCallback(() => {
    if (data)
      return data.map(caseEntry => {
        const {
          referralType,
          isPrivate,
          cardUsers,
          negotiations,
          negotiationAmount,
          dataroom,
          leads,
          showInviteToCase,
          datePosted,
          isAgreement,
          caseMpPreferenceId,
          isFollowingOnly,
          fee,
          casePurchaseWithPayment,
          bothPartiesPaid,
          showActionsForCaseManagerWithOwnership,
          representingAttorneyId,
          showDataroomRequests,
          showLeads,
          isInitiatingAttorney,
        } = getCaseParams(caseEntry)

        // If FF is enabled, the referral type changes to 'matched' or 'signed' if applicable,
        // otherwise it will be the same as the original referral type
        let caseStatusReferralType: ReferralType = referralType
        if (isCaseStatusFlowSimplificationEnabled) {
          if (
            caseEntry?.status === CASE_STATUS_FLOW.Matched &&
            // With the new case status flow, the referral type will change to 'matched' if one of the parties has paid,
            // which would fall under one of the following referral types:
            [
              'agreement',
              'acceptedByCurrentUser',
              'acceptedByCounterpart',
            ].includes(referralType)
          ) {
            caseStatusReferralType = 'matched'
          }
          if (caseEntry?.status === CASE_STATUS_FLOW.Signed) {
            caseStatusReferralType = 'signed'
          }
        }

        return (
          <JReferralCard
            key={`${keyId}-${caseEntry.id}`}
            caseId={caseEntry.id}
            title={caseEntry?.name}
            fee={showActionsForCaseManagerWithOwnership ? fee : undefined}
            referralType={caseStatusReferralType}
            isPrivate={isPrivate}
            users={cardUsers}
            onGoToCaseProfile={() => goToCaseProfile(caseEntry.id)}
            showInviteToCase={showInviteToCase && !isAgreement}
            showStatusBar={needsYourAttention}
            dataroomRequest={dataroom.length > 0}
            datePosted={datePosted}
            onStopWatching={
              caseEntry?.following && caseMpPreferenceId
                ? () => onStopWatching(caseMpPreferenceId)
                : undefined
            }
            loading={loading || isRefetching}
            bothPartiesPaid={bothPartiesPaid}
            representingAttorneyId={representingAttorneyId}
            casePracticeArea={caseEntry?.type || ''}
            jurisdiction={caseEntry?.jurisdiction || ''}
          >
            {negotiationAmount > 0 && showActionsForCaseManagerWithOwnership
              ? renderNegotations({
                  negotiations,
                  newCase: caseEntry,
                  isAgreement,
                  casePurchaseWithPayment,
                  isPrivate,
                  isInitiatingAttorney,
                })
              : isFollowingOnly && showActionsForCaseManagerWithOwnership
              ? renderNegotations({
                  negotiations,
                  newCase: caseEntry,
                  isAgreement,
                  isWatchingOnly: true,
                  casePurchaseWithPayment,
                  isPrivate,
                  isInitiatingAttorney,
                })
              : null}
            {showDataroomRequests && <DataroomRequests dataroom={dataroom} />}
            {showLeads && <MyReferralsLeads leads={leads} />}
          </JReferralCard>
        )
      })
    return null
  }, [
    keyId,
    data,
    getCaseParams,
    needsYourAttention,
    loading,
    isRefetching,
    renderNegotations,
    goToCaseProfile,
    onStopWatching,
    isCaseStatusFlowSimplificationEnabled,
  ])

  if (rolesLoading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Skeleton variant="rounded" height={350} sx={{ mt: 2 }} />
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {error && (
        <Alert severity="error" onClose={() => setError('')}>
          {error}
        </Alert>
      )}
      {renderCases()}
    </Box>
  )
}

export default ReferralItems

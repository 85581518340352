import { useCallback, useState } from 'react'

function useListFilesState<T>(initialList: T[]): [
  T[],
  {
    length: number
    enqueueList: (items: T[]) => void
    deleteIndexList: (index: number) => Promise<T[]>
    setItemStatusByIndexList: (index: number, newStatus: any) => void
    setItemErrorByIndexList: (index: number, error: string) => void
  }
] {
  const [list, setList] = useState<T[]>([...initialList])

  const enqueueList = useCallback((items: T[]) => {
    setList(prev => [...prev, ...items])
  }, [])

  const deleteIndexList = useCallback(
    (index: number): Promise<T[]> => {
      if (index <= list.length) {
        return new Promise(resolve => {
          setList(prev => {
            const newList = [...prev]

            newList[index] = {
              ...newList[index],
              file: null,
              isHidden: true,
            }

            resolve(newList)

            return newList
          })
        })
      }

      return Promise.resolve(list)
    },
    [list]
  )

  const setItemStatusByIndexList = useCallback(
    (index: number, newStatus: any) => {
      if (index <= list.length) {
        setList(prev => {
          const newList = [...prev]

          newList[index] = {
            ...newList[index],
            status: newStatus,
            file: null,
            isHidden: true,
          }

          return newList
        })
      }
    },
    [list]
  )

  const setItemErrorByIndexList = useCallback(
    (index: number, error: string) => {
      if (index <= list.length) {
        setList(prev => {
          const newList = [...prev]

          newList[index] = {
            ...newList[index],
            file: null,
            error,
          }

          return newList
        })
      }
    },
    [list]
  )

  const controls = {
    length: list.length,
    enqueueList,
    deleteIndexList,
    setItemStatusByIndexList,
    setItemErrorByIndexList,
  }

  return [list, controls]
}

export { useListFilesState }

import { useCallback } from 'react'

import {
  CASE_PURCHASE_STATUS,
  CASE_STATUS_FLOW,
  CONTACT_TYPES,
  PAYMENT_STATUS,
} from '@/constants'
import { Case_Lead_Contact, Case_Purchase_Activity } from '@/gql/appApi'

export interface IUsePurchaseAgreementsProps {
  activeAttorneyId?: string
  isCaseStatusFlowSimplificationEnabled?: boolean
}

interface CurrentUserHasPaidParams {
  agreement?: Case_Purchase_Activity
  hasPayment: boolean
}

interface IGetLeadsParams {
  caseActivity?: Case_Purchase_Activity
  isCaseManagerWithNoAccess: boolean
  isCaseOwner: boolean
  finalizedAgreement: boolean
}

const usePurchaseAgreements = ({
  activeAttorneyId,
  isCaseStatusFlowSimplificationEnabled,
}: IUsePurchaseAgreementsProps) => {
  const currentUserHasPaid = useCallback(
    ({ agreement, hasPayment }: CurrentUserHasPaidParams) =>
      hasPayment &&
      agreement?.case_purchase_id?.payments?.some(
        payment =>
          payment?.attorney_id?.id === activeAttorneyId &&
          (payment?.payment_status_id?.id === PAYMENT_STATUS.Uncaptured ||
            payment?.payment_status_id?.id === PAYMENT_STATUS.Completed)
      ),
    [activeAttorneyId]
  )

  const getEmailOrPhone = useCallback(
    (contacts: Case_Lead_Contact[], type: 'email' | 'phone') => {
      const contactValue = contacts.find(
        contact =>
          contact?.type?.id ===
          (type === 'email'
            ? CONTACT_TYPES.EmailContactType
            : CONTACT_TYPES.PhoneContactType)
      )

      if (type === 'phone') {
        const phone = contactValue?.value || ''
        const cleaned = `''${phone}`.replace(/\D/g, '')
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`
        }
        return contactValue?.value || ''
      }
      return contactValue?.value || ''
    },
    []
  )

  const getPurchaseAgreementParams = useCallback(
    (agreement: Case_Purchase_Activity) => {
      const caseCreatedBy =
        agreement?.case_purchase_id?.case_id?.attorney_id?.id
      const isCaseOwner = caseCreatedBy === activeAttorneyId
      const profileToDisplay = isCaseOwner
        ? agreement?.case_purchase_id?.prospect_attorney_id?.profiles?.[0]
        : agreement?.case_purchase_id?.case_id?.attorney_id?.profiles?.[0]
      const hasPayment =
        (agreement?.case_purchase_id?.payments?.length ?? 0) > 0
      const bothPartiesPaid =
        (agreement?.case_purchase_id?.payments?.length ?? 0) >= 2
      const currentUserWithValidPayment = currentUserHasPaid({
        agreement,
        hasPayment,
      })

      const isCasePurchaseAccepted =
        agreement?.activity_entry_status?.id === CASE_PURCHASE_STATUS.Accept

      const isCaseMatched =
        agreement?.case_purchase_id?.case_id?.status ===
        CASE_STATUS_FLOW.Matched

      const isCaseSigned =
        agreement?.case_purchase_id?.case_id?.status === CASE_STATUS_FLOW.Signed

      const buttonLabel = 'Reach Agreement'

      const finalizedAgreement = isCaseStatusFlowSimplificationEnabled
        ? (isCasePurchaseAccepted && isCaseMatched) || isCaseSigned
        : bothPartiesPaid

      const paymentEmail = isCaseOwner
        ? agreement?.case_purchase_id?.case_id?.attorney_id?.profiles?.[0]
            ?.attorney_id?.user_id?.email
        : agreement?.case_purchase_id?.prospect_attorney_id?.profiles?.[0]
            ?.attorney_id?.user_id?.email

      return {
        caseCreatedBy,
        isCaseOwner,
        currentUserWithValidPayment,
        profileToDisplay,
        buttonLabel,
        finalizedAgreement,
        paymentEmail,
        hasPayment,
        bothPartiesPaid,
      }
    },
    [
      activeAttorneyId,
      currentUserHasPaid,
      isCaseStatusFlowSimplificationEnabled,
    ]
  )

  const getLeads = useCallback(
    ({
      caseActivity,
      isCaseManagerWithNoAccess,
      isCaseOwner,
      finalizedAgreement,
    }: IGetLeadsParams) => {
      if (!caseActivity || isCaseManagerWithNoAccess) return undefined
      const caseLeads =
        (caseActivity?.case_purchase_id?.case_id?.leads?.length ?? 0) > 0
          ? caseActivity?.case_purchase_id?.case_id?.leads
          : undefined

      const leadInfo = caseLeads?.map(lead => {
        const leadContacts = lead?.contacts?.filter(
          contact => contact !== null
        ) as Case_Lead_Contact[]

        return {
          id: lead?.id,
          firstName: (lead?.first_name && String(lead.first_name)) || '',
          lastName: (lead?.last_name && String(lead.last_name)) || '',
          email: getEmailOrPhone(leadContacts, 'email'),
          phone: getEmailOrPhone(leadContacts, 'phone'),
        }
      })
      if (isCaseOwner) return leadInfo
      if (finalizedAgreement) return leadInfo
      return undefined
    },
    [getEmailOrPhone]
  )

  return { getPurchaseAgreementParams, getLeads, currentUserHasPaid }
}

export default usePurchaseAgreements

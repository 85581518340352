import {
  useRemoveIntegrationItem,
  useSetupMyCase,
} from '@/utils/api/integrations'

export interface UseMyCaseIntegrationItemProps {
  code: string
  connectMyCase: boolean
  organizationId: string
  myCaseName: string
  myCaseRemoveStatus: boolean
}

export const useMyCaseIntegrationItem = ({
  code,
  connectMyCase,
  myCaseRemoveStatus,
  organizationId,
  myCaseName,
}: UseMyCaseIntegrationItemProps) => {
  const connectMyCaseIntegration = () => {
    const url = import.meta.env.VITE_MYCASE_OAUTH_URL
    window.open(url, '_self', 'noopener,noreferrer')
  }

  const config = {
    grant_code: code.replace(/ /g, '+'),
  }

  const { data: removeMyCaseData, isLoading: removeMyCaseIsLoading } =
    useRemoveIntegrationItem(organizationId, myCaseRemoveStatus)

  const {
    data: connectMyCaseIntegrationData,
    isLoading: isMyCaseIntegrationLoading,
  } = useSetupMyCase(
    {
      organization_id: organizationId,
      lcms: myCaseName,
      config,
    },
    myCaseRemoveStatus,
    connectMyCase
  )

  return {
    connectMyCaseIntegration,
    connectMyCaseIntegrationData,
    isMyCaseIntegrationLoading,
    removeMyCaseData,
    removeMyCaseIsLoading,
  }
}

import Grid from '@mui/material/Grid'
import JTextField from '@/components/atoms/JTextField'
import { MAX_CASE_DESCRIPTION_LENGTH } from '@/constants'
import { JRichTextEditor } from '@/components/atoms/JRichTextEditor'
import JAutocomplete from '@/components/atoms/JAutocomplete'
import { useContext } from 'react'
import { CreateCaseContext } from '../../CreateCaseContext/CreateCaseContext'
import ConfidentialityCheckbox from '../ConfidentialityCheckbox'
import CreateCaseSectionLayout from './CreateCaseSectionLayout'

const CaseOverview = () => {
  const {
    caseTypes,
    methods: { control, setValue, trigger },
    usStates,
  } = useContext(CreateCaseContext)

  return (
    <CreateCaseSectionLayout
      title="Case Overview"
      subtitle="These details will be viewable by other attorneys. The more information the better."
      sx={{
        mb: 5,
        borderBottom: `1px solid`,
        borderColor: 'divider',
      }}
    >
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12}>
          <JTextField
            name="caseName"
            control={control}
            label="Case Name"
            placeholder="Case Name"
            variant="outlined"
            fullWidth
            InputProps={{ inputProps: { maxLength: 90 } }}
            sx={{
              marginBottom: 1.5,
            }}
            rules={{
              required: 'Please enter a Name for the Case.',
              minLength: {
                value: 3,
                message: 'The Case Name should be at least 3 characters.',
              },
              maxLength: {
                value: 90,
                message: 'The Case Name should not exceed 90 characters.',
              },
            }}
            fitTextField
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <JAutocomplete
            control={control}
            name="caseType"
            options={caseTypes}
            label="Case Type"
            fullWidth
            getOptionLabel={option => option.name}
            rules={{
              required: 'Please select a Case Type.',
            }}
            fitTextField
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <JAutocomplete
            control={control}
            name="jurisdiction"
            options={usStates}
            label="Jurisdiction"
            fullWidth
            getOptionLabel={option => `${option.code} - ${option.name}`}
            rules={{
              required: 'Please select a Jurisdiction.',
            }}
            fitTextField
          />
        </Grid>
        <Grid item xs={12}>
          <JRichTextEditor
            sx={{ mb: 2 }}
            name="caseDescriptionFormatted"
            control={control}
            onChange={({ plainText, formattedText }) => {
              setValue('caseDescription', plainText, { shouldDirty: true })
              setValue('caseDescriptionFormatted', formattedText, {
                shouldDirty: true,
              })
            }}
            rules={{
              required: 'Please enter a Description for the Case.',
              maxLength: {
                value: MAX_CASE_DESCRIPTION_LENGTH,
                message: `The Case Description should not exceed ${MAX_CASE_DESCRIPTION_LENGTH} characters.`,
              },
            }}
            onBlur={async () => {
              await trigger('caseDescription')
            }}
            placeholder="Case Description *"
            minHeight={100}
          />
        </Grid>
      </Grid>
      <ConfidentialityCheckbox control={control} rules={{ required: true }} />
    </CreateCaseSectionLayout>
  )
}

export default CaseOverview

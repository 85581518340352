import { useState, useEffect } from 'react'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import {
  Control,
  FieldValues,
  UseControllerProps,
  Controller,
} from 'react-hook-form'

import { useDebounceSchools } from '@/hooks/useDebounceSchools'
import { TEducationSchool } from '@/pages/Profile/types'

export type JAutocompleteProps<FV extends FieldValues = FieldValues> = Omit<
  AutocompleteProps<any, boolean, boolean, boolean>,
  'options' | 'getOptionLabel' | 'onChange' | 'renderInput' | 'setInputValue'
> &
  UseControllerProps<FV> & {
    isUndergradSchool?: boolean
    control: Control<FV>
    name: string
    rules?: string | UseControllerProps<FV>['rules']
    label: string
    valueKey?: string
    noValueKey?: boolean
  }

const JSchoolSearch = <FV extends FieldValues>({
  control,
  name,
  label,
  rules,
  noValueKey,
  value,
  isUndergradSchool,
  ...rest
}: JAutocompleteProps<FV>) => {
  const [inputValue, setInputValue] = useState('')
  const [innerValue, setInnerValue] = useState(value)
  const [firstTime, setFirstTime] = useState(true)

  useEffect(() => {
    if (firstTime && value) {
      setInnerValue(value)
      setFirstTime(false)
    }
  }, [value, firstTime])

  const { options: lawSchools, loading: loadingLawSchools } =
    useDebounceSchools({ value: innerValue, inputValue, isUndergradSchool })
  // If the "rules" prop exists, update the label with an asterisk as suffix
  const textInputLabel = `${label ?? ''}${rules ? ' *' : ''}`
  return (
    <Controller<FV>
      name={name}
      control={control}
      rules={typeof rules === 'string' ? { required: rules } : rules}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <Autocomplete
          autoComplete
          includeInputInList
          filterSelectedOptions
          loading={loadingLawSchools}
          options={lawSchools}
          noOptionsText={
            innerValue || !inputValue
              ? 'Type to Search for Schools'
              : 'No matches found'
          }
          getOptionLabel={(option: TEducationSchool) => option.name || ''}
          isOptionEqualToValue={(option, v) => option?.id === v?.id}
          filterOptions={x => x}
          onInputChange={(e, newInputValue) => {
            e?.stopPropagation()
            setInputValue(newInputValue)
          }}
          value={innerValue}
          onChange={(e, optionData) => {
            e.stopPropagation()
            setInnerValue(optionData)
            onChange(optionData)
          }}
          renderInput={params => (
            <TextField
              {...params}
              error={!!error}
              helperText={error ? error.message : ' '}
              variant="outlined"
              label={textInputLabel}
            />
          )}
          {...rest}
        />
      )}
    />
  )
}

export default JSchoolSearch

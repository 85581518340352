import {
  SessionKey,
  sessionStorageService,
} from '@/services/SessionStorage/SessionStorageService'

const useAttorneyProfileId = () => {
  const getAttorneyProfileId = () =>
    sessionStorageService.getItem(SessionKey.ATTORNEY_PROFILE_ID_KEY)
  const setAttorneyProfileId = (id: string) =>
    sessionStorageService.setItem(SessionKey.ATTORNEY_PROFILE_ID_KEY, id)
  const removeAttorneyProfileId = () =>
    sessionStorageService.removeItem(SessionKey.ATTORNEY_PROFILE_ID_KEY)

  return {
    getAttorneyProfileId,
    setAttorneyProfileId,
    removeAttorneyProfileId,
  }
}

export default useAttorneyProfileId

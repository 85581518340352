import React from 'react'

export const useAddCaseManagerModal = () => {
  const [openModal, setOpenModal] = React.useState<boolean>(false)

  return {
    openModal,
    setOpenModal,
  }
}

export default useAddCaseManagerModal

export class MemoryStorage extends Storage {
  private storage: Record<string, string> = {}

  clear(): void {
    this.storage = {}
  }

  getItem(key: string) {
    return this.storage[key]
  }

  removeItem(key: string) {
    delete this.storage[key]
  }

  setItem(key: string, value: string) {
    this.storage[key] = value
  }
}

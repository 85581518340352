import { ReactNode, createContext, useCallback, useMemo, useState } from 'react'
import useAttorneyId from '@/hooks/useAttorneyId'
import {
  GetOrganizationProfileDocument,
  GetOrganizationWaterfallListsDocument,
  GetWaterfallListsDocument,
  useDeleteOrganizationWaterfallListMutation,
  useGetOrganizationWaterfallListsQuery,
  useGetWaterfallListsQuery,
  useRemoveWaterfallListMutation,
  useUpdateOrganizationMutation,
} from '@/gql/appApi'
import { useSnackBar } from '@/hooks/useSnackBar'
import { getImagePath } from '@/utils'
import { IMAGE_SIZES } from '@/constants'
import { isQualifiedMembership } from '@/utils/membership'
import { useGetOrgWaterfallConfigQuery } from '@/gql/systemApi'
import { initialContextValue } from './initialContextValue'
import {
  IOrganizationWaterfallsContext,
  TOrgWaterfallFilters,
  WaterfallList,
} from './types'

export const OrganizationWaterfallsContext =
  createContext<IOrganizationWaterfallsContext>(initialContextValue)

interface IOrganizationWaterfallsProviderProps {
  isFirmSettings?: boolean
  children: ReactNode
}

export const OrganizationWaterfallsProvider = ({
  isFirmSettings = false,
  children,
}: IOrganizationWaterfallsProviderProps) => {
  const [editOrCreateWaterfallModalOpen, setEditOrCreateWaterfallModalOpen] =
    useState<{
      open: boolean
      waterfall?: WaterfallList
    }>({
      open: false,
    })
  const [deleteWaterfallModalOpen, setDeleteWaterfallModalOpen] = useState<{
    open: boolean
    waterfallId: string
    waterfallTitle: string
  }>({ open: false, waterfallId: '', waterfallTitle: '' })
  const [filters, setFilters] = useState<TOrgWaterfallFilters>({
    searchText: '',
    practiceArea: '',
    jurisdiction: '',
    integrationsOnly: false,
    alphabetical: '',
  })
  const { alert, showAlert, handleClose } = useSnackBar()
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = getAttorneyId()

  const { data: waterfallConfig } = useGetOrgWaterfallConfigQuery({
    fetchPolicy: 'network-only',
  })

  const orgId =
    waterfallConfig?.users_me?.current_organization?.[0]?.organization_id?.id ??
    ''

  const hasPermissionToCreateWaterfalls =
    waterfallConfig?.users_me?.current_organization?.[0]?.can_create_wf_lists ??
    false

  const { data: attorneyWaterfallData, loading: loadingWaterfalls } =
    useGetWaterfallListsQuery({
      variables: {
        filter: {
          attorney_id: {
            id: {
              _eq: attorneyId,
            },
          },
        },
        sort: '-created_ts',
        itemsSort: 'list_order',
      },
      fetchPolicy: 'network-only',
      skip: !attorneyId || isFirmSettings,
    })

  const { data: orgWaterfallListData, loading: loadingOrgWaterfallList } =
    useGetOrganizationWaterfallListsQuery({
      variables: {
        filter: {
          organization_id: {
            id: {
              _eq: orgId,
            },
          },
        },
        sort: '-created_ts',
        itemsSort: 'list_order',
      },
      fetchPolicy: 'network-only',
      skip: !orgId || !isFirmSettings,
    })

  const [
    updateOrganizationItemMutation,
    { loading: updatingOrganizationProfile },
  ] = useUpdateOrganizationMutation({
    refetchQueries: [GetOrganizationProfileDocument],
  })

  const [deleteOrgWaterfallListMutation, { loading: deletingOrgWaterfall }] =
    useDeleteOrganizationWaterfallListMutation({
      refetchQueries: [
        GetOrganizationWaterfallListsDocument,
        'getOrgWaterfallLists',
      ],
    })

  const [deleteWaterfallListMutation, { loading: deletingWaterfall }] =
    useRemoveWaterfallListMutation({
      refetchQueries: [GetWaterfallListsDocument, 'getWaterfallLists'],
    })

  const loading = loadingWaterfalls || loadingOrgWaterfallList

  const waterfallLists: WaterfallList[] = useMemo(() => {
    if (
      !attorneyWaterfallData?.attorney_waterfall_list &&
      !orgWaterfallListData?.organization_waterfall_list
    )
      return []

    if (isFirmSettings) {
      return (
        orgWaterfallListData?.organization_waterfall_list?.map(waterfall => {
          const waterfallMembers = waterfall?.items?.map(item => ({
            attorneyId: item?.attorney_id?.id ?? '',
            attorneyName: `${item?.attorney_id?.profiles?.[0]?.first_name} ${item?.attorney_id?.profiles?.[0]?.last_name}`,
            attorneyOrganization:
              item?.attorney_id?.user_id?.current_organization?.[0]
                ?.organization_id?.profiles?.[0]?.name ?? '',
            avatar: getImagePath({
              id: item?.attorney_id?.user_id?.avatar?.id ?? null,
              size: IMAGE_SIZES.XSmall,
            }),
            isVetted: item?.attorney_id?.is_vetted ?? false,
            isQualified:
              isQualifiedMembership(item?.attorney_id?.membership_type?.id) ??
              false,
            listOrder: item?.list_order,
          }))

          const jurisdiction = waterfall?.jurisdiction_id && {
            id: waterfall?.jurisdiction_id?.id ?? '',
            name: waterfall?.jurisdiction_id?.name ?? '',
          }

          const practiceAreas = waterfall?.practice_areas?.map(
            practiceArea => ({
              id: practiceArea?.practice_area_id?.id ?? '',
              name: practiceArea?.practice_area_id?.name ?? '',
            })
          )

          return {
            jurisdiction,
            practiceAreas,
            enabledForAttorneys: waterfall?.is_visible,
            waterfallId: waterfall.id ?? '',
            waterfallName: waterfall.name ?? '',
            waterfallMembers,
          }
        }) ?? []
      )
    }

    return (
      attorneyWaterfallData?.attorney_waterfall_list?.map(waterfall => {
        const waterfallMembers = waterfall?.items?.map(item => ({
          attorneyId: item?.attorney_id?.id ?? '',
          attorneyName: `${item?.attorney_id?.profiles?.[0]?.first_name} ${item?.attorney_id?.profiles?.[0]?.last_name}`,
          attorneyOrganization:
            item?.attorney_id?.user_id?.current_organization?.[0]
              ?.organization_id?.profiles?.[0]?.name ?? '',
          avatar: getImagePath({
            id: item?.attorney_id?.user_id?.avatar?.id ?? null,
            size: IMAGE_SIZES.XSmall,
          }),
          isVetted: item?.attorney_id?.is_vetted ?? false,
          isQualified:
            isQualifiedMembership(item?.attorney_id?.membership_type?.id) ??
            false,
          listOrder: item?.list_order,
        }))

        return {
          waterfallId: waterfall.id ?? '',
          waterfallName: waterfall.name ?? '',
          waterfallMembers,
        }
      }) ?? []
    )
  }, [
    attorneyWaterfallData?.attorney_waterfall_list,
    orgWaterfallListData?.organization_waterfall_list,
    isFirmSettings,
  ])

  const filteredWaterfallLists = useMemo(() => {
    if (!waterfallLists?.length) return waterfallLists

    const { searchText, practiceArea, jurisdiction, alphabetical } = filters

    const allLists = [...waterfallLists]

    // Sort alphabetically in descending or ascending order
    if (alphabetical) {
      if (alphabetical === 'asc') {
        allLists.sort((a, b) =>
          (a?.waterfallName ?? '').localeCompare(b?.waterfallName ?? '')
        )
      } else {
        allLists.sort((a, b) =>
          (b?.waterfallName ?? '').localeCompare(a.waterfallName ?? '')
        )
      }
    }

    return allLists.filter(waterfallList => {
      if (
        searchText &&
        !String(waterfallList?.waterfallName || '')
          .toLowerCase()
          .includes(searchText.toLowerCase())
      ) {
        return false
      }

      // Filter By Practice Areas
      if (
        practiceArea &&
        !waterfallList?.practiceAreas?.some(
          waterfallPracticeArea => waterfallPracticeArea?.name === practiceArea
        )
      ) {
        return false
      }

      // Filter By Jurisdiction
      if (
        jurisdiction &&
        String(waterfallList?.jurisdiction?.name) !== jurisdiction
      ) {
        return false
      }

      // Filter by Integrations Only Waterfalls
      if (filters.integrationsOnly && waterfallList?.enabledForAttorneys) {
        return false
      }

      return true
    })
  }, [filters, waterfallLists])

  // If Firm Settings, obtain the Jurisdiction and Practice Areas currently in use.
  // The following will be implemented:

  /**
   * The Practice Areas per Jurisdiction in use and the Jurisdictions with Waterfalls with no Practice Areas.
   *
   * @description
   * The key is the Jurisdiction ID, and the value is the Practice Areas in use for that Jurisdiction.
   * The Practice Areas are represented by their ID and can be repeated in different Jurisdictions.
   * __Note: Only applies to Organization Waterfalls.__
   *
   * - A waterfall can be created for a Jurisdiction with no Practice Areas, but if a 2nd waterfall is created for the same Jurisdiction, the Practice Areas are now required.
   * - For this 2nd and subsequent waterfalls, the Practice Areas selected must be unique and not already used in another waterfall for the same Jurisdiction.
   * - If a Practice Area is removed from a waterfall, it can be added to another waterfall for the same Jurisdiction.
   *
   *
   *  jurisdictionsWithWaterfallsWithNoPracticeAreas is a Set of Jurisdiction IDs from Waterfalls that have no Practice Areas.
   *
   * @example
   *   practiceAreasInUsePerJurisdiction: {
   *     "jdxId1": ["pa1", "pa2"],
   *     "jdxId2": ["pa3", "pa2"],
   *   },
   *
   *   jurisdictionsWithWaterfallsWithNoPracticeAreas: {
   *     "jdxId1",
   *     "jdxId3",
   *   }
   */

  const jurisdictionAndPracticeAreasConditions = useMemo(() => {
    if (!orgWaterfallListData?.organization_waterfall_list)
      return {
        practiceAreasInUsePerJurisdiction: {},
        jurisdictionsWithWaterfallsWithNoPracticeAreas: new Set<string>(),
      }

    const practiceAreasPerJurisdiction: Record<string, string[]> = {}
    const jurisdictionsWithNoPracticeAreas = new Set<string>()

    orgWaterfallListData.organization_waterfall_list.forEach(waterfall => {
      if (!waterfall?.jurisdiction_id?.id) return

      // Exclude the current waterfall being edited (if editing)
      if (
        waterfall.id === editOrCreateWaterfallModalOpen?.waterfall?.waterfallId
      ) {
        return
      }

      const jurisdictionId = waterfall.jurisdiction_id.id
      const practiceAreas =
        waterfall.practice_areas?.map(
          practiceArea => practiceArea?.practice_area_id?.id ?? ''
        ) ?? []

      if (practiceAreasPerJurisdiction[jurisdictionId]) {
        practiceAreasPerJurisdiction[jurisdictionId] = [
          ...practiceAreasPerJurisdiction[jurisdictionId],
          ...practiceAreas,
        ]
      } else {
        practiceAreasPerJurisdiction[jurisdictionId] = practiceAreas
      }

      if (practiceAreas.length === 0) {
        jurisdictionsWithNoPracticeAreas.add(jurisdictionId)
      }
    })

    return {
      practiceAreasInUsePerJurisdiction: practiceAreasPerJurisdiction,
      jurisdictionsWithWaterfallsWithNoPracticeAreas:
        jurisdictionsWithNoPracticeAreas,
    }
  }, [
    orgWaterfallListData?.organization_waterfall_list,
    editOrCreateWaterfallModalOpen?.waterfall?.waterfallId,
  ])

  const onCreateWaterfall = useCallback(() => {
    setEditOrCreateWaterfallModalOpen({
      open: true,
    })
  }, [])

  const onEditWaterfall = useCallback((waterfall: WaterfallList) => {
    setEditOrCreateWaterfallModalOpen({
      open: true,
      waterfall,
    })
  }, [])

  const openDeleteWaterfallModal = useCallback((waterfall: WaterfallList) => {
    setDeleteWaterfallModalOpen({
      open: true,
      waterfallId: waterfall?.waterfallId ?? '',
      waterfallTitle: waterfall?.waterfallName ?? '',
    })
  }, [])

  const deleteWaterfall = useCallback(async () => {
    try {
      if (isFirmSettings) {
        const response = await deleteOrgWaterfallListMutation({
          variables: {
            deleteOrganizationWaterfallListItemId:
              deleteWaterfallModalOpen.waterfallId,
          },
        })
        if (response.errors) {
          throw new Error(response.errors[0].message)
        }
      } else {
        const response = await deleteWaterfallListMutation({
          variables: {
            deleteAttorneyWaterfallListItemId:
              deleteWaterfallModalOpen.waterfallId,
          },
        })
        if (response.errors) {
          throw new Error(response.errors[0].message)
        }
      }
      setDeleteWaterfallModalOpen({
        open: false,
        waterfallId: '',
        waterfallTitle: '',
      })
      showAlert({
        message: 'Waterfall deleted successfully',
        severity: 'success',
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      showAlert({
        message: 'An error occurred while deleting the waterfall',
        severity: 'error',
      })
    }
  }, [
    deleteOrgWaterfallListMutation,
    deleteWaterfallListMutation,
    deleteWaterfallModalOpen.waterfallId,
    isFirmSettings,
    showAlert,
  ])

  const updateWaterfallCreationPermissions = useCallback(
    async (enable = false) => {
      if (!orgId) {
        showAlert({
          message: 'Organization profile not found',
          severity: 'error',
        })
        return
      }

      try {
        const response = await updateOrganizationItemMutation({
          variables: {
            updateOrganizationItemId: orgId,
            data: {
              allow_attorney_wf_list: enable,
            },
          },
        })
        if (response.errors) {
          throw new Error(response.errors[0].message)
        }
        showAlert({
          message: 'Waterfall creation permissions updated successfully',
          severity: 'success',
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        showAlert({
          message:
            'An error occurred while updating waterfall creation permissions',
          severity: 'error',
        })
      }
    },
    [orgId, showAlert, updateOrganizationItemMutation]
  )

  const onEditOrCreateWaterfallSuccess = useCallback(() => {
    showAlert({
      message: 'Waterfall saved successfully',
      severity: 'success',
    })
  }, [showAlert])

  const onEditOrCreateWaterfallError = useCallback(() => {
    showAlert({
      message: 'An error occurred while saving the waterfall',
      severity: 'error',
    })
  }, [showAlert])

  const title = isFirmSettings
    ? 'Firm Waterfall Referrals™'
    : 'Waterfall Referrals™'

  const contextValue = useMemo(() => {
    const organizationWaterfallsContextValue: IOrganizationWaterfallsContext = {
      isFirmSettings,
      title,
      alert,
      deleteWaterfallModalOpen,
      deleteWaterfall,
      deletingWaterfall: deletingWaterfall || deletingOrgWaterfall,
      editOrCreateWaterfallModalOpen,
      filters,
      handleClose,
      hasPermissionToCreateWaterfalls,
      jurisdictionAndPracticeAreasConditions,
      loading,
      onCreateWaterfall,
      onEditOrCreateWaterfallError,
      onEditOrCreateWaterfallSuccess,
      onEditWaterfall,
      openDeleteWaterfallModal,
      setDeleteWaterfallModalOpen,
      setEditOrCreateWaterfallModalOpen,
      setFilters,
      updateWaterfallCreationPermissions,
      updatingOrganizationProfile,
      orgId,
      waterfalls: filteredWaterfallLists,
    }

    return organizationWaterfallsContextValue
  }, [
    isFirmSettings,
    title,
    alert,
    deleteWaterfallModalOpen,
    deleteWaterfall,
    deletingWaterfall,
    deletingOrgWaterfall,
    editOrCreateWaterfallModalOpen,
    filters,
    handleClose,
    hasPermissionToCreateWaterfalls,
    jurisdictionAndPracticeAreasConditions,
    loading,
    onCreateWaterfall,
    onEditOrCreateWaterfallError,
    onEditOrCreateWaterfallSuccess,
    onEditWaterfall,
    openDeleteWaterfallModal,
    setDeleteWaterfallModalOpen,
    setEditOrCreateWaterfallModalOpen,
    setFilters,
    updateWaterfallCreationPermissions,
    updatingOrganizationProfile,
    orgId,
    filteredWaterfallLists,
  ])

  return (
    <OrganizationWaterfallsContext.Provider value={contextValue}>
      {children}
    </OrganizationWaterfallsContext.Provider>
  )
}

import { useState, useEffect, useCallback, FC } from 'react'
import { useSearchBox, useInstantSearch } from 'react-instantsearch'
import { TextField, InputAdornment, IconButton } from '@mui/material'
import { Search, Close } from '@mui/icons-material'
import { ALGOLIA_DEBOUNCE_TIME_MS } from '@/pages/Marketplace/config'
import { useDebounceCallback } from 'usehooks-ts'

const AlgoliaSearchBox: FC = () => {
  const { refine } = useSearchBox()
  const { indexUiState } = useInstantSearch()
  const [inputValue, setInputValue] = useState(indexUiState.query || '')

  const debouncedRefine = useDebounceCallback(refine, ALGOLIA_DEBOUNCE_TIME_MS)

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      setInputValue(value)
      debouncedRefine(value)
    },
    [debouncedRefine]
  )

  const handleClear = useCallback(() => {
    setInputValue('')
    debouncedRefine('')
  }, [debouncedRefine])

  useEffect(() => {
    setInputValue(indexUiState.query || '')
  }, [indexUiState.query])

  return (
    <TextField
      value={inputValue}
      placeholder="Search for Cases..."
      type="search"
      sx={{
        maxWidth: {
          xs: '100%',
          md: '30%',
        },
      }}
      fullWidth
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: inputValue && (
          <InputAdornment position="end">
            <IconButton onClick={handleClear}>
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default AlgoliaSearchBox

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import { FormControlLabelProps } from '@mui/material/FormControlLabel'
import { forwardRef, ReactNode } from 'react'
import MUICheckbox, { CheckboxProps } from '@mui/material/Checkbox'

import Box from '@mui/material/Box'
import * as Styles from './Checkbox.styles'

interface CustomCheckboxProps
  extends Omit<FormControlLabelProps, 'control' | 'label'> {
  checkboxProps: {
    label: ReactNode
    error?: boolean
    helperText?: ReactNode
    isCentered?: boolean
  }
  muiCheckboxProps?: CheckboxProps
}

export type CheckboxOnChangeHandler = FormControlLabelProps['onChange']

const Checkbox = forwardRef<HTMLInputElement, CustomCheckboxProps>(
  (
    {
      checkboxProps: { error = false, label, helperText, isCentered = false },
      muiCheckboxProps,
      ...props
    },
    ref
  ) => {
    const checkboxLabel = (
      <Styles.CheckboxLabel error={error}>{label}</Styles.CheckboxLabel>
    )

    const errorMessage = !error ? '' : helperText

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Styles.CheckboxLabelControl
          {...props}
          isCentered={isCentered}
          inputRef={ref}
          label={checkboxLabel}
          control={
            <MUICheckbox
              checkedIcon={<CheckBoxOutlinedIcon />}
              color="default"
              icon={<CheckBoxOutlineBlankOutlinedIcon />}
              {...muiCheckboxProps}
            />
          }
        />
        {errorMessage && (
          <Styles.CheckboxLabelError error={error}>
            {errorMessage || ' '}
          </Styles.CheckboxLabelError>
        )}
      </Box>
    )
  }
)

export default Checkbox

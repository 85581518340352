import { yupResolver } from '@hookform/resolvers/yup'

import { Box, TextField, Typography } from '@mui/material'
import classNames from 'classnames'
import { useCallback, useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import LogoBar from '@/pages/CreateAccount/shared/LogoBar'

import { MAX_BIO_LENGTH } from '@/constants'
import { useSizes } from '@/hooks/useSizes'
import CreateProfileFormContext from '../CreateProfileFormContext'
import ButtonBar from '../shared/ButtonBar'
import {
  defaultValues,
  PersonalInfoFormValues,
  personalInfoSchema,
} from './config'

type PersonalInformationProps = {
  back: () => void
  next: () => void
}

const PersonalInformation = ({ back, next }: PersonalInformationProps) => {
  const createProfileContext = useContext(CreateProfileFormContext)

  if (!createProfileContext) {
    throw new Error('Must be used within a CreateProfileFormProvider')
  }

  const { formState, updateFormState } = createProfileContext
  const { isLessMd } = useSizes()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PersonalInfoFormValues>({
    defaultValues: { bio: formState.personalInfo?.bio } || defaultValues,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolver: yupResolver(personalInfoSchema),
  })

  const onSubmit = useCallback(
    (formData: PersonalInfoFormValues) => {
      updateFormState('personalInfo', {
        bio: formData.bio,
      })
      next()
    },
    [next, updateFormState]
  )

  return (
    <Box
      sx={theme => ({
        paddingTop: { xs: 3.5, md: 0 },
        paddingRight: { xs: 0, md: 2 },
        pb: { md: 5 },
        height: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        [theme.breakpoints.down('md')]: {
          paddingBottom: theme.spacing(6),
        },
      })}
    >
      <LogoBar />
      <div className={classNames('w-full', isLessMd ? 'px-3' : '')}>
        <Typography
          sx={{
            textAlign: 'left',
            marginBottom: 5,
          }}
          variant={isLessMd ? 'h5' : 'h3'}
        >
          Personal Information
        </Typography>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classNames('w-full my-5', isLessMd ? ' px-3' : '')}>
          <Controller
            name="bio"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Bio. Sell yourself, tastefully."
                error={!!errors.bio}
                helperText={errors.bio?.message || ' '}
                multiline
                fullWidth
                rows={5}
                inputProps={{
                  maxLength: MAX_BIO_LENGTH,
                }}
              />
            )}
          />
        </div>
        <ButtonBar back={back} />
      </form>
    </Box>
  )
}

export default PersonalInformation

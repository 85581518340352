import MUIListItem from '@mui/material/ListItem'
import MUIListItemIcon from '@mui/material/ListItemIcon'
import MUIListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'

export const ListItemIcon = styled(MUIListItemIcon)`
  & > .MuiSvgIcon-root {
    margin: 0.5rem;
  }
`
export const ListItem = styled(MUIListItem, {
  shouldForwardProp: prop => prop !== 'reverseRow',
})<{
  reverseRow: boolean
  size: 'small' | 'medium' | 'large' | 'xLarge'
}>`
  padding: 0;
  flex-direction: ${({ reverseRow }) => (reverseRow ? 'row-reverse' : 'row')};
  gap: ${({ theme, size }) => (size === 'xLarge' ? theme.spacing(2) : 0)};
`

export const ListItemText = styled(MUIListItemText, {
  shouldForwardProp: prop => prop !== 'reverseText',
})<{
  reverseText: boolean
}>`
  flex-direction: ${({ reverseText }) =>
    reverseText ? 'column' : 'column-reverse'};
  display: flex;
  margin: 0;
`

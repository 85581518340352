import { useCallback, useEffect, useState } from 'react'
import { useAttorneyNameAndOrgLazyQuery } from '@/gql/appApi'
import { getImagePath } from '@/utils'
import { IMAGE_SIZES, USER_ROLES } from '@/constants'
import { isQualifiedMembership } from '@/utils/membership'
import { useDebounceValue } from 'usehooks-ts'
import { WaterfallList } from '@/components/OrganizationWaterfalls/types'
import { useSnackBar } from '@/hooks/useSnackBar'
import { CreateCaseWaterfallMember } from '../../types'

const useAssignIndividually = (orgId: string) => {
  const { alert, showAlert, handleClose } = useSnackBar()
  const [users, setUsers] = useState<CreateCaseWaterfallMember[]>([])
  const [selectedUsers, setSelectedUsers] = useState<
    CreateCaseWaterfallMember[]
  >([])
  const [options, setOptions] = useState<CreateCaseWaterfallMember[]>([])
  const [inputValue, setInputValue] = useState('')
  const [getAttorneyById, { data, loading, error }] =
    useAttorneyNameAndOrgLazyQuery()
  const [editOrCreateWaterfallModalOpen, setEditOrCreateWaterfallModalOpen] =
    useState<{
      open: boolean
      waterfall?: WaterfallList
    }>({
      open: false,
    })
  const [debouncedSearchText] = useDebounceValue(inputValue, 500)

  useEffect(() => {
    if (orgId) {
      getAttorneyById({
        variables: {
          sort: 'first_name',
          search: debouncedSearchText.toLowerCase(),
          filter: {
            _and: [
              {
                attorney_id: {
                  user_id: {
                    current_organization: {
                      organization_id: {
                        id: {
                          _neq: orgId,
                        },
                      },
                    },
                  },
                },
              },
              {
                _or: [
                  {
                    attorney_id: {
                      user_id: {
                        role: {
                          id: {
                            //  SAdminAtt
                            _eq: USER_ROLES.SAdminAttorneyUser,
                          },
                        },
                      },
                    },
                  },
                  {
                    attorney_id: {
                      user_id: {
                        role: {
                          id: {
                            // admin att
                            _eq: USER_ROLES.AdminAttorneyUser,
                          },
                        },
                      },
                    },
                  },
                  {
                    attorney_id: {
                      user_id: {
                        role: {
                          id: {
                            // basic
                            _eq: USER_ROLES.BasicUser,
                          },
                        },
                      },
                    },
                  },
                ],
              },
            ],
          },
        },
      })
    }
  }, [orgId, getAttorneyById, debouncedSearchText])

  useEffect(() => {
    if (data) {
      const newOptions: CreateCaseWaterfallMember[] = []
      data.attorney_profile?.forEach(attorney => {
        if (
          attorney &&
          attorney?.attorney_id?.id &&
          attorney.first_name !== 'undefined'
        ) {
          newOptions.push({
            attorneyId: attorney.attorney_id.id,
            attorneyName: `${attorney.first_name} ${attorney.last_name}`,
            avatar: getImagePath({
              id: attorney?.attorney_id?.user_id?.avatar?.id || null,
              size: IMAGE_SIZES.Small,
            }),
            attorneyOrganization:
              attorney?.attorney_id?.user_id?.current_organization?.[0]
                ?.organization_id?.profiles?.[0]?.name || '',
            isVetted: attorney?.attorney_id?.is_vetted || false,
            isQualified: isQualifiedMembership(
              attorney?.attorney_id?.membership_type?.id
            ),
          })
        }
      })
      setOptions(newOptions)
    }
  }, [data])

  const onCreateWaterfall = useCallback(() => {
    setEditOrCreateWaterfallModalOpen({
      open: true,
      waterfall: {
        waterfallMembers: selectedUsers.map((user, idx) => ({
          ...user,
          listOrder: idx + 1,
        })),
      },
    })
  }, [selectedUsers])

  const onEditOrCreateWaterfallSuccess = useCallback(() => {
    showAlert({
      message: 'Waterfall saved successfully',
      severity: 'success',
    })
  }, [showAlert])

  const onEditOrCreateWaterfallError = useCallback(() => {
    showAlert({
      message: 'An error occurred while saving the waterfall',
      severity: 'error',
    })
  }, [showAlert])

  return {
    alert,
    handleClose,
    error,
    loading,
    editOrCreateWaterfallModalOpen,
    onCreateWaterfall,
    options,
    setEditOrCreateWaterfallModalOpen,
    setSelectedUsers,
    setInputValue,
    setUsers,
    selectedUsers,
    users,
    onEditOrCreateWaterfallSuccess,
    onEditOrCreateWaterfallError,
  }
}

export default useAssignIndividually

import { styled } from '@mui/material'
import Button from '@mui/material/Button'

export const ErrorButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.error.main,
  color: theme.palette.error.main,
  ':hover': {
    borderColor: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,
  },
  ':focus': {
    borderColor: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,
  },
}))

import FilesViewer from '@/components/DataRoom/FilesViewer/FilesViewer'
import FilesUploaderForm from '@/components/DataRoom/FilesUploader/FilesUploaderForm'
import { Case_File } from '@/gql/systemApi'
import noop from 'lodash/noop'
import { TCase } from '../../types'
import DataroomNonOwner from './components/DataroomNonOwner'

type TDataroomProps = {
  caseData: TCase
  isOwner: boolean
  refetchCaseData: () => Promise<void>
  showCaseOptions?: boolean
}

const Dataroom = ({
  caseData,
  isOwner,
  refetchCaseData,
  showCaseOptions,
}: TDataroomProps) => {
  const { files } = caseData
  const dataroomFiles = files?.filter(
    file => !file?.file_id?.metadata?.is_fee_agreement
  )

  return isOwner ? (
    <>
      <FilesViewer
        isOwner
        caseId={caseData.id}
        caseName={caseData.name || ''}
        files={dataroomFiles as unknown as Case_File[]}
        refetchCaseData={refetchCaseData}
      />
      <FilesUploaderForm
        caseId={caseData.id}
        refetchCaseData={refetchCaseData}
        onAddExternal={noop}
      />
    </>
  ) : (
    <DataroomNonOwner
      caseData={caseData}
      files={dataroomFiles as unknown as Case_File[]}
      refetchCaseData={refetchCaseData}
      showCaseOptions={showCaseOptions}
    />
  )
}

export default Dataroom

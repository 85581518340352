import { useContext } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import JWaterfallCard from './components/JWaterfallCard'
import EmptyWaterfalls from '../EmptyWaterfalls'
import { OrganizationWaterfallsContext } from '../../OrganizationWaterfallsContext'

const WaterfallCards = () => {
  const {
    waterfalls = [],
    isFirmSettings,
    loading,
  } = useContext(OrganizationWaterfallsContext)

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress size={48} />
      </Box>
    )
  }

  return waterfalls.length > 0 ? (
    <Grid container spacing={2} py={1}>
      {waterfalls.map(waterfall => (
        <Grid key={waterfall.waterfallId} item xs={12} md={6}>
          <JWaterfallCard
            isFirmSettings={isFirmSettings}
            waterfall={waterfall}
          />
        </Grid>
      ))}
    </Grid>
  ) : (
    <EmptyWaterfalls />
  )
}
export default WaterfallCards

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import JTextField from '@/components/atoms/JTextField'
import JAutocomplete from '@/components/atoms/JAutocomplete'
import SubmitButtons from '@/pages/Profile/components/SubmitButtons'
import { DiscardChangesModal, ResponseModal } from '@/components'
import JSnackBar from '@/components/atoms/JSnackBar'
import { Title } from '../components'
import useLawInfo, { LawInfoForm } from './useLawInfo'
import useTheme from '@mui/material/styles/useTheme'

const LawInfo = () => {
  const theme = useTheme()
  const {
    isLessMd,
    alert,
    handleCloseSnack,
    response,
    lawFirmCatalogs,
    lawFirmCatalogsLoading,
    control,
    handleSubmit,
    getConfirmationModalProps,
    modalOpen,
    onSubmitDiscardModal,
    onSubmit,
    isDirty,
    setResponse,
    setOpenModal,
  } = useLawInfo()

  return (
    <Paper
      sx={{
        padding: isLessMd ? theme.spacing(3, 2) : theme.spacing(5),
        width: '100%',
        paddingBottom: isLessMd ? theme.spacing(20) : theme.spacing(0),
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container mb={2}>
        <Grid item md={4} sm={12}>
          <Title variant="h3" color="primary.main">
            Law Firm Info
          </Title>
        </Grid>
        <Grid item md={8} sm={12}>
          <Grid item mb={1}>
            <JTextField<LawInfoForm>
              name="name"
              control={control}
              label="Law Firm Name"
              type="text"
              InputProps={{ inputProps: { maxLength: 40 } }}
              rules={{
                validate: value =>
                  value === '' ? 'Firm Name is required' : true,
              }}
            />
          </Grid>
          <Grid item mb={1} mt={3}>
            <JTextField<LawInfoForm>
              name="description"
              control={control}
              label="Law Firm Description"
              multiline
              rows={4}
              type="text"
            />
          </Grid>
          <Typography variant="subtitle1" sx={{ mb: 2.5 }}>
            Location
          </Typography>
          <Grid container spacing={1.25}>
            <Grid item xs={12} md={6}>
              <JTextField<LawInfoForm>
                name="address"
                control={control}
                label="Address"
                type="text"
                InputProps={{ inputProps: { maxLength: 40 } }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                mt: {
                  xs: 2,
                  md: 0,
                },
              }}
            >
              <JTextField<LawInfoForm>
                name="city"
                control={control}
                label="City"
                type="text"
                InputProps={{ inputProps: { maxLength: 20 } }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1.25} mb={1} mt={2}>
            <Grid item xs={12} md={6}>
              <JAutocomplete<LawInfoForm>
                control={control}
                loading={lawFirmCatalogsLoading}
                name="state"
                options={lawFirmCatalogs?.catalog_us_state || []}
                label="State"
                getOptionLabel={option => `${option.code} - ${option.name}`}
                valueKey="id"
                rules={{
                  validate: value =>
                    value !== '' || 'Please provide a valid State',
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <JTextField<LawInfoForm>
                name="zipcode"
                control={control}
                label="Zip Code"
                type="numeric"
              />
            </Grid>
          </Grid>
          <Typography variant="subtitle1" sx={{ mb: 2.5 }}>
            Extra Information
          </Typography>
          <Grid container spacing={1.25} mb={1}>
            <Grid item xs={12} md={6}>
              <JAutocomplete
                control={control}
                loading={lawFirmCatalogsLoading}
                name="company_size"
                options={lawFirmCatalogs?.catalog_company_size || []}
                label="Company Size"
                getOptionLabel={option => option?.name}
                rules={{
                  validate: value =>
                    value !== '' || 'Please provide a valid Company Size',
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <JAutocomplete
                control={control}
                loading={lawFirmCatalogsLoading}
                name="case_management_software"
                options={lawFirmCatalogs?.case_management_software || []}
                label="Case Management Software"
                getOptionLabel={option => option?.name}
                valueKey="id"
                rules={{
                  validate: value =>
                    value !== '' || 'Please choose a Case Management Software',
                }}
              />
            </Grid>
          </Grid>
          <Grid item>
            <JTextField<LawInfoForm>
              name="website_url"
              control={control}
              label="Website"
              type="text"
              InputProps={{ inputProps: { maxLength: 40 } }}
            />
          </Grid>
        </Grid>
      </Grid>
      <SubmitButtons
        disabled={!isDirty}
        onCancel={() => setOpenModal('confirmDiscardChanges')}
      />
      {modalOpen && (
        <DiscardChangesModal
          ConfirmationModalProps={getConfirmationModalProps()}
          onSubmit={onSubmitDiscardModal}
        />
      )}
      {response.open && (
        <ResponseModal
          open={response.open}
          type={response.type}
          onClose={() => setResponse({ open: false, type: null })}
        />
      )}
      <JSnackBar
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        horizontal="center"
        vertical="bottom"
        handleClose={handleCloseSnack}
      />
    </Paper>
  )
}

export default LawInfo

import { useCatalogCasePurchaseDeclineReasonQuery } from '@/gql/appApi'

export const useCasePurchaseDeclineReason = () => {
  const { data, loading } = useCatalogCasePurchaseDeclineReasonQuery()

  return {
    casePurchaseDeclineReasons:
      data?.catalog_case_purchase_decline_reason ?? [],
    loading,
  }
}

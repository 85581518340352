import { FieldValues, UseControllerProps, Controller } from 'react-hook-form'
import { RichTextField, RichTextInputProps } from '@/components/RichTextField'

type JRichTextEditorProps<FV extends FieldValues = FieldValues> =
  UseControllerProps<FV> & RichTextInputProps

export const JRichTextEditor = <FV extends FieldValues>({
  onBlur,

  // Controller props
  rules,
  name,
  control,

  ...rest
}: JRichTextEditorProps<FV>) => (
  <Controller<FV>
    name={name}
    control={control}
    rules={typeof rules === 'string' ? { required: rules } : rules}
    render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => {
      const handleChange = ({ plainText }: { plainText: string }) => {
        onChange(plainText)
      }

      return (
        <RichTextField
          error={error}
          onChange={handleChange}
          variant="outlined"
          {...rest}
          {...field}
        />
      )
    }}
  />
)

import { Dayjs } from 'dayjs'

export type ProfessionalLicenseFormValues = {
  id?: string
  barNumber: string
  isActive: boolean
  isDisciplined: boolean
  isSued: boolean
  state: string
  insuranceId?: string
  malpracticeInsuranceId: string
  insuranceExpirationDate: string | Dayjs | null
}

const getDefaultValues = (): ProfessionalLicenseFormValues => ({
  barNumber: '',
  isActive: false,
  isDisciplined: false,
  isSued: false,
  state: '',
  malpracticeInsuranceId: '',
  insuranceExpirationDate: null,
})

export { getDefaultValues }

import React from 'react'
import { styled } from '@mui/material'
import LeadInfoDisplay from '../LeadInfoDisplay'
import { useCaseContext } from '../../../hooks/useCaseContext'
import { Lead } from '../../../types'
import SidebarAccordion from '../SidebarAccordion'

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  width: '100%',
})

const LeadItem = styled('li')(({ theme }) => ({
  padding: theme.spacing(1, 1),
  width: '100%',
}))

const ClientContactInfo: React.FC<{ defaultExpanded?: boolean }> = ({
  defaultExpanded = false,
}) => {
  const { caseData } = useCaseContext()

  const clientContactInfo = caseData?.sidebar?.client_contact_info?.leads || []

  if (clientContactInfo.length === 0) return null

  return (
    <SidebarAccordion
      title="Client Contact Info"
      defaultExpanded={defaultExpanded}
    >
      <List role="list">
        {clientContactInfo.map((lead: Lead) => (
          <LeadItem key={lead.email} role="listitem">
            <LeadInfoDisplay lead={lead} />
          </LeadItem>
        ))}
      </List>
    </SidebarAccordion>
  )
}

export default ClientContactInfo

import {
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import Close from '@mui/icons-material/Close'
import { ChannelOptions } from '@/context/ChatClientContext'
import { useNavigate } from 'react-router-dom'
import { Channel as ChannelType } from 'stream-chat'
import { useStreamChat } from '@/hooks/useStreamChat'

import { AvatarDisplay } from '../AvatarDisplay'

const ChatChannelHeader = ({ channel }: { channel: ChannelType }) => {
  const navigate = useNavigate()
  const {
    userId,
    setActiveChannelId,
    getChannelTitle,
    getChannelAvatar,
    getAttorneyIdFromUserId,
    closeMessageDrawer,
  } = useStreamChat()

  const title = getChannelTitle(channel)
  const { members } = channel.state
  const memberNames = Object.values(members).reduce<string[]>((acc, member) => {
    if (member?.user?.name) {
      acc.push(member.user.name)
    }
    return acc
  }, [])
  const subtitle =
    channel.data?.channel_type === ChannelOptions.CaseMessages
      ? memberNames.join(', ')
      : ''

  const images: string[] = getChannelAvatar(channel)

  const handleBackClick = () => {
    setActiveChannelId(null)
  }

  const handleCloseClick = () => {
    closeMessageDrawer()
  }

  const handleHeaderClick = async () => {
    const channelType = channel.data?.channel_type

    switch (channelType) {
      case ChannelOptions.CaseMessages: {
        const caseId = channel.data?.case_id
        if (caseId) {
          navigate(`/case/${caseId}`)
          closeMessageDrawer()
        }
        break
      }

      case ChannelOptions.DirectMessages: {
        const otherMember = Object.values(channel.state.members).find(
          member => member?.user?.id !== userId
        )

        if (!otherMember || !otherMember.user?.id) {
          console.warn('No other member found in direct messages channel.')
          return
        }

        try {
          const otherMemberAttorneyId = await getAttorneyIdFromUserId({
            id: String(otherMember.user.id),
          })

          if (otherMemberAttorneyId) {
            navigate(`/profile/${otherMemberAttorneyId}`)
            closeMessageDrawer()
          } else {
            console.warn('Attorney ID not found for the other member.')
          }
        } catch (error) {
          console.error('Failed to get attorney ID:', error)
        }

        break
      }

      default:
        break
    }
  }

  return (
    <>
      <ListItem
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <IconButton
          onClick={handleBackClick}
          sx={{
            ml: -1,
            mr: 1,
          }}
        >
          <ChevronLeft />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            overflow: 'hidden',
            flex: 1,
            minWidth: 0,
          }}
          onClick={handleHeaderClick}
        >
          <ListItemAvatar>
            <AvatarDisplay images={images} />
          </ListItemAvatar>
          <ListItemText
            sx={{ flex: 1, minWidth: 0, overflow: 'hidden' }}
            primary={
              <Typography
                variant="subtitle1"
                noWrap
                color="text.primary"
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  minHeight: 24,
                }}
              >
                {title}
              </Typography>
            }
            secondary={
              <Typography
                component="span"
                variant="body2"
                color="text.secondary"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {subtitle}
              </Typography>
            }
          />
        </Box>
        <IconButton onClick={handleCloseClick}>
          <Close />
        </IconButton>
      </ListItem>
      <Divider />
    </>
  )
}

export default ChatChannelHeader

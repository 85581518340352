import Alert, { AlertProps } from '@mui/material/Alert'
import Typography from '@mui/material/Typography'

interface CaseAlertProps extends AlertProps {
  alertTitle: string
  alertSubtitle: string
}

export const CaseAlert = ({
  alertTitle,
  alertSubtitle,
  sx,
}: CaseAlertProps) => (
  <Alert
    severity="success"
    sx={[{ width: '100%' }, ...(Array.isArray(sx) ? sx : [sx])]}
    icon={false}
  >
    <Typography variant="subtitle1" mb={0.5}>
      {alertTitle}
    </Typography>
    <Typography variant="body2">{alertSubtitle}</Typography>
  </Alert>
)

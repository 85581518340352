import { FeeAgreementStatusValue } from '@/constants'
import {
  GetActivityPurchaseDocument,
  GetActivityPurchaseFromMyOrgDocument,
  GetCasesDocument,
  useUpdateCasePurchaseFeeAgreementStatusMutation,
} from '@/gql/appApi'
import { useSnackBar } from '@/hooks/useSnackBar'

const useFeeAgreements = () => {
  const { alert, showAlert, handleClose } = useSnackBar()
  const [updateFeeAgreementStatus, { loading }] =
    useUpdateCasePurchaseFeeAgreementStatusMutation({
      refetchQueries: [
        GetActivityPurchaseDocument,
        'case_purchase_activity',
        GetCasesDocument,
        'case',
        GetActivityPurchaseFromMyOrgDocument,
        'case_purchase_activity',
      ],
    })

  const updateFeeAgreement = async ({
    feeAgreementStatusId,
    casePurchaseId,
  }: {
    feeAgreementStatusId: FeeAgreementStatusValue | null
    casePurchaseId: string
  }) => {
    try {
      const response = await updateFeeAgreementStatus({
        variables: {
          updateCasePurchaseItemId: casePurchaseId,
          data: {
            fee_agreement_status: feeAgreementStatusId,
            fee_agreement_status_ts: new Date().toISOString(),
          },
        },
      })
      if (response.data?.update_case_purchase_item?.id) {
        showAlert({
          message: 'Fee agreement status updated successfully',
          severity: 'success',
        })
      }
      if (response.errors) {
        throw new Error(response.errors[0].message)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      showAlert({
        message: 'An error occurred while updating the fee agreement status',
        severity: 'error',
      })
    }
  }

  return {
    alert,
    handleClose,
    loading,
    updateFeeAgreement,
  }
}

export default useFeeAgreements

import { useSizes } from '@/hooks/useSizes'
import { ALGOLIA_STARTING_ROWS_PER_PAGE } from './config'
import { AlgoliaRouteState, AlgoliaRouteRefinementList } from './types'

const { VITE_ALGOLIA_MARKETPLACE_INDEX } = import.meta.env

export const showCasesInMyOrgQueryParamKey = 'myOrg'
export const showHiddenCasesQueryParamKey = 'showHiddenCases'

export const formatFee = (fee: string | number | null | undefined): string => {
  if (fee === null || fee === undefined || fee === '') {
    return 'N/A'
  }
  const numericFee = Number(fee)
  return Number.isNaN(numericFee) ? String(fee) : `${numericFee}%`
}

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' }
  return date
    .toLocaleDateString('en-US', options)
    .replace(
      /^(\w+)\s(\d+)$/,
      (_, month, day) => `${month} ${day.padStart(2, '0')}`
    )
}

export const CreateStateMapping = () => {
  const { isLessMd } = useSizes()
  return {
    stateToRoute(uiState: {
      [key: string]: AlgoliaRouteState
    }): AlgoliaRouteState {
      const indexUiState = uiState[VITE_ALGOLIA_MARKETPLACE_INDEX]
      const routeState: AlgoliaRouteState = {}

      if (indexUiState.query) {
        routeState.query = indexUiState.query
      }
      if (indexUiState.page && indexUiState.page !== 1) {
        routeState.page = isLessMd ? undefined : indexUiState.page
      }
      if (
        indexUiState.hitsPerPage &&
        indexUiState.hitsPerPage !== ALGOLIA_STARTING_ROWS_PER_PAGE
      ) {
        routeState.hitsPerPage = indexUiState.hitsPerPage
      }

      // Handle refinement lists
      if (indexUiState.refinementList) {
        Object.entries(indexUiState.refinementList).forEach(([key, values]) => {
          if (values && Array.isArray(values) && values.length > 0) {
            routeState[key] = values
          }
        })
      }

      // Add other dynamic attributes to the route state
      Object.keys(indexUiState).forEach(key => {
        if (
          ![
            'query',
            isLessMd && 'page', // Always start from page 1 for infinite scroll on mobile
            'hitsPerPage',
            'refinementList',
            'configure',
          ].includes(key) &&
          indexUiState[key]
        ) {
          routeState[key] = indexUiState[key]
        }
      })

      return routeState
    },
    routeToState(routeState: AlgoliaRouteState) {
      const startingPage = isLessMd ? 1 : routeState.page
      const state: AlgoliaRouteState = {
        query: routeState.query,
        page: startingPage,
        hitsPerPage: routeState.hitsPerPage || ALGOLIA_STARTING_ROWS_PER_PAGE,
      }

      // Handle refinement lists
      const refinementList: AlgoliaRouteRefinementList = {}
      Object.keys(routeState).forEach(key => {
        if (
          ![
            'query',
            isLessMd && 'page', // Always start from page 1 for infinite scroll on mobile
            'hitsPerPage',
            'configure',
          ].includes(key)
        ) {
          refinementList[key] = routeState[key]
        }
      })
      if (Object.keys(refinementList).length > 0) {
        state.refinementList = refinementList
      }

      return {
        [VITE_ALGOLIA_MARKETPLACE_INDEX]: state,
      }
    },
  }
}

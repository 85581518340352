export enum ParameterName {
  REF = 'ref',
  SRC = 'src',
  CASE_ID = 'case_id',
  LAWMATICS_CODE = 'code',
  LITIFY_CODE = 'code',
  SALESFORCE_CODE = 'code',
  CLIO_CODE = 'code',
  INVITE_TOKEN = 'token',
  SUBJECT_URL = 'subject_url',
  MYCASE_CODE = 'code',
}

export class BootParamsService {
  private queryParams: URLSearchParams | undefined

  initialize() {
    this.queryParams = new URLSearchParams(window.location.search)
  }

  get(paramName: ParameterName) {
    return this.queryParams?.get(paramName) ?? null
  }

  remove(paramName: ParameterName) {
    this.queryParams?.delete(paramName)
  }
}

export const bootParamsService = new BootParamsService()

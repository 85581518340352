import { Controller, FieldValues } from 'react-hook-form'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import { useState } from 'react'
import { InputBaseProps } from '@mui/material'
import TextField from '@mui/material/TextField'
import CreateAccountField from '@/components/CreateAccountField/CreateAccountField'
import { TextMaskPhone } from '@/components/PhoneField/PhoneField'
import { ITextField } from './types'

const JTextField = <FV extends FieldValues>({
  // Component props
  startAdornment,
  endAdornment,
  fitTextField,

  // TextField props
  fullWidth = true,
  type,
  label,
  multiline,
  rows,

  // Controller props
  rules,
  name,
  control,
  forceIsNotRequired,

  InputProps,

  ...textInputProps
}: ITextField<FV>) => {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev)
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    // remove non digits and allow only numbers
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault()
    }
  }

  let textFieldType = type
  let inputProps: InputBaseProps = { ...InputProps }

  if (type === 'search' || type === 'password') {
    // If the type is search, add the magnifying glass and the cross icons
    if (type === 'search') {
      inputProps = {
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }
    } else {
      // If the type is password, add the "eye" icon and toggle between text to display the content
      textFieldType = showPassword ? 'text' : type
      inputProps = {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={togglePasswordVisibility}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }
    }
  } else {
    // Add initial adornment
    if (startAdornment) {
      inputProps.startAdornment = (
        <InputAdornment position="start">{startAdornment}</InputAdornment>
      )
    }

    // Add end Adornment
    if (endAdornment) {
      inputProps.endAdornment = (
        <InputAdornment position="end">{endAdornment}</InputAdornment>
      )
    }
  }

  if (type === 'tel') {
    inputProps = {
      ...inputProps,
      inputProps: {
        ...inputProps.inputProps,
        onKeyPress: handleKeyPress,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      inputComponent: TextMaskPhone as any,
    }
  }

  // If the "rules" prop exists, update the label with an asterisk as suffix
  const textInputLabel = `${label ?? ''}${
    rules && !forceIsNotRequired ? ' *' : ''
  }`

  const TextFieldComponent = fitTextField ? CreateAccountField : TextField

  return (
    <Controller<FV>
      name={name}
      control={control}
      rules={typeof rules === 'string' ? { required: rules } : rules}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <TextFieldComponent
          multiline={multiline}
          rows={rows}
          error={!!error}
          inputRef={ref}
          fullWidth={fullWidth}
          helperText={error ? error.message : ''}
          variant="outlined"
          type={textFieldType}
          InputProps={inputProps}
          label={textInputLabel}
          {...textInputProps}
          {...field}
        />
      )}
    />
  )
}

export default JTextField

import {
  SessionKey,
  sessionStorageService,
} from '@/services/SessionStorage/SessionStorageService'

const useAttorneyId = () => {
  const getAttorneyId = () =>
    sessionStorageService.getItem(SessionKey.ATTORNEY_ID_KEY)
  const setAttorneyId = (id: string) => {
    const event = new Event('setAttorneyId')

    sessionStorageService.setItem(SessionKey.ATTORNEY_ID_KEY, id)
    document.dispatchEvent(event)
  }
  const removeAttorneyId = () =>
    sessionStorageService.removeItem(SessionKey.ATTORNEY_ID_KEY)

  return {
    getAttorneyId,
    setAttorneyId,
    removeAttorneyId,
  }
}

export default useAttorneyId

import { useState } from 'react'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import request from '@/axios/config'
import setAuthToken from '@/axios/setAuthToken'
import { useDeleteCaseFileMutation, Case_File } from '@/gql/systemApi'
import { ConfirmationModal } from '@/components'
import {
  SessionKey,
  sessionStorageService,
} from '@/services/SessionStorage/SessionStorageService'
import { useTheme } from '@mui/material/styles'
import useRemoveActions from '@/components/DataRoom/FilesViewer/useRemoveActions'
import JSnackBar from '@/components/atoms/JSnackBar'
import useFeeAgreements from '../useFeeAgreements'

interface FeeAgreementFilesProps {
  isOwner: boolean
  caseId: string
  casePurchaseId: string
  files?: Case_File[]
  refetchCaseData: () => Promise<void>
}

const FeeAgreementFiles = ({
  isOwner,
  caseId,
  casePurchaseId,
  files = [],
  refetchCaseData,
}: FeeAgreementFilesProps) => {
  const theme = useTheme()
  const [isWorking, setIsWorking] = useState(false)
  const { alert, handleClose, updateFeeAgreement } = useFeeAgreements()

  const onDownloadFile = async (url: string, filename: string) => {
    setIsWorking(true)
    try {
      const token = sessionStorageService.getItem(SessionKey.TOKEN)
      setAuthToken(token)
      const response = await request.get(url, {
        responseType: 'blob',
      })

      const href = window.URL.createObjectURL(new Blob([response.data]))
      const anchorElement = document.createElement('a')
      anchorElement.href = href
      anchorElement.setAttribute('download', filename)
      document.body.appendChild(anchorElement)
      anchorElement.click()

      document.body.removeChild(anchorElement)
      window.URL.revokeObjectURL(href)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setIsWorking(false)
    }
  }

  const onDownloadHandler = async (fileId: string, fileName: string) => {
    const downloadFilePath = `assets/${fileId}?download`
    onDownloadFile(downloadFilePath, fileName)
  }

  const onDeleteAllFilesHandler = async () => {
    const deleteAllFilesPath = `dataroom/files/case/${caseId}`

    setIsWorking(true)
    try {
      const token = sessionStorageService.getItem(SessionKey.TOKEN)
      setAuthToken(token)
      await request.delete(deleteAllFilesPath)

      await updateFeeAgreement({
        feeAgreementStatusId: null,
        casePurchaseId,
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setIsWorking(false)
    }
  }

  const [updateUsersMeMutation] = useDeleteCaseFileMutation({})

  const onDeleteFileHandler = async (fileId: string) => {
    setIsWorking(true)

    await updateUsersMeMutation({
      variables: {
        deleteFilesItemId: fileId,
      },
    })

    if (files.length === 1) {
      await updateFeeAgreement({
        feeAgreementStatusId: null,
        casePurchaseId,
      })
    }

    await refetchCaseData()

    setIsWorking(false)
  }

  const { modal, onRemove, onCloseModal, onSubmitModal } = useRemoveActions({
    removeAllFunction: onDeleteAllFilesHandler,
    removeFunction: onDeleteFileHandler,
    isFeeAgreement: true,
  })

  if (files.length === 0) return null

  return (
    <Box
      sx={{
        my: 2,
      }}
    >
      {files.map(({ file_id = {} }) => {
        const fileName = file_id?.filename_download || ''
        const fileId = file_id?.id || ''

        return (
          <Paper
            key={`fileViewer-item-${fileId}`}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 1.5,
              py: 1,
              backgroundColor: theme.palette.background.page,
              borderRadius: 1,
              border: '1px solid',
              borderColor: theme.palette.divider,
            }}
            elevation={0}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <InsertDriveFileOutlinedIcon
                fontSize="small"
                sx={{ fontSize: 20, mr: 1 }}
              />
              <Tooltip title={fileName}>
                <Typography
                  variant="caption"
                  sx={{
                    maxWidth: 200,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {fileName}
                </Typography>
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <IconButton
                sx={{ width: 30, height: 30, p: 1, mr: 1 }}
                onClick={() => onDownloadHandler(fileId, fileName)}
                aria-label="download fee agreement file"
                {...(isWorking ? { disabled: true } : {})}
              >
                <FileDownloadOutlinedIcon
                  sx={{
                    fontSize: 20,
                  }}
                />
              </IconButton>
              {isOwner && (
                <IconButton
                  color="error"
                  sx={{ width: 30, height: 30, p: 1 }}
                  onClick={() => onRemove(fileId, fileName)}
                  aria-label="delete fee agreement file"
                  {...(isWorking ? { disabled: true } : {})}
                >
                  <DeleteOutlineIcon
                    sx={{
                      fontSize: 20,
                    }}
                  />
                </IconButton>
              )}
            </Box>
          </Paper>
        )
      })}
      <JSnackBar
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        horizontal="center"
        vertical="bottom"
        handleClose={handleClose}
      />
      {modal.open && (
        <ConfirmationModal
          open={modal.open}
          title={modal.title as string}
          onClose={onCloseModal}
          AcceptButtonProps={{
            children: modal.buttonText,
            onClick: onSubmitModal,
          }}
        >
          {modal.description}
        </ConfirmationModal>
      )}
    </Box>
  )
}

export default FeeAgreementFiles

import { useCallback, useRef, useState } from 'react'
import {
  GetUserFileAccessRequestDocument,
  useCreateFileAccessRequestMutation,
} from '@/gql/appApi'
import { SubmitHandler, useForm, FieldValues } from 'react-hook-form'
import useAttorneyId from '@/hooks/useAttorneyId'

export interface IUseRequestAccessProps {
  caseId: string
  userId?: string
  onSuccess: () => void
}

const useRequestAccess = ({
  caseId,
  userId,
  onSuccess,
}: IUseRequestAccessProps) => {
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = useRef(getAttorneyId())
  const [requestAccess, { loading }] = useCreateFileAccessRequestMutation({
    refetchQueries: [GetUserFileAccessRequestDocument],
  })
  const [error, setError] = useState<string | null>(null)
  const { control, handleSubmit } = useForm({
    defaultValues: {
      requestMessage: '',
    },
  })

  const handleRequestAccess: SubmitHandler<FieldValues> = useCallback(
    async ({ requestMessage }) => {
      if (!caseId || !userId) {
        setError('Access request failed. Please try again.')
        return
      }

      const { data, errors } = await requestAccess({
        variables: {
          data: {
            requested_by_user_id: userId,
            attorney_id: attorneyId.current,
            requested_case_id: {
              id: caseId,
            },
            ...(requestMessage && {
              message: requestMessage,
            }),
          },
        },
      })

      if (data?.create_user_file_access_request_item?.id) {
        onSuccess()
        return
      }

      if (errors) {
        setError(
          errors[0].message || 'Access request failed. Please try again.'
        )
      }
    },
    [caseId, onSuccess, requestAccess, userId]
  )

  return {
    handleRequestAccess,
    control,
    handleSubmit,
    loading,
    setError,
    error,
  }
}

export default useRequestAccess

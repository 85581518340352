import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const ContainerResponsive = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
}))

export default ContainerResponsive

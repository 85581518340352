import JDatePicker from '@/components/DatePicker'
import Grid from '@mui/material/Grid'
import JAutocomplete from '../JAutocomplete'
import { TJMalpracticeInsuranceProps } from './types'

const JMalpracticeInsurance = ({
  control,
  options,
  malpracticeName,
  expirationDateName,
  showExpirationDatePicker,
  loading,
}: TJMalpracticeInsuranceProps) => (
  <>
    <Grid item xs={12} md={showExpirationDatePicker ? 7 : 12}>
      <JAutocomplete
        control={control}
        name={malpracticeName}
        options={options}
        label="Malpractice insurance coverage"
        getOptionLabel={option => option.name}
        disabled={loading}
        fullWidth
        rules={{
          required: 'Please select a Malpractice insurance coverage.',
          validate: {
            required: value => value !== '',
          },
        }}
      />
    </Grid>
    {showExpirationDatePicker && (
      <Grid item md={5} xs={12}>
        <JDatePicker
          name={expirationDateName}
          control={control}
          label="Valid until"
          disablePast
          rules={{
            required: `Please select your insurance's expiration date`,
            validate: {
              required: value => value !== null || value !== '',
            },
          }}
        />
      </Grid>
    )}
  </>
)

export default JMalpracticeInsurance

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { CaseInformation } from '@/components'
import { JCaseType, ModalTypes } from '@/types'
import { IJUser } from '@/components/atoms/JUser/types'
import { useSizes } from '@/hooks/useSizes'
import { CASE_ACCESS_TYPES, CASE_PURCHASE_STATUS } from '@/constants'
import CaseMessageButton from '@/components/GetStreamChat/CaseMessageButton'
import { WithdrawProposalButton } from '@/components/WithdrawProposalButton/WithdrawProposalButton'
import { Case_Purchase_Activity } from '@/gql/appApi'
import { useFeatureFlags } from '@/optimizely/useFeatureFlags'
import { FeatureFlag } from '@/optimizely/types'
import AttorneyCase from '../Sidebar/components/AttorneyCase'
import SidebarFee from '../Sidebar/components/SidebarFee'
import { AccessType, TCase } from '../../types'

export interface PublicCasesButtonsProps {
  caseActivity: Case_Purchase_Activity[]
  caseOwnerAttorneyId?: string
  isAttorneyLastActivity: boolean
  accessType: AccessType
  setGenericOpenModal: React.Dispatch<React.SetStateAction<ModalTypes>>
  caseInfo: TCase
  profile: IJUser
  isVetted: boolean
  isQualified: boolean
  isSameOrganization?: boolean
  setOpenModal: React.Dispatch<
    React.SetStateAction<{
      open: boolean
      jCase: JCaseType | null
      fee: number | null
    }>
  >
  showNonVettedModal: React.Dispatch<React.SetStateAction<boolean>>
  loading?: boolean
  chatCaseAttorneyId?: string
  isNonVettedAdminOrCaseMngWithJhqMembersInOrg?: boolean
  caseIsTakenByMyOrg?: boolean
  prospectAttorneyProfile?: IJUser
  isPendingPayment?: boolean
  attorneyTitle?: string
  casePurchaseId: string
  fee: number
}

const PublicCasesButtons = ({
  caseActivity,
  isAttorneyLastActivity,
  accessType,
  setOpenModal,
  isVetted,
  isQualified,
  setGenericOpenModal,
  caseInfo,
  showNonVettedModal,
  profile,
  isSameOrganization,
  loading,
  isNonVettedAdminOrCaseMngWithJhqMembersInOrg = false,
  caseIsTakenByMyOrg = false,
  prospectAttorneyProfile,
  isPendingPayment,
  attorneyTitle,
  casePurchaseId,
  fee,
}: PublicCasesButtonsProps) => {
  const { isLessMd } = useSizes()
  const featureFlags = useFeatureFlags()
  const isNegotiationFlowImprovementEnabled =
    featureFlags[FeatureFlag.NEGOTIATION_FLOW_IMPROVEMENTS]
  const isWithdrawn =
    caseActivity[0]?.activity_entry_status?.id ===
    CASE_PURCHASE_STATUS.Withdrawn

  const isProposed =
    caseActivity[0]?.activity_entry_status?.id === CASE_PURCHASE_STATUS.Proposed

  const canProposalBeWithdrawn = isAttorneyLastActivity && isProposed

  const renderButtons = (): JSX.Element | null => {
    if (caseIsTakenByMyOrg) {
      return !isLessMd ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 2.5,
          }}
        >
          <AttorneyCase
            caseOwner={prospectAttorneyProfile}
            relationshipType="attorney"
            title={attorneyTitle}
            showSendMessage
          />
        </Box>
      ) : null
    }

    if (caseActivity?.length === 1 || isWithdrawn) {
      if (isSameOrganization) {
        return !isLessMd ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 2.5,
            }}
          >
            <CaseInformation
              profile={profile}
              {...(accessType === CASE_ACCESS_TYPES.Invited && {
                proposalType: 'Invited',
              })}
              isSameOrg={isSameOrganization}
              attorneyTitle={attorneyTitle}
            />
            <CaseMessageButton fullWidth size="large" variant="outlined" />
          </Box>
        ) : null
      }

      return (
        <>
          {!isLessMd && (
            <SidebarFee
              fee={fee}
              sx={{
                py: 1,
              }}
            />
          )}
          <Button
            variant="contained"
            size="large"
            disabled={
              isWithdrawn ? false : isAttorneyLastActivity && !isPendingPayment
            }
            onClick={() => {
              if (
                accessType === CASE_ACCESS_TYPES.NoAccess &&
                !(
                  isVetted ||
                  isQualified ||
                  isNonVettedAdminOrCaseMngWithJhqMembersInOrg
                )
              ) {
                showNonVettedModal(true)
              } else {
                setOpenModal({
                  open: true,
                  jCase: { id: caseInfo.id, name: caseInfo.name || '' },
                  fee:
                    caseInfo.fee !== undefined && caseInfo.fee !== null
                      ? caseInfo.fee
                      : 0,
                })
              }
            }}
          >
            {isPendingPayment ? 'Complete Payment' : 'Send Proposal'}
          </Button>
          <WithdrawProposalButton
            casePurchaseId={casePurchaseId}
            canProposalBeWithdrawn={canProposalBeWithdrawn}
          />
          {(isVetted ||
            isQualified ||
            isNonVettedAdminOrCaseMngWithJhqMembersInOrg) && (
            <CaseMessageButton size="large" variant="outlined" fullWidth />
          )}
        </>
      )
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 1.5,
        }}
      >
        {!isLessMd && (
          <CaseInformation
            profile={profile}
            attorneyTitle={attorneyTitle}
            {...(isNegotiationFlowImprovementEnabled && { fee })}
          />
        )}
        <Button
          size="large"
          fullWidth
          variant="contained"
          disabled={isAttorneyLastActivity || loading}
          onClick={() => {
            setGenericOpenModal('Accept')
          }}
        >
          Accept Proposal
        </Button>
        {!isAttorneyLastActivity && (
          <Button
            size="large"
            variant="outlined"
            fullWidth
            disabled={isAttorneyLastActivity || loading}
            onClick={() => {
              setGenericOpenModal('Counteroffer')
            }}
          >
            Send Counteroffer
          </Button>
        )}
        {isAttorneyLastActivity ? (
          <WithdrawProposalButton
            casePurchaseId={casePurchaseId}
            canProposalBeWithdrawn={canProposalBeWithdrawn}
          />
        ) : (
          <Button
            size="large"
            variant="text"
            disabled={isAttorneyLastActivity || loading}
            fullWidth
            onClick={() => {
              setGenericOpenModal('Reject')
            }}
            color="error"
          >
            Decline Proposal
          </Button>
        )}
        {!isLessMd && (
          <CaseMessageButton fullWidth size="large" variant="outlined" />
        )}
      </Box>
    )
  }

  return renderButtons()
}

export default PublicCasesButtons

import { useContext } from 'react'
import { OptimizelyContext } from './OptimizelyContext'

/**
 * Hook to use the optimizely instance
 *
 * @example
 *
 * import { useOptimizely } from '@/optimizely/useOptimizely'
 *
 * const { optimizely } = useOptimizely()
 * const isEnabled = optimizely.isFeatureEnabled(
 *    'marketplace_v2_enabled',
 *   '0'
 * )
 */

export const useOptimizely = () => useContext(OptimizelyContext)

import { useCallback } from 'react'
import { useUpdateCaseItemMutation } from '@/gql/appApi'
import dayjs from 'dayjs'
import { ICaseForm, TLeadFormData } from '@/components/Case/CaseForm/types'
import { CONTACT_TYPES, DEFAULT_CASE_FEE } from '@/constants'

const useEditCase = () => {
  const [editCaseMutation] = useUpdateCaseItemMutation()

  const editCase = useCallback(
    async (formData: ICaseForm, caseId: string) => {
      // Process the form data
      const getContacts = (lead: TLeadFormData) => {
        const newContacts = [
          lead.email && {
            type: CONTACT_TYPES.EmailContactType,
            value: lead.email,
          },
          lead.phone && {
            type: CONTACT_TYPES.PhoneContactType,
            value: lead.phone,
          },
        ]
        const n = newContacts.filter(contact => contact)
        return n
      }

      const leads = formData.leadInfo
        ?.map(
          (l: TLeadFormData) =>
            l.firstName &&
            l.firstName !== '' && {
              first_name: l.firstName,
              last_name: l.lastName,
              contacts: getContacts(l),
            }
        )
        .filter(l => l)

      const options = {
        ...(formData.caseAlias && { case_alias: formData.caseAlias }),
        ...(formData.caseNumber && { case_number: formData.caseNumber }),
        ...(formData.legalStage && { process_stage: formData.legalStage }),
        ...(formData.proposalsDueDate && {
          proposal_due_date: dayjs(formData.proposalsDueDate).format(
            'YYYY-MM-DD'
          ),
        }),
        ...(formData.caseType && { type: formData.caseType }),
        ...(formData.incidentDate && {
          incident_date: dayjs(formData.incidentDate).format('YYYY-MM-DD'),
        }),
        ...(formData.internalCaseNumber && {
          internal_case_number: formData.internalCaseNumber,
        }),
        ...(formData.jurisdiction && { jurisdiction: formData.jurisdiction }),
        ...(formData.language && { preferred_language: formData.language }),
      }

      const updatePayload = {
        updateCaseItemId: caseId,
        data: {
          description: formData.caseDescription,
          description_formatted: formData.caseDescriptionFormatted,
          fee: parseInt(formData?.fee ?? DEFAULT_CASE_FEE, 10),
          financing_source: formData.financingSource,
          leads,
          name: formData.caseName,
          // TODO: Uncomment when mp_visibility is added to the form
          // mp_visibility: formData.isPriority
          //   ? MP_VISIBILITY.Priority
          //   : MP_VISIBILITY.Public,
          ...options,
        },
      }

      try {
        // Execute the mutation
        const { data, errors } = await editCaseMutation({
          variables: updatePayload,
        })

        // Handle the response
        if (data) {
          // Case edit successful
          return {
            id: caseId,
          }
        }

        if (errors) {
          // Handle errors
          return {
            editCaseErrors: {
              base: 'Case update failed. Please try again.',
              server: errors[0]?.message,
            },
          }
        }

        return {}
      } catch (error) {
        // Check if error is an instance of Error
        const errorMessage =
          error instanceof Error ? error.message : 'Unknown error occurred'

        // eslint-disable-next-line no-console
        console.error('Error updating case:', errorMessage)
        return {
          editCaseErrors: {
            base: 'An error occurred while updating the case.',
            server: errorMessage,
          },
        }
      }
    },
    [editCaseMutation]
  )

  return editCase
}

export default useEditCase

import { object, string } from 'yup'

import { MAX_BIO_LENGTH } from '@/constants'

export type PersonalInfoFormValues = {
  bio: string | undefined
}

const personalInfoSchema = object({
  bio: string().max(MAX_BIO_LENGTH),
}).required()

const defaultValues = {
  bio: '',
}

export { defaultValues, personalInfoSchema }

import { GridColDef } from '@mui/x-data-grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import { ATTORNEYS_ROLES_BY_ID, INVITATION_STATUS } from '@/constants'

export interface GetInvitationsSentColumnsProps {
  onSendInvitation: (id: string) => void
  onDeleteInvitation: (id: string) => void
}

export const getInvitationsSentColumns = ({
  onSendInvitation,
  onDeleteInvitation,
}: GetInvitationsSentColumnsProps) => {
  const invitationsSentColumns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: params => <Typography>{params.value}</Typography>,
    },
    {
      field: 'target_role_id',
      headerName: 'Role',
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: params => {
        const { id } = params.value
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        return <Typography>{ATTORNEYS_ROLES_BY_ID[id]}</Typography>
      },
    },
    {
      field: 'invite_status_id',
      headerName: 'Status',
      sortable: true,
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: params => (
        <Typography>
          {params.value.id === INVITATION_STATUS.Sent ||
          params.value.id === INVITATION_STATUS.Resent
            ? 'Active'
            : 'Expired'}
        </Typography>
      ),
    },
    {
      field: 'act',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      flex: 1.5,
      renderCell: params => {
        const { row } = params
        const isExpired = row.invite_status_id.id === INVITATION_STATUS.Expired
        return (
          <Box sx={{ display: 'flex', gap: 2 }}>
            {isExpired && (
              <Button
                variant="outlined"
                onClick={() => onSendInvitation(params.id as string)}
              >
                Resend Invitation
              </Button>
            )}
            <IconButton onClick={() => onDeleteInvitation(params.id as string)}>
              <DeleteOutlinedIcon sx={{ color: 'error.main' }} />
            </IconButton>
          </Box>
        )
      },
    },
  ]
  return invitationsSentColumns
}

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { ProfileType } from '@/types'

import ReadMore from '@/components/ReadMore'
import JProfileItem from '@/components/atoms/JProfileItem'
import { PersonOutline } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import { PREFERRED_LANGUAGE } from '@/constants'
import { ProfileDescription } from './ProfileBio.styles'

interface IProfileBioProps {
  profile: ProfileType
  loading?: boolean
}

const ProfileBio = ({
  profile: { bio, education, languages, licenses },
  loading,
}: IProfileBioProps) => {
  const withEducation = education && education.length > 0

  // const withAwards = awards && awards.length > 0

  const filteredLanguages = languages?.filter(
    language => language?.language_id?.id !== PREFERRED_LANGUAGE.PL_English
  )
  const withLanguages = filteredLanguages && filteredLanguages?.length > 0

  const withLicenses = (licenses?.length ?? 0) > 0

  const emptyProfile =
    !bio &&
    !withEducation &&
    // !withAwards &&
    !withLanguages &&
    !withLicenses

  if (loading)
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={60} />
      </Box>
    )

  if (emptyProfile)
    return (
      <Box
        sx={{
          height: '100%',
          padding: {
            xs: 2,
            md: 1,
          },
          pb: {
            xs: 8,
            md: 0,
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PersonOutline
          sx={theme => ({
            fontSize: 60,
            color: theme.palette.primary.main,
            mb: 2,
          })}
        />
        <Typography variant="h6" color="primary" sx={{ textAlign: 'center' }}>
          Looks like this attorney hasn&apos;t filled out their profile yet
        </Typography>
        <Typography
          variant="body1"
          sx={{ mt: 1.5, textAlign: 'center' }}
          color="primary"
        >
          Check back later to learn more about them.
        </Typography>
      </Box>
    )

  return (
    <ProfileDescription>
      <Grid container>
        {bio && (
          <Grid item>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Bio</Typography>
            </Grid>
            <Grid item xs={12}>
              <ReadMore unexpandedValue={bio.slice(0, 150)}>{bio}</ReadMore>
            </Grid>
          </Grid>
        )}
        {withEducation && (
          <Grid container item sx={{ mt: bio ? 5 : 0 }}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Education</Typography>
            </Grid>
            <Grid
              container
              item
              direction="row"
              columnSpacing={8}
              rowSpacing={3}
              md={10}
              xs={12}
            >
              {education.map(school => (
                <Grid item key={school.school_id.id}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2">
                      {school.school_id.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {/* {withAwards && (
          <Grid container item sx={{ mt: 5 }}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Awards</Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              {awards.map(award => (
                <JProfileItem
                  key={award.award_id.id}
                  text={award.award_id.name}
                  icon={
                    <WorkspacePremiumOutlined
                      sx={theme => ({
                        color: theme.palette.common.black,
                        width: theme.spacing(2),
                        height: theme.spacing(2),
                      })}
                    />
                  }
                />
              ))}
            </Grid>
          </Grid>
        )} */}
        {withLanguages && (
          <Grid container item sx={{ mt: withEducation || bio ? 5 : 0 }}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Extra Languages</Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              {filteredLanguages.map(language => (
                <JProfileItem
                  key={language.language_id.id}
                  text={language.language_id.name}
                />
              ))}
            </Grid>
          </Grid>
        )}

        {withLicenses && (
          <Grid container item sx={{ mt: withLicenses || bio ? 5 : 0 }}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Jurisdictions</Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              {licenses?.map(license => (
                <JProfileItem
                  key={license.license_state.name}
                  text={license.license_state.name}
                />
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </ProfileDescription>
  )
}

export default ProfileBio

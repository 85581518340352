import { useOptimizely } from '@/optimizely/useOptimizely'
import { useUsersMeQuery } from '@/gql/systemApi'

export function useOptimizelyVariable<T>(
  flagKey: string,
  variableName: string,
  defaultValue: T
): T {
  const { optimizely } = useOptimizely()
  const { data: userMe } = useUsersMeQuery()
  const userId = userMe?.users_me?.id ?? '0'

  if (optimizely && userId) {
    const variableValue = optimizely.getFeatureVariable(
      flagKey,
      variableName,
      userId
    )

    if (
      variableValue === null ||
      typeof variableValue !== typeof defaultValue
    ) {
      console.error(
        `Optimizely variable ${variableName} has unexpected type or is null. Expected type: ${typeof defaultValue}, Actual: ${
          variableValue === null ? 'null' : typeof variableValue
        }`
      )
      return defaultValue
    }

    return variableValue as T
  }

  return defaultValue
}

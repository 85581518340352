import { useEffect, useState } from 'react'
import { uniq } from 'lodash'
import {
  useGetDashboardAttorneyLazyQuery,
  useGetDashboardOrganizationLazyQuery,
} from '@/gql/appApi'
import {
  mapDashboardAttoneyResponse,
  mapDashboardOrganizationResponse,
} from '@/pages/Dashboard/utils/responseMappers'
import {
  IDashboardAttorney,
  IDashboardOrganization,
} from '@/pages/Dashboard/types'
import {
  useGetUsersAvatarsLazyQuery,
  useUserOrganizacionQuery,
} from '@/gql/systemApi'
import useAttorneyId from './useAttorneyId'

interface UseDashboardDataProps {
  limit?: number
}

export function useDashboardData({ limit = 5 }: UseDashboardDataProps = {}) {
  const { data: organizationIdData, error: organizationIdDataError } =
    useUserOrganizacionQuery()

  const [getOrganizationData, organizationData] =
    useGetDashboardOrganizationLazyQuery()

  const { getAttorneyId } = useAttorneyId()
  const attorneyId = getAttorneyId()
  const [getAttorneyData, attorneyData] = useGetDashboardAttorneyLazyQuery()

  const [mappedOrganizationData, setMappedOrganizationData] = useState<
    IDashboardOrganization | undefined
  >()
  const [mappedAttorneyData, setMappedAttorneyData] = useState<
    IDashboardAttorney | undefined
  >()

  useEffect(() => {
    if (!organizationIdData) return
    ;(async () => {
      const organizationId =
        organizationIdData?.users_me?.current_organization?.[0]?.organization_id
          ?.id

      const [fetchedOrganizationData, fetchedAttorneyData] = await Promise.all([
        getOrganizationData({
          variables: {
            metricsFilter: {
              organization_id: {
                _eq: organizationId,
              },
            },
            topAttorneysFilter: {
              organization_id: {
                _eq: organizationId,
              },
            },
            topPracticeAreasFilter: {
              organization_id: {
                _eq: organizationId,
              },
            },
            topJurisdictionsFilter: {
              organization_id: {
                _eq: organizationId,
              },
            },
            limit,
            topAttorneysSort: '-num_cases',
            topPracticeAreaSort: '-num_cases',
            topJurisdictionsSort: '-num_cases',
          },
        }),

        getAttorneyData({
          variables: {
            metricsFilter: {
              attorney_id: {
                _eq: attorneyId,
              },
            },
            topAttorneysFilter: {
              attorney_id: {
                _eq: attorneyId,
              },
            },
            topPracticeAreasFilter: {
              attorney_id: {
                _eq: attorneyId,
              },
            },
            topJurisdictionsFilter: {
              attorney_id: {
                _eq: attorneyId,
              },
            },
            limit: 5,
            topAttorneysSort: '-num_cases',
            topPracticeAreaSort: '-num_cases',
            topJurisdictionsSort: '-num_cases',
          },
        }),
      ])

      setMappedOrganizationData(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        mapDashboardOrganizationResponse(fetchedOrganizationData.data)
      )

      setMappedAttorneyData(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        mapDashboardAttoneyResponse(fetchedAttorneyData.data)
      )
    })()
  }, [
    attorneyId,
    getAttorneyData,
    getOrganizationData,
    limit,
    organizationIdData,
  ])

  const [getUsersAvatars, { data: avatarData }] = useGetUsersAvatarsLazyQuery()
  const [avatarMap, setAvatarMap] = useState<Record<string, string>>({})

  useEffect(() => {
    if (!mappedOrganizationData || !mappedAttorneyData) return
    ;(async () => {
      const organizationQueryAttorneyIds =
        mappedOrganizationData.topAttorneysOrganization.map(
          topAttorney => topAttorney.attorneyId
        )

      const attorneyQueryAttorneyIds =
        mappedAttorneyData.topAttorneysAttorney.map(
          topAttorney => topAttorney.attorneyId
        )

      const attorneyIds = uniq([
        ...organizationQueryAttorneyIds,
        ...attorneyQueryAttorneyIds,
      ])

      if (attorneyIds.length === 0) {
        return
      }

      const usersAvatars = await getUsersAvatars({
        variables: {
          filter: {
            attorneys: {
              profiles: {
                attorney_id: {
                  id: {
                    _in: attorneyIds,
                  },
                },
              },
            },
          },
        },
      })

      const newAvatarMap = usersAvatars.data?.users.reduce(
        (acc: typeof avatarMap, { avatar, attorneys }) => {
          const avatarAttorneyId =
            attorneys?.[0]?.profiles?.[0]?.attorney_id?.id

          if (avatarAttorneyId && avatar?.filename_disk) {
            acc[avatarAttorneyId] = avatar.filename_disk
          }

          return acc
        },
        {}
      )

      setAvatarMap(oldAvatarMap => ({ ...oldAvatarMap, ...newAvatarMap }))
    })()
  }, [
    avatarData,
    organizationData,
    getUsersAvatars,
    mappedOrganizationData,
    mappedAttorneyData,
  ])

  return {
    avatarMap,
    error:
      organizationIdDataError || organizationData.error || attorneyData.error,
    organization: { ...organizationData, data: mappedOrganizationData },
    attorney: { ...attorneyData, data: mappedAttorneyData },
  }
}

import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

const ModalFooter = styled(Box)(({ theme }) => ({
  flexDirection: 'row-reverse',
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3, 3, 3, 3),
  gap: theme.spacing(2),
}))

export default ModalFooter

import axios from 'axios'
import request from '@/axios/config'
import { useQuery } from 'react-query'
import { CreditsResponse, MembersCreditsResponse } from './types'
import { validateToken } from './validateToken'

const fetchMembersCredits = async (): Promise<CreditsResponse | null> => {
  validateToken()
  try {
    const req = await request.get<MembersCreditsResponse>(`/members/credits`)
    if (req.data) {
      return {
        data: req.data,
        error: '',
      }
    }
    return { data: null, error: 'Data not found' }
  } catch (error) {
    let errorMessage = 'An unexpected error occurred'
    if (axios.isAxiosError(error)) {
      errorMessage = error.message
    }
    return {
      data: null,
      error: errorMessage,
    }
  }
}

export const useMembersCredits = () =>
  useQuery<CreditsResponse | null, Error>(
    ['membersCredits'],
    () => fetchMembersCredits(),
    {
      // Used to fetch data only when needed
      enabled: false,
    }
  )

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import LogoBar from '@/pages/CreateAccount/shared/LogoBar'
import { CheckCircleOutline } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { RoutesData } from '@/router/RoutesData'

type PasswordSuccessProps = {
  title: string
  subtitle: string
  btnText: string
}

const PasswordSuccess = ({
  title,
  subtitle,
  btnText,
}: PasswordSuccessProps) => {
  const navigate = useNavigate()
  return (
    <>
      <LogoBar />
      <Alert
        severity="success"
        variant="filled"
        sx={{
          backgroundColor: theme => theme.palette.success.lightest,
          alignItems: 'flex-start',
        }}
        iconMapping={{
          success: (
            <CheckCircleOutline
              fontSize="inherit"
              sx={{ color: theme => theme.palette.success.light }}
            />
          ),
        }}
      >
        <AlertTitle>
          <Typography
            variant="subtitle1"
            sx={{ color: theme => theme.palette.success.dark }}
          >
            {title}
          </Typography>
        </AlertTitle>
        <Typography
          variant="body2"
          sx={{ color: theme => theme.palette.success.dark }}
        >
          {subtitle}
        </Typography>
      </Alert>
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          justifyContent: { xs: 'center', md: 'flex-end' },
        }}
      >
        <Button
          onClick={() => navigate(RoutesData.RSignIn.path)}
          size="large"
          variant="contained"
        >
          {btnText}
        </Button>
      </Box>
    </>
  )
}

export default PasswordSuccess

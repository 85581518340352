import React, { useEffect } from 'react'
import ContentCaseLayout from '@/components/ContentCaseLayout'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum'
import { CaseProvider } from './context'
import { useCaseContext } from './hooks/useCaseContext'
import CaseHeader from './components/CaseHeader'
import CaseAlerts from './components/CaseAlerts'
import CaseInformation from './components/CaseInformation'
import Dataroom from './components/Dataroom'
import NegotiationHistory from './components/NegotiationHistory'
import Sidebar from './components/Sidebar'
import { CaseLayout } from './layout'

type CaseParams = {
  id: string
}

export const CaseContent: React.FC = () => {
  const { id: caseId } = useParams<CaseParams>()
  const { fetchCaseData, error } = useCaseContext()

  useEffect(() => {
    // Skip fetching in Storybook to prevent 404s
    if ('STORYBOOK_ENV' in window) return

    if (!caseId) throw new Error('No case ID found in URL parameters')
    ;(async () => {
      try {
        await fetchCaseData(caseId)
      } catch (err) {
        datadogRum.addError(err)
      }
    })()
  }, [caseId, fetchCaseData])

  // Display error page if there is an error
  if (error) throw Error(error)

  return (
    <ContentCaseLayout>
      <CaseLayout>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 12,
            pb: { xs: 4, md: 8 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              flex: 1,
            }}
          >
            <CaseHeader />
            <CaseAlerts />
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <Sidebar />
            </Box>
            <CaseInformation />
            <Dataroom />
            <NegotiationHistory />
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'block' },
              width: '360px',
              flexShrink: 0,
            }}
          >
            <Sidebar />
          </Box>
        </Box>
      </CaseLayout>
    </ContentCaseLayout>
  )
}

const CaseV2: React.FC = () => (
  <CaseProvider>
    <CaseContent />
  </CaseProvider>
)

export default CaseV2

import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'

export const RadioTitleTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}))

export const RadioSubTitleTypography = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.common.black, 0.6),
}))

export const CustomRadio = styled(Radio)(({ theme }) => ({
  alignSelf: 'flex-start',
  marginRight: theme.spacing(1),
  paddingTop: theme.spacing(0),
  transform: 'scale(0.8)',
}))

import { useState } from 'react'
import { Controller } from 'react-hook-form'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'

import JTextField from '@/components/atoms/JTextField'
import { isValidEmail } from '@/utils/helpers'
import FormHelperText from '@mui/material/FormHelperText'
import ConfirmationModal from '../ConfirmationModal'
import useInviteAttorneyModal from './useInviteAttorneyModal'
import { getRoleCount, getInviteRoles, roleSpecs } from './utils'

export interface InviteAttorneyModalProps {
  open: boolean
  onClose: () => void
  onSubmit: () => void
}

const InviteAttorneyModal = ({
  open,
  onSubmit,
  onClose,
}: InviteAttorneyModalProps) => {
  const {
    handleSubmit,
    onSendInvitation,
    loading,
    fields,
    register,
    control,
    append,
    isLessMd,
    roles,
    watch,
    errors,
  } = useInviteAttorneyModal({ onSubmit })
  const [expanded, setExpanded] = useState(false)

  return (
    <ConfirmationModal
      open={open}
      title="Add New Members"
      AcceptButtonProps={{
        children: 'Send Invitations',
        onClick: handleSubmit(onSendInvitation),
        disabled: loading,
      }}
      onClose={onClose}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: isLessMd ? 4 : 1,
          mt: -2,
        }}
        component="form"
      >
        <Box sx={{ mt: 1 }}>
          <Typography variant="body2">
            There {getRoleCount()} for you to choose from when adding new
            members to your law firm: <b>{getInviteRoles()}</b>
          </Typography>
          <Button
            onClick={() => setExpanded(prev => !prev)}
            variant="text"
            size="small"
            sx={{ p: 0, mt: 2.5, mb: { xs: !expanded ? 0 : 2.5, md: 2.5 } }}
          >
            {expanded ? 'Hide Roles Permissions' : 'View Roles Permissions'}
          </Button>
          <Collapse in={expanded}>
            {Object.entries(roleSpecs).map(([role, description], i) => (
              <Box
                key={role}
                sx={{
                  mt: i === 0 ? 0 : 3,
                  mb: i === Object.entries(roleSpecs).length - 1 ? 2.5 : 0,
                }}
              >
                <Typography variant="subtitle1">{role}</Typography>
                <Typography variant="body2">{description}</Typography>
              </Box>
            ))}
          </Collapse>
        </Box>
        {fields.map((f, i) => {
          const { name: email } = register(`attorneys.${i}.email`)
          const { name: role } = register(`attorneys.${i}.role`)
          return (
            <Box
              key={f.id}
              component="li"
              sx={{
                display: 'flex',
                width: { md: '500px' },
                gap: isLessMd ? 1 : 2,
                flexDirection: isLessMd ? 'column' : 'row',
              }}
            >
              <JTextField
                name={email}
                control={control}
                label="Member Email"
                type="text"
                rules={{
                  validate: {
                    required: value => {
                      const roleValue = watch(`attorneys.${i}.role`)
                      const emailValue = value as string
                      return (
                        (emailValue === '' && roleValue === '') ||
                        isValidEmail(emailValue) ||
                        'Email is required'
                      )
                    },
                  },
                }}
                error={!!errors.attorneys?.[i]?.email}
              />
              <Controller
                name={role}
                control={control}
                rules={{
                  validate: {
                    required: value => {
                      const emailValue = watch(`attorneys.${i}.email`)
                      const emailString = (emailValue as string) || ''
                      return (
                        (value === '' && emailString === '') ||
                        value !== '' ||
                        'Role is required'
                      )
                    },
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl fullWidth error={!!error}>
                    <InputLabel id="member_role">Member Role</InputLabel>
                    <Select
                      labelId="member_role"
                      label="Member Role"
                      value={value}
                      onChange={val => {
                        onChange(val)
                      }}
                    >
                      {roles &&
                        roles.map((roleOption: any) => (
                          <MenuItem
                            key={roleOption.role_id.id}
                            value={roleOption.role_id.id}
                          >
                            {roleOption.name}
                          </MenuItem>
                        ))}
                    </Select>
                    {error && <FormHelperText>{error.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Box>
          )
        })}
      </Box>
      <Box mt={2.5}>
        <Button
          onClick={() => append({ email: '', role: '' })}
          startIcon={<PersonAddAlt1OutlinedIcon sx={{ fontSize: '1rem' }} />}
        >
          Add more members
        </Button>
      </Box>
    </ConfirmationModal>
  )
}

export default InviteAttorneyModal

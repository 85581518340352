import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FolderSpecialOutlined from '@mui/icons-material/FolderSpecialOutlined'
import React, { useCallback } from 'react'
import dayjs from 'dayjs'
import ResponseModal from '@/components/Modals/ResponseModal'
import { RequestDataRoomModal } from '@/components'
import { getImagePath } from '@/utils/helpers'
import { JCaseType, ModalTypes } from '@/types'
import { useSizes } from '@/hooks/useSizes'
import {
  NEW_DATAROOM_ENABLED,
  CASE_ACCESS_TYPES,
  IMAGE_SIZES,
  CASE_STATUS_FLOW,
} from '@/constants'
import { useFileDownload } from '@/hooks/useFileDownload'
import Dataroom from '@/pages/Case/components/Dataroom/Dataroom'
import AccessRequests from '@/pages/Case/components/Dataroom/components/AccessRequests/AccessRequests'
import { isQualifiedMembership } from '@/utils/membership'
import initApollo from '@/apollo'
import {
  Case_Purchase_Activity,
  GetActivityPurchaseDocument,
  GetActivityPurchaseFromMyOrgDocument,
  GetCasesDocument,
} from '@/gql/appApi'
import { CaseDescription } from '@/components/Case/CaseForm/CaseDescription'
import CaseBodyTag from './CaseBodyTag'
import { TCase, AccessType } from '../../types'
import CaseNegotiationItem from './CaseNegotiationItem'
import { UsersWithDataroomAccess } from '../Dataroom/components/AccessRequests/UsersWithDataroomAccess'
import { caseTitles } from '../../hooks/constants'
import Leads from '../Leads/Leads'

interface ICaseBodyProps {
  caseActivity?: Case_Purchase_Activity[]
  caseData: TCase
  accessType: AccessType
  responseModal: {
    type: ModalTypes
    open: boolean
  }
  dataRoomModalOpen: {
    open: boolean
    jCase: JCaseType | null
  }

  setResponseModal: React.Dispatch<
    React.SetStateAction<{
      type: ModalTypes
      open: boolean
    }>
  >
  setDataRoomOpenModal: React.Dispatch<
    React.SetStateAction<{
      open: boolean
      jCase: JCaseType | null
    }>
  >
  leadInfo:
    | {
        id: string | undefined
        firstName: string
        lastName: string
        email: string
        phone: string
      }[]
    | undefined
  refetchCaseData: () => Promise<any>
  showCaseOptions?: boolean
  hasAdminAccess?: boolean
  hasCaseManagerAccess?: boolean
  isCaseManager?: boolean
  currentUserAttorneyId?: string | null
  actionTitle: string | null
  iamProspectAttorneyInExternalCase?: boolean
  caseAccepted?: boolean
  isAttorneyOwner?: boolean
  isSigned?: boolean
  isCaseOwner?: boolean
}

const CaseBody: React.FC<ICaseBodyProps> = ({
  caseData,
  refetchCaseData,
  accessType,
  caseActivity,
  responseModal,
  dataRoomModalOpen,
  setResponseModal,
  setDataRoomOpenModal,
  leadInfo,
  showCaseOptions,
  hasAdminAccess,
  hasCaseManagerAccess,
  currentUserAttorneyId,
  isCaseManager,
  actionTitle,
  iamProspectAttorneyInExternalCase,
  caseAccepted,
  isAttorneyOwner,
  isSigned,
  isCaseOwner,
}: ICaseBodyProps) => {
  const { download } = useFileDownload()
  const { isLessMd } = useSizes()
  const withLeads = leadInfo && isLessMd && !isSigned && !isCaseOwner
  const hasDownloadAccess = accessType === CASE_ACCESS_TYPES.Waterfall
  const isOwner = accessType === CASE_ACCESS_TYPES.Owner

  const isCaseManagerWithAccess = isCaseManager && hasCaseManagerAccess

  const noRestrictedPermission = actionTitle !== caseTitles.noPermission

  const showDataRoom =
    noRestrictedPermission &&
    ((NEW_DATAROOM_ENABLED &&
      (isAttorneyOwner || hasAdminAccess || isCaseManagerWithAccess)) ||
      hasDownloadAccess ||
      iamProspectAttorneyInExternalCase ||
      showCaseOptions)

  const dataroomFiles = caseData?.files?.filter(
    file => !file?.file_id?.metadata?.is_fee_agreement
  )

  const downloadDataRoomFile = useCallback(async () => {
    if (!dataroomFiles?.[0]?.file_id?.id) return null
    try {
      await download(dataroomFiles[0].file_id.id, caseData?.name || '')
      return true
    } catch (err) {
      return null
    }
  }, [dataroomFiles, caseData?.name, download])

  const onCloseResponseModal = useCallback(async () => {
    await initApollo().refetchQueries({
      include: [
        GetActivityPurchaseDocument,
        'case_purchase_activity',
        GetCasesDocument,
        'case',
        GetActivityPurchaseFromMyOrgDocument,
        'case_purchase_activity',
      ],
    })
    setResponseModal({ open: false, type: null })
  }, [setResponseModal])

  const isCaseSigned = caseData?.status === CASE_STATUS_FLOW.Signed

  return (
    <Grid container gap={1.5}>
      {isOwner && noRestrictedPermission ? (
        <AccessRequests caseId={caseData.id} />
      ) : null}
      {withLeads && !iamProspectAttorneyInExternalCase && (
        <Leads leadInfo={leadInfo} />
      )}
      <Grid container gap={1} sx={theme => ({ mb: theme.spacing(1) })}>
        <CaseDescription caseData={caseData} />
        {dataroomFiles?.[0]?.file_id?.id && !NEW_DATAROOM_ENABLED && (
          <Button
            fullWidth={isLessMd}
            size={isLessMd ? 'large' : 'medium'}
            sx={{ mt: 1 }}
            variant="outlined"
            startIcon={<FolderSpecialOutlined />}
            onClick={downloadDataRoomFile}
          >
            Download Data Room
          </Button>
        )}
        {showDataRoom && (
          <Dataroom
            caseData={caseData}
            isOwner={isOwner}
            refetchCaseData={refetchCaseData}
            showCaseOptions={showCaseOptions}
          />
        )}
        {isOwner && !caseAccepted && (
          <UsersWithDataroomAccess caseId={caseData.id} />
        )}
      </Grid>
      {caseData?.type?.name && (
        <CaseBodyTag title="Case Type" description={caseData.type.name} />
      )}
      {caseData?.process_stage?.name && (
        <CaseBodyTag
          title="Process Stage"
          description={caseData.process_stage.name}
        />
      )}
      {caseData && caseData.case_number && (
        <CaseBodyTag title="Case Number" description={caseData.case_number} />
      )}
      {caseData?.jurisdiction && (
        <CaseBodyTag
          title="Location"
          description={
            caseData &&
            `${`${caseData?.jurisdiction.code} ${
              caseData?.jurisdiction ? caseData?.jurisdiction.name || '' : ''
            }`}`
          }
        />
      )}
      {caseData?.incident_date && (
        <CaseBodyTag
          title="Incident Day"
          description={dayjs(caseData?.incident_date).format('DD MMM YYYY')}
        />
      )}
      {caseData?.preferred_language?.name && (
        <CaseBodyTag
          title="Support Language"
          description={caseData?.preferred_language.name}
        />
      )}
      {!isOwner && !isCaseSigned && (
        <Grid
          container
          gap={1}
          sx={theme => ({
            marginTop: theme.spacing(3),
            [theme.breakpoints.down('md')]: {
              marginBottom: theme.spacing(23),
            },
          })}
        >
          {caseActivity &&
          caseActivity?.[0].created_by?.profiles?.[0]?.id !== null ? (
            <Grid item xs={12}>
              <Typography variant="h6">Negotiation History</Typography>
            </Grid>
          ) : null}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              height: '100%',
            }}
          >
            {caseActivity &&
            caseActivity?.[0].created_by?.profiles?.[0]?.id !== null
              ? caseActivity.map((d: any) => (
                  <CaseNegotiationItem
                    key={d.id}
                    user={d.created_by.profiles[0]}
                    isMe={
                      currentUserAttorneyId ===
                      d.created_by.profiles[0].attorney_id.id
                    }
                    status={d.activity_entry_status.name}
                    date={dayjs(d.created_ts || '').format('DD MMM YYYY')}
                    avatar={getImagePath({
                      id: d.created_by.profiles[0].attorney_id?.user_id?.avatar
                        ?.id,
                      size: IMAGE_SIZES.Small,
                    })}
                    fee={d.fee}
                    comment={d.message}
                    isVetted={
                      d.created_by.profiles?.[0]?.attorney_id?.is_vetted
                    }
                    isQualified={isQualifiedMembership(
                      d.created_by.profiles?.[0]?.attorney_id?.membership_type
                        ?.id
                    )}
                  />
                ))
              : null}
          </Box>
        </Grid>
      )}

      {dataRoomModalOpen.open && (
        <RequestDataRoomModal
          open={dataRoomModalOpen.open}
          onClose={() => setDataRoomOpenModal({ open: false, jCase: null })}
          onSubmit={() => {
            setDataRoomOpenModal({ open: false, jCase: null })
            if (!hasDownloadAccess) {
              setResponseModal({
                type: 'Dataroom',
                open: true,
              })
            }
          }}
          jCase={dataRoomModalOpen.jCase}
          hasDownloadAccess={hasDownloadAccess}
        />
      )}
      {responseModal.open && (
        <ResponseModal
          open={responseModal.open}
          type={responseModal.type}
          onClose={onCloseResponseModal}
        />
      )}
    </Grid>
  )
}

export default CaseBody

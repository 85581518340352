import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import InviteAttorneyModal from '@/components/Modals/InviteAttorneyModal/InviteAttorneyModal'
import { useSizes } from '@/hooks/useSizes'
import useAttorneyData from '@/hooks/useIsAttorneyVetted'
import { NonVettedModal } from '@/components'
import { useStripe } from '@/hooks/useStripe'
import { useGetMyRoleAndOrg } from '@/hooks/useGetMyRoleAndOrg'
import ActiveMembersTab from './ActiveMembers/ActiveMembersTab'
import InvitationsSentTab from './InvitationsSent/InvitationsSentTab'
import { Container, Title, Header } from '../components'

const Members = () => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState(0)
  const { isLessMd } = useSizes()
  const [nonVettedModal, setShowNonVettedModal] = useState(false)
  const { applyForMembership } = useStripe()
  const { getIsAttorneyVetted } = useAttorneyData()
  const isVetted = getIsAttorneyVetted()

  const { isAdmin, isCaseManager } = useGetMyRoleAndOrg()

  const a11yProps = (index: number) => ({
    id: `member-tab-${index}`,
    'aria-controls': `member-tabpanel-${index}`,
  })

  const handleAddNewMembers = async () => {
    if (!isVetted && !isAdmin && !isCaseManager) {
      setShowNonVettedModal(true)
      return
    }
    setOpenModal(true)
  }

  return (
    <>
      <Container>
        <Header sx={{ ...(isLessMd && { padding: 2 }) }}>
          <Title variant="h3" color="primary.main">
            Members
          </Title>

          <Button
            fullWidth={isLessMd}
            variant="contained"
            size={isLessMd ? 'xlarge' : 'large'}
            onClick={handleAddNewMembers}
          >
            Add New Members
          </Button>
        </Header>
        <Paper
          sx={{
            mt: 3,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              p: theme => theme.spacing(2.5, 2.5, 0, 2.5),
            }}
          >
            <Tabs
              value={activeTab}
              onChange={(_event: React.SyntheticEvent, newValue: number) => {
                setActiveTab(newValue)
              }}
              aria-label="members tabs"
            >
              <Tab label="ACTIVE MEMBERS" {...a11yProps(0)} />
              <Tab label="INVITATIONS SENT" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <Box>
            {activeTab === 0 && <ActiveMembersTab />}
            {activeTab === 1 && <InvitationsSentTab />}
          </Box>
        </Paper>
      </Container>
      {nonVettedModal && (
        <NonVettedModal
          open={nonVettedModal}
          onClose={() => setShowNonVettedModal(false)}
          AcceptButtonProps={{
            onClick: () => {
              applyForMembership()
              setShowNonVettedModal(false)
            },
          }}
        />
      )}
      {openModal && (
        <InviteAttorneyModal
          open={openModal}
          onSubmit={() => setOpenModal(false)}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  )
}

export default Members

import { useState, ReactNode } from 'react'
import Typography from '@mui/material/Typography'
import { ModalProposalType, ModalTypes } from '@/types'
import JUser from '@/components/atoms/JUser'
import { TPaymentStatus } from '@/pages/MyReferralsV2/NegotiationItem/types'
import { CASE_PROPOSAL_TYPES } from '@/constants'
import usePricesAndCredits from '@/hooks/usePricesAndCredits'
import useTheme from '@mui/material/styles/useTheme'
import AcceptProposalAlert from './components/AcceptProposalAlert'
import { ProposalType } from '../../CaseInformation'
import AcceptProposalPrice from './components/AcceptProposalPrice'

export interface IUseMatchFeesModalProps {
  type: ModalProposalType
  caseTitle: string
  fee: number
  avatar?: string | null
  firms?: string[]
  fullname: string
  isCaseOwner?: boolean
  isVetted?: boolean
  isQualified?: boolean
  caseId?: string
  agreement?: ReactNode | boolean | null
  paymentStatus: TPaymentStatus
  isInvited?: boolean
  isPrivate?: boolean
  noPaymentRequired?: boolean
  onBehalfOfUserId?: string
}

export const useMatchFeesModal = ({
  type,
  caseTitle,
  fee,
  fullname,
  firms,
  avatar,
  isVetted,
  isQualified,
  caseId,
  agreement,
  paymentStatus,
  isCaseOwner,
  isInvited,
  isPrivate,
  noPaymentRequired,
  onBehalfOfUserId,
}: IUseMatchFeesModalProps) => {
  const theme = useTheme()
  const [openModal, setOpenModal] = useState<ModalTypes>(null)

  const isTransactionModal = openModal === 'Accept'

  const { pricesData, creditsData, loading } = usePricesAndCredits({
    caseId,
    isTransaction: isTransactionModal,
    onBehalfOfUserId,
  })

  const getRestProps = () => {
    const isProposal = type === 'Proposal'
    switch (openModal) {
      case 'Accept': {
        let title = 'Accept Proposal'
        if (agreement && !paymentStatus.currentUserPaid) {
          title = 'Accept Agreement'
        }
        const description = (
          <>
            <JUser
              avatar={avatar}
              mainText={fullname}
              secondaryText={firms}
              isVetted={isVetted}
              isQualified={isQualified}
            />
            <Typography sx={{ margin: '20px 0 8px 76px' }}>
              <Typography component="span" variant="subtitle1" display="block">
                {caseTitle}
              </Typography>
              <Typography component="span" display="block">
                <strong>
                  <Typography component="span" fontSize={20}>
                    {fee}
                  </Typography>
                  %
                </strong>{' '}
                <Typography
                  component="span"
                  sx={{
                    textTransform: 'uppercase',
                    fontSize: 10,
                    color: theme.palette.text.disabled,
                  }}
                >
                  Referring Attorney Fees
                </Typography>
              </Typography>{' '}
            </Typography>
            {!isCaseOwner &&
              creditsData?.organization?.credits !== 'unlimited' &&
              !noPaymentRequired && (
                <AcceptProposalAlert
                  pricesData={pricesData}
                  creditsData={creditsData}
                  isLoading={loading}
                  isInvited={isInvited}
                  isPrivate={isPrivate}
                  sx={{
                    mt: 4,
                  }}
                />
              )}
          </>
        )
        const buttonProps = {
          children: (
            <AcceptProposalPrice
              pricesData={pricesData}
              creditsData={creditsData}
              agreement={agreement}
              paymentStatus={paymentStatus}
              isCaseOwner={isCaseOwner}
              isLoading={loading}
              isInvited={isInvited}
              noPaymentRequired={noPaymentRequired}
            />
          ),
        }
        return { title, description, buttonProps }
      }
      case 'Reject': {
        const description = (
          <Typography variant="body1">
            Are you sure you want to reject this referral?
          </Typography>
        )
        const title = `Reject ${isProposal ? 'Proposal' : 'Referral'}`
        const buttonProps = {
          children: `Yes, Reject the ${isProposal ? 'Proposal' : 'Referral'}`,
        }
        return { title, description, buttonProps }
      }
      case 'Counteroffer':
        return {
          title: 'Send Counteroffer',
          description: (
            <Typography mb={4}>
              You can update the proposed percentage below to send a
              counteroffer.
            </Typography>
          ),
          buttonProps: {
            children: 'Send Counteroffer',
          },
        }
      default:
        return { title: '', description: '', buttonProps: {} }
    }
  }

  const getConfirmationModalProps = () => {
    const open = !!openModal
    const onClose = () => {
      setOpenModal(null)
    }
    const { title, description, buttonProps } = getRestProps()
    return {
      open,
      onClose,
      title,
      description,
      AcceptButtonProps: buttonProps,
    }
  }
  const getProposalType = (): ProposalType => {
    switch (type) {
      case 'Proposal':
        return CASE_PROPOSAL_TYPES.Proposed
      case 'Referral':
        return CASE_PROPOSAL_TYPES.Referred
      case 'Invited':
        return CASE_PROPOSAL_TYPES.Invited
      default:
        return null
    }
  }

  const getCaseInformationProps = () => ({
    title: caseTitle,
    fee,
    proposalType: getProposalType(),
    profile: {
      mainText: fullname,
      secondaryText: firms,
      avatar,
      isVetted,
      isQualified,
    },
  })

  const stripePriceId =
    pricesData?.discount_stripe_price_id || pricesData?.stripe_price_id

  return {
    modalOpen: !!openModal,
    getConfirmationModalProps,
    getCaseInformationProps,
    type: openModal,
    setOpenModal,
    stripePriceId,
    discount: pricesData?.discount_stripe_price_id,
    credits: creditsData?.organization?.credits,
  }
}

import './index.css'

import { CssBaseline, ThemeProvider } from '@mui/material'
import ReactDOM from 'react-dom/client'

import { theme } from '@/styles/theme'

import App from './App'
import Bootstrap from '@/components/Bootstrap'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Bootstrap>
      <App />
    </Bootstrap>
  </ThemeProvider>
)

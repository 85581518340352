import TagManager from 'react-gtm-module'
import { EventName, AnalyticsEvents } from './AnalyticsEvents'

const tagManagerArgs = {
  gtmId: String(import.meta.env.VITE_GTM_ID),
}

export class AnalyticsService {
  private hasGtmInitialized = false

  get hasInitialized() {
    return this.hasGtmInitialized
  }

  initialize() {
    if (this.hasGtmInitialized) {
      // eslint-disable-next-line no-console
      console.warn(
        '[AnalyticsService] initialize was called more than once. This is a no-op.'
      )
      return
    }

    TagManager.initialize(tagManagerArgs)
    this.hasGtmInitialized = true
  }

  track = <T extends EventName>(
    eventName: T,
    properties: AnalyticsEvents[T]
  ) => {
    if (!this.hasGtmInitialized) {
      throw new Error('[AnalyticsService] track was called before initialize')
    }

    TagManager.dataLayer({
      dataLayer: {
        ...properties,
        event: eventName,
      },
    })
  }
}

export const analyticsService = new AnalyticsService()

import { useEffect, useMemo, useState } from 'react'
import { Catalog_Practice_Area, usePracticeAreasQuery } from '@/gql/appApi'
import { useFormContext } from 'react-hook-form'
import { AttorneyToPracticeAreas } from '../usePracticeAreaMapping'

interface ISelectPracticeAreasProps {
  fieldName: string
  index?: number
  jurisdictionSelected: string
}

const useSelectPracticeAreas = ({
  fieldName,
  index,
  jurisdictionSelected,
}: ISelectPracticeAreasProps) => {
  const { setValue, watch } = useFormContext()
  const { data, loading } = usePracticeAreasQuery({
    fetchPolicy: 'cache-first',
  })
  const [selectedPracticeAreas, setSelectedPracticeAreas] = useState<
    Catalog_Practice_Area[]
  >([])

  const externalSelectedPracticeAreas = watch('assignedAttorneys')
    ?.filter(
      (item: AttorneyToPracticeAreas | null) =>
        item?.jurisdictionId === jurisdictionSelected
    )
    ?.map(
      (practiceAreas: { attorneyToPracticeAreas: string[] | null }) =>
        practiceAreas.attorneyToPracticeAreas
    )

  // Don't include selection of the current field
  const unavailablePracticeAreas = externalSelectedPracticeAreas
    ?.filter((_: string[] | null, idx: number) => idx !== index)
    .flat()

  const handleSelection = (
    _event: React.SyntheticEvent,
    newValue: Catalog_Practice_Area[]
  ) => {
    setSelectedPracticeAreas(newValue)
    setValue(
      fieldName,
      newValue.map(practiceArea => practiceArea.id)
    )
  }

  const practiceAreas = data?.catalog_practice_area || []

  const filteredPracticeAreas = practiceAreas.filter(
    practiceArea => !unavailablePracticeAreas.includes(practiceArea.id)
  )

  const externalAssignedAttorneys: AttorneyToPracticeAreas[] | null =
    watch('assignedAttorneys')

  const jurisdictionsWithAttorneysWithNoPracticeAreas = useMemo(() => {
    if (!externalAssignedAttorneys) return new Map<string, string>()

    const jurisdictionsWithNoPracticeAreas = new Map<string, string>()

    externalAssignedAttorneys.forEach(assignedAttorney => {
      if (!assignedAttorney?.jurisdictionId) return

      const { jurisdictionId, attorneyToPracticeAreas, assignedAttorneyId } =
        assignedAttorney
      const hasPracticeAreas = (attorneyToPracticeAreas?.length ?? 0) > 0

      if (
        !hasPracticeAreas &&
        !jurisdictionsWithNoPracticeAreas.has(jurisdictionId) &&
        !!assignedAttorneyId
      ) {
        jurisdictionsWithNoPracticeAreas.set(jurisdictionId, assignedAttorneyId)
      }
    })

    return jurisdictionsWithNoPracticeAreas
  }, [externalAssignedAttorneys])

  // Map values coming from the server to the selectedPracticeAreas. This can be obtained from watch(fieldName)
  // This is necessary to display the selected values in the Autocomplete component. To be done only on the first render
  const selectedPracticeAreasIds = watch(fieldName)
  useEffect(() => {
    if (selectedPracticeAreasIds) {
      const filteredSelectedPracticeAreas = practiceAreas.filter(practiceArea =>
        selectedPracticeAreasIds.includes(practiceArea.id)
      )
      setSelectedPracticeAreas(filteredSelectedPracticeAreas)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practiceAreas])

  return {
    selectedPracticeAreas,
    handleSelection,
    filteredPracticeAreas,
    loading,
    jurisdictionsWithAttorneysWithNoPracticeAreas,
  }
}

export default useSelectPracticeAreas

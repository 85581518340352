import { CasePurchase } from '@/pages/MyReferralsV2/types'
import useLegacyReferralItems from '@/pages/MyReferralsV2/components/ReferralItems/hooks/useReferralItems'
import { useContext, useState } from 'react'
import legacyUseNegotiations from '@/pages/MyReferralsV2/NegotiationItem/hooks/useNegotiations'
import { getImagePath } from '@/utils'
import { CASE_PURCHASE_STATUS, IMAGE_SIZES } from '@/constants'
import {
  SendProposalModalProps,
  useMatchFeesModal,
} from '@/components/Modals/MatchFeesModal'
import { MODAL_TYPES } from '@/types'
import { MyReferralsContext } from '../MyReferralsContext'
import { ReferralContext } from './ReferralContext'
import { ProposalReceivedActionsProps } from './ProposalReceivedActions'
import { useProposalSubmit } from './useProposalSubmit'

interface UseNegotiationProps {
  casePurchase: CasePurchase
}

export const useNegotiation = ({ casePurchase }: UseNegotiationProps) => {
  const { refetch } = useContext(MyReferralsContext)
  const { caseEntry, isUserSeller } = useContext(ReferralContext)
  const { getNegotiationParams, getCaseParams, userId } =
    useLegacyReferralItems({
      refetch,
    })
  const caseParams = getCaseParams(caseEntry)
  const { casePurchaseWithPayment, isPrivate, isInitiatingAttorney } =
    caseParams

  const negotiationParams = getNegotiationParams(
    casePurchase,
    caseEntry,
    casePurchaseWithPayment
  )

  const {
    activityCreatedById = '',
    isCaseOwner,
    isInvited,
    paymentStatus,
    representingAttorneyId,
    fee: offeredFee,
    caseTaken,
    representingAttorneyUserId,
    type: negotiationType,
  } = negotiationParams

  const userSideAttorney = isUserSeller
    ? caseEntry.attorney
    : casePurchase.prospect_attorney

  const otherSideAttorney = isUserSeller
    ? casePurchase.prospect_attorney
    : caseEntry.attorney

  const casePurchaseDate = new Date(
    casePurchase?.case_purchase_activities?.created_ts ?? ''
  )
  const casePurchaseDateString = new Intl.DateTimeFormat(undefined, {
    day: '2-digit',
    month: 'short',
  }).format(casePurchaseDate)
  const casePurchaseTimeString = new Intl.DateTimeFormat(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).format(casePurchaseDate)
  const casePurchaseDateTimeString = `${casePurchaseDateString} - ${casePurchaseTimeString.toLowerCase()}`

  const noPaymentRequired =
    isInvited || isCaseOwner || paymentStatus.currentUserPaid

  const negotiationProps = legacyUseNegotiations({
    activityCreatedById,
    caseId: caseEntry.id,
    casePurchaseId: casePurchase.id,
    isCaseOwner,
    isInvited,
    noPaymentRequired,
    paymentStatus,
    representingAttorneyId: representingAttorneyId ?? '',
    userId,
    userProfile: otherSideAttorney,
  })

  const avatar = getImagePath({
    id: otherSideAttorney?.avatar ?? null,
    size: IMAGE_SIZES.Small,
  })

  const {
    credits,
    discount,
    getCaseInformationProps,
    getConfirmationModalProps,
    modalOpen: matchFeesModalOpen,
    setOpenModal: setMatchFeesModalOpen,
    stripePriceId,
    type: modalType,
    ...rest
  } = useMatchFeesModal({
    avatar,
    caseId: caseEntry.id,
    caseTitle: caseEntry.name,
    fee: offeredFee ?? 0,
    firms: negotiationProps.firms,
    fullname: negotiationProps.fullname,
    isCaseOwner,
    isInvited,
    isPrivate,
    isQualified: negotiationProps.isQualified,
    isVetted: negotiationProps.isVetted,
    noPaymentRequired,
    onBehalfOfUserId: representingAttorneyUserId,
    paymentStatus,
    type: negotiationType,
  })

  const [isSubmittingMatchFee, setIsSubmittingMatchFee] = useState(false)
  const { handleSubmit } = useProposalSubmit({
    setMatchFeesModalOpen,
    setIsSubmittingMatchFee,
    negotiationType,
    onSubmitAction: negotiationProps.onSubmitAction,
    setResponse: negotiationProps.setResponse,
    modalType,
    offeredFee,
    stripePriceId,
    credits,
    discount,
  })

  const matchFeesModalProps: SendProposalModalProps = {
    CaseInformationProps: getCaseInformationProps(),
    ConfirmationModalProps: getConfirmationModalProps(),
    isCaseOwner,
    isInitiatingAttorney,
    type: modalType,
    onSubmit: handleSubmit,
    ...rest,
  }

  const proposalReceivedActionsProps: ProposalReceivedActionsProps = {
    caseErrorModalOpen: negotiationProps.caseErrorModalOpen,
    caseTaken,
    isSubmittingMatchFee,
    matchFeesModalOpen,
    matchFeesModalProps,
    setMatchFeesModalOpen,
    otherSideAttorneyId: otherSideAttorney.user_id,
  }

  const didSellerTakeMostRecentAction =
    caseEntry.attorney_id ===
    casePurchase.case_purchase_activities.created_by_attorney.attorney_id

  let feeText = ''

  switch (casePurchase.case_purchase_activities.activity_entry_status) {
    case CASE_PURCHASE_STATUS.Accept:
      feeText = 'The case has been accepted'
      break
    case CASE_PURCHASE_STATUS.Counteroffer:
      if (isUserSeller) {
        feeText = didSellerTakeMostRecentAction
          ? 'Counteroffer Sent'
          : 'Counteroffer Received'
      } else {
        feeText = didSellerTakeMostRecentAction
          ? 'Counteroffer Received'
          : 'Counteroffer Sent'
      }
      break
    case CASE_PURCHASE_STATUS.Proposed:
      if (didSellerTakeMostRecentAction) {
        feeText = isUserSeller ? 'Invitation Sent' : 'Invitation Received'
      } else {
        feeText = 'Proposal Received'
      }
      break
    case CASE_PURCHASE_STATUS.Reject:
      feeText = 'Proposal was rejected'
      break
    default:
  }

  const clientSigningResponseModalTypes: string[] = [
    MODAL_TYPES.CLIENT_SIGNED_CONFIRMATION,
    MODAL_TYPES.CLIENT_SIGNED_REJECTED,
  ]

  const isClientSigningResponseModal = clientSigningResponseModalTypes.includes(
    negotiationProps.response.type ?? ''
  )

  return {
    userSideAttorney,
    otherSideAttorney,
    didSellerTakeMostRecentAction,
    feeText,
    offeredFee,
    casePurchaseDateString,
    casePurchaseDateTimeString,
    proposalReceivedActionsProps,
    negotiationProps,
    isClientSigningResponseModal,
  }
}

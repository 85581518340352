import { useCallback, useEffect, useState } from 'react'
import {
  CASE_PURCHASE_STATUS,
  MP_VISIBILITY,
  USER_ATTORNEY_ROLES,
} from '@/constants'
import {
  Attorney,
  Case_Purchase_Activity,
  useGetActivityPurchaseFromMyOrgLazyQuery,
} from '@/gql/appApi'
import { NO_PURCHASE_ACTIVITY_STATUS } from '../types'

interface IUseAdminCaseData {
  isAdminAttorney: boolean
  isCaseManager: boolean
  attorneyId: string | null
  caseId?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  caseData: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataCase: any
  getIsSameOrg: (orgId: string) => boolean
  orgId?: string
}

const useActiveAttorneyId = ({
  isAdminAttorney,
  isCaseManager,
  attorneyId,
  caseId,
  caseData,
  dataCase,
  getIsSameOrg,
  orgId,
}: IUseAdminCaseData) => {
  const [activityFromMyOrg, setActivityFromMyOrg] =
    useState<Case_Purchase_Activity | null>(null)
  const [representingAttorneyId, setRepresentingAttorneyId] = useState('')
  const [caseIsTakenByMyOrg, setCaseIsTakenByMyOrg] = useState(false)
  const [prospectProfileFromMyOrg, setProspectProfileFromMyOrg] =
    useState<Attorney | null>(null)
  const [hasProspectIdChanged, setHasProspectIdChanged] = useState(false)

  const isPriorityCase =
    caseData?.case[0]?.mp_visibility?.id === MP_VISIBILITY.Priority

  const [getActivityFromMyOrg, { loading: getActivityFromMyOrgLoading }] =
    useGetActivityPurchaseFromMyOrgLazyQuery({
      variables: {
        sort: ['created_ts'],
        filter: {
          case_purchase_id: {
            case_id: {
              id: {
                _eq: caseId,
              },
            },
            // Case purchases with Blocked, Dismissed, Reject, DeclineAndAvailable status should be excluded
            // This is because a case purchase may be blocked, but have an activity set as Proposed, which would be incorrectly interpreted as a proposal.
            // By excluding these statuses, we allow users to send a new proposal to the case and simplify the logic.
            ...(!isPriorityCase && {
              status: {
                id: {
                  _nin: [
                    CASE_PURCHASE_STATUS.Blocked,
                    CASE_PURCHASE_STATUS.Dismissed,
                    CASE_PURCHASE_STATUS.Reject,
                    CASE_PURCHASE_STATUS.DeclineAndAvailable,
                    CASE_PURCHASE_STATUS.Withdrawn,
                    CASE_PURCHASE_STATUS.Expired,
                  ],
                },
              },
            }),
          },
          _or: [
            {
              _and: [
                {
                  case_purchase_id: {
                    prospect_attorney_id: {
                      user_id: {
                        current_organization: {
                          organization_id: {
                            id: {
                              _eq: orgId,
                            },
                          },
                        },
                      },
                    },
                  },
                },
                {
                  case_purchase_id: {
                    prospect_attorney_id: {
                      user_id: {
                        role: {
                          id: {
                            _in: USER_ATTORNEY_ROLES,
                          },
                        },
                      },
                    },
                  },
                },
              ],
            },
            {
              _and: [
                {
                  case_purchase_id: {
                    case_id: {
                      attorney_id: {
                        user_id: {
                          current_organization: {
                            organization_id: {
                              id: {
                                _eq: orgId,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
                {
                  case_purchase_id: {
                    case_id: {
                      attorney_id: {
                        user_id: {
                          role: {
                            id: {
                              _in: USER_ATTORNEY_ROLES,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              ],
            },
          ],
        },
      },
      fetchPolicy: 'network-only',
    })

  const ownerId = caseData?.case[0]?.attorney_id?.id
  const ownerOrgId =
    caseData?.case[0]?.attorney_id?.user_id?.current_organization?.[0]
      ?.organization_id?.id
  const isCaseOwner = ownerId === attorneyId
  const ownerIsFromSameOrganization = getIsSameOrg(ownerOrgId)
  const isPublicCase =
    caseData?.case[0]?.mp_visibility?.id === MP_VISIBILITY.Public
  const isAdminAttorneyOrCaseManager = isAdminAttorney || isCaseManager

  /**
   * Determines the active attorney ID for managing a case based on various conditions.
   * The active attorney ID is essential for fetching case-related proposals. Without a valid `activeAttorneyId`,
   * the query for `case_purchase_activities` will fail to return relevant results, affecting the visibility of proposals.
   *
   * The ID is set based on the current user's role and the case's visibility:
   * - For admin attorneys handling priority (private) cases:
   *   - If the current user is the case owner, their ID is used.
   *   - If not, but the case owner is from the same organization, the case owner's ID is used.
   *   - Otherwise, it falls back to the case manager's ID or the attorney's ID (default behavior).
   * - For admin attorneys handling public cases and a `representingAttorneyId` is set, that ID is used.
   *    - If `representingAttorneyId` is set, it means there is an open negotiation from someone in the same organization.
   *
   * - For case managers, the only difference is we always need to check for activity in our organization
   *   since case managers are never directly involved in a negotation.
   *
   */
  const activeAttorneyId = (() => {
    if (isAdminAttorneyOrCaseManager && isPriorityCase) {
      if (isCaseOwner) {
        return attorneyId
      }
      if (ownerIsFromSameOrganization) {
        return ownerId
      }
      if (representingAttorneyId) {
        return representingAttorneyId
      }
      return attorneyId
    }
    if (isAdminAttorneyOrCaseManager && isPublicCase) {
      if (!isCaseOwner && ownerIsFromSameOrganization) {
        return ownerId
      }
      if (representingAttorneyId) {
        return representingAttorneyId
      }
    }
    return attorneyId
  })()

  const fetchActivitiesFromMyOrg = useCallback(async () => {
    const { data } = await getActivityFromMyOrg()
    if (Number(data?.case_purchase_activity?.length) > 0) {
      // Invert the data to get the latest activity first (Didn't find an option in our schema to sort ascending)
      const mostRecentCasePurchaseActivity = [
        ...(data?.case_purchase_activity ?? []),
      ]?.sort(
        (a, b) =>
          new Date(String(b.created_ts)).getTime() -
          new Date(String(a.created_ts)).getTime()
      )?.[0]
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setActivityFromMyOrg(mostRecentCasePurchaseActivity)
    }
  }, [getActivityFromMyOrg])

  const getActiveNegotiationsFromMyOrg = useCallback(async () => {
    const isStatusActive =
      activityFromMyOrg?.activity_entry_status?.id !==
        CASE_PURCHASE_STATUS.Reject || isPriorityCase
    if (activityFromMyOrg?.id) {
      const caseInfo = activityFromMyOrg?.case_purchase_id
      if (isAdminAttorneyOrCaseManager) {
        const attorneyOrgId =
          caseInfo?.case_id?.attorney_id?.user_id?.current_organization?.[0]
            ?.organization_id?.id
        if (getIsSameOrg(attorneyOrgId || '')) {
          const attorneyActivityId = caseInfo?.case_id?.attorney_id?.id
          setRepresentingAttorneyId(attorneyActivityId || '')
          return
        }
      }
      const prospectAttorneyOrgId =
        caseInfo?.prospect_attorney_id?.user_id?.current_organization?.[0]
          ?.organization_id?.id
      if (getIsSameOrg(prospectAttorneyOrgId || '') && isStatusActive) {
        if (!isAdminAttorneyOrCaseManager) {
          if (
            caseInfo?.prospect_attorney_id?.profiles?.[0]?.attorney_id?.id !==
              attorneyId &&
            isStatusActive
          ) {
            setCaseIsTakenByMyOrg(true)
            setProspectProfileFromMyOrg(caseInfo?.prospect_attorney_id || null)
          }
          return
        }
        const prospectAttorneyId =
          caseInfo?.prospect_attorney_id?.profiles?.[0]?.attorney_id?.id
        setRepresentingAttorneyId(prospectAttorneyId || '')
        setHasProspectIdChanged(true)
      }
    }
  }, [
    activityFromMyOrg,
    isAdminAttorneyOrCaseManager,
    getIsSameOrg,
    attorneyId,
    isPriorityCase,
  ])

  const hasActivity =
    dataCase?.case_purchase_activity?.length > 0 &&
    dataCase?.case_purchase_activity?.[0]?.case_purchase_id?.status?.name !==
      NO_PURCHASE_ACTIVITY_STATUS

  useEffect(() => {
    // If proposals are empty for the admin, we need to fetch the case activities
    // from all the org to see if there are any open negotiations available.
    if (dataCase && !hasActivity) {
      getActiveNegotiationsFromMyOrg()
    }
  }, [hasActivity, getActiveNegotiationsFromMyOrg, dataCase])

  useEffect(() => {
    if (caseId && orgId) {
      fetchActivitiesFromMyOrg()
    }
  }, [caseId, fetchActivitiesFromMyOrg, orgId])

  return {
    activeAttorneyId,
    hasActivity,
    ownerOrgId,
    caseIsTakenByMyOrg,
    prospectProfileFromMyOrg,
    getActivityFromMyOrgLoading,
    hasProspectIdChanged,
  }
}

export default useActiveAttorneyId

import { useEffect, useState } from 'react'
import { Tweenable, tween } from 'shifty'
import { Theme } from '@mui/material/styles/createTheme'
import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

const defaultFormatter = (num: number) => new Intl.NumberFormat().format(num)

export interface AnimatedNumberProps {
  children: number
  formatter?: typeof defaultFormatter
  startingNumber?: number
  easing?: keyof Theme['transitions']['easing']
  duration?: number
  delay?: number
}

const AnimatedNumber = ({
  children,
  startingNumber,
  formatter = defaultFormatter,
  easing = 'easeInOut',
  delay = 0,
  duration,
}: AnimatedNumberProps) => {
  const prefersReducedMotion = useMediaQuery('@media (prefers-reduced-motion)')
  const [displayedNumber, setDisplayedNumber] = useState(
    startingNumber ?? children ?? 0
  )
  const [previousNumber, setPreviousNumber] = useState(
    startingNumber ?? children
  )
  const [currentTweenable, setCurrentTweenable] = useState<
    Tweenable | undefined
  >()

  const theme = useTheme()
  const easingString = theme.transitions.easing[easing]

  useEffect(() => {
    setPreviousNumber(children)
  }, [children, setPreviousNumber])

  const tweenDuration = duration ?? theme.transitions.duration.standard

  useEffect(() => {
    if (prefersReducedMotion) {
      setDisplayedNumber(Number(children))
    } else {
      const [, bezierPointsListString] = easingString.match(/\(([^)]+)\)/) ?? []

      const bezierPointsList = bezierPointsListString
        .split(',')
        .map(str => Number(str.trim()))

      if (bezierPointsList.length !== 4) {
        throw new Error(
          `[AnimatedNumber]: "${easingString}" is not in expected cubic-bezier(number, number, number, number) format`
        )
      }

      if (children !== previousNumber) {
        if (currentTweenable) {
          currentTweenable.cancel()
        }

        const tweenable = tween({
          easing: bezierPointsList,
          duration: tweenDuration,
          render: ({ number }) => {
            setDisplayedNumber(Number(number))
          },
          from: {
            number: previousNumber,
          },
          to: { number: children },
          delay,
        })

        setCurrentTweenable(tweenable)
      }
    }

    return () => {
      if (currentTweenable) {
        currentTweenable.cancel()
      }
    }
  }, [
    currentTweenable,
    children,
    previousNumber,
    easingString,
    tweenDuration,
    delay,
    prefersReducedMotion,
  ])

  return <>{formatter(displayedNumber)}</>
}

export default AnimatedNumber

import { useState } from 'react'
import { ButtonProps } from '@mui/material/Button'

import { ModalTypes } from '@/types'
import { useSizes } from '@/hooks/useSizes'
import {
  GetActivityPurchaseDocument,
  useCaseExistsLazyQuery,
  useCreateCaseActivityPurchaseMutation,
  useCreateCreditsPaymentMutation,
} from '@/gql/appApi'
import useAttorneyId from '@/hooks/useAttorneyId'
import { CASE_PURCHASE_STATUS, IMAGE_SIZES, PAYMENT_STATUS } from '@/constants'
import { getImagePath } from '@/utils/helpers'
import useAttorneyEmail from '@/hooks/useAttorneyEmail'
import { isQualifiedMembership } from '@/utils/membership'
import { PricesType, useStripe } from '@/hooks/useStripe'
import { MembersPricesResponse } from '@/utils/api/types'
import { CaseEntry } from '../../types'
import { TPaymentStatus } from '../types'
import { getModalResponse, getPaymentMethod, getQueryParam } from './utils'

export interface IUseNegotiationsProps {
  userProfile?: CaseEntry['attorney']
  caseId: string
  casePurchaseId: string
  activityCreatedById: string
  representingAttorneyId: string
  userId?: string
  paymentStatus: TPaymentStatus
  isCaseOwner?: boolean
  isInvited?: boolean
  noPaymentRequired: boolean
}

interface IOnSubmitActionProps {
  type: ModalTypes
  fee: number
  priceType: PricesType
  stripePriceId: string | undefined
  discount?: MembersPricesResponse['discount_stripe_price_id'] | null
  credits: string | number | undefined
}

const useNegotiations = ({
  userProfile,
  caseId,
  casePurchaseId,
  activityCreatedById,
  representingAttorneyId,
  userId,
  paymentStatus,
  isCaseOwner,
  isInvited,
  noPaymentRequired,
}: IUseNegotiationsProps) => {
  const [rejectionModalIsOpen, setRejectionModalIsOpen] =
    useState<boolean>(false)
  const [expand, setExpand] = useState(false)
  const [caseErrorModalOpen, setCaseErrorModalOpen] = useState(false)
  const [response, setResponse] = useState<{ type: ModalTypes; open: boolean }>(
    {
      type: null,
      open: false,
    }
  )

  const [signedModalOpen, setSignedModalOpen] = useState<boolean>(false)
  const [createCaseActivityPurchaseMutation, { loading }] =
    useCreateCaseActivityPurchaseMutation({
      refetchQueries: [GetActivityPurchaseDocument, 'case_purchase_activity'],
    })

  const [payWithCredits, { loading: payWithCreditsLoading }] =
    useCreateCreditsPaymentMutation()

  const [caseExists, { loading: caseExistsLoading }] = useCaseExistsLazyQuery({
    variables: {
      filter: {
        id: {
          _eq: caseId,
        },
      },
    },
    fetchPolicy: 'network-only',
  })

  const { isGreaterMd } = useSizes()

  const { getAttorneyId } = useAttorneyId()
  const attorneyId = getAttorneyId()

  const { getAttorneyEmail } = useAttorneyEmail()
  const attorneyEmail = getAttorneyEmail()

  const { createCheckoutSession } = useStripe()

  const isActivityOwner = activityCreatedById === attorneyId

  const buttonSize: ButtonProps['size'] = isGreaterMd ? 'large' : 'medium'

  const fullname = `${userProfile?.first_name} ${userProfile?.last_name}`
  const firms = userProfile?.organization_name
    ? [userProfile?.organization_name]
    : []
  const avatar = getImagePath({
    id: userProfile?.avatar ?? null,
    size: IMAGE_SIZES.Small,
  })
  const isVetted = userProfile?.is_vetted || userProfile?.is_vetted || false
  const isQualified = isQualifiedMembership(userProfile?.membership_type)

  const getActivityEntryStatus = (type: ModalTypes) => {
    switch (type) {
      case 'Counteroffer':
        return CASE_PURCHASE_STATUS.Counteroffer
      case 'Accept':
        return CASE_PURCHASE_STATUS.Accept
      case 'Reject':
        return CASE_PURCHASE_STATUS.Reject
      default:
        return null
    }
  }

  const onSubmitAction = async ({
    type,
    fee,
    stripePriceId,
    credits,
    discount,
  }: IOnSubmitActionProps) => {
    /*
     Before launching the payment screen or creating a case activity,
     we need to check if the case is still available. This is a simple solution
     to detect if a case has been deleted or not. Otherwise, we would need to have
     specific errors returned on each mutation to know if the case was deleted.
    */
    const caseExistsResult = await caseExists()
    if (!caseExistsResult.data?.case?.[0]?.id) {
      setCaseErrorModalOpen(true)
      return null
    }

    const status = getActivityEntryStatus(type)
    if (status === CASE_PURCHASE_STATUS.Accept && !noPaymentRequired) {
      const hasCredits =
        credits && (credits === 'unlimited' || Number(credits) > 0)
      if (hasCredits || isCaseOwner || isInvited) {
        try {
          const creditsPaymentResult = await payWithCredits({
            variables: {
              data: {
                attorney_id: representingAttorneyId,
                case_purchase_id: casePurchaseId,
                payment_method: getPaymentMethod({
                  isCaseOwner: !!isCaseOwner,
                  isInvited: !!isInvited,
                }),
                payment_status_id: PAYMENT_STATUS.Completed,
                amount: 0,
              },
            },
          })

          if (
            creditsPaymentResult.data?.create_case_purchase_payment_item?.id
          ) {
            return getModalResponse({
              paymentStatus,
              isCaseOwner: !!isCaseOwner,
            })
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }
        return null
      }
      if (userId) {
        const queryParam = getQueryParam({
          paymentStatus,
          isCaseOwner: !!isCaseOwner,
        })

        return createCheckoutSession({
          attorney_id: representingAttorneyId || String(attorneyId),
          case_purchase_id: casePurchaseId,
          customer_email: String(attorneyEmail),
          user_id: userId || '',
          stripePriceId,
          queryParam,
          ...(discount && {
            discounts: [discount],
          }),
        })
      }
      return null
    }
    const data = {
      fee,
      activity_entry_status: getActivityEntryStatus(type),
      created_by: representingAttorneyId,
      case_purchase_id: {
        id: casePurchaseId,
      },
    }
    try {
      const createCasePurchaseActivityResult =
        await createCaseActivityPurchaseMutation({ variables: { data } })
      if (
        createCasePurchaseActivityResult.data
          ?.create_case_purchase_activity_item?.id
      ) {
        if (type === 'Accept') {
          return {
            type: isCaseOwner
              ? 'FeederAcceptFreeCompleted'
              : 'EaterAcceptFreeCompleted',
            open: true,
          }
        }
        return true
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
    return null
  }

  const handleClientRejected = () => {
    setRejectionModalIsOpen(true)
  }

  const handleClientSigned = () => {
    setSignedModalOpen(true)
  }

  const handleClientSignedModalClose = () => {
    setSignedModalOpen(false)
  }

  const handleRejectionModalClose = () => {
    setRejectionModalIsOpen(false)
  }

  const handleClientSignedSubmit = ({
    responseType,
  }: {
    responseType: ModalTypes
  }) => {
    setSignedModalOpen(false)
    setResponse({
      type: responseType,
      open: true,
    })
  }

  const handleClientRejectedSubmit = ({
    responseType,
  }: {
    responseType: ModalTypes
  }) => {
    setRejectionModalIsOpen(false)
    setResponse({
      type: responseType,
      open: true,
    })
  }

  return {
    expand,
    setExpand,
    response,
    setResponse,
    buttonSize,
    fullname,
    firms,
    isGreaterMd,
    onSubmitAction,
    loading: loading || caseExistsLoading || payWithCreditsLoading,
    isActivityOwner,
    avatar,
    isVetted,
    isQualified,
    signedModalOpen,
    caseErrorModalOpen,
    rejectionModalIsOpen,
    handleClientRejected,
    handleClientSigned,
    handleClientSignedModalClose,
    handleRejectionModalClose,
    handleClientSignedSubmit,
    handleClientRejectedSubmit,
  }
}

export default useNegotiations

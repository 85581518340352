import { useEffect } from 'react'
import { ConfirmationModal } from '@/components'
import Backdrop from '@mui/material/Backdrop'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { RoutesData } from '@/router/RoutesData'
import { noop } from 'lodash'
import { useCountdown } from 'usehooks-ts'

interface ICaseNotAvailableProps {
  open: boolean
}

const CaseNotAvailable = ({ open }: ICaseNotAvailableProps) => {
  const navigate = useNavigate()
  const goToMarketplace = () => {
    navigate(RoutesData.RMarketplace.path)
  }
  const [seconds, { startCountdown, stopCountdown }] = useCountdown({
    countStart: 5,
    countStop: 0,
    intervalMs: 1000,
  })

  useEffect(() => {
    if (!open) return
    startCountdown()

    return () => {
      stopCountdown()
    }
  }, [open, navigate])

  useEffect(() => {
    if (seconds === 0) {
      goToMarketplace()
    }
  }, [seconds])

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <ConfirmationModal
        open={open}
        title="This case is no longer available"
        onClose={noop}
        disableClose
        AcceptButtonProps={{
          children: 'Go to Marketplace',
          onClick: goToMarketplace,
        }}
      >
        <Typography variant="body2" sx={{ mt: 2 }}>
          Find new opportunities in the Marketplace
        </Typography>
        <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
          You will be automatically redirected to the Marketplace in {seconds}{' '}
          seconds
        </Typography>
      </ConfirmationModal>
    </Backdrop>
  )
}

export default CaseNotAvailable

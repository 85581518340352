import { useCallback, useEffect, useState } from 'react'
import {
  Case,
  useGetAcceptedPublicCasePurchasesQuery,
  useGetCasesQuery,
} from '@/gql/appApi'
import useAttorneyId from '@/hooks/useAttorneyId'
import {
  CASE_PURCHASE_STATUS,
  CASE_STATUS_FLOW,
  MP_VISIBILITY,
} from '@/constants'
import { useAxios } from '@/axios/config'
import setAuthToken from '@/axios/setAuthToken'
import { AxiosError } from 'axios'
import useGetMyRoleAndOrg from '@/hooks/useGetMyRoleAndOrg'
import {
  SessionKey,
  sessionStorageService,
} from '@/services/SessionStorage/SessionStorageService'

interface IUseInviteToCaseModal {
  onSubmit: () => void
  prospectAttorneyId: string
}

const useInviteToCaseModal = ({
  onSubmit,
  prospectAttorneyId,
}: IUseInviteToCaseModal) => {
  const { isCaseManager, isAdminAttorney, userId, orgId } = useGetMyRoleAndOrg()
  const [cases, setCases] = useState<Case[]>([])
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = getAttorneyId()
  const [{ loading: createCaseLoading }, createCasePurchase] = useAxios({
    url: '/casepurchase',
    method: 'POST',
  })
  const [error, setError] = useState('')

  const getFilter = useCallback(() => {
    if (isAdminAttorney) {
      // As an admin you can see all cases from your organization
      return {
        attorney_id: {
          user_id: {
            current_organization: {
              organization_id: {
                id: {
                  _eq: orgId,
                },
              },
            },
          },
        },
      }
    }
    if (isCaseManager) {
      // Only look for cases purchases in which the case manager is part of the ownership of the case
      return {
        ownerships: {
          user_id: {
            id: {
              _eq: userId,
            },
          },
        },
      }
    }
    return {
      attorney_id: {
        id: {
          _eq: attorneyId,
        },
      },
    }
  }, [isAdminAttorney, isCaseManager, orgId, userId, attorneyId])

  const { data, loading } = useGetCasesQuery({
    variables: {
      filter: {
        ...getFilter(),
        mp_visibility: {
          id: {
            _eq: MP_VISIBILITY.Public,
          },
        },
        status: {
          _neq: CASE_STATUS_FLOW.Signed,
        },
      },
    },
    fetchPolicy: 'network-only',
  })

  const { data: casePurchases, loading: casePurchasesLoading } =
    useGetAcceptedPublicCasePurchasesQuery({
      variables: {
        filter: {
          _and: [
            {
              case_id: {
                ...getFilter(),
                mp_visibility: {
                  id: {
                    _eq: MP_VISIBILITY.Public,
                  },
                },
              },
            },
            {
              status: {
                id: {
                  _eq: CASE_PURCHASE_STATUS.Accept,
                },
              },
            },
          ],
        },
      },
      fetchPolicy: 'network-only',
    })

  useEffect(() => {
    if (data && casePurchases) {
      const casePurchaseAccepted = casePurchases?.case_purchase?.map(
        cp => cp?.case_id?.id
      )
      const aux = data.case.filter(d => !casePurchaseAccepted.includes(d.id))
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setCases(aux)
    }
  }, [data, casePurchases])

  /**
   * Get the attorneyId that will be used to create the case purchase
   * @param caseOwnerAttorneyId The attorneyId of the case owner
   * @returns The attorneyId that will be used to create the case purchase
   *
   * If the user is an admin attorney and they are not the case owner, the createdByAttorneyId will be the case owner's attorneyId.
   * Otherwise, the createdByAttorneyId will be the user's attorneyId
   *
   * If the user is a case manager, the createdByAttorneyId will be the case owner's attorneyId
   *
   * If the user is an attorney, the createdByAttorneyId will be the user's attorneyId
   *
   * */
  const getCreatedByAttorneyId = useCallback(
    (caseOwnerAttorneyId: string) => {
      if (!attorneyId || !caseOwnerAttorneyId) return null
      if (isCaseManager) {
        return caseOwnerAttorneyId
      }
      if (isAdminAttorney) {
        return caseOwnerAttorneyId !== attorneyId
          ? caseOwnerAttorneyId
          : attorneyId
      }
      return attorneyId
    },
    [attorneyId, isCaseManager, isAdminAttorney]
  )

  const handleSubmitModal = async (form: {
    caseId?: string
    message?: string
  }) => {
    setError('')
    const jCase = data?.case.find(c => c.id === form.caseId)
    const createdByAttorneyId = getCreatedByAttorneyId(
      jCase?.attorney_id?.id || ''
    )
    const newData = [
      {
        status: CASE_PURCHASE_STATUS.Proposed,
        created_by: createdByAttorneyId,
        prospect_attorney_id: prospectAttorneyId,
        case_id: jCase?.id,
      },
    ]

    const token = sessionStorageService.getItem(SessionKey.TOKEN)
    setAuthToken(token)

    try {
      await createCasePurchase({ data: newData })
    } catch (err) {
      let errorMessage =
        'There was a problem submitting your request. Please try again later.'

      if (err instanceof AxiosError && err.response?.status === 409) {
        errorMessage =
          "There's already an existing negotiation for one or more specified attorneys for this case."
      }

      setError(errorMessage)
      return
    }

    onSubmit()
  }

  return {
    cases,
    handleSubmitModal,
    loading,
    createCaseLoading,
    casePurchasesLoading,
    error,
  }
}

export default useInviteToCaseModal

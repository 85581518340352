import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@/context/GlobalContext'
import { useUserOrganizacionQuery } from '@/gql/systemApi'
import { USER_ROLES } from '@/constants'
import { GlobalPermissionsType, PermissionsMatrixType } from '@/types'

export const useRestrictedUserPermissions = (
  globalEntity: keyof PermissionsMatrixType,
  permissionsType?: keyof GlobalPermissionsType
) => {
  const [isRestrictedUser, setIsRestrictedUser] = useState(false)
  const globalContext = useContext(GlobalContext)
  const permissions = globalContext?.globalPermissions

  const currentPermissionsSet = permissionsType
    ? permissions?.[permissionsType]
    : permissions?.inOrganization

  const { data: userMe } = useUserOrganizacionQuery()
  const roleId = userMe?.users_me?.role?.id
  const isOrganizationAccount =
    userMe?.users_me?.account_type?.[0]?.user_id?.account_type?.[0]
      ?.account_type_id?.name !== 'Individual'
  const isCaseManager = roleId === USER_ROLES.CaseManagerUser

  useEffect(() => {
    if (roleId) {
      setIsRestrictedUser(
        roleId.includes(USER_ROLES.RestrictedUser) ||
          !currentPermissionsSet?.[globalEntity]?.read
      )
    }
  }, [roleId, currentPermissionsSet, globalEntity])

  return {
    roleId,
    isRestrictedUser,
    isCaseManager,
    currentPermissionsSet,
    isOrganizationAccount,
  }
}

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Layout from '@/components/Layout'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import { useNavigate } from 'react-router-dom'
import useTheme from '@mui/material/styles/useTheme'
import { RoutesData } from '@/router/RoutesData'
import { useFeatureFlags } from '@/optimizely/useFeatureFlags'
import { FeatureFlag } from '@/optimizely/types'
import { MyReferralsProvider } from './MyReferralsContext'
import { TabsContainer } from './TabsContainer/TabsContainer'
import { Filters } from './Filters/Filters'

import MyReferralsV2 from '../MyReferralsV2'

const MyReferrals = () => {
  const navigate = useNavigate()
  const theme = useTheme()

  const featureFlags = useFeatureFlags()

  if (!featureFlags[FeatureFlag.MY_REFERRALS_V3]) {
    return <MyReferralsV2 />
  }

  return (
    <MyReferralsProvider>
      <Layout
        sx={{
          paddingTop: theme.spacing(4),
          width: {
            md: '100%',
          },
          flex: {
            md: 1,
          },
          overflow: 'auto',
          paddingBottom: theme.spacing(16),
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        <Container>
          <Grid container justifyContent="space-between">
            <Grid item xs={5}>
              <Typography
                variant="h3"
                sx={{ mb: 1, textAlign: 'left' }}
                id="createNewCaseTitle"
              >
                Referrals
              </Typography>
            </Grid>
            <Grid item xs={5} sx={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate(RoutesData.RCreateNewCase.path)}
                sx={{ width: 'fit-content', marginRight: 1 }}
              >
                Create New Case
              </Button>
            </Grid>
          </Grid>
          <Filters />
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              justifyContent: { xs: 'center', md: 'space-between' },
              marginBottom: 2.25,
              marginTop: 2.5,
              width: '100%',
            }}
          >
            <TabsContainer />
          </Box>
        </Container>
      </Layout>
    </MyReferralsProvider>
  )
}

export default MyReferrals

import { Controller, useFormContext } from 'react-hook-form'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { Catalog_Us_State } from '@/gql/appApi'
import useSelectJurisdiction from './useSelectJurisdiction'

interface SelectJurisdictionProps {
  fieldName: string
  validJurisdictions: Catalog_Us_State[]
}

const SelectJurisdiction = ({
  fieldName,
  validJurisdictions,
}: SelectJurisdictionProps) => {
  const { control } = useFormContext()
  const { selectedJurisdiction, handleSelection } = useSelectJurisdiction({
    fieldName,
    validJurisdictions,
  })

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{
        required: 'Please select a Jurisdiction.',
      }}
      render={({ fieldState }) => (
        <Autocomplete
          value={selectedJurisdiction}
          options={validJurisdictions}
          getOptionLabel={option => `${option.code} - ${option.name}`}
          onChange={handleSelection}
          renderInput={params => (
            <TextField
              {...params}
              label="Jurisdiction"
              placeholder="Jurisdiction"
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              sx={{ width: '100%' }}
            />
          )}
        />
      )}
    />
  )
}

export default SelectJurisdiction

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { ConfirmationModal, ResponseModal } from '@/components'
import ListRequestAccess from './ListRequestAccess'
import useAccessRequests from './hooks/useAccessRequests'

export const UsersWithDataroomAccess = ({ caseId }: { caseId: string }) => {
  const {
    modal,
    responseModal,
    acceptedRequests,
    onGiveAccess,
    onDenyAccess,
    onCloseModal,
    onSubmitModal,
    onCloseResponseModal,
    loading,
    error,
    setError,
  } = useAccessRequests({
    caseId,
  })

  if (!acceptedRequests.length) return null

  return (
    <>
      {error?.message && (
        <Alert severity="error" onClose={() => setError(null)}>
          {error.message}
        </Alert>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
          width: '100%',
        }}
      >
        <Typography variant="subtitle1">
          Users with access to Data Room
        </Typography>
        <ListRequestAccess
          requests={acceptedRequests}
          hasAccess
          onGiveAccess={onGiveAccess}
          onDenyAccess={onDenyAccess}
          loading={loading}
        />
      </Box>
      {modal.open && (
        <ConfirmationModal
          open={modal.open}
          title={String(modal.title)}
          onClose={onCloseModal}
          AcceptButtonProps={{
            children: modal.buttonText,
            onClick: onSubmitModal,
          }}
        >
          {modal.description}
        </ConfirmationModal>
      )}
      {responseModal.open && (
        <ResponseModal
          open={responseModal.open}
          title={responseModal.title}
          bodyContent={responseModal.bodyContent}
          onClose={onCloseResponseModal}
        />
      )}
    </>
  )
}

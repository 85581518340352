import { FC, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import AppBar from '@mui/material/AppBar'
import Paper from '@mui/material/Paper'

import { RoutesData, getPageData } from '@/router/RoutesData'
import { useSizes } from '@/hooks/useSizes'
import logo from '@/assets/logoTurquoise.svg'
import { useGetDelegatedUsersByCaseManagerLazyQuery } from '@/gql/systemApi'
import { getSignInRedirectionByRole } from '@/pages/SignIn/utils'
import get from 'lodash/get'
import Links from './Links'
import UserMenu from './UserMenu'

interface IApplicationBarProps {
  skipBottomBar?: boolean
}

const ApplicationBar: FC<IApplicationBarProps> = ({
  skipBottomBar,
}: IApplicationBarProps) => {
  const location = useLocation()
  const pageData = getPageData(`/${location.pathname.split('/')[1]}`) // to account for parametrized routes
  const isMobileBackBar = get(pageData, 'mobileBackNavbar', false)
  const { screenSize } = useSizes()
  const mobileLayout = screenSize !== 'desktop'
  const navigate = useNavigate()

  const showMobileBackBar = isMobileBackBar && mobileLayout

  const [getUserMeWithRoleData] = useGetDelegatedUsersByCaseManagerLazyQuery()
  const [navPath, setNavPath] = useState(RoutesData.RMarketplace.path)

  useEffect(() => {
    const setMainPage = async () => {
      const userMeRole = await getUserMeWithRoleData()
      const navLogoUrl = getSignInRedirectionByRole(
        get(userMeRole, 'data.users_me.role.id', '')
      )

      setNavPath(navLogoUrl)
    }
    setMainPage()
  }, [getUserMeWithRoleData])

  return (
    <AppBar
      position="sticky"
      sx={theme => ({
        zIndex: 1000,
        background: showMobileBackBar
          ? theme.palette.common.white
          : theme.palette.primary.main,
      })}
      id="static-navigation-bar"
      elevation={0}
    >
      <Container maxWidth="xl">
        <>
          <Toolbar
            disableGutters
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              height: '3.5rem',
              gap: 4,
            }}
          >
            {showMobileBackBar ? (
              <>
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBackOutlinedIcon />
                </IconButton>

                <Typography
                  variant="subtitle1"
                  color="text.primary"
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  {pageData?.title}
                </Typography>
              </>
            ) : (
              <>
                <Link to={navPath}>
                  <img alt="logo" src={logo} width={87} />
                </Link>

                {!mobileLayout && <Links />}
                <Box display="flex" gap={2}>
                  <UserMenu />
                </Box>
              </>
            )}
          </Toolbar>

          {mobileLayout && !skipBottomBar && (
            <Paper
              sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 10000,
              }}
            >
              <Links />
            </Paper>
          )}
        </>
      </Container>
    </AppBar>
  )
}

export default ApplicationBar

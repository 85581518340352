import { FC } from 'react'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { RoutesData } from '@/router/RoutesData'
import { useSizes } from '@/hooks/useSizes'
import AlgoliaMarketplaceFilters from './AlgoliaMarketplaceFilters'
import AlgoliaActiveFilters from './AlgoliaActiveFilters'
import AlgoliaSearchBox from './AlgoliaSearchBox'

const AlgoliaMarketplaceHeader: FC = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const { isLessMd } = useSizes()

  const handleCreateCaseClick = () => {
    navigate(RoutesData.RCreateNewCase.path)
  }

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 0, md: 1.5 } }}
    >
      <Box
        sx={{
          paddingTop: theme.spacing(2.5),
          paddingInline: {
            xs: theme.spacing(2),
            sm: 0,
          },
          display: 'flex',
          flexDirection: isLessMd ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isLessMd ? 'flex-start' : 'center',
          mb: { xs: 2, md: 4 },
          gap: { xs: 2, md: 4 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: 0, md: 1 },
          }}
        >
          <Typography variant={isLessMd ? 'subtitle1' : 'h5'}>
            Referrals Marketplace
          </Typography>
          <Typography
            variant={isLessMd ? 'body2' : 'body1'}
            color="text.secondary"
          >
            You are not charged at the time of matching. You are charged a
            one-time match fee only if you sign the client. You have 30 days to
            review the case details and indicate you did sign or did not sign
            the client before being charged.
          </Typography>
        </Box>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={handleCreateCaseClick}
          sx={{
            bgcolor: theme.palette.secondary.main,
            mt: isLessMd ? 2 : 0,
            width: isLessMd ? '100%' : 'auto',
            flexShrink: 0,
          }}
        >
          Create New Case
        </Button>
      </Box>
      <Box sx={{ display: 'flex', gap: 2, px: { xs: 2, sm: 0 } }}>
        <AlgoliaSearchBox />
        <AlgoliaMarketplaceFilters />
      </Box>
      <Box sx={{ my: { xs: 2, md: 1 }, px: 1 }}>
        <AlgoliaActiveFilters />
      </Box>
    </Box>
  )
}

export default AlgoliaMarketplaceHeader

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { useSizes } from '@/hooks/useSizes'
import CaseMessageButton from '@/components/GetStreamChat/CaseMessageButton'
import { IJUser } from '@/components/atoms/JUser/types'
import AttorneyCase from '../Sidebar/components/AttorneyCase'
import SidebarFee from '../Sidebar/components/SidebarFee'

export interface IWaterfallProfile {
  id: string
  firstName: string
  lastName: string
  avatar: string
  isVetted: boolean
  isQualified: boolean
  organization: string
}

export interface OwnerCaseButtonsProps {
  setModalInviteToCaseOpen: (open: boolean) => void
  hasAdminAccess?: boolean
  hasCaseManagerAccess?: boolean
  iamProspectInCase?: boolean
  profile?: IJUser
  waterfallProfile?: IWaterfallProfile | undefined
  isPrivateCase: boolean
  fee: number
}

const OwnerCaseButtons = ({
  setModalInviteToCaseOpen,
  hasAdminAccess,
  hasCaseManagerAccess,
  iamProspectInCase = false,
  profile,
  waterfallProfile,
  isPrivateCase,
  fee,
}: OwnerCaseButtonsProps) => {
  const { isLessMd } = useSizes()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {waterfallProfile !== undefined && isPrivateCase ? (
        !isLessMd && (
          <>
            <AttorneyCase
              caseOwner={waterfallProfile}
              showSendMessage={!iamProspectInCase}
              relationshipType="counterpart"
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                py: 1,
              }}
            >
              <SidebarFee fee={fee} />
            </Box>
          </>
        )
      ) : (
        <>
          {!isLessMd && (
            <SidebarFee
              fee={fee}
              sx={{
                py: 1,
              }}
            />
          )}
          <Button
            fullWidth
            size="large"
            variant="contained"
            onClick={() => setModalInviteToCaseOpen(true)}
          >
            Invite Attorneys to Case
          </Button>
          <CaseMessageButton />
        </>
      )}
      {(hasAdminAccess || hasCaseManagerAccess) && !isLessMd && (
        <AttorneyCase
          caseOwner={profile}
          relationshipType={hasAdminAccess ? 'attorney' : 'on-behalf-of'}
        />
      )}
    </Box>
  )
}

export default OwnerCaseButtons

import React from 'react'
import { useCaseContext } from '../../hooks/useCaseContext'
import DataroomHeader from './DataroomHeader'
import DataroomAccessRequest from './DataroomAccessRequest'
import DataroomFileList from './DataroomFileList'
import DataroomFileUpload from './DataroomFileUpload'
import DataroomUserAccess from './DataroomUserAccess'
import DataroomSkeleton from './skeleton'

const Dataroom: React.FC = () => {
  const { caseData, loading } = useCaseContext()
  const dataroom = caseData?.dataroom

  if (loading) return <DataroomSkeleton />

  if (!dataroom) return null

  const { access, files } = dataroom

  if (!access) return null
  if (access === 'view' && files.length === 0) return null

  return (
    <div className="dataroom">
      <DataroomHeader />
      <DataroomAccessRequest />
      <DataroomFileList />
      <DataroomFileUpload />
      <DataroomUserAccess />
    </div>
  )
}

export default Dataroom

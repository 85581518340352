import { MenuItemType } from '@/components/Menu'
import { PATHS_WITH_SECTIONS, getSubsectionLink } from '@/router/RoutesUtils'

import {
  AccountBalanceOutlined,
  BusinessOutlined,
  PersonOutlineOutlined,
  HowToRegOutlined,
  SchoolOutlined,
  // WorkspacePremiumOutlined,
} from '@mui/icons-material'

export const Subtitle = ({ page }: { page: string }) => {
  switch (page) {
    case 'Personal Information': {
      return 'Introduce yourself to other members. Showcase your strengths to establish trust and credibility.'
    }
    case 'Professional License': {
      return 'You have the option to add, remove, or edit your bar license information as needed.'
    }
    case 'Law Firm': {
      return 'What firm do you work for?'
    }
    case 'Practice Areas': {
      return 'Select the practice areas you specialize in.'
    }
    case 'Education': {
      return 'Please add your Law School and Undergraduate details at a minimum.'
    }
    // case 'Awards': {
    //   return 'Add any awards you have been selected for.'
    // }
    default: {
      return ''
    }
  }
}

export const menuItems: MenuItemType[] = [
  {
    sectionTitle: '',
    items: [
      {
        label: 'Personal Information',
        icon: <PersonOutlineOutlined sx={{ fontSize: '1rem' }} />,
      },
      {
        label: 'Professional License',
        icon: <HowToRegOutlined sx={{ fontSize: '1rem' }} />,
      },
      {
        label: 'Law Firm',
        icon: <BusinessOutlined sx={{ fontSize: '1rem' }} />,
      },
      {
        label: 'Practice Areas',
        icon: <AccountBalanceOutlined sx={{ fontSize: '1rem' }} />,
      },
      {
        label: 'Education',
        icon: <SchoolOutlined sx={{ fontSize: '1rem' }} />,
      },
      // {
      //   label: 'Awards',
      //   icon: <WorkspacePremiumOutlined sx={{ fontSize: '1rem' }} />,
      // },
    ],
  },
]

export const getEditPublicProfileFirstLabel = () => menuItems[0].items[0].label
export const getEditPublicProfileFirstLink = () =>
  getSubsectionLink(
    PATHS_WITH_SECTIONS.EDIT_PUBLIC_PROFILE,
    getEditPublicProfileFirstLabel()
  )

import { useState, useEffect } from 'react'

import {
  GetActivityPurchaseQuery,
  useGetActivityPurchaseLazyQuery,
} from '@/gql/appApi'
import { orderPurchaseActivityByTimeStamp } from '../utils'

export interface UseProposalsProps {
  filter: any
  searchText?: string
  showAll?: boolean
  isLoadingOrganzation?: boolean
}

const useProposals = ({
  filter,
  searchText = '',
  showAll = false,
  isLoadingOrganzation,
}: UseProposalsProps) => {
  const [getActivityPurchase, { data, loading, error }] =
    useGetActivityPurchaseLazyQuery({
      variables: { filter },
      fetchPolicy: 'network-only',
    })
  const [actualData, setActualData] = useState<unknown>(null)
  const [filteredData, setFilteredData] =
    useState<GetActivityPurchaseQuery | null>(null)

  useEffect(() => {
    if (!isLoadingOrganzation) {
      getActivityPurchase()
    }
  }, [isLoadingOrganzation, getActivityPurchase])

  useEffect(() => {
    if (data) {
      const purchaseActivityGroup = orderPurchaseActivityByTimeStamp(
        data,
        showAll
      )
      setActualData(purchaseActivityGroup)
    }
  }, [data, showAll])

  useEffect(() => {
    if (searchText === '') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setFilteredData(actualData)
    } else {
      const newDataFiltered = {}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      Object.keys(actualData).forEach(key => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const proposals = actualData?.[key]
        const newCase = proposals?.[0]?.case_purchase_id?.case_id
        if (newCase?.name.toLowerCase().includes(searchText.toLowerCase())) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          newDataFiltered[key] = proposals
        }
      })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setFilteredData(newDataFiltered)
    }
  }, [searchText, actualData])

  return {
    data: filteredData,
    loading,
    error,
  }
}

export default useProposals

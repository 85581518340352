import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { Catalog_Case_Purchase_Decline_Reason } from '@/gql/systemApi'
import JSnackBar from '@/components/atoms/JSnackBar'

import { ModalTypes } from '@/types'
import ConfirmationModal from '../ConfirmationModal'
import { useAgreementRejectionConfirmationModal } from './useAgreementRejectionConfirmationModal'

interface AgreementRejectionConfirmationModalProps {
  onClose: () => void
  open: boolean
  onSubmitResponse: ({ responseType }: { responseType: ModalTypes }) => void
  casePurchaseId: string
  onBehalfOfAttorneyId: string
  fee: number
}

const AgreementRejectionConfirmationModal = ({
  onClose,
  open,
  onSubmitResponse,
  casePurchaseId,
  onBehalfOfAttorneyId,
  fee,
}: AgreementRejectionConfirmationModalProps) => {
  const {
    alert,
    selectedRejectionReason,
    casePurchaseDeclineReasons,
    handleSnackBarClose,
    loading,
    isSubmitting,
    handleAcceptClick,
    handleClose,
    handleReasonChange,
  } = useAgreementRejectionConfirmationModal({
    onClose,
    onSubmitResponse,
    casePurchaseId,
    onBehalfOfAttorneyId,
    fee,
  })

  return (
    <ConfirmationModal
      sx={{
        '.MuiTypography-root': {
          my: 1,
        },
      }}
      title="Why Wasn't The Client Signed?"
      open={open}
      AcceptButtonProps={{
        children: isSubmitting ? 'Sending...' : 'Confirm',
        onClick: handleAcceptClick,
        disabled: !selectedRejectionReason || isSubmitting,
      }}
      onClose={handleClose}
    >
      <>
        <Typography>
          Please select the reason why the client didn&apos;t sign.{' '}
          <strong>You will not be charged a match fee for this case.</strong>
        </Typography>
        <Typography>
          We will also let the referring attorney and client know that
          you&apos;re not taking the case.
        </Typography>
        <Autocomplete<Catalog_Case_Purchase_Decline_Reason, false, false, false>
          sx={{ mt: 4 }}
          value={selectedRejectionReason ?? null}
          onChange={handleReasonChange}
          options={casePurchaseDeclineReasons}
          getOptionLabel={({ name }) => name}
          renderInput={params => (
            <TextField {...params} label="Why Wasn't The Client Signed? *" />
          )}
          disabled={loading}
        />
      </>
      <JSnackBar
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        horizontal="center"
        vertical="bottom"
        handleClose={handleSnackBarClose}
      />
    </ConfirmationModal>
  )
}

export default AgreementRejectionConfirmationModal

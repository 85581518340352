import { ReactNode, useRef, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'

const DragAndDropArea = styled('div')`
  width: 100%;
  border: 1px dashed #e1e3e6;
`

interface FilesDragAndDropAreaProps {
  addFiles: (files: FileList) => void
  children: ReactNode
}

const FilesDragAndDropArea = ({
  children,
  addFiles,
}: FilesDragAndDropAreaProps) => {
  const drop = useRef<HTMLInputElement>(null)
  const [dragging, setDragging] = useState(false)

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()

    setDragging(false)

    const { files } = e.dataTransfer || {}

    if (files && files.length) {
      addFiles(files)
    }
  }

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.target !== drop.current) {
      setDragging(true)
    }
  }

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.target === drop.current) {
      setDragging(false)
    }
  }

  useEffect(() => {
    drop?.current?.addEventListener('dragover', handleDragOver)
    drop?.current?.addEventListener('drop', handleDrop)
    drop?.current?.addEventListener('dragenter', handleDragEnter)
    drop?.current?.addEventListener('dragleave', handleDragLeave)

    return () => {
      drop?.current?.removeEventListener('dragover', handleDragOver)
      drop?.current?.removeEventListener('drop', handleDrop)
      drop?.current?.removeEventListener('dragenter', handleDragEnter)
      drop?.current?.removeEventListener('dragleave', handleDragLeave)
    }
  }, [])

  return (
    <DragAndDropArea
      ref={drop}
      className={classNames(dragging ? 'isDragging' : '')}
    >
      {children}
    </DragAndDropArea>
  )
}

export default FilesDragAndDropArea

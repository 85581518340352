import { useContext, useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import useDataroomRequests from '@/pages/MyReferralsV2/components/DataroomRequests/hooks/useDataroomRequests'
import { ConfirmationModal, ResponseModal } from '@/components'
import useTheme from '@mui/material/styles/useTheme'
import { ReferralContext } from './ReferralContext'
import { DataRoomRequest } from './DataRoomRequest'
import { MyReferralsContext } from '../MyReferralsContext'

export const DataRoom = () => {
  const theme = useTheme()
  const { refetch } = useContext(MyReferralsContext)
  const { caseEntry } = useContext(ReferralContext)
  const {
    modal,
    onCloseModal,
    onCloseResponseModal,
    onDenyAccess,
    onGiveAccess,
    onSubmitModal,
    requests,
    responseModal,
  } = useDataroomRequests({
    dataroom: caseEntry.data_room,
  })
  const [isExpanded, setIsExpanded] = useState(true)

  if (caseEntry.data_room.length === 0) {
    return null
  }

  const handleExpandToggleClick = () => {
    setIsExpanded(!isExpanded)
  }

  const handleCloseResponseModal = async () => {
    onCloseResponseModal()
    await refetch()
  }

  return (
    <Accordion
      expanded={isExpanded}
      disableGutters
      elevation={0}
      square
      sx={{
        background: 'none',
        '&::before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        onClick={handleExpandToggleClick}
        sx={{
          flexDirection: 'row-reverse',
          px: 1,
          py: 2,
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)',
          },
          '& .MuiAccordionSummary-content': {
            my: 0.5,
          },
        }}
        expandIcon={
          <KeyboardArrowDown
            sx={{
              cursor: 'pointer',
            }}
          />
        }
      >
        <Typography variant="subtitle2" paddingLeft={1}>
          Data Room Access Requests
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List
          sx={{
            '& .MuiListItem-root:not(:last-child)': {
              borderBottom: { md: `solid 1px ${theme.palette.divider}` },
            },
            pt: 0,
          }}
        >
          {caseEntry.data_room.map((dataRoomRequest, idx) => (
            <ListItem key={dataRoomRequest.id} sx={{ px: 0, py: 1.25 }}>
              <DataRoomRequest
                accessRequest={requests[idx]}
                dataRoomRequest={dataRoomRequest}
                onDenyAccess={onDenyAccess}
                onGiveAccess={onGiveAccess}
              />
            </ListItem>
          ))}
          {modal.open && (
            <ConfirmationModal
              open={modal.open}
              title={String(modal.title)}
              onClose={onCloseModal}
              AcceptButtonProps={{
                children: modal.buttonText,
                onClick: onSubmitModal,
              }}
            >
              {modal.description}
            </ConfirmationModal>
          )}
          {responseModal.open && (
            <ResponseModal
              open={responseModal.open}
              title={responseModal.title}
              bodyContent={responseModal.bodyContent}
              onClose={handleCloseResponseModal}
            />
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}

import { MEMBERSHIP_TYPES, MembershipTypes } from '@/constants'

export const isQualifiedMembership = (
  myMembershipType: MembershipTypes | string | undefined
) => MEMBERSHIP_TYPES.Qualified === myMembershipType

export const isVettedMembership = (
  myMembershipType: MembershipTypes | string | undefined
) => MEMBERSHIP_TYPES.Vetted === myMembershipType

export const isStandardMembership = (
  myMembershipType: MembershipTypes | string | undefined
) => MEMBERSHIP_TYPES.Standard === myMembershipType

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { ProportionBar } from './ProportionBar'

interface ReferralProps {
  rangeMax: number
  value: number
  label: string
  barColor: string
}

export const Referral = ({
  rangeMax,
  value,
  label,
  barColor,
}: ReferralProps) => {
  const theme = useTheme()

  return (
    <Grid
      container
      columns={{ xs: 6, md: 2 }}
      columnSpacing={2}
      wrap="nowrap"
      alignItems="center"
    >
      <Grid item xs={2} md={1}>
        <ProportionBar
          sx={{
            backgroundColor: barColor,
          }}
          rangeMax={rangeMax}
          value={value}
        />
      </Grid>
      <Grid item xs={4} md={1}>
        <Grid container columnSpacing={1}>
          <Grid item>
            <Typography
              component="span"
              sx={{
                fontSize: theme.spacing(2.5),
                fontWeight: theme.typography.fontWeightBold,
              }}
            >
              {new Intl.NumberFormat().format(value)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="span">{label}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

import { styled } from '@mui/material/styles'

const Anchor = styled('a')(({ theme }) => ({
  color: theme.palette.common.black,
  textDecoration: 'underline',
  ...theme.typography.body1,

  '&:hover': {
    color: theme.palette.secondary.dark,
  },
}))

export default Anchor

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { Attorney_Profile } from '@/gql/appApi'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import JDropdownUser from '@/components/atoms/JDropdownUser'
import { getImagePath } from '@/utils'
import { ControllerFieldState } from 'react-hook-form'
import { IMAGE_SIZES } from '@/constants'
import useSearchAttorneys from './hooks/useSearchAttorneys'

export interface SearchAttorneysProps {
  id?: string
  onChange: (value: Attorney_Profile | null) => void
  value: Attorney_Profile | null
  attorneySelected: Attorney_Profile | null
  label: string
  placeholder?: string
  fieldState: ControllerFieldState
}

const SearchAttorneys = ({
  id,
  value = null,
  placeholder,
  onChange,
  attorneySelected = null,
  label,
  fieldState,
}: SearchAttorneysProps) => {
  const {
    attorneyProfile,
    setAttorney,
    filteredOptions,
    loading,
    filterOptions,
    inputValue,
    setInputValue,
  } = useSearchAttorneys({
    attorneyValue: value,
    attorneySelected,
  })

  return (
    <Autocomplete
      options={filteredOptions}
      loading={loading}
      value={attorneyProfile}
      getOptionLabel={(att: Attorney_Profile) =>
        `${att.first_name} ${att.last_name} (${att.id})`
      }
      filterOptions={filterOptions}
      onChange={(_, newAttorney) => {
        setAttorney(newAttorney)
        if (newAttorney) {
          setInputValue(`${newAttorney.first_name} ${newAttorney.last_name}`)
        } else {
          setInputValue('')
        }
        onChange(newAttorney)
      }}
      isOptionEqualToValue={(option, v) => option.id === v.id}
      renderOption={(props, option) => (
        <JDropdownUser
          key={option.id}
          user={{
            id: option.id,
            name: `${option.first_name} ${option.last_name}`,
            picture: getImagePath({
              id: option?.attorney_id?.user_id?.avatar?.id || null,
              size: IMAGE_SIZES.Small,
            }),
            isVetted: option?.attorney_id?.is_vetted || false,
          }}
          sx={{
            avatar: { width: 28, height: 28 },
          }}
          badgeSize="xsmall"
          {...props}
        />
      )}
      onInputChange={(_, eInputValue) => {
        setInputValue(eInputValue)
      }}
      renderInput={params => {
        const { InputProps, inputProps, ...other } = params
        return (
          <TextField
            sx={{
              width: '100%',
              backgroundColor: theme => theme.palette.common.white,
              borderRadius: '4px',
            }}
            {...other}
            size="medium"
            InputProps={{
              ...InputProps,
              inputProps: {
                ...inputProps,
                value: inputValue,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setInputValue(e.target.value)
                },
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            id={id}
            label={label}
            placeholder={placeholder}
            variant="outlined"
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
          />
        )
      }}
    />
  )
}

export default SearchAttorneys

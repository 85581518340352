import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { ReactNode, useMemo } from 'react'

import { useSizes } from '@/hooks/useSizes'

const ButtonBar = ({
  back,
  loading,
  submitTitle = 'Continue',
  submitStarIcon,
  onSubmit,
  enableFixedBar = true,
}: {
  back?: () => void
  loading?: boolean
  submitTitle?: string
  submitStarIcon?: ReactNode
  onSubmit?: () => void
  enableFixedBar?: boolean
}) => {
  const { isLessMd, isLessSm } = useSizes()
  const showFixedBar = isLessMd && enableFixedBar

  const buttons = useMemo(
    () => (
      <Box
        sx={{
          display: 'flex',
          ml: 'auto',
          gap: 2,
          ...(showFixedBar && {
            justifyContent: 'space-between',
          }),
        }}
      >
        {back && (
          <Button
            disabled={loading}
            variant="text"
            onClick={back}
            size="large"
            sx={{ flexGrow: 1 }}
          >
            Back
          </Button>
        )}
        <Button
          size="large"
          disabled={loading}
          startIcon={submitStarIcon}
          type="submit"
          variant="contained"
          sx={{ flexGrow: isLessSm ? 2 : 1 }}
          {...(onSubmit && { onClick: onSubmit })}
        >
          {submitTitle}
        </Button>
      </Box>
    ),
    [
      showFixedBar,
      back,
      loading,
      submitStarIcon,
      isLessSm,
      onSubmit,
      submitTitle,
    ]
  )

  return (
    <>
      {!showFixedBar && buttons}
      {showFixedBar && (
        <Box
          sx={{
            position: { xs: 'fixed', md: 'sticky' },
            bottom: 0,
            left: 0,
            boxShadow: '0 -10px 10px -8px rgba(0, 0, 0, 0.2)',
            backgroundColor: 'background.page',
            zIndex: 1,
            padding: 1.5,
            width: '100%',
          }}
        >
          {buttons}
        </Box>
      )}
    </>
  )
}

export default ButtonBar

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import JTextField from '@/components/atoms/JTextField'
import { useSizes } from '@/hooks/useSizes'
import useRequestAccess from './DataroomNonOwner/hooks/useRequestAccess'
import { DataroomNonOwnerStatus } from './DataroomNonOwner/types'

type TRequestAccessProps = {
  caseId: string
  userId?: string
  handleStatusChange: (newStatus: DataroomNonOwnerStatus) => void
  accessDenied?: boolean
}

const RequestAccess = ({
  caseId,
  userId,
  handleStatusChange,
  accessDenied,
}: TRequestAccessProps) => {
  const { isLessMd } = useSizes()
  const {
    control,
    handleSubmit,
    handleRequestAccess,
    error,
    setError,
    loading,
  } = useRequestAccess({
    caseId,
    userId,
    onSuccess: () => handleStatusChange('PENDING'),
  })

  return (
    <form onSubmit={handleSubmit(handleRequestAccess)}>
      <Typography
        variant="body2"
        sx={{
          mb: 3,
        }}
      >
        I understand that the rules of professional conduct apply to my request
        for Data Room access, as well as to any conduct I subsequently take in
        connection with that access. As such, I agree to keep confidential any
        confidential information that is provided to me pursuant to my request.
      </Typography>
      {accessDenied && (
        <Alert
          severity="warning"
          variant="outlined"
          sx={{
            alignItems: 'flex-start',
            mb: 3,
          }}
        >
          <Typography variant="body2">
            Your previous request has been denied. You can request access again,
            but we recommend sending a message to your counterpart before doing
            so.
          </Typography>
        </Alert>
      )}
      <JTextField
        multiline
        name="requestMessage"
        control={control}
        minRows={3}
        placeholder="Message (optional)"
        InputProps={{ inputProps: { maxLength: 1000 } }}
      />
      <Button
        size="large"
        variant="outlined"
        type="submit"
        fullWidth={isLessMd}
        disabled={loading}
        sx={{
          mt: 2,
        }}
      >
        Request Access
      </Button>
      {error && (
        <Alert severity="error" onClose={() => setError('')}>
          {error}
        </Alert>
      )}
    </form>
  )
}

export default RequestAccess

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import LogoBar from '@/pages/CreateAccount/shared/LogoBar'

import { useSizes } from '@/hooks/useSizes'
import { TCommunicationPreferencesFormValues } from '@/components/CommunicationPreferences/types'
import CommunicationPreferences from '@/components/CommunicationPreferences'
import useCommunicationPreferences from '@/components/CommunicationPreferences/hooks/useCommunicationPreferences'

type ProfileCommunicationPreferencesProps = {
  back: () => void
  initialCommPreferences: TCommunicationPreferencesFormValues
  onSubmit: (commPreferences: TCommunicationPreferencesFormValues) => void
}

const ProfileCommunicationPreferences = ({
  back,
  initialCommPreferences,
  onSubmit,
}: ProfileCommunicationPreferencesProps) => {
  const { isLessMd } = useSizes()

  const {
    control,
    handleSubmit,
    isNotificationsEnabled,
    casesToNotifyOptions,
    notificationMethodOptions,
    loading,
  } = useCommunicationPreferences(initialCommPreferences)

  return (
    <Box
      sx={theme => ({
        paddingTop: { xs: 3.5, md: 0 },
        pb: { md: 5 },
        height: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        [theme.breakpoints.down('md')]: {
          paddingBottom: theme.spacing(6),
        },
      })}
    >
      <LogoBar />
      <Typography
        sx={{
          textAlign: 'left',
          mb: 2,
          fontSize: { xs: '20px', md: '24px' },
        }}
        variant={isLessMd ? 'h6' : 'h5'}
      >
        Notification Preferences
      </Typography>
      <Typography
        sx={{ textAlign: 'left', mb: 4.5 }}
        variant="body1"
        color="text.secondary"
      >
        Get notified whenever a new case is posted on the Marketplace so you
        don&apos;t miss opportunities. Select which cases and how you want to be
        notified.
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CommunicationPreferences
          control={control}
          casesToNotifyOptions={casesToNotifyOptions}
          notificationMethodOptions={notificationMethodOptions}
          loading={loading}
          isNotificationsEnabled={isNotificationsEnabled}
          fitTextField
        />
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            mt: 3,
            justifyContent: 'flex-end',
            ...(isLessMd && {
              justifyContent: 'space-between',
            }),
          }}
        >
          <Button
            size="large"
            fullWidth
            onClick={back}
            sx={{
              width: {
                xs: '35%',
                md: '50%',
              },
              maxWidth: 290,
            }}
          >
            Back
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            sx={{
              width: {
                xs: '65%',
                md: '50%',
              },
              maxWidth: 290,
            }}
            disabled={loading}
          >
            Finish
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default ProfileCommunicationPreferences

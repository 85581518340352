import axios from 'axios'
import { makeUseAxios } from 'axios-hooks'

const API_URL = import.meta.env.VITE_API_URL
export const API_URL_ROOT = API_URL.replace('/graphql', '')

const request = axios.create({
  baseURL: API_URL_ROOT,
})

export const useAxios = makeUseAxios({
  axios: request,
  defaultOptions: { manual: true },
})

export default request

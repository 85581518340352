import React from 'react'
import { Box, Button } from '@mui/material'
import { SidebarHeader } from '@/pages/Case/CaseV2/types'
import { MessageButton } from './MessageButton'

export interface ActionButtonsProps {
  actions: SidebarHeader['actions']
  messageChannels: SidebarHeader['message_channels']
}

// Component that renders action buttons in the case sidebar header
export const ActionButtons: React.FC<ActionButtonsProps> = ({
  actions,
  messageChannels,
}) => {
  // Don't render anything if no actions are available and no message channels exist
  if (!actions && !messageChannels.length) return null

  // Placeholder handlers for button clicks
  const onInvite = () => {
    console.log('clicked invite attorneys')
  }

  const onSendProposal = () => {
    console.log('clicked send proposal')
  }

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {/* Render "Send Proposal" button if user has permission */}
      {actions?.propose_case && (
        <Button
          variant="contained"
          fullWidth
          color="primary"
          size="large"
          onClick={onSendProposal}
        >
          Send Proposal
        </Button>
      )}

      {/* Render "Invite Attorneys" button if user has permission */}
      {actions?.invite_users && (
        <Button variant="outlined" fullWidth size="large" onClick={onInvite}>
          Invite Attorneys to Case
        </Button>
      )}

      {/* Messaging button component that handles all message-related actions */}
      <MessageButton
        messageChannels={messageChannels}
        sendMessageAction={actions?.send_message}
      />
    </Box>
  )
}

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import JTextField from '@/components/atoms/JTextField'
import JAutocomplete from '@/components/atoms/JAutocomplete'
import Button from '@mui/material/Button'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { useSizes } from '@/hooks/useSizes'
import LogoBar from '@/pages/CreateAccount/shared/LogoBar'
import { TLawFirmPublicInfo } from '../types'
import useOrganizationLawFirmInfo from './hooks/useOrganizationLawFirmInfo'

type TOrganizationLawFirmInformationProps = {
  back: () => void
  lawFirmPublicInfoValues: TLawFirmPublicInfo
  onSubmit: (orgData: TLawFirmPublicInfo) => void
}

const OrganizationLawFirmInformation = ({
  back,
  lawFirmPublicInfoValues,
  onSubmit,
}: TOrganizationLawFirmInformationProps) => {
  const { isLessMd } = useSizes()
  const {
    control,
    handleSubmit,
    states,
    caseManagementSoftware,
    companySize,
    loading,
  } = useOrganizationLawFirmInfo({
    lawFirmPublicInfoValues,
  })

  return (
    <Box
      sx={theme => ({
        marginTop: {
          xs: 0,
        },
        maxWidth: {
          md: theme.spacing(74),
        },
        height: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
      })}
    >
      <LogoBar />
      <Box>
        <Typography
          sx={{
            textAlign: 'left',
            marginBottom: 5,
          }}
          variant={isLessMd ? 'h6' : 'h5'}
        >
          Law Firm Public Information
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          rowSpacing={1.25}
          columnSpacing={2.5}
          sx={{
            pb: 2,
          }}
        >
          <Grid item xs={12}>
            <JTextField<TLawFirmPublicInfo>
              name="name"
              control={control}
              label="Law Firm Name"
              type="text"
              InputProps={{ inputProps: { maxLength: 40 } }}
              rules={{
                required: "Please provide the Law Firm's Name.",
                validate: value =>
                  (value as string)?.length > 40
                    ? 'Law Firm Name must not exceed 40 characters'
                    : true,
              }}
              fitTextField
            />
          </Grid>
          <Grid item xs={12}>
            <JTextField<TLawFirmPublicInfo>
              multiline
              rows={4}
              name="description"
              control={control}
              label="Law Firm Description"
              type="text"
              InputProps={{ inputProps: { maxLength: 300 } }}
              rules={{
                required: "Please provide the Law Firm's Description.",
                validate: value =>
                  (value as string)?.length > 300
                    ? 'Law Firm Description must not exceed 300 characters'
                    : true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <JTextField<TLawFirmPublicInfo>
              name="address"
              control={control}
              label="Address"
              type="text"
              InputProps={{ inputProps: { maxLength: 40 } }}
              rules={{
                required: "Please provide the Law Firm's Address.",
                validate: value =>
                  (value as string)?.length > 40
                    ? 'Address must not exceed 40 characters'
                    : true,
              }}
              fitTextField
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <JTextField<TLawFirmPublicInfo>
              name="city"
              control={control}
              label="City"
              type="text"
              InputProps={{ inputProps: { maxLength: 40 } }}
              rules={{
                required: "Please provide the Law Firm's City.",
                validate: value =>
                  (value as string)?.length > 40
                    ? 'City must not exceed 40 characters'
                    : true,
              }}
              fitTextField
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <JAutocomplete<TLawFirmPublicInfo>
              control={control}
              name="state"
              options={states}
              label="State"
              getOptionLabel={option => `${option.code} - ${option.name}`}
              rules={{
                required: "Please select the Law Firm's State.",
              }}
              disabled={loading}
              fitTextField
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <JTextField<TLawFirmPublicInfo>
              name="zipcode"
              control={control}
              label="Zip Code"
              inputMode="numeric"
              rules={{
                required: "Please provide the Law Firm's Zip Code.",
                pattern: {
                  value: /^[0-9]{5}(?:-[0-9]{4})?$/,
                  message: 'Invalid zip code',
                },
              }}
              fitTextField
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <JAutocomplete<TLawFirmPublicInfo>
              control={control}
              name="companySize"
              options={companySize}
              label="Company Size"
              getOptionLabel={option => option.name}
              rules={{
                required: 'Please select a Company Size.',
              }}
              disabled={loading}
              fitTextField
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <JAutocomplete<TLawFirmPublicInfo>
              control={control}
              name="caseManagementSoftware"
              options={caseManagementSoftware}
              label="Case Management Software"
              getOptionLabel={option => option.name}
              rules={{
                required: 'Please select a Case Management Software.',
              }}
              disabled={loading}
              fitTextField
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <JTextField<TLawFirmPublicInfo>
              name="website"
              control={control}
              label="Website"
              type="text"
              fitTextField
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: 2,
            justifyContent: 'flex-end',
            ...(isLessMd && {
              justifyContent: 'space-between',
            }),
            pb: 10,
          }}
        >
          <Button
            size="large"
            fullWidth
            onClick={back}
            sx={{
              width: {
                xs: '40%',
                md: '50%',
              },
              height: 48,
            }}
          >
            Back
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            endIcon={<ArrowForward sx={{ color: 'primary.main' }} />}
            sx={{
              width: {
                xs: '60%',
                md: '50%',
              },
              height: 48,
            }}
          >
            Continue
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default OrganizationLawFirmInformation

import { styled } from '@mui/material'
import Badge from '@mui/material/Badge'

export const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    fontFamily: 'inherit',
    fontSize: '.75rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

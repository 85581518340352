import {
  IDashboardAttorney,
  IDashboardAttorneyResponse,
  IDashboardOrganization,
  IDashboardOrganizationResponse,
} from '../types'

export const mapDashboardOrganizationResponse = ({
  metrics_organization,
  top_attorneys_organization,
  top_jurisdictions_organization,
  top_practice_areas_organization,
}: IDashboardOrganizationResponse): IDashboardOrganization => {
  const [
    {
      num_referrals: numReferrals,
      num_referrals_inbound: numReferralsInbound,
      num_referrals_inbound_marketplace: numReferralsInboundMarketplace,
      num_referrals_inbound_waterfall: numReferralsInboundWaterfall,
      num_referrals_marketplace: numReferralsMarketplace,
      num_referrals_outbound: numReferralsOutbound,
      num_referrals_outbound_marketplace: numReferralsOutboundMarketplace,
      num_referrals_outbound_waterfall: numReferralsOutboundWaterfall,
      num_referrals_waterfall: numReferralsWaterfall,
    } = {
      num_referrals: 0,
      num_referrals_inbound: 0,
      num_referrals_inbound_marketplace: 0,
      num_referrals_inbound_waterfall: 0,
      num_referrals_marketplace: 0,
      num_referrals_outbound: 0,
      num_referrals_outbound_marketplace: 0,
      num_referrals_outbound_waterfall: 0,
      num_referrals_waterfall: 0,
    },
  ] = metrics_organization

  return {
    metricsOrganization: {
      numReferrals: Number(numReferrals),
      numReferralsInbound: Number(numReferralsInbound),
      numReferralsInboundMarketplace: Number(numReferralsInboundMarketplace),
      numReferralsInboundWaterfall: Number(numReferralsInboundWaterfall),
      numReferralsMarketplace: Number(numReferralsMarketplace),
      numReferralsOutbound: Number(numReferralsOutbound),
      numReferralsOutboundMarketplace: Number(numReferralsOutboundMarketplace),
      numReferralsOutboundWaterfall: Number(numReferralsOutboundWaterfall),
      numReferralsWaterfall: Number(numReferralsWaterfall),
    },
    topAttorneysOrganization: top_attorneys_organization.map(
      ({
        attorney_id,
        attorney_name,
        num_cases,
        num_referrals_inbound,
        num_referrals_outbound,
      }) => ({
        attorneyId: attorney_id,
        attorneyName: attorney_name,
        numCases: Number(num_cases),
        numReferralsInbound: Number(num_referrals_inbound),
        numReferralsOutbound: Number(num_referrals_outbound),
      })
    ),
    topJurisdictionsOrganization: top_jurisdictions_organization.map(
      ({ jurisdiction, num_cases }) => ({
        jurisdiction,
        numCases: Number(num_cases),
      })
    ),
    topPracticeareasOrganization: top_practice_areas_organization.map(
      ({ num_cases, practice_area }) => ({
        numCases: Number(num_cases),
        practiceArea: practice_area,
      })
    ),
  }
}

export const mapDashboardAttoneyResponse = ({
  metrics_attorney,
  top_attorneys_attorney,
  top_jurisdictions_attorney,
  top_practice_areas_attorney,
}: IDashboardAttorneyResponse): IDashboardAttorney => {
  const [
    {
      num_referrals: numReferrals,
      num_referrals_inbound: numReferralsInbound,
      num_referrals_inbound_marketplace: numReferralsInboundMarketplace,
      num_referrals_inbound_waterfall: numReferralsInboundWaterfall,
      num_referrals_marketplace: numReferralsMarketplace,
      num_referrals_outbound: numReferralsOutbound,
      num_referrals_outbound_marketplace: numReferralsOutboundMarketplace,
      num_referrals_outbound_waterfall: numReferralsOutboundWaterfall,
      num_referrals_waterfall: numReferralsWaterfall,
    } = {
      num_referrals: 0,
      num_referrals_inbound: 0,
      num_referrals_inbound_marketplace: 0,
      num_referrals_inbound_waterfall: 0,
      num_referrals_marketplace: 0,
      num_referrals_outbound: 0,
      num_referrals_outbound_marketplace: 0,
      num_referrals_outbound_waterfall: 0,
      num_referrals_waterfall: 0,
    },
  ] = metrics_attorney

  return {
    metricsAttorney: {
      numReferrals: Number(numReferrals),
      numReferralsInbound: Number(numReferralsInbound),
      numReferralsInboundMarketplace: Number(numReferralsInboundMarketplace),
      numReferralsInboundWaterfall: Number(numReferralsInboundWaterfall),
      numReferralsMarketplace: Number(numReferralsMarketplace),
      numReferralsOutbound: Number(numReferralsOutbound),
      numReferralsOutboundMarketplace: Number(numReferralsOutboundMarketplace),
      numReferralsOutboundWaterfall: Number(numReferralsOutboundWaterfall),
      numReferralsWaterfall: Number(numReferralsWaterfall),
    },
    topAttorneysAttorney: top_attorneys_attorney.map(
      ({
        referral_attorney_id,
        referral_attorney_name,
        num_cases,
        num_referrals_inbound,
        num_referrals_outbound,
      }) => ({
        attorneyId: referral_attorney_id,
        attorneyName: referral_attorney_name,
        numCases: Number(num_cases),
        numReferralsInbound: Number(num_referrals_inbound),
        numReferralsOutbound: Number(num_referrals_outbound),
      })
    ),
    topJurisdictionsAttorney: top_jurisdictions_attorney.map(
      ({ jurisdiction, num_cases }) => ({
        jurisdiction,
        numCases: Number(num_cases),
      })
    ),
    topPracticeareasAttorney: top_practice_areas_attorney.map(
      ({ num_cases, practice_area }) => ({
        numCases: Number(num_cases),
        practiceArea: practice_area,
      })
    ),
  }
}

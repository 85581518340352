import { useContext } from 'react'
import { CaseContext } from '../context'
import { CaseContextType } from '../types'

export const useCaseContext = (): CaseContextType => {
  const context = useContext(CaseContext)
  if (context === undefined) {
    throw new Error('useCaseContext must be used within a CaseProvider')
  }
  return context
}

import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import JHQBadge from '@/assets/JHQBadge.svg'
import { JAvatarProps } from './types'

const JAvatar = ({
  isVetted,
  isQualified,
  badgeColor,
  badgeSize = 'small',
  sx,
  ...props
}: JAvatarProps) => {
  let badgeBoxSize = {}
  let iconDimensions = {}

  if (badgeSize === 'xsmall') {
    badgeBoxSize = { width: 16, height: 16 }
    iconDimensions = { width: 9, height: 11 }
  } else if (badgeSize === 'small') {
    badgeBoxSize = { width: 20, height: 20 }
    iconDimensions = { width: 11, height: 14 }
  } else if (badgeSize === 'medium') {
    badgeBoxSize = { width: 28, height: 28 }
    iconDimensions = { width: 14, height: 17 }
  } else if (badgeSize === 'large') {
    badgeBoxSize = { width: 36, height: 36 }
    iconDimensions = { width: 19, height: 22 }
  }

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        isVetted ? (
          <Tooltip title="Justice HQ Member">
            <Box
              sx={{
                backgroundColor: theme =>
                  badgeColor || theme.palette.background.badge,
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...badgeBoxSize,
                ...sx?.badgeContent,
              }}
            >
              <img alt="JHQ Badge" src={JHQBadge} {...iconDimensions} />
            </Box>
          </Tooltip>
        ) : null
      }
      sx={{ marginRight: 1.5, ...sx?.badge }}
    >
      <Avatar
        {...props}
        sx={{
          ...((isVetted || isQualified) && {
            border: theme =>
              `3px solid ${badgeColor || theme.palette.background.badge}`,
          }),
          ...sx?.avatar,
        }}
        slotProps={{
          img: {
            loading: 'lazy',
          },
        }}
      />
    </Badge>
  )
}

export default JAvatar

import { CaseEntry, CasePurchase } from '@/pages/MyReferralsV2/types'
import useLegacyReferralItems from '@/pages/MyReferralsV2/components/ReferralItems/hooks/useReferralItems'
import { useContext, useMemo } from 'react'
import { useCatalogUsStateQuery } from '@/gql/appApi'
import useGetMyRoleAndOrg from '@/hooks/useGetMyRoleAndOrg'
import { CASE_PURCHASE_STATUS } from '@/constants'
import useAttorneyId from '@/hooks/useAttorneyId'
import { MyReferralsContext } from '../MyReferralsContext'

const sortCasePurchasesByFee = (casePurchases: CasePurchase[]) =>
  [...casePurchases].sort(
    (a, b) => b.case_purchase_activities.fee - a.case_purchase_activities.fee
  )

export const useReferral = ({ caseEntry }: { caseEntry: CaseEntry }) => {
  const { refetch, organizationData } = useContext(MyReferralsContext)
  const { getCaseParams, getNegotiationParams } = useLegacyReferralItems({
    refetch,
  })

  const userOrganizationId =
    organizationData?.users_me?.current_organization?.[0]?.organization_id?.id

  const isUserSeller = userOrganizationId === caseEntry.attorney.organization_id

  const firstCasePurchase =
    caseEntry.case_purchase.length > 0 ? caseEntry.case_purchase[0] : undefined

  const counterpart = isUserSeller
    ? firstCasePurchase?.prospect_attorney
    : caseEntry.attorney
  const counterpartInitials = counterpart
    ? `${counterpart.first_name[0]}${counterpart.last_name[0]}`
    : ''

  const { case_purchase: casePurchases } = caseEntry

  // NOTE: Sorting logic, per requirements:
  //
  // > First, display the negotiations where the user needs to take action,
  // sorted from the highest fee to the lowest. Then, list the negotiations
  // where the user is waiting for a response from their counterpart, also
  // sorted from highest to lowest fee.
  const sortedCasePurchases = useMemo(() => {
    const casePurchasesWaitingForSeller = []
    const casePurchasesWaitingForBuyer = []

    // eslint-disable-next-line no-restricted-syntax
    for (const casePurchase of casePurchases) {
      if (CASE_PURCHASE_STATUS.Proposed === casePurchase.status) {
        casePurchasesWaitingForSeller.push(casePurchase)
      } else {
        casePurchasesWaitingForBuyer.push(casePurchase)
      }
    }

    return [
      ...sortCasePurchasesByFee(casePurchasesWaitingForSeller),
      ...sortCasePurchasesByFee(casePurchasesWaitingForBuyer),
    ]
  }, [casePurchases])

  const { getAttorneyId } = useAttorneyId()
  const attorneyId = getAttorneyId()
  const { isCaseManager, isAdminAttorney } = useGetMyRoleAndOrg()

  const caseParams = getCaseParams(caseEntry)
  const {
    casePurchaseWithPayment,
    negotiations,
    showActionsForCaseManagerWithOwnership,
  } = caseParams

  const buyerNegotiationParams = getNegotiationParams(
    negotiations[0],
    caseEntry,
    casePurchaseWithPayment
  )
  const { isCaseOwner, isInvited, paymentStatus } = buyerNegotiationParams

  /**
   * The user can accept the proposal without paying if:
   * - The user is the case owner
   * - The user is the invited attorney
   * - The user submitted a prepaid proposal (so no additional payment is required)
   */
  const noPaymentRequired =
    isInvited || isCaseOwner || paymentStatus.currentUserPaid

  const postedAtDate = new Date(caseEntry.created_ts)
  const postedAtDateString = new Intl.DateTimeFormat(undefined, {
    day: '2-digit',
    month: 'short',
  }).format(postedAtDate)
  const formattedTime = new Intl.DateTimeFormat(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).format(postedAtDate)
  const postedAtDateTimeString = `${postedAtDateString} - ${formattedTime.toLowerCase()}`

  const {
    data: statesData = { catalog_us_state: [] },
    loading: statesLoading,
  } = useCatalogUsStateQuery({
    variables: { sort: 'code' },
  })

  const statesMap = useMemo(() => {
    const lookupMap = statesData.catalog_us_state.reduce(
      (
        acc: Record<string, { code: string; name: string }>,
        { id, code, name }
      ) => {
        acc[id] = { code: String(code), name: String(name) }
        return acc
      },
      {}
    )

    return lookupMap
  }, [statesData])

  const wasBuyerInvited = negotiations.some(
    negotiation =>
      negotiation.created_by_attorney?.attorney_id !==
        negotiation.prospect_attorney_id &&
      negotiation.status === CASE_PURCHASE_STATUS.Proposed
  )

  const isSellerUsingCaseManager = Boolean(caseEntry.ownerships?.length)
  const isBuyerUsingCaseManager = Boolean(caseEntry.collaborators.length)

  const isCaseManagerWithPermission =
    isCaseManager && showActionsForCaseManagerWithOwnership
  const isCaseManagerWithoutPermission =
    isCaseManager && !showActionsForCaseManagerWithOwnership

  const isUserActingAsProxy =
    (isUserSeller && caseEntry.attorney_id !== attorneyId) ||
    (!isUserSeller && casePurchases[0]?.prospect_attorney_id !== attorneyId)

  const showUserSidePrincipal = isUserActingAsProxy || isAdminAttorney

  return {
    caseEntry,
    caseParams,
    counterpart,
    counterpartInitials,
    isBuyerUsingCaseManager,
    isCaseManagerWithPermission,
    isCaseManagerWithoutPermission,
    isSellerUsingCaseManager,
    isUserSeller,
    noPaymentRequired,
    postedAtDate,
    postedAtDateString,
    postedAtDateTimeString,
    sortedCasePurchases,
    showUserSidePrincipal,
    statesLoading,
    statesMap,
    wasBuyerInvited,
  }
}

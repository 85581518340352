import Typography from '@mui/material/Typography'
import Button, { ButtonProps } from '@mui/material/Button'
import { ReactNode } from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'

import { noop } from 'lodash'
import Header from '../ModalHeader/ModalHeader'
import Footer from '../ModalFooter/ModalFooter'
import ModalContent from '../ModalContent/ModalContent'

export interface ConfirmationModalProps extends DialogProps {
  title: string
  description?: string | ReactNode
  AcceptButtonProps?: ButtonProps
  CancelButtonProps?: ButtonProps
  disableClose?: boolean
}

const ConfirmationModal = ({
  title,
  description,
  onClose,
  AcceptButtonProps,
  CancelButtonProps,
  disableClose = false,
  children,
  ...rest
}: ConfirmationModalProps) => {
  const handleClose = disableClose ? noop : onClose

  return (
    <Dialog
      onClose={handleClose}
      scroll="paper"
      sx={theme => ({
        height: '100vh',
        [theme.breakpoints.down('md')]: {
          height: '90vh',
        },
      })}
      {...rest}
    >
      <Header
        title={title}
        onClose={handleClose}
        showCloseIcon={!disableClose}
      />
      <DialogContent>
        {typeof description === 'string' ? (
          <Typography variant="body1">{description}</Typography>
        ) : (
          description
        )}
        <ModalContent>{children}</ModalContent>
      </DialogContent>
      <Footer>
        <Button variant="contained" {...AcceptButtonProps} size="large" />
        {!disableClose && (
          <Button size="large" onClick={handleClose} {...CancelButtonProps}>
            {CancelButtonProps?.children || 'Cancel'}
          </Button>
        )}
      </Footer>
    </Dialog>
  )
}

export default ConfirmationModal

import React, {
  ReactNode,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import {
  Autocomplete,
  TextField,
  Box,
  Checkbox,
  Typography,
  useTheme,
} from '@mui/material'
import {
  useRefinementList,
  UseRefinementListProps,
  useCurrentRefinements,
} from 'react-instantsearch'
import { AlgoliaItem, AlgoliaFacetLabels } from '@/pages/Marketplace/types'
import JChip from '@/components/atoms/JChip'
import { useSizes } from '@/hooks/useSizes'

type AlgoliaFacetAttribute = keyof typeof AlgoliaFacetLabels

interface AlgoliaSelectProps extends UseRefinementListProps {
  attribute: AlgoliaFacetAttribute
  renderOption?: (option: AlgoliaItem) => ReactNode
}

const CustomListbox = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof Box>
>((props, ref) => (
  <Box {...props} ref={ref}>
    {props.children}
  </Box>
))

const AlgoliaSelect = React.memo((props: AlgoliaSelectProps) => {
  const theme = useTheme()
  const { isLessMd } = useSizes()
  const { items, refine } = useRefinementList({
    ...props,
    operator: 'or',
    limit: 1000, // Max number of items to fetch via the API
  })
  const label = AlgoliaFacetLabels[props.attribute] ?? 'Select'
  const listboxRef = useRef<HTMLUListElement>(null)
  const scrollPositionRef = useRef(0)

  const { items: currentRefinements } = useCurrentRefinements()

  const currentRefinementValues = useMemo(
    () =>
      currentRefinements
        .find(refinement => refinement.attribute === props.attribute)
        ?.refinements.map(r => r.value) || [],
    [currentRefinements, props.attribute]
  )

  const handleDelete = useCallback(
    (refinement: AlgoliaItem) => {
      refine(refinement.value)
    },
    [refine]
  )

  const saveScrollPosition = useCallback(() => {
    if (listboxRef.current) {
      scrollPositionRef.current = listboxRef.current.scrollTop
    }
  }, [])

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: AlgoliaItem[]) => {
      event.preventDefault()
      saveScrollPosition()
      const selectedValues = newValue.map(item => item.value)
      items.forEach(item => {
        if (
          selectedValues.includes(item.value) &&
          !currentRefinementValues.includes(item.value)
        ) {
          // Add the item to the list if it is selected and not already in the list
          refine(item.value)
        } else if (
          !selectedValues.includes(item.value) &&
          currentRefinementValues.includes(item.value)
        ) {
          // Remove the item from the list if it is not selected and already in the list
          refine(item.value)
        }
      })
    },
    [refine, items, currentRefinementValues, saveScrollPosition]
  )

  const renderOptionContent = useCallback(
    (option: AlgoliaItem, isSelected: boolean) => (
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Checkbox
          size="small"
          checked={isSelected}
          sx={{ padding: 0, marginRight: 1 }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'hidden',
          }}
        >
          {props.renderOption ? (
            <Box component="span">{props.renderOption(option)}</Box>
          ) : (
            <Typography
              variant="body2"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {option.label}
            </Typography>
          )}
        </Box>
        <Typography
          variant="body2"
          sx={{ whiteSpace: 'nowrap', marginLeft: 1 }}
        >
          ({option.count})
        </Typography>
      </Box>
    ),
    [props]
  )

  const memoizedOptions = useMemo(() => items, [items])
  const memoizedValue = useMemo(
    () =>
      currentRefinementValues
        .map(value => items.find(item => item.value === value))
        .filter(Boolean) as AlgoliaItem[],
    [currentRefinementValues, items]
  )

  useEffect(() => {
    if (listboxRef.current) {
      listboxRef.current.scrollTop = scrollPositionRef.current
    }
  })

  return (
    <Autocomplete<AlgoliaItem, true, true, false>
      key={props.attribute}
      multiple
      value={memoizedValue}
      onChange={handleChange}
      fullWidth
      options={memoizedOptions}
      getOptionLabel={(option: AlgoliaItem) => `${option.label}`}
      renderInput={params => <TextField {...params} label={label} />}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderOption={(optionProps, option) => (
        <li {...optionProps} key={option.label}>
          {renderOptionContent(
            option,
            currentRefinementValues.includes(option.value)
          )}
        </li>
      )}
      slotProps={{
        popper: {
          sx: {
            // Prevent the options from overlaying header, but also show in mobile drawer
            zIndex: {
              xs: theme.zIndex.modal,
              md: 1,
            },
          },
        },
      }}
      renderTags={tags =>
        isLessMd
          ? tags.map(tag => (
              <JChip
                variant="outlined"
                sx={{
                  background: theme.palette.common.white,
                  borderRadius: '9999px',
                  paddingLeft: 0.375,
                  '& .MuiChip-deleteIcon': {
                    cursor: 'pointer',
                  },
                }}
                label={tag.label}
                key={tag.value}
                onDelete={() => handleDelete(tag)}
              />
            ))
          : null
      }
      sx={{
        '& .MuiAutocomplete-tag': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
        },
      }}
      ListboxComponent={
        CustomListbox as React.ComponentType<React.HTMLAttributes<HTMLElement>>
      }
      ListboxProps={
        {
          style: { maxHeight: 250 },
          ref: listboxRef,
          onScroll: saveScrollPosition,
        } as React.HTMLAttributes<HTMLElement>
      }
      disableCloseOnSelect
      disableClearable
    />
  )
})

export default AlgoliaSelect

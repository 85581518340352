import { TIME_UNIT, FINANCING_SOURCE } from '@/constants'
import { ModalTypes } from '@/types'

/* eslint-disable @typescript-eslint/no-explicit-any */

// Lead data from backend
export type TLead = {
  id: string
  contacts: TContact[]
  first_name?: string
  last_name?: string
}

// Lead data from form
export type TLeadFormData = {
  id?: string
  firstName?: string
  lastName?: string
  phone?: string
  email?: string
}

export type TContact = {
  type: {
    id: string
    name: string
  }
  value: string
}
export interface ICreateNewCaseForm {
  caseName: string
  caseDescription: string
  caseDescriptionFormatted: string
  leadInfo: TLeadFormData[]
  fee: string
  financingSource: string
  terms: boolean
  caseType: string
  legalStage: string
  jurisdiction: string
  caseNumber: string
  language?: string
  incidentDate: any
  proposalsDueDate: any
  isPriority: boolean
  dataRoomFile: File | null
  caseAlias: string
  internalCaseNumber: string
  assigneesId: string[]
  timeLimit: number
  timeUnitId: string
}

export interface ICaseForm {
  caseName: string
  caseDescription: string
  caseDescriptionFormatted: string
  leadInfo: TLeadFormData[]
  fee: string
  financingSource: string
  terms: boolean
  confidentiality: boolean
  caseType: string
  legalStage: string
  jurisdiction: string
  caseNumber: string
  language?: string
  incidentDate: any
  proposalsDueDate?: any
  isPriority?: boolean
  dataRoomFile?: File | null
  caseAlias: string
  internalCaseNumber: string
  assigneesId?: string[]
  timeLimit?: number
  timeUnitId?: string
  // NOTE: This is actually `Attorney_Profile | null`, but `any` is used because
  // of poor type checking performance for `Attorney_Profile.`
  onBehalfOf: any | null
  caseManagers?: string[]
}

export type MarketplaceVisibilityCard = 'public' | 'priority'

export const createNewCaseDefaultValues: ICaseForm = {
  caseName: '',
  caseDescription: '',
  caseDescriptionFormatted: '',
  confidentiality: false,
  leadInfo: [
    {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
    },
  ],
  fee: '33',
  financingSource: FINANCING_SOURCE.default,
  terms: false,
  caseType: '',
  legalStage: '',
  jurisdiction: '',
  caseNumber: '',
  language: '',
  incidentDate: null,
  proposalsDueDate: null,
  isPriority: false,
  dataRoomFile: null,
  caseAlias: '',
  internalCaseNumber: '',
  assigneesId: ['', '', ''],
  timeLimit: 2,
  timeUnitId: TIME_UNIT.Hours,
  onBehalfOf: null,
  caseManagers: [],
}

interface IFieldErrorLabels {
  [key: string]: string
}

export const fieldErrorLabels: IFieldErrorLabels = {
  caseName: 'Case Name',
  caseDescription: 'Case Description',
  caseDescriptionFormatted: 'Case Description',
  leadName: 'Lead Name',
  leadPhone: 'Lead Phone',
  leadEmail: 'Lead Email',
  caseType: 'Case Type',
  jurisdiction: 'Jurisdiction',
  proposalsDueDate: 'Proposals Due Date',
  terms: 'Terms & Conditions',
  confidentiality: 'Confidentiality Agreement',
  onBehalfOf: 'Create On Behalf Of',
}

export interface ICreateNewCaseProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (data: ICaseForm) => Promise<any>
  isEdit?: boolean
  defaultValues?: ICaseForm
  submitButtonText?: string
  title?: string
  modalType?: ModalTypes
  onDataroomFilesChange?: (files: File[], replace: boolean) => void
  isExternalLoading?: boolean
  caseId?: string
}

import { FC } from 'react'
import { useRouteError } from 'react-router-dom'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { datadogRum } from '@datadog/browser-rum'
import { ErrorWithFriendlyMessage } from './errors'

const Error: FC = () => {
  const error = useRouteError() as { message: string; statusText: string }
  console.error('Error:', error)

  datadogRum.addError(error, {
    errorMessage: error.message || error.statusText,
    errorType: 'Route Error',
  })

  const errorMessage =
    error instanceof ErrorWithFriendlyMessage
      ? error.friendlyMessage
      : error.statusText || error.message

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
        color="text.primary"
        p={3}
      >
        <Typography variant="h1" component="h1" gutterBottom>
          Oops!
        </Typography>
        <Typography variant="h5" component="p" gutterBottom>
          Sorry, an unexpected error has occurred.
        </Typography>
        <Typography variant="body1" component="i" color="textSecondary">
          {errorMessage}
        </Typography>
      </Box>
    </Container>
  )
}

export default Error

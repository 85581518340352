import { styled } from '@mui/material/styles'
import { Paper } from '@mui/material'

export const ProfileSummaryContainer = styled(Paper)`
  border-radius: 4px;
  overflow: auto;
`

export const StyledAvatarBG = styled('img')`
  width: 100%;
`

import { useState } from 'react'
import Typography from '@mui/material/Typography'

export type DiscardChangesModalTypes = 'confirmDiscardChanges' | null

export interface UseDiscardChangesModalProps {
  type?: string
  onSubmit: (form: any) => void
}

export const useDiscardChangesModal = ({
  onSubmit,
}: UseDiscardChangesModalProps) => {
  const [openModal, setOpenModal] = useState<DiscardChangesModalTypes>(null)

  const getRestProps = () => {
    switch (openModal) {
      case 'confirmDiscardChanges': {
        const description = (
          <Typography variant="body1">
            Are you sure you want to discard all your changes?
          </Typography>
        )
        const title = `Discard Changes`
        const buttonProps = {
          children: `Yes, Discard`,
        }
        return { title, description, buttonProps }
      }
      default:
        return { title: '', description: '', buttonProps: {} }
    }
  }

  const getConfirmationModalProps = () => {
    const open = !!openModal
    const onClose = () => {
      setOpenModal(null)
    }
    const { title, description, buttonProps } = getRestProps()
    return {
      open,
      onClose,
      title,
      description,
      AcceptButtonProps: buttonProps,
    }
  }

  return {
    modalOpen: !!openModal,
    getConfirmationModalProps,
    onSubmit,
    type: openModal,
    setOpenModal,
  }
}

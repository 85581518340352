import { useState } from 'react'
import { Controller } from 'react-hook-form'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined'

import { Attorney_Profile } from '@/gql/appApi'
import { JCaseType } from '@/types'
import { NEW_DATAROOM_ENABLED } from '@/constants'
import ErrorMessage from '@/components/Messagges/ErrorMessage'
import ConfirmationModal from '../ConfirmationModal'
import AttorneySearch from '../../AttorneySearch'
import useBulkInviteToCaseModal from './useBulkInviteToCaseModal'

export interface BulkInviteToCaseModalProps {
  open: boolean
  onClose: () => void
  jCase?: JCaseType
  onSubmit: () => void
  onBehalfOf?: string
  onBehalfOfUserId?: string
}

const BulkInviteToCaseModal = ({
  open,
  jCase,
  onSubmit,
  onClose,
  onBehalfOf,
  onBehalfOfUserId,
}: BulkInviteToCaseModalProps) => {
  const [attorneysSelected, setAttorneysSelected] = useState<
    (Attorney_Profile | null)[]
  >([])

  const {
    handleSubmit,
    onSendInvitation,
    loading,
    fields,
    register,
    control,
    append,
    error,
  } = useBulkInviteToCaseModal({
    onSubmit,
    jCase,
    onBehalfOf,
    onBehalfOfUserId,
  })
  return (
    <ConfirmationModal
      open={open}
      title="Invite Attorneys to Case"
      description="Avoid spamming and only select members who you think may be interested in the case. All colleagues you select will be able to submit proposals, including those who have not been vetted."
      AcceptButtonProps={{
        children: 'Send Invitations',
        onClick: handleSubmit(onSendInvitation),
        disabled: loading,
      }}
      onClose={onClose}
    >
      <Typography mt={2} variant="subtitle1">
        All colleagues you select will be able to submit proposals, including
        those who have not been vetted.
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5, mt: 4 }}>
        {fields.map((f, i) => {
          const { name } = register(`attorneys.${i}.value`)
          const { name: allowAccessDataRoom } = register(
            `attorneys.${i}.allowAccessDataRoom`
          )
          return (
            <Box key={f.id}>
              <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AttorneySearch
                    placeholder="Search for Attorney Share members"
                    label="Search for Attorney Share member..."
                    value={value}
                    attorneysSelected={
                      attorneysSelected.filter(
                        att => att !== null
                      ) as Attorney_Profile[]
                    }
                    onChange={(att: Attorney_Profile | null) => {
                      const attSelected = [...attorneysSelected]
                      attSelected[i] = att
                      setAttorneysSelected(attSelected)
                      onChange(att)
                    }}
                  />
                )}
              />
              <FormControlLabel
                control={
                  <Controller
                    name={allowAccessDataRoom}
                    control={control}
                    render={({ field: { onChange, ...props } }) => (
                      <Checkbox
                        {...props}
                        onChange={(event, checked) => {
                          onChange(event, checked)
                        }}
                      />
                    )}
                  />
                }
                label="Allow access to Data Room files"
                sx={{ display: NEW_DATAROOM_ENABLED ? '' : 'none' }}
              />
            </Box>
          )
        })}
      </Box>
      {error ? (
        <ErrorMessage
          title="There was a problem submitting your request"
          content={error}
          sx={{
            // NOTE: undefined overrides a maxWidth in ErrorMessage that is too small
            maxWidth: undefined,
            '& .MuiAlert-root': {
              alignItems: 'flex-start',
            },
          }}
        />
      ) : null}
      <Box mt={2.5}>
        <Button
          onClick={() => append({ value: null, allowAccessDataRoom: false })}
          startIcon={<PersonAddAlt1OutlinedIcon sx={{ fontSize: '1rem' }} />}
        >
          Invite more members
        </Button>
      </Box>
    </ConfirmationModal>
  )
}

export default BulkInviteToCaseModal

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Card from '@mui/material/Card'
import Collapse from '@mui/material/Collapse'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/material/styles/useTheme'

import JSnackBar from '@/components/atoms/JSnackBar'
import { ParameterName, bootParamsService } from '@/services/BootParams'
import { IntegrationItemProps } from '../../types'

import textContent, { INTEGRATION_LINKS } from '../../constants'

import IntegrationItemCardContent from '../shared/IntegrationItemCardContent'
import IntegrationItemButtons from '../shared/IntegrationItemButtons'
import useSalesForceIntegrationItem from './useSalesForceIntegrationItem'
import {
  challengeFromVerifier,
  checkAndHandleErrors,
  disableIntegrationAction,
  handleTextFieldValue,
  isSetupSuccessful,
  verifier,
} from '../shared/utils'
import SalesForceCollapsableContent from './SalesForceCollapsableContent'
import { useCodeVerifier, useInstanceUrl } from '../shared/localStorageUtil'
import AdditionalInstructions from '../shared/AdditionalInstructions'

const SalesForceIntegrationItem = ({
  icon,
  name,
  subtitle,
  loading,
  organizationId,
  isConnectedToOtherLcms,
  isConnected,
  refetchLcmsData,
  removeIntegrationItem,
  isConnectingToLcms,
  connectingToLcms,
}: IntegrationItemProps) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const isSmallSize = useMediaQuery('(min-width:900px)')
  const {
    INTEGRATION_V2: { SALESFORCE },
  } = textContent
  const codeQueryParam = bootParamsService.get(ParameterName.SALESFORCE_CODE)
  const srcQueryParam = bootParamsService.get(ParameterName.SRC)

  const [expanded, setExpanded] = useState(false)
  const [salesforceRemoveStatus, setSalesforceRemoveStatus] = useState(false)
  const [urlInput, setUrlInput] = useState<string>('')
  const salesforceSrcName = 'salesforce'
  const [connectSalesForce, setConnectSalesForce] = useState(
    Boolean(
      codeQueryParam &&
        organizationId !== '' &&
        String(srcQueryParam) === salesforceSrcName
    )
  )
  const [codeChallenge, setCodeChallenge] = useState('')
  const [instanceUrl, setInstanceUrl] = useInstanceUrl()
  const [codeVerifier, setCodeVerifier] = useCodeVerifier()

  const getChallenge = async (v: string) => {
    try {
      const challenge = await challengeFromVerifier(v)
      setCodeChallenge(challenge)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error:', error)
    }
  }

  const {
    connectSalesForceIntegration,
    connectSalesForceIntegrationData,
    removeSalesForceData,
    isSalesForceIntegrationLoading,
    alert,
    handleClose,
    showAlert,
  } = useSalesForceIntegrationItem({
    code_challenge: String(codeChallenge),
    code: String(codeQueryParam),
    instance_url: urlInput,
    connectSalesForce,
    salesforceRemoveStatus,
    organizationId: String(organizationId),
    storageInstanceUrl: instanceUrl,
    storageCodeVerifier: codeVerifier,
    setInstanceUrl,
  })

  useEffect(() => {
    if (expanded) {
      getChallenge(verifier)
      setCodeVerifier(verifier)
    } else {
      setCodeChallenge('')
    }
  }, [expanded, setCodeVerifier])

  // Trigger connection
  useEffect(() => {
    if (
      codeQueryParam &&
      organizationId !== '' &&
      srcQueryParam === salesforceSrcName
    ) {
      connectingToLcms(true)
      setConnectSalesForce(true)
    }
  }, [
    salesforceSrcName,
    codeQueryParam,
    organizationId,
    srcQueryParam,
    connectingToLcms,
  ])

  // Monitors the LCMs setup process.
  useEffect(() => {
    if (
      isSetupSuccessful(connectSalesForceIntegrationData) &&
      !salesforceRemoveStatus &&
      !isConnected
    ) {
      connectingToLcms(false)
      setConnectSalesForce(false)
      bootParamsService.remove(ParameterName.SALESFORCE_CODE)
      bootParamsService.remove(ParameterName.SRC)
      refetchLcmsData()
    }
  }, [
    connectingToLcms,
    connectSalesForceIntegrationData,
    isConnected,
    refetchLcmsData,
    salesforceRemoveStatus,
  ])

  // Navigate to the current window location path clearing the query params provided,
  // replacing the current entry in the browser's history.
  useEffect(() => {
    if (window.location.search !== '') {
      navigate(window.location.pathname, { replace: true })
    }
  }, [navigate])

  // Handles errors during Salesforce integration setup or removal process
  // Check if the current organization is not connected to other LCMs
  useEffect(() => {
    const hasError = checkAndHandleErrors({
      connectIntegrationData: connectSalesForceIntegrationData,
      removeData: removeSalesForceData,
      isConnectedToOtherLcms: Boolean(isConnectedToOtherLcms),
      showAlert,
      setConnect: setConnectSalesForce,
    })
    if (hasError) {
      connectingToLcms(false)
      bootParamsService.remove(ParameterName.SALESFORCE_CODE)
      bootParamsService.remove(ParameterName.SRC)
    }
  }, [
    connectSalesForceIntegrationData,
    isConnectedToOtherLcms,
    removeSalesForceData,
    showAlert,
    connectingToLcms,
  ])

  // Handle disconnection from other LCMs
  useEffect(() => {
    if (isConnected && removeSalesForceData?.message === 'deleted') {
      setSalesforceRemoveStatus(false)
      refetchLcmsData()
    }
  }, [
    setSalesforceRemoveStatus,
    isConnected,
    removeSalesForceData?.message,
    refetchLcmsData,
  ])

  const salesforceName =
    expanded && isSmallSize ? SALESFORCE.NAME_EXPANDED : name

  const removeSalesforceIntegration = () => {
    removeIntegrationItem({
      integrationName: name,
      onSubmit: () => {
        setSalesforceRemoveStatus(true)
      },
    })
  }

  return (
    <Card
      sx={{
        margin: theme.spacing(0, 0, 4),
        padding: theme.spacing(2),
        boxShadow: 2,
      }}
    >
      <IntegrationItemCardContent
        actions={
          <IntegrationItemButtons
            shouldRenderLoadingButton={loading || isConnectingToLcms}
            shouldRenderDisconnectButton={
              !!isConnected && !salesforceRemoveStatus
            }
            disconnectLcms={() => {
              removeSalesforceIntegration()
            }}
            isConnectedToOtherLcms={isConnectedToOtherLcms}
            isExpandable
            expanded={expanded}
            setExpanded={setExpanded}
          />
        }
        icon={icon}
        subtitle={
          <AdditionalInstructions
            isConnected={isConnected}
            linkUrl={INTEGRATION_LINKS.SALESFORCE_INSTRUCTIONS_URL}
            subtitle={subtitle}
          />
        }
        name={`${isConnected ? 'Connected to ' : ''}${salesforceName}`}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <SalesForceCollapsableContent
          connectIntegration={connectSalesForceIntegration}
          isIntegrationLoading={
            isSalesForceIntegrationLoading || isConnectingToLcms
          }
          handleTextFieldValue={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleTextFieldValue(event, setUrlInput)
          }
          disableAction={disableIntegrationAction(urlInput)}
          codeChallenge={codeChallenge}
        />
      </Collapse>
      <JSnackBar
        vertical="bottom"
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={handleClose}
      />
    </Card>
  )
}

export default SalesForceIntegrationItem

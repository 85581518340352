import Typography from '@mui/material/Typography'
import JDropdownUser from '@/components/atoms/JDropdownUser'
import { CreateCaseWaterfallMember } from '../../types'

interface UserFormProps {
  position: number
  member: CreateCaseWaterfallMember
}

const AttorneySortableItem = ({ position, member }: UserFormProps) => (
  <>
    <Typography variant="body1" sx={{ mr: 2 }}>
      {position}
    </Typography>
    <JDropdownUser
      key={member.attorneyId}
      user={{
        id: member.attorneyId,
        name: member.attorneyName,
        organization: member.attorneyOrganization,
        isVetted: member.isVetted,
        isQualified: member.isQualified,
        picture: member.avatar,
      }}
      sx={{
        avatar: { width: 36, height: 36 },
        list: { px: 0, py: 0 },
        textBox: {
          width: {
            xs: '75%',
            md: '100%',
          },
        },
      }}
      badgeSize="xsmall"
    />
  </>
)

export default AttorneySortableItem

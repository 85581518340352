import { useCallback } from 'react'

import Alert, { AlertColor, AlertProps } from '@mui/material/Alert'
import Typography from '@mui/material/Typography'

import { MEMBERSHIP_TYPE } from '@/constants'
import {
  MembersCreditsResponse,
  MembersPricesResponse,
} from '@/utils/api/types'

interface ProposalAlertProps extends AlertProps {
  pricesData: MembersPricesResponse | null
  creditsData: MembersCreditsResponse | null
  isLoading: boolean
  isInvited?: boolean
  isPrivate?: boolean
}

interface AlertDetails {
  title: string
  boldText: string
  text: string
  severity: AlertColor
  lineBreak: boolean
}

interface AlertDetails {
  title: string
  boldText: string
  text: string
  severity: AlertColor
  lineBreak: boolean
}

const AcceptProposalAlert = ({
  pricesData,
  creditsData,
  isLoading,
  isInvited,
  isPrivate,
  sx,
}: ProposalAlertProps) => {
  const isVettedMembership: boolean =
    pricesData?.membership?.toUpperCase() ===
    MEMBERSHIP_TYPE.VETTED?.toUpperCase()

  const hasDiscount =
    Number(pricesData?.discount ?? 0) > 0 &&
    pricesData?.discount_stripe_price_id

  const credits = Number(creditsData?.organization?.credits) || 0

  const getAlertDetails = useCallback((): AlertDetails => {
    if (isInvited) {
      return {
        title: 'Good News!',
        boldText: '',
        text: isPrivate
          ? "Since you received this case via a Waterfall Referral™ there's no fee to complete this match!"
          : "Since you were invited to this case there's no fee to complete this match!",
        severity: 'success',
        lineBreak: false,
      }
    }
    if (isVettedMembership) {
      if (credits > 0) {
        return {
          title: 'Good news!',
          boldText: `Your law firm still has ${credits} ${
            credits <= 1 ? 'free match' : 'free matches'
          },`,
          text: ` and you don't have to pay the $${pricesData?.finalPrice} Match Fee for acquiring a case.`,
          severity: 'success',
          lineBreak: false,
        }
      }
      if (hasDiscount) {
        return {
          title: 'Match Fee Required to Complete Transaction',
          boldText: `As a JHQ Member you receive a ${pricesData?.discount_description} discount.`,
          text: 'This charge is 100% refundable if you do not sign the client for whatever reason, no questions asked.',
          severity: 'warning',
          lineBreak: false,
        }
      }
      return {
        title: 'Match Fee Required to Complete Transaction',
        boldText: '',
        text: 'This charge is 100% refundable if you do not sign the client for whatever reason, no questions asked.',
        severity: 'warning',
        lineBreak: false,
      }
    }
    if ((credits as number) >= 1) {
      return {
        title: 'Good news!',
        boldText: `Your law firm still has ${credits} ${
          (credits as number) <= 1 ? 'free match' : 'free matches'
        },`,
        text: ` and you don't have to pay the $${pricesData?.finalPrice} Match Fee for acquiring a case.`,
        severity: 'success',
        lineBreak: false,
      }
    }
    return {
      title: 'Match Fee Required to Complete Transaction',
      boldText: '',
      text: 'This charge is 100% refundable if you do not sign the client for whatever reason, no questions asked.',
      severity: 'warning',
      lineBreak: false,
    }
  }, [
    isVettedMembership,
    credits,
    pricesData,
    isInvited,
    isPrivate,
    hasDiscount,
  ])

  if (isLoading) {
    return null
  }

  const { title, boldText, text, severity, lineBreak } = getAlertDetails()

  return (
    <Alert
      severity={severity}
      sx={[{ width: '100%' }, ...(Array.isArray(sx) ? sx : [sx])]}
      icon={false}
    >
      <strong>{title}</strong>
      <br />
      <Typography variant="body2">
        <strong>{boldText}</strong>
        {!lineBreak && text}
      </Typography>
      {lineBreak && <Typography variant="body2">{text}</Typography>}
    </Alert>
  )
}

export default AcceptProposalAlert

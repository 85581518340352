import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import FilterListIcon from '@mui/icons-material/FilterList'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import Search from '@/components/Search'
import { ConfirmationModal } from '@/components'
import { alpha, useTheme } from '@mui/material/styles'
import { useWaterfallFilters } from './useWaterfallFilters'

export const WaterfallFilters = () => {
  const theme = useTheme()
  const {
    applyFilters,
    practiceAreas,
    clearFilters,
    filters,
    isLessMd,
    isFirmSettings,
    jurisdictions,
    loading,
    modalOpen,
    onChangeFilters,
    searchProps,
    setModalOpen,
    onAtoZClick,
  } = useWaterfallFilters()

  const getAtoZIcon = () => {
    if (filters.alphabetical === 'asc') {
      return <ArrowDropDownIcon />
    }
    if (filters.alphabetical === 'desc') {
      return <ArrowDropUpIcon />
    }
    return null
  }

  const renderFilters = () => {
    if (!isFirmSettings) return null

    return (
      <>
        <Autocomplete
          sx={{
            mr: 2,
            mb: {
              xs: 2.5,
              md: 0,
            },
            width: '100%',
            minWidth: '150px',
            maxWidth: { xs: 'auto', md: '250px' },
          }}
          value={
            practiceAreas.find(item => item.name === filters.practiceArea) ||
            null
          }
          options={practiceAreas}
          getOptionLabel={option => option?.name || ''}
          onChange={(_, optionData) =>
            onChangeFilters({ practiceArea: optionData?.name || '' })
          }
          renderInput={params => (
            <TextField {...params} variant="outlined" label="Case Type" />
          )}
          disabled={loading}
        />
        <Autocomplete
          sx={{
            mr: 2,
            mb: {
              xs: 2.5,
              md: 0,
            },
            width: '100%',
            minWidth: '150px',
            maxWidth: { xs: 'auto', md: '250px' },
          }}
          value={
            jurisdictions.find(item => item.name === filters.jurisdiction) ||
            null
          }
          options={jurisdictions}
          getOptionLabel={option => option?.name || ''}
          onChange={(_, optionData) =>
            onChangeFilters({ jurisdiction: optionData?.name || '' })
          }
          renderInput={params => (
            <TextField {...params} variant="outlined" label="Jurisdiction" />
          )}
          disabled={loading}
        />
      </>
    )
  }

  const renderToggleAndSort = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        width: { xs: '100%', md: isFirmSettings ? '100%' : '35%' },
        justifyContent: isFirmSettings ? 'space-between' : 'flex-end',
        mt: { xs: 0, md: 2 },
        mb: isFirmSettings ? 2 : 0,
        px: { xs: 2, md: 0 },
      }}
    >
      {isFirmSettings && (
        <FormControlLabel
          control={
            <Switch
              checked={filters.integrationsOnly}
              onChange={() =>
                onChangeFilters({
                  integrationsOnly: !filters.integrationsOnly,
                })
              }
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={
            <Typography
              variant="body2"
              color={alpha(theme.palette.common.black, 0.87)}
            >
              Integrations Only Waterfalls
            </Typography>
          }
          disabled={loading}
        />
      )}
      <Button
        onClick={onAtoZClick}
        variant="text"
        size="small"
        sx={{ width: 'fit-content', my: { xs: 2, md: 0 } }}
        endIcon={getAtoZIcon()}
      >
        <Typography variant="body2">Sort A to Z</Typography>
      </Button>
    </Box>
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: !isFirmSettings ? 'column' : 'column-reverse',
          md: !isFirmSettings ? 'row' : 'column',
        },
        mb: { xs: 0, md: isFirmSettings ? 0 : 2.5 },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: { xs: '100%', md: isFirmSettings ? '100%' : '65%' },
          justifyContent: {
            xs: 'space-between',
            md: 'flex-start',
          },
          mt: {
            xs: 2.5,
            md: 4,
          },
          px: { xs: 2, md: 0 },
        }}
      >
        <Search
          {...searchProps}
          sx={{
            width: '100%',
            minWidth: '200px',
            mr: 1,
          }}
        />
        {!isLessMd && isFirmSettings && renderFilters()}
        {isLessMd && isFirmSettings && (
          <>
            <Button onClick={() => setModalOpen(true)}>
              <FilterListIcon />
            </Button>
            {modalOpen && (
              <ConfirmationModal
                open={modalOpen}
                title="Filter Cases"
                AcceptButtonProps={{
                  children: 'Apply Filters',
                  onClick: applyFilters,
                }}
                onClose={() => setModalOpen(false)}
                fullScreen
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mt: 2,
                  }}
                >
                  {renderFilters()}
                  {renderToggleAndSort()}
                  <Button
                    variant="text"
                    onClick={clearFilters}
                    size="small"
                    sx={{ width: 'fit-content', alignSelf: 'center' }}
                  >
                    Clear filters
                  </Button>
                </Box>
              </ConfirmationModal>
            )}
          </>
        )}
      </Box>
      {(!isLessMd || !isFirmSettings) && renderToggleAndSort()}
    </Box>
  )
}

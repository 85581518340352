import { useEffect, useState, useRef } from 'react'
import CustomLoading from '@/components/Loading'
import { ContentCaseLayout } from '@/components'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { useCase } from '@/pages/Case/hooks/useJCase'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate } from 'react-router-dom'
import { useRestrictedUserPermissions } from '@/hooks/permissions/useRestrictedUserPermissions'
import ErrorPage from '@/components/shared/ErrorPage'
import { RoutesData } from '@/router/RoutesData'
import NotPaymentMessage from '@/components/Messagges/NotPayment'
import useMembership from '@/hooks/useMembership'
import get from 'lodash/get'
import { useWindowSize } from 'usehooks-ts'
import {
  CASE_PURCHASE_STATUS,
  PAYMENT_WALL_ENABLED,
  CASE_ACCESS_TYPES,
  USER_ROLES,
} from '@/constants'
import MobileTopOptions from '@/pages/Case/components/Sidebar/Mobile/MobileTopOptions'
import useMediaQuery from '@mui/material/useMediaQuery'
import useCaseManager from '@/hooks/useCaseManager'
import { CaseChatProvider } from '@/providers/CaseChatProvider'
import { useStripe } from '@/hooks/useStripe'
import CaseHeader from './components/CaseHeader/CaseHeader'
import CaseBody from './components/CaseBody/CaseBody'
import { TCase } from './types'
import CaseNotAvailableModal from '../MyReferrals/components/CaseNotAvailableModal/CaseNotAvailableModal'

import Sidebar from './components/Sidebar/Sidebar'
import { caseTitles } from './hooks/constants'
import { ISidebarProps } from './components/Sidebar/CaseSidebar/types'
import CaseNotAvailableDeepLink from './components/CaseNotAvailable/CaseNotAvailable'

const Case = () => {
  const {
    accessType,
    createPreference,
    isDeletedData,
    isDeletedLoading,
    dataCase,
    dataCaseLoading,
    refetchCaseData,
    deletePreference,
    genericModalOpen,
    getCaseInformationProps,
    getConfirmationModalProps,
    isVetted,
    isSigned,
    isQualified,
    modalOpen,
    modalType,
    nonVettedModal,
    onSubmit,
    onSubmitGenericModal,
    preferences,
    preferencesLoading,
    responseModal,
    setGenericOpenModal,
    setOpenModal,
    setResponseModal,
    showNonVettedModal,
    attorneyId,
    dataRoomModalOpen,
    setDataRoomOpenModal,
    inviteToCaseModalOpen,
    setInviteToCaseModalOpen,
    isLoadingPayment,
    agreementParams,
    actionTitle,
    caseSpecialText,
    caseErrorModalOpen,
    isSameOrganization,
    loadingCreateCaseMutation,
    isCaseOwner,
    isCaseFromSomeoneInMyOrganization,
    caseOwnerWithOrg,
    prospectAttorneyProfile,
    isPriorityAccess,
    iamProspectAttorneyInExternalCase,
    hasAdminAccess,
    hasCaseManagerAccess,
    isCaseManager,
    isAdminAttorney,
    isNonVettedAdminAttorney,
    currentUserAttorneyId,
    isPrivateCase,
    loggedUserId,
    loggedUserOrganizationId,
    qualifiesForInvitedPricing,
    caseIsTakenByMyOrg,
    rejectedByAttorneyInvolved,
    rejectedByCounterpart,
    hasActivity,
    optionsLoading,
    leads,
    isAttorneyOwner,
    showInvitationPricing,
    waterfallCasePurchase,
    alertTitle,
    alertSubtitle,
    caseNotAvailable,
    ...useCaseProps
  } = useCase()

  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()
  const { vettedAttorneys, isAdmin } = useCaseManager()
  const caseActivity = dataCase?.case_purchase_activity ?? []
  const caseInfo: TCase = caseActivity?.[0]?.case_purchase_id?.case_id as TCase
  const sellerCaseManagers =
    caseInfo?.ownerships?.filter(
      (ownership): ownership is NonNullable<typeof ownership> =>
        ownership?.user_id?.role?.id === USER_ROLES.CaseManagerUser
    ) ?? []

  const sellerCaseManagerUserIds = sellerCaseManagers
    .map(ownership => ownership.user_id?.id)
    .filter((id): id is string => id !== undefined)
  const caseOwner = caseInfo?.attorney_id?.profiles?.[0]
  const chatCaseAttorneyId = caseInfo?.attorney_id?.id
  const isAttorneyLastActivity =
    caseActivity?.[0]?.created_by?.profiles?.[0]?.attorney_id?.id === attorneyId
  const iamProspectInCase = get(
    caseActivity,
    '[0].case_purchase_id.prospect_attorney_id.profiles',
    []
  ).some(
    (profile: { attorney_id: { id: string } }) =>
      profile.attorney_id.id === attorneyId
  )
  const isInitiatingAttorney =
    caseActivity?.at(-1)?.created_by?.profiles?.[0]?.attorney_id?.id ===
    attorneyId

  const hasAccess =
    accessType === CASE_ACCESS_TYPES.Invited ||
    accessType === CASE_ACCESS_TYPES.Waterfall ||
    accessType === CASE_ACCESS_TYPES.Owner
  const createdAtDate = caseInfo?.created_ts

  // TODO: Move to helper/util function
  const isAccepted = caseActivity?.some(
    activity =>
      activity?.case_purchase_id?.status?.id === CASE_PURCHASE_STATUS.Accept
  )

  const isNonVettedAdminOrCaseMngWithJhqMembersInOrg =
    (isNonVettedAdminAttorney || isCaseManager) &&
    Number(vettedAttorneys?.length) > 0

  const showCaseOptions =
    hasAccess ||
    isVetted ||
    isQualified ||
    hasActivity ||
    isCaseFromSomeoneInMyOrganization ||
    isNonVettedAdminOrCaseMngWithJhqMembersInOrg

  const { isRestrictedUser } = useRestrictedUserPermissions(
    'Case',
    'inOrganization'
  )

  const { applyForMembership } = useStripe()
  const {
    isMembershipEnabled,
    isMembershipPrefLoading,
    isNeededToCheckMembership,
  } = useMembership()

  const loading = dataCaseLoading

  useEffect(() => {
    if (!!caseInfo?.name && !!caseInfo?.type?.name) {
      document.title = `Attorney Share - ${RoutesData.RCase.title} - ${
        caseInfo?.name || ''
      } (${caseInfo?.type?.name || ''})`
    }
  }, [caseInfo?.name, caseInfo?.type?.name])

  const [sidebarHeight, setSidebarHeight] = useState(0)
  const sidebarRef = useRef(null)
  const { width = 0, height = 0 } = useWindowSize()

  useEffect(() => {
    if (!loading) {
      setSidebarHeight(get(sidebarRef, 'current.clientHeight', 0))
    }
  }, [loading, width, height])

  const sidebarProps: ISidebarProps = {
    dataCase,
    genericModalOpen,
    getCaseInformationProps,
    getConfirmationModalProps,
    modalOpen,
    modalType,
    nonVettedModal,
    onSubmit,
    onSubmitGenericModal,
    setGenericOpenModal,
    setOpenModal,
    showNonVettedModal,
    inviteToCaseModalOpen,
    setInviteToCaseModalOpen,
    actionTitle,
    loadingCreateCaseMutation,
    handleApplyForMembership: () => applyForMembership('/marketplace'),
    chatCaseAttorneyId,
    isAttorneyLastActivity,
    isSameOrganization,
    agreementParams,
    accessType,
    responseModal,
    setResponseModal,
    isVetted,
    isQualified,
    // other fields
    caseInfo,
    caseActivity,
    caseOwner,
    showCaseOptions,
    isCaseOwner: Boolean(isCaseOwner),
    isCaseFromSomeoneInMyOrganization,
    caseOwnerWithOrg,
    prospectAttorneyProfile,
    isPriorityAccess,
    hasAdminAccess,
    hasCaseManagerAccess,
    iamProspectInCase,
    iamProspectAttorneyInExternalCase,
    isAdmin,
    isAdminAttorney,
    isCaseManager,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    vettedAttorneys,
    isNonVettedAdminOrCaseMngWithJhqMembersInOrg,
    attorneyId,
    currentUserAttorneyId,
    isPrivateCase,
    loggedUserId,
    loggedUserOrganizationId,
    qualifiesForInvitedPricing,
    caseIsTakenByMyOrg,
    rejectedByAttorneyInvolved,
    rejectedByCounterpart,
    optionsLoading,
    leads,
    isAttorneyOwner,
    showInvitationPricing,
    isInitiatingAttorney,
    caseNotAvailable,
    isSigned,
    ...useCaseProps,
  }

  // Redirect to My Cases if case is deleted
  if (isDeletedData && !isDeletedLoading) {
    const isDeleted = isDeletedData?.case?.[0]?.deleted ?? true
    if (isDeleted) {
      navigate(`${RoutesData.RMyReferrals.path}`, {
        state: { refetchRequired: true },
      })
    }
  }

  if (isRestrictedUser && !loading) {
    return (
      <ContentCaseLayout>
        <ErrorPage message="You don’t have permissions to view My Referrals" />
      </ContentCaseLayout>
    )
  }

  if (
    PAYMENT_WALL_ENABLED &&
    !loading &&
    !isMembershipPrefLoading &&
    isNeededToCheckMembership &&
    !isMembershipEnabled
  ) {
    return (
      <ContentCaseLayout>
        <NotPaymentMessage />
      </ContentCaseLayout>
    )
  }

  const showSidebar =
    (!(
      isCaseFromSomeoneInMyOrganization &&
      accessType === CASE_ACCESS_TYPES.NoAccess
    ) &&
      actionTitle !== caseTitles.noPermission) ||
    isLargeScreen

  return (
    <Box
      sx={{
        overflow: 'auto',
        height: '100vh',
      }}
    >
      <CaseNotAvailableDeepLink open={caseNotAvailable} />
      <CaseNotAvailableModal
        open={caseErrorModalOpen}
        onClose={() => navigate('/marketplace')}
        onSubmit={() => navigate('/marketplace')}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: th => th.zIndex.drawer + 1 }}
        open={isLoadingPayment}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ContentCaseLayout hideBottomBar={isSigned}>
        {loading ? (
          <CustomLoading message="Loading case information..." />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              overflow: 'auto',
              paddingBottom: isLargeScreen
                ? theme.spacing(14)
                : `${sidebarHeight}px`,
              '&::-webkit-scrollbar': { display: 'none' },
            }}
          >
            <CaseHeader
              isSameOrganization={isSameOrganization}
              accessType={accessType}
              title={caseInfo?.name || ''}
              fee={caseInfo?.fee || 0}
              priorityText={caseSpecialText}
              preferences={preferences ?? { attorney_case_mp_preference: [] }}
              preferencesLoading={preferencesLoading}
              deletePreference={deletePreference}
              createPreference={createPreference}
              caseInfo={caseInfo}
              isAccepted={isAccepted}
              postedOnDate={createdAtDate}
              hasAdminAccess={hasAdminAccess}
              hasCaseManagerAccess={hasCaseManagerAccess}
              isCaseManager={isCaseManager}
              caseAccepted={!!agreementParams}
              isAttorneyOwner={isAttorneyOwner}
              showInvitationPricing={showInvitationPricing}
              waterfallCasePurchase={waterfallCasePurchase}
              alertTitle={alertTitle}
              alertSubtitle={alertSubtitle}
              caseActivity={caseActivity}
            />
            <CaseChatProvider
              caseId={caseInfo?.id}
              members={[caseOwnerWithOrg.userId, ...sellerCaseManagerUserIds]}
              caseTitle={String(caseInfo.name)}
              isCaseOwner={!!isCaseOwner}
              isCasePage
            >
              <MobileTopOptions
                showCaseOptions={showCaseOptions}
                sidebarProps={sidebarProps}
              />
            </CaseChatProvider>
            <CaseBody
              caseData={caseInfo}
              caseActivity={caseActivity}
              accessType={accessType}
              responseModal={responseModal}
              setResponseModal={setResponseModal}
              dataRoomModalOpen={dataRoomModalOpen}
              setDataRoomOpenModal={setDataRoomOpenModal}
              leadInfo={leads}
              refetchCaseData={refetchCaseData}
              showCaseOptions={showCaseOptions}
              hasAdminAccess={hasAdminAccess}
              hasCaseManagerAccess={hasCaseManagerAccess}
              isCaseManager={isCaseManager}
              currentUserAttorneyId={currentUserAttorneyId}
              actionTitle={actionTitle}
              iamProspectAttorneyInExternalCase={
                iamProspectAttorneyInExternalCase
              }
              caseAccepted={!!agreementParams}
              isAttorneyOwner={isAttorneyOwner}
              isSigned={isSigned}
              isCaseOwner={isCaseOwner}
            />
          </Box>
        )}
        {!loading && showSidebar ? (
          <CaseChatProvider
            caseId={caseInfo?.id}
            members={[caseOwnerWithOrg.userId, ...sellerCaseManagerUserIds]}
            caseTitle={String(caseInfo.name)}
            isCaseOwner={!!isCaseOwner}
            isCasePage
          >
            <Sidebar {...sidebarProps} />
          </CaseChatProvider>
        ) : null}
        {!loading && showSidebar ? <Sidebar {...sidebarProps} /> : null}
      </ContentCaseLayout>
    </Box>
  )
}

export default Case

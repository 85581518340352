import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { LitifyContentProps } from '@/pages/AccountSettings/Integrations/types'
import { INTEGRATION_LINKS } from '../../../constants'

const LitifyCollapsableContent = ({
  connectIntegration,
  isIntegrationLoading,
  handleTextFieldValue,
  disableAction,
  codeChallenge,
}: LitifyContentProps) => {
  const theme = useTheme()

  return (
    <Grid
      item
      md={8}
      xs={12}
      sx={{
        margin: {
          xs: theme.spacing(2, 0, 0),
          md: theme.spacing(4, 0, 2, 8),
        },
      }}
    >
      <Box
        sx={{
          marginBottom: theme.spacing(3),
          width: {
            xs: '100%',
          },
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
          Configure the Attorney Share integration in Litify
        </Typography>
        <Typography variant="body2">
          Please follow{' '}
          <Link
            href={INTEGRATION_LINKS.LITIFY_INSTRUCTIONS_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            these instructions
          </Link>{' '}
          to complete the additional Litify and Salesforce configuration steps
          to enable the integration with Attorney Share.
        </Typography>
      </Box>
      <Box
        sx={{
          marginBottom: theme.spacing(3),
          width: {
            xs: '100%',
          },
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
          Enter your law firm&apos;s Litify instance URL
        </Typography>
        <Typography variant="body2">
          This is the address that you use to access Litify; for example
          {': '} <strong>https://mylawfirm.litify.com</strong>
        </Typography>
        <TextField
          id="url-input"
          label="Litify instance URL"
          variant="outlined"
          sx={{
            width: {
              md: '475px',
              xs: '100%',
            },
            marginTop: theme.spacing(3),
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleTextFieldValue(event)
          }
        />
      </Box>
      <Button
        variant={isIntegrationLoading ? 'text' : 'contained'}
        size="large"
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          connectIntegration(event)
        }
        disabled={isIntegrationLoading || disableAction || codeChallenge === ''}
      >
        {isIntegrationLoading ? 'Loading' : 'Connect'}
      </Button>
    </Grid>
  )
}

export default LitifyCollapsableContent

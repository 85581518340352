import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { forwardRef, useState } from 'react'
import CreateAccountField from '../CreateAccountField/CreateAccountField'

type PasswordFieldProps = {
  label: string
  error?: string | null
}

const PasswordField: React.FC<PasswordFieldProps> = forwardRef<
  HTMLInputElement,
  PasswordFieldProps
>(({ error, label, ...props }, ref) => {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  return (
    <CreateAccountField
      {...props}
      ref={ref}
      fullWidth
      error={!!error}
      helperText={error || ' '}
      label={label}
      type={showPassword ? 'text' : 'password'}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={togglePasswordVisibility}
            onMouseDown={handleMouseDownPassword}
          >
            {showPassword ? (
              <Visibility
                sx={{
                  width: 24,
                }}
              />
            ) : (
              <VisibilityOff
                sx={{
                  width: 24,
                }}
              />
            )}
          </IconButton>
        ),
      }}
    />
  )
})

export default PasswordField

import { ICreateCaseContext } from './types'

export const initialContextValue: ICreateCaseContext = {
  caseError: null,
  caseErrorRef: { current: null },
  caseTypes: [],
  clioAlert: { open: false, message: '', severity: 'success' },
  closeClioAlert: () => {},
  dataroomErrors: [],
  error: undefined,
  errorMessages: [],
  errorRef: { current: null },
  financingSources: [],
  formErrorsRef: { current: null },
  handleSelectVisibility: () => {},
  handleSubmitCase: () => Promise.resolve(),
  isCaseSubmitted: false,
  isEdit: false,
  isExternalLoading: false,
  isSubmitLoading: false,
  languages: [],
  loading: false,
  marketplaceVisibilityError: '',
  methods: null,
  mktpVisibilityErrorRef: { current: null },
  modalType: 'CaseCreated',
  onDataroomFilesChange: () => {},
  onCloseCaseSubmittedHandler: () => {},
  processStages: [],
  selectedCard: 'public',
  submitButtonText: 'Create Case',
  title: 'Create New Case',
  usStates: [],
}

import React from 'react'
import { Alert, AlertTitle, Typography } from '@mui/material'
import { useCaseContext } from '../../../hooks/useCaseContext'

const SidebarAlerts: React.FC = () => {
  const { caseData } = useCaseContext()

  if (!caseData) return null

  const { alerts } = caseData.sidebar

  if (!alerts) return null

  return (
    <>
      {alerts.map(alert => (
        <Alert
          key={alert.id}
          severity={alert.type}
          icon={false}
          sx={{
            borderRadius: 0,
            pb: alert.message ? 1 : 0,
            pt: 1,
          }}
        >
          <AlertTitle>
            <Typography variant="subtitle2">{alert.title}</Typography>
          </AlertTitle>
          {alert.message && (
            <Typography variant="body2">{alert.message}</Typography>
          )}
        </Alert>
      ))}
    </>
  )
}

export default SidebarAlerts

import axios from 'axios'
import request from '@/axios/config'
import { useQuery } from 'react-query'
import { TError } from '../types'
import { validateToken } from '../validateToken'
import {
  CaseDetailsResponse,
  ClioSetupPayload,
  LawmaticsSetupPayload,
  LeadDocketSetupPayload,
  LitifySetupPayload,
  MyCaseSetupPayload,
  RemoveResponse,
  SetupResponse,
  TGetLcmsData,
} from './types'

export const removeIntegrationItem = async (
  organization_id: string
): Promise<RemoveResponse | TError | null> => {
  validateToken()
  try {
    const response = await request.delete<RemoveResponse>(
      '/integrations/remove',
      {
        data: { organization_id },
      }
    )
    if (response.data) {
      return response.data
    }
    return { message: 'No data received', statusCode: 204 }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const err: TError = {
        message:
          error.response?.data?.errors ||
          error.message ||
          'Something went wrong',
        statusCode: error.response?.status || 500,
      }
      if (error.response?.data?.status) {
        err.status = error.response.data.status
      }
      return err
    }
    return {
      message:
        error instanceof Error ? error.message : 'An unexpected error occurred',
      statusCode: 500,
    }
  }
}

export const useRemoveIntegrationItem = (
  organization_id: string,
  isConnected: boolean
) =>
  useQuery(
    ['removeIntegration', organization_id],
    () => removeIntegrationItem(organization_id),
    {
      enabled: isConnected,
      cacheTime: 0,
    }
  )

const getLcmsData = async (
  caseId: string,
  src: string,
  organizationId: string
): Promise<CaseDetailsResponse | TError | undefined> => {
  validateToken()
  try {
    const req = await request.get('/integrations/get-case', {
      params: {
        case_id: caseId,
        src,
        organization_id: organizationId,
      },
    })
    if (req.data) {
      return req.data
    }
    const errorRequest: TError = {
      message: 'Data not found',
      statusCode: 500,
    }
    return errorRequest
  } catch (error) {
    let errorMessage = 'An unexpected error occurred'
    if (axios.isAxiosError(error)) {
      errorMessage = error.message
    }
    return {
      message: errorMessage,
      statusCode: 500,
    }
  }
}

export const useGetLcmsData = ({
  caseId,
  src,
  organizationId,
  shouldEnable,
}: TGetLcmsData) =>
  useQuery(
    [`${src}-integration-data`, caseId],
    () => getLcmsData(caseId, src, organizationId),
    {
      enabled: shouldEnable,
      cacheTime: 0,
    }
  )

const setupIntegration = async <T extends object>(
  payload: T,
  removeStatus: boolean,
  endpoint: string
): Promise<SetupResponse | TError> => {
  validateToken()
  try {
    if (removeStatus) {
      return { message: 'No data received', statusCode: 204 }
    }
    const response = await request.post<SetupResponse>(endpoint, payload)
    if (response.data) {
      return response.data
    }
    return { message: 'No data received', statusCode: 204 }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const err: TError = {
        message:
          error.response?.data?.errors ||
          error.message ||
          'Something went wrong',
        statusCode: error.response?.status || 500,
      }
      if (error.response?.data?.status) {
        err.status = error.response.data.status
      }
      return err
    }
    return {
      message:
        error instanceof Error ? error.message : 'An unexpected error occurred',
      statusCode: 500,
    }
  }
}

const useSetupIntegration = <T extends object>(
  payload: T,
  removeStatus: boolean,
  connectSetup: boolean,
  endpoint: string,
  queryKey: string
) =>
  useQuery(
    [queryKey, payload],
    () => setupIntegration(payload, removeStatus, endpoint),
    {
      enabled: connectSetup,
      cacheTime: 0,
    }
  )

export const useSetupClio = (
  payload: ClioSetupPayload,
  clioRemoveStatus: boolean,
  connectSetup: boolean
) =>
  useSetupIntegration<ClioSetupPayload>(
    payload,
    clioRemoveStatus,
    connectSetup,
    '/integrations/setup',
    'setupClioIntegration'
  )

export const useSetupLawmatics = (
  payload: LawmaticsSetupPayload,
  lawmaticsRemoveStatus: boolean,
  connectSetup: boolean
) =>
  useSetupIntegration<LawmaticsSetupPayload>(
    payload,
    lawmaticsRemoveStatus,
    connectSetup,
    '/integrations/setup',
    'setupLawmaticsIntegration'
  )

export const useSetupLitify = (
  payload: LitifySetupPayload,
  litifyRemoveStatus: boolean,
  connectSetup: boolean
) =>
  useSetupIntegration<LitifySetupPayload>(
    payload,
    litifyRemoveStatus,
    connectSetup,
    '/integrations/setup',
    'setupLitifyIntegration'
  )

export const useSetupSalesForce = (
  payload: LitifySetupPayload,
  salesforceRemoveStatus: boolean,
  connectSetup: boolean
) =>
  useSetupIntegration<LitifySetupPayload>(
    payload,
    salesforceRemoveStatus,
    connectSetup,
    '/integrations/setup',
    'setupSalesForceIntegration'
  )

export const useSetupMyCase = (
  payload: MyCaseSetupPayload,
  myCaseRemoveStatus: boolean,
  connectSetup: boolean
) =>
  useSetupIntegration<MyCaseSetupPayload>(
    payload,
    myCaseRemoveStatus,
    connectSetup,
    '/integrations/setup',
    'setupMyCaseIntegration'
  )

export const setupLeadDocket = async (
  payload: LeadDocketSetupPayload
): Promise<SetupResponse | TError> => {
  validateToken()
  try {
    const response = await request.post<SetupResponse>(
      '/integrations/setup',
      payload
    )
    if (response.data) {
      return response.data
    }
    return { message: 'No data received', statusCode: 204 }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const err: TError = {
        message:
          error.response?.data?.errors ||
          error.message ||
          'Something went wrong',
        statusCode: error.response?.status || 500,
      }
      if (error.response?.data?.status) {
        err.status = error.response.data.status
      }
      return err
    }
    return {
      message:
        error instanceof Error ? error.message : 'An unexpected error occurred',
      statusCode: 500,
    }
  }
}

export const useSetupLeadDocket = (
  payload: LeadDocketSetupPayload,
  connectSetup: boolean
) =>
  useQuery(
    ['setupLeadDocketIntegration', payload],
    () => setupLeadDocket(payload),
    {
      enabled: connectSetup,
      cacheTime: 0,
    }
  )

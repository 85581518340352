import React from 'react'
import { Box, Typography, Button, useMediaQuery, Theme } from '@mui/material'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import DeleteIconOutlined from '@mui/icons-material/DeleteOutlined'
import { useCaseContext } from '../../hooks/useCaseContext'

const DataroomHeader: React.FC = () => {
  const { caseData } = useCaseContext()
  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('sm')
  )

  if (!caseData) return null

  const { dataroom } = caseData
  if (!dataroom) return null

  const { access } = dataroom
  if (!access) return null

  const hasFiles = dataroom.files.length > 0
  const hasFileAccess = ['edit', 'view'].includes(access)
  const hasFileEditAccess = dataroom.access === 'edit'

  const getDisclaimerText = () => {
    switch (dataroom.access) {
      case 'request':
      case 'denied':
        return 'This is protected information. You need to request access to Data Room files.'
      default:
        return ''
    }
  }

  const handleDownloadAll = () => {
    // TODO: move to case context/hook
    console.log('download all')
  }

  const handleRemoveAll = () => {
    // TODO: move to case context/hook
    console.log('remove all')
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: theme => theme.spacing(5),
        }}
      >
        <Typography variant="subtitle2" component="h2">
          Data Room
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {hasFiles && hasFileAccess && (
            <Button
              startIcon={<FileDownloadOutlinedIcon />}
              variant="text"
              color="primary"
              onClick={handleDownloadAll}
            >
              {isLargeScreen ? 'Download All' : 'All'}
            </Button>
          )}
          {hasFiles && hasFileEditAccess && (
            <Button
              startIcon={<DeleteIconOutlined />}
              variant="text"
              color="error"
              onClick={handleRemoveAll}
            >
              {isLargeScreen ? 'Remove All' : 'All'}
            </Button>
          )}
        </Box>
      </Box>
      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
        {getDisclaimerText()}
      </Typography>
    </Box>
  )
}

export default DataroomHeader

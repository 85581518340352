import { GridColDef } from '@mui/x-data-grid'
import Typography from '@mui/material/Typography'
import { isQualifiedMembership } from '@/utils/membership'

import JUser from '@/components/atoms/JUser'
import { ATTORNEYS_ROLES_BY_ID, IMAGE_SIZES } from '@/constants'
import { getImagePath } from '@/utils'

export const getActiveMemberColumns = () => {
  const activeMemberColumns: GridColDef[] = [
    {
      field: 'user_id',
      headerName: 'Name',
      sortable: true,
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: params => {
        const { first_name: firstName, last_name: lastName } = params.value
        return (
          <JUser
            mainText={`${firstName} ${lastName}`}
            size="small"
            isVetted={params.value?.attorneys?.[0]?.is_vetted}
            isQualified={isQualifiedMembership(
              params.value?.attorneys?.[0]?.membership_type?.id
            )}
            avatar={getImagePath({
              id: params.value?.avatar?.id,
              size: IMAGE_SIZES.Small,
            })}
          />
        )
      },
    },
    {
      field: 'user_id.current_organization[0].position_name',
      headerName: 'Position',
      sortable: true,
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: params => (
        <Typography>
          {params.row.user_id.current_organization[0].position_name}
        </Typography>
      ),
    },
    {
      field: 'user_id.role',
      headerName: 'Role',
      sortable: true,
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: params => (
        <Typography>
          {
            ATTORNEYS_ROLES_BY_ID[
              params.row.user_id.role.id as keyof typeof ATTORNEYS_ROLES_BY_ID
            ]
          }
        </Typography>
      ),
    },
  ]
  return activeMemberColumns
}

import Grid from '@mui/material/Grid'
import { ReactNode, useContext } from 'react'
import { ResponseModal } from '@/components'
import useGetMyRoleAndOrg from '@/hooks/useGetMyRoleAndOrg'
import { CASE_PURCHASE_STATUS } from '@/constants'
import { BuyerHeadline } from './BuyerHeadline'
import { MyReferralsContext } from '../MyReferralsContext'
import { useBuyerReferral } from './useBuyerReferral'
import { ReferralWithDrawer } from './ReferralWithDrawer'
import { SummaryHeader } from './SummaryHeader'
import { ProposalReceivedActions } from './ProposalReceivedActions'
import { ActionHeader } from './ActionHeader'
import { ReferralContext } from './ReferralContext'
import { AwaitingOfferResponseActions } from './AwaitingOfferResponseActions'
import { WatchedReferralActions } from './WatchedReferralActions'
import { isFollowedAndAvailable } from './utils'

export const BuyerReferral = () => {
  const { refetch } = useContext(MyReferralsContext)
  const {
    caseEntry,
    caseEntry: {
      case_purchase: [casePurchase],
      fee: originalFee,
    },
    counterpart,
  } = useContext(ReferralContext)

  const { isAttorney, isAdmin } = useGetMyRoleAndOrg()

  const showAsFollowing = isFollowedAndAvailable(caseEntry)

  const {
    areNegotiationsTakingPlace,
    buyerNegotiationParams: { fee: negotiatedFee, caseStatus },
    buyerNegotiationProps: { response, setResponse },
    didBuyerTakeMostRecentAction,
    feeOfferedByBuyer,
    isClientSigningResponseModal,
    proposalReceivedActionsProps,
    showCaseManagerPermissionRequest,
    showContactInformation,
    showContactInformationInAccordion,
    showCounterpartContactInformation,
    showDataRoom,
    showNegotiations,
    showReferralContent,
    showWaterfallProposalDueDate,
    watchedReferralActionsProps,
    waterfallProposalDueDate,
  } = useBuyerReferral()

  if (
    !showAsFollowing &&
    (!isAttorney || isAdmin || !areNegotiationsTakingPlace)
  ) {
    return (
      <ReferralWithDrawer
        headline={
          <BuyerHeadline
            caseStatus={caseStatus}
            didBuyerTakeMostRecentAction={didBuyerTakeMostRecentAction}
            showWaterfallProposalDueDate={showWaterfallProposalDueDate}
            waterfallProposalDueDate={waterfallProposalDueDate}
          />
        }
        header={
          <SummaryHeader
            counterparts={counterpart ? [counterpart] : []}
            fee={feeOfferedByBuyer}
            showWaterfallProposalDueDate={showWaterfallProposalDueDate}
            waterfallProposalDueDate={waterfallProposalDueDate}
          />
        }
        showCaseManagerPermissionRequest={showCaseManagerPermissionRequest}
        showContactInformation={showContactInformation}
        showContactInformationInAccordion={showContactInformationInAccordion}
        showCounterpartContactInformation={showCounterpartContactInformation}
        showDataRoom={showDataRoom}
        showNegotiations={showNegotiations}
        showReferralContent={showReferralContent}
      />
    )
  }

  const buyerHasOfferOutToSeller =
    [
      String(CASE_PURCHASE_STATUS.Counteroffer),
      String(CASE_PURCHASE_STATUS.Proposed),
    ].includes(casePurchase?.status) && didBuyerTakeMostRecentAction

  const wasBuyerInvited =
    casePurchase?.created_by_attorney?.attorney_id === caseEntry.attorney_id

  let Action: ReactNode = null

  if (buyerHasOfferOutToSeller) {
    if (casePurchase && counterpart) {
      Action = (
        <AwaitingOfferResponseActions
          casePurchase={casePurchase}
          counterpart={counterpart}
          enableWithdraw={!wasBuyerInvited}
        />
      )
    }
  } else if (showAsFollowing) {
    Action = <WatchedReferralActions {...watchedReferralActionsProps} />
  } else {
    Action = <ProposalReceivedActions {...proposalReceivedActionsProps} />
  }

  return (
    <Grid container columnSpacing={2} rowSpacing={{ xs: 1.25, md: 0.75 }} p={2}>
      <Grid item xs={12} mb={{ xs: -0.5, md: 0 }}>
        <BuyerHeadline
          caseStatus={caseStatus}
          didBuyerTakeMostRecentAction={didBuyerTakeMostRecentAction}
          showWaterfallProposalDueDate={showWaterfallProposalDueDate}
          waterfallProposalDueDate={waterfallProposalDueDate}
        />
      </Grid>
      <ActionHeader
        Action={Action}
        fee={showAsFollowing ? originalFee : negotiatedFee}
        showCounterpart={!showAsFollowing}
      />
      {response.open && (
        <ResponseModal
          open={response.open}
          type={response.type}
          onClose={async () => {
            await refetch()
            setResponse({ open: false, type: null })
          }}
          variant={isClientSigningResponseModal ? 'standard' : 'outlined'}
          {...(isClientSigningResponseModal && { icon: false })}
        />
      )}
    </Grid>
  )
}

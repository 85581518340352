import { useCallback, useEffect, useState } from 'react'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'
import { useUpdateUserFileAccessRequestItemMutation } from '@/gql/appApi'
import { ACCESS_REQUEST_STATUS } from '@/constants'
import { CaseEntry } from '@/pages/MyReferralsV2/types'
import { isQualifiedMembership } from '@/utils/membership'

interface IUseDataroomRequests {
  dataroom: CaseEntry['data_room']
}

type ModalProps = {
  open: boolean
  type?: string
  title?: string
  description?: React.ReactNode
  buttonText?: string
  fileAccessId?: string
  targetUserName?: string
}

type ResponseModalProps = {
  open: boolean
  title?: string
  bodyContent?: React.ReactNode
}

const modalDefault: ModalProps = {
  open: false,
}

const responseModalDefault: ResponseModalProps = {
  open: false,
}

export type TUpdateRequests = {
  fileAccessId?: string
  targetUserName?: string
  hasAccess?: boolean
}

export type TAccessRequests = {
  id: string
  fileAccessId: string
  firstName?: string
  lastName?: string
  avatarId?: string | null
  isVetted: boolean
  orgName?: string
}

interface ErrorType {
  message: string
}

const baseError = 'An unexpected error occurred. Please try again later.'

const useDataroomRequests = ({ dataroom }: IUseDataroomRequests) => {
  const [error, setError] = useState<ErrorType | null>(null)

  const [updateFileAccessRequest, { loading: updateLoading }] =
    useUpdateUserFileAccessRequestItemMutation()

  const [modal, setModal] = useState<ModalProps>(modalDefault)
  const [responseModal, setResponseModal] =
    useState<ResponseModalProps>(responseModalDefault)

  const [requests, setRequests] = useState<TAccessRequests[]>([])

  useEffect(() => {
    if (dataroom) {
      const allRequests: TAccessRequests[] = dataroom.map(data => ({
        id: data.requested_case_id,
        fileAccessId: data.id,
        firstName: data.created_by_attorney?.first_name || '',
        lastName: data.created_by_attorney?.last_name || '',
        avatarId: data.created_by_attorney?.avatar || '',
        isVetted: data.created_by_attorney?.is_vetted || false,
        isQualified: isQualifiedMembership(
          data.created_by_attorney?.membership_type
        ),
        orgName: data.created_by_attorney?.organization_name || '',
      }))
      setRequests(allRequests)
    }
  }, [dataroom])

  const onUpdateAccess = useCallback(
    async (fileAccessId: string) => {
      const { type } = modal

      const getUpdateId = () => {
        switch (type) {
          case 'granted': {
            return ACCESS_REQUEST_STATUS.Granted
          }
          case 'denied': {
            return ACCESS_REQUEST_STATUS.Rejected
          }
          default:
            return ''
        }
      }

      try {
        const result = await updateFileAccessRequest({
          variables: {
            updateUserFileAccessRequestItemId: fileAccessId,
            data: {
              status: getUpdateId(),
            },
          },
        })
        if (result?.data?.update_user_file_access_request_item?.id) {
          return true
        }

        if (result?.errors) {
          const errorMessage = result.errors[0]?.message || baseError
          setError({ message: errorMessage })
        }
        return false
      } catch (err) {
        setError({ message: baseError })
        return false
      }
    },
    [modal, updateFileAccessRequest]
  )

  const onGiveAccess = ({ fileAccessId, targetUserName }: TUpdateRequests) => {
    setModal({
      open: true,
      type: 'granted',
      fileAccessId,
      targetUserName,
      title: 'Allow Data Room file access',
      description: (
        <Typography component="span" variant="subtitle1">
          Are you sure you want to grant access to the data room to{' '}
          {targetUserName}?
          <Typography mt={2}>
            If granted access, user will be able to view and download the files
            from the Data Room. Please note that you can remove their access
            anytime.
          </Typography>
        </Typography>
      ),
      buttonText: 'Yes, grant access',
    })
  }

  const onDenyAccess = ({
    fileAccessId,
    targetUserName,
    hasAccess,
  }: TUpdateRequests) => {
    setModal({
      open: true,
      type: hasAccess ? 'removed' : 'denied',
      fileAccessId,
      targetUserName,
      title: `${hasAccess ? 'Remove' : 'Deny'} Data Room files access`,
      description: (
        <Typography variant="subtitle1">
          Are you sure you want to {hasAccess ? 'remove' : 'deny'} access to the
          data room to {targetUserName}?
        </Typography>
      ),
      buttonText: `Yes, ${hasAccess ? 'remove' : 'deny'} access`,
    })
  }

  const onCloseModal = () => {
    setModal({ open: false })
  }

  const onSubmitModal = useCallback(async () => {
    const { type, fileAccessId } = modal

    const getDescription = () => {
      switch (type) {
        case 'granted': {
          return 'The user will now have the ability to view or download Data Room files. Please remember that you can revoke this permission at any time.'
        }
        case 'denied': {
          return 'The files related to the case are protected and can only be viewed by those users to whom you have granted access.'
        }
        default:
          return ''
      }
    }

    const result = await onUpdateAccess(fileAccessId || '')

    if (!result) {
      onCloseModal()
      return
    }

    // Remove request from list to have an optimistic UI.
    const updatedRequests = requests.filter(
      req => req.fileAccessId !== fileAccessId
    )
    setRequests(updatedRequests)

    setModal({ open: false })
    setResponseModal({
      open: true,
      title: `Data Room access ${type}`,
      bodyContent: (
        <>
          <AlertTitle>
            You have successfully {type} access to Data Room
          </AlertTitle>
          {getDescription()}
        </>
      ),
    })
  }, [modal, onUpdateAccess, requests])

  const onCloseResponseModal = () => {
    setResponseModal({ open: false })
  }

  return {
    requests,
    modal,
    setModal,
    responseModal,
    setResponseModal,
    onGiveAccess,
    onDenyAccess,
    onCloseModal,
    onSubmitModal,
    onCloseResponseModal,
    loading: updateLoading,
    error,
    setError,
  }
}

export default useDataroomRequests

import { useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import LockIcon from '@mui/icons-material/Lock'
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useSizes } from '@/hooks/useSizes'
import { Link, generatePath } from 'react-router-dom'
import { RoutesData } from '@/router/RoutesData'
import ReferralStatusBar from './components/ReferralStatusBar'
import CaseUsers from './components/CaseUsers'
import CardFee from './components/CardFee'
import { IJReferralCardProps } from './types'
import InviteToCaseButton from './components/InviteToCaseButton'

const JReferralCard = ({
  caseId,
  title,
  fee,
  referralType,
  expirationTime,
  datePosted,
  isPrivate,
  onGoToCaseProfile,
  showInviteToCase,
  showStatusBar,
  onStopWatching,
  users,
  dataroomRequest,
  loading,
  bothPartiesPaid,
  representingAttorneyId,
  casePracticeArea,
  jurisdiction,
  children,
}: IJReferralCardProps) => {
  const theme = useTheme()
  const { isLessMd } = useSizes()
  const [expanded, setExpanded] = useState(true)

  const handleExpandClick = () => {
    setExpanded(exp => !exp)
  }

  const path = generatePath(RoutesData.RCase.path, {
    id: caseId,
  })

  const renderCaseType = useCallback(() => {
    const caseType = isPrivate ? 'Waterfall Referral™' : 'Marketplace'
    return (
      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {isPrivate ? (
          <LockIcon sx={{ height: '12px', width: '12px', mr: 0.5 }} />
        ) : (
          <LockOpenIcon sx={{ height: '12px', width: '12px', mr: 0.5 }} />
        )}
        <Typography variant="caption">{caseType}</Typography>
        {isLessMd && (
          <Typography
            variant="caption"
            sx={{
              ml: 1,
              color: theme.palette.text.secondary,
            }}
          >
            {datePosted}
          </Typography>
        )}
      </Grid>
    )
  }, [datePosted, isLessMd, isPrivate, theme.palette.text.secondary])

  const typeAndJurisdictionText = [jurisdiction, casePracticeArea]
    .filter(Boolean)
    .join(', ')

  return (
    <Card sx={{ margin: '1rem 0' }}>
      {showStatusBar && (
        <ReferralStatusBar
          referralType={referralType}
          expirationTime={expirationTime}
          dataroomRequest={dataroomRequest}
        />
      )}
      <CardContent>
        {typeAndJurisdictionText && (
          <Typography
            variant="caption"
            sx={{
              color: theme.palette.text.secondary,
            }}
          >
            {typeAndJurisdictionText}
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            my: 2,
          }}
        >
          <Link
            to={path}
            style={{
              textDecoration: 'none',
            }}
          >
            <Typography variant="h6">{title}</Typography>
          </Link>
          {!isLessMd && (
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.text.secondary,
              }}
            >
              {datePosted}
            </Typography>
          )}
        </Box>
        <Grid container columnGap={2}>
          {renderCaseType()}
          {fee && (
            <CardFee
              fee={fee}
              referralType={referralType}
              bothPartiesPaid={bothPartiesPaid}
            />
          )}
          <CaseUsers users={users || []} />
        </Grid>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent
          sx={{
            p: 0,
          }}
        >
          {children}
        </CardContent>
      </Collapse>
      <CardActions
        sx={{
          py: { xs: 1, md: 1.5 },
          borderTop: 1,
          borderColor: theme.palette.divider,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <Button
          size="small"
          color="primary"
          startIcon={
            expanded ? (
              <KeyboardArrowUpIcon
                sx={{
                  height: '16px',
                }}
              />
            ) : (
              <KeyboardArrowDownIcon
                sx={{
                  height: '16px',
                }}
              />
            )
          }
          onClick={handleExpandClick}
          sx={{
            m: 1,
          }}
        >
          {expanded ? 'Hide Details' : 'Show Details'}
        </Button>
        {onStopWatching && (
          <Button
            size="small"
            color="error"
            startIcon={
              <VisibilityOff
                sx={{
                  height: '16px',
                }}
              />
            }
            onClick={onStopWatching}
            disabled={loading}
            sx={{
              m: 1,
            }}
          >
            Stop Watching
          </Button>
        )}
        {showInviteToCase && (
          <InviteToCaseButton
            caseId={caseId}
            name={title}
            loading={loading}
            representingAttorneyId={representingAttorneyId}
          />
        )}
        {onGoToCaseProfile && (
          <Link
            to={path}
            style={{
              textDecoration: 'none',
            }}
          >
            <Button
              size="small"
              color="primary"
              endIcon={
                <CallMadeOutlinedIcon
                  sx={{
                    height: '16px',
                  }}
                />
              }
              disabled={loading}
              sx={{
                m: 1,
              }}
            >
              Go to Case Profile
            </Button>
          </Link>
        )}
      </CardActions>
    </Card>
  )
}

export default JReferralCard

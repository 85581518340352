import { datadogRum } from '@datadog/browser-rum'

const environment = import.meta.env.VITE_VERCEL_ENV ?? 'development'

export const initializeDatadogRUM = () => {
  datadogRum.init({
    applicationId: 'b730baee-e2d4-4882-bf80-2141e2bffe27',
    clientToken: 'pubf8ff5a5142b011b643d6d66d6053430d',
    site: 'us3.datadoghq.com',
    service: 'attorney-share-marketplace',
    env: environment,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    version: import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA,
    defaultPrivacyLevel: 'mask-user-input',
  })
}

import { useCallback, useEffect, useMemo, useState } from 'react'
import { JCaseType, MODAL_TYPES, ModalTypes } from '@/types'
import {
  CASE_PURCHASE_STATUS,
  CASE_ACCESS_TYPES,
  IMAGE_SIZES,
  CASE_STATUS_FLOW,
  PAYMENT_METHOD,
} from '@/constants'
import {
  Case_Purchase,
  Case_Purchase_Activity,
  GetActivityPurchaseDocument,
  GetActivityPurchaseFromMyOrgDocument,
  GetCasesDocument,
  IsDeletedQueryHookResult,
  useIsDeletedQuery,
} from '@/gql/appApi'
import get from 'lodash/get'
import { isQualifiedMembership } from '@/utils/membership'
import { useMatchFeesModal } from '@/components/Modals/MatchFeesModal'
import { getImagePath } from '@/utils'
import { isNil } from 'lodash'
import { useFeatureFlags } from '@/optimizely/useFeatureFlags'
import { FeatureFlag } from '@/optimizely/types'
import { MpPreferences, AccessType } from '../types'
import { useCaseData } from './useJCaseData'
import useOnAcceptAction from './useOnAcceptAction'
import usePurchaseAgreements from './usePurchaseAgreements'
import { caseMessages, caseTitles } from './constants'
import { FormattedCaseProfile } from '../components/Sidebar/CaseSidebar/types'

const formatCaseProfile = (
  attorneyIdObj: any,
  isProspect?: boolean
): FormattedCaseProfile => {
  const attorneyProfile = attorneyIdObj?.profiles?.[0]
  const userId =
    attorneyIdObj?.user_id || attorneyIdObj?.profiles?.[0]?.attorney_id?.user_id
  return {
    id: !isProspect ? attorneyIdObj?.id : attorneyProfile?.attorney_id?.id,
    firstName: attorneyProfile?.first_name,
    lastName: attorneyProfile?.last_name,
    isVetted: !isProspect
      ? attorneyIdObj?.is_vetted
      : attorneyProfile?.attorney_id?.is_vetted,
    isQualified: isQualifiedMembership(
      !isProspect
        ? attorneyIdObj?.membership_type?.id
        : attorneyProfile?.attorney_id?.membership_type?.id
    ),
    avatar: userId?.avatar?.id,
    organization: get(
      userId,
      'current_organization[0].organization_id.profiles[0].name',
      ''
    ),
    userId: userId?.id || null,
  }
}

export const useCase = () => {
  const featureFlags = useFeatureFlags()
  const isCaseStatusFlowSimplificationEnabled =
    featureFlags[FeatureFlag.CASE_STATUS_FLOW_SIMPLIFICATION]

  const {
    dataCase,
    getCaseDataLoading,
    refetchCaseData,
    preferences,
    preferencesLoading,
    isVetted,
    isQualified,
    activeAttorneyId,
    caseId,
    refetchPreferences,
    deleteCaseMpPreferenceItemMutation,
    createCaseActivityPurchaseMutation,
    createCaseMpPreferenceMutation,
    checkCaseExists,
    loadingCreateCaseMutation,
    isPrivateCase,
    isCaseFromSomeoneInMyOrganization,
    isAdminAttorney,
    isCaseManager,
    currentUserAttorneyId,
    loggedUserId,
    loggedUserOrganizationId,
    caseIsTakenByMyOrg,
    hasActivity,
    optionsLoading,
    caseStatus,
  } = useCaseData()

  const [waterfallCasePurchase, setWaterfallCasePurchase] = useState<
    Case_Purchase | undefined
  >()

  const isNonVettedAdminAttorney = isAdminAttorney && !isVetted && !isQualified

  const caseOwnerWithOrg = useMemo(
    () =>
      formatCaseProfile(
        get(
          dataCase,
          'case_purchase_activity[0].case_purchase_id.case_id.attorney_id'
        )
      ),
    [dataCase]
  )

  const prospectAttorneyProfile = useMemo(
    () =>
      formatCaseProfile(
        get(
          dataCase,
          'case_purchase_activity[0].case_purchase_id.prospect_attorney_id'
        ),
        true
      ),
    [dataCase]
  )

  const currentUserIsActivityOwner =
    activeAttorneyId ===
    dataCase?.case_purchase_activity?.[0]?.created_by?.profiles?.[0]
      ?.attorney_id?.id

  const status = dataCase
    ? dataCase?.case_purchase_activity?.[0].activity_entry_status?.name
    : 'Proposed'

  const { getPurchaseAgreementParams, getLeads, currentUserHasPaid } =
    usePurchaseAgreements({
      activeAttorneyId,
      isCaseStatusFlowSimplificationEnabled,
    })

  const activityEntryStatusId =
    dataCase?.case_purchase_activity?.[0]?.activity_entry_status?.id

  const acceptedCase =
    activityEntryStatusId &&
    activityEntryStatusId === CASE_PURCHASE_STATUS.Accept

  const isWithdrawn = activityEntryStatusId === CASE_PURCHASE_STATUS.Withdrawn

  const isMatched = caseStatus === CASE_STATUS_FLOW.Matched

  const isSigned = caseStatus === CASE_STATUS_FLOW.Signed

  const generateAgreements =
    acceptedCase ||
    ((isMatched || isSigned) &&
      dataCase?.case_purchase_activity?.[0] &&
      isCaseStatusFlowSimplificationEnabled)

  const agreementParams = useMemo(() => {
    if (!generateAgreements) return null
    return getPurchaseAgreementParams(dataCase?.case_purchase_activity?.[0])
  }, [dataCase, getPurchaseAgreementParams, generateAgreements])
  const [accessType, setAccessType] = useState<AccessType>(null)
  const [headerPriorityText, setHeaderPriorityText] = useState<string>('')
  const [dataRoomModal, showDataRoomModal] = useState<boolean>(false)
  const [sendProposalModal, showSendProposalModal] = useState<boolean>(false)
  const [nonVettedModal, showNonVettedModal] = useState<boolean>(false)
  const [isLoadingPayment, setIsLoadingPayment] = useState<boolean>(false)
  const [inviteToCaseModalOpen, setInviteToCaseModalOpen] =
    useState<boolean>(false)
  const [responseModal, setResponseModal] = useState<{
    type: ModalTypes
    open: boolean
  }>({
    type: null,
    open: false,
  })
  const [modalOpen, setOpenModal] = useState<{
    open: boolean
    jCase: JCaseType | null
    fee: number | null
  }>({ open: false, jCase: null, fee: null })
  const [dataRoomModalOpen, setDataRoomOpenModal] = useState<{
    open: boolean
    jCase: JCaseType | null
  }>({ open: false, jCase: null })
  const [caseErrorModalOpen, setCaseErrorModalOpen] = useState<boolean>(false)
  const [signedModalOpen, setSignedModalOpen] = useState<boolean>(false)
  const [rejectedModalOpen, setRejectedModalOpen] = useState<boolean>(false)

  // Checks if the current user (Attorney, Admin or Case Manager) is on the owner side of the case (not necessarily the actual case owner)
  const isCaseOwner = !!dataCase?.case_purchase_activity?.some(
    (f: Case_Purchase_Activity) =>
      f?.case_purchase_id?.case_id?.attorney_id?.id === activeAttorneyId
  )

  // The difference between this and isCaseOwner is that this one checks if the user is the actual direct owner of the case
  const isAttorneyOwner =
    (accessType === CASE_ACCESS_TYPES.Owner &&
      !isAdminAttorney &&
      !isCaseManager &&
      currentUserAttorneyId === activeAttorneyId) ||
    (accessType === CASE_ACCESS_TYPES.Owner &&
      isAdminAttorney &&
      currentUserAttorneyId === activeAttorneyId)

  const isProspectAttorney =
    dataCase?.case_purchase_activity?.[0]?.case_purchase_id
      ?.prospect_attorney_id?.profiles?.[0]?.attorney_id?.id ===
    activeAttorneyId

  const iamProspectAttorneyInExternalCase =
    !isCaseOwner && !isCaseFromSomeoneInMyOrganization && isProspectAttorney

  const hasCaseManagerAccess = isCaseOwner
    ? // Checks the ownership table of the Case
      dataCase?.case_purchase_activity?.[0]?.case_purchase_id?.case_id?.ownerships?.some(
        ownership => ownership?.user_id?.id === loggedUserId
      ) && isCaseManager
    : // Checks the ownership table of the Case Purchase
      dataCase?.case_purchase_activity?.[0]?.case_purchase_id?.ownerships?.some(
        ownership => ownership?.user_id?.id === loggedUserId
      ) && isCaseManager

  const hasAdminAccess = useMemo(
    () => isAdminAttorney && currentUserAttorneyId !== activeAttorneyId,
    [isAdminAttorney, currentUserAttorneyId, activeAttorneyId]
  )

  const isCaseManagerWithNoAccess = isCaseManager && !hasCaseManagerAccess
  const isCaseManagerWithAccess = Boolean(isCaseManager && hasCaseManagerAccess)

  // Combination of conditions to show the Add Case Managers button and part of the conditions to show Agreements
  const showAllowCaseManagerActions =
    !caseIsTakenByMyOrg &&
    (isAttorneyOwner ||
      (isPrivateCase && !isCaseManager && !hasAdminAccess) ||
      (hasAdminAccess &&
        (accessType === CASE_ACCESS_TYPES.Owner ||
          iamProspectAttorneyInExternalCase)) ||
      hasCaseManagerAccess ||
      (iamProspectAttorneyInExternalCase &&
        activeAttorneyId === currentUserAttorneyId))

  const prospectId =
    dataCase?.case_purchase_activity?.[0]?.case_purchase_id
      ?.prospect_attorney_id?.profiles?.[0]?.attorney_id?.id

  const unavailableCasePurchaseStatus: string[] = [
    CASE_PURCHASE_STATUS.DeclineAndRejected,
    CASE_PURCHASE_STATUS.Dismissed,
    CASE_PURCHASE_STATUS.Expired,
    CASE_PURCHASE_STATUS.Reject,
  ]

  const isRejectedDismissedOrDeclinedAndRejected =
    unavailableCasePurchaseStatus.includes(activityEntryStatusId || '')

  const hasWaterfallAccessAndIsRejected =
    accessType === CASE_ACCESS_TYPES.Waterfall &&
    isRejectedDismissedOrDeclinedAndRejected

  const isProspectAndRejected =
    isProspectAttorney && isRejectedDismissedOrDeclinedAndRejected

  const isExpired = caseStatus === CASE_STATUS_FLOW.Expired

  const isCaseStatusRejected = caseStatus === CASE_STATUS_FLOW.Rejected

  const unavailableCaseStatus =
    isMatched || isSigned || isExpired || isCaseStatusRejected

  const isOwnerWithDeclinedAndRejected =
    isCaseOwner &&
    activityEntryStatusId === CASE_PURCHASE_STATUS.DeclineAndRejected

  // Case is no longer available when another attorney's proposal was accepted.
  // i.e. case was matched or signed with another attorney outside your organization
  const caseNotAvailable = Boolean(
    ((prospectId || prospectId !== null) &&
      activeAttorneyId &&
      !optionsLoading &&
      !getCaseDataLoading &&
      unavailableCaseStatus &&
      !isCaseOwner &&
      (accessType === CASE_ACCESS_TYPES.NoAccess ||
        hasWaterfallAccessAndIsRejected) &&
      (!isProspectAttorney || isProspectAndRejected) &&
      !caseIsTakenByMyOrg) ||
      // A case is no longer available (even for the case owner) if the prospect marked the case
      // as DeclinedAndRejected (did not sign the client and case is not re-listed to Marketplace)
      isOwnerWithDeclinedAndRejected
  )

  const leads = caseNotAvailable
    ? undefined
    : getLeads({
        caseActivity: dataCase?.case_purchase_activity?.[0],
        isCaseManagerWithNoAccess,
        isCaseOwner,
        finalizedAgreement: !!agreementParams?.finalizedAgreement,
      })

  /**
   * If the case is in the `PendingPaymentAuthorization` status, the following data is needed
   * to send the correct information to the proposal/payment modal:
   * - The `attorney_id` of the user who created the activity (or was created on their behalf)
   * - The `user_id` of the attorney who created the activity (or was created on their behalf)
   * - The `fee` sent in the activity when the proposal was created (could have proposed a different fee than the case's fee).
   */
  const isPendingPayment =
    activityEntryStatusId ===
      CASE_PURCHASE_STATUS.PendingPaymentAuthorization && !isCaseOwner

  const onBehalfOfUserId =
    dataCase?.case_purchase_activity?.[0]?.case_purchase_id
      ?.prospect_attorney_id?.profiles?.[0]?.attorney_id?.user_id?.id

  let onBehalfOfAttorneyIdPendingPayment: string | undefined
  let onBehalfOfUserIdPendingPayment: string | undefined
  let pendingPaymentFee: number | undefined

  if (isPendingPayment) {
    onBehalfOfAttorneyIdPendingPayment =
      dataCase?.case_purchase_activity?.[0]?.created_by?.profiles?.[0]
        ?.attorney_id?.id
    onBehalfOfUserIdPendingPayment =
      dataCase?.case_purchase_activity?.[0]?.created_by?.profiles?.[0]
        ?.attorney_id?.user_id?.id
    pendingPaymentFee = dataCase?.case_purchase_activity?.[0]?.fee
  }

  /** The criteria to show the 'Yes, Client Signed' and 'No, Rejected' buttons are:
   * - The case is in the 'Matched' status.
   * - The user is the prospect attorney in an external case.
   * - The Feature Flag is enabled
   */
  const showClientSigningConfirmation =
    isCaseStatusFlowSimplificationEnabled &&
    iamProspectAttorneyInExternalCase &&
    isMatched

  const handleClientSignedSubmit = ({
    responseType,
  }: {
    responseType: ModalTypes
  }) => {
    setSignedModalOpen(false)
    setResponseModal({
      type: responseType,
      open: true,
    })
  }

  const handleClientRejectedSubmit = ({
    responseType,
  }: {
    responseType: ModalTypes
  }) => {
    setRejectedModalOpen(false)
    setResponseModal({
      type: responseType,
      open: true,
    })
  }

  const getTitle = useCallback(() => {
    if (isCaseManagerWithNoAccess) {
      return ''
    }
    if (agreementParams) {
      return 'You have reached an agreement for this case'
    }
    if (status !== 'Proposed') {
      if (status === 'Accepted') {
        return 'You have accepted this referral'
      }
      if (status === 'Rejected') {
        return currentUserIsActivityOwner
          ? 'You have rejected the proposal'
          : `Your proposal has been rejected`
      }
      if (status === 'PendingPaymentAuthorization' && isPendingPayment) {
        return 'You have a pending payment authorization'
      }
      if (
        status === 'DeclinedAndAvaliable' ||
        status === 'DeclinedAndRejected'
      ) {
        return 'You have declined this case'
      }
      const msg = currentUserIsActivityOwner
        ? 'You have sent a'
        : 'You have received a'
      return `${msg} ${status}`
    }
    if (accessType === CASE_ACCESS_TYPES.Invited)
      return 'You have been invited to this case'
    if (accessType === CASE_ACCESS_TYPES.Waterfall)
      return 'You have Priority Access to this case'
    return ''
  }, [
    isCaseManagerWithNoAccess,
    status,
    accessType,
    currentUserIsActivityOwner,
    agreementParams,
    isPendingPayment,
  ])

  const validateAccessType = useCallback(() => {
    if (dataCase && dataCase.case_purchase_activity.length > 0) {
      const isInvited = dataCase.case_purchase_activity.find(
        (f: Case_Purchase_Activity) =>
          f?.case_purchase_id?.prospect_attorney_id?.profiles?.[0]?.attorney_id
            ?.id === activeAttorneyId &&
          f?.case_purchase_id?.created_by?.profiles?.[0]?.attorney_id?.id !==
            activeAttorneyId
      )

      if (isPrivateCase) {
        const waterfallCasePurchaseActivity =
          dataCase.case_purchase_activity.find(
            (f: Case_Purchase_Activity) =>
              f?.case_purchase_id?.prospect_attorney_id?.profiles?.[0]
                ?.attorney_id?.id === activeAttorneyId
          )

        if (waterfallCasePurchaseActivity) {
          setWaterfallCasePurchase(
            waterfallCasePurchaseActivity?.case_purchase_id ?? undefined
          )

          setAccessType('waterfall')

          return
        }
      }

      if (isCaseOwner) {
        setAccessType('owner')
        return
      }

      if (isInvited && !isCaseOwner && isProspectAttorney) {
        setAccessType('invited')
      }
    }
  }, [
    dataCase,
    isPrivateCase,
    isCaseOwner,
    isProspectAttorney,
    activeAttorneyId,
  ])

  useEffect(() => {
    if (accessType && accessType !== CASE_ACCESS_TYPES.Owner) {
      setHeaderPriorityText(getTitle())
    }
  }, [accessType, getTitle])

  useEffect(() => {
    validateAccessType()
  }, [validateAccessType])

  const getModalTypeByAccessType = () => {
    switch (accessType) {
      case 'invited':
        return 'Invited'
      case 'waterfall':
        return 'Referral'
      default:
        return 'Proposal'
    }
  }

  const paymentStatus = {
    bothPartiesPaid: !!agreementParams?.bothPartiesPaid,
    currentUserPaid: agreementParams?.currentUserWithValidPayment || false,
    counterpartPaid: !!(
      agreementParams?.hasPayment &&
      !agreementParams?.currentUserWithValidPayment
    ),
  }

  const qualifiesForInvitedPricing =
    accessType === CASE_ACCESS_TYPES.Invited ||
    accessType === CASE_ACCESS_TYPES.Waterfall

  const currentUserWithValidPayment = currentUserHasPaid({
    agreement: dataCase?.case_purchase_activity?.[0],
    hasPayment:
      (dataCase?.case_purchase_activity?.[0]?.case_purchase_id?.payments
        ?.length ?? 0) > 0,
  })

  /**
   * With the new case status flow, the user can accept the proposal without paying if:
   * - The user is the case owner
   * - The user is the invited attorney
   * - The user submitted a prepaid proposal (so no additional payment is required)
   */
  const noPaymentRequired =
    (qualifiesForInvitedPricing ||
      isCaseOwner ||
      !!currentUserWithValidPayment) &&
    !!isCaseStatusFlowSimplificationEnabled

  const { onSubmitAcceptAction } = useOnAcceptAction({
    caseId,
    casePurchaseId:
      dataCase?.case_purchase_activity?.[0]?.case_purchase_id?.id ?? '',
    representingAttorneyId: activeAttorneyId,
    userId: loggedUserId,
    isCaseOwner,
    setCaseErrorModalOpen,
    paymentStatus,
    isInvited: qualifiesForInvitedPricing,
    noPaymentRequired,
    fee:
      (!isNil(dataCase?.case_purchase_activity[0].fee)
        ? dataCase?.case_purchase_activity[0].fee
        : dataCase?.case_purchase_activity[0].case_purchase_id?.case_id?.fee) ??
      0,
  })

  const onSubmit = async (fee?: number, message?: string) => {
    const caseExistsResult = await checkCaseExists()
    if (!caseExistsResult.data?.case?.[0]?.id) {
      setOpenModal({ open: false, jCase: null, fee: null })
      setCaseErrorModalOpen(true)
      return
    }
    if (accessType === CASE_ACCESS_TYPES.Invited) {
      const casePurchaseId =
        dataCase?.case_purchase_activity?.[0]?.case_purchase_id?.id
      const data = {
        fee:
          fee !== undefined && fee !== null
            ? fee
            : dataCase?.case_purchase_activity?.[0]?.fee ?? 0,
        message,
        activity_entry_status: CASE_PURCHASE_STATUS.Counteroffer,
        created_by: activeAttorneyId,
        case_purchase_id: {
          id: casePurchaseId,
        },
      }
      await createCaseActivityPurchaseMutation({
        variables: { data },
        refetchQueries: [
          GetActivityPurchaseDocument,
          'case_purchase_activity',
          GetCasesDocument,
          'case',
          GetActivityPurchaseFromMyOrgDocument,
          'case_purchase_activity',
        ],
      })
    }
    setOpenModal({ open: false, jCase: null, fee: null })
    setResponseModal({
      type: 'Send',
      open: true,
    })
  }
  const onSubmitAction = (type: ModalTypes, fee: number) => {
    const casePurchaseId =
      dataCase?.case_purchase_activity?.[0]?.case_purchase_id?.id
    const data = {
      fee,
      activity_entry_status:
        type === 'Reject'
          ? CASE_PURCHASE_STATUS.Reject
          : CASE_PURCHASE_STATUS.Counteroffer,
      created_by: activeAttorneyId,
      case_purchase_id: {
        id: casePurchaseId,
      },
    }
    return createCaseActivityPurchaseMutation({
      variables: { data },
      refetchQueries: [
        GetActivityPurchaseDocument,
        'case_purchase_activity',
        GetCasesDocument,
        'case',
        GetActivityPurchaseFromMyOrgDocument,
        'case_purchase_activity',
      ],
    })
  }

  let firms: string[] = []

  if (isCaseOwner) {
    firms = [prospectAttorneyProfile?.organization]
  } else {
    firms = [caseOwnerWithOrg?.organization]
  }

  const {
    getConfirmationModalProps,
    getCaseInformationProps,
    setOpenModal: setGenericOpenModal,
    modalOpen: genericModalOpen,
    type: modalType,
    stripePriceId,
    discount,
    credits,
  } = useMatchFeesModal({
    type: getModalTypeByAccessType(),
    fullname: isCaseOwner
      ? `${prospectAttorneyProfile?.firstName} ${prospectAttorneyProfile?.lastName}`
      : `${caseOwnerWithOrg?.firstName} ${caseOwnerWithOrg?.lastName}`,
    firms,
    caseTitle:
      dataCase?.case_purchase_activity[0].case_purchase_id?.case_id?.name ?? '',
    fee:
      (!isNil(dataCase?.case_purchase_activity[0].fee)
        ? dataCase?.case_purchase_activity[0].fee
        : dataCase?.case_purchase_activity[0].case_purchase_id?.case_id?.fee) ??
      0,
    isCaseOwner: accessType === CASE_ACCESS_TYPES.Owner,
    avatar: getImagePath({
      id: isCaseOwner
        ? prospectAttorneyProfile?.avatar
        : caseOwnerWithOrg?.avatar,
      size: IMAGE_SIZES.Small,
    }),
    isVetted: isCaseOwner
      ? prospectAttorneyProfile?.isVetted
      : caseOwnerWithOrg?.isVetted,
    isQualified: isCaseOwner
      ? prospectAttorneyProfile?.isQualified
      : caseOwnerWithOrg?.isQualified,
    caseId,
    paymentStatus,
    agreement: !!agreementParams,
    isInvited: qualifiesForInvitedPricing,
    isPrivate: isPrivateCase,
    noPaymentRequired,
    onBehalfOfUserId,
  })

  const onSubmitGenericModal = async (form: any) => {
    const caseExistsResult = await checkCaseExists()
    if (!caseExistsResult.data?.case?.[0]?.id) {
      setGenericOpenModal(null)
      setCaseErrorModalOpen(true)
      return
    }
    const mType = modalType
    if (mType === 'Accept') {
      setGenericOpenModal(null)
      setIsLoadingPayment(true)
      const result = await onSubmitAcceptAction({
        stripePriceId: stripePriceId || '',
        credits: credits || '',
        discount,
      })
      if (result === null) {
        setResponseModal({ type: 'Error', open: true })
        setIsLoadingPayment(false)
        return
      }
      if (typeof result === 'object') {
        setIsLoadingPayment(false)
        setResponseModal({
          type: result.type as ModalTypes,
          open: result.open,
        })
      }
    } else {
      await onSubmitAction(
        mType,
        Number(
          form.fee !== undefined && form.fee !== null
            ? form.fee
            : dataCase?.case_purchase_activity[0].case_purchase_id?.case_id?.fee
        )
      )
      setGenericOpenModal(null)
      setResponseModal({
        type: mType,
        open: true,
      })
    }
  }

  const {
    data: isDeletedData,
    loading: isDeletedLoading,
  }: IsDeletedQueryHookResult = useIsDeletedQuery({
    variables: {
      filter: {
        id: {
          _eq: caseId,
        },
      },
    },
  })

  const deletePreference = useCallback(
    async (preferenceId: string) => {
      const { data } = await deleteCaseMpPreferenceItemMutation({
        variables: {
          deleteAttorneyCaseMpPreferenceItemId: preferenceId,
        },
      })

      if (data) {
        refetchPreferences()
      }
    },
    [deleteCaseMpPreferenceItemMutation, refetchPreferences]
  )

  const createPreference = useCallback(
    async (preferenceId: MpPreferences) => {
      const { data } = await createCaseMpPreferenceMutation({
        variables: {
          data: {
            case_id: caseId,
            case_mp_preference_id: preferenceId,
          },
        },
      })

      if (data) {
        refetchPreferences()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createCaseMpPreferenceMutation, refetchPreferences]
  )

  const onRequestDataRoomAccessClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault()
    if (!isVetted) {
      showNonVettedModal(true)
    } else {
      setDataRoomOpenModal({
        open: true,
        jCase: {
          id:
            dataCase?.case_purchase_activity?.[0].case_purchase_id?.case_id
              ?.id ?? '',
          name:
            dataCase?.case_purchase_activity?.[0].case_purchase_id?.case_id
              ?.name ?? '',
        },
      })
    }
  }

  const onSendProposalClick = (
    e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>
  ) => {
    e.preventDefault()

    if (accessType === CASE_ACCESS_TYPES.NoAccess && !isVetted) {
      showNonVettedModal(true)
    }

    if (accessType === CASE_ACCESS_TYPES.NoAccess && isVetted) {
      showSendProposalModal(true)
    }

    if (accessType === CASE_ACCESS_TYPES.Waterfall) {
      showSendProposalModal(true)
    }

    if (accessType === CASE_ACCESS_TYPES.Invited) {
      showSendProposalModal(true)
    }
  }

  const rejectedByAttorneyInvolved =
    currentUserIsActivityOwner &&
    status === 'Rejected' &&
    activeAttorneyId === currentUserAttorneyId

  const rejectedByCounterpart =
    !currentUserIsActivityOwner &&
    status === 'Rejected' &&
    activeAttorneyId === currentUserAttorneyId

  const actionTitle = useMemo(() => {
    if (isCaseManagerWithNoAccess && hasActivity) {
      return caseTitles.noPermission
    }

    if (caseIsTakenByMyOrg && !rejectedByAttorneyInvolved) {
      return caseTitles.noProposals
    }

    if (agreementParams) {
      if (isCaseStatusFlowSimplificationEnabled) {
        if (isMatched) {
          return 'Congrats! You Reached an Agreement'
        }
        if (isSigned) {
          return null
        }
      }
      if (agreementParams.bothPartiesPaid) return 'You reached an agreement'
      if (agreementParams.currentUserWithValidPayment)
        return 'Proposal Accepted'
      if (
        agreementParams.hasPayment &&
        !agreementParams.currentUserWithValidPayment
      )
        return isCaseOwner
          ? 'One step away from reaching an agreement'
          : 'Last step before getting the case!'
      return ''
    }

    const isAdminOrManagerWithAccess =
      (isAdminAttorney || isCaseManagerWithAccess) &&
      isCaseFromSomeoneInMyOrganization

    if (isAdminOrManagerWithAccess)
      return isPrivateCase ? caseTitles.waterfall : caseTitles.promote
    if (isCaseManager && isCaseFromSomeoneInMyOrganization)
      return caseTitles.noPermission

    if (
      isCaseFromSomeoneInMyOrganization &&
      accessType === CASE_ACCESS_TYPES.NoAccess
    )
      return caseTitles.noProposals

    if (iamProspectAttorneyInExternalCase && status !== 'Proposed') {
      if (status === 'Rejected') {
        return currentUserIsActivityOwner
          ? 'You have rejected the proposal'
          : 'Your proposal has been rejected'
      }
      if (status === 'PendingPaymentAuthorization' && isPendingPayment) {
        return 'You have a pending payment authorization'
      }
      // TODO: We should refactor and stop using the status name to identify the action.
      // We should use the `id` instead. I had to use `DeclinedAndAvaliable` mispelled to match the status name.
      if (
        status === 'DeclinedAndAvaliable' ||
        status === 'DeclinedAndRejected'
      ) {
        return 'You have declined this case'
      }

      if (!currentUserIsActivityOwner && status === 'Counteroffer') {
        return null
      }

      if (isWithdrawn) {
        return caseTitles.proposalWithdrawn
      }

      const msg = currentUserIsActivityOwner
        ? 'You have sent a'
        : 'You have received a'
      return `${msg} ${status}`
    }

    if (isPrivateCase) {
      if (accessType === CASE_ACCESS_TYPES.Owner) return caseTitles.waterfall
      if (isCaseManagerWithNoAccess) return caseTitles.noPermission
    }

    if (iamProspectAttorneyInExternalCase && status === 'Proposed') {
      const msg = currentUserIsActivityOwner
        ? 'You have sent a'
        : 'You have received a'
      return `${msg} Proposal`
    }

    return accessType === CASE_ACCESS_TYPES.Owner
      ? caseTitles.promote
      : 'Get this case'
  }, [
    isCaseManagerWithNoAccess,
    agreementParams,
    isAdminAttorney,
    isCaseManager,
    isCaseFromSomeoneInMyOrganization,
    isPrivateCase,
    accessType,
    iamProspectAttorneyInExternalCase,
    status,
    currentUserIsActivityOwner,
    caseIsTakenByMyOrg,
    rejectedByAttorneyInvolved,
    hasActivity,
    isCaseOwner,
    isCaseManagerWithAccess,
    isMatched,
    isSigned,
    isCaseStatusFlowSimplificationEnabled,
    isPendingPayment,
    isWithdrawn,
  ])

  const actionSubtitle = useMemo(() => {
    if (isWithdrawn) {
      return caseMessages.proposalWithdrawn
    }

    if (isCaseManagerWithNoAccess && hasActivity) {
      return caseMessages.caseManagerAskPermission
    }

    if (caseIsTakenByMyOrg && !rejectedByAttorneyInvolved) {
      return caseMessages.caseTakenByMyOrg
    }

    if (agreementParams) {
      if (isCaseStatusFlowSimplificationEnabled) {
        if (isMatched) {
          return iamProspectAttorneyInExternalCase
            ? `The client's contact info is below. Please reach out to them as soon as possible.`
            : `Your counterpart now has access to the leads' contact information. We will keep you updated on whether they can sign the client.`
        }
        if (isSigned) {
          return null
        }
      }
      if (agreementParams.bothPartiesPaid)
        return "Both parts have accepted their counterpart proposals and the leads' information is shared. Reach out to the other lawyer to proceed with further arrangements."
      if (agreementParams.currentUserWithValidPayment)
        return 'Waiting on the other party to accept.'
      if (
        agreementParams.hasPayment &&
        !agreementParams.currentUserWithValidPayment
      )
        return qualifiesForInvitedPricing || isCaseOwner
          ? 'To reach an agreement, accept the proposal.'
          : "Click Reach Agreement to complete the transaction and get the potential client's contact information."
      return ''
    }

    const isAdminOrManagerWithAccess =
      (isAdminAttorney || isCaseManagerWithAccess) &&
      isCaseFromSomeoneInMyOrganization

    if (isAdminOrManagerWithAccess)
      return isPrivateCase ? '' : caseMessages.spam
    if (isCaseManager && isCaseFromSomeoneInMyOrganization)
      return caseMessages.caseManagerAskPermission

    if (
      isCaseFromSomeoneInMyOrganization &&
      accessType === CASE_ACCESS_TYPES.NoAccess
    )
      return 'This case was submitted by an Attorney of your own Law Firm.'

    if (iamProspectAttorneyInExternalCase && status !== 'Proposed') {
      if (status === 'Rejected') {
        return 'The proposal has been rejected.'
      }
      if (status === 'PendingPaymentAuthorization' && isPendingPayment) {
        return 'Your proposal will not be submitted until you provide payment details.'
      }
      if (
        status === 'DeclinedAndAvaliable' ||
        status === 'DeclinedAndRejected'
      ) {
        return 'An agreement was not reached with the client.'
      }
      if (currentUserIsActivityOwner) return caseMessages.awaitingResponse
    }

    if (isPrivateCase) {
      if (accessType === CASE_ACCESS_TYPES.Owner) return ''
      if (isCaseManagerWithNoAccess)
        return caseMessages.caseManagerAskPermission
    }

    if (iamProspectAttorneyInExternalCase && status === 'Proposed') {
      return caseMessages.awaitingResponse
    }

    if (!hasActivity) {
      return null
    }

    if (accessType === CASE_ACCESS_TYPES.Owner) {
      return caseMessages.spam
    }

    return null
  }, [
    accessType,
    agreementParams,
    currentUserIsActivityOwner,
    isCaseManagerWithAccess,
    iamProspectAttorneyInExternalCase,
    isAdminAttorney,
    isCaseFromSomeoneInMyOrganization,
    isCaseManager,
    isPrivateCase,
    status,
    isCaseManagerWithNoAccess,
    caseIsTakenByMyOrg,
    rejectedByAttorneyInvolved,
    hasActivity,
    isCaseOwner,
    qualifiesForInvitedPricing,
    isMatched,
    isSigned,
    isCaseStatusFlowSimplificationEnabled,
    isPendingPayment,
    isWithdrawn,
  ])

  const alertTitle = (() => {
    if (agreementParams && isSigned) {
      return iamProspectAttorneyInExternalCase
        ? 'You signed this client! Great job.'
        : 'This client was signed by the attorney!'
    }

    return null
  })()

  const alertSubtitle = (() => {
    if (agreementParams && isSigned) {
      return iamProspectAttorneyInExternalCase
        ? 'Friendly reminder to execute a fee sharing agreement with the referring attorney.'
        : 'Friendly reminder to execute a fee sharing agreement with the referee attorney.'
    }

    return null
  })()

  const handleClientSignedConfirmationModalClose = () => {
    setSignedModalOpen(false)
  }

  const handleAgreementRejectionConfirmationModalClosed = () => {
    setRejectedModalOpen(false)
  }

  const handleClientSigned = () => {
    setSignedModalOpen(true)
  }

  const handleClientRejected = () => {
    setRejectedModalOpen(true)
  }

  const noRestrictedPermission = actionTitle !== caseTitles.noPermission

  const showInvitationPricing =
    qualifiesForInvitedPricing &&
    !agreementParams?.finalizedAgreement &&
    noRestrictedPermission &&
    !rejectedByAttorneyInvolved &&
    !rejectedByCounterpart &&
    status !== 'DeclinedAndAvaliable' &&
    status !== 'DeclinedAndRejected'

  const stripePaymentAmount =
    dataCase?.case_purchase_activity?.[0]?.case_purchase_id?.payments?.[0]
      ?.amount ?? 0

  const isStripePaymentMethod =
    dataCase?.case_purchase_activity?.[0]?.case_purchase_id?.payments?.[0]
      ?.payment_method === PAYMENT_METHOD.Stripe

  const amountToPay =
    isStripePaymentMethod && stripePaymentAmount > 0 ? stripePaymentAmount : 0

  const matchFeeContentOnClientSigning =
    responseModal.type === MODAL_TYPES.CLIENT_SIGNED_CONFIRMATION &&
    (amountToPay > 0
      ? `A match fee of $${amountToPay} has been or will be charged to the card you provided. Thank you for using Attorney Share.`
      : 'Thank you for using Attorney Share. ')

  const feeAgreementStatus =
    dataCase?.case_purchase_activity?.[0]?.case_purchase_id
      ?.fee_agreement_status?.id ?? null

  const feeAgreementFiles =
    dataCase?.case_purchase_activity?.[0]?.case_purchase_id?.case_id?.files?.filter(
      file => file?.file_id?.metadata?.is_fee_agreement
    ) ?? []

  return {
    dataCase,
    dataCaseLoading: getCaseDataLoading,
    refetchCaseData,
    isDeletedData,
    isDeletedLoading,
    preferences,
    dataRoomModal,
    sendProposalModal,
    nonVettedModal,
    responseModal,
    preferencesLoading,
    modalOpen,
    genericModalOpen,
    signedModalOpen,
    rejectedModalOpen,
    accessType,
    isVetted,
    isQualified,
    modalType,
    dataRoomModalOpen,
    isSameOrganization: isCaseFromSomeoneInMyOrganization,
    showDataRoomModal,
    showSendProposalModal,
    showNonVettedModal,
    onRequestDataRoomAccessClick,
    validateAccessType,
    onSendProposalClick,
    getConfirmationModalProps,
    getCaseInformationProps,
    setOpenModal,
    setResponseModal,
    handleClientSignedConfirmationModalClose,
    handleAgreementRejectionConfirmationModalClosed,
    handleClientSigned,
    handleClientRejected,
    deletePreference,
    createPreference,
    onSubmit,
    setGenericOpenModal,
    onSubmitGenericModal,
    setDataRoomOpenModal,
    attorneyId: activeAttorneyId,
    inviteToCaseModalOpen,
    setInviteToCaseModalOpen,
    isLoadingPayment,
    agreementParams,
    actionTitle,
    actionSubtitle,
    caseSpecialText: headerPriorityText,
    caseErrorModalOpen,
    loadingCreateCaseMutation,
    isCaseOwner,
    isCaseFromSomeoneInMyOrganization,
    caseOwnerWithOrg,
    prospectAttorneyProfile,
    iamProspectAttorneyInExternalCase,
    isPriorityAccess: isPrivateCase,
    hasAdminAccess,
    hasCaseManagerAccess,
    isAdminAttorney,
    isCaseManager,
    isNonVettedAdminAttorney,
    currentUserAttorneyId,
    isPrivateCase,
    loggedUserId,
    loggedUserOrganizationId,
    qualifiesForInvitedPricing,
    caseIsTakenByMyOrg: caseIsTakenByMyOrg && !rejectedByAttorneyInvolved,
    rejectedByAttorneyInvolved,
    rejectedByCounterpart,
    hasActivity,
    optionsLoading,
    isCaseManagerWithAccess,
    isCaseManagerWithNoAccess,
    leads,
    showClientSigningConfirmation,
    isAttorneyOwner,
    handleClientSignedSubmit,
    handleClientRejectedSubmit,
    isMatched,
    isSigned,
    isWithdrawn,
    showAllowCaseManagerActions,
    isCaseStatusFlowSimplificationEnabled,
    noRestrictedPermission,
    showInvitationPricing,
    waterfallCasePurchase,
    isPendingPayment,
    onBehalfOfAttorneyIdPendingPayment,
    onBehalfOfUserIdPendingPayment,
    pendingPaymentFee,
    alertTitle,
    alertSubtitle,
    caseNotAvailable,
    matchFeeContentOnClientSigning,
    feeAgreementFiles,
    feeAgreementStatus,
  }
}

import { PaperProps } from '@mui/material/Paper'
import useTheme from '@mui/material/styles/useTheme'
import { PieChart } from '../PieChart'

export interface OutboundReferralsProps extends PaperProps {
  marketplace: number
  waterfallReferrals: number
}

const OutboundReferrals = ({
  marketplace,
  waterfallReferrals,
  ...rest
}: OutboundReferralsProps) => {
  const theme = useTheme()

  return (
    <PieChart
      label="Outbound Referrals"
      data={[
        {
          label: 'Marketplace',
          value: marketplace,
          color: theme.palette.secondary.main,
        },
        {
          label: 'Waterfall Referrals™',
          value: waterfallReferrals,
          color: theme.palette.primary.main,
        },
      ]}
      {...rest}
    />
  )
}

export default OutboundReferrals

import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  useCatalogUsStateQuery,
  useCatalogPracticeAreaQuery,
} from '@/gql/appApi'
import { SearchProps } from '@/components'
import { useSizes } from '@/hooks/useSizes'
import { MyReferralsContext } from '../MyReferralsContext'
import { TMyReferralsFilters, TMyReferralsFiltersCounterparts } from './types'

export const useFilters = () => {
  const { rawCaseEntries, getIsSameOrg, setFilters, filters } =
    useContext(MyReferralsContext)
  const { isLessMd } = useSizes()

  // Mobile filters are in a modal, so they need to be handled separately
  const [tempFilters, setTempFilters] = useState(filters)
  const [modalOpen, setModalOpen] = useState(false)

  const { data: caseTypesData, loading: loadingCaseTypes } =
    useCatalogPracticeAreaQuery({
      variables: {
        sort: ['name'],
      },
    })
  const { data: jurisdictionsData, loading: loadingJurisdictions } =
    useCatalogUsStateQuery({
      variables: { sort: 'name' },
    })

  const loading = loadingCaseTypes || loadingJurisdictions

  useEffect(() => {
    if (modalOpen) {
      setTempFilters(filters)
    }
  }, [modalOpen, filters])

  const sellerAttorneys = useMemo(
    () =>
      rawCaseEntries.reduce<TMyReferralsFiltersCounterparts[]>(
        (sellers, caseEntry) => {
          if (
            caseEntry?.attorney &&
            !getIsSameOrg(caseEntry?.attorney?.organization_id || '')
          ) {
            sellers.push({
              id: caseEntry?.attorney?.attorney_id,
              name: `${caseEntry?.attorney?.last_name}, ${caseEntry?.attorney?.first_name}`,
            })
          }
          return sellers
        },
        []
      ),
    [rawCaseEntries, getIsSameOrg]
  )

  const buyerAttorneys = useMemo(
    () =>
      rawCaseEntries.reduce<TMyReferralsFiltersCounterparts[]>(
        (buyers, caseEntry) => {
          if (caseEntry?.case_purchase?.length > 0) {
            caseEntry.case_purchase.forEach(purchase => {
              if (
                purchase?.prospect_attorney &&
                !getIsSameOrg(
                  purchase?.prospect_attorney?.organization_id || ''
                )
              ) {
                buyers.push({
                  id: purchase?.prospect_attorney?.attorney_id,
                  name: `${purchase?.prospect_attorney?.last_name}, ${purchase?.prospect_attorney?.first_name}`,
                })
              }
            })
          }
          return buyers
        },
        []
      ),
    [rawCaseEntries, getIsSameOrg]
  )

  const counterparts = useMemo(() => {
    const allAttorneys = [...sellerAttorneys, ...buyerAttorneys]
    const existingAttorneys: Map<string, TMyReferralsFiltersCounterparts> =
      new Map()
    allAttorneys.forEach(attorney => {
      if (!existingAttorneys.has(attorney.id)) {
        existingAttorneys.set(attorney.id, attorney)
      }
    })
    const sortedAttorneys = [...existingAttorneys.values()].sort((a, b) =>
      a.name.localeCompare(b.name)
    )
    return sortedAttorneys
  }, [sellerAttorneys, buyerAttorneys])

  const onChangeFilters = useCallback(
    (updatedFilters: Partial<TMyReferralsFilters>) => {
      // Store in tempFilters if modal is open, otherwise store in filters (desktop instantly applies the changes).
      // This is to prevent values from being automatically applied before the user clicks "Apply Filters"
      const setState = isLessMd ? setTempFilters : setFilters
      setState(prev => ({
        ...prev,
        ...updatedFilters,
      }))
    },
    [isLessMd, setFilters]
  )

  const applyFilters = () => {
    setFilters(prev => ({
      ...prev,
      ...tempFilters,
    }))
    setModalOpen(false)
  }

  const clearFilters = () => {
    setTempFilters({
      searchText: '',
      caseType: '',
      jurisdiction: '',
      counterpart: '',
    })
  }

  const searchProps: SearchProps = {
    value: filters.searchText,
    placeholder: 'Search for Case...',
    onChange: (event: ChangeEvent<HTMLInputElement>) =>
      setFilters(prev => ({ ...prev, searchText: event.target.value })),
    'aria-label': 'Search Cases',
  }

  // Used to display the selected values correctly in the dropdowns when the modal is open
  const filterValues = isLessMd ? tempFilters : filters

  return {
    applyFilters,
    caseTypes: caseTypesData?.catalog_practice_area || [],
    clearFilters,
    counterparts,
    filters: filterValues,
    isLessMd,
    jurisdictions: jurisdictionsData?.catalog_us_state || [],
    loading,
    modalOpen,
    onChangeFilters,
    searchProps,
    setModalOpen,
  }
}

import useTheme from '@mui/material/styles/useTheme'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import Typography from '@mui/material/Typography'
import { useSizes } from '@/hooks/useSizes'
import useGetMyRoleAndOrg from '@/hooks/useGetMyRoleAndOrg'
import { NegotiationTableHeader } from './NegotiationTableHeader'

export const ProposalsAndInvitesTableHeader = () => {
  const theme = useTheme()
  const { isCaseManager, isAdmin } = useGetMyRoleAndOrg()

  const { isLessLg } = useSizes()

  if (isCaseManager || isAdmin) {
    return <NegotiationTableHeader owningUserLabel="Case Owner" />
  }

  if (isLessLg) {
    return null
  }

  return (
    <TableHead
      component={Box}
      sx={{
        display: 'flex',
        borderBottomWidth: 0,
        '& > .MuiTableCell-root': {
          borderBottomWidth: 0,
          pb: 0,
        },
      }}
    >
      <TableCell component={Box} sx={{ width: 0.3275 }}>
        <Typography variant="body2" color={theme.palette.text.disabled} pl={1}>
          Case Name
        </Typography>
      </TableCell>
      <TableCell component={Box} sx={{ width: 0.2095 }}>
        <Typography variant="body2" color={theme.palette.text.disabled}>
          Case Owner
        </Typography>
      </TableCell>
      <TableCell component={Box} sx={{ width: 0.2 }}>
        <Typography variant="body2" color={theme.palette.text.disabled}>
          Fees
        </Typography>
      </TableCell>
    </TableHead>
  )
}

import { IOrganizationWaterfallsContext } from './types'

export const initialContextValue: IOrganizationWaterfallsContext = {
  alert: {
    severity: 'info',
    message: '',
    open: false,
  },
  deleteWaterfallModalOpen: {
    open: false,
    waterfallId: '',
    waterfallTitle: '',
  },
  deleteWaterfall: async (): Promise<void> => {},
  deletingWaterfall: false,
  editOrCreateWaterfallModalOpen: {
    open: false,
    waterfall: undefined,
  },
  filters: {
    searchText: '',
    practiceArea: '',
    jurisdiction: '',
    integrationsOnly: false,
    alphabetical: '',
  },
  handleClose: () => {},
  hasPermissionToCreateWaterfalls: false,
  isFirmSettings: false,
  loading: false,
  onCreateWaterfall: () => {},
  onEditOrCreateWaterfallError: () => {},
  onEditOrCreateWaterfallSuccess: () => {},
  onEditWaterfall: () => {},
  openDeleteWaterfallModal: () => {},
  jurisdictionAndPracticeAreasConditions: {
    practiceAreasInUsePerJurisdiction: {},
    jurisdictionsWithWaterfallsWithNoPracticeAreas: new Set(),
  },
  setDeleteWaterfallModalOpen(): void {
    throw new Error('Function not implemented.')
  },
  setEditOrCreateWaterfallModalOpen(): void {
    throw new Error('Function not implemented.')
  },
  setFilters: () => {},
  title: '',
  updateWaterfallCreationPermissions: async (): Promise<void> => {},
  updatingOrganizationProfile: false,
  orgId: '',
  waterfalls: [],
}

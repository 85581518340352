import { useEffect, useState } from 'react'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { ConfirmationModal } from '@/components'
import {
  useGetOrganizationMembersLazyQuery,
  useUpdateOrganziationItemMutation,
  GetOrganizationMembersDocument,
} from '@/gql/appApi'
import { ATTORNEYS_ROLES } from '@/constants'
import JSnackBar from '@/components/atoms/JSnackBar'
import { useSnackBar } from '@/hooks/useSnackBar'
import useOrganizationId from '@/hooks/organization/useOrganizationId'
import { Attorney_Profile } from '@/gql/systemApi'

export interface PermissionModalProps {
  open: boolean
  member: any
  onClose: () => void
  onSubmit: () => void
}

const PermissionModal = ({
  open,
  onClose,
  member,
  onSubmit,
}: PermissionModalProps) => {
  const [attorneys, setAttorneys] = useState([])
  const { id } = useOrganizationId()
  const [delegateUser, setDelegateUser] = useState<Attorney_Profile | null>(
    null
  )
  const [getOrganizationMembers, { data, loading }] =
    useGetOrganizationMembersLazyQuery({
      variables: {
        filter: {
          _or: [
            {
              user_id: {
                role: {
                  id: {
                    _eq: ATTORNEYS_ROLES.ATTORNEY.actualId,
                  },
                },
              },
            },
            {
              user_id: {
                role: {
                  id: {
                    _eq: ATTORNEYS_ROLES.ADMIN_ATTORNEY.id,
                  },
                },
              },
            },
            {
              user_id: {
                role: {
                  id: {
                    _eq: ATTORNEYS_ROLES.ORGANIZATION_CREATOR_ADMIN_ATTORNEY.id,
                  },
                },
              },
            },
          ],
        },
      },
    })

  const [updateOrganziationItem, { loading: updateLoading }] =
    useUpdateOrganziationItemMutation({
      refetchQueries: [GetOrganizationMembersDocument],
    })

  const { alert, showAlert, handleClose: handleCloseSnack } = useSnackBar()

  useEffect(() => {
    if (id) {
      getOrganizationMembers({
        variables: {
          filter: {
            organization_id: {
              id: {
                _eq: id,
              },
            },
            _or: [
              {
                user_id: {
                  role: {
                    id: {
                      _eq: ATTORNEYS_ROLES.ATTORNEY.actualId,
                    },
                  },
                },
              },
              {
                user_id: {
                  role: {
                    id: {
                      _eq: ATTORNEYS_ROLES.ADMIN_ATTORNEY.id,
                    },
                  },
                },
              },
              {
                user_id: {
                  role: {
                    id: {
                      _eq: ATTORNEYS_ROLES.ORGANIZATION_CREATOR_ADMIN_ATTORNEY
                        .id,
                    },
                  },
                },
              },
            ],
          },
        },
      })
    }
  }, [id, getOrganizationMembers])

  useEffect(() => {
    if (data?.organization_user) {
      const atts = data?.organization_user.map(d => ({
        ...d.user_id,
        id: d.id,
      }))
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setAttorneys(atts)
    }
  }, [data])

  const handleOnSubmit = async () => {
    const payload = {
      updateOrganizationUserItemId: member.id,
      data: {
        delegated_organization_user_id: delegateUser
          ? { id: delegateUser.id }
          : null,
      },
    }
    try {
      await updateOrganziationItem({
        variables: {
          ...payload,
        },
      })
    } catch (err) {
      showAlert({
        severity: 'error',
        message: 'Something went wrong, please try again in a few minutes',
      })
    }
    onSubmit()
  }

  const isCaseManager =
    member.user_id.role.id === ATTORNEYS_ROLES.CASE_MANAGER.id
  const fullname = `${member.user_id.first_name} ${member.user_id.last_name}`

  const filterOptions = createFilterOptions<Attorney_Profile>({
    matchFrom: 'any',
    stringify: option => `${option.first_name} ${option.last_name}`,
  })

  const toggleFeature = false

  // TODO: fetch permissions
  return (
    <>
      <ConfirmationModal
        open={open}
        title="Attorney Assignment"
        description={`As a Case Manager, ${fullname} must act on behalf of one of the attorneys in your law firm.
        Case Managers are responsible for handling all cases and interactions with users from other companies on behalf of the attorney they are assigned to. You can modify this configuration as needed.`}
        onClose={onClose}
        AcceptButtonProps={{
          disabled: updateLoading,
          children: 'Assign Attorney',
          onClick: handleOnSubmit,
        }}
      >
        <FormGroup sx={{ display: 'flex', gap: 1, mt: 3 }}>
          {isCaseManager && (
            <Autocomplete<Attorney_Profile>
              value={delegateUser}
              isOptionEqualToValue={(opt, val) => opt.id === val.id}
              loading={loading}
              options={attorneys}
              filterOptions={filterOptions}
              onChange={(_event, val) => {
                setDelegateUser(val)
              }}
              getOptionLabel={att => `${att.first_name} ${att.last_name}`}
              renderInput={params => (
                <TextField {...params} label="Acts on behalf of" />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {`${option.first_name} ${option.last_name}`}
                </li>
              )}
            />
          )}
          {toggleFeature && (
            <>
              <FormControlLabel control={<Switch />} label="Create new cases" />
              <FormControlLabel
                control={<Switch />}
                label="Submit proposals to Marketplace cases"
              />
            </>
          )}
        </FormGroup>
      </ConfirmationModal>
      <JSnackBar
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        horizontal="center"
        vertical="bottom"
        handleClose={handleCloseSnack}
      />
    </>
  )
}

export default PermissionModal

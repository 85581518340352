import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export interface DotNumberProps {
  number: number
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '20px',
  height: '20px',
  borderRadius: '20px',
  border: `1px solid ${theme.palette.text.secondary}`,
}))

const DotNumber = ({ number }: DotNumberProps) => (
  <Container>
    <Typography variant="caption" color="text.disabled" sx={{ mb: '1px' }}>
      {number}
    </Typography>
  </Container>
)

export default DotNumber

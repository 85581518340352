import { CasePurchase } from '@/pages/MyReferralsV2/types'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import useTheme from '@mui/material/styles/useTheme'
import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded'
import { useSizes } from '@/hooks/useSizes'
import { useCaseMessageTrigger } from '@/components/GetStreamChat/useCaseMessageTrigger'
import { useContext } from 'react'
import { ResponseModal } from '@/components'
import { CASE_PURCHASE_STATUS } from '@/constants'
import { ReferralAttorneyAvatar } from './ReferralAttorneyAvatar'
import { useNegotiation } from './useNegotiation'
import { ProposalReceivedActions } from './ProposalReceivedActions'
import { ReferralContext } from './ReferralContext'
import { MyReferralsContext } from '../MyReferralsContext'
import { AwaitingOfferResponseActions } from './AwaitingOfferResponseActions'

interface NegotiationProps {
  casePurchase: CasePurchase
}

export const Negotiation = ({ casePurchase }: NegotiationProps) => {
  const theme = useTheme()
  const { refetch } = useContext(MyReferralsContext)
  const { caseEntry, isUserSeller, showUserSidePrincipal } =
    useContext(ReferralContext)
  const {
    userSideAttorney,
    otherSideAttorney,
    didSellerTakeMostRecentAction,
    offeredFee,
    feeText,
    casePurchaseDateString,
    proposalReceivedActionsProps,
    negotiationProps: { response, setResponse },
    isClientSigningResponseModal,
  } = useNegotiation({
    casePurchase,
  })
  const { handleTriggerClick } = useCaseMessageTrigger()
  const { isLessMd, isLessLg } = useSizes()

  const handleSendMessageClick = () => {
    handleTriggerClick({
      membersToMessage: [
        otherSideAttorney.user_id,
        ...(caseEntry.ownerships ?? []),
      ],
    })
  }

  const showProposalReceivedActions =
    casePurchase.status !== CASE_PURCHASE_STATUS.Reject && isUserSeller
      ? !didSellerTakeMostRecentAction
      : didSellerTakeMostRecentAction

  const buyerHasOfferOutToSeller =
    [
      String(CASE_PURCHASE_STATUS.Counteroffer),
      String(CASE_PURCHASE_STATUS.Proposed),
    ].includes(casePurchase?.status) && !didSellerTakeMostRecentAction

  const showWithdrawControls = !isUserSeller && buyerHasOfferOutToSeller

  const wasBuyerInvited =
    casePurchase?.created_by_attorney?.attorney_id === caseEntry.attorney_id

  return (
    <Grid container spacing={2} minHeight={theme.spacing(7.5)}>
      <Grid item container xs={12} md={8} alignItems="center" columnSpacing={1}>
        <Grid item xs={9} sm={8} display="flex" alignItems="center">
          <ReferralAttorneyAvatar
            attorney={otherSideAttorney}
            showOrganization
            fitToSingleLine={!isLessMd}
          />
          {showUserSidePrincipal && !isLessMd && (
            <>
              <CompareArrowsRoundedIcon
                sx={{ mx: 1.5, fontSize: theme.spacing(2) }}
              />
              <ReferralAttorneyAvatar
                attorney={userSideAttorney}
                fitToSingleLine
              />
            </>
          )}
        </Grid>
        <Grid
          item
          xs={3}
          sm={4}
          display="flex"
          justifyContent={{ xs: 'start', md: 'flex-end' }}
          flexDirection={{ xs: 'column', md: 'row' }}
          gap={0.75}
        >
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="flex-end"
            gap={1}
          >
            {typeof offeredFee === 'number' && (
              <Typography variant="subtitle1">{offeredFee}%</Typography>
            )}
            {!isLessLg && (
              <Typography
                variant="caption"
                color={theme.palette.text.secondary}
              >
                {feeText}
              </Typography>
            )}
          </Box>
          <Box display="flex" alignItems="baseline" justifyContent="end">
            <Typography
              variant="caption"
              color={theme.palette.text.disabled}
              lineHeight={{ sm: theme.typography.subtitle1.lineHeight }}
            >
              {casePurchaseDateString}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        display="flex"
        alignItems="center"
        justifyContent="end"
      >
        {showProposalReceivedActions && (
          <ProposalReceivedActions {...proposalReceivedActionsProps} />
        )}
        {!showProposalReceivedActions && (
          <>
            {showWithdrawControls && (
              <AwaitingOfferResponseActions
                casePurchase={casePurchase}
                counterpart={otherSideAttorney}
                enableWithdraw={!wasBuyerInvited}
              />
            )}
            {!showWithdrawControls && (
              <Box justifySelf="end" ml="auto">
                <Button sx={{ mr: 1 }} onClick={handleSendMessageClick}>
                  Send Message
                </Button>
              </Box>
            )}
          </>
        )}
      </Grid>
      {response.open && (
        <ResponseModal
          open={response.open}
          type={response.type}
          onClose={async () => {
            await refetch()
            setResponse({ open: false, type: null })
          }}
          variant={isClientSigningResponseModal ? 'standard' : 'outlined'}
          {...(isClientSigningResponseModal && { icon: false })}
        />
      )}
    </Grid>
  )
}

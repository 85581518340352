import { styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import Box from '@mui/material/Box'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import LockIcon from '@mui/icons-material/Lock'
import Avatar from '@mui/material/Avatar'

import {
  AllReferralFilterItem,
  ALL_REFERRAL_FILTER_TYPES_CATALOG,
} from '../../types'

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}))

export interface SelectedFiltersProps {
  currentFilters: AllReferralFilterItem[]
  setFilters: (newFilters: AllReferralFilterItem[]) => void
}

const SelectedFilters = ({
  currentFilters,
  setFilters,
}: SelectedFiltersProps) => {
  const handleDelete = (filterToDelete: AllReferralFilterItem) => () => {
    setFilters(currentFilters.filter(chip => chip.id !== filterToDelete.id))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {currentFilters.map(filter => {
        let icon
        let avatar

        if (filter.type === ALL_REFERRAL_FILTER_TYPES_CATALOG.REFERRAL_TYPE) {
          switch (filter.id) {
            case 1:
            case 3:
              icon = <LockOpenOutlinedIcon />
              break
            case 2:
              icon = <LockIcon />
              break
            default:
              icon = undefined
          }
        } else if (
          [
            ALL_REFERRAL_FILTER_TYPES_CATALOG.ATTORNEY,
            ALL_REFERRAL_FILTER_TYPES_CATALOG.CASE_MANAGER,
          ].includes(String(filter.type))
        ) {
          if (filter.data) {
            avatar = <Avatar alt={filter.name} src={filter.data} />
          } else {
            avatar = <Avatar>{filter.name[0]}</Avatar>
          }
        }

        return (
          <ListItem key={filter.id}>
            <Chip
              avatar={avatar}
              icon={icon}
              label={filter.name}
              onDelete={handleDelete(filter)}
              variant="outlined"
            />
          </ListItem>
        )
      })}
    </Box>
  )
}

export default SelectedFilters

import Grid, { GridProps } from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { alpha, useTheme } from '@mui/material/styles'

interface ICreateCaseSectionLayoutProps extends GridProps {
  title: string
  subtitle: string | React.ReactNode
  children: React.ReactNode
}

const CreateCaseSectionLayout = ({
  title,
  subtitle,
  children,
  sx,
  ...rest
}: ICreateCaseSectionLayoutProps) => {
  const theme = useTheme()

  const renderSubtitle = () => {
    if (typeof subtitle === 'string') {
      return (
        <Typography
          variant="body2"
          sx={{
            mt: 1.25,
            color: alpha(theme.palette.common.black, 0.6),
          }}
        >
          {subtitle}
        </Typography>
      )
    }

    return subtitle
  }
  return (
    <Grid
      sx={[{ pb: 2.5 }, ...(Array.isArray(sx) ? sx : [sx])]}
      container
      columnSpacing={2}
      {...rest}
    >
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          mb: {
            xs: 2.5,
            md: 0,
          },
        }}
      >
        <Typography variant="subtitle1">{title}</Typography>
        {renderSubtitle()}
      </Grid>
      <Grid item xs={12} md={7}>
        {children}
      </Grid>
    </Grid>
  )
}

export default CreateCaseSectionLayout

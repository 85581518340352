import AuthLayout from '@/components/shared/AuthLayout'
import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import { TTokenValidationResponse } from '@/hooks/useTokenValidation'
import { useMemo } from 'react'
import ProfileCommunicationPreferences from '@/pages/CreateProfile/ProfileCommunicationPreferences/ProfileCommunicationPreferences'
import { CreateAccountData } from '../InitialStep/types'
import { useCreateCaseManager } from './hooks/useCreateCaseManager'
import { OrganizationCreateProfile } from '../CreateOrganization/OrganizationCreateProfile'

interface CreateCaseManagerProps {
  goBack: () => void
  createAccountValues: CreateAccountData
  tokenData: TTokenValidationResponse
}

const CreateCaseManager = ({
  goBack,
  createAccountValues,
  tokenData,
}: CreateCaseManagerProps) => {
  const {
    formState,
    handleCreateAccount,
    submitAndContinue,
    setError,
    error,
    loading,
    state,
    back,
  } = useCreateCaseManager({
    createAccountValues,
    tokenData,
  })

  const renderSteps = useMemo((): JSX.Element => {
    switch (state.value) {
      case 'personalInfo':
        return (
          <OrganizationCreateProfile
            back={goBack}
            onSubmit={data => submitAndContinue('personalInfo', data)}
            hasLicense={false}
            profileValues={formState.personalInfo}
            loading={loading}
            prevBtnText="Back"
            nextBtnText="Continue"
          />
        )
      case 'communicationPreferences':
        return (
          <ProfileCommunicationPreferences
            back={back}
            initialCommPreferences={formState.communicationPreferences}
            onSubmit={handleCreateAccount}
          />
        )
      default:
        return <CircularProgress />
    }
  }, [
    state.value,
    goBack,
    formState.personalInfo,
    formState.communicationPreferences,
    loading,
    back,
    handleCreateAccount,
    submitAndContinue,
  ])

  return (
    <AuthLayout>
      {error && (
        <Alert severity="error" onClose={() => setError('')}>
          {error}
        </Alert>
      )}
      {renderSteps}
    </AuthLayout>
  )
}

export default CreateCaseManager

import EmptyPage from '@/components/EmptyPage/EmptyPage'
import CustomLoading from '@/components/Loading'
import { CASE_PURCHASE_STATUS, CASE_STATUS_FLOW } from '@/constants'
import useGetMyRoleAndOrg from '@/hooks/useGetMyRoleAndOrg'
import { CaseEntry } from '@/pages/MyReferralsV2/types'
import { RoutesData } from '@/router/RoutesData'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { FC, ReactNode, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSessionStorage } from 'usehooks-ts'
import { MyReferralsContext } from '../MyReferralsContext'
import { Referral } from '../Referral/Referral'
import { isFollowedAndAvailable } from '../Referral/utils'
import { NegotiationTableHeader } from './NegotiationTableHeader'
import { ProposalsAndInvitesTableHeader } from './ProposalsAndInvitesTableHeader'
import { WatchedCasesTableHeader } from './WatchedCasesTableHeader'
import { myReferralsSelectedTabSessionKey } from './constants'

type TabData = {
  id: number
  title: string
  caseEntries: CaseEntry[]
  disabled?: boolean
  header: ReactNode
  bodyContent?: ReactNode
  /**
   * Can be used to override the number shown in the tab label
   */
  numberOfCases?: number
}

export enum TabIndex {
  NEEDS_ACTION = 0,
  PROPOSALS_AND_INVITES,
  OPEN_REFERRALS,
  MATCHED,
  SIGNED,
  WATCHING,
  INACTIVE,
}

export enum SearchParams {
  INITIAL_TAB = 'initialTab',
}

const TabHeader = ({ title }: { title: string }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      px: 0.5,
    }}
  >
    <Typography variant="subtitle2">{title}</Typography>
  </Box>
)

interface CaseTabContentProps {
  header: React.ReactNode
  caseEntries: CaseEntry[]
}

export const CaseTabContent = ({
  header,
  caseEntries,
}: CaseTabContentProps) => (
  <>
    {header}
    {caseEntries.length > 0 ? (
      caseEntries.map(caseEntry => (
        <Referral key={caseEntry.id} caseEntry={caseEntry} />
      ))
    ) : (
      <EmptyPage
        message={`You don't have any cases to show here`}
        sx={{
          textAlign: 'center',
          mb: {
            xs: 5,
            md: 12,
          },
        }}
      />
    )}
  </>
)

const CaseTabPanel: FC<{
  tabData: TabData
  tabValue: number
  bodyContent?: ReactNode
}> = ({ tabData: { id, caseEntries, header, bodyContent }, tabValue }) => {
  const theme = useTheme()

  return (
    <Box
      role="tabpanel"
      hidden={tabValue !== id}
      id={`cases-tabpanel-${id}`}
      aria-labelledby={`cases-tab-${id}`}
    >
      {tabValue === id && (
        <Box
          overflow="auto"
          display="flex"
          flexDirection="column"
          sx={{
            '& > .MuiPaper-root:not(:last-child), & > .MuiGrid-root:not(:last-child)':
              {
                borderBottom: `solid 1px ${theme.palette.divider}`,
              },
          }}
        >
          {bodyContent ?? (
            <CaseTabContent header={header} caseEntries={caseEntries} />
          )}
        </Box>
      )}
    </Box>
  )
}

const NeedsActionTab = ({
  proposalsAndInvites,
  openReferrals,
  matchedCases,
}: {
  proposalsAndInvites: CaseEntry[]
  openReferrals: CaseEntry[]
  matchedCases: CaseEntry[]
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <>
      {proposalsAndInvites.length > 0 && (
        <>
          <Typography variant="subtitle1" pt={4} pb={1} px={3}>
            Proposals and Invites
          </Typography>
          <CaseTabContent
            header={<ProposalsAndInvitesTableHeader />}
            caseEntries={proposalsAndInvites}
          />
        </>
      )}
      {openReferrals.length > 0 && (
        <>
          <Typography variant="subtitle1" pt={4} pb={1} px={3}>
            Open
          </Typography>
          <CaseTabContent
            header={<NegotiationTableHeader owningUserLabel="Counterpart" />}
            caseEntries={openReferrals}
          />
        </>
      )}
      {matchedCases.length > 0 && (
        <>
          <Typography variant="subtitle1" pt={4} pb={1} px={3}>
            Matched
          </Typography>
          <CaseTabContent
            header={<NegotiationTableHeader owningUserLabel="Counterpart" />}
            caseEntries={matchedCases}
          />
        </>
      )}
      {proposalsAndInvites.length +
        openReferrals.length +
        matchedCases.length ===
        0 && (
        <Box textAlign="center" py={10} color={theme.palette.text.secondary}>
          <Typography variant="h6" mx="auto">
            You&apos;re all caught up!
          </Typography>
          <Typography variant="body2" maxWidth="400px" mx="auto" my={2}>
            You can click into the tabs above to view your Open, Matched, and
            Signed Referrals.
          </Typography>

          <Button
            variant="contained"
            size="large"
            onClick={() => navigate(RoutesData.RMarketplace.path)}
            sx={{ width: 'fit-content', marginRight: 1, mt: 2, mx: 'auto' }}
          >
            Browse the Marketplace
          </Button>
        </Box>
      )}
    </>
  )
}

export const TabsContainer = () => {
  const theme = useTheme()
  const { isLoading, error, caseEntries } = useContext(MyReferralsContext)
  const { getIsSameOrg } = useGetMyRoleAndOrg()

  const initialTab = Number(TabIndex.NEEDS_ACTION)

  const [tabValue, setTabValue] = useSessionStorage(
    myReferralsSelectedTabSessionKey,
    initialTab
  )

  const handleChangeTab = (_e: React.SyntheticEvent, newValue: TabIndex) => {
    setTabValue(newValue)
  }

  const casesThatNeedAttention = (cases: CaseEntry[]) =>
    cases?.filter(caseEntry => caseEntry?.needs_your_attention ?? false)

  const tabDataList = useMemo(() => {
    const proposalsAndInvites = caseEntries.filter(
      caseEntry =>
        ![
          String(CASE_STATUS_FLOW.Matched),
          String(CASE_STATUS_FLOW.Signed),
        ].includes(caseEntry.status) &&
        !getIsSameOrg(caseEntry.attorney.organization_id ?? '') &&
        !isFollowedAndAvailable(caseEntry) &&
        // NOTE: This is a temporary fix  for https://attorney.atlassian.net/browse/MP-2703.
        // TODO: This .some check should be removed as part of https://attorney.atlassian.net/browse/MP-2454
        //
        // See: https://attorneyshare.slack.com/archives/C063FAP5AHY/p1730407851074829?thread_ts=1730406754.507679&cid=C063FAP5AHY
        caseEntry.case_purchase.some(
          ({ status }) => status !== CASE_PURCHASE_STATUS.Reject
        )
    )

    const followedCases = caseEntries.filter(isFollowedAndAvailable)

    const openReferrals = caseEntries.filter(
      caseEntry =>
        ![
          String(CASE_STATUS_FLOW.Matched),
          String(CASE_STATUS_FLOW.Signed),
        ].includes(caseEntry.status) &&
        getIsSameOrg(caseEntry.attorney.organization_id ?? '')
    )

    const matchedCases = caseEntries.filter(
      caseEntry => caseEntry.status === CASE_STATUS_FLOW.Matched
    )

    const signedCases = caseEntries.filter(
      caseEntry => caseEntry.status === CASE_STATUS_FLOW.Signed
    )

    const proposalsAndInvitesThatNeedAttention =
      casesThatNeedAttention(proposalsAndInvites)
    const openReferralsThatNeedAttention = casesThatNeedAttention(openReferrals)
    const matchedCasesThatNeedAttention = casesThatNeedAttention(matchedCases)

    const tabData: TabData[] = [
      // NOTE: Filters for Cases where the user needs to take action
      {
        id: TabIndex.NEEDS_ACTION,
        title: 'Needs Action',
        caseEntries: [],
        header: null,
        numberOfCases:
          proposalsAndInvitesThatNeedAttention.length +
          openReferralsThatNeedAttention.length +
          matchedCasesThatNeedAttention.length,
        bodyContent: (
          <NeedsActionTab
            proposalsAndInvites={proposalsAndInvitesThatNeedAttention}
            openReferrals={openReferralsThatNeedAttention}
            matchedCases={matchedCasesThatNeedAttention}
          />
        ),
      },
      // NOTE: Filters for Cases where the user is the Buyer
      {
        id: TabIndex.PROPOSALS_AND_INVITES,
        title: 'Proposals & Invites',
        caseEntries: proposalsAndInvites,
        header: <ProposalsAndInvitesTableHeader />,
      },
      // NOTE: Filters for Cases where the user is the Feeder
      {
        id: TabIndex.OPEN_REFERRALS,
        title: 'Open',
        caseEntries: openReferrals,
        header: <NegotiationTableHeader owningUserLabel="Counterpart" />,
      },
      // NOTE: Filters for Matched cases where the user is either the Eater or
      // the Feeder
      {
        id: TabIndex.MATCHED,
        title: 'Matched',
        caseEntries: matchedCases,
        header: <NegotiationTableHeader owningUserLabel="Counterpart" />,
      },
      // NOTE: Filters for Signed cases where the user is either the Eater or
      // the Feeder
      {
        id: TabIndex.SIGNED,
        title: 'Signed',
        caseEntries: signedCases,
        header: <NegotiationTableHeader owningUserLabel="Counterpart" />,
      },
      // NOTE: Filters for Cases that the user is following
      {
        id: TabIndex.WATCHING,
        title: 'Watching',
        caseEntries: followedCases,
        header: <WatchedCasesTableHeader />,
      },
      // NOTE: This functionality is being deferred for now:
      // https://attorneyshare.slack.com/archives/C063FAP5AHY/p1722619138185389?thread_ts=1722374952.550269&cid=C063FAP5AHY
      //
      // NOTE: Filters for:
      //   - Any case user was involved with (submitted a proposal, etc) but is
      //   no longer active or available to the user
      // {
      //   id: TabIndex.INACTIVE,
      //   title: 'Inactive',
      //   caseEntries: [],
      //   disabled: true,
      // },
    ]

    return tabData
  }, [caseEntries, getIsSameOrg])

  if (isLoading) return <CustomLoading message="Loading..." />

  if (error) return `An error has occurred: ${error.message}`

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 1,
      }}
      elevation={2}
    >
      <Tabs
        aria-label="Referral tabs"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        value={tabValue}
        onChange={handleChangeTab}
        sx={{
          borderBottomColor: theme.palette.divider,
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
        }}
      >
        {tabDataList.map(
          ({
            id,
            title,
            disabled,
            numberOfCases,
            caseEntries: { length: caseEntriesLength },
          }) => (
            <Tab
              key={`Referrals-tab-${id}`}
              label={
                <TabHeader
                  title={`${title} (${new Intl.NumberFormat().format(
                    numberOfCases ?? caseEntriesLength
                  )})`}
                />
              }
              id={`cases-tab-${id}`}
              aria-controls={`cases-tabpanel-${id}`}
              disabled={disabled}
              sx={{
                py: 2.5,
              }}
            />
          )
        )}
      </Tabs>
      {tabDataList.map(tabData => (
        <CaseTabPanel
          key={`Referrals-tab-panel-${tabData.id}`}
          tabValue={tabValue}
          tabData={tabData}
        />
      ))}
    </Paper>
  )
}

import Typography from '@mui/material/Typography'
import JTextField from '@/components/atoms/JTextField'
import { useSizes } from '@/hooks/useSizes'
import { isValidEmail, validatePhoneNumber } from '@/utils'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { useState, FC } from 'react'
import Box from '@mui/material/Box'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  DiscardChangesModal,
  useDiscardChangesModal,
  ResponseModal,
} from '@/components'
import { MobileAppBar } from '@/pages/AccountSettings/Shared/MobileAppBar'
import { useTheme } from '@mui/material'
import { ModalTypes } from '@/types'
import TitleColumn from '../Shared/TitleColumn'
import { DesktopStickyActions } from '../Shared/DesktopStickyActions'
import { IAccountSettingsTabProps } from '../types'

type TForm = {
  first_name: string
  last_name: string
  phone: string
  email: string
}

const PersonalInformation: FC<IAccountSettingsTabProps> = ({
  title,
  subtitle,
  profile,
}) => {
  const theme = useTheme()
  const defaultValues: TForm = {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
  }
  const newDefValues = {
    first_name: profile?.first_name,
    last_name: profile?.last_name,
  }

  const { control, handleSubmit, reset } = useForm({
    defaultValues,
    values: newDefValues,
    mode: 'onChange',
  })

  const { screenSize } = useSizes()
  const mobileLayout = screenSize !== 'desktop'

  const { textFieldSize } = useSizes()
  const [response, setResponse] = useState<{ type: ModalTypes; open: boolean }>(
    {
      type: null,
      open: false,
    }
  )
  const onSubmit: SubmitHandler<TForm> = () => {}

  const {
    getConfirmationModalProps,
    setOpenModal,
    modalOpen,
    type: modalType,
    ...rest
  } = useDiscardChangesModal({
    type: 'confirm',
    onSubmit: () => {
      reset(newDefValues)
      setOpenModal(null)
      setResponse({ type: 'DiscardChangesAccepted', open: true })
    },
  })

  const handleDiscardChanges = () => {
    setOpenModal('confirmDiscardChanges')
  }

  return (
    <Paper
      sx={{
        overflow: 'auto',
        maxHeight: '100%',
        padding: theme.spacing(2),
        mb: mobileLayout ? theme.spacing(20) : theme.spacing(0),
      }}
    >
      <form
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container direction="row" spacing={mobileLayout ? 1 : 5}>
          <TitleColumn title={title} subtitle={subtitle} />
          <Grid item mt={0} md={8} xs={12}>
            <Box
              sx={{
                width: '100%',
                p: 2,
                ...(!mobileLayout && { pt: 2, pl: 0 }),
              }}
              className="personal-information"
            >
              {modalOpen && (
                <DiscardChangesModal
                  ConfirmationModalProps={getConfirmationModalProps()}
                  {...rest}
                />
              )}
              {response.open && (
                <ResponseModal
                  open={response.open}
                  type={response.type}
                  onClose={() => setResponse({ open: false, type: null })}
                />
              )}

              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <JTextField<TForm>
                    name="first_name"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    control={control}
                    label="First Name"
                    type="text"
                    size={textFieldSize}
                    rules="required"
                  />
                </Grid>
                <Grid item xs={12}>
                  <JTextField<TForm>
                    name="last_name"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    control={control}
                    label="Last Name"
                    type="text"
                    size={textFieldSize}
                    rules="required"
                  />
                </Grid>
                <Grid item xs={12}>
                  <JTextField<TForm>
                    name="phone"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    control={control}
                    label="Phone Number"
                    type="tel"
                    size={textFieldSize}
                    required={false}
                    rules={{
                      validate: (phone: string) => validatePhoneNumber(phone),
                    }}
                  />
                  <Typography variant="caption">
                    Changes will require phone number verification
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <JTextField<TForm>
                    name="email"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    control={control}
                    label="Email"
                    type="text"
                    size={textFieldSize}
                    rules={{ validate: (email: string) => isValidEmail(email) }}
                  />
                  <Typography variant="caption">
                    Changes will require phone number verification
                  </Typography>
                </Grid>
              </Grid>
              <MobileAppBar
                onPrimaryActionClick={() => {}}
                onSecondaryActionClick={handleDiscardChanges}
              />
            </Box>
          </Grid>
        </Grid>
        <DesktopStickyActions
          onPrimaryActionClick={() => {}}
          onSecondaryActionClick={handleDiscardChanges}
        />
      </form>
    </Paper>
  )
}

export default PersonalInformation

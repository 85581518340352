import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { FormProvider } from 'react-hook-form'
import JSnackBar from '@/components/atoms/JSnackBar'
import AttorneyToPracticeArea from './AttorneyToPracticeArea/AttorneyToPracticeArea'
import usePracticeAreaMapping from './usePracticeAreaMapping'
import SelectDefaultAttorney from './SelectDefaultAttorney/SelectDefaultAttorney'
import ReferCasesToWaterfalls from '../ReferCasesToWaterfalls/ReferCasesToWaterfalls'

const PracticeAreaMapping = () => {
  const {
    onSubmit,
    methods,
    loading,
    isSubmitting,
    alert: { alertOpen, alertSeverity, alertMessage, handleCloseAlert },
    validJurisdictionsAndAttorneys,
  } = usePracticeAreaMapping()

  return (
    <Card
      sx={{
        mb: 4,
        p: 2,
        pb: 4,
        boxShadow: 2,
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Attorney Mapping</Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Configure how cases are assigned to attorneys based on specific
                jurisdictions and practice areas.
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Typography variant="subtitle1">
                Select a default attorney
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mt: 1, mb: 2 }}
              >
                Cases that either lack an assigned attorney or belong to a
                vacant practice area will be given to this user
              </Typography>
              <Grid item xs={12} md={4}>
                <SelectDefaultAttorney
                  fieldName="defaultAttorney"
                  rules={{
                    required: 'You must select an attorney',
                  }}
                  validAttorneys={validJurisdictionsAndAttorneys.validAttorneys}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Typography variant="subtitle1">
                Map Attorneys to Jurisdictions and Practice Areas
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mt: 1, mb: 2 }}
              >
                You can leave Practice Area blank to set the attorney as default
                for all cases in the selected jurisdiction.
              </Typography>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <AttorneyToPracticeArea
                  validJurisdictionsAndAttorneys={
                    validJurisdictionsAndAttorneys
                  }
                />
              </Grid>
            </Grid>
            <Button
              size="large"
              variant="contained"
              color="primary"
              sx={{ ml: 'auto', mt: 4 }}
              onClick={methods.handleSubmit(onSubmit)}
              disabled={isSubmitting}
            >
              Save Configuration
            </Button>
          </Grid>
          <ReferCasesToWaterfalls />
        </FormProvider>
      )}
      <JSnackBar
        vertical="bottom"
        open={alertOpen}
        severity={alertSeverity}
        message={alertMessage}
        handleClose={handleCloseAlert}
      />
    </Card>
  )
}

export default PracticeAreaMapping

import Box, { BoxProps } from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useSizes } from '@/hooks/useSizes'
import { useContext } from 'react'
import JSnackBar from '@/components/atoms/JSnackBar'
import { CreateOrEditWaterfallModal } from './CreateOrEditWaterfallModal'
import { OrganizationWaterfallsContext } from '../OrganizationWaterfallsContext'
import HowDoesItWork from './HowDoesItWork'
import ConfigurationBox from './ConfigurationBox'
import DeleteWaterfallModal from './DeleteWaterfallModal'
import WaterfallCards from './WaterfallCards/WaterfallCards'
import { WaterfallFilters } from './WaterfallFilters/WaterfallFilters'

interface IOrganizationWaterfallsProps {
  containerBoxProps?: Partial<BoxProps>
}

const Waterfalls = ({ containerBoxProps }: IOrganizationWaterfallsProps) => {
  const { isLessMd } = useSizes()
  const {
    title,
    onCreateWaterfall,
    deleteWaterfallModalOpen,
    editOrCreateWaterfallModalOpen,
    alert,
    handleClose,
    hasPermissionToCreateWaterfalls,
    isFirmSettings,
    onEditOrCreateWaterfallSuccess,
    onEditOrCreateWaterfallError,
    jurisdictionAndPracticeAreasConditions,
    setEditOrCreateWaterfallModalOpen,
    orgId,
  } = useContext(OrganizationWaterfallsContext)

  const enabledPermissions = hasPermissionToCreateWaterfalls || isFirmSettings

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          pb: 8,
          flexDirection: 'column',
          overflow: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
        {...containerBoxProps}
      >
        {!isLessMd && <Typography variant="h5">{title}</Typography>}
        <Grid
          container
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            px: { xs: 2, md: 0 },
          }}
        >
          <Grid item xs={12} md={8}>
            <HowDoesItWork />
          </Grid>
          {enabledPermissions && (
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mt: {
                  xs: 2,
                  md: 0,
                },
              }}
            >
              <Button
                fullWidth={isLessMd}
                variant="contained"
                size="large"
                onClick={onCreateWaterfall}
              >
                Create New Waterfall
              </Button>
            </Grid>
          )}
        </Grid>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: {
              xs: 'column-reverse',
              md: 'column',
            },
          }}
        >
          <ConfigurationBox
            sx={{
              mt: 4,
            }}
          />
          {enabledPermissions && <WaterfallFilters />}
        </Box>
        {enabledPermissions && <WaterfallCards />}
      </Box>
      {deleteWaterfallModalOpen.open && <DeleteWaterfallModal />}
      {editOrCreateWaterfallModalOpen.open && (
        <CreateOrEditWaterfallModal
          open={editOrCreateWaterfallModalOpen.open}
          waterfall={editOrCreateWaterfallModalOpen.waterfall}
          onClose={() =>
            setEditOrCreateWaterfallModalOpen({
              open: false,
            })
          }
          onSuccess={onEditOrCreateWaterfallSuccess}
          onError={onEditOrCreateWaterfallError}
          isFirmSettings={isFirmSettings}
          organizationId={orgId}
          jurisdictionAndPracticeAreasConditions={
            jurisdictionAndPracticeAreasConditions
          }
        />
      )}
      <JSnackBar
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        horizontal="center"
        vertical="bottom"
        handleClose={handleClose}
      />
    </>
  )
}

export default Waterfalls

import {
  AttorneyDocument,
  Case_Management_Software,
  Catalog_Company_Size,
  Catalog_Firm_Relationship,
  Catalog_Us_State,
  Catalog_Years_Practice,
  Update_Attorney_Profile_Input,
  useAttorneyQuery,
  useDeleteAttorneyProfileAwardItemsMutation,
  useDeleteAttorneyProfileEducationItemsMutation,
  useGradesQuery,
  useLawFirmCatalogsQuery,
  useUpdateAttorneyProfileItemMutation,
  useUpdateAttorneyProfileMutation,
} from '@/gql/appApi'
import { useCallback, useEffect, useState } from 'react'
import { LICENSE_STATUS } from '@/constants'
import { useQuery } from '@apollo/client'
import { SignInQueryResult } from '@/apollo/AuthRoute'
import { IS_LOGGED_IN } from '@/apollo/queries'
import useAttorneyProfileId from './useAttorneyProfileId'
import useAttorneyId from './useAttorneyId'
import useOrganization from './useOrganization'

interface UseProfileProps {
  /**
   * Whether to retrieve all licenses associated with this this profile. If
   * false or omitted, only validated licenses are retrieved.
   */
  getAllLicenses?: boolean
}

export type TUpdateProfileInput = {
  id: string
  /**
   * `referral_source` is part of the `Update_Attorney_Profile_Input` type (you
   * can confirm in Apollo) but for some reason it's not included in the type
   * definition. This is a workaround.
   */
  data: Partial<Update_Attorney_Profile_Input> & { referral_source?: string }
}

const useProfile = ({ getAllLicenses = false }: UseProfileProps = {}) => {
  const [deleteAttorneyProfileAwardItemsMutation] =
    useDeleteAttorneyProfileAwardItemsMutation()
  const [updateAttorneyProfileItemMutation] =
    useUpdateAttorneyProfileItemMutation({
      refetchQueries: [AttorneyDocument, 'Attorney'],
    })
  const { getAttorneyId } = useAttorneyId()
  const { getAttorneyProfileId } = useAttorneyProfileId()
  const { data: lawFirmCatalogs, loading: lawFirmCatalogsLoading } =
    useLawFirmCatalogsQuery({
      variables: {
        catalogFirmRelationshipSort2: 'name',
        catalogUsStateSort2: 'code',
        catalogYearsPracticeSort2: 'name',
        sortCaseSoftware: 'name',
      },
    })
  const attorneyId = getAttorneyId()
  const profileId = getAttorneyProfileId()
  const { data: signedInData } = useQuery<SignInQueryResult>(IS_LOGGED_IN)
  const isLoggedIn = Boolean(signedInData?.isLoggedIn)
  const skipQuery = !isLoggedIn
  const [caseManagementSoftware, setCaseManagementSoftware] = useState<
    Array<Case_Management_Software>
  >([])
  const [companySize, setCompanySize] = useState<
    Array<Omit<Catalog_Company_Size, '__typename'>>
  >([])
  const [states, setStates] = useState<
    Array<Omit<Catalog_Us_State, '__typename'>>
  >([])
  const [relationship, setRelationship] = useState<
    Array<Omit<Catalog_Firm_Relationship, '__typename'>>
  >([])
  const [yearsPracticingLaw, setYearsPracticingLaw] = useState<
    Array<Omit<Catalog_Years_Practice, '__typename'>>
  >([])

  useEffect(() => {
    if (lawFirmCatalogs) {
      setCaseManagementSoftware(lawFirmCatalogs.case_management_software)
      setCompanySize(lawFirmCatalogs.catalog_company_size)
      setStates(lawFirmCatalogs.catalog_us_state)
      setRelationship(lawFirmCatalogs.catalog_firm_relationship)
      setYearsPracticingLaw(lawFirmCatalogs.catalog_years_practice)
    }
  }, [lawFirmCatalogs])

  const [
    updateAttorneyProfileMutation,
    { loading: attorneyProfileMutationLoading },
  ] = useUpdateAttorneyProfileMutation({
    refetchQueries: [AttorneyDocument, 'Attorney'],
  })

  const [deleteAttorneyProfileEducationItemsMutation] =
    useDeleteAttorneyProfileEducationItemsMutation({
      refetchQueries: [AttorneyDocument, 'Attorney'],
    })

  const { data: attorneyProfile, loading: attorneyProfileLoading } =
    useAttorneyQuery({
      variables: {
        filter: {
          id: {
            _eq: attorneyId,
          },
        },
        ...(!getAllLicenses && {
          licenseFilter: {
            status: {
              id: {
                _eq: LICENSE_STATUS.VALIDATED,
              },
            },
          },
        }),
        educationSort2: 'grade.name',
        licenseSort: 'created_ts',
      },
      skip: !attorneyId || skipQuery,
    })

  const organizationUserItemId =
    attorneyProfile?.attorney?.[0]?.user_id?.current_organization?.[0]?.id

  const { updateOrganizationUser } = useOrganization()

  // TODO: Replace this with updateProfile
  const updateEducation = useCallback(
    (data: Partial<Update_Attorney_Profile_Input>) =>
      updateAttorneyProfileMutation({
        variables: {
          updateAttorneyProfileItemId: profileId ?? '',
          data,
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateAttorneyProfileMutation]
  )

  const deleteEducations = useCallback(
    (data: string[]) =>
      deleteAttorneyProfileEducationItemsMutation({
        variables: {
          ids: data,
        },
      }),
    [deleteAttorneyProfileEducationItemsMutation]
  )

  const { data: grades, loading: gradesLoading } = useGradesQuery()

  const updateProfile = useCallback(
    ({ id = profileId ?? '', data }: TUpdateProfileInput) =>
      updateAttorneyProfileMutation({
        variables: {
          updateAttorneyProfileItemId: id,
          data,
        },
      }),
    [profileId, updateAttorneyProfileMutation]
  )

  const loading =
    lawFirmCatalogsLoading ||
    attorneyProfileLoading ||
    gradesLoading ||
    attorneyProfileMutationLoading

  return {
    attorneyId,
    profileId,
    organizationUserItemId,
    grades,
    attorneyProfile,
    caseManagementSoftware,
    states,
    companySize,
    relationship,
    yearsPracticingLaw,
    updateEducation,
    deleteEducations,
    deleteAttorneyProfileAwardItemsMutation,
    updateAttorneyProfileItemMutation,
    updateProfile,
    updateOrganizationUser,
    loading,
  }
}

export default useProfile

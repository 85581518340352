import { useFeatureFlags } from '@/optimizely/useFeatureFlags'
import { FeatureFlag } from '@/optimizely/types'
import CaseV1 from './Case'
import CaseV2 from './CaseV2'

const ActiveCasePage = () => {
  const featureFlags = useFeatureFlags()
  const isCasePageV2Enabled = featureFlags[FeatureFlag.MARKETPLACE_CASE_PAGE_V2]

  return isCasePageV2Enabled ? <CaseV2 /> : <CaseV1 />
}

const Case = () => <ActiveCasePage />

export default Case

import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import Box from '@mui/material/Box'
import { Theme } from '@mui/material/styles'
import { SystemStyleObject } from '@mui/system'

interface ErrorMessageProps {
  content?: string | JSX.Element
  title?: string
  sx?: SystemStyleObject<Theme>
}

const ErrorMessage = ({ title, content, sx }: ErrorMessageProps) => (
  <Box
    sx={theme => ({
      maxWidth: '450px',
      m: { xs: 3, md: `${theme.spacing(3)} auto` },
      ...(sx && sx),
    })}
  >
    <Alert
      icon={<WarningAmberIcon fontSize="inherit" />}
      variant="standard"
      severity="error"
      sx={({ palette }) => ({
        background: palette.error.light,
      })}
    >
      <Typography variant="subtitle1" color="error.dark">
        {title || 'We are currently validating your bar license'}
      </Typography>
      {content || (
        <Typography variant="body2" color="error.dark">
          This process is typically pretty quick, but can take up to one
          business day.
          <br />
          <br />
          <strong>
            There is no action required from you at this time. We will reach out
            to you if we need further information.
          </strong>
        </Typography>
      )}
    </Alert>
  </Box>
)

export default ErrorMessage

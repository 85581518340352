import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { FC, useContext, useRef, useState } from 'react'
import useTheme from '@mui/material/styles/useTheme'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MatchFeesModal, {
  SendProposalModalProps,
} from '@/components/Modals/MatchFeesModal'
import { ModalTypes } from '@/types'
import Alert from '@mui/material/Alert'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import CaseNotAvailableModal from '@/pages/MyReferrals/components/CaseNotAvailableModal/CaseNotAvailableModal'
import { useCaseMessageTrigger } from '@/components/GetStreamChat/useCaseMessageTrigger'
import { MyReferralsContext } from '../MyReferralsContext'
import { ReferralContext } from './ReferralContext'

export interface ProposalReceivedActionsProps {
  caseErrorModalOpen: boolean
  caseTaken: boolean
  isSubmittingMatchFee: boolean
  matchFeesModalOpen: boolean
  matchFeesModalProps: SendProposalModalProps
  setMatchFeesModalOpen: React.Dispatch<React.SetStateAction<ModalTypes>>
  otherSideAttorneyId: string
}

export const ProposalReceivedActions: FC<ProposalReceivedActionsProps> = ({
  caseErrorModalOpen,
  caseTaken,
  isSubmittingMatchFee,
  matchFeesModalOpen,
  matchFeesModalProps,
  setMatchFeesModalOpen,
  otherSideAttorneyId,
}) => {
  const theme = useTheme()
  const { isRefetching } = useContext(MyReferralsContext)
  const { caseEntry } = useContext(ReferralContext)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const menuTriggerRef = useRef<HTMLButtonElement>(null)
  const { handleTriggerClick } = useCaseMessageTrigger()

  const handleMenuTriggerClick = () => {
    setIsMenuOpen(true)
  }

  const handleMenuClose = () => {
    setIsMenuOpen(false)
  }

  const handleSendMessageClick = () => {
    setIsMenuOpen(false)
    handleTriggerClick({
      membersToMessage: [otherSideAttorneyId, ...(caseEntry.ownerships ?? [])],
    })
  }

  const handleRejectProposalClick = () => {
    setIsMenuOpen(false)
    setMatchFeesModalOpen('Reject')
  }

  const handleAcceptProposalButtonClick = () => {
    setMatchFeesModalOpen('Accept')
  }

  const handleCounterOfferButtonClick = () => {
    setMatchFeesModalOpen('Counteroffer')
  }

  return (
    <Box
      alignItems="center"
      display="flex"
      flexWrap="nowrap"
      columnGap={1}
      width={1}
    >
      <CaseNotAvailableModal
        open={caseErrorModalOpen}
        onClose={() => window.location.reload()}
      />
      <Backdrop
        sx={{
          color: theme.palette.common.white,
          zIndex: theme.zIndex.drawer + 1,
        }}
        open={isSubmittingMatchFee}
      >
        {isSubmittingMatchFee && <CircularProgress color="inherit" />}
      </Backdrop>
      {caseTaken && (
        <Alert severity="warning" sx={{ mb: 1 }}>
          You can’t send a proposal, another attorney from your law firm has an
          ongoing negotiation.
        </Alert>
      )}
      <Box
        display="flex"
        columnGap={1}
        flexDirection={{ md: 'row-reverse' }}
        flexGrow={1}
      >
        <Button
          variant="outlined"
          sx={{
            alignSelf: 'stretch',
            flexGrow: 1,
            flexBasis: { xs: 0, md: 'auto' },
          }}
          disabled={caseTaken || isRefetching}
          onClick={handleCounterOfferButtonClick}
        >
          Counteroffer
        </Button>
        <Button
          variant="contained"
          sx={{
            alignSelf: 'stretch',
            flexGrow: 1,
            flexBasis: { xs: 0, md: 'auto' },
          }}
          disabled={caseTaken || isRefetching}
          onClick={handleAcceptProposalButtonClick}
        >
          Accept
        </Button>
      </Box>
      <IconButton
        aria-label="more"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleMenuTriggerClick}
        ref={menuTriggerRef}
      >
        <MoreVertIcon
          sx={{
            fontSize: theme.spacing(2.5),
          }}
        />
      </IconButton>
      <Menu
        anchorEl={menuTriggerRef.current}
        open={isMenuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          role: 'listbox',
        }}
      >
        <MenuItem
          onClick={handleSendMessageClick}
          disabled={caseTaken || isRefetching}
        >
          <Typography>Send Message</Typography>
        </MenuItem>
        <MenuItem onClick={handleRejectProposalClick}>
          <Typography color={theme.palette.error.main}>
            Reject Proposal
          </Typography>
        </MenuItem>
      </Menu>
      {matchFeesModalOpen && <MatchFeesModal {...matchFeesModalProps} />}
    </Box>
  )
}

export const roleSpecs = {
  Administrators:
    'Administrators have full admin access to the organization, including adding new users, updating user access and billing. Administrators can be attorneys and will have additional permissions listed under the attorney user type below.',
  Attorneys:
    'Attorneys have profiles on Attorney Share. You have the option to give them permission to post referrals, submit proposals and accept referrals OR just be a profile on the platform where Administrators and/or Case Managers do the accepting of referrals and posting of cases.',
  'Case Managers':
    "Case Managers do not have profiles on Attorney Share, rather, they are able to submit inbound or outbound referrals on behalf of the organization's attorneys. They can choose which attorney they are working on behalf of on a case-by-case basis.",
}

export const getRoleCount = () => {
  const count = Object.keys(roleSpecs).length
  switch (count) {
    case 1:
      return 'is one role'
    case 2:
      return 'are two roles'
    case 3:
      return 'are three roles'
    case 4:
      return 'are four roles'
    default:
      return 'are multiple roles'
  }
}

export const getInviteRoles = () =>
  Object.keys(roleSpecs).map((role, i) => {
    if (i === 0) {
      return role
    }
    if (i === Object.keys(roleSpecs).length - 1) {
      return ` and ${role}.`
    }
    return `, ${role}`
  })

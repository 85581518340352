import { FC } from 'react'
import { Paper } from '@mui/material'
import { useHits } from 'react-instantsearch'
import AlgoliaHitList from './AlgoliaHitList'
import NoResultsDisplay from './NoResultsDisplay'

const MobileMarketplaceContent: FC = () => {
  const { items } = useHits()

  if (items.length === 0) {
    return <NoResultsDisplay />
  }

  return (
    <Paper sx={{ marginBottom: 4 }}>
      <AlgoliaHitList />
    </Paper>
  )
}

export default MobileMarketplaceContent

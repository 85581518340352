import React, { useCallback } from 'react'
import {
  Box,
  Typography,
  Button,
  Paper,
  useTheme,
  Grid,
  Divider,
} from '@mui/material'
import CloudUploadOutlined from '@mui/icons-material/CloudUploadOutlined'
import { useDropzone } from 'react-dropzone'
import { useCaseContext } from '../../hooks/useCaseContext'
import { FILE_UPLOAD_MAX_SIZE, APPROVED_FILE_TYPES } from '../../constants/case'

const DataroomFileUpload: React.FC = () => {
  const theme = useTheme()
  const { caseData } = useCaseContext()

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // TODO: Implement file upload logic in context/hook
    console.log('Uploading files:', acceptedFiles)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      APPROVED_FILE_TYPES,
    },
    maxSize: FILE_UPLOAD_MAX_SIZE,
  })

  if (!caseData?.dataroom?.access || caseData.dataroom.access !== 'edit')
    return null

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="subtitle2" component="h3" sx={{ mb: 2 }}>
        Add more files to Data Room
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        Any files you upload for this case are only viewable by you and any
        attorney(s) you grant access to.
      </Typography>
      <Paper
        elevation={0}
        sx={{
          border: `1px dashed ${theme.palette.divider}`,
          borderRadius: 1,
          backgroundColor: theme.palette.grey[50],
          p: 3,
        }}
      >
        <Box {...getRootProps()} sx={{ cursor: 'pointer' }}>
          <input {...getInputProps()} />
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={12}
              sm={5}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <Box
                sx={{
                  height: 52,
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <CloudUploadOutlined
                  sx={{
                    fontSize: 32,
                    color: theme.palette.text.disabled,
                    display: { xs: 'none', sm: 'block' },
                  }}
                />
              </Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ textAlign: 'center' }}
              >
                {isDragActive
                  ? 'Drop the files here'
                  : 'Drag and drop files here'}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              sx={{
                display: { xs: 'none', sm: 'flex' },
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                height: '100%',
              }}
            >
              <Divider
                orientation="vertical"
                sx={{
                  height: '100%',
                  borderColor: theme.palette.common.black,
                  borderRightWidth: 2,
                }}
              />
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  position: 'absolute',
                  px: 1,
                  backgroundColor: theme.palette.grey[50],
                }}
              >
                or
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <Box
                sx={{
                  height: 52,
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <Button variant="outlined" color="primary" sx={{ height: 36 }}>
                  Browse Files
                </Button>
              </Box>
              <Typography variant="body2" color="text.secondary">
                Maximum file size is {FILE_UPLOAD_MAX_SIZE / 1024 / 1024}MB
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  )
}

export default DataroomFileUpload

import { useState, useRef, useEffect, useCallback, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { MenuItemType, MyPagesLayout } from '@/components'
import { useGetAttorneysProfileByIdQuery } from '@/gql/appApi'
import useAttorneyId from '@/hooks/useAttorneyId'
import { useRestrictedUserPermissions } from '@/hooks/permissions/useRestrictedUserPermissions'
import {
  PATHS_WITH_SECTIONS,
  getRouteLabelByPathStr,
  labelToPath,
} from '@/router/RoutesUtils'
import { Box, useTheme } from '@mui/material'
import { useUsersMeQuery } from '@/gql/systemApi'
import GlobalContext from '@/context/GlobalContext'
import ChangePassword from './ChangePassword/ChangePassword'
import ChangeAccountEmail from './ChangeAccountEmail/ChangeAccountEmail'
import PersonalInformation from './PersonalInformation/PersonalInformation'
import Integrations from './Integrations/Integrations'

import {
  menuItems,
  getSubtitle,
  getAccountSettingsFirstLabel,
  ACCOUNT_SETTINGS_PAGES,
} from './utils/constants'
import useGetOrganizationLcmsData from './Integrations/components/shared/useGetOrganizationLcmsData'
import { PAGE } from './Integrations/types'
import useOrganizationData from './Shared/useOrganizationData'
import { getClioConnectionData } from './Integrations/components/Clio/utils'
import NotificationPreferences from './NotificationPreferences/NotificationPreferences'

const AccountSettings: React.FC = () => {
  const navigate = useNavigate()
  const { id: pageId } = useParams()
  const theme = useTheme()
  const globalContext = useContext(GlobalContext)
  const { isOrganizationAccount } = useRestrictedUserPermissions(
    'User',
    'inOrganization'
  )

  const { data: userMe, loading: usersMeLoading } = useUsersMeQuery()
  const userId = userMe?.users_me?.id
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = useRef(getAttorneyId())
  const [page, setPage] = useState<string | PAGE>(
    getRouteLabelByPathStr(menuItems, pageId) || getAccountSettingsFirstLabel()
  )
  const [menu, setMenu] = useState<MenuItemType[]>(menuItems)
  const [{ items }] = menu

  const { organizationId } = useOrganizationData()

  const { organizationData, loadingOrganizationData } =
    useGetOrganizationLcmsData({
      organizationId,
    })

  // Only start the filter process once we have all the data we need to prevent flickering
  const filterTabContent =
    !loadingOrganizationData && !usersMeLoading && organizationData && userId

  useEffect(() => {
    if (filterTabContent) {
      let hasAdminConnectedToClio = false
      let isCurrentUserConnected = false
      if (organizationData?.organization_connected_lcms.length && userId) {
        const {
          isCurrentUserConnectedToClio,
          hasAdminConnectedToClio: isAdminConnected,
        } = getClioConnectionData({
          organizationData,
          userId,
        })
        hasAdminConnectedToClio = isAdminConnected
        isCurrentUserConnected = isCurrentUserConnectedToClio
      }
      const data = menuItems[0].items.filter(f => {
        switch (f.label) {
          /** TODO: Uncomment when MEMBERSHIP page redesign with pricing is ready
           *  @see https://attorney.atlassian.net/browse/MP-1358
           */
          // case ACCOUNT_SETTINGS_PAGES.MEMBERSHIP:
          //   return !isOrganizationAccount
          case ACCOUNT_SETTINGS_PAGES.INTEGRATIONS:
            return (
              !isOrganizationAccount ||
              hasAdminConnectedToClio ||
              isCurrentUserConnected
            )
          default:
            return true
        }
      })
      setMenu([{ sectionTitle: '', items: data }])
    }
  }, [isOrganizationAccount, filterTabContent, organizationData, userId])

  useEffect(() => {
    if (menu && menu[0].items.length > 0) {
      // Establish the selected page based on the URL and update the global context
      // to reflect the selected tab in the UI.
      const selectedPage = getRouteLabelByPathStr(menu, pageId)
      globalContext?.setSelectedTab(selectedPage || menu[0].items[0].label)
      setPage(
        selectedPage || menu[0].items[0].label || menuItems[0].items[0].label
      )

      // If the URL does not match any of the available tabs, redirect to the default tab
      if (!selectedPage) {
        navigate(
          `${PATHS_WITH_SECTIONS.ACCOUNT_SETTINGS}/${labelToPath(
            menu[0].items[0].label || menuItems[0].items[0].label
          )}`
        )
      }
    }
  }, [globalContext, menu, navigate, pageId])

  const onClickItem = (item: string) => {
    setPage(item)
  }

  const attorneysProfileById = useGetAttorneysProfileByIdQuery({
    variables: {
      filter: {
        attorney_id: {
          id: {
            _eq: attorneyId.current,
          },
        },
      },
    },
  })

  const renderTab = useCallback((): JSX.Element | null => {
    switch (page) {
      case ACCOUNT_SETTINGS_PAGES.PERSONAL_INFORMATION:
        return (
          <PersonalInformation
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            profile={attorneysProfileById?.data?.attorney_profile?.[0]}
            title={page}
            subtitle={getSubtitle(page)}
          />
        )
      /** TODO: Uncomment when MEMBERSHIP page redesign with pricing is ready
       *  @see https://attorney.atlassian.net/browse/MP-1358
       */
      // case ACCOUNT_SETTINGS_PAGES.MEMBERSHIP:
      //   return <Membership />
      case ACCOUNT_SETTINGS_PAGES.CHANGE_PASSWORD:
        return <ChangePassword title={page} subtitle={getSubtitle(page)} />
      case ACCOUNT_SETTINGS_PAGES.NOTIFICATION_PREFERENCES:
        return (
          <NotificationPreferences title={page} subtitle={getSubtitle(page)} />
        )
      case ACCOUNT_SETTINGS_PAGES.CHANGE_ACCOUNT_EMAIL:
        return (
          <ChangeAccountEmail
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            profile={attorneysProfileById?.data?.attorney_profile?.[0]}
            title={page}
            subtitle={getSubtitle(page)}
          />
        )
      case ACCOUNT_SETTINGS_PAGES.INTEGRATIONS:
        return <Integrations page={page} />
      default:
        return null
    }
  }, [page, attorneysProfileById?.data?.attorney_profile])

  return (
    <MyPagesLayout
      menuItems={items.length > 0 ? menu : menuItems}
      onClickItem={onClickItem}
      contentSX={{
        overflow: 'auto',
        padding: 0,
        '&::-webkit-scrollbar': { display: 'none' },
      }}
      skipBottomBar
      useLinks={PATHS_WITH_SECTIONS.ACCOUNT_SETTINGS}
    >
      <Box
        sx={{
          width: '100%',
          '&::-webkit-scrollbar': { display: 'none' },
          overflowY: 'auto',
          padding: {
            xs: theme.spacing(0),
            md: theme.spacing(5),
          },
          marginBottom: {
            xs: theme.spacing(24),
            md: theme.spacing(0),
          },
        }}
      >
        {renderTab()}
      </Box>
    </MyPagesLayout>
  )
}

export default AccountSettings

import Paper, { PaperProps } from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import AnimatedNumber, { integerFormatter } from '@/components/AnimatedNumber'
import {
  animationDelay,
  animationDuration,
} from '@/components/Metrics/constants'

import { Referral } from './Referral'

export interface TotalReferralsProps extends PaperProps {
  inboundCases: number
  outboundCases: number
  marketplace: number
  waterfallReferrals: number
}

const TotalReferrals = ({
  inboundCases,
  outboundCases,
  marketplace,
  waterfallReferrals,
  ...rest
}: TotalReferralsProps) => {
  const theme = useTheme()

  return (
    <Paper {...rest}>
      <Grid
        container
        columns={7}
        columnSpacing={2}
        py={{ xs: 1.5, md: 4 }}
        px={{ xs: 1.5, md: 2 }}
      >
        <Grid
          item
          xs={7}
          md={1}
          sx={{ textAlign: { xs: 'left', md: 'center' } }}
        >
          <Typography variant="subtitle1" mb={1}>
            Total Referrals
          </Typography>
          <Typography
            sx={{
              fontSize: theme.typography.h1.fontSize,
              lineHeight: theme.typography.h1.lineHeight,
              fontWeight: theme.typography.fontWeightBold,
              fontFamily: theme.typography.h1.fontFamily,
            }}
          >
            <AnimatedNumber
              formatter={integerFormatter}
              startingNumber={0}
              delay={animationDelay}
              duration={animationDuration}
            >
              {inboundCases + outboundCases}
            </AnimatedNumber>
          </Typography>
        </Grid>
        <Grid item xs={7} md={3} my={{ xs: 1 }}>
          <Grid container direction="row" rowGap={1} px={{ md: 1 }}>
            <Grid item>
              <Typography
                variant="caption"
                color={theme.palette.text.secondary}
                display="block"
                mt={0.5}
                mb={-0.5}
              >
                Referrals by Origin
              </Typography>
            </Grid>
            <Referral
              rangeMax={Math.max(inboundCases, outboundCases)}
              value={inboundCases}
              barColor={theme.palette.secondary.main}
              label="Inbound Cases"
            />
            <Referral
              rangeMax={Math.max(inboundCases, outboundCases)}
              value={outboundCases}
              barColor={theme.palette.primary.main}
              label="Outbound Cases"
            />
          </Grid>
        </Grid>
        <Grid item xs={7} md={3} my={{ xs: 1 }}>
          <Grid container direction="row" rowGap={1} px={{ md: 1 }}>
            <Grid item>
              <Typography
                variant="caption"
                color={theme.palette.text.secondary}
                display="block"
                mt={0.5}
                mb={-0.5}
              >
                Referrals by Type
              </Typography>
            </Grid>
            <Referral
              rangeMax={Math.max(marketplace, waterfallReferrals)}
              value={marketplace}
              barColor={theme.palette.secondary.main}
              label="Marketplace"
            />
            <Referral
              rangeMax={Math.max(marketplace, waterfallReferrals)}
              value={waterfallReferrals}
              barColor={theme.palette.primary.main}
              label="Waterfall Referrals™"
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default TotalReferrals

import useLegacyReferralItems from '@/pages/MyReferralsV2/components/ReferralItems/hooks/useReferralItems'
import { useContext, useState } from 'react'
import useNegotiations from '@/pages/MyReferralsV2/NegotiationItem/hooks/useNegotiations'
import { MODAL_TYPES } from '@/types'
import {
  SendProposalModalProps,
  useMatchFeesModal,
} from '@/components/Modals/MatchFeesModal'
import { CASE_STATUS_FLOW, IMAGE_SIZES } from '@/constants'
import { getImagePath } from '@/utils'
import { timeService } from '@/services/Time/TimeService'
import { MyReferralsContext } from '../MyReferralsContext'
import { ProposalReceivedActionsProps } from './ProposalReceivedActions'
import { ReferralContext } from './ReferralContext'
import { useProposalSubmit } from './useProposalSubmit'
import { WatchedReferralActionsProps } from './WatchedReferralActions'

export const useBuyerReferral = () => {
  const { caseEntry, isCaseManagerWithoutPermission } =
    useContext(ReferralContext)

  const { refetch } = useContext(MyReferralsContext)
  const { getCaseParams, getNegotiationParams, userId, onStopWatching } =
    useLegacyReferralItems({
      refetch,
    })

  const caseParams = getCaseParams(caseEntry)
  const {
    casePurchaseWithPayment,
    caseMpPreferenceId,
    isInitiatingAttorney,
    isPrivate,
    negotiations,
  } = caseParams

  const negotiationParams = getNegotiationParams(
    negotiations[0],
    caseEntry,
    casePurchaseWithPayment
  )
  const {
    activityCreatedById = '',
    caseTaken,
    isCaseOwner,
    isInvited,
    paymentStatus,
    profile,
    representingAttorneyId,
    representingAttorneyUserId,
    type: negotiationType,
  } = negotiationParams

  /**
   * The user can accept the proposal without paying if:
   * - The user is the case owner
   * - The user is the invited attorney
   * - The user submitted a prepaid proposal (so no additional payment is required)
   */
  const noPaymentRequired =
    isInvited || isCaseOwner || paymentStatus.currentUserPaid

  const negotiationProps = useNegotiations({
    activityCreatedById,
    caseId: caseEntry.id,
    casePurchaseId: negotiations[0]?.id ?? '',
    isCaseOwner,
    isInvited,
    noPaymentRequired,
    paymentStatus,
    representingAttorneyId: representingAttorneyId ?? '',
    userId,
    userProfile: profile,
  })

  const avatar = getImagePath({
    id: profile?.avatar ?? null,
    size: IMAGE_SIZES.Small,
  })

  const feeOfferedByBuyer =
    caseEntry.case_purchase?.[0]?.case_purchase_activities.fee

  const [isSubmittingMatchFee, setIsSubmittingMatchFee] = useState(false)

  const {
    credits,
    discount,
    getCaseInformationProps,
    getConfirmationModalProps,
    modalOpen: matchFeesModalOpen,
    setOpenModal: setMatchFeesModalOpen,
    stripePriceId,
    type: modalType,
    ...rest
  } = useMatchFeesModal({
    avatar,
    caseId: caseEntry.id,
    caseTitle: caseEntry.name,
    fee: feeOfferedByBuyer ?? 0,
    firms: negotiationProps.firms,
    fullname: negotiationProps.fullname,
    isCaseOwner,
    isInvited,
    isPrivate,
    isQualified: negotiationProps.isQualified,
    isVetted: negotiationProps.isVetted,
    noPaymentRequired,
    onBehalfOfUserId: representingAttorneyUserId,
    paymentStatus,
    type: negotiationType,
  })

  const { handleSubmit } = useProposalSubmit({
    setMatchFeesModalOpen,
    setIsSubmittingMatchFee,
    negotiationType,
    onSubmitAction: negotiationProps.onSubmitAction,
    setResponse: negotiationProps.setResponse,
    modalType,
    offeredFee: feeOfferedByBuyer,
    stripePriceId,
    credits,
    discount,
  })

  const matchFeesModalProps: SendProposalModalProps = {
    CaseInformationProps: getCaseInformationProps(),
    ConfirmationModalProps: getConfirmationModalProps(),
    isCaseOwner,
    isInitiatingAttorney,
    type: modalType,
    onSubmit: handleSubmit,
    ...rest,
  }

  const proposalReceivedActionsProps: ProposalReceivedActionsProps = {
    caseErrorModalOpen: negotiationProps.caseErrorModalOpen,
    caseTaken,
    isSubmittingMatchFee,
    matchFeesModalOpen,
    matchFeesModalProps,
    setMatchFeesModalOpen,
    otherSideAttorneyId: caseEntry.attorney.user_id,
  }

  const watchedReferralActionsProps: WatchedReferralActionsProps = {
    caseErrorModalOpen: negotiationProps.caseErrorModalOpen,
    caseMpPreferenceId,
    caseTaken,
    stopWatching: onStopWatching,
    isSubmittingMatchFee,
    matchFeesModalOpen,
    matchFeesModalProps,
    otherSideAttorneyId: caseEntry.attorney.user_id,
  }

  const clientSigningResponseModalTypes: string[] = [
    MODAL_TYPES.CLIENT_SIGNED_CONFIRMATION,
    MODAL_TYPES.CLIENT_SIGNED_REJECTED,
  ]

  const isClientSigningResponseModal = clientSigningResponseModalTypes.includes(
    negotiationProps.response.type ?? ''
  )

  let waterfallProposalDueDate = new Date()
  let showWaterfallProposalDueDate = false

  const areNegotiationsTakingPlace = ![
    String(CASE_STATUS_FLOW.Matched),
    String(CASE_STATUS_FLOW.Signed),
  ].includes(caseEntry.status)

  const [casePurchase] = caseEntry.case_purchase

  if (isPrivate && areNegotiationsTakingPlace && casePurchase?.active_ts) {
    const timeStart = new Date(casePurchase.active_ts)
    const timeUnit = casePurchase.time_unit
    const timedAccessLimit = casePurchase.timed_access_limit

    if (timeUnit && timedAccessLimit) {
      showWaterfallProposalDueDate = true
      waterfallProposalDueDate = timeService.getEndDate(
        timeStart,
        timeUnit,
        timedAccessLimit
      )
    }
  }

  const showNegotiations =
    !isCaseManagerWithoutPermission && areNegotiationsTakingPlace

  const showDataRoom = false

  const showContactInformation =
    caseEntry.leads.length > 0 || !areNegotiationsTakingPlace

  const showCounterpartContactInformation =
    !isCaseManagerWithoutPermission || !areNegotiationsTakingPlace

  const showContactInformationInAccordion = areNegotiationsTakingPlace

  const showCaseManagerPermissionRequest =
    isCaseManagerWithoutPermission && areNegotiationsTakingPlace

  const showReferralContent =
    showNegotiations ||
    showContactInformation ||
    showCounterpartContactInformation ||
    showCaseManagerPermissionRequest

  const didBuyerTakeMostRecentAction =
    casePurchase?.case_purchase_activities.created_by_attorney.attorney_id ===
    casePurchase?.prospect_attorney_id

  return {
    areNegotiationsTakingPlace,
    buyerNegotiationParams: negotiationParams,
    buyerNegotiationProps: negotiationProps,
    didBuyerTakeMostRecentAction,
    feeOfferedByBuyer,
    handleStopWatching: onStopWatching,
    isClientSigningResponseModal,
    proposalReceivedActionsProps,
    showCaseManagerPermissionRequest,
    showContactInformation,
    showContactInformationInAccordion,
    showCounterpartContactInformation,
    showDataRoom,
    showNegotiations,
    showReferralContent,
    showWaterfallProposalDueDate,
    watchedReferralActionsProps,
    waterfallProposalDueDate,
  }
}

import Typography from '@mui/material/Typography'
import { theme } from '@/styles/theme'
import Box from '@mui/material/Box'
import Switch from '@mui/material/Switch'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { Link } from 'react-router-dom'
import JSnackBar from '@/components/atoms/JSnackBar'
import useReferCasesToWaterfalls from '../../hooks/useReferCasesToWaterfalls'

const ReferCasesToWaterfalls = () => {
  const {
    alert,
    handleClose,
    assignIntegrationCasesToWaterfalls,
    priorityAccessDuration,
    timeLimitCatalog,
    toggleAutomaticAssignment,
    updatePriorityAccessDuration,
  } = useReferCasesToWaterfalls()

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="subtitle1">
          Automatically refer cases to Waterfalls
        </Typography>
        <Typography variant="body2">
          Cases from your integration can be automatically assigned to a
          Waterfall before being published in the Marketplace. Select which of
          your Waterfalls will receive cases that match their jurisdictions and
          practice areas.
        </Typography>
        <Typography variant="caption" color={theme.palette.text.secondary}>
          To create and manage your Waterfalls and learn more about how they
          work, visit the{' '}
          <Link to="/">
            <span
              style={{
                color: theme.palette.primary.main,
                textDecoration: 'underline',
              }}
            >
              Waterfall Referrals™
            </span>
          </Link>
          page.
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" sx={{ mt: 2, mb: 1.5 }}>
        <Switch
          checked={assignIntegrationCasesToWaterfalls}
          onChange={toggleAutomaticAssignment}
        />
        <Typography variant="body2">
          Always assign integration cases to Waterfalls whenever possible
        </Typography>
      </Box>
      <Box display="flex" gap={2} alignItems="center">
        <Box>
          <Typography variant="subtitle2">Priority Access Duration</Typography>
          <Typography variant="caption" color={theme.palette.text.secondary}>
            How long each attorney will have privileged access to the case.
          </Typography>
        </Box>
        <FormControl sx={{ flexBasis: '130px' }}>
          <Select
            disabled={!assignIntegrationCasesToWaterfalls}
            value={priorityAccessDuration}
            onChange={(e: SelectChangeEvent<string>) =>
              updatePriorityAccessDuration(e.target.value)
            }
          >
            {timeLimitCatalog?.catalog_waterfall_duration?.map(item => (
              <MenuItem value={item.id} key={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <JSnackBar
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        horizontal="center"
        vertical="bottom"
        handleClose={handleClose}
      />
    </Box>
  )
}

export default ReferCasesToWaterfalls

import React from 'react'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import parse from 'html-react-parser'
import { useCaseContext } from '../../hooks/useCaseContext'

export const CaseDescription: React.FC = () => {
  const theme = useTheme()
  const { caseData } = useCaseContext()

  if (!caseData) return null

  const { description } = caseData
  if (!description) return null

  return (
    <Typography
      sx={{
        whiteSpace: 'break-spaces',
        '& p': {
          minHeight: theme.spacing(2),
        },
        '& ol': {
          mx: 3,
          listStyle: 'revert',
        },
        '& ul': {
          mx: 3,
          listStyle: 'revert',
        },
      }}
      variant="body1"
      component="div"
    >
      {parse(description)}
    </Typography>
  )
}

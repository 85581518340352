import { useFormContext } from 'react-hook-form'
import JTextField from '@/components/atoms/JTextField'
import WaterfallModalLayout from './WaterfallModalLayout'

const WaterfallName = () => {
  const { control } = useFormContext()

  return (
    <WaterfallModalLayout
      title="Waterfall Name"
      description="Assign a name that describes the Waterfall."
    >
      <JTextField
        control={control}
        name="waterfallName"
        placeholder="Waterfall Name (e.g., Personal injury, California)"
        label="Waterfall Name"
        fullWidth
        rules={{
          required: 'Please specify a Waterfall Name.',
        }}
      />
    </WaterfallModalLayout>
  )
}

export default WaterfallName

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useState } from 'react'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import TableRow from '@mui/material/TableRow'

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { useSizes } from '@/hooks/useSizes'

import request from '@/axios/config'
import setAuthToken from '@/axios/setAuthToken'

// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { useDeleteCaseFileMutation, Case_File } from '@/gql/systemApi'
import { ConfirmationModal } from '@/components'
import {
  SessionKey,
  sessionStorageService,
} from '@/services/SessionStorage/SessionStorageService'
import useRemoveActions from './useRemoveActions'

interface FileViewerProps {
  isOwner: boolean
  caseId: string
  caseName: string
  files?: Case_File[]
  refetchCaseData: () => void
}

const FilesViewer = ({
  isOwner,
  caseId,
  caseName,
  files = [],
  refetchCaseData,
}: FileViewerProps) => {
  const [isWorking, setIsWorking] = useState(false)
  const { isLessMd } = useSizes()

  const refreshData = async () => {
    await refetchCaseData()
  }

  const onDownloadFile = async (url: string, filename: string) => {
    setIsWorking(true)
    try {
      const token = sessionStorageService.getItem(SessionKey.TOKEN)
      setAuthToken(token)
      const response = await request.get(url, {
        responseType: 'blob',
      })

      const href = window.URL.createObjectURL(new Blob([response.data]))
      const anchorElement = document.createElement('a')
      anchorElement.href = href
      anchorElement.setAttribute('download', filename)
      document.body.appendChild(anchorElement)
      anchorElement.click()

      document.body.removeChild(anchorElement)
      window.URL.revokeObjectURL(href)
      setIsWorking(false)
    } catch (error) {
      setIsWorking(false)
    }
  }

  const onDownloadAllHandler = async () => {
    const downloadAllFilesPath = `dataroom/files/case/${caseId}`
    onDownloadFile(downloadAllFilesPath, `case_dataroom_${caseName}.zip`)
  }

  const onDownloadHandler = async (fileId: string, fileName: string) => {
    const downloadFilePath = `assets/${fileId}?download`
    onDownloadFile(downloadFilePath, fileName)
  }

  const onDeleteAllFilesHandler = async () => {
    const deleteAllFilesPath = `dataroom/files/case/${caseId}`

    setIsWorking(true)
    try {
      const token = sessionStorageService.getItem(SessionKey.TOKEN)
      setAuthToken(token)
      await request.delete(deleteAllFilesPath)

      await refreshData()
      setIsWorking(false)
    } catch (error) {
      setIsWorking(false)
    }
  }

  const [updateUsersMeMutation] = useDeleteCaseFileMutation({})

  const onDeleteFileHandler = (fileId: string) => {
    setIsWorking(true)

    updateUsersMeMutation({
      variables: {
        deleteFilesItemId: fileId,
      },
    })

    refreshData()
    setIsWorking(false)
  }

  const { modal, onRemove, onRemoveAll, onCloseModal, onSubmitModal } =
    useRemoveActions({
      removeAllFunction: onDeleteAllFilesHandler,
      removeFunction: onDeleteFileHandler,
    })

  if (files.length === 0) return null

  return (
    <Grid container sx={theme => ({ marginTop: theme.spacing(2) })}>
      <Grid item xs={5}>
        <Typography variant="h6">Data Room</Typography>
      </Grid>
      {files.length > 1 && (
        <Grid item xs={7}>
          <Box
            sx={{
              textAlign: 'right',
            }}
          >
            <Button
              startIcon={<FileDownloadOutlinedIcon />}
              onClick={() => onDownloadAllHandler()}
              {...(isWorking ? { disabled: true } : {})}
            >
              Download All
            </Button>
            {isOwner && (
              <Button
                startIcon={<DeleteOutlineIcon />}
                color="error"
                onClick={() => onRemoveAll('1234')}
                {...(isWorking ? { disabled: true } : {})}
              >
                Remove All
              </Button>
            )}
          </Box>
        </Grid>
      )}
      <Grid item xs={12} sx={{ mt: 2.5 }}>
        <TableContainer component={Paper}>
          <Table aria-label="custom pagination table">
            <TableBody>
              {files.map(({ file_id = {} }) => {
                const fileName = file_id?.filename_download || ''
                const fileId = file_id?.id || ''

                return (
                  <TableRow key={`fileViewer-item-${fileId}`}>
                    <TableCell style={{ width: 20 }}>
                      <InsertDriveFileOutlinedIcon fontSize="small" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {fileName}
                    </TableCell>
                    <TableCell
                      align="right"
                      padding="checkbox"
                      style={{ width: 50 }}
                    >
                      <Button
                        startIcon={<FileDownloadOutlinedIcon />}
                        onClick={() => onDownloadHandler(fileId, fileName)}
                        {...(isWorking ? { disabled: true } : {})}
                      >
                        {isLessMd ? '' : 'Download'}
                      </Button>
                    </TableCell>
                    {isOwner && (
                      <TableCell
                        align="right"
                        padding="checkbox"
                        style={{ width: 50 }}
                      >
                        <Button
                          startIcon={<DeleteOutlineIcon />}
                          color="error"
                          onClick={() => onRemove(fileId, fileName)}
                          {...(isWorking ? { disabled: true } : {})}
                        >
                          {isLessMd ? '' : 'Remove'}
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {modal.open && (
        <ConfirmationModal
          open={modal.open}
          title={modal.title as string}
          onClose={onCloseModal}
          AcceptButtonProps={{
            children: modal.buttonText,
            onClick: onSubmitModal,
          }}
        >
          {modal.description}
        </ConfirmationModal>
      )}
    </Grid>
  )
}

export default FilesViewer

import List from '@mui/material/List'
import ListItem from './ListRequestAccessItem'
import { TAccessRequests, TUpdateRequests } from './hooks/useAccessRequests'

export interface ListRequestAccessProps {
  requests: TAccessRequests[]
  hasAccess?: boolean
  onGiveAccess?: (params: TUpdateRequests) => void
  onDenyAccess: (params: TUpdateRequests) => void
  loading?: boolean
}

const ListRequestAccess = ({
  requests,
  hasAccess,
  onGiveAccess,
  onDenyAccess,
  loading,
}: ListRequestAccessProps) => (
  <List>
    {requests.map(req => (
      <ListItem
        key={req.id}
        request={req}
        hasAccess={hasAccess}
        onGiveAccess={onGiveAccess}
        onDenyAccess={onDenyAccess}
        loading={loading}
        sx={{
          boxShadow: theme => theme.shadows[1],
        }}
      />
    ))}
  </List>
)

export default ListRequestAccess

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid, { GridProps } from '@mui/material/Grid'
import { useContext } from 'react'
import { useSizes } from '@/hooks/useSizes'
import { Attorney } from '@/pages/MyReferralsV2/types'
import { CASE_STATUS_FLOW } from '@/constants'
import useTheme from '@mui/material/styles/useTheme'
import { ReferralContext } from './ReferralContext'
import { JurisdictionAndPracticeArea } from './JurisdictionAndPracticeArea'
import { InvolvedAttorneys } from './InvolvedAttorneys'

interface SummaryHeaderProps extends GridProps {
  counterparts: Attorney[]
  waterfallProposalDueDate: Date
  showWaterfallProposalDueDate: boolean
  fee: number | undefined
}

export const SummaryHeader = ({
  counterparts,
  waterfallProposalDueDate,
  showWaterfallProposalDueDate,
  fee,
  ...rest
}: SummaryHeaderProps) => {
  const theme = useTheme()
  const { isLessMd } = useSizes()
  const {
    caseEntry: { name, status, case_purchase: casePurchase },
    isUserSeller,
    showUserSidePrincipal,
  } = useContext(ReferralContext)

  const isCaseExpired = status === CASE_STATUS_FLOW.Expired
  const showNoProposalsReceived =
    isUserSeller && casePurchase.length === 0 && !isCaseExpired

  return (
    <Grid
      container
      columnSpacing={2}
      rowSpacing={{
        xs: 1.25,
        md: 0.75,
      }}
      {...rest}
    >
      <Grid
        item
        xs={12}
        lg={showUserSidePrincipal ? 4 : 6}
        display="flex"
        justifyContent="space-between"
        alignItems={{
          md: 'center',
        }}
        // Needed to prevent attorney avatars from obscuring clickable case link
        zIndex={1}
      >
        <Box
          display="flex"
          flexDirection="column"
          overflow="hidden"
          mb={{
            md: -0.5,
          }}
          pr={1}
        >
          <Typography
            variant="subtitle2"
            mt={{
              xs: 0.5,
              lg: 0,
            }}
          >
            {name}
          </Typography>
          {isLessMd && <JurisdictionAndPracticeArea condense />}
        </Box>
        <Typography
          variant="subtitle1"
          mt={{
            xs: 0.5,
            mt: 0,
          }}
        >
          {
            // NOTE: The &nbsp; is needed to preserve a consistent layout
          }
          {typeof fee === 'number' && !isCaseExpired ? `${fee}%` : <>&nbsp;</>}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        lg={showUserSidePrincipal ? 8 : 6}
        spacing={2}
        display="flex"
        alignItems="center"
      >
        {showNoProposalsReceived && (
          <Grid item xs={12} md={7}>
            <Typography variant="body2" color={theme.palette.text.disabled}>
              No Proposals Received
            </Typography>
          </Grid>
        )}
        {!showNoProposalsReceived && (
          <InvolvedAttorneys
            item
            xs={12}
            md={7}
            mt={{
              xs: 0.5,
              lg: '2px',
            }}
            condense={isLessMd}
            counterparts={counterparts}
            waterfallProposalDueDate={waterfallProposalDueDate}
            showWaterfallProposalDueDate={showWaterfallProposalDueDate}
          />
        )}
        {!isLessMd && (
          <Grid
            item
            xs={12}
            md={5}
            display="flex"
            alignItems="center"
            justifyContent={{
              md: 'end',
            }}
            mt={{
              md: '6px',
              lg: '4px',
            }}
          >
            <JurisdictionAndPracticeArea />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

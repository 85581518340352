import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const JusticeHQ = ({ height = 47, width = 60, ...props }: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 47 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 0H46.5V37.9412C46.5 40.7102 45.618 44.2303 43.839 47.6409L38.0131 41.8299C38.6821 40.3968 39.1475 39.0406 39.4231 37.9412V7.94118H0.5V0ZM32.7527 48.9359C30.5436 50.783 27.7719 52.0588 24.3846 52.0588C15.1846 52.0588 9.9359 42.6471 8.46154 37.9412V31.7647H19.9615V37.9412L23.5 41.4706L27.0385 37.9412V15.8824H19.9615V23.8235H8.46154V15.8824H0.5V37.9412C0.5 45.8824 8.46154 60 24.3846 60C30.2601 60 34.9465 57.7934 38.4657 54.6342L32.7527 48.9359Z"
        fill="black"
      />
    </svg>
  </SvgIcon>
)

export default JusticeHQ

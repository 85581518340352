import { DataRoom } from '@/pages/MyReferralsV2/types'

interface UseDataRoomRequestProps {
  dataRoomRequest: DataRoom
}
export const useDataRoomRequest = ({
  dataRoomRequest,
}: UseDataRoomRequestProps) => {
  const { created_by_attorney: createdByAttorney } = dataRoomRequest

  const requestDate = new Date(dataRoomRequest.created_ts)
  const requestDateString = new Intl.DateTimeFormat(undefined, {
    day: '2-digit',
    month: 'short',
  }).format(requestDate)
  const requestTimeString = new Intl.DateTimeFormat(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).format(requestDate)
  const requestDateTimeString = `${requestDateString} - ${requestTimeString.toLowerCase()}`

  if (!createdByAttorney) {
    throw new TypeError(`createdByAttorney is ${typeof createdByAttorney}`)
  }

  return { createdByAttorney, requestDateString, requestDateTimeString }
}

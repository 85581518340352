import ArrowBack from '@mui/icons-material/ArrowBack'
import Close from '@mui/icons-material/Close'
import Search from '@mui/icons-material/Search'

import Alert from '@mui/material/Alert'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import InputAdornment from '@mui/material/InputAdornment'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { Controller, useForm } from 'react-hook-form'

import { useSizes } from '@/hooks/useSizes'
import { useCallback } from 'react'
import LogoBar from '@/pages/CreateAccount/shared/LogoBar'
import { usePracticeAreas } from './hooks/usePracticeAreas'
import { ChipList } from './components/ChipList'

type PracticeAreasProps = {
  initialPracticeAreas: string[]
  secondaryBtnAction: () => void
  onSubmit: (practiceAreas: string[]) => void
  loading: boolean
  secondayBtnText?: string
}

const PracticeAreas = ({
  initialPracticeAreas,
  secondaryBtnAction,
  onSubmit,
  loading: callbackLoading,
  secondayBtnText = 'Skip',
}: PracticeAreasProps) => {
  const { isLessMd } = useSizes()
  const { handleSubmit, control } = useForm()

  const {
    practiceAreas,
    groupedOptions,
    sortedOptions,
    filteredOptions,
    sortedDefault,
    chipData,
    isFullScreen,
    searchText,
    setSearchText,
    handleCheckboxClick,
    handleFullScreen,
    handleCloseFullScreen,
    handleSearchChange,
    loading,
    error,
  } = usePracticeAreas(initialPracticeAreas)

  const handleSubmitForm = useCallback(() => {
    const filteredData = practiceAreas
      .filter(
        area =>
          area.name != null &&
          (chipData.includes(area.name) || chipData.includes(area.id))
      )
      .map(area => area.id)
    onSubmit(filteredData)
  }, [chipData, onSubmit, practiceAreas])

  return (
    <>
      <Box
        sx={{
          height: '100%',
          overflow: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
          paddingBottom: 14,
        }}
      >
        {isFullScreen ? (
          <Dialog
            fullScreen={isLessMd}
            open={isFullScreen}
            onClose={handleCloseFullScreen}
            sx={{
              marginTop: 9,
            }}
          >
            <AppBar
              sx={theme => ({
                width: '100%',
                backgroundColor: theme.palette.background.page,
              })}
            >
              <DialogTitle
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 1,
                }}
              >
                <IconButton onClick={handleCloseFullScreen} color="inherit">
                  <ArrowBack
                    sx={theme => ({ color: theme.palette.primary.main })}
                  />
                </IconButton>
                <TextField
                  placeholder="Search"
                  variant="outlined"
                  fullWidth
                  value={searchText}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search color="disabled" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setSearchText('')}
                          color="inherit"
                        >
                          <Close />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </DialogTitle>
            </AppBar>
            {filteredOptions.length ? (
              filteredOptions.map(option => (
                <Box
                  key={option}
                  onClick={() => handleCheckboxClick(option)}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    onClick={() => handleCheckboxClick(option)}
                    checked={sortedDefault.includes(option)}
                  />
                  <ListItemText primary={option} />
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h6">
                  No results found for “{searchText}”
                </Typography>
              </Box>
            )}
          </Dialog>
        ) : (
          <>
            <Box sx={{ marginRight: { xs: 0, md: 2 }, pb: { md: 5 } }}>
              <LogoBar />
              {error?.message && (
                <Alert severity="error">{error?.message}</Alert>
              )}
              <Typography
                sx={{
                  textAlign: 'left',
                  marginBottom: {
                    xs: 5,
                    md: 0,
                  },
                }}
                variant={isLessMd ? 'h6' : 'h5'}
              >
                Practice Areas
              </Typography>
              {loading && <CircularProgress />}
            </Box>
            {practiceAreas.length > 0 && (
              <form>
                <Box sx={{ marginRight: { xs: 0, md: 2 } }}>
                  <Controller
                    name="search"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={sortedOptions}
                        groupBy={option => option.charAt(0).toUpperCase()}
                        renderOption={(_props, option) => (
                          <Box
                            key={option}
                            onClick={() => handleCheckboxClick(option)}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                              width: 'fit-content',
                            }}
                          >
                            <Checkbox
                              checked={sortedDefault.includes(option)}
                            />
                            <ListItemText primary={option} />
                          </Box>
                        )}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder="Search"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Search color="disabled" />
                                </InputAdornment>
                              ),
                            }}
                            {...(isLessMd ? { onFocus: handleFullScreen } : {})}
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        value=""
                        freeSolo
                      />
                    )}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      mt: 2,
                    }}
                  >
                    <ChipList
                      groupedOptions={groupedOptions}
                      sortedDefault={sortedDefault}
                      handleCheckboxClick={handleCheckboxClick}
                    />
                  </Box>
                </Box>
              </form>
            )}
          </>
        )}
      </Box>
      {!isFullScreen && (
        <Box
          sx={{
            position: 'fixed',
            width: { xs: '100%', md: '54%' },
            bottom: {
              xs: 0,
            },
            right: 0,
            boxShadow: '0px -4px 16px -2px rgba(0, 0, 0, 0.25)',
            backgroundColor: 'background.page',
            zIndex: 1,
            py: 2.5,
            px: { xs: 1.5, md: 5 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              justifyContent: 'center',
              ...(isLessMd && {
                justifyContent: 'space-between',
              }),
            }}
          >
            <Button
              size="large"
              fullWidth
              onClick={secondaryBtnAction}
              disabled={callbackLoading}
              sx={{
                width: {
                  xs: '35%',
                  md: '50%',
                },
                maxWidth: 290,
              }}
            >
              {secondayBtnText}
            </Button>
            <Button
              size="large"
              type="submit"
              variant="contained"
              disabled={callbackLoading}
              onClick={handleSubmit(handleSubmitForm)}
              sx={{
                width: {
                  xs: '65%',
                  md: '50%',
                },
                maxWidth: 290,
              }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      )}
    </>
  )
}

export default PracticeAreas

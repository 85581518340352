import Typography from '@mui/material/Typography'
import { IJProfileItemProps } from './types'
import * as S from './styles'

const JProfileItem = ({
  text,
  icon,
  reverseRow = false,
}: IJProfileItemProps) => (
  <S.ListItem reverseRow={reverseRow}>
    {icon}
    <Typography variant="body2">{text}</Typography>
  </S.ListItem>
)

export default JProfileItem

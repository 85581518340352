import { Box, Container, Typography, useTheme } from '@mui/material'
import { useFeatureFlags } from '@/optimizely/useFeatureFlags'
import { FeatureFlag } from '@/optimizely/types'
import { useOptimizelyVariable } from '@/optimizely/useOptimizelyVariable'
import parse from 'html-react-parser'

const MAX_ROWS_DESKTOP = 1
const MAX_ROWS_MOBILE = 3

const JNotificationBanner = () => {
  const theme = useTheme()
  const featureFlags = useFeatureFlags()
  const isNotificationBannerEnabled =
    featureFlags[FeatureFlag.NOTIFICATION_BANNER] ?? false
  const bannerContent = useOptimizelyVariable(
    FeatureFlag.NOTIFICATION_BANNER,
    'banner_content',
    ''
  )

  if (!isNotificationBannerEnabled || !bannerContent) return null

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        paddingBlock: theme.spacing(2.5),
        width: '100%',
        textAlign: 'left',
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="body2"
          color={theme.palette.primary.main}
          component="span"
          sx={{
            a: {
              fontWeight: 'bold',
              textDecoration: 'underline',
            },
          }}
        >
          <Box
            component="span"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: {
                xs: MAX_ROWS_MOBILE,
                md: MAX_ROWS_DESKTOP,
              },
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {parse(bannerContent)}
          </Box>
        </Typography>
      </Container>
    </Box>
  )
}

export default JNotificationBanner

import React from 'react'
import {
  Box,
  Typography,
  List,
  ListItem,
  IconButton,
  Paper,
  useTheme,
  Avatar,
} from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useCaseContext } from '../../hooks/useCaseContext'

const DataroomUserAccess: React.FC = () => {
  const { caseData } = useCaseContext()
  const theme = useTheme()

  if (!caseData?.dataroom) return null

  const { approved_access_requests, access } = caseData.dataroom

  if (!access || access !== 'edit') return null
  if (!approved_access_requests || approved_access_requests.length === 0)
    return null

  const iconStyle = {
    fontSize: theme.typography.body2.fontSize,
    height: theme.typography.body2.lineHeight,
    width: 'auto',
  }

  const handleRemoveAccess = (userId: string) => {
    // TODO: Implement remove access functionality in context/hook
    console.log('Remove access for user:', userId)
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="subtitle2" component="h3" sx={{ mb: 2 }}>
        Users with access to Data Room
      </Typography>
      <Paper
        elevation={0}
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
        }}
      >
        <List disablePadding>
          {approved_access_requests.map((approvedAccessRequest, index) => {
            const { user, id } = approvedAccessRequest
            return (
              <ListItem
                key={id}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="remove access"
                    size="small"
                    onClick={() => handleRemoveAccess(id)}
                  >
                    <DeleteOutlineIcon color="error" sx={iconStyle} />
                  </IconButton>
                }
                divider={index !== approved_access_requests.length - 1}
                sx={{ borderColor: theme.palette.divider }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <Avatar
                    src={user.avatar_image || ''}
                    alt={user.name}
                    sx={{
                      marginRight: theme.spacing(1),
                      width: theme.spacing(3),
                      height: theme.spacing(3),
                      fontSize: '0.75rem',
                      flexShrink: 0,
                    }}
                  >
                    {user.name.charAt(0)}
                  </Avatar>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      minWidth: 0, // Allow child elements to shrink below their content size
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color="text.primary"
                      sx={{
                        mr: theme.spacing(1),
                        flexShrink: 0,
                      }}
                    >
                      {user.name}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="text.disabled"
                      sx={{
                        flexGrow: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {user.firm.name}
                    </Typography>
                  </Box>
                </Box>
              </ListItem>
            )
          })}
        </List>
      </Paper>
    </Box>
  )
}

export default DataroomUserAccess

import { IMyReferralsContext } from './types'

export const initialContextValue: IMyReferralsContext = {
  rawCaseEntries: [],
  caseEntries: [],
  error: null,
  getIsSameOrg: () => false,
  isLoading: false,
  isRefetching: false,
  refetch: async () => {
    throw new Error('Unimplemented')
  },
  needsYourAttentionData: [],
  responseModal: {
    open: false,
    type: 'Accept',
  },
  filters: {
    searchText: '',
    caseType: '',
    jurisdiction: '',
    counterpart: '',
  },
  setFilters: () => {},
  watchedCases: [],
  organizationData: undefined,
}

import { SxProps, Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'

import JNotificationBanner from '@/components/atoms/JNotificationBanner'
import ApplicationBar from '../AppBar'
import GetStreamChatDrawer from '../GetStreamChat/GetStreamChatDrawer'

interface ILayoutProps {
  children?: React.ReactNode
  sx?: SxProps<Theme>
  skipBottomBar?: boolean
}

export const pageLayoutBodyClass = 'body_layout'

const Layout: React.FC<ILayoutProps> = ({
  children,
  sx,
  skipBottomBar,
}: ILayoutProps) => (
  <Box className="root_layout">
    <GetStreamChatDrawer />
    <ApplicationBar skipBottomBar={skipBottomBar} />
    <Box
      className={pageLayoutBodyClass}
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundColor: theme => theme.palette.background.page,
        height: '100vh',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <JNotificationBanner />
      <Box
        maxWidth={{
          xs: '100%',
          md: 'xl',
        }}
        sx={{
          marginInline: {
            xs: 0,
            md: 'auto',
          },
        }}
      >
        <Box sx={Array.isArray(sx) ? sx : [sx]}>{children}</Box>
      </Box>
    </Box>
  </Box>
)

export default Layout

import React from 'react'
import { Box } from '@mui/material'
import { ActionButtons } from './components/Sidebar/Header/ActionButtons'
import { useCaseContext } from './hooks/useCaseContext'

// Layout component that handles the positioning of action buttons in a case view
// Provides a mobile-responsive layout with fixed action buttons at the bottom on small screens
export const CaseLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { caseData } = useCaseContext()
  // Track the height of action buttons for proper content padding
  const [actionButtonsHeight, setActionButtonsHeight] = React.useState(0)
  const actionButtonsRef = React.useRef<HTMLDivElement>(null)

  // Dynamically adjust padding when action buttons height changes or on window resize
  React.useEffect(() => {
    const updateHeight = () => {
      if (actionButtonsRef.current) {
        setActionButtonsHeight(actionButtonsRef.current.offsetHeight)
      }
    }

    updateHeight()
    window.addEventListener('resize', updateHeight)
    return () => window.removeEventListener('resize', updateHeight)
  }, [])

  // Early return if no sidebar header data is available
  if (!caseData?.sidebar?.header) return children

  const { header } = caseData.sidebar
  // Check if there are any active actions or message channels
  const hasActions = Object.values(header.actions).some(action => action)

  // Early return if no actions or message channels are present
  if (!hasActions && !header.message_channels?.length) return children

  return (
    <>
      {/* Main content container with dynamic bottom padding on mobile */}
      <Box sx={{ pb: { xs: `${actionButtonsHeight}px`, md: 0 } }}>
        {children}
      </Box>
      {/* Fixed action buttons container, visible only on mobile */}
      <Box
        ref={actionButtonsRef}
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          p: 2,
          bgcolor: 'background.paper',
          borderTop: 1,
          borderColor: 'divider',
          display: { xs: 'block', md: 'none' }, // Show on mobile, hide on desktop
          zIndex: 1200,
        }}
      >
        <ActionButtons
          actions={header.actions}
          messageChannels={header.message_channels}
        />
      </Box>
    </>
  )
}

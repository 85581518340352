import { useMemo, useState } from 'react'
import {
  GetWaterfallListsDocument,
  useCreateWaterfallListMutation,
  useUpdateWaterfallListMutation,
  useCatalogUsStateQuery,
  useCatalogPracticeAreaQuery,
  useCreateOrganizationWaterfallListMutation,
  GetOrganizationWaterfallListsDocument,
  useUpdateOrganizationWaterfallListMutation,
} from '@/gql/appApi'
import { useForm } from 'react-hook-form'
import { TCreateOrEditWaterfallForm } from './types'
import { WaterfallList } from '../../types'

export interface IUseCreateOrEditWaterfallModalProps {
  waterfall?: WaterfallList
  isFirmSettings?: boolean
  onClose: () => void
  onSuccess: () => void
  onError: () => void
  organizationId?: string
  jurisdictionAndPracticeAreasConditions?: {
    practiceAreasInUsePerJurisdiction: Record<string, string[]>
    jurisdictionsWithWaterfallsWithNoPracticeAreas: Set<string>
  }
}

const useCreateOrEditWaterfallModal = ({
  waterfall,
  isFirmSettings,
  onClose,
  onSuccess,
  onError,
  organizationId,
  jurisdictionAndPracticeAreasConditions,
}: IUseCreateOrEditWaterfallModalProps) => {
  const {
    practiceAreasInUsePerJurisdiction,
    jurisdictionsWithWaterfallsWithNoPracticeAreas,
  } = jurisdictionAndPracticeAreasConditions || {}
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data: usStates, loading: loadingUsStatesCatalog } =
    useCatalogUsStateQuery({
      variables: { sort: 'code' },
    })
  const { data: practiceAreas, loading: loadingPracticeAreasCatalog } =
    useCatalogPracticeAreaQuery({
      variables: {
        sort: ['name'],
      },
    })

  const catalogUsState = useMemo(
    () => usStates?.catalog_us_state || [],
    [usStates]
  )

  const catalogPracticeAreas = useMemo(
    () => practiceAreas?.catalog_practice_area || [],
    [practiceAreas]
  )

  const [
    createOrgWaterfallListMutation,
    { loading: createOrgWaterfallLoading },
  ] = useCreateOrganizationWaterfallListMutation({
    refetchQueries: [
      GetOrganizationWaterfallListsDocument,
      'getOrgWaterfallLists',
    ],
  })

  const [
    updateOrgWaterfallListMutation,
    { loading: updateOrgWaterfallLoading },
  ] = useUpdateOrganizationWaterfallListMutation({
    refetchQueries: [
      GetOrganizationWaterfallListsDocument,
      'getOrgWaterfallLists',
    ],
  })

  const [
    createAttorneyWaterfallList,
    { loading: createAttorneyWaterfallLoading },
  ] = useCreateWaterfallListMutation({
    refetchQueries: [GetWaterfallListsDocument, 'getWaterfallLists'],
  })

  const [updateWaterfallListMutation, { loading: updateLoading }] =
    useUpdateWaterfallListMutation({
      refetchQueries: [GetWaterfallListsDocument, 'getWaterfallLists'],
    })

  const methods = useForm<TCreateOrEditWaterfallForm>({
    defaultValues: {
      ...(isFirmSettings && {
        jurisdiction: waterfall?.jurisdiction?.id || '',
        practiceAreas:
          waterfall?.practiceAreas?.map(practiceArea => practiceArea?.id) || [],
        enabledForAttorneys: waterfall?.enabledForAttorneys ?? true,
      }),
      waterfallName: waterfall?.waterfallName || '',
      waterfallMembers:
        waterfall?.waterfallMembers?.map(item => item?.attorneyId || '') ||
        null,
    },
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  // If practiceAreasInUsePerJurisdiction is provided, filter out the practice areas that are already in use,
  // except for the ones that are already in use by the current waterfall and depending on the jurisdiction selected.
  const jurisdictionId = methods.watch('jurisdiction')

  const filteredPracticeAreas = useMemo(() => {
    if (practiceAreasInUsePerJurisdiction && jurisdictionId) {
      const practiceAreasInUse =
        practiceAreasInUsePerJurisdiction[jurisdictionId]
      if (practiceAreasInUse) {
        return catalogPracticeAreas.filter(
          practiceArea =>
            !practiceAreasInUse.includes(practiceArea.id) ||
            waterfall?.practiceAreas?.find(item => item.id === practiceArea.id)
        )
      }
    }
    return catalogPracticeAreas
  }, [
    catalogPracticeAreas,
    jurisdictionId,
    practiceAreasInUsePerJurisdiction,
    waterfall,
  ])

  const isPracticeAreasRequired = useMemo(() => {
    if (
      !isFirmSettings ||
      !jurisdictionId ||
      !jurisdictionsWithWaterfallsWithNoPracticeAreas
    ) {
      return false
    }
    return jurisdictionsWithWaterfallsWithNoPracticeAreas.has(jurisdictionId)
  }, [
    jurisdictionId,
    isFirmSettings,
    jurisdictionsWithWaterfallsWithNoPracticeAreas,
  ])

  const onSubmit = async () => {
    setIsSubmitting(true)
    const isValid = await methods.trigger()
    if (isValid) {
      const formData = {
        ...methods.getValues(),
      }
      if (
        !formData?.waterfallMembers?.find(id => id !== '') &&
        !formData?.waterfallMembers?.length
      ) {
        methods.setError('waterfallMembers', {
          type: 'required',
          message: 'Please select at least one attorney.',
        })
        setIsSubmitting(false)
        return
      }

      const items = formData?.waterfallMembers?.map((attorneyId, index) => ({
        list_order: index,
        attorney_id: attorneyId,
      }))

      const newPayload = {
        name: formData.waterfallName,
        items,
      }

      try {
        if (isFirmSettings) {
          const practiceAreaIds =
            formData.practiceAreas?.map(id => ({
              practice_area_id: id,
            })) || []
          if (waterfall?.waterfallId) {
            const response = await updateOrgWaterfallListMutation({
              variables: {
                updateOrganizationWaterfallListItemId: waterfall.waterfallId,
                data: {
                  jurisdiction_id: formData.jurisdiction,
                  practice_areas: practiceAreaIds,
                  is_visible: formData.enabledForAttorneys ?? false,
                  organization_id: { id: organizationId || '' },
                  ...newPayload,
                },
              },
            })
            if (response.errors) {
              onError()
              setIsSubmitting(false)
              return
            }
          } else {
            const response = await createOrgWaterfallListMutation({
              variables: {
                data: {
                  jurisdiction_id: formData.jurisdiction,
                  practice_areas: practiceAreaIds,
                  is_visible: formData.enabledForAttorneys ?? false,
                  organization_id: organizationId,
                  ...newPayload,
                },
              },
            })
            if (response.errors) {
              onError()
              setIsSubmitting(false)
              return
            }
          }
        } else if (waterfall?.waterfallId) {
          const response = await updateWaterfallListMutation({
            variables: {
              updateAttorneyWaterfallListItemId: waterfall.waterfallId,
              data: { ...newPayload },
            },
          })
          if (response.errors) {
            onError()
            setIsSubmitting(false)
            return
          }
        } else {
          const response = await createAttorneyWaterfallList({
            variables: { data: newPayload },
          })
          if (response.errors) {
            onError()
            setIsSubmitting(false)
            return
          }
        }
        onSuccess()
        onClose()
      } catch (error) {
        onError()
        // eslint-disable-next-line no-console
        console.error(error)
      } finally {
        setIsSubmitting(false)
      }
    }
    setIsSubmitting(false)
  }

  const loading =
    createOrgWaterfallLoading ||
    createAttorneyWaterfallLoading ||
    updateLoading ||
    loadingUsStatesCatalog ||
    loadingPracticeAreasCatalog ||
    updateOrgWaterfallLoading

  return {
    loading,
    isPracticeAreasRequired,
    isSubmitting,
    methods,
    onSubmit,
    usStates: catalogUsState,
    practiceAreas: filteredPracticeAreas,
  }
}

export default useCreateOrEditWaterfallModal

import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import InputAdornment from '@mui/material/InputAdornment'
import Search from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import { Controller, useFormContext } from 'react-hook-form'
import useSelectPracticeAreas from './useSelectPracticeAreas'

interface ISelectPracticeAreasProps {
  fieldName: string
  index?: number
  assignedAttorneyId: string | null
}

const SelectPracticeAreas = ({
  fieldName,
  index,
  assignedAttorneyId,
}: ISelectPracticeAreasProps) => {
  const { control, watch } = useFormContext()
  const jurisdictionSelected = watch(
    `assignedAttorneys.${index}.jurisdictionId`
  )
  const selectedAttorney = watch(
    `assignedAttorneys.${index}.assignedAttorneyId`
  )
  const {
    selectedPracticeAreas,
    handleSelection,
    filteredPracticeAreas,
    loading,
    jurisdictionsWithAttorneysWithNoPracticeAreas,
  } = useSelectPracticeAreas({
    fieldName,
    index,
    jurisdictionSelected,
  })

  const disabled = !selectedAttorney

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{
        required:
          assignedAttorneyId !== '' &&
          assignedAttorneyId !== null &&
          jurisdictionsWithAttorneysWithNoPracticeAreas.has(
            jurisdictionSelected
          ) &&
          jurisdictionsWithAttorneysWithNoPracticeAreas.get(
            jurisdictionSelected
          ) !== selectedAttorney &&
          'Please select at least one practice area',
      }}
      render={({ fieldState }) => (
        <Autocomplete
          value={selectedPracticeAreas}
          multiple
          disabled={loading || disabled}
          options={filteredPracticeAreas}
          getOptionLabel={option => option.name || ''}
          onChange={handleSelection}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={loading ? 'Loading...' : 'Select the Practice Areas'}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <Search color="disabled" />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              sx={{ width: '100%' }}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, idx) => {
              const { key, ...rest } = getTagProps({ index: idx })
              return (
                <Chip
                  key={option.id}
                  variant="outlined"
                  label={option.name}
                  {...rest}
                />
              )
            })
          }
          sx={{
            ml: {
              xs: 0,
              md: 3,
            },
            mt: {
              xs: 3,
              md: 0,
            },
          }}
        />
      )}
    />
  )
}

export default SelectPracticeAreas

import { useMemo, useState, useEffect } from 'react'

import Grid from '@mui/material/Grid'

import request from '@/axios/config'
import setAuthToken from '@/axios/setAuthToken'
import CustomLoading from '@/components/Loading'
import { useQuery } from 'react-query'
import get from 'lodash/get'
import { useUserOrganizacionQuery } from '@/gql/systemApi'
import { getMyOrganizationId } from '@/utils/helpers'
import { MODAL_TYPES, ModalTypes } from '@/types'
import { ResponseModal } from '@/components'
import { useSearchParams } from 'react-router-dom'
import { ProposalSuccess } from '@/constants'
import {
  SessionKey,
  sessionStorageService,
} from '@/services/SessionStorage/SessionStorageService'
import NeedsYourAttention from './NeedsYourAttention/NeedsYourAttention'
import AllReferrals from './AllReferrals/AllReferrals'
import { CaseEntry } from './types'

const MyReferralsV2Content = () => {
  const [searchParams] = useSearchParams()
  const proposalSuccessType = searchParams.get('proposalSuccess')
  const [response, setResponse] = useState<{ type: ModalTypes; open: boolean }>(
    {
      type: null,
      open: false,
    }
  )
  const { data: orgData, loading: orgLoading } = useUserOrganizacionQuery()
  const userOrganizatinoId = getMyOrganizationId(orgData) || ''

  const { data, isLoading, error, refetch, isRefetching } = useQuery(
    'allReferrals',
    async () => {
      const token = sessionStorageService.getItem(SessionKey.TOKEN)
      setAuthToken(token)
      const res = await request.get('/myreferrals')
      return res.data
    }
  )

  const needsYourAttentionData: CaseEntry[] = useMemo(
    () =>
      (Array.isArray(data) &&
        data.filter((item: CaseEntry) => item.needs_your_attention === true)) ||
      [],
    [data]
  )

  const watchedCases: CaseEntry[] = useMemo(
    () =>
      (Array.isArray(data) &&
        data.filter((item: CaseEntry) => item.following === true)) ||
      [],
    [data]
  )

  useEffect(() => {
    if (proposalSuccessType) {
      // 'case#: ' refers to the scenarios established in https://attorney.atlassian.net/browse/MP-1254
      // These are placeholders or suggestions, the final queryParams will be defined in the Match Fee implementation
      // case2: 1st user accepting, feeder or eater, payment needed (Payment done, waiting counterpart response)
      // case4: Eater accepting in 2nd turn, payment needed (Payment done. Agreement reached, you have acquired the case)
      // case6: Feeder accepting in 2nd turn, payment needed (Payment done. Agreement reached, you have transferred the case)
      if (proposalSuccessType === ProposalSuccess.ACCEPT_PAID_PENDING) {
        setResponse({ type: MODAL_TYPES.ACCEPT_PAID_PENDING, open: true })
      }

      if (proposalSuccessType === ProposalSuccess.EATER_ACCEPT_PAID_COMPLETED) {
        setResponse({
          type: MODAL_TYPES.EATER_ACCEPT_PAID_COMPLETED,
          open: true,
        })
      }

      if (
        proposalSuccessType === ProposalSuccess.FEEDER_ACCEPT_PAID_COMPLETED
      ) {
        setResponse({
          type: MODAL_TYPES.FEEDER_ACCEPT_PAID_COMPLETED,
          open: true,
        })
      }
    }
  }, [proposalSuccessType])

  if (isLoading || orgLoading) return <CustomLoading message="Loading..." />

  if (error) return `An error has occurred: ${get(error, 'message', '')}`

  return (
    <>
      <Grid container rowSpacing={1.5} columnSpacing={3}>
        <Grid item xs={12}>
          <NeedsYourAttention
            cases={needsYourAttentionData}
            refetch={refetch}
            isRefetching={isRefetching}
          />
        </Grid>
        <Grid item xs={12}>
          <AllReferrals
            cases={data}
            watchedCases={watchedCases}
            userOrganization={userOrganizatinoId}
            refetch={refetch}
            isRefetching={isRefetching}
          />
        </Grid>
      </Grid>
      {response.open && (
        <ResponseModal
          open={response.open}
          type={response.type}
          onClose={() => {
            window.history.replaceState({}, '', window.location.pathname)
            setResponse({ open: false, type: null })
          }}
        />
      )}
    </>
  )
}

export default MyReferralsV2Content

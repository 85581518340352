import { useEffect, useState } from 'react'
import { Tweenable, tween } from 'shifty'
import { useMediaQuery } from '@mui/material'
import Paper, { PaperProps } from '@mui/material/Paper'
import useTheme from '@mui/material/styles/useTheme'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { PieChart as MuiPieChart } from '@mui/x-charts/PieChart'
import {
  animationDelay,
  animationDuration,
} from '@/components/Metrics/constants'
import AnimatedNumber, { integerFormatter } from '@/components/AnimatedNumber'

const chartSize = 180
const lineThickness = 12

interface LegendLineProps {
  color: string
  label: string
  value: number
}

const LegendLine = ({ color, label, value }: LegendLineProps) => {
  const theme = useTheme()

  return (
    <Grid
      item
      sx={{
        my: 1.25,
      }}
    >
      <Box display="flex">
        <Box
          height={theme.spacing(3)}
          width={theme.spacing(3)}
          minWidth={theme.spacing(3)}
          borderRadius={1}
          mr={1}
          sx={{
            backgroundColor: color,
          }}
        />
        <Typography mr="auto">{label}</Typography>
        <Typography variant="subtitle1" ml={2} position="relative" top={3}>
          <AnimatedNumber
            formatter={integerFormatter}
            delay={animationDelay}
            startingNumber={0}
            duration={animationDuration}
          >
            {value}
          </AnimatedNumber>
        </Typography>
      </Box>
    </Grid>
  )
}

type LegendProps = Pick<PieChartProps, 'data'>

const Legend = ({ data }: LegendProps) =>
  data.map(({ color, label, value }) => (
    <LegendLine
      key={`${color}_${value}_${label}`}
      value={value}
      color={color}
      label={label}
    />
  ))

interface PieChartDatum {
  value: number
  label: string
  color: string
}

interface PieChartProps extends PaperProps {
  data: PieChartDatum[]
  label: string
}

export const PieChart = ({ data, label, sx, ...rest }: PieChartProps) => {
  const theme = useTheme()
  const [endAngle, setEndAngle] = useState(0)

  const easingString = theme.transitions.easing.easeInOut

  const [currentTweenable, setCurrentTweenable] = useState<Tweenable | null>(
    null
  )

  const prefersReducedMotion = useMediaQuery('@media (prefers-reduced-motion)')

  useEffect(() => {
    ;(async () => {
      if (currentTweenable) {
        return
      }

      const [, bezierPointsListString] = easingString.match(/\(([^)]+)\)/) ?? []

      const bezierPointsList = bezierPointsListString
        .split(',')
        .map(str => Number(str.trim()))

      if (prefersReducedMotion) {
        setEndAngle(360)
        return
      }

      const tweenable = tween({
        from: { tweenEndAngle: 0 },
        to: { tweenEndAngle: 360 },
        render: ({ tweenEndAngle }) => {
          setEndAngle(Number(tweenEndAngle))
        },
        delay: animationDelay,
        duration: animationDuration,
        easing: bezierPointsList,
      })

      setCurrentTweenable(tweenable)
    })()

    return () => {
      if (currentTweenable) {
        currentTweenable.cancel()
        setCurrentTweenable(null)
      }
    }
  }, [currentTweenable, easingString, prefersReducedMotion])

  const radius = chartSize / 2

  const total = data.reduce((acc, { value }) => value + acc, 0)

  return (
    <Paper
      {...rest}
      sx={[
        {
          p: { xs: 1.5, sm: 4 },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography variant="subtitle1" mb={2}>
        {label}
      </Typography>
      <Grid container columns={5}>
        <Grid
          item
          xs={5}
          sm={2}
          pb={{ xs: 1, sm: 0 }}
          display={{ xs: 'flex' }}
          justifyContent={{ xs: 'center', sm: 'flex-end' }}
        >
          <Box minWidth={chartSize} minHeight={chartSize} position="relative">
            <Box
              position="absolute"
              top={0}
              left={0}
              bottom={0}
              width={chartSize}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h2"
                fontFamily={theme.typography.body1.fontFamily}
                zIndex={20}
              >
                <AnimatedNumber
                  formatter={integerFormatter}
                  delay={animationDelay}
                  startingNumber={0}
                  duration={animationDuration}
                >
                  {total}
                </AnimatedNumber>
              </Typography>
              <Typography
                variant="body1"
                color={theme.palette.text.disabled}
                mt={-1}
                zIndex={20}
              >
                Total
              </Typography>
            </Box>
            <MuiPieChart
              skipAnimation={!currentTweenable}
              series={[
                {
                  data,
                  innerRadius: radius - lineThickness,
                  outerRadius: radius,
                  paddingAngle: 0,
                  cornerRadius: 0,
                  startAngle: 0,
                  endAngle,
                  cx: radius,
                  cy: radius,
                },
              ]}
              height={chartSize}
              width={chartSize}
              margin={{ top: 0, left: 0 }}
              slotProps={{
                legend: { hidden: true },
              }}
              sx={{
                '.MuiPieArc-root': {
                  strokeWidth: 0,
                },
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={5}
          sm={3}
          pl={{ sm: 6 }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Legend data={data} />
        </Grid>
      </Grid>
    </Paper>
  )
}

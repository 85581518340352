import Grid from '@mui/material/Grid'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import TablePagination from '@mui/material/TablePagination'
import CustomLoading from '@/components/Loading'
import useTheme from '@mui/material/styles/useTheme'
import Typography from '@mui/material/Typography'
import NoResultsFound from '@/assets/no-result-found.svg'
import Image from '@/components/Image'
import JChip from '@/components/atoms/JChip'
import { Button } from '@mui/material'
import { Fragment } from 'react'
import useGetMyRoleAndOrg from '@/hooks/useGetMyRoleAndOrg'
import { useUsersMeQuery } from '@/gql/systemApi'
import { defaultPageSize, useMemberDirectory } from './useMemberDirectory'
import DirectoryCard from '../components/DirectoryCard'
import ErrorMessage from './ErrorMessage'
import { InviteToNetworkCTA } from './InviteToNetworkCTA'

interface MemberDirectoryProps {
  searchText: string
}

const filtersClass = 'filters'
const inviteCtaInsertionIdx = 6

const MemberDirectory = ({ searchText }: MemberDirectoryProps) => {
  const theme = useTheme()
  const { orgId: userOrgId } = useGetMyRoleAndOrg()
  const { data: userMe } = useUsersMeQuery()
  const currentUserId = userMe?.users_me?.id

  const {
    attorneysCount,
    cardsPerPage,
    error,
    firmNames,
    handlePageChange,
    handleChangeRowsPerPage,
    handleClearFiltersClick,
    handleJurisdictionDelete,
    handleJurisdictionsChange,
    handleLawFirmChange,
    handleLawFirmDelete,
    handlePracticeAreaChange,
    handlePracticeAreaDelete,
    jurisdictionNames,
    loading,
    members: { data: membersData },
    numFiltersSelected,
    page,
    practiceAreaNames,
    selectedJurisdictions,
    selectedLawFirms,
    selectedPracticeAreas,
  } = useMemberDirectory({
    searchText,
  })

  if (error) {
    return <ErrorMessage detail={error.message} />
  }

  return (
    <Grid
      container
      item
      columns={2}
      spacing={2}
      sx={{
        // NOTE: Styling for the nested .filters grid is done here to override
        // child combinator selectors within MUI that override inline sx styles
        // on the nested grid.
        [`& .${filtersClass}`]: {
          pr: { xs: 2, md: 0 },
          pl: { xs: 4, md: 2 },
        },
        pb: { md: 7 },
      }}
    >
      <Grid className={filtersClass} item container columns={6} spacing={2}>
        <Grid item xs={6} sm={2}>
          <Autocomplete
            blurOnSelect
            disablePortal
            value={null}
            options={firmNames}
            renderInput={params => <TextField {...params} label="Law Firms" />}
            onChange={handleLawFirmChange}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <Autocomplete
            blurOnSelect
            disablePortal
            value={null}
            options={practiceAreaNames}
            renderInput={params => (
              <TextField {...params} label="Practice Areas" />
            )}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onChange={handlePracticeAreaChange}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <Autocomplete
            blurOnSelect
            disablePortal
            value={null}
            options={jurisdictionNames}
            renderInput={params => (
              <TextField {...params} label="Jurisdictions" />
            )}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onChange={handleJurisdictionsChange}
          />
        </Grid>
        <Grid item xs>
          {numFiltersSelected > 0 ? (
            <Typography
              component="span"
              variant="caption"
              color={theme.palette.text.secondary}
              fontWeight="bold"
              mr={2}
            >
              Filters Applied
            </Typography>
          ) : null}
          {selectedLawFirms.map(firmName => (
            <JChip
              key={`firm_${firmName}`}
              label={firmName}
              variant="outlined"
              sx={{
                background: theme.palette.common.white,
              }}
              onDelete={() => handleLawFirmDelete(firmName)}
            />
          ))}
          {selectedPracticeAreas.map(practiceArea => (
            <JChip
              key={`practiceArea_${practiceArea}`}
              variant="outlined"
              sx={{
                background: theme.palette.common.white,
              }}
              label={practiceArea}
              onDelete={() => handlePracticeAreaDelete(practiceArea)}
            />
          ))}
          {selectedJurisdictions.map(jurisdiction => (
            <JChip
              key={`jurisdiction_${jurisdiction}`}
              variant="outlined"
              sx={{
                background: theme.palette.common.white,
              }}
              label={jurisdiction}
              onDelete={() => handleJurisdictionDelete(jurisdiction)}
            />
          ))}
          {numFiltersSelected > 0 ? (
            <Button
              variant="text"
              sx={{
                ':hover, :focus': {
                  background: theme.palette.action.hover,
                },
              }}
              onClick={handleClearFiltersClick}
            >
              Clear Filters
            </Button>
          ) : null}
        </Grid>
      </Grid>
      {membersData && !loading ? (
        <>
          {membersData.attorney_profile?.map((attorneyProfile, idx) => {
            const isUserOrgMember = Boolean(
              attorneyProfile?.attorney_id?.user_id?.current_organization?.some(
                org => org?.id === userOrgId
              )
            )
            const userId = attorneyProfile?.attorney_id?.user_id?.id
            const showInviteButton =
              !isUserOrgMember || currentUserId !== userId
            const showChatButton = currentUserId !== userId
            return (
              <Fragment key={attorneyProfile.id}>
                <Grid item xs={2} md={1}>
                  <DirectoryCard
                    sx={{ height: '100%' }}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    profile={attorneyProfile}
                    showInviteButton={showInviteButton}
                    showChatButton={showChatButton}
                  />
                </Grid>
                {idx + 1 === inviteCtaInsertionIdx ? (
                  <InviteToNetworkCTA />
                ) : null}
              </Fragment>
            )
          })}
          {membersData.attorney_profile.length === 0 ? (
            <Grid item xs display="flex" flexDirection="column">
              <Image
                alt="No results found"
                src={NoResultsFound}
                sx={{ maxWidth: '194px', mx: 'auto', mt: 2 }}
              />
              <Typography variant="body1" mx="auto" mt={3}>
                We couldn&apos;t find any user with these characteristics
              </Typography>
            </Grid>
          ) : null}
          {membersData.attorney_profile?.length < inviteCtaInsertionIdx ? (
            <InviteToNetworkCTA />
          ) : null}
          {membersData.attorney_profile.length > 0 ? (
            <Grid item xs={2}>
              <TablePagination
                showFirstButton
                showLastButton
                labelRowsPerPage="Users per page:"
                component="div"
                count={attorneysCount}
                page={page}
                onPageChange={handlePageChange}
                rowsPerPage={cardsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[defaultPageSize, 50, 100]}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  '.MuiTablePagination-toolbar': {
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    [theme.breakpoints.down('md')]: {
                      p: 0,
                    },
                  },
                }}
              />
            </Grid>
          ) : null}
        </>
      ) : (
        <CustomLoading message="Loading users..." />
      )}
    </Grid>
  )
}

export default MemberDirectory

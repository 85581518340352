import pkg from 'time-constants'
const { DAY, HOUR, AVERAGE_MONTH, WEEK } = pkg
import { CATALOG_TIME_UNIT, TimeUnitValue } from '../../constants/constants.js'

const timeMultiplier = {
  [CATALOG_TIME_UNIT.HOURS]: HOUR,
  [CATALOG_TIME_UNIT.DAYS]: DAY,
  [CATALOG_TIME_UNIT.MONTHS]: AVERAGE_MONTH,
  [CATALOG_TIME_UNIT.WEEKS]: WEEK,
} as const

const isTimeMultiplierId = (id: string): id is keyof typeof timeMultiplier =>
  id in timeMultiplier

export class TimeService {
  // eslint-disable-next-line class-methods-use-this
  getEndDate = (
    timeStart: Date,
    timeUnitId: TimeUnitValue,
    timeLimit: number
  ) => {
    if (!isTimeMultiplierId(timeUnitId)) {
      throw new TypeError(`Unexpected timeUnit ID: ${timeUnitId}`)
    }

    const timeUnitMs = timeMultiplier[timeUnitId]
    const timeSpan = timeUnitMs * timeLimit

    return new Date(Number(timeStart) + timeSpan)
  }
}

export const timeService = new TimeService()

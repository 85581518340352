import { CASE_PURCHASE_STATUS, CASE_STATUS_FLOW } from '@/constants'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { useContext } from 'react'
import { useSizes } from '@/hooks/useSizes'
import { ReferralContext } from './ReferralContext'
import { WaterfallCountdown } from './WaterfallCountdown'

interface SellerHeadlineProps {
  showWaterfallProposalDueDate: boolean
  waterfallProposalDueDate: Date
}

export const SellerHeadline = ({
  showWaterfallProposalDueDate,
  waterfallProposalDueDate,
}: SellerHeadlineProps) => {
  const theme = useTheme()
  const { isLessMd } = useSizes()
  const {
    caseParams: { referralType },
    caseEntry,
    caseEntry: { status },
    sortedCasePurchases: [firstCasePurchase],
    wasBuyerInvited,
    isCaseManagerWithoutPermission,
  } = useContext(ReferralContext)

  const didSellerTakeMostRecentAction =
    firstCasePurchase?.case_purchase_activities.created_by_attorney
      .attorney_id === caseEntry.attorney_id

  const showCounterofferMessage =
    firstCasePurchase?.status === CASE_PURCHASE_STATUS.Counteroffer &&
    !didSellerTakeMostRecentAction

  const wasProposalReceived =
    referralType === 'proposed' &&
    !wasBuyerInvited &&
    status !== CASE_STATUS_FLOW.Signed

  const showMatchedCaseMessage =
    !isCaseManagerWithoutPermission && status === CASE_STATUS_FLOW.Matched

  const showExpirationMessage = status === CASE_STATUS_FLOW.Expired

  return (
    <Box display="flex" alignItems="baseline" flexWrap="wrap" columnGap={1}>
      {!isCaseManagerWithoutPermission && showCounterofferMessage && (
        <Typography color={theme.palette.success.main} variant="captionBold">
          Counteroffer Received
        </Typography>
      )}
      {!isCaseManagerWithoutPermission && wasProposalReceived && (
        <Typography color={theme.palette.success.main} variant="captionBold">
          Proposal Received
        </Typography>
      )}
      {!isCaseManagerWithoutPermission &&
        !isLessMd &&
        showWaterfallProposalDueDate && (
          <WaterfallCountdown
            waterfallProposalDueDate={waterfallProposalDueDate}
          />
        )}
      {showMatchedCaseMessage && (
        <Typography color={theme.palette.warning.dark} variant="captionBold">
          Matched Case
        </Typography>
      )}
      {showExpirationMessage && (
        <Typography color={theme.palette.text.disabled} variant="captionBold">
          The case has expired
        </Typography>
      )}
    </Box>
  )
}

import { useState, useEffect } from 'react'
import {
  useGetCatalogWaterfallDurationQuery,
  useUpdateOrganizationMutation,
  useGetOrganizationLcmsWaterfallsInfoQuery,
} from '@/gql/appApi'
import { useGetMyRoleAndOrg } from '@/hooks/useGetMyRoleAndOrg'
import { useSnackBar } from '@/hooks/useSnackBar'

const useReferCasesToWaterfalls = () => {
  const [
    assignIntegrationCasesToWaterfalls,
    setAssignIntegrationCasesToWaterfalls,
  ] = useState(true)
  const [priorityAccessDuration, setPriorityAccessDuration] = useState('')
  const { data: timeLimitCatalog } = useGetCatalogWaterfallDurationQuery()
  const [updateOrganization] = useUpdateOrganizationMutation()
  const { orgId } = useGetMyRoleAndOrg()
  const { alert, handleClose, showAlert } = useSnackBar()

  const { data: organizationLcmsWaterfallInfo } =
    useGetOrganizationLcmsWaterfallsInfoQuery({
      variables: {
        filter: {
          id: {
            _eq: orgId,
          },
        },
      },
      skip: !orgId,
      fetchPolicy: 'network-only',
    })

  useEffect(() => {
    setAssignIntegrationCasesToWaterfalls(
      organizationLcmsWaterfallInfo?.organization[0]
        .lcms_waterfall_mapping_enabled || false
    )
    setPriorityAccessDuration(
      organizationLcmsWaterfallInfo?.organization[0]
        .lcms_waterfall_default_duration?.id || ''
    )
  }, [organizationLcmsWaterfallInfo])

  const toggleAutomaticAssignment = async () => {
    try {
      await updateOrganization({
        variables: {
          updateOrganizationItemId: orgId || '',
          data: {
            lcms_waterfall_mapping_enabled: !assignIntegrationCasesToWaterfalls,
          },
        },
      })
      setAssignIntegrationCasesToWaterfalls(!assignIntegrationCasesToWaterfalls)
      showAlert({
        message:
          'Assigning integration cases to Waterfalls updated successfully',
        severity: 'success',
      })
    } catch (error) {
      showAlert({
        message: 'Error assigning integration cases to Waterfalls',
        severity: 'error',
      })
    }
  }

  const updatePriorityAccessDuration = async (duration: string) => {
    try {
      await updateOrganization({
        variables: {
          updateOrganizationItemId: orgId || '',
          data: {
            lcms_waterfall_default_duration: duration,
          },
        },
      })
      setPriorityAccessDuration(duration)
      showAlert({
        message: 'Priority access duration updated successfully',
        severity: 'success',
      })
    } catch (error) {
      showAlert({
        message: 'Error updating priority access duration',
        severity: 'error',
      })
    }
  }

  return {
    alert,
    handleClose,
    assignIntegrationCasesToWaterfalls,
    priorityAccessDuration,
    timeLimitCatalog,
    toggleAutomaticAssignment,
    updatePriorityAccessDuration,
  }
}

export default useReferCasesToWaterfalls

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'
import { CheckCircleOutline } from '@mui/icons-material'

const PendingAccess = () => (
  <Alert
    severity="success"
    variant="outlined"
    sx={{
      alignItems: 'flex-start',
    }}
    iconMapping={{
      success: <CheckCircleOutline fontSize="inherit" />,
    }}
  >
    <AlertTitle>
      <Typography variant="subtitle1">
        You have successfully submitted a request to access Data Room.
      </Typography>
    </AlertTitle>
    <Typography variant="body2">
      We&apos;ll notify you once the case owner has reviewed and made a decision
      regarding your request. Please stay tuned for updates, and we appreciate
      your patience in this process.
    </Typography>
  </Alert>
)

export default PendingAccess

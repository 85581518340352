import { useState } from 'react'
import Box from '@mui/material/Box'
import { SubmitHandler, useForm, FieldValues } from 'react-hook-form'
import { Autocomplete, TextField, InputAdornment, Chip } from '@mui/material'

import { Search } from '@mui/icons-material'
import JDropdownUser from '@/components/atoms/JDropdownUser'
import { getImagePath } from '@/utils'
import { useUsersMeQuery } from '@/gql/systemApi'
import useOrganizationId from '@/hooks/organization/useOrganizationId'

import {
  GetActivityPurchaseDocument,
  GetActivityPurchaseFromMyOrgDocument,
  GetCasesDocument,
  useCreateCaseOwnershipItemsMutation,
  useCreateCasePurchaseOwnershipItemsMutation,
  useGetCaseManagersInOrgQuery,
} from '@/gql/appApi'
import { ATTORNEYS_ROLES, IMAGE_SIZES } from '@/constants'
import { useSnackBar } from '@/hooks/useSnackBar'
import JSnackBar from '@/components/atoms/JSnackBar'
import ResponseModal from '../ResponseModal'
import ConfirmationModal from '../ConfirmationModal'

export interface AddCaseManagerModalProps {
  caseId: string
  existingCaseManagers: any[]
  openModal: boolean
  setOpenModal: (value: boolean) => void
  isCaseOwner?: boolean
  casePurchaseId?: string
}

interface CaseManager {
  ownership_id: string
  id: string
  is_vetted: boolean
  user_id: {
    id: string
    first_name: string
    last_name: string
    avatar: {
      id: string
    }
  }
}

const AddCaseManagerModal = ({
  caseId,
  existingCaseManagers,
  openModal,
  setOpenModal,
  isCaseOwner,
  casePurchaseId,
}: AddCaseManagerModalProps) => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const { data: userMe } = useUsersMeQuery()
  const { id } = useOrganizationId()
  const userId = userMe?.users_me?.id
  const caseManagerRoles = [ATTORNEYS_ROLES.CASE_MANAGER.id]
  const existingCaseManagerIds = existingCaseManagers?.map(
    (caseManager: { id: string }) => caseManager.id
  )

  const { data, loading, error } = useGetCaseManagersInOrgQuery({
    variables: {
      filter: {
        _and: [
          // Filter by organization
          {
            user_id: {
              current_organization: {
                organization_id: {
                  id: {
                    _eq: id,
                  },
                },
              },
            },
          },
          // Filter by case manager role
          {
            user_id: {
              role: {
                id: {
                  _in: caseManagerRoles,
                },
              },
            },
          },
          // Exclude current user
          {
            user_id: {
              id: {
                _neq: userId,
              },
            },
          },
          // Exclude existing case managers if exists
          {
            ...(existingCaseManagers.length > 0 && {
              user_id: {
                id: {
                  _nin: existingCaseManagerIds,
                },
              },
            }),
          },
        ],
      },
    },
    fetchPolicy: 'network-only',
  })

  // Fallback to empty array if loading or error to prevent bad UX
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const { attorney: caseManagers } =
    !loading && !error ? data : { attorney: [] }

  const { alert, showAlert, handleClose } = useSnackBar()
  const { handleSubmit, setValue, watch } = useForm<FieldValues>({
    defaultValues: {
      selectedCaseManagers: [] as CaseManager[],
    },
  })

  const [createCaseOwnershipItemsMutation, { loading: ownershipLoading }] =
    useCreateCaseOwnershipItemsMutation({
      refetchQueries: [
        GetActivityPurchaseDocument,
        'case_purchase_activity',
        GetCasesDocument,
        'case',
        GetActivityPurchaseFromMyOrgDocument,
        'case_purchase_activity',
      ],
    })

  const [
    createCasePurchaseOwnershipItemsMutation,
    { loading: purchaseOwnershipLoading },
  ] = useCreateCasePurchaseOwnershipItemsMutation({
    refetchQueries: [
      GetActivityPurchaseDocument,
      'case_purchase_activity',
      GetCasesDocument,
      'case',
      GetActivityPurchaseFromMyOrgDocument,
      'case_purchase_activity',
    ],
  })

  const selectedCaseManagers = watch('selectedCaseManagers')

  const handleSubmitModal: SubmitHandler<FieldValues> = async form => {
    try {
      const { selectedCaseManagers: formCaseManagers } = form
      const caseManagerIds = formCaseManagers.map(
        (caseManager: CaseManager) => caseManager.user_id.id
      )
      const payloadData = caseManagerIds.map((caseManagerId: string) => ({
        case_id: {
          id: caseId,
        },
        user_id: caseManagerId,
      }))
      if (isCaseOwner) {
        await createCaseOwnershipItemsMutation({
          variables: {
            data: payloadData,
          },
        })
      } else if (casePurchaseId) {
        const casePurchaseOwnershipData = caseManagerIds.map(
          (caseManagerId: string) => ({
            case_purchase_id: casePurchaseId,
            user_id: caseManagerId,
          })
        )
        await createCasePurchaseOwnershipItemsMutation({
          variables: {
            data: casePurchaseOwnershipData,
          },
        })
      }
      setOpenModal(false)
      setShowSuccess(true)
      setValue('selectedCaseManagers', [])
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error adding case managers: ', err)
      showAlert({
        severity: 'error',
        message: 'There was an error adding the case managers',
      })
    }
  }

  const title = 'Add Case Managers'
  const description =
    'Select the Case Managers you want to allow to act on your behalf on this case. You can revoke these permissions at any time you want.'

  return (
    <>
      <ResponseModal
        open={showSuccess}
        onClose={() => setShowSuccess(false)}
        type="AddCaseManager"
      />
      <JSnackBar
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        horizontal="center"
        vertical="bottom"
        handleClose={handleClose}
      />
      <ConfirmationModal
        title={title}
        description={description}
        open={openModal}
        onClose={() => {
          setValue('selectedCaseManagers', [])
          setOpenModal(false)
        }}
        AcceptButtonProps={{
          children: 'Give permissions',
          disabled:
            !selectedCaseManagers.length ||
            ownershipLoading ||
            purchaseOwnershipLoading,
          onClick: handleSubmit(handleSubmitModal),
        }}
      >
        <Box
          sx={{
            paddingTop: 2,
            display: 'flex',
            gap: 1,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <Autocomplete
            options={caseManagers}
            sx={{ width: '100%' }}
            getOptionLabel={(option: CaseManager) =>
              `${option.user_id.first_name} ${option.user_id.last_name}`
            }
            multiple
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const { key, ...rest } = getTagProps({ index })
                return (
                  <Chip
                    key={option.id}
                    variant="outlined"
                    label={`${option.user_id.first_name} ${option.user_id.last_name}`}
                    {...rest}
                  />
                )
              })
            }
            noOptionsText="No case managers found"
            renderInput={params => (
              <TextField
                color="secondary"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment
                        position="start"
                        sx={{
                          pl: 1,
                        }}
                      >
                        <Search color="disabled" />
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
                placeholder={
                  selectedCaseManagers.length > 0
                    ? ''
                    : 'Search for Case Managers'
                }
              />
            )}
            onChange={(_, value) => {
              setValue('selectedCaseManagers', value)
            }}
            renderOption={(props, option: CaseManager) => (
              <JDropdownUser
                key={option.id}
                user={{
                  id: String(option.id),
                  name: `${option.user_id.first_name} ${option.user_id.last_name}`,
                  picture: getImagePath({
                    id: option.user_id?.avatar?.id ?? null,
                    size: IMAGE_SIZES.Small,
                  }),
                  isVetted: option.is_vetted ?? false,
                }}
                {...props}
              />
            )}
          />
        </Box>
      </ConfirmationModal>
    </>
  )
}

export default AddCaseManagerModal

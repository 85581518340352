import { useCallback, useEffect, useContext, useState } from 'react'
import { useUsersMeLazyQuery } from '@/gql/systemApi'
import request from '@/axios/config'
import GlobalContext from '@/context/GlobalContext'

const useUpdatePermissions = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const [getUserMe] = useUsersMeLazyQuery()
  const [isDone, setIsDone] = useState(false)
  const globalContext = useContext(GlobalContext)

  const fetchPermissions = useCallback(async () => {
    setIsDone(true)
    const userMe = await getUserMe()
    const userRoleID = userMe?.data?.users_me?.role?.id
    const permissionsURL = `permissions/role_permissions/${userRoleID}?format=json`
    const permissionsResponse = await request.get(permissionsURL)
    globalContext?.setGlobalPermissions(permissionsResponse.data)
  }, [getUserMe, globalContext])

  useEffect(() => {
    if (!isLoggedIn || isDone) return
    fetchPermissions()
  }, [isLoggedIn, fetchPermissions, isDone])
}

export default useUpdatePermissions

import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Controller } from 'react-hook-form'
import SelectSavedWaterfall from '../SelectSavedWaterfall/SelectSavedWaterfall'
import { CreateCaseWaterfallList } from '../../types'
import AssignIndividually from '../AssignIndividually/AssignIndividually'
import useWaterfallReferrals from './useWaterfallReferrals'

interface IWaterfallReferralsProps {
  hasPermissionToCreateWaterfalls: boolean
  orgId: string
  waterfallLists: {
    orgWaterfalls: CreateCaseWaterfallList[]
    attorneyWaterfalls: CreateCaseWaterfallList[]
  }
}

const WaterfallReferrals = ({
  hasPermissionToCreateWaterfalls,
  orgId,
  waterfallLists,
}: IWaterfallReferralsProps) => {
  const {
    assignByWaterfall,
    assignByUser,
    control,
    handleChangeOption,
    lists,
    loadingTimeLimitOptions,
    showOrgWaterfallsOnly,
    showSelectUsersOnly,
    showAllWaterfallOptions,
    timeLimitOptions,
  } = useWaterfallReferrals({
    hasPermissionToCreateWaterfalls,
    waterfallLists,
  })

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'start',
          my: 2.5,
          flexDirection: 'column',
        }}
      >
        {showAllWaterfallOptions && (
          <>
            <FormControlLabel
              sx={{
                marginBottom: { xs: 2.5, md: 0 },
              }}
              control={
                <Radio
                  checked={assignByWaterfall}
                  onChange={() => handleChangeOption('waterfall')}
                />
              }
              label="I want to assign the case to a saved waterfall list"
            />
            <FormControlLabel
              control={
                <Radio
                  checked={assignByUser}
                  onChange={() => handleChangeOption('user')}
                />
              }
              label="I want to assign attorneys individually"
            />
          </>
        )}
      </Box>
      {showOrgWaterfallsOnly && <SelectSavedWaterfall waterfallLists={lists} />}
      {(showSelectUsersOnly || assignByUser) && (
        <AssignIndividually orgId={orgId} />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: 'center',
          mt: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="subtitle2">Priority Access Duration</Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            How long each attorney will have privileged access to the case.
          </Typography>
        </Box>
        <Controller
          control={control}
          name="timeLimit"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              sx={{
                ml: {
                  md: 'auto',
                },
                mt: {
                  xs: 2.5,
                  md: 0,
                },
                width: '100%',
                maxWidth: { md: 200 },
              }}
              options={timeLimitOptions}
              getOptionLabel={option => option?.name || ''}
              loading={loadingTimeLimitOptions}
              value={
                timeLimitOptions.find(option => option?.duration === value) ||
                null
              }
              onChange={(_, optionData) =>
                onChange(optionData?.duration || timeLimitOptions[0]?.duration)
              }
              renderInput={params => (
                <Box>
                  <TextField
                    {...params}
                    label="Select Time Limit"
                    placeholder="Select Time Limit"
                  />
                </Box>
              )}
            />
          )}
        />
      </Box>
      <Typography variant="subtitle2" sx={{ mt: 4 }}>
        What are Waterfall Referrals™?
      </Typography>
      <Typography variant="body2" sx={{ mt: 1.5 }}>
        We&apos;ll start by sending a private invitation to this case to the
        first attorney in the selected Waterfall. If the case is not accepted by
        any attorneys in your Waterfall, we&apos;ll share on the Marketplace to
        try and match to an interested attorney.
      </Typography>
    </Box>
  )
}

export default WaterfallReferrals

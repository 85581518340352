import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useSizes } from '@/hooks/useSizes'
import { theme } from '@/styles/theme'
import { ConfirmationModal as DisconnectConfirmationModal } from '@/components'
import textContent from './constants'
import { IntegrationProps, PAGE } from './types'
import ClioNonAdminIntegrationItem from './components/Clio/ClioNonAdminIntegrationItem'
import useIntegrations, { IntegrationItem } from './hooks/useIntegrations'
import PracticeAreaMapping from './components/PracticeAreaMapping/PracticeAreaMapping'

const Integrations = ({ page }: IntegrationProps) => {
  const { TITLE, INTEGRATION_V2 } = textContent

  const { isLessMd } = useSizes()

  const {
    isClioEnabled,
    isMarketplaceLeadDocketEnabled,
    organizationData,
    organizationId,
    loading,
    connectedItem,
    disconnectedItems,
    preferredIntegrationItem,
    modalProps,
    setModalProps,
    refetchLcmsData,
    removeIntegrationItem,
    isConnectingToLcms,
    connectingToLcms,
    isCurrentUserConnectedToClio: connectedToClio,
    hasAdminConnectedToClio,
  } = useIntegrations()

  const renderIntegrationItem = (item: IntegrationItem) => {
    const Component = item.component
    return <Component key={item.id} {...item.props} />
  }

  const renderIntegrationContent = () => (
    <Grid item xs={12}>
      <Typography variant="h5">{TITLE}</Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{
          margin: `${theme.spacing(2)} 0 ${theme.spacing(4)}`,
        }}
      >
        {INTEGRATION_V2.SUBTITLE}
      </Typography>
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <>
          {page !== PAGE.Integrations && (
            <>
              {connectedItem && <>{renderIntegrationItem(connectedItem)}</>}
              <PracticeAreaMapping />
              {!connectedItem &&
                preferredIntegrationItem &&
                renderIntegrationItem(preferredIntegrationItem)}
              {disconnectedItems.length > 0 &&
                (connectedItem || preferredIntegrationItem) && (
                  <Box>
                    <Box sx={{ my: 4 }}>
                      <Typography variant="subtitle1">
                        Other Integrations
                      </Typography>
                      {connectedItem && (
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          If you want to connect with one of the following
                          systems, you must first disconnect from your current
                          one.
                        </Typography>
                      )}
                    </Box>
                    {disconnectedItems.map(renderIntegrationItem)}
                  </Box>
                )}
              {!connectedItem &&
                !preferredIntegrationItem &&
                disconnectedItems.map(renderIntegrationItem)}
            </>
          )}
          {page === PAGE.Integrations && (
            <Grid item xs={12}>
              {isClioEnabled && (hasAdminConnectedToClio || connectedToClio) ? (
                <ClioNonAdminIntegrationItem
                  icon={INTEGRATION_V2.CLIO_GROW.ICON}
                  name={INTEGRATION_V2.CLIO_GROW.NAME}
                  subtitle={INTEGRATION_V2.CLIO_GROW.SUBTITLE}
                  isCurrentUserConnectedToClio={connectedToClio}
                  organizationData={organizationData}
                  organizationId={organizationId}
                  loading={loading}
                  removeIntegrationItem={removeIntegrationItem}
                  refetchLcmsData={refetchLcmsData}
                  isConnectingToLcms={isConnectingToLcms}
                  connectingToLcms={connectingToLcms}
                />
              ) : (
                <Typography variant="body2" color="text.secondary">
                  There are no integrations available at the moment.
                </Typography>
              )}
            </Grid>
          )}
        </>
      )}
    </Grid>
  )

  return (
    <Box
      sx={{
        width: '100%',
        boxShadow: isMarketplaceLeadDocketEnabled ? 'none' : 'inherit',
        background: theme.palette.background.page,
        marginBottom: { xs: theme.spacing(14), md: theme.spacing(0) },
      }}
    >
      <CardContent>
        <Grid container direction="row" spacing={isLessMd ? 3 : 5}>
          {renderIntegrationContent()}
        </Grid>
      </CardContent>
      {modalProps.open && (
        <DisconnectConfirmationModal
          open={modalProps.open}
          title={modalProps.title}
          description={modalProps.description}
          onClose={() =>
            setModalProps({
              open: false,
              onSubmit: () => {},
              title: '',
              description: '',
            })
          }
          AcceptButtonProps={{
            children: 'Disconnect',
            onClick: modalProps.onSubmit,
            disabled: loading,
          }}
        />
      )}
    </Box>
  )
}

export default Integrations

import * as React from 'react'

import { Grid, SxProps, Theme, styled } from '@mui/material'
import { getAppHeights } from '@/styles/utils'
import Layout from '../Layout'
import Menu, { MenuProps } from '../Menu'

export interface MyPagesLayoutProps {
  menuItems: MenuProps['menuItems']
  onClickItem: MenuProps['onClickItem']
  children: React.ReactNode
  contentSX?: SxProps<Theme>
  menuSX?: SxProps<Theme>
  skipBottomBar?: boolean
  customOnClickItemOnly?: boolean
  useLinks?: string
}

const StyledContent = styled(Grid)(
  ({ theme }) => `
  display: flex;
  width: 100%;
  ${theme.breakpoints.up('md')} {
    height: 100%;
    overflow: auto;
  }
`
)

const StyledMenu = styled(Grid)(
  ({ theme }) => `
  height: 100%;
  width: 100%;
  ${theme.breakpoints.up('md')} {
    display: flex;
    background: linear-gradient( to right, transparent 0%, ${
      theme.palette.primary.lightest
    } 50%);
    .MuiList-root {
      margin-top: ${theme.spacing(5)};
    }
  };
  ${theme.breakpoints.down('md')} {
    overflow: hidden;
  }
`
)

const MyPagesLayout = ({
  menuItems,
  onClickItem,
  children,
  contentSX,
  menuSX,
  skipBottomBar,
  customOnClickItemOnly,
  useLinks,
}: MyPagesLayoutProps) => {
  const appHeights = getAppHeights()
  return (
    <Layout skipBottomBar={skipBottomBar}>
      <Grid
        container
        mt={0}
        sx={theme => ({
          [theme.breakpoints.up('md')]: {
            height: `calc(${appHeights.contentHeight})`,
            overflow: 'hidden',
          },
        })}
        className="My-Pages-Layout"
      >
        <StyledMenu item justifyContent="end" md={3} sx={menuSX}>
          <Menu
            menuItems={menuItems}
            onClickItem={onClickItem}
            customOnClickItemOnly={customOnClickItemOnly}
            useLinks={useLinks}
          />
        </StyledMenu>
        <StyledContent item md={9} sx={contentSX}>
          {children}
        </StyledContent>
      </Grid>
    </Layout>
  )
}

export default MyPagesLayout

import { FC } from 'react'
import { useCurrentRefinements } from 'react-instantsearch'
import { CurrentRefinementsConnectorParamsRefinement } from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements'
import { Grid, Typography, Button, Theme } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import JChip from '@/components/atoms/JChip'
import { motion, AnimatePresence } from 'framer-motion'
import { useAlgoliaMarketplaceContext } from '../context/useAlgoliaMarketplaceContext'

const ActiveFilters: FC = () => {
  const theme: Theme = useTheme()
  const { items, refine } = useCurrentRefinements()
  const { handleClearFilters } = useAlgoliaMarketplaceContext()

  const handleDelete = (item: CurrentRefinementsConnectorParamsRefinement) => {
    refine(item)
  }

  const numFiltersSelected = items.reduce(
    (acc, item) => acc + item.refinements.length,
    0
  )

  if (numFiltersSelected === 0) {
    return null
  }

  return (
    <Grid item xs={12} sx={{ display: 'block', alignItems: 'center' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Typography
          component="div"
          variant="caption"
          color={theme.palette.text.secondary}
          fontWeight="bold"
          mr={2}
          display={{ xs: 'none', md: 'flex' }}
          alignItems="center"
        >
          Filters Applied
        </Typography>
        <AnimatePresence mode="popLayout">
          {items.map(item =>
            item.refinements.map(refinement => (
              <motion.div
                key={`${item.attribute}_${refinement.label}`}
                layoutId={`${item.attribute}_${refinement.label}`}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                style={{ zIndex: 1 }}
              >
                <JChip
                  label={`${refinement.label}`}
                  variant="outlined"
                  sx={{
                    background: theme.palette.common.white,
                    borderRadius: '9999px',
                    paddingLeft: 0.375,
                    '& .MuiChip-deleteIcon': {
                      cursor: 'pointer',
                    },
                  }}
                  onDelete={() => handleDelete(refinement)}
                />
              </motion.div>
            ))
          )}
        </AnimatePresence>
        {numFiltersSelected > 0 ? (
          <Button
            variant="text"
            sx={{
              ':hover, :focus': {
                background: theme.palette.action.hover,
                cursor: 'pointer',
              },
            }}
            onClick={handleClearFilters}
          >
            Clear Filters
          </Button>
        ) : null}
      </div>
    </Grid>
  )
}

export default ActiveFilters

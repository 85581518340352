import Layout from '@/components/Layout'
import CustomLoading from '@/components/Loading'
import { useDashboardData } from '@/hooks/useDashboardData'
import { useUsersMeQuery } from '@/gql/systemApi'
import Container from '@mui/material/Container'
import useTheme from '@mui/material/styles/useTheme'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { RoutesData } from '@/router/RoutesData'
import { theme as themeDefinition } from '@/styles/theme'
import { useMediaQuery } from '@mui/material'
import JChip from '@/components/atoms/JChip'
import { useState } from 'react'
import ErrorMessage from './ErrorMessage'
import { JChipLabel } from './components/JChipLabel'
import { OrganizationDashboard } from './components/OrganizationDashboard'
import { AttorneyDashboard } from './components/AttorneyDashboard'

const sm = themeDefinition.breakpoints.up('sm')

enum SelectedDashboard {
  FIRM = 'FIRM',
  ATTORNEY = 'ATTORNEY',
}

const Dashboard = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const isLargeScreen = useMediaQuery(sm)

  const { data: userMe } = useUsersMeQuery()
  const {
    avatarMap,
    error,
    organization: { data: organizationData },
    attorney: { data: attorneyData },
  } = useDashboardData()
  const [selectedDashboard, setSelectedDashboard] = useState(
    SelectedDashboard.FIRM
  )

  if (error) {
    return (
      <Layout>
        <ErrorMessage detail={error.message} />
      </Layout>
    )
  }

  const handleCreateCaseClick = () => {
    navigate(RoutesData.RCreateNewCase.path)
  }

  const handleClickDashboardChip = (dashboard: SelectedDashboard) => {
    setSelectedDashboard(dashboard)
  }

  return (
    <Layout
      sx={{
        overflow: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
      }}
    >
      <Container
        sx={{
          pb: {
            xs: theme.spacing(22),
            md: 20,
          },
          px: { xs: 0, sm: 1, lg: 5 },
        }}
      >
        {organizationData && attorneyData && userMe ? (
          <>
            <Box
              py={{ xs: 3, sm: 4, lg: 6 }}
              px={{ xs: 2, sm: 2, lg: 0 }}
              display="flex"
              flexDirection={{
                xs: 'column',
                sm: 'row',
              }}
              justifyContent="space-between"
            >
              <Box>
                <Typography variant={isLargeScreen ? 'h3' : 'h5'}>
                  Hi {userMe?.users_me?.first_name}!
                </Typography>
                <Typography
                  variant="body2"
                  color={theme.palette.text.secondary}
                  mt={1}
                >
                  All of your referrals in one dashboard. How cool is that!?
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    display: 'block',
                    width: { xs: 1 },
                    maxWidth: { xs: '350px' },
                    mx: 'auto',
                    my: {
                      xs: 3,
                      sm: 0,
                    },
                  }}
                  onClick={handleCreateCaseClick}
                >
                  Create New Case
                </Button>
              </Box>
            </Box>
            <Box mb={{ xs: 3, sm: 0 }} px={{ xs: 2, sm: 2, lg: 0 }}>
              <JChip
                size="small"
                sx={{
                  ml: 0,
                  mr: 2,
                  py: 2,
                }}
                label={
                  <JChipLabel
                    labelText="All Firm Cases"
                    numReferrals={
                      organizationData?.metricsOrganization.numReferrals ?? 0
                    }
                    selected={selectedDashboard === SelectedDashboard.FIRM}
                  />
                }
                variant={
                  selectedDashboard === SelectedDashboard.FIRM
                    ? 'filled'
                    : 'outlined'
                }
                onClick={() => {
                  handleClickDashboardChip(SelectedDashboard.FIRM)
                }}
                selected={selectedDashboard === SelectedDashboard.FIRM}
              />
              <JChip
                size="small"
                sx={{
                  ml: 0,
                  mr: 2,
                  py: 2,
                }}
                label={
                  <JChipLabel
                    labelText="Only My Cases"
                    numReferrals={
                      attorneyData?.metricsAttorney.numReferrals ?? 0
                    }
                    selected={selectedDashboard === SelectedDashboard.ATTORNEY}
                  />
                }
                variant={
                  selectedDashboard === SelectedDashboard.ATTORNEY
                    ? 'filled'
                    : 'outlined'
                }
                onClick={() => {
                  handleClickDashboardChip(SelectedDashboard.ATTORNEY)
                }}
                selected={selectedDashboard === SelectedDashboard.ATTORNEY}
              />
            </Box>
            {organizationData &&
            selectedDashboard === SelectedDashboard.FIRM ? (
              <OrganizationDashboard
                avatarMap={avatarMap}
                organizationData={organizationData}
              />
            ) : null}
            {attorneyData &&
            selectedDashboard === SelectedDashboard.ATTORNEY ? (
              <AttorneyDashboard
                avatarMap={avatarMap}
                attorneyData={attorneyData}
              />
            ) : null}
          </>
        ) : (
          <CustomLoading message="Loading..." />
        )}
      </Container>
    </Layout>
  )
}

export default Dashboard

import { useEffect, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { UseFormReset } from 'react-hook-form'
import { useSnackBar } from '@/hooks/useSnackBar'
import { getClioMatters } from '@/utils/api/integrations/clio'
import { TError } from '@/utils/api/types'
import { ICaseForm } from '../types'

export type CaseDescription = {
  caseDescription?: string
  firstName?: string
  lastName?: string
  phone?: string
  email?: string
}

const useClioCreateCase = ({ reset }: { reset: UseFormReset<ICaseForm> }) => {
  const { alert, showAlert, handleClose } = useSnackBar()
  const [searchParams, setSearchParams] = useSearchParams()

  const getClioData = useCallback(
    async (matterId: string) => {
      const data = await getClioMatters(matterId)
      if ((data as TError).message) {
        showAlert({
          severity: 'error',
          message: (data as TError).message,
        })
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const names = data.primary_contact?.full_name?.split(' ')
        const newData = {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          caseDescription: data.description,
          leadInfo: [
            {
              firstName: names?.[0],
              lastName: names?.[1],
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              phone: data.primary_contact?.phone_number || '',
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              email: data.primary_contact?.primary_email || '',
            },
          ],
        }
        reset({
          ...newData,
        })
      }
    },
    [reset, showAlert]
  )

  const getMatterId = useCallback(() => {
    const matterId = searchParams.get('matterId')
    if (Array.from(searchParams.keys()).length > 0) {
      setSearchParams('')
    }
    return matterId
  }, [searchParams, setSearchParams])

  useEffect(() => {
    const matterId = getMatterId()

    if (matterId) {
      getClioData(matterId)
    }
  }, [])

  return { clioAlert: alert, closeClioAlert: handleClose }
}

export default useClioCreateCase

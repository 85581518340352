import { useStreamChat } from '@/hooks/useStreamChat'
import { SwipeableDrawer } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import ChannelList from './ChannelList'
import ChatChannel from './ChatChannel'

const GetStreamChatDrawer: React.FC = () => {
  const theme = useTheme()

  const {
    messageDrawerOpen,
    setMessageDrawerOpen,
    activeChannelId,
    closeMessageDrawer,
  } = useStreamChat()

  return (
    <SwipeableDrawer
      anchor="right"
      open={messageDrawerOpen}
      onOpen={() => setMessageDrawerOpen(true)}
      onClose={closeMessageDrawer}
      sx={{
        zIndex: theme.zIndex.tooltip + 1,
        '& .MuiDrawer-paper': {
          width: '100%',
          maxWidth: {
            sm: '100%',
            md: '400px',
          },
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <ChannelList />
      {activeChannelId && <ChatChannel />}
    </SwipeableDrawer>
  )
}

export default GetStreamChatDrawer

import useOrganization from '@/hooks/useOrganization'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useParams } from 'react-router-dom'
import { getImagePath } from '@/utils/helpers'
import GlobalContext from '@/context/GlobalContext'
import { useContext, FC, useEffect, useState } from 'react'
import Layout from '@/components/Layout'
import { useSizes } from '@/hooks/useSizes'
import ProfileSummary from '@/pages/Profile/PublicProfile/ProfileSummary'
import {
  GetAttorneysProfileByIdQueryHookResult,
  useGetAttorneysProfileByIdQuery,
} from '@/gql/appApi'
import CloseIcon from '@mui/icons-material/Close'
import { Typography } from '@mui/material'
import { useUsersMeQuery } from '@/gql/systemApi'
import { LICENSE_STATUS, IMAGE_SIZES } from '@/constants'
import ProfileBio from './ProfileBio'

const PublicProfile: FC = () => {
  const { screenSize } = useSizes()
  const { id } = useParams()
  const globalContext = useContext(GlobalContext)
  const permissions = globalContext?.globalPermissions
  const [myOrganizationID, setMyOrganizationID] = useState('')
  // Organization data of user viewing profile
  const { getOrganizationData } = useOrganization()
  const { data: UsersMeQueryData, loading: usersMeQueryLoading } =
    useUsersMeQuery()

  useEffect(() => {
    async function fetchOrganizationData() {
      const getMyOrganizationDataResult = await getOrganizationData()
      setMyOrganizationID(getMyOrganizationDataResult.organizationUserId || '')
    }
    fetchOrganizationData()
  }, [])

  const { data, loading }: GetAttorneysProfileByIdQueryHookResult =
    useGetAttorneysProfileByIdQuery({
      variables: {
        filter: {
          attorney_id: {
            id: {
              _eq: id,
            },
          },
        },
        licenseFilter: {
          status: {
            id: {
              _eq: LICENSE_STATUS.VALIDATED,
            },
          },
        },
      },
    })

  const profile = data?.attorney_profile?.[0]

  const ATTORNEY_ID = profile?.attorney_id?.id || ''
  const PROFILE = {
    ...profile,
    avatar: {
      id: getImagePath({
        id: profile?.attorney_id?.user_id?.avatar?.id ?? null,
        size: IMAGE_SIZES.Large,
      }),
    },
  }
  const currentOrganization =
    PROFILE?.attorney_id?.user_id?.current_organization?.[0]
  const viewingProfileOrganizationID = currentOrganization?.id
  const isSameOrganization = viewingProfileOrganizationID === myOrganizationID
  const currentPermissionsSet = isSameOrganization
    ? permissions?.inOrganization
    : permissions?.outOrganization
  const isWorkingForMe = !!UsersMeQueryData?.users_me?.id

  const mobileLayout = screenSize !== 'desktop'
  const somethingLoading =
    loading || usersMeQueryLoading || !currentPermissionsSet

  return (
    <Layout sx={{ overflow: 'auto', padding: { xs: 0 }, maxWidth: 'lg' }}>
      <Grid
        container
        spacing={mobileLayout ? 0 : 2}
        sx={{
          mt: {
            xs: 0,
            md: 3,
          },
          mb: mobileLayout ? 10 : 3,
          borderRadius: 1,
          width: '100%',
          mx: 'auto',
        }}
      >
        {currentPermissionsSet?.User.read ||
        isWorkingForMe ||
        somethingLoading ? (
          <>
            <Grid item md={4} xs={12}>
              <ProfileSummary
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                profile={PROFILE}
                attorneyId={ATTORNEY_ID}
                loading={somethingLoading}
                currentPermissionsSet={currentPermissionsSet}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                currentOrganization={currentOrganization}
                isSameOrganization={isSameOrganization}
              />
            </Grid>
            <Grid item md={8} xs={12}>
              <ProfileBio
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                profile={PROFILE}
                loading={somethingLoading}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <CloseIcon
                sx={theme => ({
                  border: '3px solid',
                  borderRadius: '100%',
                  color: theme.palette.text.disabled,
                  mb: 2,
                  mt: '30vh',
                })}
              />
              <Typography
                variant="h6"
                color="text.secondary"
                sx={{ width: '100%' }}
              >
                You don’t have permissions to view this user profile
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Layout>
  )
}
export default PublicProfile

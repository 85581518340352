import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const ProfileDescription = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5, 2.5, 8, 2.5),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2, 2.8125, 10, 2.8125),
  },
}))

export const Spacer = styled(Box)<{ gap?: string }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ gap }) => gap || '8px'};
`

import { getImagePath } from '@/utils'
import { IDashboardAttorney } from '../types'
import AttorneyCases from './AttorneyCases'
import { IMAGE_SIZES } from '@/constants'

interface AttorneyDashboardProps {
  avatarMap: Record<string, string>
  attorneyData: IDashboardAttorney
}

export const AttorneyDashboard = ({
  avatarMap,
  attorneyData: {
    metricsAttorney,
    topAttorneysAttorney,
    topJurisdictionsAttorney,
    topPracticeareasAttorney,
  },
}: AttorneyDashboardProps) => (
  <AttorneyCases
    py={{
      xs: 0,
      sm: 1,
      lg: 5,
    }}
    pt={{
      xs: 0,
      sm: 0,
      lg: 0,
    }}
    mt="0 !important"
    inboundReferrals={{
      marketplace: metricsAttorney.numReferralsInboundMarketplace,
      waterfallReferrals: metricsAttorney.numReferralsInboundWaterfall,
    }}
    outboundReferrals={{
      marketplace: metricsAttorney.numReferralsOutboundMarketplace,
      waterfallReferrals: metricsAttorney.numReferralsOutboundWaterfall,
    }}
    topAttorneys={{
      data: topAttorneysAttorney.map(
        ({
          attorneyName,
          numReferralsInbound,
          numReferralsOutbound,
          attorneyId,
        }) => {
          const imageUrl =
            attorneyId in avatarMap
              ? getImagePath({
                  id: avatarMap[attorneyId],
                  size: IMAGE_SIZES.Small,
                })
              : ``

          return {
            inboundReferrals: numReferralsInbound,
            imageUrl,
            name: attorneyName,
            outboundReferrals: numReferralsOutbound,
          }
        }
      ),
    }}
    topJurisdictions={{
      data: topJurisdictionsAttorney.map(({ jurisdiction, numCases }) => ({
        jurisdiction,
        referrals: numCases,
      })),
    }}
    topPracticeAreas={{
      data: topPracticeareasAttorney.map(({ numCases, practiceArea }) => ({
        practiceArea,
        referrals: numCases,
      })),
    }}
    totalReferrals={{
      inboundCases: metricsAttorney.numReferralsInbound,
      marketplace: metricsAttorney.numReferralsMarketplace,
      outboundCases: metricsAttorney.numReferralsOutbound,
      waterfallReferrals: metricsAttorney.numReferralsWaterfall,
    }}
  />
)

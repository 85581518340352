import { UserOrganizacionQuery } from '@/gql/systemApi'
import get from 'lodash/get'

export const generateRandom = (min: number, max: number): number => {
  const difference = max - min
  let rand = Math.random()
  rand = Math.floor(rand * difference)
  rand += min

  return rand
}

export const isValidEmail = (value: string) => {
  const emailRegex = /^\S+@\S+\.\S+$/
  if (!value) {
    return 'Please enter an Email Address'
  }
  if (!emailRegex.test(value)) {
    return 'Please enter a valid Email Address'
  }
  return true
}

export const validatePhoneNumber = (input: string) => {
  if (input === '') {
    return true
  }
  const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/

  return re.test(input)
}

export const getFilePath = (id: string | null) =>
  id && `${import.meta.env.VITE_API_URL.replace('graphql', 'assets')}/${id}`

/**
 * Get image path with size
 * @param id - image id
 * @param size - image size in pixels (width and height)
 * @returns string | null - image path if exists
 */
export const getImagePath = ({
  id,
  size,
}: {
  id: string | null
  size: number | null
}) => {
  const filePath = getFilePath(id)
  if (!filePath) return ''
  if (size) {
    return `${filePath}?width=${size}&height=${size}&fit=cover`
  }
  return filePath
}

export const getImageUrlWithParams = ({
  url,
  params,
}: {
  url: string
  params: Record<string, number | string>
}) => {
  const paramString = new URLSearchParams(
    Object.entries(params).map(([key, value]) => [key, String(value)])
  ).toString()
  // if url has query params, replace them with new params
  // otherwise append new params
  const newUrl = url.includes('?')
    ? `${url.split('?')[0]}?${paramString}`
    : `${url}?${paramString}`
  return newUrl
}

export const getMyOrganizationId = (
  orgData: UserOrganizacionQuery | undefined
) => get(orgData, 'users_me.current_organization.[0].organization_id.id')

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

export async function hashStringSHA256(str: string) {
  const encoder = new TextEncoder()
  const data = encoder.encode(str)
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

export const getCurrencyString = (money: number) =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
  }).format(money)

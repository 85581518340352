import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import { Attorney_Profile } from '@/gql/appApi'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import JDropdownUser from '@/components/atoms/JDropdownUser'
import { getImagePath } from '@/utils'
import { IMAGE_SIZES } from '@/constants'
import useSearchCaseManagers from './hooks/useSearchCaseManagers'

export interface SearchCaseManagersProps {
  id?: string
  onChange: (value: Attorney_Profile[] | null) => void
  value: Attorney_Profile[]
  attorneysSelected?: Attorney_Profile[]
  label: string
  placeholder?: string
}

const SearchCaseManagers = ({
  id,
  value = [],
  placeholder,
  onChange,
  attorneysSelected = [],
  label,
}: SearchCaseManagersProps) => {
  const {
    attorneyProfile,
    setAttorney,
    options: allOptions,
    loading,
    filterOptions,
    setInputValue,
  } = useSearchCaseManagers({
    attorneyValue: value,
    attorneysSelected,
  })

  return (
    <Autocomplete
      options={allOptions}
      loading={loading}
      value={attorneyProfile}
      getOptionLabel={(att: Attorney_Profile) =>
        `${att.first_name} ${att.last_name} (${att.id})`
      }
      multiple
      filterOptions={filterOptions}
      onChange={(_, newAttorney) => {
        setAttorney(newAttorney)
        onChange(newAttorney)
      }}
      isOptionEqualToValue={(option, v) => option.id === v.id}
      renderOption={(props, option) => (
        <JDropdownUser
          key={option.id}
          user={{
            id: option.id,
            name: `${option.first_name} ${option.last_name}`,
            picture: getImagePath({
              id: option?.attorney_id?.user_id?.avatar?.id || null,
              size: IMAGE_SIZES.Small,
            }),
            isVetted: option?.attorney_id?.is_vetted || false,
          }}
          sx={{
            avatar: { width: 28, height: 28 },
          }}
          badgeSize="xsmall"
          {...props}
        />
      )}
      onInputChange={(_, eInputValue) => {
        setInputValue(eInputValue)
      }}
      renderInput={params => {
        const { InputProps, ...other } = params
        return (
          <TextField
            sx={{
              width: '100%',
              backgroundColor: theme => theme.palette.common.white,
              borderRadius: theme => theme.spacing(2),
            }}
            {...other}
            size="medium"
            InputProps={{
              ...InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                  {InputProps.startAdornment}
                </>
              ),
            }}
            id={id}
            label={label}
            placeholder={placeholder}
            variant="outlined"
          />
        )
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, ...rest } = getTagProps({ index })
          return (
            <Chip
              key={option.id}
              variant="outlined"
              label={`${option.first_name} ${option.last_name}`}
              {...rest}
            />
          )
        })
      }
    />
  )
}

export default SearchCaseManagers

import { FeatureFlag } from '@/optimizely/types'
import { useFeatureFlags } from '@/optimizely/useFeatureFlags'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'

interface IFreePricingAlertProps {
  isPrivate?: boolean
}

const FreePricingAlert = ({ isPrivate }: IFreePricingAlertProps) => {
  const featureFlags = useFeatureFlags()
  const isNegotiationFlowImprovementEnabled =
    featureFlags[FeatureFlag.NEGOTIATION_FLOW_IMPROVEMENTS]

  if (isNegotiationFlowImprovementEnabled) {
    return null
  }

  const freePricingTitle = 'Good News!'
  const freePricingText = isPrivate
    ? "Since you received this case via a Waterfall Referral™ there's no fee to complete this match!"
    : "Since you were invited to this case there's no fee to complete this match!"

  return (
    <Alert severity="success" sx={{ width: '100%' }} icon={false}>
      <strong>{freePricingTitle}</strong>
      <br />
      <Typography variant="body2">{freePricingText}</Typography>
    </Alert>
  )
}

export default FreePricingAlert

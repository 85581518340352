import { FormProvider, useForm } from 'react-hook-form'

import { IFileUploaderForm, FileUploaderProps } from './types'
import FilesUploader from './FilesUploader'

const FilesUploaderForm = (props: FileUploaderProps) => {
  const methods = useForm<IFileUploaderForm>({
    defaultValues: {},
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  return (
    <FormProvider {...methods}>
      <FilesUploader {...props} />
    </FormProvider>
  )
}

export default FilesUploaderForm

import { useEffect, useState, useMemo, SetStateAction } from 'react'
import { Catalog_Practice_Area, usePracticeAreasQuery } from '@/gql/appApi'
import groupPracticeAreas, { getCleanString } from '../utils'

export const usePracticeAreas = (practiceAreasCtx: string[]) => {
  const { data, loading, error } = usePracticeAreasQuery()
  const [practiceAreas, setPracticeAreas] = useState<
    Array<Catalog_Practice_Area>
  >([])
  const [chipData, setChipData] = useState<string[]>(practiceAreasCtx || [])
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    if (data) {
      setPracticeAreas(data.catalog_practice_area)
    }
  }, [data])

  const options = useMemo(
    () =>
      practiceAreas
        .map((item: Catalog_Practice_Area) => getCleanString(item.name))
        .filter((name): name is string => name != null),
    [practiceAreas]
  )

  const defaultOptions = useMemo(() => {
    const filterDefault = practiceAreas
      .filter(
        (item: Catalog_Practice_Area) =>
          (chipData.includes(item.id) || chipData.includes(item.name || '')) &&
          getCleanString(item.name) != null
      )
      .map((item: Catalog_Practice_Area) => getCleanString(item.name))

    return filterDefault.filter((name): name is string => name != null)
  }, [practiceAreas, chipData])

  const sortedOptions = useMemo(() => options.sort(), [options])

  const groupedOptions = useMemo(
    () => groupPracticeAreas(sortedOptions),
    [sortedOptions]
  )

  const sortedDefault = useMemo(() => defaultOptions.sort(), [defaultOptions])

  const filteredOptions = options.filter(option =>
    option.toLowerCase().includes(searchText.toLowerCase())
  )

  const handleCheckboxClick = (option: string) => {
    const selectedArea = practiceAreas.filter(
      area => area.name != null && area.name === option
    )
    const exists =
      chipData.includes(selectedArea[0].name || '') ||
      chipData.includes(selectedArea[0].id)
    if (exists) {
      setChipData(
        chipData.filter(item => item !== option && item !== selectedArea[0].id)
      )
    } else {
      setChipData([...chipData, option])
    }
  }

  const handleFullScreen = () => {
    setIsFullScreen(true)
  }

  const handleCloseFullScreen = () => {
    setIsFullScreen(false)
  }

  const handleSearchChange = (event: {
    target: { value: SetStateAction<string> }
  }) => {
    setSearchText(event.target.value)
  }

  return {
    practiceAreas,
    groupedOptions,
    sortedOptions,
    filteredOptions,
    sortedDefault,
    chipData,
    setChipData,
    isFullScreen,
    setIsFullScreen,
    searchText,
    setSearchText,
    handleCheckboxClick,
    handleFullScreen,
    handleCloseFullScreen,
    handleSearchChange,
    loading,
    error,
  }
}

import { yupResolver } from '@hookform/resolvers/yup'
import { useSizes } from '@/hooks/useSizes'
import {
  useSignInMutation,
  useUpdatePwMutation,
  useUsersMeQuery,
} from '@/gql/systemApi'
import { useState, useCallback } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDiscardChangesModal } from '@/components'
import { ModalTypes } from '@/types'
import { defaultValues, editPasswordSchema } from './config'
import { TChangePasswordForm } from './types'

const DEFAULT_ERROR = 'Something went wrong'

export const useChangePassword = () => {
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver<TChangePasswordForm>(editPasswordSchema),
  })
  const { textFieldSize } = useSizes()
  const { screenSize } = useSizes()
  const mobileLayout = screenSize !== 'desktop'
  const [response, setResponse] = useState<{
    type: null | ModalTypes
    open: boolean
  }>({
    type: null,
    open: false,
  })

  const {
    getConfirmationModalProps,
    setOpenModal,
    modalOpen,
    type: modalType,
    ...dischargeChangeModalsRestProps
  } = useDiscardChangesModal({
    type: 'confirm',
    onSubmit: () => {
      reset(defaultValues)
      setOpenModal(null)
    },
  })

  const onError = useCallback(() => {
    const errors = { base: DEFAULT_ERROR }
    return {
      baseErrors: errors,
    }
  }, [])

  const { data: UsersMeQueryData, loading: usersMeQueryLoading } =
    useUsersMeQuery()

  const [signInMutation, { loading: signInMutationLoading }] =
    useSignInMutation({
      onError,
    })
  const [updatePwMutation, { loading: updatePwMutationLoading }] =
    useUpdatePwMutation({
      onError,
    })
  const callLoading =
    usersMeQueryLoading || signInMutationLoading || updatePwMutationLoading

  const handleDiscardChangesStart = () => {
    setOpenModal('confirmDiscardChanges')
  }

  const pSize = mobileLayout ? 2 : 0

  const onChangePasswordSubmit: SubmitHandler<
    TChangePasswordForm
    // eslint-disable-next-line consistent-return
  > = async form => {
    const signInData = await signInMutation({
      variables: {
        email: UsersMeQueryData?.users_me?.email || '',
        password: form.currentPassword,
      },
    })
    if (!signInData.data?.auth_login?.access_token || signInData.errors) {
      return setError('currentPassword', {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        message: signInData.errors?.message || 'Incorrect current password',
      })
    }

    const updatePwMutationResult = await updatePwMutation({
      variables: { data: { password: form.newPassword } },
    })
    if (updatePwMutationResult?.errors) {
      setError('repeatPassword', {
        message: 'Operation failed',
      })
      setError('newPassword', {
        message: 'Operation failed',
      })
      return setError('currentPassword', {
        message: 'Operation failed',
      })
    }
    setResponse({ type: 'ChangePasswordSuccess', open: true })
  }

  return {
    control,
    handleSubmit,
    pSize,
    textFieldSize,
    handleDiscardChangesStart,
    callLoading,
    onChangePasswordSubmit,
    mobileLayout,
    response,
    setResponse,
    getConfirmationModalProps,
    modalOpen,
    dischargeChangeModalsRestProps,
    isDirty,
    isValid,
  }
}

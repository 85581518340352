import { createBrowserRouter, Navigate } from 'react-router-dom'
import { ReactNode } from 'react'

import AuthRoute from '@/apollo/AuthRoute'
import {
  CreateAccount,
  EditPublicProfile,
  Error,
  Marketplace,
  PublicProfile,
  MyNetwork,
  MyReferrals,
  CreateNewCase,
  EditCase,
  CreateProfile,
  SignIn,
  SignOut,
  AccountSettings,
  Case,
  AdminPanel,
  Verification,
  ForgotPassword,
  Dashboard,
  Maintenance,
} from '@/pages'
import { DocumentTitle } from '@/components'

import { IS_MAINTENANCE_ENABLED } from '@/constants'
import { RoutesData } from './RoutesData'

const isMaintenanceEnabled = IS_MAINTENANCE_ENABLED === 'true'

export const Routes: Partial<
  Record<
    keyof typeof RoutesData,
    { element: ReactNode; errorElement: ReactNode }
  >
> = {
  RHome: {
    element: <Navigate to="/signIn" replace />,
    errorElement: <Error />,
  },
  RCreateAccount: {
    element: (
      <>
        <DocumentTitle title={RoutesData.RCreateAccount.title} />
        <CreateAccount />
      </>
    ),
    errorElement: <Error />,
  },
  RClaimInvite: {
    element: (
      <>
        <DocumentTitle title={RoutesData.RClaimInvite.title} />
        <CreateAccount />
      </>
    ),
    errorElement: <Error />,
  },
  REditProfile: {
    element: (
      <AuthRoute>
        <DocumentTitle title={RoutesData.REditProfile.title} />
        <EditPublicProfile />
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RProfile: {
    element: (
      <AuthRoute>
        <DocumentTitle title={RoutesData.RProfile.title} />
        <PublicProfile />
      </AuthRoute>
    ),
    errorElement: <Error />,
  },

  RMyNetwork: {
    element: (
      <AuthRoute>
        <DocumentTitle title={RoutesData.RMyNetwork.title} />
        <MyNetwork />
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RMarketplace: {
    element: (
      <AuthRoute>
        <DocumentTitle title={RoutesData.RMarketplace.title} />
        <Marketplace />
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RMyReferrals: {
    element: (
      <AuthRoute>
        <DocumentTitle title={RoutesData.RMyReferrals.title} />
        <MyReferrals />
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RCreateProfile: {
    element: (
      <AuthRoute>
        <DocumentTitle title={RoutesData.RCreateProfile.title} />
        <CreateProfile />
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RCreateNewCase: {
    element: (
      <AuthRoute>
        <DocumentTitle title={RoutesData.RCreateNewCase.title} />
        <CreateNewCase />
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  REditCase: {
    element: (
      <AuthRoute>
        <DocumentTitle title={RoutesData.REditCase.title} />
        <EditCase />
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RSignIn: {
    element: (
      <AuthRoute>
        <DocumentTitle title={RoutesData.RSignIn.title} />
        <SignIn />
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RSignOut: {
    element: <SignOut />,
    errorElement: <Error />,
  },
  RSettings: {
    element: (
      <AuthRoute>
        <DocumentTitle title={RoutesData.RSettings.title} />
        <AccountSettings />
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RCase: {
    element: (
      <AuthRoute>
        <DocumentTitle title={RoutesData.RCase.title} />
        <Case />
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RAdmin: {
    element: (
      <AuthRoute>
        <DocumentTitle title={RoutesData.RAdmin.title} />
        <AdminPanel />
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RVerification: {
    element: (
      <>
        <DocumentTitle title={RoutesData.RVerification.title} />
        <Verification />
      </>
    ),
    errorElement: <Error />,
  },
  RResetPassword: {
    element: (
      <>
        <DocumentTitle title={RoutesData.RResetPassword.title} />
        <ForgotPassword />
      </>
    ),
    errorElement: <Error />,
  },
  RDashboard: {
    element: (
      <AuthRoute>
        <DocumentTitle title={RoutesData.RDashboard.title} />
        <Dashboard />
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
}

const routesArr: {
  element: ReactNode
  errorElement: ReactNode
  path: string
}[] = Object.keys(Routes).map(key => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  ...Routes[key],
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  ...RoutesData[key],
}))

const rootRouter = createBrowserRouter(
  routesArr.map(r => ({
    element: isMaintenanceEnabled ? <Maintenance /> : r.element,
    errorElement: r.errorElement,
    path: r.path,
  }))
)

export default rootRouter

import { useMemo, useCallback } from 'react'
import {
  useStats,
  usePagination,
  useInstantSearch,
  useHitsPerPage,
} from 'react-instantsearch'

import {
  Box,
  Typography,
  Select,
  MenuItem,
  Pagination,
  SelectChangeEvent,
} from '@mui/material'
import {
  ALGOLIA_STARTING_ROWS_PER_PAGE,
  ALGOLIA_HITS_PER_PAGE_OPTIONS,
} from '@/pages/Marketplace/config'
import { pageLayoutBodyClass } from '@/components/Layout'
import { sleep } from '@/utils'

const { VITE_ALGOLIA_MARKETPLACE_INDEX } = import.meta.env

const AlgoliaPagination = () => {
  const { indexUiState, setIndexUiState, setUiState } = useInstantSearch()
  const { refine: refinePagination } = usePagination()
  const { refine: refineHitsPerPage } = useHitsPerPage({
    items: useMemo(
      () =>
        ALGOLIA_HITS_PER_PAGE_OPTIONS.map(value => ({
          label: value.toString(),
          value,
          default: value === ALGOLIA_STARTING_ROWS_PER_PAGE,
        })),
      []
    ),
  })
  const { nbHits } = useStats()

  const page = Number(indexUiState.page) || 1
  const hitsPerPage = indexUiState.hitsPerPage || ALGOLIA_STARTING_ROWS_PER_PAGE

  const handleChangeRowsPerPage = useCallback(
    (event: SelectChangeEvent<number>) => {
      const newValue = parseInt(String(event.target.value), 10)
      setIndexUiState(prevState => ({
        ...prevState,
        hitsPerPage: newValue,
        page: 1,
      }))

      setUiState(prevState => ({
        ...prevState,
        [VITE_ALGOLIA_MARKETPLACE_INDEX]: {
          ...prevState[VITE_ALGOLIA_MARKETPLACE_INDEX],
          hitsPerPage: newValue,
          page: 1,
        },
      }))
      refineHitsPerPage(newValue)
    },
    [setIndexUiState, setUiState, refineHitsPerPage]
  )

  const handlePageChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: number) => {
      // Algolia pagination is 0-based
      refinePagination(value - 1)
      setIndexUiState(prevState => {
        // NOTE: This operation needs to be deferred until the next render (via
        // `sleep(0)`) to ensure consistent scrolling behavior.
        sleep(0).then(() => {
          document
            .querySelector(`.${pageLayoutBodyClass}`)
            ?.scrollTo({ top: 0, behavior: 'smooth' })
        })

        return {
          ...prevState,
          page: value,
        }
      })
    },
    [refinePagination, setIndexUiState]
  )

  const paginationCount = Math.ceil(nbHits / hitsPerPage)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column-reverse',
          sm: 'row',
        },
        justifyContent: {
          xs: 'center',
          sm: 'space-between',
        },
        alignItems: 'center',
        padding: 2,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: { xs: 2, sm: 0 },
          paddingBottom: { xs: 0 },
          width: { xs: '100%', sm: 'auto' },
        }}
      >
        <Typography variant="body2" sx={{ marginRight: 2 }}>
          Rows per page:
        </Typography>
        <Select
          value={hitsPerPage}
          onChange={handleChangeRowsPerPage}
          sx={{ marginRight: 2, height: 32, maxWidth: 100 }}
        >
          {ALGOLIA_HITS_PER_PAGE_OPTIONS.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Pagination
        count={paginationCount}
        page={page}
        onChange={handlePageChange}
        showFirstButton
        showLastButton
        siblingCount={1}
        boundaryCount={0}
        sx={{
          width: { xs: '100%', sm: 'auto' },
          justifyContent: 'center',
          display: 'flex',
        }}
      />
    </Box>
  )
}

export default AlgoliaPagination

import { useCallback } from 'react'

import { useStateMachine } from '@/hooks/useStateMachine'

const useOrganizationFlow = () => {
  const [state, send] = useStateMachine({})({
    initial: 'lawFirmPublicInfo',
    on: {
      INITIAL_STEP: 'lawFirmPublicInfo',
    },
    states: {
      lawFirmPublicInfo: {
        on: {
          NEXT: 'lawyerDecision',
        },
      },
      lawyerDecision: {
        on: {
          PREVIOUS: 'lawFirmPublicInfo',
          NEXT: 'professionalLicense',
        },
      },
      professionalLicense: {
        on: {
          PREVIOUS: 'lawyerDecision',
        },
      },
    },
  })

  const next = useCallback(() => send('NEXT'), [send])
  const back = useCallback(() => send('PREVIOUS'), [send])

  return {
    state,
    send,
    next,
    back,
  }
}

export default useOrganizationFlow

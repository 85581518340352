/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react'
import {
  TCreateProfileEducationForm,
  educationDefaultValues,
} from '@/pages/CreateProfile/Education/types'
import useOrganization from '@/hooks/useOrganization'
import useAuthentication from '@/hooks/useAuthentication'
import { ACCOUNT_TYPE, USER_ROLES } from '@/constants'
import { useResendEmailVerification } from '@/hooks/useResendEmailVerification'
import {
  ProfessionalLicenseFormValues,
  getDefaultValues as getProfessionalLicenseDefaultValues,
} from '@/components/ProfessionalLicensesForm'
import dayjs from 'dayjs'
import { sessionStorageService } from '@/services/SessionStorage/SessionStorageService'
import useOrganizationFlow from './useOrganizationFlow'
import {
  TLawFirmPublicInfo,
  lawFirmDefaultValues,
  TCreateOrganizationProfile,
  profileDefaultValues,
} from '../types'
import { CreateAccountData } from '../../InitialStep/types'

export type CreateOrgProfileFormState = {
  lawFirmPublicInfo: TLawFirmPublicInfo
  professionalLicenses: ProfessionalLicenseFormValues[]
  practiceAreas: string[]
  education: TCreateProfileEducationForm
  profile: TCreateOrganizationProfile
}

interface IUseCreateOrganizationProps {
  createAccountValues: CreateAccountData
}

export const useCreateOrganization = ({
  createAccountValues,
}: IUseCreateOrganizationProps) => {
  const { resendVerificationEmail, isLoading: resendEmailLoading } =
    useResendEmailVerification()
  const { createAccount, loading: authLoading } = useAuthentication()
  const {
    getOrganizationData,
    updateOrganizationAndProfile,
    loading: orgLoading,
  } = useOrganization()
  const [hasLicense, setHasLicense] = useState<boolean>(true)
  const [emailModalOpen, setEmailModalOpen] = useState<boolean>(false)
  const [error, setError] = useState('')
  const { state, next, back } = useOrganizationFlow()
  const [formState, setFormState] = useState<CreateOrgProfileFormState>({
    lawFirmPublicInfo: lawFirmDefaultValues,
    professionalLicenses: [getProfessionalLicenseDefaultValues()],
    practiceAreas: [],
    education: educationDefaultValues,
    profile: profileDefaultValues,
  })

  const loading = authLoading || orgLoading

  const updateFormState = (
    key: keyof CreateOrgProfileFormState,
    value: CreateOrgProfileFormState[keyof CreateOrgProfileFormState]
  ) => {
    setFormState(prevState => ({ ...prevState, [key]: value }))
  }

  const submitAndContinue = async (
    key: keyof CreateOrgProfileFormState,
    data: any
  ) => {
    updateFormState(key, data)
    next()
  }

  const handleLicenseSelection = useCallback((withLicense: boolean) => {
    setHasLicense(withLicense)
  }, [])

  const handleEmailModalOpen = useCallback((open: boolean) => {
    setEmailModalOpen(open)
  }, [])

  const handleCreateOrg = useCallback(
    async (
      formValues?:
        | ProfessionalLicenseFormValues
        | { professionalLicenses: ProfessionalLicenseFormValues[] }
    ) => {
      const licenseValuesArray: ProfessionalLicenseFormValues[] = []

      if (formValues && 'professionalLicenses' in formValues) {
        licenseValuesArray.push(...formValues.professionalLicenses)
      } else if (formValues) {
        licenseValuesArray.push(formValues)
      }

      // Makes sure that create account runs on an empty sessionStorageService instance
      // otherwise the create account mutation will fail.
      sessionStorageService.clear()

      const targetRole = licenseValuesArray[0]?.barNumber
        ? USER_ROLES.SAdminAttorneyUser
        : USER_ROLES.SuperAdminUser
      const result = await createAccount({
        email: createAccountValues.email.toLowerCase(),
        password: createAccountValues.password,
        firstName: createAccountValues.firstName,
        lastName: createAccountValues.lastName,
        tags: JSON.stringify({
          target_role: targetRole,
          account_type: ACCOUNT_TYPE.Organization,
        }),
      })
      if (result.id && result.profileId) {
        const getMyOrganizationDataResult = await getOrganizationData()
        if (getMyOrganizationDataResult.organizationProfileId) {
          const updateResult = await updateOrganizationAndProfile({
            // Organization profile
            organizationProfileId:
              getMyOrganizationDataResult.organizationProfileId,
            address: formState.lawFirmPublicInfo.address,
            caseManagementSoftwareId:
              formState.lawFirmPublicInfo.caseManagementSoftware,
            city: formState.lawFirmPublicInfo.city,
            companySizeId: formState.lawFirmPublicInfo.companySize,
            description: formState.lawFirmPublicInfo.description,
            lawFirmName: formState.lawFirmPublicInfo.name,
            lawFirmStateId: formState.lawFirmPublicInfo.state,
            zipCode: formState.lawFirmPublicInfo.zipcode,
            websiteUrl: formState.lawFirmPublicInfo.website,
            // Attorney profile
            licenses: licenseValuesArray.map(license => ({
              barNumber: license.barNumber,
              isActive: license.isActive,
              isDisciplined: license.isDisciplined,
              isSued: license.isSued,
              stateId: license.state,
              malpracticeInsuranceId: license.malpracticeInsuranceId,
              insuranceExpirationDate: license.insuranceExpirationDate
                ? dayjs(license.insuranceExpirationDate).format('YYYY-MM-DD')
                : null,
            })),
            profileId: result.profileId,
            phoneNumber: createAccountValues.phone,
          })
          if (
            updateResult.organizationProfileId &&
            updateResult.updateProfileId
          ) {
            handleEmailModalOpen(true)
            return
          }
          if (updateResult.updateOrganizationErrors) {
            setError(updateResult.updateOrganizationErrors.base)
            return
          }
        }
        if (getMyOrganizationDataResult?.error) {
          setError(getMyOrganizationDataResult.error)
          return
        }
      }
      if (result.createAccountErrors) {
        setError(result.createAccountErrors.base)
      }
    },
    [
      createAccount,
      createAccountValues.email,
      createAccountValues.firstName,
      createAccountValues.lastName,
      createAccountValues.password,
      createAccountValues.phone,
      formState.lawFirmPublicInfo.address,
      formState.lawFirmPublicInfo.caseManagementSoftware,
      formState.lawFirmPublicInfo.city,
      formState.lawFirmPublicInfo.companySize,
      formState.lawFirmPublicInfo.description,
      formState.lawFirmPublicInfo.name,
      formState.lawFirmPublicInfo.state,
      formState.lawFirmPublicInfo.website,
      formState.lawFirmPublicInfo.zipcode,
      getOrganizationData,
      handleEmailModalOpen,
      updateOrganizationAndProfile,
    ]
  )

  const onResendEmail = useCallback(async () => {
    if (!createAccountValues.email) return false
    try {
      const result = await resendVerificationEmail({
        email: createAccountValues.email,
      })
      return result === 'success'
    } catch (e) {
      return false
    }
  }, [createAccountValues.email, resendVerificationEmail])

  return {
    state,
    formState,
    next,
    back,
    handleCreateOrg,
    hasLicense,
    handleLicenseSelection,
    submitAndContinue,
    loading,
    emailModalOpen,
    handleEmailModalOpen,
    onResendEmail,
    resendEmailLoading,
    setError,
    error,
  }
}

import React from 'react'
import {
  Typography,
  Avatar,
  Button,
  styled,
  IconButton,
  Tooltip,
} from '@mui/material'
import PersonAddAlt from '@mui/icons-material/PersonAddAlt'
import CloseIcon from '@mui/icons-material/Close'
import { useCaseContext } from '../../../hooks/useCaseContext'
import { CaseManager } from '../../../types'
import SidebarAccordion from '../SidebarAccordion'

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  width: '100%',
})

const ManagerItem = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 0),
  width: '100%',
}))

const ManagerInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  minWidth: 0,
}))

const ManagerAvatar = styled(Avatar)({
  width: 24,
  height: 24,
  fontSize: 14,
})

const ManagerName = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  marginTop: theme.spacing(2),
}))

const NoManagersMessage = styled(Typography)(({ theme }) => ({
  maxWidth: '250px',
  margin: '0 auto',
  textAlign: 'center',
  color: theme.palette.text.disabled,
}))

const IconButtonContainer = styled(IconButton)(({ theme }) => ({
  '&:focus-visible': {
    outline: '2px solid',
    outlineColor: theme.palette.primary.main,
    outlineOffset: 2,
  },
}))

const NoManagers: React.FC = () => (
  <NoManagersMessage variant="body2">
    You don&apos;t have collaborators with permissions to act on your behalf
  </NoManagersMessage>
)

const HasManagers: React.FC<{
  managers: CaseManager[]
  onRemoveManager: (id: string) => void
}> = ({ managers, onRemoveManager }) => (
  <List role="list">
    {managers.map((manager: CaseManager) => (
      <ManagerItem key={manager.id} role="listitem">
        <ManagerInfo>
          <ManagerAvatar src={manager.avatar_image || ''} alt={manager.name} />
          <Tooltip title={manager.name}>
            <ManagerName variant="subtitle1">{manager.name}</ManagerName>
          </Tooltip>
        </ManagerInfo>
        <IconButtonContainer
          size="small"
          onClick={() => onRemoveManager(manager.id)}
          aria-label={`Remove ${manager.name}`}
        >
          <CloseIcon
            fontSize="small"
            sx={{ color: 'text.primary', fontSize: 16 }}
          />
        </IconButtonContainer>
      </ManagerItem>
    ))}
  </List>
)

const CaseManagers: React.FC<{ defaultExpanded?: boolean }> = ({
  defaultExpanded = false,
}) => {
  const { caseData } = useCaseContext()

  if (!caseData) return null

  const { case_managers } = caseData.sidebar

  const handleAddManager = () => {
    // TODO: Implement add manager logic
    console.log('Add case manager')
  }

  const handleRemoveManager = (managerId: string) => {
    // TODO: Implement remove manager logic
    console.log('Remove case manager', managerId)
  }

  return (
    <SidebarAccordion title="Case Managers" defaultExpanded={defaultExpanded}>
      {case_managers && case_managers.current_case_managers.length > 0 ? (
        <HasManagers
          managers={case_managers.current_case_managers}
          onRemoveManager={handleRemoveManager}
        />
      ) : (
        <NoManagers />
      )}
      <ButtonContainer>
        <Button
          startIcon={<PersonAddAlt fontSize="small" />}
          onClick={handleAddManager}
          variant="text"
          size="small"
          color="primary"
        >
          Add Case Manager
        </Button>
      </ButtonContainer>
    </SidebarAccordion>
  )
}

export default CaseManagers

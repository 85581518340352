import { useCallback } from 'react'
import { useStateMachine } from '@/hooks/useStateMachine'

type TUseCreateOrgProfileFlowProps = {
  hasLicense?: boolean
}

const useCreateOrgProfileFlow = ({
  hasLicense,
}: TUseCreateOrgProfileFlowProps) => {
  const [state, send] = useStateMachine({})({
    initial: 'practiceAreas',
    on: {
      INITIAL_STEP: hasLicense ? 'practiceAreas' : 'personalOrgInfo',
      MEMBERSHIP: 'membershipInfo',
      COMMUNICATION_PREFERENCES: 'communicationPreferences',
    },
    states: {
      practiceAreas: {
        on: {
          NEXT: 'education',
        },
      },
      education: {
        on: {
          PREVIOUS: 'practiceAreas',
          NEXT: 'personalOrgInfo',
        },
      },
      personalOrgInfo: {
        on: {
          PREVIOUS: hasLicense ? 'education' : undefined,
          NEXT: 'communicationPreferences',
        },
      },
      communicationPreferences: {
        on: {
          PREVIOUS: 'personalOrgInfo',
          NEXT: 'membershipInfo',
        },
      },
      membershipInfo: {
        on: {
          PREVIOUS: 'personalOrgInfo',
        },
      },
    },
  })

  const next = useCallback(() => send('NEXT'), [send])
  const back = useCallback(() => send('PREVIOUS'), [send])
  const goToPayment = useCallback(() => send('MEMBERSHIP'), [send])
  const goToCommunicationPreferences = useCallback(
    () => send('COMMUNICATION_PREFERENCES'),
    [send]
  )

  return {
    state,
    send,
    next,
    back,
    goToPayment,
    goToCommunicationPreferences,
  }
}

export default useCreateOrgProfileFlow

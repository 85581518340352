import { useEffect, useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'

import { useSizes } from '@/hooks/useSizes'
import {
  useLawFirmCatalogsQuery,
  useGetOrganizationProfileQuery,
  GetOrganizationProfileDocument,
  useUpdateOrganizationItemMutation,
} from '@/gql/appApi'
import useOrganizationId from '@/hooks/organization/useOrganizationId'
import GlobalContext from '@/context/GlobalContext'
import { useDiscardChangesModal } from '@/components'
import { ModalTypes } from '@/types'
import { useSnackBar } from '@/hooks/useSnackBar'

export type LawInfoForm = {
  name: string
  description: string
  address: string
  city: string
  state: string
  zipcode: string
  company_size: string
  case_management_software: string
  website_url: string
}

const defaultValues: LawInfoForm = {
  name: '',
  description: '',
  address: '',
  city: '',
  state: '',
  zipcode: '',
  company_size: '',
  case_management_software: '',
  website_url: '',
}

const useLawInfo = () => {
  const globalContext = useContext(GlobalContext)

  const { isLessMd } = useSizes()
  const { alert, showAlert, handleClose: handleCloseSnack } = useSnackBar()

  const [response, setResponse] = useState<{
    type: ModalTypes
    open: boolean
  }>({
    type: null,
    open: false,
  })

  const [updateOrganizationItemMutation, { error }] =
    useUpdateOrganizationItemMutation({
      refetchQueries: [GetOrganizationProfileDocument],
    })

  const { id } = useOrganizationId()

  const { data } = useGetOrganizationProfileQuery({
    variables: {
      filter: {
        organization_id: {
          id: {
            _eq: id,
          },
        },
      },
    },
  })

  const { data: lawFirmCatalogs, loading: lawFirmCatalogsLoading } =
    useLawFirmCatalogsQuery({
      variables: {
        catalogFirmRelationshipSort2: 'name',
        catalogUsStateSort2: 'code',
        catalogYearsPracticeSort2: 'name',
        sortCaseSoftware: 'name',
      },
    })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const setAPIData = useCallback(
    () => ({ ...data?.organization_profile[0] }),
    [data]
  )

  const {
    getConfirmationModalProps,
    setOpenModal,
    modalOpen,
    type: modalType,
    onSubmit: onSubmitDiscardModal,
  } = useDiscardChangesModal({
    type: 'confirm',
    onSubmit: () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      reset(setAPIData())
      setOpenModal(null)
      setResponse({ type: 'DiscardChangesAccepted', open: true })
    },
  })

  useEffect(() => {
    if (error) {
      showAlert({
        severity: 'error',
        message: 'Something went wrong, please try again in a few minutes',
      })
    }
  }, [error, showAlert])

  useEffect(() => {
    if (data?.organization_profile) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      reset(setAPIData())
    }
  }, [data, reset, setAPIData])

  useEffect(() => {
    globalContext?.setPreventNavigation(isDirty)
  }, [isDirty, globalContext])

  const onSubmit = async (form: LawInfoForm) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const { id: formId, __typename, ...formData } = form
    const payload = {
      updateOrganizationProfileItemId: formId,
      data: formData,
    }

    const update = await updateOrganizationItemMutation({
      variables: {
        ...payload,
      },
    })
    if (update.data) {
      showAlert({
        severity: 'success',
        message: 'Data saved successfully',
      })
    }
  }
  return {
    isLessMd,
    alert,
    handleCloseSnack,
    response,
    lawFirmCatalogs,
    lawFirmCatalogsLoading,
    control,
    handleSubmit,
    getConfirmationModalProps,
    modalOpen,
    modalType,
    onSubmitDiscardModal,
    onSubmit,
    isDirty,
    setOpenModal,
    setResponse,
  }
}

export default useLawInfo

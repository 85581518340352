import Box from '@mui/material/Box'
import { ReactNode } from 'react'
import { styled, Theme, SxProps } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'

const SpinnerContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

export interface CustomLoadingProps {
  message?: ReactNode
  sx?: SxProps<Theme>
}
const CustomLoading = ({ message, sx = {} }: CustomLoadingProps) => (
  <SpinnerContainer
    sx={{
      width: '100%',
      mt: {
        xs: 5,
        md: 10,
      },
      ...sx,
    }}
  >
    <Stack direction="column" gap={2}>
      <CircularProgress sx={{ alignSelf: 'center' }} />
      <Typography variant="subtitle1" color="text.disabled">
        {message || 'Loading...'}
      </Typography>
    </Stack>
  </SpinnerContainer>
)

export default CustomLoading

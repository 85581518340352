import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import PhoneField from '../PhoneField/PhoneField'

const styles = {
  '& .MuiOutlinedInput-root': {
    height: '48px',
    '& input': {
      height: 'calc(100% - 12px)',
      padding: '6px 14px',
    },
  },
  '& .MuiInputLabel-root': {
    top: 0,
    left: 0,
    position: 'absolute',
    transform: 'translate(14px, 12px) scale(1)',
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(14px, -8px) scale(0.75)',
  },
}

export const CreateAccountTextField = styled(TextField)(() => styles)

export const CreateAccountPhoneField = styled(PhoneField)(() => styles)

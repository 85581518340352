import { useCallback, useMemo } from 'react'

import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import AuthLayout from '@/components/shared/AuthLayout'
import { CreateAccountData } from '../InitialStep/types'
import { OrganizationDecision } from './OrganizationDecision'
import { OrganizationLawFirmInformation } from './OrganizationLawFirmInformation'
import { useCreateOrganization } from './hooks/useCreateOrganization'
import EmailVerificationModal from '../shared/EmailVerificationModal'
import ProfessionalLicenses from '../ProfessionalLicense/ProfessionalLicenses'

interface CreateOrganizationProps {
  goBack: () => void
  createAccountValues: CreateAccountData
}

const CreateOrganization = ({
  goBack,
  createAccountValues,
}: CreateOrganizationProps) => {
  const {
    state,
    formState,
    next,
    back,
    submitAndContinue,
    handleCreateOrg,
    hasLicense,
    handleLicenseSelection,
    loading,
    emailModalOpen,
    onResendEmail,
    resendEmailLoading,
    setError,
    error,
  } = useCreateOrganization({ createAccountValues })

  const handleGoToInitialStep = useCallback(() => {
    goBack()
  }, [goBack])

  const renderStep = useMemo((): JSX.Element => {
    switch (state.value) {
      case 'lawFirmPublicInfo':
        return (
          <OrganizationLawFirmInformation
            back={handleGoToInitialStep}
            lawFirmPublicInfoValues={formState.lawFirmPublicInfo}
            onSubmit={data => submitAndContinue('lawFirmPublicInfo', data)}
          />
        )
      case 'lawyerDecision':
        return (
          <OrganizationDecision
            next={next}
            back={back}
            hasLicense={hasLicense}
            handleNoLicense={handleCreateOrg}
            handleLicenseSelection={handleLicenseSelection}
            loading={loading}
          />
        )
      case 'professionalLicense':
        return (
          <ProfessionalLicenses
            back={back}
            professionalLicensesValues={formState.professionalLicenses}
            onSubmit={handleCreateOrg}
            loading={loading}
            primaryBtnText="Create My Account"
          />
        )
      default:
        return <CircularProgress />
    }
  }, [
    state.value,
    handleGoToInitialStep,
    formState.lawFirmPublicInfo,
    formState.professionalLicenses,
    next,
    back,
    hasLicense,
    handleLicenseSelection,
    loading,
    handleCreateOrg,
    submitAndContinue,
  ])

  return (
    <AuthLayout>
      <EmailVerificationModal
        open={emailModalOpen}
        onClose={() => {}}
        onResendEmail={onResendEmail}
        isLoading={resendEmailLoading}
      />
      {error && (
        <Alert severity="error" onClose={() => setError('')}>
          {error}
        </Alert>
      )}
      {renderStep}
    </AuthLayout>
  )
}

export default CreateOrganization

import Stack from '@mui/material/Stack'
import { useSizes } from '@/hooks/useSizes'
import get from 'lodash/get'
import initApollo from '@/apollo'
import {
  GetActivityPurchaseDocument,
  GetCasesDocument,
  GetActivityPurchaseFromMyOrgDocument,
} from '@/gql/appApi'
import { useFeatureFlags } from '@/optimizely/useFeatureFlags'
import { FeatureFlag } from '@/optimizely/types'
import {
  getCaseManagerList,
  waterfallToUser,
} from '@/pages/Case/components/Sidebar/CaseSidebar/CaseSidebarUtils'
import {
  CASE_ACCESS_TYPES,
  CONTACT_TYPES,
  FEE_AGREEMENT_STATUS,
  IMAGE_SIZES,
} from '@/constants'
import { caseTitles } from '@/pages/Case/hooks/constants'
import { isQualifiedMembership } from '@/utils/membership'
import { getImagePath } from '@/utils'
import CaseMessageButton from '@/components/GetStreamChat/CaseMessageButton'
import { ISidebarProps } from './types'
import SidebarMessage from '../components/SidebarMessage'
import AttorneyCase from '../components/AttorneyCase'
import AllowCaseManagers from '../components/AllowCaseManagers'
import FreePricingAlert from '../components/FreePricingAlert'
import SidebarFee from '../components/SidebarFee'
import SignatureCard from './SignatureCard'
import Leads from '../../Leads/Leads'
import FeeAgreements from '../../FeeAgreements/FeeAgreements'

const CaseMobileTopOptions = (props: ISidebarProps) => {
  const { isLessMd } = useSizes()
  const featureFlags = useFeatureFlags()
  const isNegotiationFlowImprovementEnabled =
    featureFlags[FeatureFlag.NEGOTIATION_FLOW_IMPROVEMENTS]
  if (!isLessMd) {
    return null
  }
  const {
    dataCase,
    isCaseOwner,
    isCaseFromSomeoneInMyOrganization,
    caseOwnerWithOrg,
    actionTitle,
    actionSubtitle,
    accessType,
    hasAdminAccess,
    iamProspectInCase,
    hasCaseManagerAccess,
    isCaseManager,
    iamProspectAttorneyInExternalCase,
    agreementParams,
    loggedUserOrganizationId,
    isPrivateCase,
    currentUserAttorneyId,
    attorneyId,
    prospectAttorneyProfile,
    caseIsTakenByMyOrg,
    rejectedByAttorneyInvolved,
    rejectedByCounterpart,
    isCaseManagerWithAccess,
    isCaseManagerWithNoAccess,
    showAllowCaseManagerActions,
    isMatched,
    isSigned,
    leads,
    isCaseStatusFlowSimplificationEnabled,
    noRestrictedPermission,
    showInvitationPricing,
    caseInfo,
    feeAgreementFiles,
    feeAgreementStatus,
  } = props

  const caseId = get(
    dataCase,
    'case_purchase_activity[0].case_purchase_id.case_id.id'
  )

  const caseManagerListInCase = getCaseManagerList(
    isCaseOwner
      ? get(
          dataCase,
          'case_purchase_activity[0].case_purchase_id.case_id.ownerships',
          []
        )
      : get(
          dataCase,
          'case_purchase_activity[0].case_purchase_id.ownerships',
          []
        )
  )

  const waterfallProfile = waterfallToUser(
    get(
      dataCase,
      'case_purchase_activity[0].case_purchase_id.prospect_attorney_id.profiles[0]'
    )
  )

  const isOwnerAndPrivateCase =
    accessType === CASE_ACCESS_TYPES.Owner && isPrivateCase

  const isOwnerAsAdminOrCaseManager =
    accessType === CASE_ACCESS_TYPES.Owner &&
    (hasAdminAccess || isCaseManagerWithAccess)

  const relationshipType = (() => {
    if (hasAdminAccess) {
      return 'attorney'
    }
    if (hasCaseManagerAccess) {
      return 'on-behalf-of'
    }
    return isCaseFromSomeoneInMyOrganization ? 'attorney' : 'counterpart'
  })()

  const profileToDisplay = isOwnerAndPrivateCase
    ? waterfallProfile
    : caseOwnerWithOrg

  const onBehalfOfProfile = isOwnerAndPrivateCase
    ? caseOwnerWithOrg
    : waterfallProfile

  const showAgreements = agreementParams && showAllowCaseManagerActions

  const showMessageAlert =
    actionTitle &&
    (caseIsTakenByMyOrg ||
      rejectedByAttorneyInvolved ||
      rejectedByCounterpart ||
      (isCaseFromSomeoneInMyOrganization &&
        accessType === CASE_ACCESS_TYPES.NoAccess) ||
      actionTitle === caseTitles.noPermission ||
      (showAgreements && agreementParams?.finalizedAgreement))

  const casePurchaseId =
    dataCase?.case_purchase_activity?.[0]?.case_purchase_id.id

  const handleRefetch = async () => {
    await initApollo().refetchQueries({
      include: [
        GetActivityPurchaseDocument,
        'case_purchase_activity',
        GetCasesDocument,
        'case',
        GetActivityPurchaseFromMyOrgDocument,
        'case_purchase_activity',
      ],
    })
  }

  if (isSigned && isCaseOwner) {
    return (
      <>
        <SignatureCard
          name={`${agreementParams?.profileToDisplay?.first_name} ${agreementParams?.profileToDisplay?.last_name}`}
          organization={String(
            agreementParams?.profileToDisplay?.attorney_id?.user_id
              ?.current_organization?.[0]?.organization_id?.profiles?.[0]?.name
          )}
          avatar={getImagePath({
            id:
              agreementParams?.profileToDisplay?.attorney_id?.user_id?.avatar
                ?.id || '',
            size: IMAGE_SIZES.Small,
          })}
          email={
            agreementParams?.profileToDisplay?.attorney_id?.user_id?.email || ''
          }
          phone={
            agreementParams?.profileToDisplay?.contacts?.find(
              contact => contact?.type?.id === CONTACT_TYPES.PhoneContactType
            )?.value || ''
          }
          fee={caseInfo?.fee || 0}
          leads={leads ?? []}
        />
        {feeAgreementStatus !==
          FEE_AGREEMENT_STATUS.CreatedPriorToFeeAgreements && (
          <FeeAgreements
            caseId={caseInfo?.id}
            casePurchaseId={casePurchaseId}
            feeAgreementFiles={feeAgreementFiles}
            isOwner={isCaseOwner}
            feeAgreementStatus={feeAgreementStatus}
            onRefetch={handleRefetch}
          />
        )}
        <CaseMessageButton />
      </>
    )
  }

  return (
    <Stack spacing={1.25} p={0}>
      {showInvitationPricing && <FreePricingAlert isPrivate={isPrivateCase} />}
      {showMessageAlert && !showInvitationPricing && (
        <SidebarMessage
          title={actionTitle}
          subtitle={actionSubtitle}
          showInMobile
          finalizedAgreement={Boolean(
            showAgreements && agreementParams?.finalizedAgreement
          )}
          isMatched={isMatched && isCaseStatusFlowSimplificationEnabled}
        />
      )}
      {iamProspectAttorneyInExternalCase && (
        <Leads leadInfo={leads} sx={{ py: 2 }} />
      )}
      {feeAgreementStatus !==
        FEE_AGREEMENT_STATUS.CreatedPriorToFeeAgreements &&
        isSigned &&
        !isCaseOwner && (
          <FeeAgreements
            caseId={caseInfo?.id}
            casePurchaseId={casePurchaseId}
            feeAgreementFiles={feeAgreementFiles}
            isOwner={isCaseOwner}
            feeAgreementStatus={feeAgreementStatus}
            onRefetch={handleRefetch}
          />
        )}
      {caseIsTakenByMyOrg && (
        <AttorneyCase
          caseOwner={prospectAttorneyProfile}
          relationshipType="attorney"
          showSendMessage
        />
      )}

      {showAgreements &&
        isCaseFromSomeoneInMyOrganization &&
        !isPrivateCase && (
          <AttorneyCase
            caseOwner={{
              id: agreementParams?.profileToDisplay?.attorney_id?.id,
              firstName: agreementParams?.profileToDisplay?.first_name,
              lastName: agreementParams?.profileToDisplay?.last_name,

              organization:
                agreementParams?.profileToDisplay?.attorney_id?.user_id
                  ?.current_organization?.[0]?.organization_id?.profiles?.[0]
                  ?.name,

              avatar:
                agreementParams?.profileToDisplay?.attorney_id?.user_id?.avatar
                  ?.id || null,
              secondaryText:
                agreementParams?.profileToDisplay?.attorney_id?.user_id
                  ?.current_organization?.[0]?.organization_id?.profiles?.[0]
                  ?.name,
              isVetted:
                agreementParams?.profileToDisplay?.attorney_id?.is_vetted,
              isQualified: isQualifiedMembership(
                agreementParams?.profileToDisplay?.attorney_id?.membership_type
                  ?.id
              ),
            }}
            relationshipType="counterpart"
            showSendMessage
          />
        )}
      {isOwnerAndPrivateCase && (
        <>
          {noRestrictedPermission && (
            <AttorneyCase
              caseOwner={profileToDisplay}
              relationshipType={
                isOwnerAndPrivateCase ? 'counterpart' : 'attorney'
              }
              showSendMessage
            />
          )}
          {(hasAdminAccess || hasCaseManagerAccess) && (
            <AttorneyCase
              caseOwner={onBehalfOfProfile}
              relationshipType="on-behalf-of"
            />
          )}
        </>
      )}
      {(accessType === CASE_ACCESS_TYPES.NoAccess ||
        (showAgreements && isCaseManagerWithAccess && !isPrivateCase)) &&
        isCaseFromSomeoneInMyOrganization && (
          <AttorneyCase
            caseOwner={caseOwnerWithOrg}
            showSendMessage={
              showAgreements && isCaseManagerWithAccess
                ? false
                : !hasAdminAccess && !iamProspectInCase
            }
            relationshipType={relationshipType}
          />
        )}
      {(isCaseManagerWithNoAccess && isCaseFromSomeoneInMyOrganization) ||
        (isOwnerAsAdminOrCaseManager && !isPrivateCase && (
          <AttorneyCase
            caseOwner={caseOwnerWithOrg}
            showSendMessage={isCaseManagerWithNoAccess}
            relationshipType={isCaseManager ? 'on-behalf-of' : 'attorney'}
          />
        ))}
      {iamProspectAttorneyInExternalCase && noRestrictedPermission && (
        <AttorneyCase
          caseOwner={caseOwnerWithOrg}
          showSendMessage
          relationshipType="case-owner"
        />
      )}
      {isNegotiationFlowImprovementEnabled && (
        <SidebarFee
          fee={caseInfo?.fee || 0}
          sx={{
            py: 1,
          }}
        />
      )}
      {(accessType === CASE_ACCESS_TYPES.Invited ||
        accessType === CASE_ACCESS_TYPES.Waterfall) &&
      (isCaseManagerWithNoAccess || hasAdminAccess) ? (
        <AttorneyCase
          caseOwner={
            (isCaseManagerWithNoAccess || hasAdminAccess) &&
            iamProspectAttorneyInExternalCase
              ? prospectAttorneyProfile
              : caseOwnerWithOrg
          }
          relationshipType={
            (isCaseManagerWithNoAccess || hasAdminAccess) &&
            iamProspectAttorneyInExternalCase
              ? 'attorney'
              : 'counterpart'
          }
          showSendMessage={isCaseManagerWithNoAccess}
        />
      ) : (
        accessType === CASE_ACCESS_TYPES.Waterfall &&
        isCaseManagerWithAccess &&
        currentUserAttorneyId !== attorneyId && (
          <AttorneyCase
            caseOwner={prospectAttorneyProfile}
            relationshipType="attorney"
          />
        )
      )}
      {accessType === CASE_ACCESS_TYPES.NoAccess &&
        iamProspectAttorneyInExternalCase &&
        (isCaseManagerWithAccess || hasAdminAccess ? (
          <AttorneyCase
            caseOwner={prospectAttorneyProfile}
            relationshipType="on-behalf-of"
          />
        ) : (
          isCaseManagerWithNoAccess && (
            <>
              <AttorneyCase
                caseOwner={caseOwnerWithOrg}
                relationshipType="counterpart"
              />
              <AttorneyCase
                caseOwner={prospectAttorneyProfile}
                relationshipType="attorney"
                showSendMessage
              />
            </>
          )
        ))}
      {showAllowCaseManagerActions && (
        <AllowCaseManagers
          caseId={caseId}
          collaborators={caseManagerListInCase}
          isFromSameOrganization={isCaseFromSomeoneInMyOrganization}
          loggedUserOrganizationId={loggedUserOrganizationId ?? ''}
          isCaseOwner={isCaseOwner}
          casePurchaseId={casePurchaseId}
        />
      )}
    </Stack>
  )
}

export default CaseMobileTopOptions

import { useContext, useEffect, useState } from 'react'
import { alpha } from '@mui/material/styles'
import Paper, { PaperProps } from '@mui/material/Paper'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { OrganizationWaterfallsContext } from '../OrganizationWaterfallsContext'

const ConfigurationBox = ({ sx, ...rest }: PaperProps) => {
  const theme = useTheme()
  const {
    isFirmSettings,
    hasPermissionToCreateWaterfalls,
    updateWaterfallCreationPermissions,
    loading,
  } = useContext(OrganizationWaterfallsContext)
  const [enableWaterfallCreation, setEnableWaterfallCreation] = useState(
    Boolean(hasPermissionToCreateWaterfalls)
  )

  useEffect(() => {
    setEnableWaterfallCreation(!!hasPermissionToCreateWaterfalls)
  }, [hasPermissionToCreateWaterfalls, isFirmSettings])

  if (!isFirmSettings && hasPermissionToCreateWaterfalls) {
    return null
  }

  const title = isFirmSettings
    ? 'Waterfalls Configuration'
    : 'Waterfall Referrals™ Permissions'

  const subtitle = isFirmSettings
    ? 'Waterfalls created here will be available for use by other members of your firm. Admins can choose whether or not to allow law firm members to create their own Waterfalls.'
    : `The Admin for your firm has not enabled attorneys to create their own Waterfalls yet. Contact your organization's administrator to unlock this feature.`

  const handleSwitchChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.checked
    setEnableWaterfallCreation(newValue)
    if (updateWaterfallCreationPermissions) {
      await updateWaterfallCreationPermissions(newValue)
    }
  }

  return (
    <Paper
      sx={[
        {
          py: 2.5,
          px: 3,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      <Typography
        variant="subtitle2"
        color={alpha(theme.palette.common.black, 0.87)}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        color={alpha(theme.palette.common.black, 0.6)}
        sx={{ mt: 2 }}
      >
        {subtitle}
      </Typography>
      {isFirmSettings && (
        <FormControlLabel
          sx={{ mt: 2 }}
          control={
            <Switch
              checked={enableWaterfallCreation}
              onChange={handleSwitchChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={
            <Typography
              variant="body2"
              color={alpha(theme.palette.common.black, 0.87)}
            >
              Allow attorneys to create their own Waterfalls
            </Typography>
          }
          disabled={loading}
        />
      )}
    </Paper>
  )
}

export default ConfigurationBox

import AvatarGroup from '@mui/material/AvatarGroup'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Attorney } from '@/pages/MyReferralsV2/types'
import { getImagePath } from '@/utils'
import { IMAGE_SIZES } from '@/constants'
import Avatar from '@mui/material/Avatar'
import useTheme from '@mui/material/styles/useTheme'

type ReferralAttorneyAvatarGroupProps = {
  attorneys: Attorney[]
}

const avatarDimension = '24px'

export const ReferralAttorneyAvatarGroup = ({
  attorneys,
}: ReferralAttorneyAvatarGroupProps) => {
  const theme = useTheme()

  return (
    <Box display="flex" alignItems="center">
      <AvatarGroup
        max={3}
        sx={{
          mr: 1,
          '& .MuiAvatar-root': {
            borderWidth: 1,
          },
        }}
        slotProps={{
          additionalAvatar: {
            sx: {
              height: avatarDimension,
              width: avatarDimension,
              fontSize: theme.spacing(1.5),
            },
          },
        }}
      >
        {attorneys.map(attorney => {
          const avatar = getImagePath({
            id: attorney.avatar,
            size: IMAGE_SIZES.Small,
          })

          return (
            <Avatar
              key={attorney.attorney_id}
              src={avatar}
              alt={`${attorney.first_name} ${attorney.last_name}`}
              sx={{
                height: avatarDimension,
                width: avatarDimension,
              }}
            />
          )
        })}
      </AvatarGroup>
      <Typography
        variant="body2"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textWrap: 'nowrap',
        }}
      >
        {attorneys.length} Negotiations
      </Typography>
    </Box>
  )
}

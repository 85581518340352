import { USER_ROLES } from '@/constants'
import { useRestrictedUserPermissions } from '@/hooks/permissions/useRestrictedUserPermissions'

const useMarketplace = () => {
  const {
    isRestrictedUser: restricted,
    roleId: marketplaceUserRoleId,
    isOrganizationAccount,
    currentPermissionsSet: userMarketplacePermissionsSet,
  } = useRestrictedUserPermissions('Marketplace', 'outOrganization')
  const isMarketplaceRestrictedForUser = isOrganizationAccount
    ? restricted
    : marketplaceUserRoleId?.includes(USER_ROLES.RestrictedUser)

  return {
    isMarketplaceRestrictedForUser,
    userMarketplacePermissionsSet,
    marketplaceUserRoleId,
  }
}

export default useMarketplace

export type TCommunicationPreferencesFormValues = {
  casesToNotify: string
  notificationMethod: string
  isNotificationsEnabled: boolean
}

export type TNotificationMethodOption = {
  id: string
  name: string
}

export const NOTIFICATION_METHODS = {
  EMAIL_AND_SMS: 'catalogNotificationMethod_0',
  EMAIL_ONLY: 'catalogNotificationMethod_1',
  SMS_ONLY: 'catalogNotificationMethod_2',
} as const

export const DEFAULT_COMM_PREFERENCES = {
  Attorney: '80e50ece-54e0-11ef-aaf7-6b0f0497d8ee',
  CaseManager: '431ef6d0-5b5d-11ef-8561-e7bf9e475fc0',
} as const

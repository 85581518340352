import Link from '@mui/material/Link'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { Link as RouterLink } from 'react-router-dom'

export interface TitleProps extends TypographyProps {
  title: string
  href?: string
}

const Title = ({ title, href, sx, ...rest }: TitleProps) =>
  href ? (
    <Link
      to={href}
      component={RouterLink}
      variant="h6"
      color="primary.main"
      underline="none"
    >
      {title}
    </Link>
  ) : (
    <Typography
      variant="h6"
      sx={[
        {
          color: theme => theme.palette.primary.main,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {title}
    </Typography>
  )

export default Title

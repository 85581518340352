import { AvatarGroup } from '@mui/material'
import Badge from '@mui/material/Badge'
import ListItemAvatar from '@mui/material/ListItemAvatar'

import { isValidElement, ReactNode } from 'react'
import { IJListItem } from './types'
import * as S from './styles'
import JAvatar from '../JAvatar'

/*
 * Function to generate an "alt" text from an image source
 */
const getAlt = (imgSrc: string) => imgSrc.replace(/\.[^/.]+$/, '')

const JListItem = ({
  avatar,
  text,
  label,
  badgeContent,
  badgeVariant = 'square',
  icon,
  secondaryIcon,
  reverseText = true,
  reverseRow = false,
  size = 'medium',
  isVetted,
  isQualified,
  ...rest
}: IJListItem) => {
  let ListIcon: ReactNode
  let ListAvatar: ReactNode
  let ListBadge: ReactNode

  const getAvatarDimensions = () => {
    switch (size) {
      case 'large':
        return { height: 60, width: 60 }
      case 'xLarge':
        return { height: 72, width: 72 }
      case 'medium':
        return { height: 36, width: 36 }
      case 'small':
        return { height: 32, width: 32 }
      default:
        return { height: 48, width: 48 }
    }
  }

  // If a valid icon is provided, it assembles that component
  if (icon) {
    if (isValidElement(icon)) {
      ListIcon = <S.ListItemIcon>{icon}</S.ListItemIcon>
    }
  }

  // If an avatar is provided, it can be a string, array of strings or ReactNode
  if (avatar) {
    let badgeSize: 'small' | 'medium' | 'large' | undefined = 'small'
    if (size === 'xLarge') {
      badgeSize = 'large'
    } else if (size === 'small' || size === 'medium') {
      badgeSize = 'small'
    } else {
      badgeSize = 'medium'
    }
    // If it's a string, treat it like an image src
    if (typeof avatar === 'string') {
      ListAvatar = (
        <ListItemAvatar>
          <JAvatar
            alt={getAlt(avatar)}
            src={avatar}
            sx={{ avatar: { ...getAvatarDimensions() } }}
            isVetted={isVetted}
            isQualified={isQualified}
            badgeSize={badgeSize}
          />
        </ListItemAvatar>
      )
      // If it's a valid ReactNode component, pass it as is
    } else if (isValidElement(avatar)) {
      ListAvatar = (
        <ListItemAvatar>
          <JAvatar
            sx={{ avatar: { ...getAvatarDimensions() } }}
            isVetted={isVetted}
            isQualified={isQualified}
            badgeSize={badgeSize}
          >
            {avatar}
          </JAvatar>
        </ListItemAvatar>
      )
      // If it's an array, treat each as an image src and generate an AvatarGroup
    } else if (Array.isArray(avatar)) {
      ListAvatar = (
        <AvatarGroup max={3}>
          {avatar.map(avatarSrc => (
            <JAvatar
              key={avatarSrc}
              alt={getAlt(avatarSrc)}
              src={avatarSrc}
              isVetted={isVetted}
              isQualified={isQualified}
              badgeSize={badgeSize}
            />
          ))}
        </AvatarGroup>
      )
    }
  }

  // If badgeContent is provided, it assembles a Badge using also badgeVariant
  if (badgeContent) {
    ListBadge = <Badge badgeContent={badgeContent} variant={badgeVariant} />
  }

  return (
    <S.ListItem
      reverseRow={reverseRow}
      secondaryAction={secondaryIcon}
      size={size}
      {...rest}
    >
      {ListIcon}
      {ListAvatar}
      <S.ListItemText
        reverseText={reverseText}
        primary={text}
        secondary={label}
      />
      {ListBadge}
    </S.ListItem>
  )
}

export default JListItem

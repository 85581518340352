import Box, { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import AgreementLeads from '@/pages/MyReferrals/Agreements/AgreementLeads'
import { alpha } from '@mui/material'

interface IAgreementLeads extends BoxProps {
  leadInfo:
    | {
        id: string | undefined
        firstName: string
        lastName: string
        email: string
        phone: string
      }[]
    | undefined
}

const Leads = ({ leadInfo, sx }: IAgreementLeads) => {
  const hasLeads = leadInfo && leadInfo.length > 0

  if (!leadInfo) return null

  return (
    <Box
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography
        variant="caption"
        sx={{
          color: theme => alpha(theme.palette.common.black, 0.38),
          mb: 2.5,
        }}
      >
        Leads Contact Info
      </Typography>
      <Grid container rowSpacing={2}>
        {hasLeads &&
          leadInfo?.map(lead => (
            <Grid key={lead.id} item xs={12}>
              <AgreementLeads
                firstName={lead.firstName || ''}
                lastName={lead?.lastName || ''}
                email={lead?.email}
                phone={lead?.phone}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}

export default Leads

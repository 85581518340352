import request from '@/axios/config'
import axios from 'axios'
import { ClioResponse, TError, CResponse } from '../types'
import { validateToken } from '../validateToken'

export const getClioMatters = async (
  matterId: string
): Promise<ClioResponse | TError | unknown> => {
  try {
    validateToken()
    const req = await request.get<CResponse>(
      `/clio/getMatters?matterId=${matterId}`
    )

    if (req.data) {
      return req.data.data
    }
    return {}
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const err: TError = {
        message: error.response?.data,
        statusCode: error.response?.status || 500,
      }
      return err
    }
    return error
  }
}

import Dialog, { DialogProps } from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'

import Header from '@/components/Modals/ModalHeader/ModalHeader'
import Footer from '@/components/Modals/ModalFooter/ModalFooter'
import ModalContent from '@/components/Modals/ModalContent/ModalContent'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'
import { ZENDESK_HELP_URL } from '@/constants'

export interface EmailVerificationModalProps extends DialogProps {
  onResendEmail: () => Promise<boolean | undefined>
  isLoading?: boolean
}

const EmailVerificationModal = ({
  onClose,
  onResendEmail,
  isLoading,
  ...rest
}: EmailVerificationModalProps) => {
  const [resendSuccess, setResendSuccess] = useState<string>('')

  const title = 'Email Verification'
  const subtitle = `Please check your inbox for an email from Attorney Share titled
  "Email Verification Required".`
  const secondarySubtitle = `Haven't received your verification email?`

  const handleResendEmail = useCallback(async () => {
    const result = await onResendEmail()
    if (result) {
      setResendSuccess('success')
      return
    }
    setResendSuccess('error')
  }, [onResendEmail])

  return (
    <Dialog onClose={onClose} {...rest}>
      <Header title={title} onClose={onClose} showCloseIcon={false} />
      <ModalContent
        sx={theme => ({
          padding: theme.spacing(3, 3, 0, 3),
          maxWidth: '444px',
        })}
      >
        <Typography variant="subtitle1">{subtitle}</Typography>
        <Typography
          variant="subtitle2"
          sx={{
            mt: 4,
          }}
        >
          {secondarySubtitle}
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          Check your Spam or Junk folder. If you find it in Spam or Junk, please
          mark the email as &quot;Not Spam&quot; or &quot;Not Junk&quot; to
          improve future deliverability.
          <br />
          <br />
          If you haven’t received it, click the button below.
        </Typography>
      </ModalContent>
      <Footer sx={{ justifyContent: 'center', flexDirection: 'column', mx: 0 }}>
        <Button
          size="large"
          variant="contained"
          onClick={handleResendEmail}
          disabled={isLoading}
          sx={{
            mt: 2,
          }}
        >
          Resend Email
        </Button>
        {!isLoading && resendSuccess !== '' && (
          <Typography
            variant="body1"
            sx={{
              textAlign: 'left',
              color: theme =>
                resendSuccess === 'success'
                  ? theme.palette.success.main
                  : theme.palette.error.main,
            }}
          >
            {resendSuccess === 'success'
              ? 'Email sent successfully'
              : 'Error re-sending email'}
          </Typography>
        )}
        <Typography
          variant="body2"
          sx={{
            textAlign: 'left',
            alignSelf: 'flex-center',
            mt: 2,
          }}
        >
          If the problem isn&apos;t solved, contact our{' '}
          <Link
            href={ZENDESK_HELP_URL}
            target="_blank"
            rel="noreferrer"
            sx={theme => ({
              fontWeight: 700,
              textDecorationColor: theme.palette.primary.main,
            })}
          >
            Help Center
          </Link>
        </Typography>
      </Footer>
    </Dialog>
  )
}

export default EmailVerificationModal

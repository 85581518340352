import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material'
import { useSizes } from '@/hooks/useSizes'
import CommunicationPreferences from '@/components/CommunicationPreferences'
import useCommunicationPreferences from '@/components/CommunicationPreferences/hooks/useCommunicationPreferences'
import JSnackBar from '@/components/atoms/JSnackBar'
import { IAccountSettingsTabProps } from '../types'
import TitleColumn from '../Shared/TitleColumn'

const NotificationPreferences = ({
  title,
  subtitle,
}: IAccountSettingsTabProps) => {
  const theme = useTheme()
  const { screenSize } = useSizes()
  const mobileLayout = screenSize !== 'desktop'

  const {
    control,
    formState,
    handleSubmit,
    isNotificationsEnabled,
    updatePreferences,
    casesToNotifyOptions,
    notificationMethodOptions,
    loading,
    alert,
    handleClose,
  } = useCommunicationPreferences()

  return (
    <Paper
      sx={{
        overflow: 'auto',
        maxHeight: '100%',
        padding: theme.spacing(2),
        mb: mobileLayout ? theme.spacing(20) : theme.spacing(0),
      }}
    >
      <Grid container direction="row" spacing={mobileLayout ? 1 : 5}>
        <TitleColumn title={title} subtitle={subtitle} />
        <Grid item mt={2} md={8} xs={12}>
          <form onSubmit={handleSubmit(updatePreferences)}>
            <CommunicationPreferences
              control={control}
              casesToNotifyOptions={casesToNotifyOptions}
              notificationMethodOptions={notificationMethodOptions}
              isNotificationsEnabled={isNotificationsEnabled}
              loading={loading}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={loading || !formState.isDirty}
              sx={{ mb: 2 }}
            >
              Save Preferences
            </Button>
          </form>
        </Grid>
      </Grid>
      <JSnackBar
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        horizontal="center"
        vertical="bottom"
        handleClose={handleClose}
      />
    </Paper>
  )
}

export default NotificationPreferences

import { useContext } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import JTextField from '@/components/atoms/JTextField'
import JAutocomplete from '@/components/atoms/JAutocomplete'
import { CreateCaseContext } from '../../CreateCaseContext/CreateCaseContext'
import CreateCaseSectionLayout from './CreateCaseSectionLayout'

const FeesAndPricing = () => {
  const {
    methods: { control },
    financingSources,
  } = useContext(CreateCaseContext)

  return (
    <CreateCaseSectionLayout
      title="Fees and Pricing"
      subtitle="Co-Counsel Fees and/or Attorney's Fees. 33% is the default. Referee attorney is default financier."
      sx={{
        mb: 5,
        borderBottom: `1px solid`,
        borderColor: 'divider',
      }}
    >
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} md={4}>
          <JTextField
            name="fee"
            control={control}
            label="Fee"
            placeholder="Fee"
            variant="outlined"
            fitTextField
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography variant="h6">%</Typography>
                </InputAdornment>
              ),
            }}
            rules={{
              required: 'Please provide a Fee.',
              min: {
                value: 0,
                message: 'The Fee cannot be less than 0%',
              },
              max: {
                value: 100,
                message: 'The Fee cannot exceed 100%',
              },
              validate: (feeValue: string | number | boolean) => {
                if (typeof feeValue !== 'string') {
                  return 'Fee must be a number'
                }
                return true
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <JAutocomplete
            control={control}
            name="financingSource"
            options={financingSources}
            label="Financing Source"
            fullWidth
            getOptionLabel={option => option.name}
            fitTextField
            rules={{
              required: 'Please select a Financing Source.',
            }}
          />
        </Grid>
      </Grid>
    </CreateCaseSectionLayout>
  )
}

export default FeesAndPricing

import Box, { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { alpha } from '@mui/material'

interface ISidebarFeeProps extends BoxProps {
  fee: number
}

const SidebarFee = ({ fee, sx }: ISidebarFeeProps) => {
  const theme = useTheme()

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontSize: '1.5rem',
            fontStyle: 'normal',
            mr: 0.25,
          }}
        >
          {fee}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: '0.875rem',
            fontStyle: 'normal',
            lineHeight: '0.85rem',
            mr: 0.5,
          }}
        >
          %
        </Typography>
        <Typography
          variant="caption"
          sx={{
            color: alpha(theme.palette.common.black, 0.38),
            letterSpacing: '0em',
            lineHeight: '12px',
            fontSize: '10px',
            textTransform: 'uppercase',
          }}
        >
          Referring Attorney Fees
        </Typography>
      </Box>
    </Box>
  )
}

export default SidebarFee

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useResetPasswordMutation,
  useResetPasswordRequestMutation,
} from '@/gql/systemApi'
import { RoutesData } from '@/router/RoutesData'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const RECOVERY_FLOW_STATES = {
  INITIAL: 'Initial',
  EMAIL_SENT_SUCCESS: 'EmailSentSuccess',
  PASSWORD_RESET_SUCCESS: 'PasswordResetSuccess',
  CREATE_NEW_PASSWORD: 'CreateNewPassword',
  INVALID: 'Invalid',
  EXPIRED: 'Expired',
} as const
type RecoveryKeys = keyof typeof RECOVERY_FLOW_STATES
export type RecoveryFlowState =
  | (typeof RECOVERY_FLOW_STATES)[RecoveryKeys]
  | null

export type EmailFormValues = {
  email: string
}

export type PasswordFormValues = {
  password: string
  repeatPassword: string
}

export const useForgotPassword = () => {
  const navigate = useNavigate()
  const [resetPasswordRequest, { loading: requestLoading }] =
    useResetPasswordRequestMutation()
  const [passwordReset, { loading: resetLoading }] = useResetPasswordMutation()
  const [searchParams] = useSearchParams()
  // Obtain token from URL
  const token = searchParams.get('token')
  const [flow, setFlow] = useState<RecoveryFlowState>(
    RECOVERY_FLOW_STATES.INITIAL
  )
  const [validationLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    if (!token) {
      return
    }
    setFlow(RECOVERY_FLOW_STATES.CREATE_NEW_PASSWORD)
  }, [token, navigate])

  const handleEmailSubmit = useCallback(
    async ({ email }: EmailFormValues) => {
      try {
        const { data, errors } = await resetPasswordRequest({
          variables: {
            email,
            resetUrl: window.location.href,
          },
        })
        if (data?.auth_password_request) {
          setFlow(RECOVERY_FLOW_STATES.EMAIL_SENT_SUCCESS)
          return
        }
        if (errors) {
          setError(errors[0].message || 'Email validation failed')
        }
      } catch (err: any) {
        setError(err?.message || 'Email validation failed')
      }
    },
    [resetPasswordRequest]
  )

  const handlePasswordReset = useCallback(
    async ({ password }: PasswordFormValues) => {
      if (!token) {
        return
      }
      try {
        const { data, errors } = await passwordReset({
          variables: {
            token,
            password,
          },
        })
        if (data?.auth_password_reset) {
          setFlow(RECOVERY_FLOW_STATES.PASSWORD_RESET_SUCCESS)
          return
        }
        if (errors) {
          setError(errors[0].message || 'Password reset failed')
        }
      } catch (err: any) {
        setError(err?.message || 'Password reset failed')
      }
    },
    [passwordReset, token]
  )

  return {
    flow,
    token,
    isLoading: requestLoading || resetLoading,
    validationLoading,
    handlePasswordReset,
    handleEmailSubmit,
    error,
    setError,
    goToSignIn: () => navigate(RoutesData.RSignIn.path),
  }
}

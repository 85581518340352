import legacyUseNegotiations from '@/pages/MyReferralsV2/NegotiationItem/hooks/useNegotiations'
import { useMatchFeesModal } from '@/components/Modals/MatchFeesModal'
import { ModalTypes } from '@/types'
import { PricesType } from '@/hooks/useStripe'

export const useProposalSubmit = ({
  setMatchFeesModalOpen,
  setIsSubmittingMatchFee,
  negotiationType,
  onSubmitAction,
  setResponse,
  modalType,
  offeredFee,
  stripePriceId,
  credits,
  discount,
}: {
  setMatchFeesModalOpen: ReturnType<typeof useMatchFeesModal>['setOpenModal']
  setIsSubmittingMatchFee: React.Dispatch<React.SetStateAction<boolean>>
  negotiationType: string
  onSubmitAction: ReturnType<typeof legacyUseNegotiations>['onSubmitAction']
  setResponse: ReturnType<typeof legacyUseNegotiations>['setResponse']
  modalType: ModalTypes
  offeredFee: number | undefined
  stripePriceId: string | undefined
  credits: string | number | undefined
  discount: string | undefined
}) => {
  const handleSubmit = async (form: { fee?: string | number | null }) => {
    setMatchFeesModalOpen(null)
    setIsSubmittingMatchFee(true)
    const priceType: PricesType =
      negotiationType === 'Proposal' ? 'owner' : 'prospect'

    const result = await onSubmitAction({
      type: modalType,
      fee:
        form?.fee !== undefined && form?.fee !== null
          ? Number(form.fee)
          : offeredFee ?? 0,
      priceType,
      stripePriceId,
      credits,
      discount,
    })

    if (result === null) {
      setResponse({ type: 'Error', open: true })
      setIsSubmittingMatchFee(false)
      return
    }

    if (modalType === 'Accept') {
      setIsSubmittingMatchFee(false)
      if (typeof result === 'object') {
        setResponse({
          type: result.type as ModalTypes,
          open: result.open,
        })
      }
    }

    if (modalType !== 'Accept') {
      setIsSubmittingMatchFee(false)
      setResponse({
        type: modalType,
        open: true,
      })
    }
  }

  return { handleSubmit }
}

import { useEffect, useState, useRef } from 'react'

import { USER_ROLES, MEMBERSHIP_PAID_STATUS_ID } from '@/constants'
import { useUsersMeQuery } from '@/gql/systemApi'
import { useGetAttorneyPaymentMembershipQuery } from '@/gql/appApi'
import useAttorneyId from '@/hooks/useAttorneyId'

const useMembership = () => {
  const [isMembershipEnabled, setIsMembershipEnabled] = useState(false)
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = useRef(getAttorneyId())
  const { data: userMe } = useUsersMeQuery()
  const ROLES_TO_CHECK = [
    USER_ROLES.SAdminAttorneyUser,
    USER_ROLES.SuperAdminUser,
  ]

  const isNeededToCheckMembership = ROLES_TO_CHECK.includes(
    userMe?.users_me?.role?.id || ''
  )

  const { data: membershipPrefData, loading: membershipPrefLoading } =
    useGetAttorneyPaymentMembershipQuery({
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          attorney_id: {
            id: {
              _eq: attorneyId.current,
            },
          },
          _and: [
            {
              payment_status_id: {
                id: {
                  _eq: MEMBERSHIP_PAID_STATUS_ID,
                },
              },
            },
          ],
        },
      },
    })

  useEffect(() => {
    if (membershipPrefData) {
      const isMembershipPaid =
        Array.isArray(membershipPrefData.attorney_payment_membership) &&
        membershipPrefData.attorney_payment_membership.length > 0
      setIsMembershipEnabled(isMembershipPaid)
    }
  }, [membershipPrefData])

  return {
    isNeededToCheckMembership,
    isMembershipEnabled,
    isMembershipPrefLoading: membershipPrefLoading,
  }
}

export default useMembership

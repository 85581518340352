import { ReactNode } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Box, { BoxProps } from '@mui/material/Box'
import { SxProps } from '@mui/material'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

interface SortableItemProps {
  id: string
  children: ReactNode
  containerSxProps?: SxProps<BoxProps>
  dragIndicatorContainerSxProps?: SxProps<BoxProps>
}

const SortableItem = ({
  id,
  children,
  containerSxProps,
  dragIndicatorContainerSxProps,
}: SortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Box
      ref={setNodeRef}
      style={style}
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        ...(Array.isArray(containerSxProps)
          ? containerSxProps
          : [containerSxProps]),
      ]}
    >
      <Box
        {...attributes}
        {...listeners}
        sx={[
          {
            cursor: 'grab',
            marginRight: 1,
            display: 'flex',
            alignItems: 'center',
            marginBottom: { xs: 0, md: 5 },
          },
          ...(Array.isArray(dragIndicatorContainerSxProps)
            ? dragIndicatorContainerSxProps
            : [dragIndicatorContainerSxProps]),
        ]}
      >
        <DragIndicatorIcon />
      </Box>
      {children}
    </Box>
  )
}

export default SortableItem

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import useAttorneyData from '@/hooks/useIsAttorneyVetted'
import { useCallback, useState } from 'react'

import { ResponseModal, SendProposalModal } from '@/components'
import { JCaseType, ModalTypes } from '@/types'

import { CaseEntry } from '@/pages/MyReferralsV2/types'
import { ContainerResponsive } from '@/pages/MyReferrals/components'
import JUser from '@/components/atoms/JUser'
import NegotationFee from '@/pages/MyReferralsV2/NegotiationItem/components/NegotationFee'
import { useSizes } from '@/hooks/useSizes'
import { generatePath, useNavigate } from 'react-router-dom'
import { RoutesData } from '@/router/RoutesData'
import { getImagePath } from '@/utils'
import NonVettedOptionsModal from '@/components/Modals/NonVettedOptionsModal'
import { isQualifiedMembership } from '@/utils/membership'
import { IMAGE_SIZES } from '@/constants'
import CaseMessageButton from '@/components/GetStreamChat/CaseMessageButton'

interface ICaseWatchingProps {
  caseEntry: CaseEntry
  refetch?: () => Promise<void>
}

const CaseWatching = ({ caseEntry, refetch }: ICaseWatchingProps) => {
  const navigate = useNavigate()
  const { getIsAttorneyVetted, getIsQualifiedAttorney } = useAttorneyData()
  const currentUserIsVetted = getIsAttorneyVetted()
  const currentUserIsQualified = getIsQualifiedAttorney()
  const [nonVettedModal, setShowNonVettedModal] = useState(false)
  const { isGreaterMd } = useSizes()
  const [modalOpen, setOpenModal] = useState<{
    open: boolean
    jCase: JCaseType | null
    fee: number | null
  }>({ open: false, jCase: null, fee: null })
  const [response, setResponse] = useState<{ type: ModalTypes; open: boolean }>(
    {
      type: null,
      open: false,
    }
  )

  const onSubmit = () => {
    setOpenModal({ open: false, jCase: null, fee: null })
    setResponse({
      type: 'Send',
      open: true,
    })
  }

  const onClickProfile = useCallback(() => {
    const path = generatePath(RoutesData.RProfile.path, {
      id: caseEntry?.attorney?.attorney_id,
    })
    navigate(path)
  }, [caseEntry, navigate])

  const renderCard = useCallback(() => {
    if (caseEntry) {
      const fullname = `${caseEntry?.attorney?.first_name} ${caseEntry?.attorney?.last_name}`
      const firms = caseEntry?.attorney?.organization_name
        ? [caseEntry?.attorney?.organization_name]
        : []
      const avatar =
        getImagePath({
          id: caseEntry?.attorney?.avatar ?? null,
          size: IMAGE_SIZES.Small,
        }) || null
      const isVetted = caseEntry?.attorney?.is_vetted
      const isQualified = isQualifiedMembership(
        caseEntry?.attorney?.membership_type
      )
      const fee = caseEntry?.fee

      return (
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={3.5} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <JUser
                  mainText={fullname}
                  secondaryText={firms}
                  avatar={avatar}
                  onClick={onClickProfile}
                  isVetted={isVetted}
                  isQualified={isQualified}
                />
              </Box>
            </Grid>
            <Grid
              item
              md={3.5}
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <NegotationFee
                fee={fee}
                feeText="Referral/Co-Counsel Fee Requested"
              />
            </Grid>
            <Grid
              item
              md={5}
              xs={12}
              sx={{
                ...(isGreaterMd && {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }),
              }}
            >
              <ContainerResponsive sx={{ gap: 2 }}>
                <Button
                  fullWidth={!isGreaterMd}
                  size="large"
                  variant="contained"
                  onClick={() => {
                    if (!(currentUserIsVetted || currentUserIsQualified)) {
                      setShowNonVettedModal(true)
                      return
                    }
                    setOpenModal({
                      open: true,
                      jCase: caseEntry,
                      fee,
                    })
                  }}
                >
                  Send Proposal
                </Button>
                <CaseMessageButton
                  variant="outlined"
                  fullWidth={!isGreaterMd}
                  size="large"
                />
              </ContainerResponsive>
            </Grid>
          </Grid>
        </CardContent>
      )
    }
    return null
  }, [
    caseEntry,
    isGreaterMd,
    onClickProfile,
    currentUserIsVetted,
    currentUserIsQualified,
  ])

  return (
    <Box>
      {renderCard()}
      {modalOpen.open &&
        modalOpen.jCase &&
        modalOpen.fee !== undefined &&
        modalOpen.fee !== null && (
          <SendProposalModal
            open={modalOpen.open}
            onClose={() =>
              setOpenModal({ open: false, jCase: null, fee: null })
            }
            onSubmit={onSubmit}
            jCase={modalOpen.jCase}
            fee={modalOpen.fee}
          />
        )}
      {nonVettedModal && (
        <NonVettedOptionsModal
          open={nonVettedModal}
          onClose={() => setShowNonVettedModal(false)}
        />
      )}
      {response.open && (
        <ResponseModal
          open={response.open}
          type={response.type}
          onClose={async () => {
            if (refetch) await refetch()
            setResponse({ open: false, type: null })
          }}
        />
      )}
    </Box>
  )
}

export default CaseWatching

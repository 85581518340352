import Typography from '@mui/material/Typography'
import parse from 'html-react-parser'
import { TCase } from '@/pages/Case/types'
import useTheme from '@mui/material/styles/useTheme'

export const CaseDescription = ({ caseData }: { caseData: TCase }) => {
  const theme = useTheme()

  return (
    <Typography
      sx={{
        whiteSpace: 'break-spaces',
        '& p': {
          minHeight: theme.spacing(2),
        },
        '& ol': {
          mx: 3,
          listStyle: 'revert',
        },
        '& ul': {
          mx: 3,
          listStyle: 'revert',
        },
      }}
      variant="body1"
      component="div"
    >
      {caseData?.description_formatted
        ? parse(caseData.description_formatted)
        : caseData.description}
    </Typography>
  )
}

import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { RoutesData } from '@/router/RoutesData'
import { MODAL_TYPES } from '@/types'
import { TabIndex } from '@/pages/MyReferralsV3/TabsContainer/TabsContainer'
import { useSessionStorage } from 'usehooks-ts'
import { myReferralsSelectedTabSessionKey } from '@/pages/MyReferralsV3/TabsContainer/constants'
import { ICreateCaseContext } from './types'
import useCreateNewCase from '../CaseForm/hooks/useCreateNewCase'
import {
  createNewCaseDefaultValues,
  fieldErrorLabels,
  ICaseForm,
  ICreateNewCaseProps,
  MarketplaceVisibilityCard,
} from '../CaseForm/types'
import useClioCreateCase from '../CaseForm/hooks/useClioCreateCase'
import { initialContextValue } from './initialContextValue'

export const CreateCaseContext =
  createContext<ICreateCaseContext>(initialContextValue)

interface ICreateCaseProviderProps extends ICreateNewCaseProps {
  children: ReactNode
}

export const CreateCaseProvider = ({
  onSubmit,
  isEdit = false,
  defaultValues = createNewCaseDefaultValues,
  submitButtonText = isEdit ? 'Save Changes' : 'Create Case',
  title = isEdit ? 'Edit Case' : 'Create New Case',
  modalType = MODAL_TYPES.CASE_CREATED,
  onDataroomFilesChange,
  isExternalLoading = false,
  caseId,
  children,
}: ICreateCaseProviderProps) => {
  const navigate = useNavigate()

  const {
    financingSources,
    caseTypes,
    processStages,
    usStates,
    languages,
    error,
    loading,
  } = useCreateNewCase()

  const methods = useForm<ICaseForm>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const { clioAlert, closeClioAlert } = useClioCreateCase({
    reset: methods.reset,
  })

  const [caseError, setCaseError] = useState<string | null>(null)
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)
  const [marketplaceVisibilityError, setMarketplaceVisibilityError] =
    useState<string>('')
  const [selectedCard, setSelectedCard] =
    useState<MarketplaceVisibilityCard>('public')
  const errorRef = useRef<HTMLDivElement>(null)
  const caseErrorRef = useRef<HTMLDivElement>(null)
  const mktpVisibilityErrorRef = useRef<HTMLDivElement>(null)
  const formErrorsRef = useRef<HTMLDivElement>(null)
  const formValidationErrors = methods.formState.errors
  const [createdCaseId, setCreatedCaseId] = useState<string | undefined>()
  const [isCaseSubmitted, setIsCaseSubmitted] = useState(false)
  const [dataroomErrors, setDataroomErrors] = useState<string[]>([])

  const [, setMyreferralsTabValue] = useSessionStorage(
    myReferralsSelectedTabSessionKey,
    0
  )

  const onCloseCaseSubmittedHandler = useCallback(
    (isEditForm: boolean) => {
      if (isEditForm) {
        navigate(`/case/${caseId}`)
      } else {
        setMyreferralsTabValue(TabIndex.OPEN_REFERRALS)
        navigate(
          {
            pathname: RoutesData.RMyReferrals.path,
          },
          {
            state: { refetchRequired: true },
          }
        )
      }
    },
    [caseId, navigate, setMyreferralsTabValue]
  )

  // Check if there are any errors in the form, and if so, scroll to the first one
  useEffect(() => {
    // Check if any of the errors are present and get the respective ref
    let errorToScrollTo = null
    if (error?.message) {
      errorToScrollTo = errorRef.current
    } else if (caseError) {
      errorToScrollTo = caseErrorRef.current
    } else if (marketplaceVisibilityError) {
      errorToScrollTo = mktpVisibilityErrorRef.current
    }
    // If an error ref is found, scroll it into view
    if (errorToScrollTo) {
      errorToScrollTo.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }
  }, [error, caseError, marketplaceVisibilityError])

  // If valid - submit the form
  // If invalid - scroll to the first error
  const handleSubmitCase = useCallback(async () => {
    setIsSubmitLoading(true)
    const isValid = await methods.trigger()

    if (isValid) {
      setCaseError('')
      setMarketplaceVisibilityError('')

      // Additional pre-submission logic
      const isPriority = selectedCard === 'priority'
      const formData = {
        ...methods.getValues(),
        isPriority,
      }

      // TODO: Remove !isEdit when adding in the ability to edit marketplace visibility + assignees
      if (
        isPriority &&
        !formData?.assigneesId?.find(id => id !== '') &&
        !isEdit
      ) {
        setMarketplaceVisibilityError(
          'Please assign the case to a saved waterfall list or selected attorneys. Or change the visibility to public.'
        )
        mktpVisibilityErrorRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        })
        setIsSubmitLoading(false)
        return
      }

      try {
        // If pre-submission logic passes, submit the form
        const result = await onSubmit(formData)

        // Post-submission result handling
        if (result.id) {
          const apiDataroomErrors = result?.dataroomErrors || []
          setDataroomErrors(apiDataroomErrors)
          setIsCaseSubmitted(true)
          setCreatedCaseId(result.id)
        } else if (result.createCaseErrors) {
          setCaseError(result.createCaseErrors.base)
        }
      } catch (tryError) {
        // Handle errors that occur during the submission
        // eslint-disable-next-line no-console
        console.error('Error submitting case:', tryError)
        setCaseError('An unexpected error occurred while submitting the case.')
      }
    } else if (formErrorsRef.current) {
      formErrorsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }
    setIsSubmitLoading(false)
  }, [methods, onSubmit, selectedCard, isEdit])

  // When defaultValues change, update the selectedCard
  useEffect(() => {
    if (!defaultValues) return
    if (defaultValues.isPriority) {
      setSelectedCard('priority')
    } else {
      setSelectedCard('public')
    }
  }, [defaultValues])

  useEffect(() => {
    methods.reset(defaultValues)
  }, [defaultValues, methods])

  const handleSelectVisibility = useCallback(
    (id: MarketplaceVisibilityCard) => {
      setSelectedCard(id)
    },
    []
  )

  const errorMessages = Object.keys(formValidationErrors).map(
    fieldName => fieldErrorLabels[fieldName] || fieldName
  )

  const contextValue = useMemo(() => {
    const createCaseContextValue: ICreateCaseContext = {
      caseError,
      caseErrorRef,
      caseTypes,
      clioAlert,
      closeClioAlert,
      createdCaseId,
      dataroomErrors,
      error,
      errorMessages,
      errorRef,
      financingSources,
      formErrorsRef,
      handleSelectVisibility,
      handleSubmitCase,
      isCaseSubmitted,
      isEdit,
      isExternalLoading,
      isSubmitLoading,
      languages,
      loading,
      marketplaceVisibilityError,
      methods,
      mktpVisibilityErrorRef,
      modalType,
      onDataroomFilesChange,
      onCloseCaseSubmittedHandler,
      processStages,
      selectedCard,
      submitButtonText,
      title,
      usStates,
    }

    return createCaseContextValue
  }, [
    caseError,
    caseErrorRef,
    caseTypes,
    clioAlert,
    closeClioAlert,
    createdCaseId,
    dataroomErrors,
    error,
    errorMessages,
    errorRef,
    financingSources,
    formErrorsRef,
    handleSelectVisibility,
    handleSubmitCase,
    isCaseSubmitted,
    isEdit,
    isExternalLoading,
    isSubmitLoading,
    languages,
    loading,
    marketplaceVisibilityError,
    methods,
    mktpVisibilityErrorRef,
    modalType,
    onDataroomFilesChange,
    onCloseCaseSubmittedHandler,
    processStages,
    selectedCard,
    submitButtonText,
    title,
    usStates,
  ])

  return (
    <CreateCaseContext.Provider value={contextValue}>
      {children}
    </CreateCaseContext.Provider>
  )
}

import { useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import EmptyPage from '@/components/EmptyPage/EmptyPage'
import ReferralItems from '../../components/ReferralItems'

import { CaseEntry } from '../../types'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  )
}
const TabHeader = ({ title, items }: { title: string; items: number }) => (
  <div>
    {title}
    <Typography
      variant="caption"
      color="text.disabled"
      sx={{ ml: 1, textTransform: 'lowercase' }}
    >
      &nbsp;{`${items} ${items === 1 ? 'item' : 'items'}`}
    </Typography>
  </div>
)

export type TabDataType = {
  id: number
  title: string
  items: CaseEntry[]
}

interface TabsContainerProps {
  tabsData: TabDataType[]
  refetch?: () => Promise<void>
  isRefetching?: boolean
}

const TabsContainer = ({
  tabsData,
  refetch,
  isRefetching,
}: TabsContainerProps) => {
  const [tabValue, setTabValue] = useState(0)

  const handleChangeTab = (_e: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleChangeTab}
        aria-label="All Referrals tabs"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {tabsData.map(tabInfo => {
          const { title, items, id } = tabInfo
          return (
            <Tab
              key={`AllReferrals-tab-${id}`}
              label={<TabHeader title={title} items={items.length} />}
              id={`simple-tab-${id}`}
              aria-controls={`simple-tabpanel-${id}`}
            />
          )
        })}
      </Tabs>
      {tabsData.map(tabInfo => {
        const { id, items, title } = tabInfo
        return (
          <CustomTabPanel
            key={`AllReferrals-tab-data-${id}`}
            value={tabValue}
            index={id}
          >
            {items.length > 0 ? (
              <ReferralItems
                data={items}
                refetch={refetch}
                isRefetching={isRefetching}
              />
            ) : (
              <EmptyPage
                message={`You don't have any ${
                  id !== 0 ? String(title).toLowerCase() : ''
                } cases`}
              />
            )}
          </CustomTabPanel>
        )
      })}
    </>
  )
}

export default TabsContainer

import React, { Suspense, ReactNode } from 'react'
import { Maintenance } from '@/pages'
import { Box, CircularProgress } from '@mui/material'
import { useMaintenanceMode } from '@/hooks/useMaintenanceMode'

interface BootstrapProps {
  children: ReactNode
}

const LoadingSpinner = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw',
    }}
  >
    <CircularProgress />
  </Box>
)

const Bootstrap: React.FC<BootstrapProps> = ({ children }) => {
  const { isMaintenanceMode, isLoading } = useMaintenanceMode()

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isMaintenanceMode) {
    return <Maintenance />
  }

  return <Suspense fallback={<LoadingSpinner />}>{children}</Suspense>
}

export default Bootstrap

import { useContext, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { Checkbox } from '@/components'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Attorney_Profile } from '@/gql/appApi'
import useInviteMembers from './hooks/useInviteMembers'
import SearchAttorneys from './SearchAttorneys/SearchAttorneys'
import SearchCaseManagers from './SearchCaseManagers/SearchCaseManagers'
import { CreateCaseContext } from '../../CreateCaseContext/CreateCaseContext'

const InviteMembers = () => {
  const {
    methods: { control, setValue },
  } = useContext(CreateCaseContext)
  const {
    attorneySelected,
    setAttorneySelected,
    caseManagersSelected,
    setCaseManagersSelected,
    isCaseManager,
    isAttorney,
    isAdmin,
    loading,
  } = useInviteMembers()

  const [createOnBehalfOf, setCreateOnBehalfOf] = useState(isCaseManager)
  const [inviteMembers, setInviteMembers] = useState(false)

  const inviteMembersLabel = (
    <Typography variant="body2" component="span">
      I want to allow one or more Case Managers to have permissions to act on my
      behalf for this case.
    </Typography>
  )

  const createOnBehalfOfLabel = (
    <Typography variant="body2" component="span">
      I want to create the case on behalf of another attorney at my firm
    </Typography>
  )

  const label = isCaseManager ? 'Create on behalf of *' : 'Create on behalf of'

  const caseManagersLabel =
    'Case Managers allowed to act on behalf of the attorney'

  useEffect(() => {
    if (caseManagersSelected.length === 0) {
      setValue('caseManagers', [] as string[])
      return
    }
    const caseManagerIds = caseManagersSelected.map(
      att => att?.attorney_id?.user_id?.id
    )
    const newCaseManagers: string[] = [...caseManagerIds].filter(
      (id): id is string => typeof id === 'string'
    )
    setValue('caseManagers', newCaseManagers)
  }, [caseManagersSelected, setValue])

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={24} />
      </Box>
    )
  }

  const showCreateOnBehalfOf = createOnBehalfOf || isCaseManager

  return (
    <Box>
      {((isAttorney && isAdmin) || isCaseManager) && (
        <>
          {!isCaseManager && (
            <Checkbox
              checked={createOnBehalfOf}
              sx={{ pr: 1, mb: 1 }}
              checkboxProps={{
                label: createOnBehalfOfLabel,
                isCentered: true,
              }}
              onChange={() => setCreateOnBehalfOf(!createOnBehalfOf)}
              muiCheckboxProps={{
                checkedIcon: <CheckBoxIcon color="primary" />,
              }}
            />
          )}
          <Collapse
            in={showCreateOnBehalfOf}
            sx={{
              mt: isCaseManager ? 3 : 0,
              mb: showCreateOnBehalfOf ? 1 : 0,
            }}
          >
            {showCreateOnBehalfOf && (
              <Controller
                control={control}
                name="onBehalfOf"
                rules={{
                  required: isCaseManager
                    ? 'You must select an attorney to create a case'
                    : undefined,
                }}
                render={({ field: { onChange, value }, fieldState }) => (
                  <SearchAttorneys
                    attorneySelected={attorneySelected}
                    id="attorneySelected"
                    label={label}
                    placeholder={label}
                    onChange={(att: Attorney_Profile | null) => {
                      setAttorneySelected(att)
                      onChange(att)
                    }}
                    value={value}
                    fieldState={fieldState}
                  />
                )}
              />
            )}
          </Collapse>
        </>
      )}
      <Checkbox
        checked={inviteMembers}
        sx={{ pr: 1, my: 1 }}
        checkboxProps={{
          label: inviteMembersLabel,
          isCentered: true,
        }}
        onChange={() =>
          setInviteMembers(prev => {
            setCaseManagersSelected([])
            return !prev
          })
        }
        muiCheckboxProps={{
          checkedIcon: <CheckBoxIcon color="primary" />,
        }}
      />
      <Collapse in={inviteMembers}>
        {inviteMembers && (
          <SearchCaseManagers
            id="caseManagersSelected"
            attorneysSelected={caseManagersSelected}
            label={caseManagersLabel}
            placeholder={caseManagersLabel}
            onChange={(att: Attorney_Profile[] | null) => {
              setCaseManagersSelected(att || [])
            }}
            value={caseManagersSelected}
          />
        )}
      </Collapse>
    </Box>
  )
}

export default InviteMembers

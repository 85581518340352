import { useContext } from 'react'
import { CreateCaseContext } from '../../CreateCaseContext/CreateCaseContext'
import CreateCaseSectionLayout from './CreateCaseSectionLayout'
import InviteMembers from '../InviteMembers/InviteMembers'

const CaseOwnership = () => {
  const { isEdit } = useContext(CreateCaseContext)

  if (isEdit) {
    return null
  }

  return (
    <CreateCaseSectionLayout
      title="Case Ownership"
      subtitle="Select the people who will have permission to act on this case."
      sx={{
        mb: 5,
        borderBottom: `1px solid`,
        borderColor: 'divider',
      }}
    >
      <InviteMembers />
    </CreateCaseSectionLayout>
  )
}

export default CaseOwnership

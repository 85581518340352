import { useState, useEffect } from 'react'

import {
  useGetFavoritesByUserIdQuery,
  useRemoveFavoriteAttorneyMutation,
  useAddFavoriteAttorneyMutation,
} from '@/gql/appApi'

export interface IUsePublicProfileProps {
  attorneyId: string
}

const usePublicProfile = ({ attorneyId }: IUsePublicProfileProps) => {
  const {
    data,
    loading: getLoading,
    error: getError,
  } = useGetFavoritesByUserIdQuery({
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        favorite_attorney_id: {
          profiles: {
            attorney_id: {
              id: {
                _eq: attorneyId,
              },
            },
          },
        },
      },
    },
  })

  const [favId, setFavId] = useState<string>('')

  const isFavorite = !!favId

  useEffect(() => {
    const [attorneyFavorite] = data?.attorney_favorite ?? []

    if (attorneyFavorite) {
      setFavId(attorneyFavorite.id)
    }
  }, [data])

  const [
    removeFavoriteAttorneyMutation,
    { loading: removeLoading, error: removeError },
  ] = useRemoveFavoriteAttorneyMutation()

  const [
    addFavoriteAttorneyMutation,
    { loading: addLoading, error: addError },
  ] = useAddFavoriteAttorneyMutation()

  const removeFavorite = async () => {
    await removeFavoriteAttorneyMutation({
      variables: {
        deleteAttorneyFavoriteItemId: favId,
      },
    })
    setFavId('')
  }

  const createFavorite = async () => {
    const dataFavId = await addFavoriteAttorneyMutation({
      variables: {
        data: {
          favorite_attorney_id: attorneyId,
        },
      },
    })
    const newFavId = dataFavId.data?.create_attorney_favorite_item?.id

    if (typeof newFavId === 'string') {
      setFavId(newFavId)
    }
  }

  const onFavorite = async () => {
    if (isFavorite) {
      await removeFavorite()
    } else {
      await createFavorite()
    }
  }

  return {
    isFavorite,
    loading: getLoading || removeLoading || addLoading,
    error: getError || removeError || addError,
    onFavorite,
  }
}

export default usePublicProfile

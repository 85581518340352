import { useEffect, useState } from 'react'
import { useSnackBar } from '@/hooks/useSnackBar'
import useCreateNewCase from '@/components/Case/CaseForm/hooks/useCreateNewCase'
import { NEW_DATAROOM_ENABLED } from '@/constants'
import {
  ICaseForm,
  createNewCaseDefaultValues,
} from '@/components/Case/CaseForm/types'
import dayjs from 'dayjs'
import JSnackBar from '@/components/atoms/JSnackBar'
import { createIntegrationDataValues } from './integrations/types'
import useGetIntegrationData from './integrations/useGetIntegrationData'
import CreateNewCase from '..'

const CreateCaseForm = () => {
  const { createCase } = useCreateNewCase()
  const { alert, showAlert, handleClose } = useSnackBar()
  const [newDataRoom, setNewDataRoom] = useState<File[]>([])
  const [isExternalLoading, setIsExternalLoading] = useState(false)
  const [integrationDataValues, setIntegrationDataValues] = useState<ICaseForm>(
    createNewCaseDefaultValues
  )

  const handleDataroomFilesChange = (myFiles: File[], replace?: boolean) => {
    setNewDataRoom(replace ? myFiles : [...newDataRoom, ...myFiles])
  }

  const { lcmsData, lcmsError } = useGetIntegrationData()

  useEffect(() => {
    if (lcmsData && !('message' in lcmsData)) {
      const defaultValues: ICaseForm = createIntegrationDataValues(lcmsData)
      setIntegrationDataValues(defaultValues)
    }
  }, [lcmsData])

  useEffect(() => {
    if (lcmsError) {
      showAlert({
        severity: 'error',
        message: 'Something went wrong, please try again in a few minutes',
      })
    }
  }, [lcmsError, showAlert])

  const onSubmit = async (formData: ICaseForm) => {
    setIsExternalLoading(true)
    const result = await createCase({
      language: formData.language,
      leadInfo: formData.leadInfo,
      dataRoomFile: NEW_DATAROOM_ENABLED
        ? newDataRoom
        : formData.dataRoomFile || null,
      alias: formData.caseAlias,
      caseNumber: formData.caseNumber,
      description: formData.caseDescription,
      descriptionFormatted: formData.caseDescriptionFormatted,
      fee: parseInt(formData.fee, 10),
      financingSource: formData.financingSource,
      incidentDate: formData.incidentDate
        ? dayjs(formData.incidentDate).format('YYYY-MM-DD')
        : '',
      internalCaseNumber: formData.internalCaseNumber,
      jurisdiction: formData.jurisdiction,
      name: formData.caseName,
      processStage: formData.legalStage,
      proposalDueDate: formData.proposalsDueDate
        ? dayjs(formData.proposalsDueDate).format('YYYY-MM-DD')
        : '',
      type: formData.caseType,
      isPrivate: formData?.isPriority ?? false,
      ...(formData.isPriority && {
        assigneeData: (formData?.assigneesId ?? [])
          .filter(assigneeId => assigneeId !== '')
          .map((assigneeId, idx) => ({
            assigneeId,
            position: idx + 1,
            timeUnitId: formData?.timeUnitId ?? '0',
            timeLimit: formData?.timeLimit ?? 0,
          })),
      }),
      ...(formData.onBehalfOf && {
        onBehalfOfId: formData.onBehalfOf?.attorney_id?.id ?? '',
      }),
      ...(formData?.caseManagers?.length && {
        caseManagerIds: formData.caseManagers,
      }),
    })
    setIsExternalLoading(false)
    return result
  }

  return (
    <>
      <CreateNewCase
        onSubmit={onSubmit}
        defaultValues={integrationDataValues}
        submitButtonText="Create Case"
        title="Create New Case"
        onDataroomFilesChange={handleDataroomFilesChange}
        isExternalLoading={isExternalLoading}
      />
      <JSnackBar
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        horizontal="center"
        vertical="bottom"
        handleClose={handleClose}
      />
    </>
  )
}

export default CreateCaseForm

import { useContext } from 'react'
import { ConfirmationModal } from '@/components'
import { OrganizationWaterfallsContext } from '@/components/OrganizationWaterfalls/OrganizationWaterfallsContext'
import Typography from '@mui/material/Typography'

const DeleteWaterfallModal = () => {
  const {
    deleteWaterfall,
    deleteWaterfallModalOpen: { open, waterfallTitle },
    setDeleteWaterfallModalOpen,
  } = useContext(OrganizationWaterfallsContext)

  const onClose = () =>
    setDeleteWaterfallModalOpen({
      open: false,
      waterfallId: '',
      waterfallTitle: '',
    })

  return (
    <ConfirmationModal
      title="Remove Waterfall"
      open={open}
      onClose={onClose}
      AcceptButtonProps={{
        children: 'Yes, remove it',
        onClick: deleteWaterfall,
      }}
    >
      <Typography display="inline" variant="body1">
        Are you sure you want to remove{' '}
        <Typography display="inline" variant="subtitle1" color="text.primary">
          {waterfallTitle}
        </Typography>{' '}
        waterfall?
      </Typography>
    </ConfirmationModal>
  )
}

export default DeleteWaterfallModal

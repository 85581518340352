import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { useContext } from 'react'
import Countdown from 'react-countdown'
import { ReferralContext } from './ReferralContext'

interface WaterfallCountdownProps {
  waterfallProposalDueDate: Date
}

export const testId = 'waterfall-countdown'

export const WaterfallCountdown = ({
  waterfallProposalDueDate,
}: WaterfallCountdownProps) => {
  const theme = useTheme()

  const { isUserSeller } = useContext(ReferralContext)

  if (isUserSeller) {
    return (
      <Typography
        data-testid={testId}
        variant="caption"
        color={theme.palette.text.disabled}
      >
        Next in Waterfall in{' '}
        <strong>
          <Countdown date={waterfallProposalDueDate} />
        </strong>
      </Typography>
    )
  }

  return (
    <Typography
      data-testid={testId}
      color={theme.palette.warning.dark}
      variant="captionBold"
      fontFamily={theme.typography.body1.fontFamily}
      sx={{
        textWrap: 'nowrap',
      }}
    >
      Priority Access expires in{' '}
      <strong>
        <Countdown date={waterfallProposalDueDate} />
      </strong>
    </Typography>
  )
}

import { ReactNode, useContext } from 'react'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import ChevronRight from '@mui/icons-material/ChevronRight'
import ExpandLess from '@mui/icons-material/ExpandLess'
import { Link, generatePath } from 'react-router-dom'
import { useSizes } from '@/hooks/useSizes'
import { RoutesData } from '@/router/RoutesData'
import { useSessionStorage } from 'usehooks-ts'
import { hasAnchorBetweenElements } from '@/lib/dom'
import { ReferralContext } from './ReferralContext'
import { CaseManagerPermissionRequest } from './CaseManagerPermissionRequest'
import { Negotiations } from './Negotiations'
import { DataRoom } from './DataRoom'
import { ContactInformation } from './ContactInformation'
import { ExpirationMessage } from './ExpirationMessage'
import { PromoteCase } from './PromoteCase'

interface ReferralWithNegotiationsProps {
  headline: ReactNode
  header: ReactNode
  showReferralContent?: boolean
  showPromoteCase?: boolean
  showCaseManagerPermissionRequest?: boolean
  showExpirationMessage?: boolean
  showNegotiations?: boolean
  showDataRoom?: boolean
  showContactInformation?: boolean
  showCounterpartContactInformation?: boolean
  showContactInformationInAccordion?: boolean
}

export const ReferralWithDrawer = ({
  headline,
  header,
  showReferralContent,
  showPromoteCase,
  showCaseManagerPermissionRequest,
  showExpirationMessage,
  showNegotiations,
  showDataRoom,
  showContactInformation,
  showCounterpartContactInformation,
  showContactInformationInAccordion,
}: ReferralWithNegotiationsProps) => {
  const { caseEntry, postedAtDate, postedAtDateString } =
    useContext(ReferralContext)
  const theme = useTheme()
  const { isLessMd } = useSizes()
  const [isExpanded, setIsExpanded] = useSessionStorage(
    `my-referrals_${caseEntry.id}_expanded`,
    caseEntry.needs_your_attention
  )

  const handleExpandToggleClick: React.MouseEventHandler<Element> = evt => {
    const { target, currentTarget } = evt

    if (!(target instanceof Element && currentTarget instanceof Element)) {
      return
    }

    // NOTE: When the user clicks a link, it's essential to avoid updating the
    // `isExpanded` state. This is because the new state will be preserved
    // before the user navigates away, potentially causing a confusing user
    // experience.
    if (!hasAnchorBetweenElements(currentTarget, target)) {
      setIsExpanded(!isExpanded)
    }
  }

  const referralContentBackgroundColor = '#FAFAFA'

  const referralHeader = (
    <Grid container p={1.5} pr={{ md: 0 }}>
      <Grid item>{headline}</Grid>
      {header}
    </Grid>
  )

  const referralContent = showReferralContent ? (
    <>
      <Divider
        sx={{
          width: {
            xs: `calc(100% + ${theme.spacing(2)})`,
          },
          position: 'relative',
          left: theme.spacing(-2),
        }}
      />
      <Box
        pt={{ xs: 2 }}
        px={{ xs: 2 }}
        sx={{
          backgroundColor: referralContentBackgroundColor,
        }}
      >
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography
              variant="caption"
              color={theme.palette.text.disabled}
              fontSize={theme.spacing(1.5)}
            >
              Posted {postedAtDateString}, {postedAtDate.getFullYear()}
            </Typography>
            <Typography
              variant="button"
              component={Link}
              to={generatePath(RoutesData.RCase.path, {
                id: caseEntry.id,
              })}
              textTransform="none"
              color={theme.palette.primary.main}
              display="flex"
              alignItems="center"
              sx={{
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Go to Case Profile{' '}
              <ChevronRight
                fontSize="inherit"
                sx={{ position: 'relative', bottom: 1, ml: 1 }}
              />
            </Typography>
          </Box>
          <Divider sx={{ mt: 2 }} />
        </Box>
        <Box
          sx={{
            '& > *:not(:first-child)': {
              borderTop: `solid 1px ${theme.palette.divider}`,
            },
          }}
        >
          {showPromoteCase && <PromoteCase />}
          {showCaseManagerPermissionRequest && <CaseManagerPermissionRequest />}
          {showExpirationMessage && <ExpirationMessage />}
          {showNegotiations && <Negotiations />}
          {showDataRoom && <DataRoom />}
          {showContactInformation && (
            <ContactInformation
              showCounterpart={showCounterpartContactInformation}
              showInAccordion={showContactInformationInAccordion}
            />
          )}
        </Box>
        {isLessMd && (
          <>
            <Divider />
            <Box p={3} display="flex" justifyContent="center">
              <Typography
                variant="button"
                component="button"
                color={theme.palette.primary.main}
                textTransform="none"
                onClick={handleExpandToggleClick}
              >
                Hide Case Details
                <ExpandLess fontSize="inherit" sx={{ ml: 1.5 }} />
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </>
  ) : null

  if (isLessMd) {
    return (
      <Accordion
        expanded={isExpanded}
        disableGutters
        elevation={0}
        square
        sx={{
          '&.Mui-expanded + &.Mui-expanded::before': {
            display: 'block',
          },
          '&.Mui-expanded::before': {
            opacity: 1,
          },
          '& > .MuiAccordionSummary-root': {
            p: 0,
          },
          '&::before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary
          sx={{
            '& > .MuiAccordionSummary-content': {
              m: 0,
              width: 1,
            },
          }}
          onClick={handleExpandToggleClick}
        >
          {referralHeader}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: referralContentBackgroundColor,
            p: 0,
          }}
        >
          {referralContent}
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <Accordion
      expanded={isExpanded}
      disableGutters
      elevation={0}
      square
      sx={{
        '&::before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        sx={{
          flexDirection: 'row-reverse',
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)',
          },
          '& .MuiAccordionSummary-content': {
            my: 0,
          },
        }}
        expandIcon={
          <KeyboardArrowDown
            sx={{
              ...(!referralContent && { visibility: 'hidden' }),
              fontSize: theme.spacing(2.5),
            }}
          />
        }
        onClick={handleExpandToggleClick}
      >
        {referralHeader}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: referralContentBackgroundColor,
          mt: theme.spacing(1),
          padding: 0,
        }}
      >
        {referralContent}
      </AccordionDetails>
    </Accordion>
  )
}

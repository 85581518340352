import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

const Header = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
}))

export default Header

import { ReactElement } from 'react'
import Typography, { TypographyProps } from '@mui/material/Typography'
import Link from '@mui/material/Link'

interface IAdditionalInstructionsProps extends TypographyProps {
  isConnected?: boolean
  subtitle?: string | ReactElement
  linkUrl: string
}

const AdditionalInstructions = ({
  isConnected,
  subtitle,
  linkUrl,
  sx,
  ...rest
}: IAdditionalInstructionsProps) => (
  <>
    {subtitle}
    {isConnected && (
      <Typography
        component="span"
        variant="body2"
        sx={[
          {
            my: 2,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...rest}
      >
        <br />
        <br />
        Please make sure that you have completed the additional steps detailed
        in{' '}
        <Link href={linkUrl} target="_blank" rel="noopener noreferrer">
          these instructions
        </Link>{' '}
        to fully enable the integration.
      </Typography>
    )}
  </>
)

export default AdditionalInstructions

import {
  createInstance,
  LogLevel,
  setLogLevel,
} from '@optimizely/optimizely-sdk'

const createOptimizelyInstance = async () => {
  const sdkKey = import.meta.env.VITE_OPTIMIZELY_SDK_KEY
  setLogLevel(LogLevel.ERROR)

  try {
    const instance = createInstance({
      sdkKey,
      datafileOptions: {
        autoUpdate: true,
      },
    })

    if (!instance) throw new Error('Failed to create Optimizely instance')

    const { success } = await instance.onReady({ timeout: 5000 })

    if (!success) throw new Error('Optimizely instance failed to initialize')

    return instance
  } catch (error) {
    console.error('Error initializing Optimizely:', error)

    // Fallback to local datafile
    const localDatafile = await fetch('/optimizely-datafile.json')
    const localDatafileJson = await localDatafile.json()

    return createInstance({
      datafile: localDatafileJson,
    })
  }
}

export default createOptimizelyInstance()

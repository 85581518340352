import { PhoneNumber } from '@/components/PhoneNumber/PhoneNumber'
import { Typography, Avatar, Box } from '@mui/material'

interface SignatureCardProps {
  name: string
  organization: string
  avatar: string
  email: string
  phone: string
  fee: number
  leads: {
    id: string | undefined
    firstName: string
    lastName: string
    email: string
    phone: string
  }[]
}

const SignatureCard = ({
  name,
  avatar,
  organization,
  email,
  phone,
  fee,
  leads,
}: SignatureCardProps) => {
  const translatedLeads =
    leads?.map(lead => ({
      id: String(lead.id),
      firstName: lead.firstName,
      lastName: lead.lastName,
      email: lead.email,
      phone: lead.phone,
    })) ?? []

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="subtitle2" color="text.secondary">
          Signed by
        </Typography>
      </Box>

      <Box display="flex" mb={2}>
        <Avatar src={avatar} alt={name} sx={{ mr: 2, width: 36, height: 36 }} />
        <Box>
          <Typography variant="subtitle2">{name}</Typography>
          <Typography variant="body2" color="text.secondary">
            {organization}
          </Typography>
          <Box ml={2} mt={1}>
            <Typography variant="body2" color="text.secondary">
              {email}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <PhoneNumber>{phone}</PhoneNumber>
            </Typography>
          </Box>
          <Box display="flex" alignItems="baseline" mt={2}>
            <Typography variant="h5" fontWeight="bold">
              {fee}
              <span style={{ fontSize: 14 }}>%</span>
            </Typography>
            <Typography
              variant="body2"
              fontSize={10}
              color="text.secondary"
              ml={1}
            >
              REFERRING ATTORNEY FEES
            </Typography>
          </Box>
        </Box>
      </Box>

      {translatedLeads.length > 0 && (
        <>
          <Typography variant="caption" color="text.secondary" gutterBottom>
            Leads Contact Info
          </Typography>
          {translatedLeads.map((lead, index) => (
            <Box key={lead.id} mb={index < translatedLeads.length - 1 ? 1 : 0}>
              <Typography variant="subtitle1">
                {lead.firstName} {lead.lastName}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {lead.email} - <PhoneNumber>{lead.phone}</PhoneNumber>
              </Typography>
            </Box>
          ))}
        </>
      )}
    </>
  )
}

export default SignatureCard

import { Autocomplete, TextField } from '@mui/material'
import { Controller, FieldValues } from 'react-hook-form'
import CreateAccountField from '@/components/CreateAccountField/CreateAccountField'
import { TJAutocompleteProps } from './types'

const JAutocomplete = <FV extends FieldValues>({
  control,
  name,
  options,
  label,
  getOptionLabel,
  rules,
  valueKey = 'id',
  noValueKey,
  fitTextField,
  ...rest
}: TJAutocompleteProps<FV>) => {
  // If the "rules" prop exists, update the label with an asterisk as suffix
  const textInputLabel = `${label ?? ''}${rules ? ' *' : ''}`
  const TextFieldComponent = fitTextField ? CreateAccountField : TextField
  return (
    <Controller<FV>
      name={name}
      control={control}
      rules={typeof rules === 'string' ? { required: rules } : rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          options={options}
          getOptionLabel={getOptionLabel}
          value={
            noValueKey
              ? value || null
              : options.find(option => option[valueKey] === value) || null
          }
          onChange={(_, optionData) =>
            onChange(noValueKey ? optionData : optionData?.[valueKey])
          }
          renderInput={params => (
            <TextFieldComponent
              {...params}
              error={!!error}
              helperText={error ? error.message : ' '}
              variant="outlined"
              label={textInputLabel}
            />
          )}
          {...rest}
        />
      )}
    />
  )
}

export default JAutocomplete

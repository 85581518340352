export const caseTitles = {
  promote: 'Promote this case',
  waterfall: 'This is a Waterfall Referral™',
  noPermission: 'You don’t have permission to act on this case',
  noProposals: 'You can’t send proposals to this case',
  proposalWithdrawn: 'Proposal withdrawn',
} as const

export const caseMessages = {
  spam: 'Avoid spamming and only select members who you think may be interested in the case. All colleagues you select will be able to submit proposals, including those who have not been vetted.',
  caseManagerAskPermission:
    'Please request permission from the Attorney to act on their behalf in this case.',
  caseTakenByMyOrg:
    'Another attorney from your law firm has an ongoing negotiation.',
  awaitingResponse:
    'You are waiting for a response from your counterpart. We will notify you when they accept or reject it.',
  caseAvailable:
    'You can accept the current fees or propose a different structure.',
  proposalWithdrawn:
    'If you are still interested in this case, you can submit a new proposal for the Case Owner to review.',
} as const

import { useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

enum ETextFieldSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
}

enum EButtonSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export const useSizes = () => {
  const theme = useTheme()
  const isLessSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isLessMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLessLg = useMediaQuery(theme.breakpoints.down('lg'))
  const isGreaterSm = useMediaQuery(theme.breakpoints.up('sm'))
  const isGreaterMd = useMediaQuery(theme.breakpoints.up('md'))
  const isGreaterLg = useMediaQuery(theme.breakpoints.up('lg'))

  const textFieldSizes = {
    ...(isLessMd && { size: ETextFieldSizes.SMALL }),
    ...(isGreaterMd && { size: ETextFieldSizes.MEDIUM }),
  }
  const textFieldSize = textFieldSizes.size || ETextFieldSizes.SMALL

  const buttonSizes = {
    ...(isLessMd && { size: EButtonSizes.SMALL }),
    ...(isGreaterMd && { size: EButtonSizes.MEDIUM }),
    ...(isGreaterLg && { size: EButtonSizes.LARGE }),
  }
  const buttonSize = buttonSizes.size || EButtonSizes.SMALL
  let screenSize = 'mobile'
  if (isGreaterMd) {
    screenSize = 'desktop'
  } else if (isGreaterSm) {
    screenSize = 'tablet'
  }

  return {
    buttonSize,
    textFieldSize,
    screenSize,
    isLessSm,
    isLessMd,
    isLessLg,
    isGreaterMd,
    isGreaterLg,
  }
}

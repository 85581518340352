import { RoutesData } from '@/router/RoutesData'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'

export const ExpirationMessage = () => {
  const navigate = useNavigate()
  const handleRepostCaseClick = () => {
    navigate(RoutesData.RCreateNewCase.path)
  }

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      py={2}
      justifyContent="center"
      alignItems="baseline"
      gap={2}
      textAlign="center"
    >
      <Typography m={{ xs: 'auto', md: 0 }}>
        The case has expired after being posted for 35 days without an agreement
      </Typography>

      <Button
        variant="outlined"
        onClick={handleRepostCaseClick}
        sx={{ m: { xs: 'auto', md: 0 } }}
      >
        Repost Case
      </Button>
    </Box>
  )
}

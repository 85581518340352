import dayjs from 'dayjs'
import { FINANCING_SOURCE, TIME_UNIT } from '@/constants'
import { CaseDetailsResponse } from '@/utils/api/integrations/types'

export const caseTypes = [
  'leaddocket',
  'lawmatics',
  'clio',
  'litify',
  'salesforce',
]

export const createIntegrationDataValues = (lcmsData: CaseDetailsResponse) => ({
  caseName: lcmsData?.case?.case_name || '',
  caseDescription: lcmsData?.case?.case_description || '',
  caseDescriptionFormatted: lcmsData?.case?.case_description_formatted || '',
  confidentiality: false,
  leadInfo: [
    {
      firstName: lcmsData?.case?.lead_contact_first_name || '',
      lastName: lcmsData?.case?.lead_contact_last_name || '',
      phone: lcmsData?.case?.lead_contact_phone || '',
      email: lcmsData?.case?.lead_contact_email || '',
    },
  ],
  fee: '33',
  financingSource: FINANCING_SOURCE.default || '',
  terms: false,
  caseType: '',
  legalStage: '',
  jurisdiction: '',
  caseNumber: '',
  language: '',
  incidentDate: lcmsData?.case?.incident_date
    ? dayjs(lcmsData?.case?.incident_date)
    : null,
  onBehalfOf: null,
  proposalsDueDate: null,
  isPriority: false,
  dataRoomFile: null,
  caseAlias: '',
  internalCaseNumber: String(lcmsData?.case?.internal_case_number) || '',
  assigneesId: ['', '', ''],
  timeLimit: 2,
  timeUnitId: TIME_UNIT.Hours,
})

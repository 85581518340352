import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { Box, Button, Grid } from '@mui/material'
import { useSizes } from '@/hooks/useSizes'
import { FC } from 'react'
import { IApplicationBarProps } from './types'

export const DesktopStickyActions: FC<IApplicationBarProps> = ({
  onPrimaryActionClick,
  onSecondaryActionClick,
  PrimaryActionProps = {},
  SecondaryActionProps = {},
}) => {
  const { screenSize } = useSizes()
  const mobileLayout = screenSize !== 'desktop'

  return (
    <Box
      sx={{
        display: `${mobileLayout ? 'none' : 'initial'}`,
        position: 'sticky',
        bottom: 0,
        zIndex: 10,
      }}
    >
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        pb={2}
        pt={2}
        sx={theme => ({
          background: theme.palette.common.white,
          borderTop: '1px solid ',
        })}
        color="divider"
      >
        <Grid item md={4} />
        <Grid item>
          <Button
            variant="contained"
            type="submit"
            startIcon={<SaveOutlinedIcon />}
            onClick={onPrimaryActionClick}
            {...PrimaryActionProps}
          >
            Save Changes
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{ width: '100%', height: '100%' }}
            variant="text"
            onClick={onSecondaryActionClick}
            {...SecondaryActionProps}
          >
            Discard Changes
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

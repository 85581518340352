import Paper from '@mui/material/Paper'
import JTextField from '@/components/atoms/JTextField'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { FC } from 'react'
import { DiscardChangesModal, ResponseModal } from '@/components'
import { MobileAppBar } from '@/pages/AccountSettings/Shared/MobileAppBar'
import { useTheme } from '@mui/material'
import { DesktopStickyActions } from '../Shared/DesktopStickyActions'
import { IAccountSettingsTabProps } from '../types'
import TitleColumn from '../Shared/TitleColumn'
import { useChangePassword } from './useChangePassword'
import { TChangePasswordForm } from './types'

const ChangePassword: FC<IAccountSettingsTabProps> = ({ title, subtitle }) => {
  const {
    control,
    handleSubmit,
    pSize,
    textFieldSize,
    handleDiscardChangesStart,
    callLoading,
    onChangePasswordSubmit,
    mobileLayout,
    response,
    setResponse,
    getConfirmationModalProps,
    modalOpen,
    dischargeChangeModalsRestProps,
    isDirty,
    isValid,
  } = useChangePassword()
  const theme = useTheme()
  const discardDisabled = callLoading || !isDirty
  const disabled = callLoading || !isDirty || !isValid

  return (
    <Paper
      sx={{
        overflow: 'auto',
        maxHeight: '100%',
        padding: theme.spacing(2),
        mb: mobileLayout ? theme.spacing(20) : theme.spacing(0),
      }}
    >
      <form onSubmit={handleSubmit(onChangePasswordSubmit)}>
        <Grid container direction="row" spacing={mobileLayout ? 1 : 5}>
          <TitleColumn title={title} subtitle={subtitle} />
          <Grid item mt={0} md={8} xs={12}>
            <Box sx={{ width: '100%' }} pl={pSize} pt={2} mb={2}>
              {modalOpen && (
                <DiscardChangesModal
                  ConfirmationModalProps={getConfirmationModalProps()}
                  {...dischargeChangeModalsRestProps}
                />
              )}
              {response.open && (
                <ResponseModal
                  open={response.open}
                  type={response.type}
                  onClose={() => setResponse({ open: false, type: null })}
                />
              )}
              <Grid container rowSpacing={2} pl={2} pr={2}>
                <Grid item xs={12}>
                  <JTextField<TChangePasswordForm>
                    name="currentPassword"
                    control={control}
                    label="Current Password"
                    type="password"
                    size={textFieldSize}
                  />
                </Grid>
                <Grid item xs={12}>
                  <JTextField<TChangePasswordForm>
                    name="newPassword"
                    control={control}
                    label="New Password"
                    type="password"
                    size={textFieldSize}
                  />
                </Grid>
                <Grid item xs={12}>
                  <JTextField<TChangePasswordForm>
                    name="repeatPassword"
                    control={control}
                    label="Repeat New Password"
                    type="password"
                    size={textFieldSize}
                  />
                </Grid>
              </Grid>
              <MobileAppBar
                onPrimaryActionClick={() => {}}
                onSecondaryActionClick={handleDiscardChangesStart}
                PrimaryActionProps={{ disabled }}
                SecondaryActionProps={{ disabled: discardDisabled }}
              />
            </Box>
          </Grid>
        </Grid>
        <DesktopStickyActions
          onPrimaryActionClick={() => {}}
          onSecondaryActionClick={handleDiscardChangesStart}
          PrimaryActionProps={{ disabled }}
          SecondaryActionProps={{ disabled: discardDisabled }}
        />
      </form>
    </Paper>
  )
}

export default ChangePassword

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Box from '@mui/material/Box'
import { FC, ReactNode } from 'react'

interface ITitleColumnProps {
  title: ReactNode
  subtitle: ReactNode
  pl?: number
  pt?: number
  isMobileView?: boolean
}

const TitleColumn: FC<ITitleColumnProps> = ({
  title,
  subtitle,
  pl = 2,
  pt = 2,
  isMobileView = false,
}) => (
  <Grid item md={4} xs={12}>
    <Box pl={pl} pt={pt}>
      {!isMobileView && (
        <Typography color="primary.main" variant="h5">
          {title}
        </Typography>
      )}
      <Typography color="text.secondary" variant="body2">
        {subtitle}
      </Typography>
    </Box>
  </Grid>
)

export default TitleColumn

import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useSizes } from '@/hooks/useSizes'

import logo from '@/assets/logo.svg'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

type LogoBarProps = {
  withActionOption?: boolean
  text?: ReactNode
  btnContent?: ReactNode
  buttonHref?: string
}

const LogoBar = ({
  withActionOption = false,
  text = 'Already have an account? ',
  btnContent = 'Sign In',
  buttonHref = '/signIn',
}: LogoBarProps) => {
  const { screenSize } = useSizes()
  const mobileLayout = screenSize !== 'desktop'
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mb: {
          xs: 5,
          md: 7.5,
        },
        mt: { xs: 4, md: 10 },
      }}
    >
      <img alt="AS Logo" src={logo} width={117} />
      {withActionOption && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            gap: 3,
            alignItems: 'center',
          }}
        >
          {!mobileLayout && (
            <Typography color="text.secondary" variant="caption">
              {text}
            </Typography>
          )}
          <Button variant="outlined" onClick={() => navigate(buttonHref)}>
            {btnContent}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default LogoBar

import Box, { BoxProps } from '@mui/material/Box'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { alpha, useTheme } from '@mui/material/styles'

interface FeeAgreementHeaderProps extends BoxProps {
  isSigned: boolean
  signedStatus: string
  titleTypographyProps?: TypographyProps
  statusTypographyProps?: TypographyProps
}

export const FeeAgreementHeader = ({
  isSigned,
  signedStatus,
  titleTypographyProps = {},
  sx,
  statusTypographyProps: {
    sx: statusTypographySx,
    ...statusTypograghyProps
  } = {},
  ...rest
}: FeeAgreementHeaderProps) => {
  const theme = useTheme()

  return (
    <Box
      sx={[
        {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      <Typography variant="body2" {...titleTypographyProps}>
        Fee Agreement
      </Typography>
      <Typography
        variant={isSigned ? 'body2' : 'caption'}
        sx={[
          {
            color: isSigned
              ? theme.palette.success.main
              : alpha(theme.palette.common.black, 0.38),
            fontWeight: isSigned ? 'bold' : 'normal',
          },
          ...(Array.isArray(statusTypographySx)
            ? statusTypographySx
            : [statusTypographySx]),
        ]}
        {...statusTypograghyProps}
      >
        {signedStatus}
      </Typography>
    </Box>
  )
}

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const ClioIcon = ({ height = 48, width = 48, ...props }: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width={height}
      height={width}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z"
        fill="#0069D1"
      />
      <g clipPath="url(#clip0_6198_155713)">
        <path
          d="M16.3228 20.2077L17.5273 18.5329L16.0249 19.8664C14.9092 18.6656 13.3117 17.9072 11.5368 17.9072C8.16431 17.9072 5.42578 20.6375 5.42578 23.9997C5.42578 27.3682 8.16431 30.0921 11.5368 30.0921C14.9155 30.0921 17.6477 27.3619 17.6477 23.9997C17.6541 22.565 17.1533 21.2505 16.3228 20.2077ZM15.7904 26.0157C15.7904 27.2481 14.7888 28.2404 13.559 28.2404H9.52089C8.28477 28.2404 7.2895 27.2418 7.2895 26.0157V21.9899C7.2895 20.7575 8.29109 19.7653 9.52089 19.7653H13.559C14.2436 19.7653 14.8522 20.0687 15.2579 20.549L11.5177 23.8796L8.97573 22.1606L10.8141 25.6871C10.8268 25.706 10.8395 25.725 10.8585 25.744C10.8648 25.7566 10.8711 25.7629 10.8775 25.7756C10.8965 25.8009 10.9092 25.8262 10.9345 25.8451C10.9536 25.8641 10.9726 25.8767 10.9916 25.8957C11.2261 26.1042 11.5368 26.1737 11.8284 26.1042H11.8347C11.8854 26.0916 11.9361 26.0726 11.9868 26.0473C11.9932 26.041 11.9995 26.041 12.0122 26.0347C12.0566 26.0157 12.0946 25.9905 12.139 25.9589C12.1517 25.9525 12.1644 25.9399 12.177 25.9336C12.196 25.9209 12.2214 25.9083 12.2404 25.8893C12.2531 25.8767 12.2658 25.8578 12.2785 25.8451C12.2848 25.8388 12.2911 25.8325 12.2975 25.8262L12.3165 25.8009C12.3418 25.7693 12.3672 25.7377 12.3862 25.706L15.6319 21.1683C15.7333 21.4211 15.7904 21.6992 15.7904 21.9899V26.0157Z"
          fill="white"
        />
        <path
          d="M25.6593 28.3737C22.8701 28.3737 21 26.6104 21 23.994C21 21.4154 22.9144 19.6143 25.6593 19.6143C27.4723 19.6143 28.4042 20.3031 28.6451 20.5117L28.0872 21.9463C27.7259 21.6493 26.8511 21.131 25.7798 21.131C24.0618 21.131 22.9081 22.2686 22.9081 23.9624C22.9081 25.6182 24.0872 26.7811 25.7798 26.7811C26.9969 26.7811 27.7829 26.3008 28.195 25.9469L28.7972 27.2993C28.0302 28.0009 26.9715 28.3737 25.6593 28.3737Z"
          fill="white"
        />
        <path
          d="M31.5097 19.7334H29.6523V28.2211H31.5097V19.7334Z"
          fill="white"
        />
        <path
          d="M32.9114 28.2153V22.2303H34.7688V28.2216H32.9114V28.2153ZM33.8433 21.2507C33.1904 21.2507 32.7656 20.8905 32.7656 20.328C32.7656 19.7781 33.1967 19.4053 33.8433 19.4053C34.4834 19.4053 34.921 19.7781 34.921 20.328C34.9147 20.8841 34.4963 21.2507 33.8433 21.2507Z"
          fill="white"
        />
        <path
          d="M39.2451 28.3603C37.1531 28.3603 35.7461 27.109 35.7461 25.2446C35.7461 23.3739 37.1531 22.1162 39.2451 22.1162C41.3307 22.1162 42.7317 23.3739 42.7317 25.2446C42.7254 27.109 41.3244 28.3603 39.2451 28.3603ZM39.2451 23.456C38.2816 23.456 37.635 24.1576 37.635 25.2067C37.635 26.2558 38.2816 26.9573 39.2451 26.9573C40.2088 26.9573 40.8554 26.2558 40.8554 25.2067C40.8554 24.1576 40.2088 23.456 39.2451 23.456Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6198_155713">
          <rect
            width="37.4634"
            height="12.2927"
            fill="white"
            transform="translate(5.26758 17.8535)"
          />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
)

export default ClioIcon

import Alert, { AlertProps } from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import { Case_Purchase_Activity } from '@/gql/appApi'

interface CaseProposalSubmittedAlertProps extends AlertProps {
  caseActivity: Case_Purchase_Activity[]
}

export const CaseProposalSubmittedAlert = ({
  caseActivity,
  sx,
}: CaseProposalSubmittedAlertProps) => {
  const offeredFee = caseActivity[0]?.fee

  return (
    <Alert
      severity="success"
      sx={[{ width: '100%' }, ...(Array.isArray(sx) ? sx : [sx])]}
      icon={false}
    >
      <Typography variant="subtitle1" mb={0.5}>
        You Submitted a Proposal
      </Typography>
      <Typography variant="body2">
        <strong>You offered {offeredFee}% to the case owner.</strong> If
        accepted, you will be notified and receive the potential client&apos;s
        contact information.
      </Typography>
    </Alert>
  )
}

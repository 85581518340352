import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Card from '@mui/material/Card'
import Collapse from '@mui/material/Collapse'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/material/styles/useTheme'

import JSnackBar from '@/components/atoms/JSnackBar'
import { ParameterName, bootParamsService } from '@/services/BootParams'
import { IntegrationItemProps } from '../../types'

import textContent, { INTEGRATION_LINKS } from '../../constants'

import IntegrationItemCardContent from '../shared/IntegrationItemCardContent'
import IntegrationItemButtons from '../shared/IntegrationItemButtons'
import useLitifyIntegrationItem from './useLitifyIntegrationItem'
import {
  challengeFromVerifier,
  checkAndHandleErrors,
  disableIntegrationAction,
  handleTextFieldValue,
  isSetupSuccessful,
  verifier,
} from '../shared/utils'
import LitifyCollapsableContent from './LitifyCollapsableContent'
import { useCodeVerifier, useInstanceUrl } from '../shared/localStorageUtil'
import AdditionalInstructions from '../shared/AdditionalInstructions'

const LitifyIntegrationItem = ({
  icon,
  name,
  subtitle,
  loading,
  organizationId,
  isConnectedToOtherLcms,
  isConnected,
  refetchLcmsData,
  removeIntegrationItem,
  isConnectingToLcms,
  connectingToLcms,
}: IntegrationItemProps) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const isSmallSize = useMediaQuery('(min-width:900px)')
  const {
    INTEGRATION_V2: { LITIFY },
  } = textContent
  const codeQueryParam = bootParamsService.get(ParameterName.LITIFY_CODE)
  const srcQueryParam = bootParamsService.get(ParameterName.SRC)

  const [expanded, setExpanded] = useState(false)
  const [litifyRemoveStatus, setLitifyRemoveStatus] = useState(false)
  const [urlInput, setUrlInput] = useState<string>('')
  const litifySrcName = LITIFY.NAME.toLowerCase()
  const [connectLitify, setConnectLitify] = useState(
    Boolean(
      codeQueryParam &&
        organizationId !== '' &&
        String(srcQueryParam) === litifySrcName
    )
  )
  const [codeChallenge, setCodeChallenge] = useState('')
  const [instanceUrl, setInstanceUrl] = useInstanceUrl()
  const [codeVerifier, setCodeVerifier] = useCodeVerifier()

  const getChallenge = async (v: string) => {
    try {
      const challenge = await challengeFromVerifier(v)
      setCodeChallenge(challenge)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error:', error)
    }
  }

  const {
    connectLitifyIntegration,
    connectLitifyIntegrationData,
    removeLitifyData,
    isLitifyIntegrationLoading,
    alert,
    handleClose,
    showAlert,
  } = useLitifyIntegrationItem({
    code_challenge: String(codeChallenge),
    code: String(codeQueryParam),
    instance_url: urlInput,
    connectLitify,
    litifyRemoveStatus,
    organizationId: String(organizationId),
    storageInstanceUrl: instanceUrl,
    storageCodeVerifier: codeVerifier,
    setInstanceUrl,
  })

  useEffect(() => {
    if (expanded) {
      getChallenge(verifier)
      setCodeVerifier(verifier)
    } else {
      setCodeChallenge('')
    }
  }, [expanded, setCodeVerifier])

  // Trigger connection
  useEffect(() => {
    if (
      codeQueryParam &&
      organizationId !== '' &&
      srcQueryParam === litifySrcName
    ) {
      connectingToLcms(true)
      setConnectLitify(true)
    }
  }, [
    litifySrcName,
    codeQueryParam,
    organizationId,
    srcQueryParam,
    connectingToLcms,
  ])

  // Monitors the LCMs setup process.
  useEffect(() => {
    if (
      isSetupSuccessful(connectLitifyIntegrationData) &&
      !litifyRemoveStatus &&
      !isConnected
    ) {
      connectingToLcms(false)
      setConnectLitify(false)
      bootParamsService.remove(ParameterName.LITIFY_CODE)
      bootParamsService.remove(ParameterName.SRC)
      refetchLcmsData()
    }
  }, [
    connectLitifyIntegrationData,
    isConnected,
    refetchLcmsData,
    litifyRemoveStatus,
    connectingToLcms,
  ])

  // Navigate to the current window location path clearing the query params provided,
  // replacing the current entry in the browser's history.
  useEffect(() => {
    if (window.location.search !== '') {
      navigate(window.location.pathname, { replace: true })
    }
  }, [navigate])

  // Handles errors during Litify integration setup or removal process
  // Check if the current organization is not connected to other LCMs
  useEffect(() => {
    const hasError = checkAndHandleErrors({
      connectIntegrationData: connectLitifyIntegrationData,
      removeData: removeLitifyData,
      isConnectedToOtherLcms: Boolean(isConnectedToOtherLcms),
      showAlert,
      setConnect: setConnectLitify,
    })
    if (hasError) {
      connectingToLcms(false)
      bootParamsService.remove(ParameterName.LITIFY_CODE)
      bootParamsService.remove(ParameterName.SRC)
    }
  }, [
    connectLitifyIntegrationData,
    isConnectedToOtherLcms,
    removeLitifyData,
    showAlert,
    connectingToLcms,
  ])

  // Handle disconnection from other LCMs
  useEffect(() => {
    if (isConnected && removeLitifyData?.message === 'deleted') {
      setLitifyRemoveStatus(false)
      refetchLcmsData()
    }
  }, [
    setLitifyRemoveStatus,
    isConnected,
    removeLitifyData?.message,
    refetchLcmsData,
  ])

  const litifyName = expanded && isSmallSize ? LITIFY.NAME_EXPANDED : name

  const removeLitifyIntegration = () => {
    removeIntegrationItem({
      integrationName: name,
      onSubmit: () => {
        setLitifyRemoveStatus(true)
      },
    })
  }

  return (
    <Card
      sx={{
        margin: theme.spacing(0, 0, 4),
        padding: theme.spacing(2),
        boxShadow: 2,
      }}
    >
      <IntegrationItemCardContent
        actions={
          <IntegrationItemButtons
            shouldRenderLoadingButton={loading || isConnectingToLcms}
            shouldRenderDisconnectButton={!!isConnected && !litifyRemoveStatus}
            disconnectLcms={() => {
              removeLitifyIntegration()
            }}
            isConnectedToOtherLcms={isConnectedToOtherLcms}
            isExpandable
            expanded={expanded}
            setExpanded={setExpanded}
          />
        }
        icon={icon}
        subtitle={
          <AdditionalInstructions
            isConnected={isConnected}
            linkUrl={INTEGRATION_LINKS.LITIFY_INSTRUCTIONS_URL}
            subtitle={subtitle}
          />
        }
        name={`${isConnected ? 'Connected to ' : ''}${litifyName}`}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <LitifyCollapsableContent
          connectIntegration={connectLitifyIntegration}
          isIntegrationLoading={
            isLitifyIntegrationLoading || isConnectingToLcms
          }
          handleTextFieldValue={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleTextFieldValue(event, setUrlInput)
          }
          disableAction={disableIntegrationAction(urlInput)}
          codeChallenge={codeChallenge}
        />
      </Collapse>
      <JSnackBar
        vertical="bottom"
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={handleClose}
      />
    </Card>
  )
}

export default LitifyIntegrationItem

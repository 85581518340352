/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState } from 'react'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useNavigate, generatePath } from 'react-router-dom'
import Backdrop from '@mui/material/Backdrop'
import useTheme from '@mui/material/styles/useTheme'
import JUser from '@/components/atoms/JUser'
import { ResponseModal } from '@/components'
import { RoutesData } from '@/router/RoutesData'
import CircularProgress from '@mui/material/CircularProgress'
import CaseNotAvailableModal from '@/pages/MyReferrals/components/CaseNotAvailableModal/CaseNotAvailableModal'
import { ContainerResponsive } from '@/pages/MyReferrals/components'
import { useSizes } from '@/hooks/useSizes'
import { MODAL_TYPES, ModalTypes } from '@/types'
import MatchFeesModal, {
  useMatchFeesModal,
} from '@/components/Modals/MatchFeesModal'
import { ClientSignedConfirmationModal } from '@/components/Modals/ClientSignedConfirmationModal'
import CaseMessageButton from '@/components/GetStreamChat/CaseMessageButton'
import AgreementRejectionConfirmationModal from '@/components/Modals/AgreementRejectionConfirmationModal'
import { PricesType } from '@/hooks/useStripe'
import { CASE_STATUS_FLOW } from '@/constants'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import { NegotiationItemProps } from './types'
import useNegotiations from './hooks/useNegotiations'
import NegotationFee from './components/NegotationFee'
import ClientSigning from './components/ClientSigning'

const NegotiationItem = ({
  fee,
  casePurchaseStatus,
  profile,
  type = 'Proposal',
  caseTitle,
  caseId,
  casePurchaseId,
  activityCreatedById,
  agreement,
  isCaseOwner,
  dateReceived,
  caseTaken,
  onSuccess,
  feeText,
  representingAttorneyId,
  representingAttorneyUserId,
  paymentStatus,
  isRefetching,
  userId,
  isInvited,
  isPrivate,
  caseStatus,
  isCaseStatusFlowSimplificationEnabled,
  isInitiatingAttorney,
  amountToPay,
  proposalExpirationDate,
}: NegotiationItemProps) => {
  const navigate = useNavigate()
  const { isLessMd } = useSizes()
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const caseAccepted = casePurchaseStatus === 'Accepted'
  const caseAcceptedWithNewCaseStatusFlow =
    caseAccepted && isCaseStatusFlowSimplificationEnabled
  const isMatched = caseStatus === CASE_STATUS_FLOW.Matched
  const isSigned = caseStatus === CASE_STATUS_FLOW.Signed
  /**
   * With the new case status flow, the user can accept the proposal without paying if:
   * - The user is the case owner
   * - The user is the invited attorney
   * - The user submitted a prepaid proposal (so no additional payment is required)
   */
  const noPaymentRequired =
    (isInvited || isCaseOwner || !!paymentStatus.currentUserPaid) &&
    !!isCaseStatusFlowSimplificationEnabled

  const {
    setResponse,
    fullname,
    firms,
    isGreaterMd,
    buttonSize,
    response,
    onSubmitAction,
    isActivityOwner,
    avatar,
    isVetted,
    isQualified,
    signedModalOpen,
    caseErrorModalOpen,
    rejectionModalIsOpen,
    handleClientSigned,
    handleClientSignedModalClose,
    handleClientRejected,
    handleRejectionModalClose,
    handleClientSignedSubmit,
    handleClientRejectedSubmit,
  } = useNegotiations({
    userProfile: profile,
    caseId,
    casePurchaseId,
    activityCreatedById,
    representingAttorneyId,
    userId,
    paymentStatus,
    isCaseOwner,
    isInvited,
    noPaymentRequired,
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (form: any) => {
    setMatchFeesModalOpen(null)
    setIsLoading(true)
    const mType = modalType
    const priceType: PricesType = type === 'Proposal' ? 'owner' : 'prospect'

    const result = await onSubmitAction({
      type: mType,
      fee:
        form?.fee !== undefined && form?.fee !== null ? Number(form.fee) : fee,
      priceType,
      stripePriceId,
      credits,
      discount,
    })
    if (result === null) {
      setResponse({ type: 'Error', open: true })
      setIsLoading(false)
      return
    }
    if (mType === 'Accept') {
      setIsLoading(false)
      if (typeof result === 'object') {
        setResponse({
          type: result.type as ModalTypes,
          open: result.open,
        })
      }
    }
    if (mType !== 'Accept') {
      setIsLoading(false)
      setResponse({
        type: mType,
        open: true,
      })
    }
  }

  const onClickProfile = () => {
    const path = generatePath(RoutesData.RProfile.path, {
      id: profile?.attorney_id,
    })
    navigate(path)
  }

  const clientSigningResponseModalTypes: string[] = [
    MODAL_TYPES.CLIENT_SIGNED_CONFIRMATION,
    MODAL_TYPES.CLIENT_SIGNED_REJECTED,
  ]

  const isClientSigningResponseModal = clientSigningResponseModalTypes.includes(
    response.type ?? ''
  )

  const matchFeeContentOnClientSigning =
    response.type === MODAL_TYPES.CLIENT_SIGNED_CONFIRMATION &&
    (amountToPay > 0
      ? `A match fee of $${amountToPay} has been or will be charged to the card you provided. Thank you for using Attorney Share.`
      : 'Thank you for using Attorney Share. ')

  const {
    getConfirmationModalProps,
    getCaseInformationProps,
    setOpenModal: setMatchFeesModalOpen,
    modalOpen: matchFeesModalOpen,
    type: modalType,
    stripePriceId,
    discount,
    credits,
    ...rest
  } = useMatchFeesModal({
    type,
    fullname,
    firms,
    avatar,
    caseTitle,
    fee,
    isCaseOwner,
    isVetted,
    isQualified,
    caseId,
    paymentStatus,
    agreement,
    isInvited,
    isPrivate,
    noPaymentRequired,
    onBehalfOfUserId: representingAttorneyUserId,
  })

  const renderButtons = () => {
    const renderMessage = () => {
      if (isSigned) {
        return (
          <Typography variant="caption" color="text.disabled">
            The client has been signed
          </Typography>
        )
      }
      if (
        (agreement && casePurchaseStatus !== 'Accepted') ||
        (agreement && !paymentStatus.currentUserPaid)
      ) {
        return ''
      }

      if (
        agreement &&
        !paymentStatus.bothPartiesPaid &&
        !caseAcceptedWithNewCaseStatusFlow
      ) {
        return (
          <Typography variant="caption" color="text.disabled">
            Waiting for counterpart response
          </Typography>
        )
      }
      switch (casePurchaseStatus) {
        case 'Accepted':
          return (
            <Typography variant="caption" color="text.disabled">
              The case has been accepted
            </Typography>
          )
        case 'Counteroffer':
          return (
            <Typography variant="caption" color="text.disabled">
              You sent a counteroffer, waiting for response
            </Typography>
          )
        case 'Rejected':
          return (
            <Typography variant="caption" color="text.disabled">
              Proposal was rejected
            </Typography>
          )
        case 'Proposed':
        default:
          return (
            <Typography variant="caption" color="text.disabled">
              Waiting for response
            </Typography>
          )
      }
    }

    if (isActivityOwner || agreement || isSigned) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2.5,
            ...(!isGreaterMd && {
              width: '100%',
            }),
          }}
        >
          {renderMessage()}
          <CaseMessageButton
            variant="outlined"
            fullWidth={!isGreaterMd}
            size={agreement ? 'large' : buttonSize}
            disabled={caseTaken || isRefetching}
            sx={{
              width: '175px',
            }}
          />
          {!isSigned &&
            agreement &&
            !paymentStatus.currentUserPaid &&
            !paymentStatus.bothPartiesPaid &&
            !caseAcceptedWithNewCaseStatusFlow && (
              <>
                <Button
                  fullWidth={!isGreaterMd}
                  variant="contained"
                  size={buttonSize}
                  onClick={() => {
                    setMatchFeesModalOpen('Accept')
                  }}
                  disabled={caseTaken || isRefetching}
                >
                  Reach Agreement
                </Button>
                <Button
                  fullWidth={!isGreaterMd}
                  variant="text"
                  size={buttonSize}
                  onClick={() => {
                    setMatchFeesModalOpen('Reject')
                  }}
                  disabled={isRefetching}
                  color="error"
                >
                  Reject
                </Button>
              </>
            )}
        </Box>
      )
    }
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            [theme.breakpoints.down('md')]: {
              width: '100%',
              justifyContent: 'center',
              gap: 1,
            },
          }}
        >
          <Button
            fullWidth={!isGreaterMd}
            variant="contained"
            size={buttonSize}
            onClick={() => {
              setMatchFeesModalOpen('Accept')
            }}
            disabled={caseTaken || isRefetching}
          >
            Accept Proposal
          </Button>
          <Button
            fullWidth={!isGreaterMd}
            variant="outlined"
            size={buttonSize}
            onClick={() => {
              setMatchFeesModalOpen('Counteroffer')
            }}
            disabled={caseTaken || isRefetching}
          >
            Send Counteroffer
          </Button>
        </Box>
        <Button
          fullWidth={!isGreaterMd}
          variant="text"
          size={buttonSize}
          onClick={() => {
            setMatchFeesModalOpen('Reject')
          }}
          disabled={caseTaken || isRefetching}
          color="error"
        >
          Reject
        </Button>
      </>
    )
  }

  // Used to make sure the timer displays the numbers with two digits. (e.g. Expires in: 06:05:04)
  const formatTimeDigits = (time: number) => String(time).padStart(2, '0')

  const proposalExpirationRenderer = ({
    hours,
    minutes,
    seconds,
  }: CountdownRenderProps) => (
    <strong>
      {formatTimeDigits(hours)}:{formatTimeDigits(minutes)}:
      {formatTimeDigits(seconds)}
    </strong>
  )

  const showExpirationCountdown =
    proposalExpirationDate && !isActivityOwner && !agreement && !isSigned

  const proposalDateAndTimer = showExpirationCountdown ? (
    <Typography
      variant="caption"
      sx={{
        ml: { md: 7.5 },
        display: 'flex',
        color: theme.palette.warning.dark,
      }}
    >
      Expires in:{' '}
      <Countdown
        date={proposalExpirationDate}
        renderer={proposalExpirationRenderer}
      />
    </Typography>
  ) : (
    dateReceived
  )

  return (
    <CardContent>
      <CaseNotAvailableModal
        open={caseErrorModalOpen}
        onClose={() => window.location.reload()}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        {isLoading && <CircularProgress color="inherit" />}
      </Backdrop>
      {caseTaken && (
        <Alert severity="warning" sx={{ mb: 1 }}>
          You can’t send a proposal, another attorney from your law firm has an
          ongoing negotiation.
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item md={3.5} xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <JUser
              mainText={fullname}
              secondaryText={firms}
              avatar={avatar}
              onClick={onClickProfile}
              isVetted={isVetted}
              isQualified={isQualified}
              referralDateText={!isLessMd ? proposalDateAndTimer : undefined}
            />
          </Box>
        </Grid>
        <Grid
          item
          md={3.5}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'flex-end' },
          }}
        >
          <NegotationFee
            fee={fee}
            referralDateText={proposalDateAndTimer}
            feeText={feeText}
          />
        </Grid>
        <Grid
          item
          md={5}
          xs={12}
          sx={{
            ...(isGreaterMd && {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }),
          }}
        >
          <ContainerResponsive sx={{ gap: 2 }}>
            {renderButtons()}
          </ContainerResponsive>
        </Grid>
        {/* Under feature flag CASE_STATUS_FLOW_SIMPLIFICATION */}
        {isCaseStatusFlowSimplificationEnabled &&
          caseAccepted &&
          isMatched &&
          !isCaseOwner && (
            <ClientSigning
              onClientSigned={handleClientSigned}
              onClientRejected={handleClientRejected}
            />
          )}
      </Grid>
      {matchFeesModalOpen && (
        <MatchFeesModal
          type={modalType}
          ConfirmationModalProps={getConfirmationModalProps()}
          CaseInformationProps={getCaseInformationProps()}
          isCaseOwner={isCaseOwner}
          isInitiatingAttorney={isInitiatingAttorney}
          onSubmit={onSubmit}
          {...rest}
        />
      )}
      {signedModalOpen && (
        <ClientSignedConfirmationModal
          onClose={handleClientSignedModalClose}
          open={signedModalOpen}
          onSubmitResponse={handleClientSignedSubmit}
          casePurchaseId={casePurchaseId}
          onBehalfOfAttorneyId={representingAttorneyId}
          fee={fee}
        />
      )}
      {rejectionModalIsOpen && (
        <AgreementRejectionConfirmationModal
          onClose={handleRejectionModalClose}
          open={rejectionModalIsOpen}
          onSubmitResponse={handleClientRejectedSubmit}
          casePurchaseId={casePurchaseId}
          onBehalfOfAttorneyId={representingAttorneyId}
          fee={fee}
        />
      )}
      {response.open && (
        <ResponseModal
          open={response.open}
          type={response.type}
          onClose={async () => {
            if (onSuccess) {
              await onSuccess()
            }
            setResponse({ open: false, type: null })
          }}
          variant={isClientSigningResponseModal ? 'standard' : 'outlined'}
          {...(isClientSigningResponseModal && { icon: false })}
          bodyContent={matchFeeContentOnClientSigning}
        />
      )}
    </CardContent>
  )
}
export default NegotiationItem

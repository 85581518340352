import { useState } from 'react'
import Image from '@/components/Image'
import Grid, { GridProps } from '@mui/material/Grid'
import InviteToNetwork from '@/assets/InviteToNetwork.svg'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import InviteToNetworkModal from './InviteToNetworkModal'
import { useLocation } from 'react-router-dom'

type InviteToNetworkCTAProps = GridProps

export const InviteToNetworkCTA = ({
  sx,
  ...rest
}: InviteToNetworkCTAProps) => {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const inviteModalOpen = params.get('inviteToNetworkModal')
  const [isModalOpen, setIsModalOpen] = useState(inviteModalOpen === 'open')

  const theme = useTheme()

  const handleInviteButtonClick = () => {
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleModalSubmit = () => {
    setIsModalOpen(false)
  }

  return (
    <Grid
      container
      columns={2}
      spacing={2}
      mt={0}
      sx={[{ py: { xs: 4, sm: 6 }, pl: 2 }, ...(Array.isArray(sx) ? sx : [sx])]}
      {...rest}
    >
      <Grid item xs={2} sm={1}>
        <Image
          src={InviteToNetwork}
          alt="Two attorneys connecting"
          sx={{ ml: { sm: 'auto' }, mx: { xs: 'auto' } }}
        />
      </Grid>
      <Grid
        item
        xs={2}
        sm={1}
        display="flex"
        justifyContent={{ xs: 'center', sm: 'normal' }}
      >
        <Box
          display="flex"
          flexDirection="column"
          textAlign="center"
          gap={2}
          pt={2.5}
          pl={{ sm: 3 }}
        >
          <Typography
            variant="subtitle2"
            color={theme.palette.text.secondary}
            mb={1}
          >
            Add your network to Attorney Share
          </Typography>

          <Button
            variant="contained"
            size="large"
            onClick={handleInviteButtonClick}
          >
            Invite Colleagues to Attorney Share
          </Button>
        </Box>
      </Grid>
      <InviteToNetworkModal
        open={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
      />
    </Grid>
  )
}

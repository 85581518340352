import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { useSizes } from '@/hooks/useSizes'

import NoVettedOptions from '@/pages/Case/components/NoVettedOptions/NoVettedOptions'
import { useMemo } from 'react'
import CaseSidebar from './CaseSidebar/CaseSidebar'
import { ISidebarProps } from './CaseSidebar/types'

const Sidebar = (props: ISidebarProps) => {
  const { screenSize } = useSizes()
  const mobileLayout = screenSize !== 'desktop'

  const Content = useMemo(() => {
    if (
      (!props.showCaseOptions && (props.isVetted || props.isQualified)) ||
      props.optionsLoading
    ) {
      return (
        <CircularProgress
          sx={{
            alignSelf: 'center',
            justifySelf: 'center',
          }}
        />
      )
    }

    if (props.showCaseOptions) {
      return <CaseSidebar {...props} />
    }

    return null
  }, [props])

  return Content ? (
    <Grid sx={{ width: '100%' }}>
      <Paper
        variant="outlined"
        square
        sx={{
          border: mobileLayout ? '0' : '2px solid',
          borderColor: 'secondary.main',
        }}
      >
        <Box sx={{ width: '100%' }}>{Content}</Box>
      </Paper>
    </Grid>
  ) : (
    !mobileLayout && !Content && <NoVettedOptions {...props} />
  )
}

export default Sidebar

import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

interface ICaseTitleProps {
  title?: string
}

const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    ...theme.typography.h3,
  },
  [theme.breakpoints.between('xs', 'md')]: {
    ...theme.typography.h5,
  },
}))

const CaseTitle: React.FC<ICaseTitleProps> = ({ title }: ICaseTitleProps) => (
  <Title color="primary.main">{title}</Title>
)

export default CaseTitle

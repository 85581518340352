import { useState } from 'react'
import {
  Create_Case_Purchase_Activity_Input,
  GetActivityPurchaseDocument,
  GetActivityPurchaseFromMyOrgDocument,
  GetCasesDocument,
  useCreateCaseActivityPurchaseMutation,
} from '@/gql/appApi'
import { CASE_PURCHASE_STATUS } from '@/constants'
import { useSnackBar } from '@/hooks/useSnackBar'
import { MODAL_TYPES, ModalTypes } from '@/types'
import { useCaseData } from '@/pages/Case/hooks/useJCaseData'

interface UseWithdrawProposalButtonProps {
  casePurchaseId: string
  onWithdrawSuccess?: () => void
}
export const useWithdrawProposalButton = ({
  casePurchaseId,
  onWithdrawSuccess,
}: UseWithdrawProposalButtonProps) => {
  const { activeAttorneyId } = useCaseData()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const { alert, showAlert, handleClose: handleCloseSnack } = useSnackBar()

  const [confirmationResponse, setResponse] = useState<{
    type: ModalTypes
    open: boolean
  }>({
    type: null,
    open: false,
  })

  const [
    createCaseActivityPurchaseMutation,
    { loading: isPerformingCreateCaseActivityPurchaseMutation },
  ] = useCreateCaseActivityPurchaseMutation({
    refetchQueries: [
      GetActivityPurchaseDocument,
      'case_purchase_activity',
      GetCasesDocument,
      'case',
      GetActivityPurchaseFromMyOrgDocument,
      'case_purchase_activity',
    ],
  })

  const handleWithdrawProposalClick = () => {
    setIsModalOpen(true)
  }

  const handleConfirmation = async () => {
    const data: Create_Case_Purchase_Activity_Input = {
      fee: 0,
      activity_entry_status: CASE_PURCHASE_STATUS.Withdrawn,
      created_by: activeAttorneyId,
      case_purchase_id: {
        id: casePurchaseId,
      },
    }

    try {
      await createCaseActivityPurchaseMutation({
        variables: {
          data,
        },
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      showAlert({
        severity: 'error',
        message: 'There was an error withdrawing the proposal.',
      })
    }

    setIsModalOpen(false)
    setResponse({ open: true, type: MODAL_TYPES.EATER_PROPOSAL_WITHDRAWN })
  }

  const handleConfirmationModalClose = () => {
    setIsModalOpen(false)
  }

  const handleConfirmationResponseModalClose = () => {
    setResponse({ open: false, type: null })

    onWithdrawSuccess?.()
  }

  return {
    alert,
    confirmationResponse,
    handleCloseSnack,
    handleConfirmation,
    handleConfirmationModalClose,
    handleConfirmationResponseModalClose,
    handleWithdrawProposalClick,
    isModalOpen,
    isPerformingCreateCaseActivityPurchaseMutation,
  }
}

import { useCallback, useEffect, useMemo, useState } from 'react'
import { LICENSE_STATUS, USER_ROLES } from '@/constants'
import {
  Attorney_Profile,
  Catalog_Us_State,
  GetOrgAttorneysAndLicenseJurisdictionsQuery,
  useAttorneyNameAndOrgQuery,
  useCreatePracticeAttorneyMappingItemsMutation,
  useDeletePracticeAttorneyMappingItemsMutation,
  useGetOrgAttorneysAndLicenseJurisdictionsQuery,
} from '@/gql/appApi'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useGetMyRoleAndOrg } from '@/hooks/useGetMyRoleAndOrg'
import useAttorneyId from '@/hooks/useAttorneyId'
import { useSnackBar } from '@/hooks/useSnackBar'

export type AttorneyToPracticeAreas = {
  jurisdictionId: string | null
  assignedAttorneyId: string | null
  attorneyToPracticeAreas: Array<string> | null
}

export type PracticeAreaMappingFormValues = {
  defaultAttorney: string | null
  assignedAttorneys: AttorneyToPracticeAreas[] | null
}

export type TValidAttorneysAndJurisdictions = {
  validAttorneys: Map<string, Attorney_Profile[]>
  validJurisdictions: Catalog_Us_State[]
}

const defaultValues: PracticeAreaMappingFormValues = {
  defaultAttorney: null,
  assignedAttorneys: [
    {
      jurisdictionId: null,
      assignedAttorneyId: null,
      attorneyToPracticeAreas: [],
    },
  ],
}

const usePracticeAreaMapping = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { orgId, isAdmin, loading: loadingOrgData } = useGetMyRoleAndOrg()
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = getAttorneyId()
  const { alert, showAlert, handleClose } = useSnackBar()

  const [
    createPracticeAttorneyMappingItems,
    { loading: loadingCreatePracticeAttorneyMappingItems },
  ] = useCreatePracticeAttorneyMappingItemsMutation()

  const [
    deletePracticeAttorneyMappingItems,
    { loading: loadingDeletePracticeAttorneyMappingItems },
  ] = useDeletePracticeAttorneyMappingItemsMutation()

  const { data: adminAttorneyData, loading } = useAttorneyNameAndOrgQuery({
    variables: {
      filter: {
        _and: [
          {
            attorney_id: {
              user_id: {
                current_organization: {
                  organization_id: {
                    id: {
                      _eq: orgId,
                    },
                  },
                },
              },
            },
          },
          {
            attorney_id: {
              user_id: {
                role: {
                  id: {
                    _in: [
                      USER_ROLES.SAdminAttorneyUser,
                      USER_ROLES.AdminAttorneyUser,
                    ],
                  },
                },
              },
            },
          },
          {
            attorney_id: {
              user_id: {
                attorneys: {
                  profiles: {
                    licenses: {
                      status: {
                        id: {
                          _eq: LICENSE_STATUS.VALIDATED,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        ],
      },
    },
    skip: !orgId,
  })

  const {
    data: orgAttorneysAndLicenseJurisdictionsData,
    loading: loadingOrgUserLicenseJurisdictionsData,
    refetch: refetchPracticeAttorneyMappingData,
  } = useGetOrgAttorneysAndLicenseJurisdictionsQuery({
    fetchPolicy: 'network-only',
    variables: {
      organizationFilter: { id: { _eq: orgId } },
      userFilter: {
        user_id: {
          attorneys: {
            profiles: {
              licenses: {
                status: {
                  id: {
                    _eq: LICENSE_STATUS.VALIDATED,
                  },
                },
              },
            },
          },
        },
      },
      sort: ['-jurisdiction_id'],
    },
    skip: !orgId,
  })

  const methods = useForm<PracticeAreaMappingFormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const validJurisdictionsAndAttorneys = useMemo(() => {
    if (!orgAttorneysAndLicenseJurisdictionsData?.organization) {
      return {
        validAttorneys: new Map<string, Attorney_Profile[]>(),
        validJurisdictions: [] as Catalog_Us_State[],
      }
    }

    const jurisdictionsMap = new Map<string, Catalog_Us_State>()
    const validAttorneysPerJurisdictionMap = new Map<
      string,
      Attorney_Profile[]
    >()

    const users =
      orgAttorneysAndLicenseJurisdictionsData.organization[0].users || []

    users.forEach(user => {
      const userId = user?.user_id
      const attorney = userId?.attorneys?.[0]
      const profile = attorney?.profiles?.[0]

      if (!profile) return

      const licenses = profile.licenses || []
      const processedJurisdictions = new Set<string>()

      licenses.forEach(license => {
        const { license_state: licenseState, status } = license || {}
        const licenseStatusId = status?.id

        if (
          licenseState?.id &&
          licenseState.name &&
          licenseState.code &&
          licenseStatusId === LICENSE_STATUS.VALIDATED &&
          !processedJurisdictions.has(licenseState.id)
        ) {
          processedJurisdictions.add(licenseState.id)

          if (!jurisdictionsMap.has(licenseState.id)) {
            jurisdictionsMap.set(licenseState.id, {
              id: licenseState.id,
              name: licenseState.name,
              code: licenseState.code,
            })
          }

          if (!validAttorneysPerJurisdictionMap.has(licenseState.id)) {
            validAttorneysPerJurisdictionMap.set(licenseState.id, [])
          }
          validAttorneysPerJurisdictionMap
            .get(licenseState.id)
            ?.push(profile as Attorney_Profile)
        }
      })
    })

    // Sort the attorneysArray for each jurisdiction after all attorneys have been added
    validAttorneysPerJurisdictionMap.forEach(attorneysArray => {
      attorneysArray.sort((a, b) =>
        (a.first_name ?? '').localeCompare(b.first_name ?? '')
      )
    })

    const validJurisdictions = Array.from(jurisdictionsMap.values())

    return {
      validAttorneys: validAttorneysPerJurisdictionMap,
      validJurisdictions,
    }
  }, [orgAttorneysAndLicenseJurisdictionsData?.organization])

  /**
   * We need a function that will convert the data from the server to the form values structure.
   * The reason for having this structure is based on a BE improvement/refactor that will come in the future to
   * add the practice attorney mapping to the organization profile, this way we can handle changes with a single
   * update mutation instead of multiple mutations (create/delete).
   *
   * Instructions from the BE: The item with the `practice_area_id` as `null` will be the default attorney.
   * */
  const convertDataToFormValues = (
    data: GetOrgAttorneysAndLicenseJurisdictionsQuery['organization'][0]['practice_attorney_mapping']
  ) => {
    if (!data) {
      return {
        defaultAttorney: null,
        assignedAttorneys: [],
      }
    }
    const defaultAttorney =
      data.find(
        item =>
          item?.practice_area_id === null && item?.jurisdiction_id === null
      )?.attorney_id?.id || null

    const assignedAttorneys: AttorneyToPracticeAreas[] = data
      .filter(
        item =>
          !(item?.practice_area_id === null && item?.jurisdiction_id === null)
      )
      .reduce((acc: AttorneyToPracticeAreas[], item) => {
        const existingAttorney = acc.find(
          accItem =>
            accItem.assignedAttorneyId === item?.attorney_id?.id &&
            accItem.jurisdictionId === item?.jurisdiction_id?.id
        )

        if (existingAttorney) {
          if (item?.practice_area_id !== null) {
            existingAttorney.attorneyToPracticeAreas?.push(
              item?.practice_area_id?.id || ''
            )
          }
        } else {
          acc.push({
            jurisdictionId: item?.jurisdiction_id?.id || null,
            assignedAttorneyId: item?.attorney_id?.id || null,
            attorneyToPracticeAreas: item?.practice_area_id?.id
              ? [item.practice_area_id.id]
              : [],
          })
        }

        return acc
      }, [])

    return {
      defaultAttorney,
      assignedAttorneys,
    }
  }

  const getDefaultAttorneyId = useCallback(
    (defaultAttorney: string | null) => {
      if (defaultAttorney === null) {
        // If the current user is an admin with a valid license, the current user will be the default attorney.
        const isAdminWithValidLicense = !!(
          adminAttorneyData?.attorney_profile?.some(
            attorney => attorney?.attorney_id?.id === attorneyId
          ) && isAdmin
        )
        if (isAdminWithValidLicense) {
          return attorneyId || null
        }
        // If the current user is not an admin (should not happen at the moment since only Admins can access this configuration),
        // the default attorney will be the first admin attorney found.
        return adminAttorneyData?.attorney_profile?.[0]?.attorney_id?.id || null
      }
      return defaultAttorney
    },
    [isAdmin, attorneyId, adminAttorneyData]
  )

  useEffect(() => {
    if (
      adminAttorneyData &&
      orgAttorneysAndLicenseJurisdictionsData?.organization?.[0]
        ?.practice_attorney_mapping
    ) {
      const formValues = convertDataToFormValues(
        orgAttorneysAndLicenseJurisdictionsData?.organization?.[0]
          ?.practice_attorney_mapping
      )

      const defaultAttorney = getDefaultAttorneyId(formValues.defaultAttorney)

      methods.setValue('defaultAttorney', defaultAttorney)
      methods.setValue('assignedAttorneys', formValues.assignedAttorneys)
    }
  }, [
    adminAttorneyData,
    orgAttorneysAndLicenseJurisdictionsData?.organization,
    isAdmin,
    attorneyId,
    getDefaultAttorneyId,
    methods,
  ])

  /**
   * This function will compare the final data with the initial data after being submitted in the form.
   * We need to see if an item was added or removed. If added, we need to isolate those items to call the createPracticeAttorneyMappingItems mutation.
   * If removed we need to isolate those ids that came from the server data and call the deletePracticeAttorneyMappingItems mutation.
   */
  const processAttorneyMappingData = (
    data: {
      organization_id: string
      practice_area_id: string | null
      jurisdiction_id: string | null
      attorney_id: string
    }[]
  ) => {
    const practiceAttorneyMappingData =
      orgAttorneysAndLicenseJurisdictionsData?.organization?.[0]
        ?.practice_attorney_mapping

    // Now, we need to compare the formItems with the data from the server:
    // If the item is not in the data from the server, it was added.
    // If an item from the server data is not in the formItems, it was removed.
    const addedItems = data.filter(
      formItem =>
        !practiceAttorneyMappingData?.find(
          item =>
            item?.attorney_id?.id === formItem.attorney_id &&
            (item?.practice_area_id?.id || null) ===
              formItem.practice_area_id &&
            (item?.jurisdiction_id?.id || null) === formItem.jurisdiction_id
        )
    )

    // We only need the ids of the removed items
    const removedItems = practiceAttorneyMappingData
      ?.filter(
        item =>
          !data.find(
            formItem =>
              item?.attorney_id?.id === formItem.attorney_id &&
              (item?.practice_area_id?.id || null) ===
                formItem.practice_area_id &&
              (item?.jurisdiction_id?.id || null) === formItem.jurisdiction_id
          )
      )
      .map(item => item?.id)
      .filter((id): id is string => Boolean(id))

    return {
      addedItems,
      removedItems,
    }
  }

  const onSubmit: SubmitHandler<PracticeAreaMappingFormValues> = async (
    formData: PracticeAreaMappingFormValues
  ) => {
    const { assignedAttorneys, defaultAttorney } = formData
    if (!orgId || (!assignedAttorneys?.length && !defaultAttorney)) return
    setIsSubmitting(true)

    const data: {
      organization_id: string
      practice_area_id: string | null
      jurisdiction_id: string | null
      attorney_id: string
    }[] = []

    // First, cleanup the data to remove possible undefined values
    if (assignedAttorneys?.length) {
      const cleanedAssignedAttorneys = assignedAttorneys.map(
        assignedAttorney => ({
          jurisdictionId: assignedAttorney.jurisdictionId || null,
          assignedAttorneyId: assignedAttorney.assignedAttorneyId ?? '',
          attorneyToPracticeAreas:
            assignedAttorney.attorneyToPracticeAreas?.filter(Boolean) || [],
        })
      )

      data.push(
        ...cleanedAssignedAttorneys.flatMap(assignedAttorney => {
          if (assignedAttorney.attorneyToPracticeAreas.length) {
            return assignedAttorney.attorneyToPracticeAreas.map(
              practiceAreaId => ({
                organization_id: orgId,
                practice_area_id: practiceAreaId || null,
                jurisdiction_id: assignedAttorney.jurisdictionId,
                attorney_id: assignedAttorney.assignedAttorneyId || '',
              })
            )
          }
          return {
            organization_id: orgId,
            practice_area_id: null,
            jurisdiction_id: assignedAttorney.jurisdictionId,
            attorney_id: assignedAttorney.assignedAttorneyId || '',
          }
        })
      )
    }

    // Default attorney is assigned null in practice_area_id and jurisdiction_id (According to BE, this will indicate that this is the default attorney)
    data.push({
      organization_id: orgId,
      practice_area_id: null,
      jurisdiction_id: null,
      attorney_id: defaultAttorney || '',
    })

    const { addedItems, removedItems } = processAttorneyMappingData(data)

    if (!addedItems?.length && !removedItems?.length) {
      setIsSubmitting(false)
      return
    }

    try {
      if (addedItems?.length) {
        const res = await createPracticeAttorneyMappingItems({
          variables: {
            data: addedItems,
          },
        })
        if (res.errors) {
          showAlert({
            severity: 'error',
            message:
              'An error occurred while creating the practice area mapping',
          })
          setIsSubmitting(false)
          return
        }
      }

      if (removedItems?.length) {
        const res = await deletePracticeAttorneyMappingItems({
          variables: {
            ids: removedItems,
          },
        })
        if (res.errors) {
          showAlert({
            severity: 'error',
            message: 'An error occurred while removing the practice areas',
          })
          setIsSubmitting(false)
          return
        }
      }
      await refetchPracticeAttorneyMappingData()
      showAlert({
        severity: 'success',
        message: 'Practice area mapping updated successfully',
      })
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('error', err)
      showAlert({
        severity: 'error',
        message:
          'An error occurred while updating the practice area mapping data',
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const isLoading =
    loading || loadingOrgUserLicenseJurisdictionsData || loadingOrgData

  const submitting =
    isSubmitting ||
    loadingCreatePracticeAttorneyMappingItems ||
    loadingDeletePracticeAttorneyMappingItems

  return {
    methods,
    onSubmit,
    loading: isLoading,
    isSubmitting: submitting,
    alert: {
      alertOpen: alert.open,
      alertSeverity: alert.severity,
      alertMessage: alert.message,
      handleCloseAlert: handleClose,
    },
    validJurisdictionsAndAttorneys,
  }
}

export default usePracticeAreaMapping

import React from 'react'
import { Skeleton } from '@mui/material'

const SidebarSkeleton: React.FC = () => (
  <Skeleton
    variant="rectangular"
    width="100%"
    height={400}
    sx={{ transform: 'scale(1, 1)', borderRadius: 2 }}
  />
)

export default SidebarSkeleton

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react'
import { educationDefaultValues } from '@/pages/CreateProfile/Education/types'
import { TTokenValidationResponse } from '@/hooks/useTokenValidation'
import useAuthentication from '@/hooks/useAuthentication'
import useUpdateAttorneyProfile from '@/pages/CreateProfile/hooks/useUpdateAttorneyProfile'
import { useNavigate } from 'react-router-dom'
import useOrganization from '@/hooks/useOrganization'
import { ACCOUNT_TYPE } from '@/constants'
import { getSignInRedirectionByRole } from '@/pages/SignIn/utils'
import { useGetDelegatedUsersByCaseManagerLazyQuery } from '@/gql/systemApi'
import {
  ProfessionalLicenseFormValues,
  getDefaultValues as getProfessionalLicenseDefaultValues,
} from '@/components/ProfessionalLicensesForm'
import dayjs from 'dayjs'
import { sessionStorageService } from '@/services/SessionStorage/SessionStorageService'
import { communicationPreferencesDefaultValues } from '@/components/CommunicationPreferences/hooks/useCommunicationPreferences'
import { cloneDeep, merge } from 'lodash'
import useCreateInvitedAdminFlow from './useCreateInvitedAdminFlow'
import { TInvitedAdminFormState } from '../types'
import { CreateAccountData } from '../../InitialStep/types'
import { profileDefaultValues } from '../../CreateOrganization/types'
import { TCaseManagerFormState } from '../../CreateCaseManager/hooks/useCreateCaseManager'

interface IUseCreateInvitedAdminProps {
  createAccountValues: CreateAccountData
  tokenData: TTokenValidationResponse
}

export const useCreateInvitedAdmin = ({
  createAccountValues,
  tokenData,
}: IUseCreateInvitedAdminProps) => {
  const navigate = useNavigate()
  const {
    createAccount,
    updateAttorneyProfile: updateLicensedProfile,
    loading: authLoading,
  } = useAuthentication()
  const {
    getOrganizationData,
    updateOrganizationUser,
    loading: orgLoading,
  } = useOrganization()
  const {
    tokenRefresh,
    updateAttorneyProfile,
    loading: updateLoading,
  } = useUpdateAttorneyProfile()
  const [hasLicense, setHasLicense] = useState<boolean>(true)
  const [error, setError] = useState('')
  const { state, next, back, send, goToCommunicationPreferences } =
    useCreateInvitedAdminFlow({ hasLicense })
  const [formState, setFormState] = useState<TInvitedAdminFormState>({
    professionalLicenses: [getProfessionalLicenseDefaultValues()],
    practiceAreas: [],
    personalInfo: profileDefaultValues,
    education: educationDefaultValues,
    communicationPreferences: communicationPreferencesDefaultValues,
  })
  const [getUserMeWithRoleData] = useGetDelegatedUsersByCaseManagerLazyQuery()

  const loading = authLoading || orgLoading || updateLoading

  const updateFormState = (
    key: keyof TInvitedAdminFormState,
    value: TInvitedAdminFormState[keyof TInvitedAdminFormState]
  ) => {
    setFormState(prevState => ({ ...prevState, [key]: value }))
  }

  const submitAndContinue = async (
    key: keyof TInvitedAdminFormState,
    data: any
  ) => {
    updateFormState(key, data)
    next()
  }

  const handleOnSkip = useCallback(async () => {
    const result = await tokenRefresh()
    if (result) {
      const userMe = await getUserMeWithRoleData()
      navigate(
        getSignInRedirectionByRole(userMe.data?.users_me?.role?.id || '')
      )
      return
    }
    setError('Something went wrong. Please try again.')
  }, [getUserMeWithRoleData, navigate, tokenRefresh])

  const handleCreateAccount = useCallback(
    async (
      formValues?:
        | ProfessionalLicenseFormValues
        | { professionalLicenses: ProfessionalLicenseFormValues[] }
    ) => {
      const licenseValuesArray: ProfessionalLicenseFormValues[] = []

      if (formValues && 'professionalLicenses' in formValues) {
        licenseValuesArray.push(...formValues.professionalLicenses)
      } else if (formValues) {
        licenseValuesArray.push(formValues)
      }

      sessionStorageService.clear()

      const result = await createAccount({
        email: tokenData.email.toLowerCase(),
        password: createAccountValues.password,
        firstName: createAccountValues.firstName,
        lastName: createAccountValues.lastName,
        tags: JSON.stringify({
          target_role: tokenData.role,
          target_organization: tokenData.organization,
          account_type: ACCOUNT_TYPE.Organization,
        }),
      })
      if (result.id && result.profileId) {
        if (hasLicense && licenseValuesArray.length) {
          updateFormState('professionalLicenses', licenseValuesArray)

          const updateAttorneyResult = await updateLicensedProfile({
            licenses: licenseValuesArray.map(licenseValues => ({
              barNumber: licenseValues.barNumber,
              isActive: licenseValues.isActive,
              isDisciplined: licenseValues.isDisciplined,
              isSued: licenseValues.isSued,
              stateId: licenseValues.state,
              malpracticeInsuranceId: licenseValues.malpracticeInsuranceId,
              insuranceExpirationDate: licenseValues.insuranceExpirationDate
                ? dayjs(licenseValues.insuranceExpirationDate).format(
                    'YYYY-MM-DD'
                  )
                : null,
            })),
            profileId: result.profileId,
            phoneNumber: createAccountValues.phone,
          })
          if (updateAttorneyResult.id) {
            next()
            return
          }
          if (updateAttorneyResult.updateAttorneyProfileErrors) {
            setError(updateAttorneyResult.updateAttorneyProfileErrors.base)
            return
          }
        }
        send('PERSONAL_INFO')
      }
      if (result.createAccountErrors) {
        setError(result.createAccountErrors.base)
      }
    },
    [
      createAccount,
      tokenData.email,
      tokenData.role,
      tokenData.organization,
      createAccountValues.password,
      createAccountValues.firstName,
      createAccountValues.lastName,
      createAccountValues.phone,
      hasLicense,
      send,
      updateLicensedProfile,
      next,
    ]
  )

  const handleUpdateOrgUser = useCallback(
    async (
      communicationPreferences: TCaseManagerFormState['communicationPreferences']
    ) => {
      updateFormState('communicationPreferences', communicationPreferences)
      let finalData = cloneDeep(formState)

      if (communicationPreferences) {
        finalData = merge(formState, { communicationPreferences })
      }

      const updateResult = await updateAttorneyProfile({
        education: hasLicense ? formState.education : educationDefaultValues,
        practiceAreas: hasLicense ? formState.practiceAreas : [],
        personalInfo: {
          bio: finalData.personalInfo.bio || '',
        },
        communicationPreferences,
        ...(!hasLicense && { phoneNumber: createAccountValues.phone }),
      })

      if (!updateResult.id) {
        setError(updateResult.updateAttorneyProfileErrors?.base || '')
        return
      }

      if (finalData.personalInfo.position) {
        const organizationDataResult = await getOrganizationData()
        if (!organizationDataResult.organizationUserItemId) {
          setError(
            organizationDataResult?.error || 'Organization User ID not found'
          )
          return
        }
        const updateOrgUserResult = await updateOrganizationUser({
          organizationUserItemId:
            organizationDataResult.organizationUserItemId || '',
          positionName: finalData.personalInfo.position,
          positionBio: finalData.personalInfo.bio || '',
        })
        if (updateOrgUserResult.organizationUserItemId) {
          handleOnSkip()
          return
        }
        if (updateOrgUserResult.updateOrganizationUserErrors) {
          setError(updateOrgUserResult.updateOrganizationUserErrors.base)
          return
        }
      }
      handleOnSkip()
    },
    [
      getOrganizationData,
      handleOnSkip,
      hasLicense,
      updateAttorneyProfile,
      updateOrganizationUser,
      formState,
      createAccountValues.phone,
    ]
  )

  return {
    state,
    hasLicense,
    next,
    back,
    send,
    formState,
    setFormState,
    submitAndContinue,
    setHasLicense,
    handleCreateAccount,
    handleUpdateOrgUser,
    handleOnSkip,
    loading,
    error,
    setError,
    goToCommunicationPreferences,
  }
}

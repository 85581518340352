import isEqual from 'lodash/isEqual'
import { useStreamChat } from './useStreamChat'

export const useChat = () => {
  const {
    createDirectChannel,
    createCaseChannel,
    setActiveChannelId,
    setMessageDrawerOpen,
    getCaseChannelByCaseId,
    isChannelArchived,
  } = useStreamChat()

  const openStreamChatForUser = async ({ userId }: { userId?: string }) => {
    try {
      if (!userId) {
        throw new Error('No user ID provided')
      }

      const channelId = await createDirectChannel([userId])
      setActiveChannelId(channelId)
      setMessageDrawerOpen(true)
    } catch (error) {
      console.error('Error opening StreamChat for user:', error)
    }
  }

  const openChatForUser = async (userId: string) => {
    try {
      openStreamChatForUser({ userId })
    } catch (error) {
      console.error('Error opening chat for user:', error)
    }
  }

  const openCaseChat = async ({
    caseId,
    members,
    caseTitle,
  }: {
    caseId?: string
    members: string[]
    caseTitle?: string
  }) => {
    try {
      if (!members.length) throw new Error('No members provided')

      if (!caseId) throw new Error('No case ID provided')

      const existingChannel = await getCaseChannelByCaseId(caseId)

      const dedupedMembers = [...new Set(members)]
      const sortedMembers = [...dedupedMembers].sort()

      const existingChannelMembers = existingChannel?.state.members ?? []

      // NOTE: Despite the stream-chat package type definitions,
      // existingChannel.state.members is sometimes an Array of member IDs and
      // sometimes a Record (as typed). It is unclear why it changes in
      // different contexts. Regardless, the user ID lists must be normalized
      // and sorted.
      const sortedExistingChannelMembers = (
        Array.isArray(existingChannelMembers)
          ? existingChannelMembers
          : Object.keys(existingChannelMembers)
      ).sort()

      if (
        existingChannel &&
        !isChannelArchived(existingChannel) &&
        isEqual(sortedMembers, sortedExistingChannelMembers)
      ) {
        setActiveChannelId(String(existingChannel.id))
        setMessageDrawerOpen(true)
        return
      }

      const channelId = await createCaseChannel({
        caseId,
        members: sortedMembers,
        caseTitle,
      })
      setActiveChannelId(channelId)
      setMessageDrawerOpen(true)
    } catch (error) {
      console.error('Error opening chat for case:', error)
    }
  }

  return { openChatForUser, openCaseChat }
}

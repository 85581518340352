import { MP_VISIBILITY } from '@/constants/index'
import { AllReferralFilterItem } from '@/pages/MyReferralsV2/types'

export const TYPES_CATALOG: Partial<AllReferralFilterItem>[] = [
  {
    id: 0,
    name: 'All Types',
    data: undefined,
  },
  {
    id: 1,
    name: 'Marketplace',
    data: MP_VISIBILITY.Public,
  },
  {
    id: 2,
    name: 'Waterfall',
    data: MP_VISIBILITY.Priority,
  },
]

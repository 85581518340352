import { DataRoom } from '@/pages/MyReferralsV2/types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import Button from '@mui/material/Button'
import useDataroomRequests, {
  TAccessRequests,
} from '@/pages/MyReferralsV2/components/DataroomRequests/hooks/useDataroomRequests'
import { useSizes } from '@/hooks/useSizes'
import IconButton from '@mui/material/IconButton'
import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import { ReferralAttorneyAvatar } from './ReferralAttorneyAvatar'
import { useDataRoomRequest } from './useDataRoomRequest'

interface DataRoomRequestProps {
  accessRequest: TAccessRequests
  dataRoomRequest: DataRoom
  onDenyAccess: ReturnType<typeof useDataroomRequests>['onDenyAccess']
  onGiveAccess: ReturnType<typeof useDataroomRequests>['onGiveAccess']
}

export const DataRoomRequest = ({
  accessRequest,
  dataRoomRequest,
  onDenyAccess,
  onGiveAccess,
}: DataRoomRequestProps) => {
  const theme = useTheme()
  const { isLessSm, isLessMd } = useSizes()
  const { createdByAttorney, requestDateString, requestDateTimeString } =
    useDataRoomRequest({
      dataRoomRequest,
    })

  const { first_name: firstName = '', last_name: lastName = '' } =
    dataRoomRequest.created_by_attorney ?? {}

  const handleGiveAccessClick = () => {
    onGiveAccess({
      fileAccessId: accessRequest.fileAccessId,
      targetUserName: `${firstName} ${lastName}`,
    })
  }

  const handleDeclineAccessClick = () => {
    onDenyAccess({
      fileAccessId: accessRequest.fileAccessId,
      hasAccess: false,
      targetUserName: `${firstName} ${lastName}`,
    })
  }

  return (
    <Grid container>
      <Grid item xs={10} sm={7} display="flex" alignItems="center">
        <ReferralAttorneyAvatar
          attorney={createdByAttorney}
          showOrganization
          fitToSingleLine
        />
      </Grid>
      {!isLessSm && (
        <Grid item xs={2} alignContent="center">
          <Typography variant="caption" color={theme.palette.text.disabled}>
            {isLessMd ? requestDateString : requestDateTimeString}
          </Typography>
        </Grid>
      )}
      <Grid item xs={2} sm={3} display="flex" justifyContent="flex-end" gap={1}>
        {isLessMd ? (
          <IconButton
            color="success"
            onClick={handleGiveAccessClick}
            sx={{
              background: theme.palette.common.white,
              borderColor: theme.palette.divider,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          >
            <Check />
          </IconButton>
        ) : (
          <Button variant="outlined" onClick={handleGiveAccessClick}>
            Give Access
          </Button>
        )}
        {isLessMd ? (
          <IconButton
            color="error"
            onClick={handleDeclineAccessClick}
            sx={{
              background: theme.palette.common.white,
              borderColor: theme.palette.divider,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          >
            <Close />
          </IconButton>
        ) : (
          <Button
            variant="text"
            color="error"
            onClick={handleDeclineAccessClick}
          >
            Decline
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

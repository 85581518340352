import { gql, useApolloClient } from '@apollo/client'
import { useCallback } from 'react'

import {
  useAttorneyByEmailLazyQuery,
  useUpdateAttorneyProfileMutation,
} from '@/gql/appApi'
import { useCreateUserMutation, useSignInMutation } from '@/gql/systemApi'

import { CONTACT_TYPES } from '@/constants'
import { isQualifiedMembership } from '@/utils/membership'
import { EventName, analyticsService } from '@/services/Analytics'
import { ParameterName, bootParamsService } from '@/services/BootParams'
import { Dayjs } from 'dayjs'
import {
  SessionKey,
  sessionStorageService,
} from '@/services/SessionStorage/SessionStorageService'
import useAttorneyProfileId from './useAttorneyProfileId'
import useAttorneyId from './useAttorneyId'
import useAttorneyData from './useIsAttorneyVetted'
import useAttorneyEmail from './useAttorneyEmail'
import useProfile from './useProfile'

const DEFAULT_ERROR =
  'There appears to be a problem with your submission. Please try again.'

export type CreateAccountInput = {
  email: string
  password: string
  firstName: string
  lastName: string
  tags?: string
}

type TUpdateProfileLicenseInput = {
  barNumber: string
  isActive: boolean
  isDisciplined: boolean
  isSued: boolean
  stateId: string
  malpracticeInsuranceId: string
  insuranceExpirationDate: string | Dayjs | null
}

type TUpdateProfileInput = {
  licenses: TUpdateProfileLicenseInput[]
  profileId: string
  lawFirmName?: string
  address?: string
  city?: string
  zipCode?: string
  companySizeId?: string
  caseManagementSoftwareId?: string
  relationshipId?: string
  yearsPracticingLawId?: string
  lawFirmStateId?: string
  phoneNumber: string
}

const useAuthentication = () => {
  const client = useApolloClient()
  const { setAttorneyProfileId } = useAttorneyProfileId()
  const { updateProfile: requestUpdateProfile } = useProfile()
  const { setAttorneyId } = useAttorneyId()
  const { setIsAttorneyVetted, setIsQualifiedAttorney } = useAttorneyData()
  const { setAttorneyEmail } = useAttorneyEmail()
  const onError = useCallback(() => {
    const errors = { base: DEFAULT_ERROR }
    return {
      baseErrors: errors,
    }
  }, [])

  const [signIn, { loading: signInLoading }] = useSignInMutation({
    onError,
  })

  const [createUser, { loading: createUserLoading }] = useCreateUserMutation({
    onError,
  })

  const [updateProfile, { loading: updateProfileLoading }] =
    useUpdateAttorneyProfileMutation()

  const [getAttorneyByEmail, { loading: attorneyByUserIdLoading }] =
    useAttorneyByEmailLazyQuery()

  const loading =
    signInLoading ||
    createUserLoading ||
    attorneyByUserIdLoading ||
    updateProfileLoading

  const getProfileId = useCallback(
    async (email: string) => {
      const emailLowerCase = email?.toLocaleLowerCase()
      const { data } = await getAttorneyByEmail({
        variables: {
          filter: {
            user_id: {
              email: {
                _eq: emailLowerCase,
              },
            },
          },
        },
      })
      if (data?.attorney?.[0].profiles?.[0]?.id) {
        setAttorneyProfileId(data.attorney[0].profiles[0].id)
        setAttorneyId(data.attorney[0].id)
        setIsAttorneyVetted(data.attorney[0].is_vetted || false)
        setIsQualifiedAttorney(
          isQualifiedMembership(data.attorney[0].membership_type?.id)
        )
        return data.attorney[0].profiles[0].id
      }
      return ''
    },
    [
      getAttorneyByEmail,
      setAttorneyProfileId,
      setAttorneyId,
      setIsAttorneyVetted,
      setIsQualifiedAttorney,
    ]
  )

  const signInAttorney = useCallback(
    async ({
      email,
      password,
      checkForEmailValidation,
    }: {
      email: string
      password: string
      checkForEmailValidation?: boolean
    }) => {
      const { data, errors } = await signIn({
        variables: { email: email?.toLocaleLowerCase(), password },
      })

      const token = data?.auth_login?.access_token
      const refreshToken = data?.auth_login?.refresh_token

      if (token) {
        setAttorneyEmail(email)

        sessionStorageService.setItem(SessionKey.TOKEN, `${token}`)
        sessionStorageService.setItem(
          SessionKey.REFRESH_TOKEN,
          `${refreshToken}`
        )

        const profileId = await getProfileId(email)

        if (!checkForEmailValidation) {
          // Update Apollo cache
          client.writeQuery({
            query: gql`
              query UpdateLoggedIn {
                isLoggedIn
              }
            `,
            data: {
              isLoggedIn: true,
            },
          })
        }

        return {
          success: true,
          profileId,
        }
      }
      if (errors) {
        return {
          signInErrors: {
            base: 'Login failed. Either the account does not exist, or the password is incorrect.',
            server: errors?.[0]?.message || 'Login failed. Please try again.',
          },
        }
      }
      return {}
    },
    [client, getProfileId, setAttorneyEmail, signIn]
  )

  const createAccount = useCallback(
    async (attorneyData: CreateAccountInput) => {
      const { data, errors } = await createUser({
        variables: {
          data: {
            email: attorneyData.email,
            password: attorneyData.password,
            first_name: attorneyData.firstName,
            last_name: attorneyData.lastName,
            ...(attorneyData?.tags && {
              tags: attorneyData.tags,
            }),
          },
        },
      })

      const userId = data?.create_users_item?.id

      if (userId) {
        analyticsService.track(EventName.MARKETPLACE_ACCOUNT_CREATED, {
          email_address: attorneyData.email,
        })

        const { success, profileId } = await signInAttorney({
          email: attorneyData.email,
          password: attorneyData.password,
        })

        if (success) {
          const refParam = bootParamsService.get(ParameterName.REF)

          if (typeof refParam === 'string') {
            await requestUpdateProfile({
              id: profileId,
              data: { referral_source: refParam },
            })
          }

          return {
            id: userId,
            profileId,
          }
        }
        sessionStorageService.removeItem(SessionKey.TOKEN)
        sessionStorageService.removeItem(SessionKey.REFRESH_TOKEN)
      }
      if (errors) {
        return {
          createAccountErrors: {
            base: 'Account creation failed. Please try again.',
            server:
              errors?.[0]?.message ||
              'Account creation failed. Please try again.',
          },
        }
      }
      return {}
    },
    [createUser, requestUpdateProfile, signInAttorney]
  )

  const updateAttorneyProfile = useCallback(
    async (attorneyData: TUpdateProfileInput) => {
      let options = {}

      if (attorneyData?.caseManagementSoftwareId) {
        options = {
          case_management_sofwares: [
            {
              case_management_software_id:
                attorneyData.caseManagementSoftwareId,
            },
          ],
        }
      }

      if (attorneyData?.lawFirmName) {
        options = {
          ...options,
          firms: [
            {
              name: attorneyData.lawFirmName,
              address: attorneyData.address,
              city: attorneyData.city,
              state: attorneyData.lawFirmStateId,
              zip: Number(attorneyData.zipCode),
              size: attorneyData.companySizeId,
              relationship: attorneyData.relationshipId,
            },
          ],
        }
      }

      if (attorneyData?.yearsPracticingLawId) {
        options = {
          ...options,
          years_practice: attorneyData.yearsPracticingLawId,
        }
      }

      const { data, errors } = await updateProfile({
        variables: {
          updateAttorneyProfileItemId: attorneyData.profileId,
          data: {
            licenses: attorneyData.licenses.map(license => ({
              bar_number: license.barNumber,
              has_been_disciplined_by_bar: license.isDisciplined,
              has_been_sued_by_malpractice: license.isSued,
              is_license_suspended: !license.isActive,
              license_state: license.stateId,
              insurances: [
                {
                  insurance_coverage_id: license.malpracticeInsuranceId,
                  expiration_date: license.insuranceExpirationDate,
                },
              ],
            })),
            contacts: [
              {
                type: CONTACT_TYPES.PhoneContactType,
                value: attorneyData.phoneNumber,
              },
            ],
            ...options,
          },
        },
      })
      if (data?.update_attorney_profile_item?.id) {
        return {
          id: data.update_attorney_profile_item.id,
        }
      }
      if (errors) {
        return {
          updateAttorneyProfileErrors: {
            base: 'Attorney profile update failed. Please try again.',
            server: errors[0]?.message,
          },
        }
      }
      return {}
    },
    [updateProfile]
  )

  return {
    createAccount,
    signInAttorney,
    getProfileId,
    updateAttorneyProfile,
    loading,
  }
}

export default useAuthentication

import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined'
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import { FC } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { useSizes } from '@/hooks/useSizes'
import { useChat } from '@/hooks/useChat'
import JUser from '@/components/atoms/JUser'
import { ProfileType } from '@/types'
import { RoutesData } from '@/router/RoutesData'
import useInviteToCase from '@/hooks/inviteToCase/useInviteToCase'
import { getPracticesAreas } from '@/utils/profile'
import { isQualifiedMembership } from '@/utils/membership'

interface IMyHiresCardProps {
  user: ProfileType
}

const MyHiresCard: FC<IMyHiresCardProps> = ({ user }) => {
  const { renderModal, openModal, setOpenModal } = useInviteToCase({
    prospectAttorneyId: user.attorney_id?.id || '',
    profile: user,
  })
  const { openChatForUser } = useChat()

  const navigate = useNavigate()

  const onClickProfile = () => {
    const path = generatePath(RoutesData.RProfile.path, {
      id: user?.attorney_id?.id,
    })
    navigate(path)
  }

  const { screenSize } = useSizes()
  const mobileLayout = screenSize !== 'desktop'
  const practiceAreas = user.practice_areas || []

  return (
    <Card className="MyHiresCard">
      <CardContent>
        <Box
          sx={theme => ({
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
              gap: 2,
            },
          })}
        >
          <Box
            display="flex"
            sx={{
              paddingRight: 2,
            }}
          >
            <JUser
              size="xLarge"
              avatar={user.avatar?.id}
              mainText={`${user.first_name} ${user.last_name}`}
              secondaryText={user?.firms?.[0]?.name}
              tertiaryText={getPracticesAreas(practiceAreas)}
              onClick={onClickProfile}
              isVetted={user?.attorney_id?.is_vetted}
              isQualified={isQualifiedMembership(
                user?.attorney_id?.membership_type?.id
              )}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexShrink: 0,
              gap: 2.5,
              justifyContent: 'center',
            }}
          >
            {mobileLayout ? (
              <>
                <Button
                  size="large"
                  startIcon={<CommentOutlinedIcon />}
                  variant="outlined"
                  onClick={() => {
                    openChatForUser(user?.attorney_id?.user_id?.id ?? '')
                  }}
                >
                  Send Message
                </Button>
                <Button
                  size="large"
                  startIcon={<HandshakeOutlinedIcon />}
                  variant="contained"
                  onClick={() => setOpenModal(true)}
                >
                  Invite to Case
                </Button>
              </>
            ) : (
              <>
                <Button
                  size="large"
                  startIcon={<HandshakeOutlinedIcon />}
                  variant="contained"
                  onClick={() => setOpenModal(true)}
                >
                  Invite to Case
                </Button>
                <Button
                  startIcon={<CommentOutlinedIcon />}
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    openChatForUser(user?.attorney_id?.user_id?.id ?? '')
                  }}
                >
                  Send Message
                </Button>
              </>
            )}
          </Box>
        </Box>
      </CardContent>
      {openModal && renderModal()}
    </Card>
  )
}

export default MyHiresCard

import React from 'react'
import { Typography, Avatar, Box } from '@mui/material'
import { styled } from '@mui/system'
import { useCaseContext } from '../../hooks/useCaseContext'
import { NegotiationHistoryItem } from '../../types'
import NegotiationHistorySkeleton from './skeleton'

const HistoryContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}))

const HistoryItem = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const ItemContent = styled(Box)({
  flexGrow: 1,
  marginLeft: '16px',
})

const Percentage = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginLeft: 'auto',
  color: theme.palette.text.primary,
}))

const formatDate = (dateString: string) => {
  const date = new Date(dateString)
  const formattedDate = date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
  })
  return `${formattedDate} - ${formattedTime}`
}

const NegotiationHistory: React.FC = () => {
  const { caseData, loading } = useCaseContext()

  if (loading) return <NegotiationHistorySkeleton />

  if (!caseData) return null

  const { negotiation_history } = caseData

  if (!negotiation_history || !negotiation_history.length) return null

  return (
    <HistoryContainer>
      <Typography variant="subtitle2" color="text.primary">
        Negotiation History
      </Typography>
      {caseData.negotiation_history.map((item: NegotiationHistoryItem) => (
        <HistoryItem key={item.id}>
          <Avatar
            src={item.created_by.avatar_image || ''}
            alt={item.created_by.name}
          />
          <ItemContent>
            <Typography
              variant="body2"
              color="text.disabled"
              sx={{ fontSize: 12 }}
            >
              {formatDate(item.created_ts)}
            </Typography>
            <Typography variant="body2">{item.activity_label}</Typography>
          </ItemContent>
          <Percentage variant="body1">{item.fee}%</Percentage>
        </HistoryItem>
      ))}
    </HistoryContainer>
  )
}

export default NegotiationHistory

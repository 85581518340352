import { useForm, useFieldArray } from 'react-hook-form'

import { INVITATION_STATUS } from '@/constants'
import {
  useBulkInviteAttorneyMutation,
  GetInvitationsSentDocument,
  useGetRoleInviteCatalogQuery,
} from '@/gql/appApi'
import { useSizes } from '@/hooks/useSizes'
import useOrganizationId from '@/hooks/organization/useOrganizationId'

export interface UseInviteAttorneyModalProps {
  onSubmit: () => void
}

export type InvitationType = {
  email: string | null
  role: string
}

const useInviteAttorneyModal = ({ onSubmit }: UseInviteAttorneyModalProps) => {
  const { id, loading: orgQueryLoading } = useOrganizationId()
  const { data, loading: loadingRoles } = useGetRoleInviteCatalogQuery()
  const [bulkInviteAttorneyMutation, { loading: bulkLoading }] =
    useBulkInviteAttorneyMutation({
      refetchQueries: [GetInvitationsSentDocument],
    })

  const { isLessMd } = useSizes()

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{
    attorneys: InvitationType[]
  }>({
    defaultValues: {
      attorneys: isLessMd
        ? [
            { email: '', role: '' },
            { email: '', role: '' },
            { email: '', role: '' },
          ]
        : [
            { email: '', role: '' },
            { email: '', role: '' },
            { email: '', role: '' },
            { email: '', role: '' },
            { email: '', role: '' },
          ],
    },
    mode: 'onBlur',
  })
  const { fields, append } = useFieldArray({
    name: 'attorneys',
    control,
  })

  const onSendInvitation = async (form: { attorneys: InvitationType[] }) => {
    const payload: {
      email: string | undefined
      invite_status_id: string
      target_organization_id: string | undefined
      target_role_id: string
    }[] = []
    form.attorneys.forEach(att => {
      if (att.email !== '') {
        const newInvitation = {
          email: att.email?.toLowerCase(),
          invite_status_id: INVITATION_STATUS.Sent,
          target_organization_id: id,
          target_role_id: att.role,
        }
        payload.push(newInvitation)
      }
    })

    if (payload.length === 0) {
      return
    }

    await bulkInviteAttorneyMutation({
      variables: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        data: payload,
      },
    })
    onSubmit()
  }

  return {
    handleSubmit,
    onSendInvitation,
    loading: orgQueryLoading || bulkLoading || loadingRoles,
    fields,
    register,
    control,
    append,
    isLessMd,
    roles: data?.catalog_role_invite || [],
    watch,
    errors,
  }
}

export default useInviteAttorneyModal

import ErrorMessage from '@/components/Messagges/ErrorMessage'
import { ModalTypes } from '@/types'
import ConfirmationModal, { ConfirmationModalProps } from '../ConfirmationModal'
import useClientSignedConfirmationModal from './useClientSignedConfirmationModal'

export interface ClientSignedConfirmationModalProps
  extends Omit<ConfirmationModalProps, 'title' | 'description'> {
  onSubmitResponse: ({ responseType }: { responseType: ModalTypes }) => void
  casePurchaseId: string
  onBehalfOfAttorneyId: string
  fee: number
}

const ClientSignedConfirmationModal = ({
  AcceptButtonProps,
  onSubmitResponse,
  casePurchaseId,
  onBehalfOfAttorneyId,
  fee,
  ...props
}: ClientSignedConfirmationModalProps) => {
  const { onSignedConfirmation, loading, error } =
    useClientSignedConfirmationModal({
      onSubmit: onSubmitResponse,
      casePurchaseId,
      onBehalfOfAttorneyId,
      fee,
    })

  const title = 'Client Signed Confirmation'

  const description = 'Please confirm you successfully signed the client.'

  return (
    <ConfirmationModal
      title={title}
      description={description}
      AcceptButtonProps={{
        children: 'Confirm',
        onClick: onSignedConfirmation,
        disabled: loading,
        ...AcceptButtonProps,
      }}
      {...props}
    >
      {error && (
        <ErrorMessage
          title="There was a problem submitting your request"
          content={error}
          sx={{
            maxWidth: undefined,
            m: 'auto',
            width: '100%',
            '& .MuiAlert-root': {
              alignItems: 'flex-start',
            },
          }}
        />
      )}
    </ConfirmationModal>
  )
}

export default ClientSignedConfirmationModal

import { useCallback, useRef, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'

import {
  GetUserFileAccessRequestDocument,
  useCreateBulkFileAccessRequestMutation,
} from '@/gql/appApi'
import { CASE_PURCHASE_STATUS, NEW_DATAROOM_ENABLED } from '@/constants'
import useAttorneyId from '@/hooks/useAttorneyId'
import { JCaseType } from '@/types'
import { useUsersMeQuery } from '@/gql/systemApi'
import setAuthToken from '@/axios/setAuthToken'
import { useAxios } from '@/axios/config'
import { AxiosError } from 'axios'
import {
  SessionKey,
  sessionStorageService,
} from '@/services/SessionStorage/SessionStorageService'

export interface UseBulkInviteToCaseModalProps {
  onSubmit: () => void
  jCase?: JCaseType
  onBehalfOf?: string
  onBehalfOfUserId?: string
}

interface TAttorneyForm {
  attorneys: {
    // NOTE: This is actually `Attorney_Profile | null`, but `any` is used
    // because of poor type checking performance for `Attorney_Profile.`
    value: any | null
    allowAccessDataRoom: boolean
  }[]
}

const useBulkInviteToCaseModal = ({
  onSubmit,
  jCase,
  onBehalfOf,
  onBehalfOfUserId,
}: UseBulkInviteToCaseModalProps) => {
  const { data: userMe } = useUsersMeQuery()
  const userId = userMe?.users_me?.id

  const [{ loading: bulkCasePurchaseLoading }, createCasePurchase] = useAxios({
    url: '/casepurchase',
    method: 'POST',
  })

  const [
    bulkCreateFileAccessRequestMutation,
    { loading: bulkFileRequestLoading },
  ] = useCreateBulkFileAccessRequestMutation({
    refetchQueries: [GetUserFileAccessRequestDocument],
  })

  const [error, setError] = useState('')
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = useRef(getAttorneyId())
  const { register, control, handleSubmit } = useForm<TAttorneyForm>({
    defaultValues: {
      attorneys: [{}],
    },
  })
  const { fields, append } = useFieldArray({
    name: 'attorneys',
    control,
  })

  const onSendInvitation = useCallback(
    async (form: TAttorneyForm) => {
      setError('')

      const payload: {
        prospect_attorney_id: string
        case_id: string | undefined
        status: string
        created_by: string | null
      }[] = []
      form.attorneys.forEach(att => {
        if (att.value && att?.value?.attorney_id?.id) {
          const newItem = {
            prospect_attorney_id: att.value.attorney_id.id,
            case_id: jCase?.id,
            status: CASE_PURCHASE_STATUS.Proposed,
            created_by: onBehalfOf || attorneyId.current,
          }
          payload.push(newItem)
        }
      })

      if (payload.length === 0) {
        return
      }

      try {
        const token = sessionStorageService.getItem(SessionKey.TOKEN)
        setAuthToken(token)
        const result = await createCasePurchase({ data: payload })

        if (result?.data.data?.create_case_purchase_items?.[0]?.id) {
          if (!NEW_DATAROOM_ENABLED) {
            onSubmit()
            return
          }

          const fileAccessRequests = form.attorneys.filter(
            item => item.allowAccessDataRoom
          )

          const fileAccessRequestPayload = fileAccessRequests.map(item => ({
            attorney_id: item.value?.attorney_id?.id,
            requested_by_user_id: onBehalfOfUserId || userId,
            requested_case_id: {
              id: jCase?.id,
            },
          }))

          if (fileAccessRequestPayload.length === 0) {
            onSubmit()
            return
          }

          const response = await bulkCreateFileAccessRequestMutation({
            variables: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              data: fileAccessRequestPayload,
            },
          })

          if (response?.data?.create_user_file_access_request_items?.[0]?.id) {
            onSubmit()
            return
          }

          if (response?.errors) {
            setError(
              response.errors?.[0]?.message ||
                'There was a problem granting access to the dataroom. Please try again later.'
            )
          }
        }
      } catch (err) {
        let message =
          'There was a problem submitting your request. Please try again later.'

        if (err instanceof AxiosError && err.response?.status === 409) {
          message =
            "There's already an existing negotiation for one or more specified attorneys for this case."
        }

        setError(message)
      }
    },
    [
      createCasePurchase,
      bulkCreateFileAccessRequestMutation,
      jCase?.id,
      onSubmit,
      userId,
      onBehalfOf,
      onBehalfOfUserId,
    ]
  )

  return {
    handleSubmit,
    onSendInvitation,
    loading: bulkCasePurchaseLoading || bulkFileRequestLoading,
    fields,
    register,
    control,
    append,
    error,
  }
}

export default useBulkInviteToCaseModal

import { useMemo } from 'react'
import {
  useGetOrganizationWaterfallListsQuery,
  useGetWaterfallListsQuery,
} from '@/gql/appApi'
import { useGetOrgWaterfallConfigQuery } from '@/gql/systemApi'
import useAttorneyId from '@/hooks/useAttorneyId'
import { getImagePath } from '@/utils'
import { isQualifiedMembership } from '@/utils/membership'
import { IMAGE_SIZES } from '@/constants'
import { CreateCaseWaterfallList } from './types'

const useMarketplaceVisibilityV2 = () => {
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = getAttorneyId()

  const { data: waterfallConfig, loading: loadingWaterfallConfig } =
    useGetOrgWaterfallConfigQuery({
      fetchPolicy: 'network-only',
    })

  const orgId =
    waterfallConfig?.users_me?.current_organization?.[0]?.organization_id?.id ??
    ''

  const hasPermissionToCreateWaterfalls =
    waterfallConfig?.users_me?.current_organization?.[0]?.can_create_wf_lists ??
    false

  const { data: attorneyWaterfallData, loading: loadingWaterfalls } =
    useGetWaterfallListsQuery({
      variables: {
        filter: {
          attorney_id: {
            id: {
              _eq: attorneyId,
            },
          },
        },
        sort: '-created_ts',
        itemsSort: 'list_order',
      },
      fetchPolicy: 'network-only',
      skip: !attorneyId,
    })

  const { data: orgWaterfallListData, loading: loadingOrgWaterfallList } =
    useGetOrganizationWaterfallListsQuery({
      variables: {
        filter: {
          organization_id: {
            id: {
              _eq: orgId,
            },
          },
        },
        sort: '-created_ts',
        itemsSort: 'list_order',
      },
      fetchPolicy: 'network-only',
      skip: !orgId,
    })

  const waterfallLists = useMemo(() => {
    if (
      !attorneyWaterfallData?.attorney_waterfall_list &&
      !orgWaterfallListData?.organization_waterfall_list
    )
      return {
        orgWaterfalls: [],
        attorneyWaterfalls: [],
      }

    const orgWaterfalls: CreateCaseWaterfallList[] =
      orgWaterfallListData?.organization_waterfall_list
        ?.filter(waterfall => waterfall?.is_visible)
        .map(waterfall => {
          const waterfallMembers =
            waterfall?.items?.map(item => ({
              attorneyId: item?.attorney_id?.id ?? '',
              attorneyName: `${item?.attorney_id?.profiles?.[0]?.first_name} ${item?.attorney_id?.profiles?.[0]?.last_name}`,
              attorneyOrganization:
                item?.attorney_id?.user_id?.current_organization?.[0]
                  ?.organization_id?.profiles?.[0]?.name ?? '',
              avatar: getImagePath({
                id: item?.attorney_id?.user_id?.avatar?.id ?? null,
                size: IMAGE_SIZES.Small,
              }),
              isVetted: item?.attorney_id?.is_vetted ?? false,
              isQualified:
                isQualifiedMembership(item?.attorney_id?.membership_type?.id) ??
                false,
            })) ?? []
          return {
            waterfallId: waterfall.id ?? '',
            waterfallName: waterfall.name ?? '',
            waterfallMembers,
            waterfallJurisdiction: waterfall?.jurisdiction_id?.name ?? '',
          }
        }) ?? []

    const attorneyWaterfalls: CreateCaseWaterfallList[] =
      attorneyWaterfallData?.attorney_waterfall_list?.map(waterfall => {
        const waterfallMembers =
          waterfall?.items?.map(item => ({
            attorneyId: item?.attorney_id?.id ?? '',
            attorneyName: `${item?.attorney_id?.profiles?.[0]?.first_name} ${item?.attorney_id?.profiles?.[0]?.last_name}`,
            attorneyOrganization:
              item?.attorney_id?.user_id?.current_organization?.[0]
                ?.organization_id?.profiles?.[0]?.name ?? '',
            avatar: getImagePath({
              id: item?.attorney_id?.user_id?.avatar?.id ?? null,
              size: IMAGE_SIZES.XSmall,
            }),
            isVetted: item?.attorney_id?.is_vetted ?? false,
            isQualified:
              isQualifiedMembership(item?.attorney_id?.membership_type?.id) ??
              false,
          })) ?? []

        return {
          waterfallId: waterfall.id ?? '',
          waterfallName: waterfall.name ?? '',
          waterfallMembers,
        }
      }) ?? []

    return {
      orgWaterfalls,
      attorneyWaterfalls,
    }
  }, [
    attorneyWaterfallData?.attorney_waterfall_list,
    orgWaterfallListData?.organization_waterfall_list,
  ])

  // If hasPermissionToCreateWaterfalls is true, and there is at least one waterfall list, then marketplaceVisibilityEnabled is true
  const marketplaceVisibilityDisabled =
    !hasPermissionToCreateWaterfalls &&
    waterfallLists.orgWaterfalls.length === 0

  const loading =
    loadingWaterfalls || loadingOrgWaterfallList || loadingWaterfallConfig

  return {
    hasPermissionToCreateWaterfalls,
    loading,
    marketplaceVisibilityDisabled,
    orgId,
    waterfallLists,
  }
}

export default useMarketplaceVisibilityV2

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'

import { refreshTokenMutation } from '@/gql/system/authentication/authMutations.systemGraphql'
import {
  SessionKey,
  sessionStorageService,
} from '@/services/SessionStorage/SessionStorageService'

let apolloClient: ApolloClient<NormalizedCacheObject> | null

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return !!sessionStorageService.getItem(SessionKey.TOKEN)
          },
        },
      },
    },
  },
})

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_API_URL,
})

const httpAuthLink = createHttpLink({
  uri: `${import.meta.env.VITE_API_URL}/system`,
})

const authLink = setContext((_, { headers }) => {
  // Get the token from sessionStorageService if available.
  const token = sessionStorageService.getItem(SessionKey.TOKEN) || ''

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

// Create an instance of Apollo Client for refresh token requests
const clientForRefreshToken = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpAuthLink,
  connectToDevTools: process.env.NODE_ENV === 'development',
})

let isRefreshing = false
let pendingRequests: any[] = []

const resolvePendingRequests = (newToken: string) => {
  pendingRequests.map(callback => callback(newToken))
  pendingRequests = []
}

const refreshToken = async () => {
  if (!isRefreshing) {
    isRefreshing = true

    const storedRefreshToken = sessionStorageService.getItem(
      SessionKey.REFRESH_TOKEN
    )
    try {
      const { data } = await clientForRefreshToken.mutate({
        mutation: refreshTokenMutation,
        variables: { refreshToken: storedRefreshToken },
      })

      const newAccessToken = data?.auth_refresh?.access_token
      const newRefreshToken = data?.auth_refresh?.refresh_token
      sessionStorageService.setItem(SessionKey.TOKEN, newAccessToken)
      sessionStorageService.setItem(SessionKey.REFRESH_TOKEN, newRefreshToken)

      resolvePendingRequests(newAccessToken)

      isRefreshing = false
      return newAccessToken
    } catch (error) {
      sessionStorageService.clear()
      isRefreshing = false
      window.location.reload()
      throw error
    }
  } else {
    // This will help resolve any other requests that failed due to the expired token,
    // but only after the token has been refreshed, instead of all requests trying to
    // refresh the token at the same time.
    return new Promise<string>(resolve => {
      pendingRequests.push((newToken: string) => resolve(newToken))
    })
  }
}

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err.extensions.code === 'TOKEN_EXPIRED') {
          return new Observable(observer => {
            refreshToken()
              .then(newAccessToken => {
                operation.setContext(({ headers }: any) => ({
                  headers: {
                    ...headers,
                    Authorization: `Bearer ${newAccessToken}`,
                  },
                }))
                forward(operation).subscribe(observer)
              })
              .catch(_err => {
                observer.error.bind(observer)
              })
          })
        }
      }
    }

    if (networkError) {
      // eslint-disable-next-line no-console
      console.log(`[Network error]: ${networkError}`)
    }
  }
)

// Docs: https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
const MAX_RETRY_ATTEMPTS = 5
const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    // This retry is implemented for the AttorneyByEmail query since it is an
    // important query in the create account flow and there is a very small chance
    // it requires a retry due to multiple updates that may have not finished in the BE.
    if (operation.operationName === 'AttorneyByEmail') {
      // This second condition is to retry the request with a new token since the first
      // one might be invalid.
      if (!!error && count === 2) {
        return refreshToken()
          .then(newToken => !!newToken && count < MAX_RETRY_ATTEMPTS)
          .catch(() => false)
      }
      return !!error && count < MAX_RETRY_ATTEMPTS
    }
    return false
  },
  delay: {
    initial: 500,
    max: 3000,
    jitter: true,
  },
})

const link = from([
  errorLink,
  retryLink,
  split(
    definition => definition.getContext().clientName === 'auth',
    // For authentication related requests or anything on the */graphql/system endpoint (e.g. login, createAccount)
    authLink.concat(httpAuthLink),
    // For regular requests to */graphql
    authLink.concat(httpLink)
  ),
])

const createApolloClient = () =>
  new ApolloClient({
    cache,
    link,
    connectToDevTools: process.env.NODE_ENV === 'development',
  })

const initApollo = () => {
  const apolloClientInstance = apolloClient ?? createApolloClient()

  if (!apolloClient) {
    apolloClient = apolloClientInstance
  }

  return apolloClientInstance
}

export default initApollo

import { ReactNode } from 'react'
import { useSizes } from '@/hooks/useSizes'
import { alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export type FeeVariant = 'contained' | 'outlined' | 'secondary'

export interface NegotationFeeProps {
  fee: number
  referralDateText?: string | ReactNode
  feeText?: string
}

const NegotationFee = ({
  fee,
  referralDateText,
  feeText,
}: NegotationFeeProps) => {
  const { isLessMd } = useSizes()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'row-reverse', md: 'row' },
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Box
        sx={theme => ({
          [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
          },
        })}
      >
        <Typography
          variant="caption"
          sx={{
            mr: {
              xs: 0,
              md: 1,
            },
            color: theme => alpha(theme.palette.common.black, 0.6),
          }}
        >
          {feeText}
        </Typography>
        {isLessMd &&
          referralDateText &&
          (typeof referralDateText === 'string' ? (
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{
                color: theme => alpha(theme.palette.common.black, 0.4),
              }}
            >
              {referralDateText}
            </Typography>
          ) : (
            referralDateText
          ))}
      </Box>
      <Typography
        variant="h5"
        sx={{
          mr: {
            xs: 1,
            md: 0,
          },
        }}
      >
        {fee}%
      </Typography>
    </Box>
  )
}

export default NegotationFee

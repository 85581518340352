import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { ConfirmationModal, ResponseModal } from '@/components'
import ListRequestAccess from './ListRequestAccess'
import useAccessRequests from './hooks/useAccessRequests'

type TAccessRequestsProps = {
  caseId: string
}

const AccessRequests = ({ caseId }: TAccessRequestsProps) => {
  const {
    modal,
    responseModal,
    onGiveAccess,
    onDenyAccess,
    onCloseModal,
    onSubmitModal,
    onCloseResponseModal,
    pendingRequests,
    loading,
    error,
    setError,
  } = useAccessRequests({
    caseId,
  })

  return (
    <Box
      sx={{ display: 'flex', gap: 5, flexDirection: 'column', width: '100%' }}
    >
      {error?.message && (
        <Alert severity="error" onClose={() => setError(null)}>
          {error.message}
        </Alert>
      )}
      {pendingRequests.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 2,
          }}
        >
          <Typography variant="subtitle1">Access Requests Received</Typography>
          <ListRequestAccess
            requests={pendingRequests}
            onGiveAccess={onGiveAccess}
            onDenyAccess={onDenyAccess}
            loading={loading}
          />
        </Box>
      )}
      {modal.open && (
        <ConfirmationModal
          open={modal.open}
          title={modal.title as string}
          onClose={onCloseModal}
          AcceptButtonProps={{
            children: modal.buttonText,
            onClick: onSubmitModal,
          }}
        >
          {modal.description}
        </ConfirmationModal>
      )}
      {responseModal.open && (
        <ResponseModal
          open={responseModal.open}
          title={responseModal.title}
          bodyContent={responseModal.bodyContent}
          onClose={onCloseResponseModal}
        />
      )}
    </Box>
  )
}

export default AccessRequests

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import HighFive from '@/assets/Giving-High-Five.svg'
import LogoBar from '@/pages/CreateAccount/shared/LogoBar'

import { useSizes } from '@/hooks/useSizes'
import { Alert as MuiAlert, styled } from '@mui/material'
import { PROMO_CODE } from '@/constants'

type MembershipInfoProps = {
  onSubmit: (() => Promise<void>) | (() => void)
  loading?: boolean
  title?: string
  submitTitle?: string
  secondaryBtnTitle?: string
  onSecondaryBtnClick: (() => Promise<void>) | (() => void)
}

const PromoAlert = styled(MuiAlert)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
  color: theme.palette.success.dark,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  textAlign: 'left',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: 'fit-content',
  },
}))

const MembershipInfo = ({
  onSubmit,
  loading,
  title = 'Become a JHQ Vetted Attorney',
  submitTitle = 'Get JHQ Vetted Membership',
  secondaryBtnTitle = 'Continue as Standard Member',
  onSecondaryBtnClick,
}: MembershipInfoProps) => {
  const { isLessMd } = useSizes()

  return (
    <Box
      sx={theme => ({
        paddingRight: { xs: 0, md: 2 },
        pb: { md: 5 },
        height: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        [theme.breakpoints.down('md')]: {
          paddingBottom: theme.spacing(6),
        },
      })}
    >
      <LogoBar />
      <Box
        sx={{
          px: { xs: 2.5, md: 0 },
          pb: { xs: 10, md: 0 },
        }}
      >
        <Typography
          sx={{
            textAlign: 'left',
            marginBottom: 5,
          }}
          variant={isLessMd ? 'h5' : 'h3'}
        >
          {title}
        </Typography>
        <Typography sx={{ textAlign: 'left' }} variant="body1">
          To unlock all the functionalities and submit proposals to public cases
          in the marketplace, you must get a JHQ Vetted Membership.
        </Typography>
        <PromoAlert
          severity="success"
          sx={{
            height: { md: '200px' },
            mt: 6,
          }}
          iconMapping={{
            // eslint-disable-next-line react/jsx-no-useless-fragment
            success: <></>,
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                display: { xs: 'flex' },
                justifyContent: { xs: 'center', md: 'flex-start' },
                alignItems: 'center',
              }}
            >
              <img alt="logo" src={HighFive} />
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography variant="body2" sx={{ pl: { md: 2 } }}>
                As a valued member of the JHQ Community, we have a special
                present for you. Add the following promo code in the payment
                form and get <b>Free access through April 30, 2024</b>
                <br />
                <br />
                <b>{PROMO_CODE.SIX_MONTHS_FREE}</b>
              </Typography>
            </Grid>
          </Grid>
        </PromoAlert>
      </Box>
      <Box sx={{ mt: 6, mb: 2 }}>
        <Box
          sx={{
            position: { xs: 'fixed', md: 'relative' },
            bottom: 0,
            left: 0,
            boxShadow: {
              xs: '0 -10px 10px -8px rgba(0, 0, 0, 0.2)',
              md: 'none',
            },
            backgroundColor: theme => ({
              xs: theme.palette.background.page,
              md: 'transparent',
            }),
            zIndex: { xs: 1, md: 'auto' },
            padding: { xs: 1.5, md: 0 },
            width: { xs: '100%', md: 'auto' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              justifyContent: { xs: 'space-between' },
            }}
          >
            <Button
              disabled={loading}
              variant="text"
              onClick={onSecondaryBtnClick}
              size={isLessMd ? 'medium' : 'xlarge'}
              fullWidth
            >
              {secondaryBtnTitle}
            </Button>
            <Button
              size={isLessMd ? 'medium' : 'xlarge'}
              disabled={loading}
              variant="contained"
              fullWidth
              onClick={onSubmit}
            >
              {submitTitle}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default MembershipInfo

import { styled } from '@mui/material/styles'
import MUIDrawer from '@mui/material/Drawer'

import { theme } from '@/styles/theme'

const Drawer = styled(MUIDrawer)`
  .MuiPaper-root {
    height: 100%;
    width: 100%;

    ${theme.breakpoints.up('sm')} {
      max-width: 720px;
      width: 50%;
    }
  }
`

const DrawerContent = styled('div')`
  height: 100%;
  width: 100%;
`

export { Drawer, DrawerContent }

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

import { ATTORNEYS_ROLES_BY_ID, INVITATION_STATUS } from '@/constants'
import Button from '@mui/material/Button'

export interface MobileTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
  onSendInvitation: (id: string) => void
  onDeleteInvitation: (id: string) => void
}

const MobileTable = ({
  data,
  onSendInvitation,
  onDeleteInvitation,
}: MobileTableProps) => (
  <Box
    sx={{ display: 'flex', flexDirection: 'column', height: '100%', mb: 16 }}
  >
    {data.map(d => {
      const isExpired = d.invite_status_id.id === INVITATION_STATUS.Expired
      return (
        <Box key={d.id}>
          <Box sx={{ padding: theme => theme.spacing(2, 0, 2, 0) }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Box>
                  <Typography
                    variant="body2"
                    {...(isExpired && { color: 'text.disabled' })}
                  >
                    {d.email}
                  </Typography>
                  <Typography
                    variant="caption"
                    {...(isExpired && { color: 'text.disabled' })}
                  >
                    {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      ATTORNEYS_ROLES_BY_ID[d.target_role_id.id]
                    }
                  </Typography>
                </Box>
                {isExpired && (
                  <Typography
                    variant="overline"
                    sx={{ mt: 0.5 }}
                    color="text.disabled"
                  >
                    Expired
                  </Typography>
                )}
              </Box>
              <IconButton onClick={() => onDeleteInvitation(d.id)}>
                <DeleteOutlinedIcon sx={{ color: 'error.main' }} />
              </IconButton>
            </Box>
            {isExpired && (
              <Button
                variant="outlined"
                size="large"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => onSendInvitation(d.id)}
              >
                Resend Invitation
              </Button>
            )}
          </Box>
          <Divider />
        </Box>
      )
    })}
  </Box>
)

export default MobileTable

export enum AlgoliaFacetLabels {
  catalog_practice_area = 'Case Type',
  catalog_us_state_name = 'State',
  catalog_process_stage = 'Process Stage',
}

export interface AlgoliaItem {
  label: string
  value: string
  count: number
  isRefined: boolean
}

export type AlgoliaRouteRefinementList = { [key: string]: string[] }

export interface AlgoliaRouteState {
  query?: string | undefined
  page?: number | undefined
  hitsPerPage?: number | undefined
  [key: string]: any
}

export type MarketplaceFilterAttribute = keyof typeof AlgoliaFacetLabels
export type MarketplaceFilter = {
  attribute: MarketplaceFilterAttribute
  displayName: string
  showOnDesktop: boolean
  showInModal: boolean
  showOnMobile: boolean
  renderOption?: (option: { label: string }) => JSX.Element
}

import { styled } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 'none',
  '&& .MuiDataGrid-selectedRowCount': {
    display: 'none',
  },
  '&& .MuiTablePagination-root': {
    marginLeft: 'auto',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    ...theme.typography.subtitle2,
    marginRight: theme.spacing(1),
  },
  '& .MuiDataGrid-cell:focus': {
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },
}))

import { useState, useEffect, useContext, useCallback } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { useChat } from '@/hooks/useChat'
import { useStreamChat } from '@/hooks/useStreamChat'
import { useChatClient, ChannelOptions } from '@/context/ChatClientContext'
import { CaseChatContext } from '@/providers/CaseChatProvider'
import { Event } from 'stream-chat'
import { StyledBadge } from './StyledBadge'

export const useCaseMessageTrigger = () => {
  const { openCaseChat } = useChat()
  const { client } = useChatClient()
  const {
    getChannelById,
    getHasMessagesByCaseId,
    getUnreadMessagesByCaseId,
    setCurrentCaseChatId,
    setMessageDrawerOpen,
    setSelectedTab,
  } = useStreamChat()
  const {
    caseId,
    members = [],
    caseTitle,
    isCaseOwner,
    isCasePage,
  } = useContext(CaseChatContext)

  const [hasMessages, setHasMessages] = useState<boolean | undefined>(undefined)
  const [unreadMessages, setTotalUnreadMessages] = useState(0)
  const [messageDataLoaded, setMessageDataLoaded] = useState(false)

  const getMessageData = useCallback(
    async (id: string) => {
      const [unreadMessageCount, channelHasMessages] = await Promise.all([
        getUnreadMessagesByCaseId(id),
        getHasMessagesByCaseId(id),
      ])

      return { unreadMessageCount, channelHasMessages }
    },
    [getHasMessagesByCaseId, getUnreadMessagesByCaseId]
  )

  useEffect(() => {
    let isMounted = true

    if (!isCasePage) {
      setMessageDataLoaded(true)
      return () => {
        isMounted = false
      }
    }

    const fetchInitialData = async () => {
      if (!caseId) return
      if (hasMessages) return
      if (unreadMessages) return

      const { unreadMessageCount, channelHasMessages } = await getMessageData(
        caseId
      )

      if (isMounted) {
        setTotalUnreadMessages(unreadMessageCount)
        setHasMessages(channelHasMessages)
        setMessageDataLoaded(true)
      }
    }

    fetchInitialData()

    return () => {
      isMounted = false
    }
  }, [caseId, hasMessages, unreadMessages, isCasePage, getMessageData])

  useEffect(() => {
    if (!isCasePage) return () => {}

    const handleNewMessage = async (event: Event) => {
      const { channel_id: channelId } = event
      if (!channelId) return
      const channel = await getChannelById(channelId)
      if (channel?.data?.case_id === caseId) {
        const { unreadMessageCount, channelHasMessages } = await getMessageData(
          caseId
        )

        setTotalUnreadMessages(unreadMessageCount)
        setHasMessages(channelHasMessages)
        setMessageDataLoaded(true)
      }
    }

    const handleReadMessage = async (event: Event) => {
      const { channel } = event
      if (channel?.case_id === caseId) {
        const unreadMessageCount = await getUnreadMessagesByCaseId(caseId)

        setTotalUnreadMessages(unreadMessageCount)
      }
    }

    client?.on('notification.message_new', handleNewMessage)
    client?.on('message.new', handleNewMessage)

    client?.on('notification.mark_read', handleReadMessage)
    client?.on('notification.mark_unread', handleNewMessage)

    return () => {
      client?.off('notification.message_new', handleNewMessage)
      client?.off('message.new', handleNewMessage)

      client?.off('notification.mark_read', handleReadMessage)
      client?.off('notification.mark_unread', handleNewMessage)
    }
  }, [
    caseId,
    client,
    getChannelById,
    getHasMessagesByCaseId,
    getUnreadMessagesByCaseId,
    isCasePage,
    getMessageData,
  ])

  const handleTriggerClick = ({ membersToMessage = members } = {}) => {
    if (isCasePage) {
      if (isCaseOwner && hasMessages) {
        // If on case page and case owner, open drawer with case id section expanded
        setSelectedTab(ChannelOptions.CaseMessages)
        setCurrentCaseChatId(caseId)
        setMessageDrawerOpen(true)
        return
      }
      if (!isCaseOwner) {
        // If on case page and not case owner, open chat with case id
        openCaseChat({
          caseId,
          members: membersToMessage,
          caseTitle,
        })
      }
    } else {
      // If not on case page, open chat with case id
      openCaseChat({
        caseId,
        members: membersToMessage,
        caseTitle,
      })
    }
  }

  const loading =
    !messageDataLoaded || (hasMessages === undefined && isCasePage)
  const disabled = isCasePage && isCaseOwner && !hasMessages && loading

  const triggerContent = () => {
    if (loading) {
      return <CircularProgress size={16} />
    }

    if (
      (isCasePage && isCaseOwner) ||
      (!loading && hasMessages && isCasePage)
    ) {
      return (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          {unreadMessages ? (
            <StyledBadge
              badgeContent={unreadMessages}
              color="secondary"
              variant="rounded"
            />
          ) : null}
          <span>Case Messages</span>
        </Box>
      )
    }
    return 'Send Message'
  }

  return {
    disabled,
    triggerContent,
    handleTriggerClick,
    isCasePage,
    isCaseOwner,
    hasMessages,
    loading,
  }
}

import {
  useDeleteCaseOwnershipItemMutation,
  useDeleteCasePurchaseOwnershipItemMutation,
  GetActivityPurchaseDocument,
  GetCasesDocument,
  GetActivityPurchaseFromMyOrgDocument,
} from '@/gql/appApi'
import { useSnackBar } from '@/hooks/useSnackBar'
import JSnackBar from '@/components/atoms/JSnackBar'
import { ICaseManagerItem } from '@/pages/Case/components/Sidebar/components/CaseManagerList'
import ConfirmationModal from '../ConfirmationModal'

export interface RemoveCaseManagerModalProps {
  caseManager: ICaseManagerItem
  isOwner: boolean
  openModal: boolean
  setOpenModal: (value: boolean) => void
}

const RemoveCaseManagerModal = ({
  caseManager,
  openModal,
  isOwner,
  setOpenModal,
}: RemoveCaseManagerModalProps) => {
  const [deleteCaseOwnershipItemsMutation, { loading: deleteOwnerLoading }] =
    useDeleteCaseOwnershipItemMutation({
      refetchQueries: [
        GetActivityPurchaseDocument,
        'case_purchase_activity',
        GetCasesDocument,
        'case',
        GetActivityPurchaseFromMyOrgDocument,
        'case_purchase_activity',
      ],
    })

  const [deleteCasePurchaseOwnershipItem, { loading: deleteLoading }] =
    useDeleteCasePurchaseOwnershipItemMutation({
      refetchQueries: [
        GetActivityPurchaseDocument,
        'case_purchase_activity',
        GetCasesDocument,
        'case',
        GetActivityPurchaseFromMyOrgDocument,
        'case_purchase_activity',
      ],
    })

  const handleSubmitModal = async () => {
    try {
      if (isOwner) {
        await deleteCaseOwnershipItemsMutation({
          variables: {
            deleteCaseOwnershipItemId: caseManager.ownership_id,
          },
        })
      } else {
        await deleteCasePurchaseOwnershipItem({
          variables: {
            deleteCasePurchaseOwnershipItemId: caseManager.ownership_id,
          },
        })
      }
      setOpenModal(false)
    } catch (error) {
      console.error('Error deleting case manager: ', error)
      showAlert({
        severity: 'error',
        message: 'There was an error deleting the case manager',
      })
    }
  }

  const { alert, showAlert, handleClose } = useSnackBar()

  const title = 'Remove Case Managers'
  const description = `Are you sure you want to revoke ${caseManager.first_name} 
    ${caseManager.last_name} permissions to act on your behalf for this
    case?`

  return (
    <>
      <JSnackBar
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        horizontal="center"
        vertical="bottom"
        handleClose={handleClose}
      />
      <ConfirmationModal
        title={title}
        description={description}
        open={openModal}
        onClose={() => setOpenModal(false)}
        AcceptButtonProps={{
          children: 'Yes, remove Case Manager',
          disabled: deleteLoading || deleteOwnerLoading || !caseManager,
          onClick: handleSubmitModal,
        }}
      />
    </>
  )
}

export default RemoveCaseManagerModal

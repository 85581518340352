import { useMemo } from 'react'
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form'
import { Attorney_Profile } from '@/gql/appApi'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import JDropdownUser from '@/components/atoms/JDropdownUser'
import { getImagePath } from '@/utils'
import { IMAGE_SIZES } from '@/constants'
import JAvatar from '@/components/atoms/JAvatar'
import { isQualifiedMembership } from '@/utils/membership'
import { JDropdownUserProps } from '@/components/atoms/JDropdownUser/types'
import useSelectedDefaultAttorney from './useSelectedDefaultAttorney'
import { AttorneyToPracticeAreas } from '../usePracticeAreaMapping'

type FieldName =
  | 'defaultAttorney'
  | `assignedAttorneys.${number}.assignedAttorneyId`

interface SelectDefaultAttorneyProps {
  fieldName: FieldName
  rules?: string | UseControllerProps['rules']
  excludeSelected?: boolean
  index?: number
  validAttorneys: Map<string, Attorney_Profile[]>
}

const SelectDefaultAttorney = ({
  fieldName,
  rules,
  excludeSelected,
  index,
  validAttorneys,
}: SelectDefaultAttorneyProps) => {
  const { control, setValue, watch } = useFormContext()
  const defaultAttorney: string | null = watch('defaultAttorney')
  const defaultSelectedAttorney = watch(fieldName)
  const selectedAttorneys: AttorneyToPracticeAreas[] | null =
    watch('assignedAttorneys')
  const currentAttorney = watch(fieldName)
  const selectedJurisdiction = watch(
    `assignedAttorneys.${index}.jurisdictionId`
  )
  const stringifiedSelectedAttorneys = JSON.stringify(selectedAttorneys)

  const excludedAttorneys = useMemo(() => {
    const filtered = selectedAttorneys
      ?.filter(
        (item: AttorneyToPracticeAreas | null) =>
          item?.assignedAttorneyId &&
          item?.assignedAttorneyId !== currentAttorney &&
          item?.jurisdictionId === selectedJurisdiction
      )
      ?.map(
        (item: AttorneyToPracticeAreas | null) =>
          item?.assignedAttorneyId || null
      )

    const excluded = excludeSelected
      ? [...(filtered ?? []), defaultAttorney]
      : filtered

    return new Set(excluded)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    excludeSelected,
    stringifiedSelectedAttorneys,
    defaultAttorney,
    currentAttorney,
  ])

  const {
    attorneyProfile,
    setAttorney,
    filteredOptions,
    filterOptions,
    inputValue,
    setInputValue,
    attorneySelected,
    setAttorneySelected,
  } = useSelectedDefaultAttorney({
    excludedAttorneys,
    defaultAttorney: defaultSelectedAttorney,
    excludeSelected,
    selectedJurisdiction,
    validAttorneys,
  })

  const label = 'Select an Attorney'

  const disabled = excludeSelected && !selectedJurisdiction

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={typeof rules === 'string' ? { required: rules } : rules}
      render={({ field: { onChange }, fieldState }) => (
        <Autocomplete
          options={filteredOptions}
          value={attorneyProfile}
          getOptionLabel={(att: Attorney_Profile) =>
            `${att.first_name} ${att.last_name}`
          }
          filterOptions={filterOptions}
          onChange={(_, newAttorney) => {
            setAttorney(newAttorney)
            if (newAttorney) {
              setInputValue(
                `${newAttorney.first_name} ${newAttorney.last_name}`
              )
            } else {
              setInputValue('')
            }
            setAttorneySelected(newAttorney)
            onChange(newAttorney)
            setValue(fieldName, newAttorney?.attorney_id?.id || null)
          }}
          disabled={disabled}
          isOptionEqualToValue={(option, v) => option.id === v.id}
          renderOption={(props, option) => {
            // The 'key' value was causing an error being spread as part of the props into JSX
            // The 'any' type is used since TS was not detecting the 'key' value in the props, even though it exists
            const { key, ...otherProps } =
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              props as any as Partial<JDropdownUserProps>
            return (
              <JDropdownUser
                key={option.id}
                user={{
                  id: option.id,
                  name: `${option.first_name} ${option.last_name}`,
                  picture: getImagePath({
                    id: option?.attorney_id?.user_id?.avatar?.id || null,
                    size: IMAGE_SIZES.Small,
                  }),
                  isVetted: option?.attorney_id?.is_vetted || false,
                }}
                sx={{
                  avatar: { width: 28, height: 28 },
                }}
                badgeSize="xsmall"
                {...otherProps}
              />
            )
          }}
          onInputChange={(_, eInputValue) => {
            setInputValue(eInputValue)
          }}
          renderInput={params => {
            const { InputProps, inputProps, ...other } = params
            return (
              <TextField
                sx={{
                  width: '100%',
                  backgroundColor: theme => theme.palette.common.white,
                  borderRadius: '4px',
                }}
                {...other}
                size="medium"
                InputProps={{
                  ...InputProps,
                  inputProps: {
                    ...inputProps,
                    value: inputValue,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      setInputValue(e.target.value)
                    },
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      {attorneySelected && (
                        <Box>
                          <JAvatar
                            src={getImagePath({
                              id:
                                attorneySelected?.attorney_id?.user_id?.avatar
                                  ?.id || null,
                              size: IMAGE_SIZES.Small,
                            })}
                            sx={{
                              avatar: {
                                width: 30,
                                height: 30,
                              },
                              badge: {
                                mr: 0,
                                ml: 1,
                              },
                            }}
                            alt={`${attorneySelected?.first_name} ${attorneySelected?.last_name}`}
                            isVetted={Boolean(
                              attorneySelected?.attorney_id?.is_vetted
                            )}
                            isQualified={isQualifiedMembership(
                              attorneySelected?.attorney_id?.membership_type?.id
                            )}
                            badgeSize="xsmall"
                          />
                        </Box>
                      )}
                    </InputAdornment>
                  ),
                }}
                id={`defaultAttorneySelected-${fieldName}`}
                placeholder={label}
                variant="outlined"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )
          }}
        />
      )}
    />
  )
}

export default SelectDefaultAttorney

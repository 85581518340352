import { Highlight, HighlightProps } from 'react-instantsearch'
import { Link as RouterLink } from 'react-router-dom'
import {
  TableCell,
  TableRow,
  Typography,
  useTheme,
  Box,
  alpha,
  TypographyProps,
} from '@mui/material'
import JStatusPill from '@/components/atoms/JStatusPill'
import { PROCESS_STAGES_BY_NAME } from '@/constants'
import { useSizes } from '@/hooks/useSizes'
import { formatFee, formatDate } from '../utils'

// Property must be configured in Algolia dashboard to be searchable
const CustomHighlight = ({
  typographyProps,
  prepend,
  ...props
}: HighlightProps<any> & {
  typographyProps?: TypographyProps
  prepend?: React.ReactNode
}) => {
  const theme = useTheme()
  return (
    <Typography
      {...typographyProps}
      sx={{
        ...typographyProps?.sx,
        '& .instantsearch-highlighted': {
          backgroundColor: alpha(theme.palette.secondary.main, 0.5),
          color: '#000000',
          borderRadius: '4px',
          padding: '2px 0px',
        },
      }}
    >
      {prepend}
      <Highlight
        classNames={{
          highlighted: 'instantsearch-highlighted',
        }}
        {...props}
      />
    </Typography>
  )
}

const AlgoliaHit = ({ hit }: { hit: any }) => {
  // TODO: Add type for hit
  const casePageRoute = `/case/${hit.id}`
  const theme = useTheme()
  const { isLessMd } = useSizes()

  const truncatedTextStyle = {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical' as const,
    WebkitLineClamp: 3,
  }

  if (isLessMd) {
    return (
      <Box
        sx={{
          padding: 2,
          borderBottom: '1px solid',
          borderColor: 'divider',
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }}
      >
        <Box
          component={RouterLink}
          to={casePageRoute}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              gap: 1,
            }}
          >
            <Box sx={{ flex: 1, minWidth: 0 }}>
              <CustomHighlight
                attribute="name"
                hit={hit}
                typographyProps={{
                  variant: 'subtitle1',
                  sx: {
                    ...truncatedTextStyle,
                    WebkitLineClamp: 3,
                    '&:hover': { textDecoration: 'underline' },
                  },
                }}
                prepend={
                  hit?.catalog_process_stage ? (
                    <JStatusPill
                      id={
                        PROCESS_STAGES_BY_NAME[hit.catalog_process_stage] || ''
                      }
                      name={hit.catalog_process_stage}
                      label={hit.catalog_process_stage}
                      sx={{
                        mr: 1,
                        mb: 0.5,
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    />
                  ) : null
                }
              />
            </Box>
            <Box
              sx={{
                width: theme.spacing(6),
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {hit.fee ? (
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {formatFee(hit.fee)}
                </Typography>
              ) : (
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    color: 'text.disabled',
                  }}
                >
                  N/A
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{
                ...truncatedTextStyle,
                WebkitLineClamp: 1,
                maxWidth: '70%',
              }}
            >
              {hit.catalog_us_state_name} · {hit.catalog_practice_area}
            </Typography>
            {hit.created_ts && (
              <Typography
                variant="caption"
                color="text.disabled"
                sx={{
                  textTransform: 'capitalize',
                  whiteSpace: 'nowrap',
                  textAlign: 'right',
                }}
              >
                Posted {formatDate(hit.created_ts)}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <TableRow
      component={RouterLink}
      to={casePageRoute}
      sx={{
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: theme.palette.action.hover,
        },
        '&:focus': {
          outline: '1px solid',
          outlineColor: theme.palette.primary.main,
          outlineWidth: 2,
          borderRadius: 2,
        },
        color: 'inherit',
      }}
    >
      <TableCell>
        <CustomHighlight
          attribute="name"
          hit={hit}
          typographyProps={{
            variant: 'subtitle2',
            sx: {
              ...truncatedTextStyle,
              '& span': {
                '&:hover': {
                  textDecoration: 'underline',
                  color: theme.palette.primary.main,
                },
              },
            },
          }}
        />
      </TableCell>
      <TableCell>
        <CustomHighlight
          hit={hit}
          attribute="catalog_practice_area"
          typographyProps={{
            variant: 'body2',
            sx: truncatedTextStyle,
          }}
        />
      </TableCell>
      <TableCell>
        <CustomHighlight
          hit={hit}
          attribute="catalog_us_state_name"
          typographyProps={{
            variant: 'body2',
            sx: truncatedTextStyle,
          }}
        />
      </TableCell>
      <TableCell>
        <Typography
          variant="subtitle1"
          sx={{
            fontFamily: 'BrownPro, sans-serif',
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '22.1px',
            textAlign: 'center',
          }}
        >
          {formatFee(hit.fee)}
        </Typography>
      </TableCell>
      <TableCell>
        <JStatusPill
          id={PROCESS_STAGES_BY_NAME[hit.catalog_process_stage] || ''}
          name={hit.catalog_process_stage}
          label={hit.catalog_process_stage}
        />
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          sx={{
            textAlign: 'right',
          }}
        >
          {hit.created_ts && formatDate(hit.created_ts)}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default AlgoliaHit

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useForm } from 'react-hook-form'

import JTextField from '@/components/atoms/JTextField'
import { MAX_BIO_LENGTH } from '@/constants'
import LogoBar from '@/pages/CreateAccount/shared/LogoBar'
import { useSizes } from '@/hooks/useSizes'
import FragmentProfilePicture from '@/components/Profile/FragmentProfilePicture'
import { useProfilePersonalInfo } from '@/hooks/profile/useProfilePersonalInfo'
import { useTheme } from '@mui/material/styles'
import { TCreateOrganizationProfile, profileDefaultValues } from '../types'

type TOrganizationCreateProfileProps = {
  back: () => void
  hasLicense: boolean
  profileValues: TCreateOrganizationProfile
  onSubmit: (profileData: TCreateOrganizationProfile) => void
  prevBtnText?: string
  nextBtnText?: string
  loading?: boolean
}

const OrganizationCreateProfile: React.FC<TOrganizationCreateProfileProps> = ({
  back,
  hasLicense,
  profileValues,
  onSubmit,
  prevBtnText = 'Cancel',
  nextBtnText = 'Continue',
  loading,
}: TOrganizationCreateProfileProps) => {
  const { isLessMd } = useSizes()
  const theme = useTheme()
  const { control, handleSubmit } = useForm<TCreateOrganizationProfile>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: profileValues || profileDefaultValues,
  })

  const {
    profilePhoto,
    profilePhotoRef,
    onProfilePictureSave,
    onChangeProfilePhoto,
    onRemoveProfilePhoto,
  } = useProfilePersonalInfo()

  const handleFormSubmit = handleSubmit(
    async (formData: TCreateOrganizationProfile) => {
      await onProfilePictureSave({ showSuccess: false })
      onSubmit(formData)
    }
  )

  return (
    <Box
      sx={{
        marginTop: {
          xs: 0,
        },
        maxWidth: {
          md: theme.spacing(74),
        },
        height: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
      }}
    >
      <LogoBar />
      <Box>
        <Typography
          sx={{
            textAlign: 'left',
            marginBottom: 5,
          }}
          variant={isLessMd ? 'h6' : 'h5'}
        >
          Professional Information
        </Typography>
      </Box>
      <form onSubmit={handleFormSubmit}>
        <Stack>
          <Box
            sx={{
              paddingBottom: theme.spacing(3),
            }}
          >
            <FragmentProfilePicture
              showTitle={false}
              profilePhoto={profilePhoto}
              profilePhotoRef={profilePhotoRef}
              onChangeProfilePhoto={onChangeProfilePhoto}
              onRemoveProfilePhoto={onRemoveProfilePhoto}
            />
          </Box>
          <JTextField<TCreateOrganizationProfile>
            name="position"
            control={control}
            label="Position"
            type="text"
            InputProps={{ inputProps: { maxLength: 40 } }}
            rules={{
              validate: value =>
                (value as string)?.length > 40
                  ? 'Position must not exceed 40 characters'
                  : true,
            }}
          />
          <JTextField<TCreateOrganizationProfile>
            multiline
            rows={4}
            name="bio"
            control={control}
            label="Bio. Sell yourself, tastefully"
            type="text"
            sx={{ mt: 2 }}
            rules={{
              validate: value =>
                (value as string)?.length > MAX_BIO_LENGTH
                  ? `Bio must not exceed ${MAX_BIO_LENGTH} characters`
                  : true,
            }}
          />
        </Stack>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            mt: 3,
            justifyContent: 'flex-end',
            ...(isLessMd && {
              justifyContent: 'space-between',
            }),
          }}
        >
          {hasLicense && (
            <Button
              size="large"
              fullWidth
              onClick={back}
              sx={{
                width: {
                  xs: '35%',
                  md: '50%',
                },
                maxWidth: 290,
              }}
            >
              {prevBtnText}
            </Button>
          )}
          <Button
            size="large"
            type="submit"
            variant="contained"
            sx={{
              width: {
                xs: '65%',
                md: '50%',
              },
              maxWidth: 290,
            }}
            disabled={loading}
          >
            {nextBtnText}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default OrganizationCreateProfile

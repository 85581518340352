import { useContext } from 'react'
import { AlgoliaMarketplaceContext } from './AlgoliaMarketplaceContext'

export const useAlgoliaMarketplaceContext = () => {
  const context = useContext(AlgoliaMarketplaceContext)
  if (context === undefined) {
    throw new Error(
      'useAlgoliaMarketplaceContext must be used within a AlgoliaMarketplaceProvider'
    )
  }
  return context
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react'
import useAuthentication from '@/hooks/useAuthentication'
import useOrganization from '@/hooks/useOrganization'
import { TTokenValidationResponse } from '@/hooks/useTokenValidation'
import useUpdateAttorneyProfile from '@/pages/CreateProfile/hooks/useUpdateAttorneyProfile'
import { useNavigate } from 'react-router-dom'
import { ACCOUNT_TYPE } from '@/constants'
import { getSignInRedirectionByRole } from '@/pages/SignIn/utils'
import { useGetDelegatedUsersByCaseManagerLazyQuery } from '@/gql/systemApi'
import { educationDefaultValues } from '@/pages/CreateProfile/Education/types'
import get from 'lodash/get'
import { sessionStorageService } from '@/services/SessionStorage/SessionStorageService'
import { TCommunicationPreferencesFormValues } from '@/components/CommunicationPreferences/types'
import { communicationPreferencesDefaultValues } from '@/components/CommunicationPreferences/hooks/useCommunicationPreferences'
import { cloneDeep, merge } from 'lodash'
import { CreateAccountData } from '../../InitialStep/types'
import useCreateCaseManagerFlow from './useCreateCaseManagerFlow'

export type TCaseManagerFormState = {
  personalInfo: {
    position?: string
    bio?: string
  }
  communicationPreferences: TCommunicationPreferencesFormValues
}

interface IUseCreateInvitedAttorneyProps {
  createAccountValues: CreateAccountData
  tokenData: TTokenValidationResponse
}

export const useCreateCaseManager = ({
  createAccountValues,
  tokenData,
}: IUseCreateInvitedAttorneyProps) => {
  const navigate = useNavigate()
  const { state, next, back } = useCreateCaseManagerFlow()
  const { createAccount, loading: createAccountLoading } = useAuthentication()
  const { getOrganizationData, updateOrganizationUser } = useOrganization()
  const {
    tokenRefresh,
    updateAttorneyProfile,
    loading: updateProfileLoading,
  } = useUpdateAttorneyProfile()
  const [error, setError] = useState('')
  const [formState, setFormState] = useState<TCaseManagerFormState>({
    personalInfo: { position: '', bio: '' },
    communicationPreferences: communicationPreferencesDefaultValues,
  })
  const [getUserMeWithRoleData] = useGetDelegatedUsersByCaseManagerLazyQuery()

  const updateFormState = (
    key: keyof TCaseManagerFormState,
    value: TCaseManagerFormState[keyof TCaseManagerFormState]
  ) => {
    setFormState(prevState => ({ ...prevState, [key]: value }))
  }

  const submitAndContinue = async (
    key: keyof TCaseManagerFormState,
    data: any
  ) => {
    updateFormState(key, data)
    next()
  }

  const handleRefreshAndContinue = useCallback(async () => {
    const result = await tokenRefresh()
    if (result) {
      const userMe = await getUserMeWithRoleData()
      navigate(
        getSignInRedirectionByRole(get(userMe, 'data.users_me.role.id', ''))
      )
      return
    }
    setError('Something went wrong. Please try again.')
  }, [navigate, tokenRefresh, getUserMeWithRoleData])

  const handleCreateAccount = useCallback(
    async (
      communicationPreferences: TCaseManagerFormState['communicationPreferences']
    ) => {
      updateFormState('communicationPreferences', communicationPreferences)
      let finalData = cloneDeep(formState)

      if (communicationPreferences) {
        finalData = merge(formState, { communicationPreferences })
      }

      sessionStorageService.clear()
      const result = await createAccount({
        email: tokenData.email.toLowerCase(),
        password: createAccountValues.password,
        firstName: createAccountValues.firstName,
        lastName: createAccountValues.lastName,
        tags: JSON.stringify({
          target_role: tokenData.role,
          target_organization: tokenData.organization,
          account_type: ACCOUNT_TYPE.Organization,
        }),
      })
      if (result.id) {
        const updateResult = await updateAttorneyProfile({
          education: educationDefaultValues,
          practiceAreas: [],
          personalInfo: {
            bio: finalData.personalInfo.bio || '',
          },
          communicationPreferences,
          phoneNumber: createAccountValues.phone,
        })

        if (!updateResult?.id || updateResult.updateAttorneyProfileErrors) {
          setError(updateResult?.updateAttorneyProfileErrors?.base || '')
          return
        }

        if (finalData.personalInfo.position) {
          const organizationDataResult = await getOrganizationData()
          if (!organizationDataResult.organizationUserItemId) {
            setError(
              organizationDataResult?.error || 'Organization User ID not found'
            )
            return
          }
          const updateOrgUserResult = await updateOrganizationUser({
            organizationUserItemId:
              organizationDataResult.organizationUserItemId || '',
            positionName: finalData.personalInfo.position,
            positionBio: finalData.personalInfo.bio || '',
          })
          if (updateOrgUserResult.organizationUserItemId) {
            handleRefreshAndContinue()
            return
          }
          if (updateOrgUserResult.updateOrganizationUserErrors) {
            setError(updateOrgUserResult.updateOrganizationUserErrors.base)
            return
          }
        }
        handleRefreshAndContinue()
      }
      if (result.createAccountErrors) {
        setError(result.createAccountErrors.base)
      }
    },
    [
      createAccount,
      tokenData.email,
      tokenData.role,
      tokenData.organization,
      createAccountValues.password,
      createAccountValues.firstName,
      createAccountValues.lastName,
      createAccountValues.phone,
      handleRefreshAndContinue,
      getOrganizationData,
      updateOrganizationUser,
      updateAttorneyProfile,
      formState,
    ]
  )

  const loading = createAccountLoading || updateProfileLoading

  return {
    formState,
    handleCreateAccount,
    submitAndContinue,
    setError,
    loading,
    error,
    state,
    back,
  }
}

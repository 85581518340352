import { useEffect, useState } from 'react'
import { useSnackBar } from '@/hooks/useSnackBar'
import {
  MembersCreditsResponse,
  MembersPricesResponse,
} from '@/utils/api/types'
import { useMembersPrices } from '@/utils/api/prices'
import { useMembersCredits } from '@/utils/api/credits'

interface IUsePricesAndCredits {
  caseId?: string
  onBehalfOfUserId?: string
  isTransaction: boolean
}

const usePricesAndCredits = ({
  caseId,
  onBehalfOfUserId,
  isTransaction,
}: IUsePricesAndCredits) => {
  const { showAlert } = useSnackBar()
  const [pricesData, setPricesData] = useState<MembersPricesResponse | null>(
    null
  )
  const [creditsData, setCreditsData] = useState<MembersCreditsResponse | null>(
    null
  )

  const {
    data: membersPricesData,
    isLoading: loadingPrices,
    refetch: getPricesData,
  } = useMembersPrices({ caseId: caseId || '', onBehalfOfUserId })

  const {
    data: membersCreditsData,
    isLoading: loadingCredits,
    refetch: getCreditsData,
  } = useMembersCredits()

  useEffect(() => {
    if (isTransaction && caseId) {
      getPricesData()
      getCreditsData()
    }
  }, [caseId, isTransaction, getPricesData, getCreditsData, onBehalfOfUserId])

  useEffect(() => {
    if (membersPricesData?.data) {
      setPricesData(membersPricesData?.data)
    }
    if (membersPricesData?.error) {
      showAlert({
        severity: 'error',
        message: membersPricesData?.error,
      })
    }
  }, [membersPricesData, showAlert])

  useEffect(() => {
    if (membersCreditsData?.data) {
      setCreditsData(membersCreditsData?.data)
    }
    if (membersCreditsData?.error) {
      showAlert({
        severity: 'error',
        message: membersCreditsData?.error,
      })
    }
  }, [membersCreditsData, showAlert])

  const loading =
    (!(pricesData && creditsData) && isTransaction) ||
    loadingPrices ||
    loadingCredits

  return {
    pricesData,
    creditsData,
    loading,
  }
}

export default usePricesAndCredits

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, CircularProgress, useTheme } from '@mui/material'
import { useParams } from 'react-router-dom'

import {
  lazy,
  FC,
  MouseEvent,
  useContext,
  useEffect,
  Suspense,
  useState,
} from 'react'
import GlobalContext from '@/context/GlobalContext'
import { useDiscardChangesModal } from '@/components/Modals/DiscardChangesModal/useDiscardChangesModal'
import DiscardChangesModal from '@/components/Modals/DiscardChangesModal/DiscardChangesModal'

import { MenuItemType } from '@/components/Menu'
import { MyPagesLayout } from '@/components'

import { useRestrictedUserPermissions } from '@/hooks/permissions/useRestrictedUserPermissions'
import {
  PATHS_WITH_SECTIONS,
  getRouteLabelByPathStr,
} from '@/router/RoutesUtils'
import {
  menuItems,
  Subtitle,
  getEditPublicProfileFirstLabel,
} from './utils/constanst'

const FragmentPersonalInfo = lazy(
  () => import('../../components/Profile/FragmentPersonalInfo')
)
const FragmentProfessionalLicenses = lazy(
  () => import('../../components/Profile/FragmentProfessionalLicenses')
)
const FragmentLawFirm = lazy(
  () => import('../../components/Profile/FragmentLawFirm')
)
const FragmentPracticeArea = lazy(
  () => import('../../components/Profile/FragmentPracticeArea')
)
const FragmentEducation = lazy(
  () => import('../../components/Profile/FragmentEducation')
)
// const FragmentAwards = lazy(
//   () => import('../../components/Profile/FragmentAwards')
// )

const EditPublicProfile: FC = () => {
  const theme = useTheme()
  const { id: pageId } = useParams()
  const globalContext = useContext(GlobalContext)
  const { isOrganizationAccount, currentPermissionsSet } =
    useRestrictedUserPermissions('Profile', 'inOrganization')
  const [page, setPage] = useState(
    getRouteLabelByPathStr(menuItems, pageId) ||
      getEditPublicProfileFirstLabel()
  )
  const [nextPage, setNextPage] = useState('')
  const [menu, setMenu] = useState<MenuItemType[]>(menuItems)
  const [{ items }] = menu

  // reset prevent navigation
  useEffect(() => {
    globalContext?.setPreventNavigation(false)
  }, [])

  useEffect(() => {
    if (currentPermissionsSet) {
      const data = menuItems[0].items.filter(f => {
        switch (f.label) {
          case 'Law Firm':
            return !isOrganizationAccount
          case 'Practice Areas':
            return currentPermissionsSet?.Profile?.read
          case 'Professional License':
            return currentPermissionsSet?.Profile?.read
          case 'Education':
            return currentPermissionsSet?.Profile?.read
          // case 'Awards':
          //   return currentPermissionsSet?.Profile?.read
          default:
            return true
        }
      })
      setMenu([{ sectionTitle: '', items: data }])
    }
  }, [currentPermissionsSet, isOrganizationAccount])

  useEffect(() => {
    globalContext?.setSelectedTab(page)
  }, [globalContext, page])

  const {
    getConfirmationModalProps,
    setOpenModal,
    modalOpen,
    type: modalType,
    ...rest
  } = useDiscardChangesModal({
    type: 'confirm',
    onSubmit: () => {
      setPage(nextPage)
      setOpenModal(null)
      globalContext?.setPreventNavigation(false)
    },
  })
  const onClickItem = (
    item: string,
    _event:
      | MouseEvent<HTMLLIElement, MouseEvent>
      | MouseEvent<HTMLDivElement, MouseEvent>
      | MouseEvent
  ) => {
    setNextPage(item)
    if (globalContext?.preventNavigation) {
      return setOpenModal('confirmDiscardChanges')
    }

    return setPage(item)
  }

  const renderTab = (): JSX.Element | null => {
    switch (page) {
      case 'Personal Information':
        return (
          <FragmentPersonalInfo
            title={page}
            subtitle={<Subtitle page={page} />}
          />
        )
      case 'Professional License':
        return (
          <FragmentProfessionalLicenses
            title={page}
            subtitle={<Subtitle page={page} />}
          />
        )
      case 'Law Firm':
        return (
          <FragmentLawFirm title={page} subtitle={<Subtitle page={page} />} />
        )
      case 'Practice Areas':
        return (
          <FragmentPracticeArea
            title={page}
            subtitle={<Subtitle page={page} />}
          />
        )
      case 'Education':
        return (
          <FragmentEducation title={page} subtitle={<Subtitle page={page} />} />
        )
      // case 'Awards':
      //   return <FragmentAwards title={page} subtitle={<Subtitle page={page} />} />
      default:
        return null
    }
  }

  return (
    <MyPagesLayout
      menuItems={items.length > 0 ? menu : menuItems}
      onClickItem={onClickItem}
      customOnClickItemOnly
      skipBottomBar
      useLinks={PATHS_WITH_SECTIONS.EDIT_PUBLIC_PROFILE}
    >
      <Suspense
        fallback={
          <Box
            sx={{
              width: '100vw',
              mt: 2,
              textAlign: 'center',
            }}
          >
            <CircularProgress color="secondary" />
          </Box>
        }
      >
        <Box
          sx={{
            width: '100%',
            '&::-webkit-scrollbar': { display: 'none' },
            overflowY: 'auto',
            padding: {
              xs: theme.spacing(0),
              md: theme.spacing(5),
            },
            marginBottom: {
              xs: theme.spacing(24),
              md: theme.spacing(0),
            },
          }}
        >
          {renderTab()}
        </Box>
      </Suspense>
      {modalOpen && (
        <DiscardChangesModal
          ConfirmationModalProps={getConfirmationModalProps()}
          {...rest}
        />
      )}
    </MyPagesLayout>
  )
}
export default EditPublicProfile

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'

import { MEMBERSHIP_FEE } from '@/constants'
import ConfirmationModal, { ConfirmationModalProps } from '../ConfirmationModal'

export type NonVettedModalProps = Omit<
  ConfirmationModalProps,
  'title' | 'description'
>

const BoxBullet = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 0.75,
  alignSelf: 'stretch',
}))

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  padding: theme.spacing(3, 4, 4, 4),
  border: '1px solid',
  borderRadius: '4px',
  borderColor: theme.palette.secondary.main,
}))

const CheckIcon = styled(CheckCircleOutline)(({ theme }) => ({
  color: theme.palette.secondary.main,
}))

const NonVettedModal = ({
  AcceptButtonProps,
  ...props
}: NonVettedModalProps) => (
  <ConfirmationModal
    title="You don’t have permissions"
    description="Only JHQ Vetted Attorneys or users whitelisted by the case owner are
  allowed to submit proposals, request access to data room or add new members to their organization."
    AcceptButtonProps={{
      children: 'Start Application Process',
      ...AcceptButtonProps,
    }}
    {...props}
  >
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          alignSelf: 'stretch',
        }}
      >
        <Typography variant="h5">Become a JHQ Vetted Attorney</Typography>
        <Typography variant="body2" color="text.secondary">
          3rd Party JHQ Vetted Marketplace Attorneys get everything Standard
          Members get, but so much more.
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 2,
          alignSelf: 'stretch',
        }}
      >
        <BoxBullet>
          <CheckIcon fontSize="small" />
          <Typography variant="body1">
            Apply to public marketplace cases
          </Typography>
        </BoxBullet>
        <BoxBullet gap={0.75}>
          <CheckIcon fontSize="small" />
          <Typography variant="body1">
            Listed in public lawyer directory
          </Typography>
        </BoxBullet>
        <BoxBullet gap={0.75}>
          <CheckIcon fontSize="small" />
          <Typography variant="body1">
            Invited to cases without whitelist
          </Typography>
        </BoxBullet>
        <BoxBullet gap={0.75}>
          <CheckIcon fontSize="small" />
          <Typography variant="body1">
            Access to the JHQ community portal
          </Typography>
        </BoxBullet>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 3,
        }}
      >
        <Typography variant="subtitle1">
          Starts at {MEMBERSHIP_FEE}/month
        </Typography>
      </Box>
    </Container>
  </ConfirmationModal>
)

export default NonVettedModal

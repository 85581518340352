import React, { useState } from 'react'
import Button from '@mui/material/Button'
import ModeEditOutlined from '@mui/icons-material/ModeEditOutlined'
import { useNavigate } from 'react-router-dom'
import ResponseModal from '@/components/Modals/ResponseModal'
import { MODAL_TYPES } from '@/types/index'
import { useSizes } from '@/hooks/useSizes'
import useTheme from '@mui/material/styles/useTheme'
import { alpha } from '@mui/material/styles'
import { useFeatureFlags } from '@/optimizely/useFeatureFlags'
import { FeatureFlag } from '@/optimizely/types'
import CaseHeaderChip from '../CaseHeader/CaseHeaderChip'

interface IEditCaseButtonProps {
  caseId: string
  isAccepted?: boolean
}

const EditCaseButton: React.FC<IEditCaseButtonProps> = ({
  caseId,
  isAccepted = false,
}) => {
  const featureFlags = useFeatureFlags()
  const isNegotiationFlowImprovementEnabled =
    featureFlags[FeatureFlag.NEGOTIATION_FLOW_IMPROVEMENTS]
  const theme = useTheme()
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()
  const { isGreaterMd } = useSizes()
  const buttonText = isGreaterMd ? 'Edit Case' : ''

  const handleEdit = () => {
    if (isAccepted) {
      setShowModal(true)
    } else {
      navigate(`/case/${caseId}/edit`)
    }
  }

  const handleModalClose = () => {
    setShowModal(false)
  }

  return (
    <>
      {isNegotiationFlowImprovementEnabled ? (
        <CaseHeaderChip
          label="Edit Case"
          icon={
            <ModeEditOutlined
              sx={{
                height: '14px',
              }}
            />
          }
          textColor={alpha(theme.palette.common.black, 0.87)}
          iconColor={alpha(theme.palette.common.black, 0.87)}
          onClick={handleEdit}
        />
      ) : (
        <Button
          variant="text"
          size="medium"
          fullWidth={isGreaterMd}
          startIcon={<ModeEditOutlined />}
          onClick={handleEdit}
        >
          {buttonText}
        </Button>
      )}
      <ResponseModal
        type={MODAL_TYPES.CASE_NOT_EDITABLE}
        open={showModal}
        onClose={handleModalClose}
      />
    </>
  )
}

export default EditCaseButton

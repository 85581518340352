import { alpha, useTheme } from '@mui/material/styles'
import Grid, { GridProps } from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

interface IWaterfallModalLayoutProps extends GridProps {
  title: string
  description?: string
  children: React.ReactNode
}

const WaterfallModalLayout = ({
  title,
  description,
  children,
  ...rest
}: IWaterfallModalLayoutProps) => {
  const theme = useTheme()
  return (
    <Grid
      container
      columnSpacing={1}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
      }}
      {...rest}
    >
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          mb: {
            xs: 2.5,
            md: 0,
          },
        }}
      >
        <Typography variant="subtitle1">{title}</Typography>
        {description && (
          <Typography
            variant="body2"
            color={alpha(theme.palette.common.black, 0.6)}
            sx={{ mt: 1 }}
          >
            {description}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </Grid>
    </Grid>
  )
}

export default WaterfallModalLayout

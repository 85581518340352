import { useMemo, useState, useEffect, useCallback } from 'react'

import {
  useGetInvitationsSentLazyQuery,
  useUpdateInviteAttorneyMutation,
  GetInvitationsSentDocument,
  Attorney_Invite,
} from '@/gql/appApi'
import { INVITATION_STATUS } from '@/constants'
import { useSnackBar } from '@/hooks/useSnackBar'
import useOrganizationId from '@/hooks/organization/useOrganizationId'
import { getInvitationsSentColumns } from './components/InvitationsSentColumns'

const initModalStatus = {
  open: false,
  onSubmit: () => {},
  title: '',
  description: '',
}

const useInvitationsSent = () => {
  const [filteredData, setFilteredData] = useState<Attorney_Invite[]>([])
  const [searchText, setSearchText] = useState('')
  const [modalProps, setModalProps] = useState(initModalStatus)
  const { id: orgId, loading: orgQueryLoading } = useOrganizationId()
  const [getInvitationsSent, { data, loading, error }] =
    useGetInvitationsSentLazyQuery({
      fetchPolicy: 'network-only',
    })

  const { alert, showAlert, handleClose: handleCloseSnack } = useSnackBar()

  const [updateInviteAttorneyMutation, { loading: removeLoading }] =
    useUpdateInviteAttorneyMutation({
      refetchQueries: [GetInvitationsSentDocument],
    })

  useEffect(() => {
    if (orgId) {
      getInvitationsSent({
        variables: {
          filter: {
            _or: [
              {
                invite_status_id: {
                  id: { _eq: INVITATION_STATUS.Sent },
                },
              },
              {
                invite_status_id: {
                  id: { _eq: INVITATION_STATUS.Resent },
                },
              },
            ],
            target_organization_id: {
              profiles: {
                organization_id: {
                  id: {
                    _eq: orgId,
                  },
                },
              },
            },
          },
        },
      })
    }
  }, [orgId, getInvitationsSent])

  useEffect(() => {
    if (error) {
      showAlert({
        severity: 'error',
        message: 'Something went wrong, please try again in a few minutes',
      })
    }
  }, [error, showAlert])

  // TODO: change this to an API function
  useEffect(() => {
    if (data?.attorney_invite) {
      if (searchText === '') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        setFilteredData(data.attorney_invite)
      } else {
        const fData: Attorney_Invite[] = []
        data.attorney_invite.forEach(inv => {
          if (inv.email?.toLowerCase().includes(searchText.toLowerCase())) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            fData.push(inv)
          }
        })
        setFilteredData(fData)
      }
    }
  }, [data, searchText])

  const onSendInvitation = useCallback(
    (id: string) => {
      setModalProps({
        open: true,
        title: 'Resend Invitation',
        onSubmit: async () => {
          const payload = {
            updateAttorneyInviteItemId: id,
            data: {
              invite_status_id: INVITATION_STATUS.Resent,
            },
          }
          try {
            await updateInviteAttorneyMutation({ variables: { ...payload } })
          } catch (err) {
            showAlert({
              severity: 'error',
              message:
                'Something went wrong, please try again in a few minutes',
            })
          }
          setModalProps(initModalStatus)
        },
        description: 'Are you sure you want to resend the invitation?',
      })
    },
    [updateInviteAttorneyMutation, showAlert]
  )

  const onDeleteInvitation = useCallback(
    (id: string) => {
      setModalProps({
        open: true,
        title: 'Remove Invitation',
        onSubmit: async () => {
          const payload = {
            updateAttorneyInviteItemId: id,
            data: {
              invite_status_id: INVITATION_STATUS.Revoked,
            },
          }
          try {
            await updateInviteAttorneyMutation({ variables: { ...payload } })
          } catch (err) {
            showAlert({
              severity: 'error',
              message:
                'Something went wrong, please try again in a few minutes',
            })
          }
          setModalProps(initModalStatus)
        },
        description: 'Are you sure you want to remove the invitation?',
      })
    },
    [updateInviteAttorneyMutation, showAlert]
  )

  const memberColumns = useMemo(
    () => getInvitationsSentColumns({ onSendInvitation, onDeleteInvitation }),
    [onSendInvitation, onDeleteInvitation]
  )

  return {
    data: filteredData,
    memberColumns,
    setSearchText,
    modalProps,
    loading: loading || orgQueryLoading,
    removeLoading,
    setModalProps,
    initModalStatus,
    onSendInvitation,
    onDeleteInvitation,
    alert,
    handleCloseSnack,
  }
}

export default useInvitationsSent

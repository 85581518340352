import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { JGrid } from '@/components/atoms/JDataTable'
import { ConfirmationModal, Search } from '@/components'
import CustomLoading from '@/components/Loading'
import JSnackBar from '@/components/atoms/JSnackBar'
import useActiveMembersTab from './useActiveMembersTab'
import MobileTable from './components/MobileTable'
import PermissionModal from './components/PermissionModal'

const customNoRows = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <Typography>You don&apos;t have active members</Typography>
  </Box>
)

const ActiveMembersTab = () => {
  const {
    filteredData,
    setSearchText,
    loading,
    isLessMd,
    memberColumns,
    modalProps,
    setModalProps,
    initModalProps,
    modalDProps,
    setModalDProps,
    initModalDProps,
    alert,
    handleCloseSnack,
  } = useActiveMembersTab()

  return (
    <Box sx={{ p: 2.5, display: 'flex' }}>
      {loading ? (
        <CustomLoading message="Loading Invitations Sent" />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            width: '100%',
          }}
        >
          <Search
            sx={{ width: isLessMd ? '100%' : '50%' }}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSearchText(event.target.value)}
          />
          {!isLessMd ? (
            <Box sx={{ minHeight: 250, height: '100%' }}>
              <JGrid
                columns={memberColumns}
                rows={filteredData}
                hideFooterPagination
                slots={{
                  noRowsOverlay: customNoRows,
                }}
              />
            </Box>
          ) : (
            <MobileTable data={filteredData} />
          )}
        </Box>
      )}
      {modalProps.open && (
        <PermissionModal
          open={modalProps.open}
          onClose={() => setModalProps(initModalProps)}
          member={modalProps.member}
          onSubmit={modalProps.onSubmit}
        />
      )}
      {modalDProps.open && (
        <ConfirmationModal
          open={modalDProps.open}
          title="Remove Member"
          description="Are you sure you want to remove this person from your organization? Once removed, they will no longer have access to the account."
          onClose={() => setModalDProps(initModalDProps)}
          AcceptButtonProps={{
            children: 'Remove member',
            onClick: () => {
              setModalDProps(initModalDProps)
            },
          }}
        />
      )}
      <JSnackBar
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        horizontal="center"
        vertical="bottom"
        handleClose={handleCloseSnack}
      />
    </Box>
  )
}

export default ActiveMembersTab

import Button from '@mui/material/Button'
import { theme } from '@/styles/theme'
import IntegrationItemExpandableButton from './IntegrationItemExpandableButton'
import { IntegrationItemButtonsProps } from '../../types'

const IntegrationItemButtons = ({
  shouldRenderLoadingButton,
  shouldRenderDisconnectButton,
  disconnectLcms,
  connectLcms,
  isConnectedToOtherLcms,
  disableConnectAction,
  isExpandable,
  expanded,
  setExpanded,
}: IntegrationItemButtonsProps) => {
  const renderLoadingButton = () => (
    <Button
      disabled
      sx={{
        [theme.breakpoints.up('md')]: {
          width: '200px',
        },
      }}
      variant="outlined"
      size="large"
    >
      Loading...
    </Button>
  )

  const renderDisconnectButton = () => (
    <Button
      sx={{
        [theme.breakpoints.up('md')]: {
          width: '200px',
        },
      }}
      variant="outlined"
      size="large"
      onClick={disconnectLcms}
    >
      Disconnect
    </Button>
  )

  const renderConnectButton = () => {
    if (isExpandable) {
      return (
        <IntegrationItemExpandableButton
          expanded={expanded}
          setExpanded={setExpanded}
          isConnectedToOtherLcms={isConnectedToOtherLcms}
        />
      )
    }
    return (
      <Button
        sx={{
          [theme.breakpoints.up('md')]: {
            width: '200px',
          },
        }}
        size="large"
        variant="contained"
        onClick={connectLcms}
        disabled={disableConnectAction || isConnectedToOtherLcms}
      >
        Connect
      </Button>
    )
  }

  if (shouldRenderLoadingButton) {
    return renderLoadingButton()
  }
  if (shouldRenderDisconnectButton) {
    return renderDisconnectButton()
  }

  return !isConnectedToOtherLcms ? renderConnectButton() : null
}

export default IntegrationItemButtons

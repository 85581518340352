import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import GlobalContext from '@/context/GlobalContext'
import { useDeleteCaseMutation } from '@/gql/appApi'
import JDeleteButton from '@/components/Buttons/JDeleteButton'
import { DeleteCaseModal } from '@/components'
import { JDeleteButtonProps } from '@/components/Buttons/JDeleteButton/types'
import { RoutesData } from '@/router/RoutesData'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import useTheme from '@mui/material/styles/useTheme'
import { useFeatureFlags } from '@/optimizely/useFeatureFlags'
import { FeatureFlag } from '@/optimizely/types'
import { TCase } from '../../types'
import CaseHeaderChip from '../CaseHeader/CaseHeaderChip'

interface IDeleteCaseButtonsProps extends JDeleteButtonProps {
  caseInfo: TCase
  onDelete?: () => void
}

const DeleteCaseButtons: React.FC<IDeleteCaseButtonsProps> = ({
  caseInfo,
  onDelete,
  ...props
}) => {
  const featureFlags = useFeatureFlags()
  const isNegotiationFlowImprovementEnabled =
    featureFlags[FeatureFlag.NEGOTIATION_FLOW_IMPROVEMENTS]
  const theme = useTheme()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const navigate = useNavigate()
  const globalContext = useContext(GlobalContext)
  const permissions = globalContext?.globalPermissions
  const currentPermissionsSet = permissions?.inOrganization
  const [deleteCaseMutation, { loading }] = useDeleteCaseMutation({})

  const defaultOnDelete = async () => {
    await deleteCaseMutation({ variables: { deleteCaseItemId: caseInfo.id } })
    navigate(`${RoutesData.RMyReferrals.path}`, {
      state: { refetchRequired: true },
    })
  }

  const caseName = caseInfo?.name ?? ''
  const handleDelete = onDelete || defaultOnDelete

  if (!currentPermissionsSet?.Case.delete) {
    return null
  }

  return (
    <>
      {isNegotiationFlowImprovementEnabled ? (
        <CaseHeaderChip
          label="Remove Case"
          icon={
            <DeleteOutlineIcon
              sx={{
                height: '14px',
              }}
            />
          }
          textColor={theme.palette.error.main}
          iconColor={theme.palette.error.main}
          onClick={() => setOpenDeleteModal(true)}
        />
      ) : (
        <JDeleteButton onClick={() => setOpenDeleteModal(true)} {...props} />
      )}
      {openDeleteModal && (
        <DeleteCaseModal
          open={openDeleteModal}
          caseTitle={caseName}
          onDelete={handleDelete}
          loading={loading}
          onClose={() => setOpenDeleteModal(false)}
        />
      )}
    </>
  )
}

export default DeleteCaseButtons

import { useState } from 'react'
import { AutocompleteProps } from '@mui/material/Autocomplete'
import { useCasePurchaseDeclineReason } from '@/hooks/catalogs/useCasePurchaseDeclineReason'
import { Catalog_Case_Purchase_Decline_Reason } from '@/gql/systemApi'
import { useSnackBar } from '@/hooks/useSnackBar'
import { MODAL_TYPES, ModalTypes } from '@/types'
import {
  GetActivityPurchaseDocument,
  GetActivityPurchaseFromMyOrgDocument,
  GetCasesDocument,
  useCreateClientSignedOrRejectedMutation,
} from '@/gql/appApi'
import { CASE_PURCHASE_STATUS, CASE_STATUS_FLOW } from '@/constants'
import useAttorneyId from '@/hooks/useAttorneyId'
import initApollo from '@/apollo'

interface UseAgreementRejectionConfirmationModalProps {
  onClose: () => void
  onSubmitResponse: ({ responseType }: { responseType: ModalTypes }) => void
  casePurchaseId: string
  onBehalfOfAttorneyId?: string
  fee: number
}

export const useAgreementRejectionConfirmationModal = ({
  onClose,
  onSubmitResponse,
  onBehalfOfAttorneyId,
  casePurchaseId,
  fee,
}: UseAgreementRejectionConfirmationModalProps) => {
  const { alert, showAlert, handleClose: handleSnackBarClose } = useSnackBar()
  const {
    casePurchaseDeclineReasons,
    loading: casePurchaseDeclineReasonsLoading,
  } = useCasePurchaseDeclineReason()

  const [selectedRejectionReason, setSelectedRejectionReason] = useState<
    Catalog_Case_Purchase_Decline_Reason | undefined
  >()
  const [loading, setLoading] = useState(false)
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = getAttorneyId()
  const createdBy = String(onBehalfOfAttorneyId || attorneyId)

  const [
    createCaseActivityPurchase,
    { loading: createCaseActivityPurchaseLoading },
  ] = useCreateClientSignedOrRejectedMutation({
    fetchPolicy: 'network-only',
    variables: {
      data: {
        decline_reason_id: selectedRejectionReason?.id,
        created_by: createdBy,
        fee,
        activity_entry_status: selectedRejectionReason?.is_case_relistable
          ? CASE_PURCHASE_STATUS.DeclineAndAvailable
          : CASE_PURCHASE_STATUS.DeclineAndRejected,
        case_purchase_id: {
          id: casePurchaseId,
        },
      },
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      GetActivityPurchaseDocument,
      'case_purchase_activity',
      GetCasesDocument,
      'case',
      GetActivityPurchaseFromMyOrgDocument,
      'case_purchase_activity',
    ],
  })

  const handleAcceptClick = async () => {
    if (!selectedRejectionReason) {
      throw new Error('selectedRejectionReason is not defined')
    }

    try {
      setLoading(true)
      const response = await createCaseActivityPurchase()
      if (response?.data?.create_case_purchase_activity_item?.id) {
        // If the case is still Matched, refetch the queries to update the case status.
        if (
          response?.data?.create_case_purchase_activity_item?.case_purchase_id
            ?.case_id?.status === CASE_STATUS_FLOW.Matched
        ) {
          const result = await initApollo().refetchQueries({
            include: [
              GetActivityPurchaseDocument,
              'case_purchase_activity',
              GetCasesDocument,
              'case',
              GetActivityPurchaseFromMyOrgDocument,
              'case_purchase_activity',
            ],
          })
          const caseResponse = result?.find(query =>
            Object.keys(query?.data).includes('case')
          )
          const caseStatus = caseResponse?.data?.case?.[0]?.status
          // If the case is still Matched, refetch the queries to update the case status.
          if (caseStatus && caseStatus === CASE_STATUS_FLOW.Matched) {
            // Add delay to initApollo.refetchQueries() to ensure the case status is updated.
            // TODO: This is a temporary solution until we have a better way to handle this. Probably with an endpoint.
            setTimeout(async () => {
              await initApollo().refetchQueries({
                include: [
                  GetActivityPurchaseDocument,
                  'case_purchase_activity',
                  GetCasesDocument,
                  'case',
                  GetActivityPurchaseFromMyOrgDocument,
                  'case_purchase_activity',
                ],
              })
            }, 2000)
          }
        }
      }
    } catch (e) {
      showAlert({
        message:
          'There was an error processing your request. Please try again later.',
        severity: 'error',
      })

      // eslint-disable-next-line no-console
      console.error(e)
      return
    } finally {
      setLoading(false)
    }

    onSubmitResponse({
      responseType: MODAL_TYPES.CLIENT_SIGNED_REJECTED,
    })

    showAlert({
      message: 'Your rejection was submitted successfully.',
      severity: 'success',
    })
  }

  const handleClose = () => {
    onClose()
  }

  const handleReasonChange: AutocompleteProps<
    Catalog_Case_Purchase_Decline_Reason,
    false,
    false,
    false
  >['onChange'] = (_evt, rejectionReason) => {
    const reason = casePurchaseDeclineReasons.find(
      ({ id }) => id === rejectionReason?.id
    )

    setSelectedRejectionReason(reason)
  }

  return {
    alert,
    selectedRejectionReason,
    casePurchaseDeclineReasons,
    handleSnackBarClose,
    loading:
      casePurchaseDeclineReasonsLoading ||
      createCaseActivityPurchaseLoading ||
      loading,
    isSubmitting: createCaseActivityPurchaseLoading || loading,
    handleAcceptClick,
    handleClose,
    handleReasonChange,
  }
}

import React, { useState, useCallback, useMemo } from 'react'
import {
  Button,
  IconButton,
  Box,
  Typography,
  SwipeableDrawer,
  FormControlLabel,
  Switch,
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import CloseIcon from '@mui/icons-material/Close'
import { ConfirmationModal } from '@/components'
import { useSizes } from '@/hooks/useSizes'
import { useStats } from 'react-instantsearch'
import AlgoliaSelect from './AlgoliaSelect'
import { marketplaceFilters } from '../config'
import { useAlgoliaMarketplaceContext } from '../context/useAlgoliaMarketplaceContext'

const AlgoliaMoreFilters: React.FC = React.memo(() => {
  const [open, setOpen] = useState(false)
  const { isLessMd } = useSizes()
  const { handleClearFilters } = useAlgoliaMarketplaceContext()
  const {
    showCasesFromMyOrg,
    setshowCasesFromMyOrg,
    showHiddenCases,
    setShowHiddenCases,
  } = useAlgoliaMarketplaceContext()

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  const { nbHits } = useStats()
  const formattedNbHits = new Intl.NumberFormat('en-US').format(nbHits)

  const handleApply = useCallback(() => {
    handleClose()
  }, [handleClose])

  const filterContent = useMemo(
    () => (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {marketplaceFilters
          .filter(filter =>
            isLessMd ? filter.showOnMobile : filter.showInModal
          )
          .map(filter => (
            <AlgoliaSelect
              key={filter.attribute}
              attribute={filter.attribute}
              renderOption={filter.renderOption}
            />
          ))}
        <FormControlLabel
          control={
            <Switch
              checked={showCasesFromMyOrg}
              onChange={event => setshowCasesFromMyOrg(event.target.checked)}
            />
          }
          label="Include cases from my firm"
        />
        <FormControlLabel
          control={
            <Switch
              checked={showHiddenCases}
              onChange={event => setShowHiddenCases(event.target.checked)}
            />
          }
          label="Include hidden cases"
        />
      </Box>
    ),
    [
      isLessMd,
      showCasesFromMyOrg,
      setshowCasesFromMyOrg,
      showHiddenCases,
      setShowHiddenCases,
    ]
  )

  if (isLessMd) {
    return (
      <>
        <Button onClick={handleOpen} variant="text">
          <FilterListIcon />
        </Button>
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          PaperProps={{
            sx: {
              height: '100%',
            },
          }}
        >
          <Box
            sx={{
              position: 'relative',
              px: 4,
              py: 4,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{ position: 'absolute', right: 22, top: 22 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" sx={{ textAlign: 'left', mb: 2 }}>
              Filter Cases
            </Typography>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', pt: 1 }}>
              {filterContent}
            </Box>
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                justifyContent: 'end',
              }}
            >
              <Button
                size="large"
                variant="text"
                color="primary"
                onClick={handleClearFilters}
              >
                Clear Filters
              </Button>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={handleApply}
              >
                View Cases ({formattedNbHits})
              </Button>
            </Box>
          </Box>
        </SwipeableDrawer>
      </>
    )
  }

  return (
    <>
      <Button
        onClick={handleOpen}
        color="primary"
        sx={{
          whiteSpace: 'nowrap',
          minWidth: 'auto',
        }}
      >
        <FilterListIcon />
      </Button>
      <ConfirmationModal
        open={open}
        onClose={handleClose}
        title="More Filters"
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '500px',
          },
        }}
        AcceptButtonProps={{
          children: `View Cases (${formattedNbHits})`,
          onClick: handleApply,
        }}
        CancelButtonProps={{
          children: 'Clear Filters',
          onClick: handleClearFilters,
        }}
      >
        {filterContent}
      </ConfirmationModal>
    </>
  )
})

export default AlgoliaMoreFilters

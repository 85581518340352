import Grid, { GridProps } from '@mui/material/Grid'
import { useContext } from 'react'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { Attorney } from '@/pages/MyReferralsV2/types'
import { CASE_STATUS_FLOW } from '@/constants'
import { ReferralContext } from './ReferralContext'
import { ReferralAttorneyAvatar } from './ReferralAttorneyAvatar'
import { WaterfallCountdown } from './WaterfallCountdown'
import { ReferralAttorneyAvatarGroup } from './ReferralAttorneyAvatarGroup'

interface InvolvedAttorneysProps extends GridProps {
  counterparts: Attorney[]
  /**
   * Whether to present the attorneys in a condensed manner.
   */
  condense?: boolean

  waterfallProposalDueDate: Date
  showWaterfallProposalDueDate: boolean
}

export const InvolvedAttorneys = ({
  counterparts,
  condense,
  waterfallProposalDueDate,
  showWaterfallProposalDueDate,
  ...rest
}: InvolvedAttorneysProps) => {
  const theme = useTheme()
  const {
    caseEntry,
    caseEntry: { attorney: sellerAttorney, status },
    isUserSeller,
    showUserSidePrincipal,
  } = useContext(ReferralContext)

  const buyerAttorney =
    caseEntry.case_purchase.length > 0
      ? caseEntry.case_purchase[0].prospect_attorney
      : null

  const userSideAttorney = isUserSeller ? sellerAttorney : buyerAttorney

  const isCaseExpired = status === CASE_STATUS_FLOW.Expired

  return (
    <Grid
      container
      columnGap={{ xs: 4, md: condense ? 2 : 0 }}
      gap={2}
      display="flex"
      flexDirection={condense ? 'column' : 'row'}
      justifyContent="flex-start"
      {...rest}
    >
      {condense ? (
        <>
          {counterparts.length === 1 && (
            <Grid
              item
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <ReferralAttorneyAvatar
                attorney={counterparts[0]}
                fitToSingleLine
              />
              {showWaterfallProposalDueDate && (
                <WaterfallCountdown
                  waterfallProposalDueDate={waterfallProposalDueDate}
                />
              )}
            </Grid>
          )}
          {showUserSidePrincipal && userSideAttorney && !isCaseExpired && (
            <Grid item display="flex" alignItems="center" gap={1}>
              <Typography variant="caption" color={theme.palette.text.disabled}>
                On behalf of
              </Typography>
              <ReferralAttorneyAvatar
                attorney={userSideAttorney}
                fitToSingleLine
              />
            </Grid>
          )}
        </>
      ) : (
        <>
          {counterparts.length === 1 && (
            <Grid
              item
              xs={showUserSidePrincipal && userSideAttorney ? 6 : 12}
              pr={{ lg: 0.5 }}
            >
              <ReferralAttorneyAvatar
                attorney={counterparts[0]}
                fitToSingleLine
              />
            </Grid>
          )}
          {showUserSidePrincipal && userSideAttorney && (
            <Grid item xs={counterparts.length ? 6 : 12} pl={{ lg: 0.5 }}>
              <ReferralAttorneyAvatar
                attorney={userSideAttorney}
                fitToSingleLine
              />
            </Grid>
          )}
        </>
      )}
      {counterparts.length > 1 && (
        <Grid
          item
          xs={showUserSidePrincipal && userSideAttorney ? 6 : 12}
          pl={{ lg: 0.5 }}
        >
          <ReferralAttorneyAvatarGroup attorneys={counterparts} />
        </Grid>
      )}
    </Grid>
  )
}

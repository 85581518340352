import { useMemo, useEffect, useState } from 'react'

import { useSizes } from '@/hooks/useSizes'
import {
  Organization_User,
  useGetOrganizationMembersLazyQuery,
} from '@/gql/appApi'
import { useUsersMeQuery } from '@/gql/systemApi'
import { useSnackBar } from '@/hooks/useSnackBar'
import useOrganizationId from '@/hooks/organization/useOrganizationId'
import { getActiveMemberColumns } from './components/activeMemberColumns'

const initModalProps = {
  open: false,
  member: {},
  isEditing: false,
  onSubmit: () => {},
}
const initModalDProps = {
  open: false,
  id: '',
}

const useActiveMembersTab = () => {
  const [filteredData, setFileterdData] = useState<Organization_User[]>([])
  const [searchText, setSearchText] = useState('')
  const [modalProps, setModalProps] = useState(initModalProps)
  const [modalDProps, setModalDProps] = useState(initModalDProps)
  const { data: orgData } = useUsersMeQuery()
  const { id, loading: orgQueryLoading } = useOrganizationId()

  const { alert, showAlert, handleClose: handleCloseSnack } = useSnackBar()

  const [getOrganizationMember, { data, loading, error }] =
    useGetOrganizationMembersLazyQuery({ fetchPolicy: 'network-only' })

  useEffect(() => {
    if (id && orgData?.users_me?.id) {
      getOrganizationMember({
        variables: {
          filter: {
            _and: [
              {
                organization_id: {
                  id: {
                    _eq: id,
                  },
                },
                user_id: {
                  status: {
                    _neq: 'archived',
                  },
                },
              },
            ],
          },
        },
      })
    }
  }, [id, orgData, getOrganizationMember])

  const { isLessMd } = useSizes()

  useEffect(() => {
    if (error) {
      showAlert({
        severity: 'error',
        message: 'Something went wrong, please try again in a few minutes',
      })
    }
  }, [error, showAlert])

  useEffect(() => {
    if (data?.organization_user) {
      const orgMembers = data.organization_user.map(item => ({
        ...item,
        user_id: {
          ...item.user_id,
          first_name: item.user_id?.attorneys?.[0]?.profiles?.[0]?.first_name,
          last_name: item.user_id?.attorneys?.[0]?.profiles?.[0]?.last_name,
        },
      }))
      if (searchText === '') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        setFileterdData(orgMembers)
      } else {
        const fData: Organization_User[] = []
        orgMembers.forEach(u => {
          const fullName = `${u?.user_id?.attorneys?.[0]?.profiles?.[0]?.first_name} ${u?.user_id?.attorneys?.[0]?.profiles?.[0]?.last_name}`
          if (fullName?.toLowerCase().includes(searchText.toLowerCase())) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            fData.push(u)
          }
        })
        setFileterdData(fData)
      }
    }
  }, [data, searchText])

  const memberColumns = useMemo(() => getActiveMemberColumns(), [])

  return {
    filteredData,
    setSearchText,
    loading: loading || orgQueryLoading,
    isLessMd,
    memberColumns,
    modalProps,
    setModalProps,
    initModalProps,
    modalDProps,
    setModalDProps,
    initModalDProps,
    alert,
    handleCloseSnack,
  }
}

export default useActiveMembersTab

import ModalHeader from '@/components/Modals/ModalHeader/ModalHeader'
import Button from '@mui/material/Button'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'

const WaterfallReferralInfoModal = ({ onClose, ...props }: DialogProps) => (
  <Dialog scroll="paper" onClose={onClose} {...props}>
    <ModalHeader title="How Waterfall Referrals™ works" onClose={onClose} />
    <DialogContent>
      <Typography
        variant="body1"
        sx={{
          mb: 2,
        }}
      >
        Waterfall Referrals™ give attorneys you select{' '}
        <strong>priority access to a case</strong>. You can select up to 3
        attorneys and assign them a specific order per waterfall.
        <br />
        <br />
        The case will be visible only to the first attorney in the list for a
        set time when it&apos;s created. If your colleague isn&apos;t interested
        or doesn&apos;t sign the client, the case will{' '}
        <strong>automatically move to the next attorney</strong>. If none of the
        users in the waterfall sign the client, the case will be published on
        the marketplace. <br />
        <br />
        <strong>
          Waterfall-referred users won&apos;t have to pay a match fee if they
          sign the client.
        </strong>
      </Typography>
    </DialogContent>
    <DialogActions
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: 3,
      }}
    >
      <Button
        variant="text"
        onClick={event => onClose?.(event, 'backdropClick')}
      >
        Close
      </Button>
    </DialogActions>
  </Dialog>
)

export default WaterfallReferralInfoModal

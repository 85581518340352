import React from 'react'
import {
  Star,
  StarOutline,
  VisibilityOffOutlined,
  Visibility,
  EditOutlined,
  DeleteOutlined,
} from '@mui/icons-material'
import { ChipProps } from '@mui/material'
import { Case, CaseContextType } from '../../types'

export interface ActionConfig {
  action: keyof Case['actions']
  getIcon: (caseData: Case) => React.ReactElement
  getLabel: (caseData: Case) => string
  color?: ChipProps['color']
  actionHandler: (caseData: Case, contextActions: CaseContextType) => void
}

export const actionConfigs: ActionConfig[] = [
  {
    action: 'watch_case',
    getIcon: caseData =>
      caseData.mp_preferences.is_watching ? <Star /> : <StarOutline />,
    getLabel: caseData =>
      caseData.mp_preferences.is_watching ? 'Stop Watching' : 'Start Watching',
    actionHandler: (caseData, { watchCase }) => {
      watchCase(!caseData.mp_preferences.is_watching)
    },
  },
  {
    action: 'hide_case',
    getIcon: caseData =>
      caseData.mp_preferences.is_hidden ? (
        <Visibility />
      ) : (
        <VisibilityOffOutlined />
      ),
    getLabel: caseData => (caseData.mp_preferences.is_hidden ? 'Show' : 'Hide'),
    actionHandler: (caseData, { hideCase }) => {
      hideCase(!caseData.mp_preferences.is_hidden)
    },
  },
  {
    action: 'edit_case',
    getIcon: () => <EditOutlined />,
    getLabel: () => 'Edit',
    actionHandler: (_, { editCase }) => {
      editCase()
    },
  },
  {
    action: 'delete_case',
    getIcon: () => <DeleteOutlined />,
    getLabel: () => 'Remove',
    color: 'error',
    actionHandler: (_, { deleteCase }) => {
      deleteCase()
    },
  },
]

import React from 'react'
import { Box, Typography, Avatar, Badge, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useCaseContext } from '../../../hooks/useCaseContext'
import { ActionButtons } from './ActionButtons'

export const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    fontFamily: 'inherit',
    fontSize: '.75rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '12px',
  color: theme.palette.text.disabled,
  marginBottom: theme.spacing(1.5),
}))

const Header: React.FC = () => {
  const { caseData } = useCaseContext()

  if (!caseData?.sidebar?.header) return null

  const { header } = caseData.sidebar

  const hasActions = Object.values(header.actions || {}).some(action => action)
  const hasFee = !!header.fee

  const hasHeaderContent =
    header.title ||
    header.description ||
    header.counterpart ||
    hasActions ||
    hasFee ||
    header.on_behalf_of

  if (!hasHeaderContent) return null

  return (
    <Box
      sx={{ py: 2, px: 3, display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      {header.title && (
        <Typography variant="subtitle1">{header.title}</Typography>
      )}
      {header.description && (
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {header.description}
        </Typography>
      )}
      {header.counterpart && (
        <Box>
          <StyledTypography variant="body2">Counterpart</StyledTypography>
          <Box display="flex" alignItems="start">
            <Avatar
              src={header.counterpart.avatar_image || ''}
              alt={header.counterpart.name}
              sx={{ width: 40, height: 40 }}
            />
            <Box ml={2} display="flex" flexDirection="column" gap={1}>
              <Box>
                <Typography variant="subtitle2">
                  {header.counterpart.name}
                </Typography>
                <Typography variant="body2" color="text.disabled">
                  {header.counterpart.firm.name}
                </Typography>
              </Box>
              {header.counterpart.contact && (
                <Box display="flex" flexDirection="column" gap={0.5}>
                  {header.counterpart.contact.email && (
                    <Typography variant="body2" color="text.secondary">
                      {header.counterpart.contact.email}
                    </Typography>
                  )}
                  {header.counterpart.contact.phone && (
                    <Typography variant="body2" color="text.secondary">
                      {header.counterpart.contact.phone}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
      {hasFee && (
        <Box display="flex" alignItems="baseline">
          <Typography variant="h6">{header.fee}</Typography>
          <Typography variant="subtitle2">%</Typography>
          <Typography variant="overline" color="text.disabled" ml={0.5}>
            REFERRAL/CO-COUNSEL FEE
          </Typography>
        </Box>
      )}
      {header.on_behalf_of && (
        <Box display="flex" alignItems="center">
          <Typography
            variant="caption"
            color="text.disabled"
            sx={{ flexShrink: 0 }}
          >
            On behalf of
          </Typography>
          <Avatar
            src={header.on_behalf_of.avatar_image || ''}
            alt={header.on_behalf_of.name}
            sx={{ width: 24, height: 24, ml: 1 }}
          />
          <Tooltip title={header.on_behalf_of.name}>
            <Typography
              variant="body2"
              ml={1}
              sx={{
                maxWidth: 'auto',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {header.on_behalf_of.name}
            </Typography>
          </Tooltip>
        </Box>
      )}

      {(hasActions || header.message_channels?.length > 0) && (
        <Box display={{ xs: 'none', md: 'block' }}>
          <ActionButtons
            actions={header.actions}
            messageChannels={header.message_channels}
          />
        </Box>
      )}
    </Box>
  )
}

export default Header

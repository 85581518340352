import { useMemo } from 'react'
import Alert from '@mui/material/Alert'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import AuthLayout from '@/components/shared/AuthLayout'
import RecoveryForm from './components/RecoveryForm'
import {
  RECOVERY_FLOW_STATES,
  useForgotPassword,
} from './hooks/useForgotPassword'
import InvalidExpiredLink from './components/InvalidExpiredLink'
import CreateNewPassword from './components/CreateNewPassword'
import PasswordSuccess from './components/PasswordSuccess'

const ForgotPassword = () => {
  const {
    flow,
    handlePasswordReset,
    handleEmailSubmit,
    goToSignIn,
    isLoading,
    validationLoading,
    error,
    setError,
  } = useForgotPassword()

  const renderComponent = useMemo(() => {
    switch (flow) {
      case RECOVERY_FLOW_STATES.INITIAL:
        return (
          <RecoveryForm
            onSubmit={handleEmailSubmit}
            loading={isLoading}
            back={goToSignIn}
          />
        )
      case RECOVERY_FLOW_STATES.EMAIL_SENT_SUCCESS:
        return (
          <PasswordSuccess
            title="The email has been sent successfully"
            subtitle="Please check your inbox and open the link we sent to continue. If you don’t see it, please check your spam folder."
            btnText="Back to Sign In"
          />
        )
      case RECOVERY_FLOW_STATES.CREATE_NEW_PASSWORD:
        return (
          <CreateNewPassword
            onSubmit={handlePasswordReset}
            loading={isLoading}
          />
        )
      case RECOVERY_FLOW_STATES.PASSWORD_RESET_SUCCESS:
        return (
          <PasswordSuccess
            title="Your password has been changed successfully"
            subtitle="Please remember to keep your new password secure and avoid sharing it with anyone. If you have any further questions or need assistance, please don't hesitate to contact our support team."
            btnText="Access your Account"
          />
        )
      case RECOVERY_FLOW_STATES.INVALID:
        return <InvalidExpiredLink />
      case RECOVERY_FLOW_STATES.EXPIRED:
        return <InvalidExpiredLink isExpired />
      default:
        return <div>Page not found</div>
    }
  }, [flow, handleEmailSubmit, isLoading, goToSignIn, handlePasswordReset])

  return (
    <AuthLayout>
      {validationLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={validationLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {error && (
        <Alert severity="error" onClose={() => setError('')}>
          {error}
        </Alert>
      )}
      {renderComponent}
    </AuthLayout>
  )
}

export default ForgotPassword

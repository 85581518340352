import ConfirmationModal from '@/components/Modals/ConfirmationModal'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

export interface INoProfessionalLicenseModalProps {
  open: boolean
  onClose: () => void
  onAccept: () => void
  loading?: boolean
}

const BulletedListItem = ({ text }: { text: string }) => (
  <ListItem
    sx={{
      display: 'list-item',
      listStyleType: 'disc',
      paddingLeft: '2px',
      marginLeft: 3,
      '&::marker': {
        fontSize: '1.25rem',
      },
    }}
  >
    <Typography variant="body1">{text}</Typography>
  </ListItem>
)

const NoProfessionalLicenseModal = ({
  open,
  onClose,
  onAccept,
  loading,
}: INoProfessionalLicenseModalProps) => (
  <ConfirmationModal
    title="Confirmation Required"
    open={open}
    AcceptButtonProps={{
      children: `I'm not an attorney`,
      onClick: onAccept,
      disabled: loading,
    }}
    onClose={onClose}
  >
    <Typography variant="subtitle1">
      You will be creating your account without attorney permissions.
    </Typography>
    <List>
      <BulletedListItem text="When creating a case or sending a proposal, it must be done on behalf of a licensed attorney in your law firm." />
      <BulletedListItem text="You cannot be invited to cases, and your profile will only be visible to members of your organization." />
    </List>
  </ConfirmationModal>
)

export default NoProfessionalLicenseModal

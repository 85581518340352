import { useForm, useFieldArray } from 'react-hook-form'

import {
  useBulkUpdateNetworkUserInviteMutation,
  GetNetworkUserInvitesSentDocument,
  Create_Network_User_Invite_Input,
} from '@/gql/appApi'
import { useSnackBar } from '@/hooks/useSnackBar'
import { useUsersMeQuery } from '@/gql/systemApi'

export interface UseInviteAttorneyModalProps {
  onSubmit: () => void
}

export type InvitationType = {
  email: string
  firstName: string
  lastName: string
}

const useInviteToNetworkModal = ({ onSubmit }: UseInviteAttorneyModalProps) => {
  const { alert, showAlert, handleClose: handleCloseSnack } = useSnackBar()
  const { data: userMe } = useUsersMeQuery()
  const userId = userMe?.users_me?.id

  const [updateNetworkUserInvite, { loading: loadingUpdate }] =
    useBulkUpdateNetworkUserInviteMutation({
      refetchQueries: [GetNetworkUserInvitesSentDocument],
    })

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<{
    attorneys: InvitationType[]
  }>({
    defaultValues: {
      attorneys: [
        { email: '', firstName: '', lastName: '' },
        { email: '', firstName: '', lastName: '' },
        { email: '', firstName: '', lastName: '' },
        { email: '', firstName: '', lastName: '' },
      ],
    },
    mode: 'onBlur',
  })
  const { fields, append } = useFieldArray({
    name: 'attorneys',
    control,
  })

  const onSendInvitation = async (form: { attorneys: InvitationType[] }) => {
    const payload: Create_Network_User_Invite_Input[] = []
    form.attorneys.forEach(att => {
      if (att.email !== '') {
        const newInvitation: Create_Network_User_Invite_Input = {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          sender_user_id: userId ?? '',
          email: att.email?.toLowerCase() ?? '',
          first_name: att.firstName,
          last_name: att.lastName,
        }

        payload.push(newInvitation)
      }
    })

    if (payload.length === 0) {
      return
    }

    try {
      await updateNetworkUserInvite({
        variables: {
          data: payload,
        },
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)

      showAlert({
        severity: 'error',
        message:
          payload.length > 1
            ? 'There was an error sending your invitations. Please try again in a moment.'
            : 'There was an error sending your invitation. Please try again in a moment.',
      })

      return
    }

    showAlert({
      severity: 'success',
      message:
        payload.length > 1
          ? 'Invitations sent successfully!'
          : 'Invitation sent successfully!',
    })

    reset()
    onSubmit()
  }

  return {
    handleSubmit,
    onSendInvitation,
    loading: loadingUpdate,
    fields,
    register,
    control,
    append,
    watch,
    errors,
    reset,
    alert,
    handleCloseSnack,
  }
}

export default useInviteToNetworkModal

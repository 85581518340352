import { ALPHANUMERIC_REGEXP } from '@/constants'
import { object, ref, string } from 'yup'

const editPasswordSchema = object({
  currentPassword: string().required('Current password is required'),
  newPassword: string()
    .min(8, 'Password must be at least 8 characters')
    .max(25, 'Password must be no more than 25 characters')
    .matches(ALPHANUMERIC_REGEXP, 'Must be alphanumeric')
    .required('New password is required'),
  repeatPassword: string()
    .oneOf([ref('newPassword')], 'Passwords do not match')
    .required('Please confirm your new password'),
}).required()

const defaultValues = {
  newPassword: '',
  repeatPassword: '',
  currentPassword: '',
}
export { editPasswordSchema, defaultValues }

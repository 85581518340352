import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const LeadDocketIcon = ({
  height = 48,
  width = 48,
  ...props
}: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z"
        fill="#EDCF4B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.2827 15H15V19.1069H34.5066L35.3928 17.0578L36.2826 15"
        fill="#184043"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.4548 21.571H15.0544V25.678H31.6785L32.5682 23.6212L33.4545 21.571"
        fill="#184043"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5904 28.2852H15.0002V32.3924H28.8143L29.705 30.3327L30.5904 28.2852Z"
        fill="#184043"
      />
    </svg>
  </SvgIcon>
)

export default LeadDocketIcon

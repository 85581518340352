import { Attorney_Profile_Firm } from '@/gql/systemApi'
import { Firm, PracticeArea, ProfileType } from '@/types'

export const getPracticesAreas = (practiceAreas: PracticeArea[]) =>
  practiceAreas
    ?.map(pArea => pArea.practice_area_id.name)
    ?.sort()
    ?.join(', ')

export const getFirms = (firms: (Attorney_Profile_Firm | Firm)[]) =>
  firms.map(f => f.name)

export const getFullname = (profile: ProfileType) =>
  `${profile.first_name} ${profile.last_name}`

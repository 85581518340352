import { USER_ROLES } from '@/constants/index'
import { RoutesData } from '@/router/RoutesData'

import { getAdminPanelFirstLink } from '@/pages/AdminPanel/utils/constants'

export const getSignInRedirectionByRole = (roleId: string) => {
  switch (roleId) {
    case USER_ROLES.AdminUser:
    case USER_ROLES.SuperAdminUser:
      return getAdminPanelFirstLink()
    default:
      return RoutesData.RMarketplace.path
  }
}

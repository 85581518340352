import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'

import Header from '@/components/Modals/ModalHeader/ModalHeader'
import Footer from '@/components/Modals/ModalFooter/ModalFooter'
import ModalContent from '@/components/Modals/ModalContent/ModalContent'
import { Typography } from '@mui/material'
import { ZENDESK_HELP_URL } from '@/constants'

export interface CaseNotAvailableModalProps extends DialogProps {
  title?: string
  subtitle?: string
  secondSubtitle?: string
  onSubmit?: () => void
}

const CaseNotAvailableModal = ({
  onClose,
  title = 'Case No Longer Available',
  subtitle = 'The case you are trying to access is not available.',
  secondSubtitle = "It's possible that the case has been deleted or that you don't have permission to access it.",
  onSubmit,
  ...rest
}: CaseNotAvailableModalProps) => (
  <Dialog onClose={onClose} {...rest}>
    <Header title={title} onClose={onClose} />
    <ModalContent
      sx={theme => ({
        padding: theme.spacing(3, 3, 0, 3),
        maxWidth: '444px',
      })}
    >
      <Typography variant="subtitle1">{subtitle}</Typography>
      <Typography variant="body1" sx={{ mt: 3 }}>
        {secondSubtitle}
      </Typography>
    </ModalContent>
    <Footer sx={{ justifyContent: 'center', flexDirection: 'column' }}>
      <Typography
        variant="body1"
        sx={{
          textAlign: 'left',
        }}
      >
        If you have additional questions, contact our{' '}
        <Link
          href={ZENDESK_HELP_URL}
          target="_blank"
          rel="noreferrer"
          sx={theme => ({
            textDecorationColor: theme.palette.primary.main,
          })}
        >
          Help Center
        </Link>
      </Typography>
    </Footer>
    <DialogActions>
      <Button
        variant="contained"
        onClick={() => {
          if (onSubmit) {
            onSubmit()
            return
          }
          window.location.reload()
        }}
        sx={{ mr: 2, mb: 2 }}
        size="large"
      >
        Understood
      </Button>
    </DialogActions>
  </Dialog>
)

export default CaseNotAvailableModal

import React, { useState } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Autocomplete from '@mui/material/Autocomplete'

import { Controller, Control } from 'react-hook-form'
import { Checkbox } from '@/components'
import { Collapse, Typography, useTheme } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { Search } from '@mui/icons-material'
import JDropdownUser from '@/components/atoms/JDropdownUser'
import { getImagePath } from '@/utils'

import { IMAGE_SIZES } from '@/constants'
import { Attorney, VettedAttorneySearchProps } from '../types'

interface IAdminForm {
  adminProposal: boolean
}

interface AdminProposalCheckboxProps {
  handleCheckboxChange: (event: React.SyntheticEvent, checked: boolean) => void
  control: Control<IAdminForm, any>
  rules: object
  onlyOnBehalf?: boolean
  isCaseManager?: boolean
}

const AdminProposalCheckbox: React.FC<AdminProposalCheckboxProps> = ({
  handleCheckboxChange,
  control,
  rules,
  onlyOnBehalf = false,
  isCaseManager,
}) => {
  const tooltipTitle = onlyOnBehalf
    ? `As a ${
        isCaseManager ? 'Case Manager' : 'non-JHQ Member'
      } you can only send the proposal on behalf of another attorney at your firm`
    : ''
  const adminProposalLabel = (
    <Tooltip title={tooltipTitle}>
      <Typography component="span" variant="body1">
        I want to send the proposal on behalf of another attorney at my firm
      </Typography>
    </Tooltip>
  )

  return (
    <Controller
      control={control}
      name="adminProposal"
      rules={rules}
      render={({ field: { onChange, value, ...field }, fieldState }) => (
        <Checkbox
          {...field}
          checked={Boolean(onlyOnBehalf || value)}
          sx={theme => ({
            pr: { xs: theme.spacing(1) },
            mb: theme.spacing(1),
            position: 'relative',
            left: '-11px',
          })}
          checkboxProps={{
            label: adminProposalLabel,
            isCentered: true,
            error: fieldState.invalid,
            helperText: fieldState.error?.message,
          }}
          onChange={(e, checked) => {
            if (!onlyOnBehalf) {
              onChange(e)
              handleCheckboxChange(e, checked)
            }
          }}
        />
      )}
    />
  )
}

const VettedAttorneySearch: React.FC<VettedAttorneySearchProps> = ({
  control,
  onSelect,
  vettedAttorneys,
  isAdmin,
  isCaseManager,
  onlyOnBehalf = false,
}) => {
  const theme = useTheme()
  const [checkboxChecked, setCheckboxChecked] = useState(onlyOnBehalf)
  const [selectedAttorney, setSelectedAttorney] = useState<Attorney | null>(
    null
  )
  const hasVettedAttorneys = vettedAttorneys?.length > 0

  const handleCheckboxChange = (
    _event: React.SyntheticEvent,
    checked: boolean
  ) => {
    setCheckboxChecked(checked)
    if (!checked) {
      onSelect(null)
      setSelectedAttorney(null)
    }
  }

  const showAutocomplete =
    ((isAdmin && checkboxChecked) || !isAdmin) && hasVettedAttorneys

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mb: theme.spacing(2),
      }}
    >
      {isAdmin ? (
        <AdminProposalCheckbox
          control={control}
          rules={{}}
          handleCheckboxChange={handleCheckboxChange}
          onlyOnBehalf={onlyOnBehalf}
          isCaseManager={isCaseManager}
        />
      ) : null}
      <Collapse in={showAutocomplete}>
        <Autocomplete
          sx={{
            mb: theme.spacing(3),
          }}
          value={selectedAttorney}
          options={vettedAttorneys}
          getOptionLabel={option => `${option.first_name} ${option.last_name}`}
          noOptionsText="No attorneys found"
          renderInput={params => (
            <TextField
              color="secondary"
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment
                      position="start"
                      sx={{
                        pl: 1,
                      }}
                    >
                      <Search color="disabled" />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
              placeholder="Proposal on behalf of *"
            />
          )}
          onChange={(_, value) => {
            setSelectedAttorney(value)
            onSelect(value)
          }}
          renderOption={(props, option) => (
            <JDropdownUser
              key={option.id}
              user={{
                id: option.id,
                name: `${option.first_name} ${option.last_name}`,
                picture: getImagePath({
                  id: option?.avatar_id ?? null,
                  size: IMAGE_SIZES.Small,
                }),
                isVetted: option?.is_vetted || false,
                isQualified: option?.is_qualifed || false,
              }}
              {...props}
            />
          )}
        />
      </Collapse>
    </Box>
  )
}

export default VettedAttorneySearch

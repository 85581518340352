import { useState } from 'react'
import { SubmitHandler, useForm, FieldValues } from 'react-hook-form'

import {
  CASE_PURCHASE_STATUS,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
} from '@/constants'
import useAttorneyId from '@/hooks/useAttorneyId'
import { JCaseType } from '@/types'
import { useAxios } from '@/axios/config'
import setAuthToken from '@/axios/setAuthToken'
import { AxiosError } from 'axios'
import initApollo from '@/apollo'
import {
  Create_Case_Purchase_Ownership_Input,
  GetActivityPurchaseDocument,
  GetActivityPurchaseFromMyOrgDocument,
  GetCasesDocument,
  useCaseExistsLazyQuery,
  useCreateCasePurchaseOwnershipItemMutation,
  useCreateCreditsPaymentMutation,
} from '@/gql/appApi'
import { useStripe } from '@/hooks/useStripe'
import useAttorneyEmail from '@/hooks/useAttorneyEmail'
import { RoutesData } from '@/router/RoutesData'
import { generatePath } from 'react-router-dom'
import { MembersPricesResponse } from '@/utils/api/types'
import {
  SessionKey,
  sessionStorageService,
} from '@/services/SessionStorage/SessionStorageService'
import { IPendingPayment } from './types'

export interface UseSendProposalModalProps {
  onSubmit: ((fee?: number, message?: string) => void) | (() => void)
  jCase: JCaseType
  isInvited?: boolean
  defaultOffer?: number
  onBehalfOfAttorneyId?: string | null
  onBehalfOfUserId?: string | null
  loggedUserId?: string
  credits?: string | number
  discount: MembersPricesResponse['discount_stripe_price_id'] | null
  /**
   * `pendingPayment` is only required when the case purchase is in
   * the `PendingPaymentAuthorization` status.
   */
  pendingPayment?: IPendingPayment
}

const useSendProposalModal = ({
  onSubmit,
  jCase,
  isInvited,
  defaultOffer = 0,
  onBehalfOfAttorneyId,
  onBehalfOfUserId,
  loggedUserId,
  credits,
  discount,
  pendingPayment: {
    isPendingPayment,
    casePurchaseId: casePurchaseIdPendingPayment,
    onBehalfOfAttorneyId: onBehalfOfAttorneyIdPendingPayment,
    onBehalfOfUserId: onBehalfOfUserIdPendingPayment,
  } = {
    isPendingPayment: false,
    casePurchaseId: undefined,
    onBehalfOfAttorneyId: undefined,
    onBehalfOfUserId: undefined,
  },
}: UseSendProposalModalProps) => {
  const { createCreditCardCaptureSession } = useStripe()
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = getAttorneyId()
  const { getAttorneyEmail } = useAttorneyEmail()
  const attorneyEmail = getAttorneyEmail()
  const [negotiateFee, setNegotiateFee] = useState<boolean>(false)
  const { control, handleSubmit } = useForm({
    defaultValues: {
      offer: defaultOffer,
      message: '',
      adminProposal: false,
      termsAndConditions: false,
    },
  })
  const [error, setError] = useState('')
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const [{ loading: casePurchaseLoading }, createCasePurchase] = useAxios({
    url: '/casepurchase',
    method: 'POST',
  })

  const [
    createCasePurchaseOwnershipItemMutation,
    { loading: ownershipLoading },
  ] = useCreateCasePurchaseOwnershipItemMutation({
    refetchQueries: [
      GetActivityPurchaseDocument,
      'case_purchase_activity',
      GetCasesDocument,
      'case',
      GetActivityPurchaseFromMyOrgDocument,
      'case_purchase_activity',
    ],
  })

  const [caseExists, { loading: caseExistsLoading }] = useCaseExistsLazyQuery({
    variables: {
      filter: {
        id: {
          _eq: jCase.id,
        },
      },
    },
    fetchPolicy: 'network-only',
  })

  const [payWithCredits, { loading: payWithCreditsLoading }] =
    useCreateCreditsPaymentMutation({
      refetchQueries: [
        GetActivityPurchaseDocument,
        'case_purchase_activity',
        GetCasesDocument,
        'case',
        GetActivityPurchaseFromMyOrgDocument,
        'case_purchase_activity',
      ],
    })

  const loading =
    ownershipLoading ||
    casePurchaseLoading ||
    isSubmitLoading ||
    caseExistsLoading ||
    payWithCreditsLoading

  const onSubmitAction = async (
    fee: number,
    caseId: string,
    message?: string,
    caseStatus?: string
  ) => {
    const data = [
      {
        prospect_attorney_id: onBehalfOfAttorneyId || attorneyId,
        status: caseStatus || CASE_PURCHASE_STATUS.Proposed,
        created_by: attorneyId,
        case_id: caseId,
        ...(fee !== undefined &&
          fee !== null && {
            activity: [
              {
                activity_entry_status:
                  caseStatus || CASE_PURCHASE_STATUS.Proposed,
                created_by: onBehalfOfAttorneyId || attorneyId,
                fee,
                message: message || '',
              },
            ],
          }),
      },
    ]

    const token = sessionStorageService.getItem(SessionKey.TOKEN)
    setAuthToken(token)

    const response = await createCasePurchase({ data })

    if (response?.data?.data?.create_case_purchase_items?.[0]?.id) {
      return {
        casePurchaseId: response.data.data.create_case_purchase_items[0].id,
      }
    }
    return null
  }

  const handleError = (message: string) => {
    setError(message)
    setIsSubmitLoading(false)
  }

  const createOwnershipItem = async (
    casePurchaseId: string,
    userId: string
  ) => {
    try {
      await createCasePurchaseOwnershipItemMutation({
        variables: {
          data: {
            case_purchase_id: casePurchaseId,
            // Had to typecast to avoid a type error in the mutation. Verified in the backend that
            // user_id is a string, but the mutation is expecting a Create_Case_Purchase_Ownership_Input.
            user_id: userId as Create_Case_Purchase_Ownership_Input,
          },
        },
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      handleError('Failed to create ownership item. Please try again later.')
    }
  }

  const handleSubmitModal: SubmitHandler<FieldValues> = async form => {
    setIsSubmitLoading(true)
    setError('')
    if (!isInvited) {
      try {
        const response = await onSubmitAction(
          Number(form.offer),
          jCase.id,
          form.message
        )
        const casePurchaseId = response?.casePurchaseId

        if (onBehalfOfUserId && casePurchaseId) {
          await createOwnershipItem(casePurchaseId, onBehalfOfUserId)
        }
        await initApollo().refetchQueries({
          include: [
            GetActivityPurchaseDocument,
            'case_purchase_activity',
            GetCasesDocument,
            'case',
            GetActivityPurchaseFromMyOrgDocument,
            'case_purchase_activity',
          ],
        })
      } catch (err) {
        let errorMessage =
          'There was a problem submitting your request. Please try again later.'

        if (err instanceof AxiosError && err.response?.status === 409) {
          errorMessage =
            "There's already an existing negotiation for one or more specified attorneys for this case."
        }

        handleError(errorMessage)
        return
      }
      await onSubmit()
    } else {
      onSubmit(Number(form.offer), form.message)
    }
    setIsSubmitLoading(false)
  }

  const handleSubmitPrePaidProposal: SubmitHandler<
    FieldValues
  > = async form => {
    setIsSubmitLoading(true)
    const caseExistsResult = await caseExists()
    if (!caseExistsResult.data?.case?.[0]?.id) {
      handleError('This case no longer exists.')
      setIsSubmitLoading(false)
      return
    }

    let casePurchaseId = isPendingPayment ? casePurchaseIdPendingPayment : null

    try {
      // If the case purchase is pending payment, we skip the creation of a new case purchase
      // and just create the payment item or stripe checkout session.
      if (!isPendingPayment) {
        // Create the Case Purchase with a status of PendingPaymentAuthorization. This new status
        // was created to be able to send a case purchase id to the payment method. Once the payment
        // is completed, the status will be updated to Proposed.
        const response = await onSubmitAction(
          Number(form.offer || defaultOffer),
          jCase.id,
          form.message,
          CASE_PURCHASE_STATUS.PendingPaymentAuthorization
        )

        casePurchaseId = response?.casePurchaseId

        if (!casePurchaseId) {
          handleError(
            'There was a problem creating the case purchase. Please try again later.'
          )
          return
        }

        // If the Proposal is sent on behalf of an attorney, we need to create a Case Purchase Ownership Item
        if (onBehalfOfUserId) {
          await createOwnershipItem(casePurchaseId, onBehalfOfUserId)
        }
      }
      const representingAttorneyId = isPendingPayment
        ? onBehalfOfAttorneyIdPendingPayment
        : onBehalfOfAttorneyId || attorneyId
      const hasCredits =
        credits && (credits === 'unlimited' || Number(credits) > 0)

      if (hasCredits) {
        const creditsPaymentResult = await payWithCredits({
          variables: {
            data: {
              attorney_id: representingAttorneyId,
              case_purchase_id: casePurchaseId || '',
              payment_method: PAYMENT_METHOD.Credits,
              payment_status_id: PAYMENT_STATUS.Completed,
              amount: 0,
            },
          },
        })

        if (creditsPaymentResult.data?.create_case_purchase_payment_item?.id) {
          onSubmit()
          setIsSubmitLoading(false)
          return
        }
        handleError(
          'There was a problem processing your credits payment. Please try again later.'
        )
        setIsSubmitLoading(false)
        return
      }

      const userId = isPendingPayment
        ? onBehalfOfUserIdPendingPayment
        : onBehalfOfUserId || loggedUserId

      if (userId) {
        const casePath = generatePath(RoutesData.RCase.path, {
          id: jCase.id,
        })
        const urlBase = `${window.location.protocol}//${window.location.host}${casePath}`
        const successUrl = `${urlBase}?case_proposal_submitted=1`
        const cancelUrl = urlBase
        createCreditCardCaptureSession({
          attorney_id: representingAttorneyId || String(attorneyId),
          case_purchase_id: casePurchaseId || '',
          customer_email: attorneyEmail || '',
          user_id: userId || '',
          customSuccessUrl: successUrl,
          customCancelUrl: cancelUrl,
          ...(discount && {
            discounts: [discount],
          }),
        })
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      handleError('An unexpected error occurred. Please try again later.')
    }
  }

  return {
    handleSubmitModal,
    handleSubmitPrePaidProposal,
    control,
    handleSubmit,
    negotiateFee,
    setNegotiateFee,
    loading,
    error,
  }
}

export default useSendProposalModal

export const hasAnchorBetweenElements = (
  parent: Element,
  descendant: Element
): boolean => {
  // Validate that descendant is actually a descendant of parent
  if (!parent.contains(descendant)) {
    throw new Error('Second element must be a descendant of the first element')
  }

  // Start from the descendant and move up until we reach the parent
  let currentElement: Element | null = descendant

  while (currentElement !== parent) {
    // Check if current element is an anchor
    if (currentElement.tagName === 'A') {
      return true
    }
    // Move up to the parent
    currentElement = currentElement.parentElement

    // Safety check in case we somehow reach the top of the DOM
    if (!currentElement) {
      throw new Error(
        'Unexpected DOM structure: reached null before finding parent'
      )
    }
  }

  return false
}

import React, { ReactNode, createContext, useMemo } from 'react'

interface ICaseChatContext {
  caseId: string
  members?: string[]
  caseTitle: string
  isCaseOwner: boolean
  isCasePage: boolean
}

export interface CaseChatProviderProps {
  children: ReactNode
  caseId: string
  members?: string[]
  caseTitle: string
  isCaseOwner: boolean
  isCasePage: boolean
}

const initialContextValue: ICaseChatContext = {
  caseId: '',
  members: [],
  caseTitle: '',
  isCaseOwner: false,
  isCasePage: false,
}

export const CaseChatContext =
  createContext<ICaseChatContext>(initialContextValue)

export const CaseChatProvider: React.FC<CaseChatProviderProps> = ({
  children,
  caseId,
  members,
  caseTitle,
  isCaseOwner,
  isCasePage,
}) => {
  const providerValues = useMemo(
    () => ({
      caseId,
      members,
      caseTitle,
      isCaseOwner,
      isCasePage,
    }),
    [caseId, members, caseTitle, isCaseOwner, isCasePage]
  )
  return (
    <CaseChatContext.Provider value={providerValues}>
      {children}
    </CaseChatContext.Provider>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react'
import {
  AttorneyDocument,
  useUpdateAttorneyProfileMutation,
} from '@/gql/appApi'
import useAttorneyProfileId from '@/hooks/useAttorneyProfileId'

import { useAuthRefreshMutation } from '@/gql/systemApi'
import {
  SessionKey,
  sessionStorageService,
} from '@/services/SessionStorage/SessionStorageService'
import { NOTIFICATION_METHODS } from '@/components/CommunicationPreferences/types'
import { CONTACT_TYPES } from '@/constants'
import { CreateProfileFormState } from '../CreateProfileFormContext'

const DEFAULT_ERROR =
  'There appears to be a problem with your submission. Please try again.'

const useUpdateAttorneyProfile = () => {
  const [updateProfile, { loading: updateProfileLoading }] =
    useUpdateAttorneyProfileMutation()
  const { getAttorneyProfileId } = useAttorneyProfileId()
  const onError = useCallback(() => {
    const errors = { base: DEFAULT_ERROR }
    return {
      baseErrors: errors,
    }
  }, [])

  const [refreshAuth, { loading: refreshAuthLoading }] = useAuthRefreshMutation(
    {
      onError,
    }
  )

  const loading = updateProfileLoading || refreshAuthLoading

  const tokenRefresh = useCallback(async () => {
    const storedRefreshToken = sessionStorageService.getItem(
      SessionKey.REFRESH_TOKEN
    )
    if (!storedRefreshToken) {
      return null
    }
    const { data } = await refreshAuth({
      variables: { refreshToken: storedRefreshToken },
    })
    const token = data?.auth_refresh?.access_token
    const refreshToken = data?.auth_refresh?.refresh_token
    if (token && refreshToken) {
      sessionStorageService.setItem(SessionKey.TOKEN, `${token}`)
      sessionStorageService.setItem(SessionKey.REFRESH_TOKEN, `${refreshToken}`)
      return true
    }
    return null
  }, [refreshAuth])

  const updateAttorneyProfile = useCallback(
    async ({
      practiceAreas,
      personalInfo,
      education: educationValues,
      communicationPreferences,
      phoneNumber,
    }: CreateProfileFormState) => {
      const areas = practiceAreas.map(areaId => ({
        practice_area_id: areaId,
      }))

      const education: any[] = []

      if (educationValues.lawSchool) {
        const schoolId =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          educationValues.lawSchool?.id || educationValues.lawSchool
        education.push({
          // FIXME: Currently there is no way to select a grade in the frontend, so it is hardcoded for now.
          // Law School grade Id
          grade: '51fe2d08-2678-11ee-b8cd-43a8d813d269',
          school_id: schoolId,
        })
      }

      if (educationValues.undergradSchool) {
        education.push({
          // Under graduate grade Id
          grade: '51fda36a-2678-11ee-b8cc-2783700798fd',
          school_id:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            educationValues.undergradSchool?.id ||
            educationValues.undergradSchool,
        })
      }
      const profileId = getAttorneyProfileId()

      const updateData: any = {}

      if (personalInfo) {
        updateData.bio = personalInfo?.bio || ''
      }

      if (areas.length > 0) {
        updateData.practice_areas = areas
      }

      if (education.length > 0) {
        updateData.education = education
      }

      updateData.communication_preference = [
        {
          is_notification_enabled:
            communicationPreferences.isNotificationsEnabled,
          ...(communicationPreferences.isNotificationsEnabled && {
            notification_level: communicationPreferences.casesToNotify,
            is_email_enabled:
              communicationPreferences.notificationMethod ===
                NOTIFICATION_METHODS.EMAIL_AND_SMS ||
              communicationPreferences.notificationMethod ===
                NOTIFICATION_METHODS.EMAIL_ONLY,
            is_sms_enabled:
              communicationPreferences.notificationMethod ===
                NOTIFICATION_METHODS.EMAIL_AND_SMS ||
              communicationPreferences.notificationMethod ===
                NOTIFICATION_METHODS.SMS_ONLY,
          }),
        },
      ]

      if (phoneNumber) {
        updateData.contacts = [
          {
            type: CONTACT_TYPES.PhoneContactType,
            value: phoneNumber,
          },
        ]
      }

      try {
        const { data, errors } = await updateProfile({
          variables: {
            updateAttorneyProfileItemId: profileId ?? '',
            data: updateData,
          },
          refetchQueries: [AttorneyDocument, 'attorney'],
          awaitRefetchQueries: true,
        })
        if (data?.update_attorney_profile_item?.id) {
          return {
            id: data.update_attorney_profile_item.id,
          }
        }
        if (errors) {
          return {
            updateAttorneyProfileErrors: {
              base: 'Attorney profile update failed. Please try again.',
              server: errors[0]?.message,
            },
          }
        }
        return {}
      } catch (e: any) {
        return {
          updateAttorneyProfileErrors: {
            base: 'Attorney profile update failed. Please try again.',
            server: e?.message,
          },
        }
      }
    },
    [getAttorneyProfileId, updateProfile]
  )

  return {
    updateAttorneyProfile,
    tokenRefresh,
    loading,
  }
}

export default useUpdateAttorneyProfile

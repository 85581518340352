import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Card from '@mui/material/Card'

import JSnackBar from '@/components/atoms/JSnackBar'
import useTheme from '@mui/material/styles/useTheme'
import { ParameterName, bootParamsService } from '@/services/BootParams'
import { useSnackBar } from '@/hooks/useSnackBar'
import { IntegrationItemProps } from '../../types'

import textContent from '../../constants'
import IntegrationItemCardContent from '../shared/IntegrationItemCardContent'
import IntegrationItemButtons from '../shared/IntegrationItemButtons'
import { useMyCaseIntegrationItem } from './useMyCaseIntegrationItem'
import { isSetupSuccessful } from '../shared/utils'

const MyCaseIntegrationItem = ({
  icon,
  name,
  subtitle,
  loading,
  organizationId,
  isConnectedToOtherLcms,
  isConnected,
  refetchLcmsData,
  isConnectingToLcms,
  connectingToLcms,
  removeIntegrationItem,
}: IntegrationItemProps) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const {
    INTEGRATION_V2: { MY_CASE },
  } = textContent

  const myCaseName = MY_CASE.NAME.toLowerCase()
  const codeQueryParam = bootParamsService.get(ParameterName.MYCASE_CODE)
  const srcQueryParam = bootParamsService.get(ParameterName.SRC)
  const [myCaseRemoveStatus, setMyCaseRemoveStatus] = useState(false)
  const [connectMyCase, setConnectMyCase] = useState(
    Boolean(
      codeQueryParam &&
        organizationId !== '' &&
        String(srcQueryParam) === myCaseName
    )
  )

  const {
    alert: myCaseAlert,
    showAlert: myCaseShowAlert,
    handleClose: myCaseHandleClose,
  } = useSnackBar()

  const {
    connectMyCaseIntegration,
    removeMyCaseData,
    connectMyCaseIntegrationData,
    isMyCaseIntegrationLoading,
  } = useMyCaseIntegrationItem({
    code: String(codeQueryParam),
    connectMyCase,
    organizationId: String(organizationId),
    myCaseName,
    myCaseRemoveStatus,
  })

  const shouldRenderLoadingButton =
    isMyCaseIntegrationLoading || loading || isConnectingToLcms

  const shouldRenderDisconnectButton =
    (isConnected && !myCaseRemoveStatus && !removeMyCaseData) ||
    removeMyCaseData?.statusCode === 404 ||
    removeMyCaseData?.statusCode === 500

  // Navigate to the current window location path clearing the query params provided,
  // replacing the current entry in the browser's history.
  useEffect(() => {
    if (window.location.search !== '') {
      navigate(window.location.pathname, { replace: true })
    }
  }, [navigate])

  useEffect(() => {
    if (
      codeQueryParam &&
      organizationId !== '' &&
      srcQueryParam === myCaseName
    ) {
      connectingToLcms(true)
      setConnectMyCase(true)
    }
  }, [
    myCaseName,
    codeQueryParam,
    organizationId,
    srcQueryParam,
    connectingToLcms,
  ])

  // Monitors the LCMs setup process.
  useEffect(() => {
    if (
      isSetupSuccessful(connectMyCaseIntegrationData) &&
      !myCaseRemoveStatus &&
      !isConnected
    ) {
      connectingToLcms(false)
      setConnectMyCase(false)
      bootParamsService.remove(ParameterName.MYCASE_CODE)
      bootParamsService.remove(ParameterName.SRC)
      refetchLcmsData()
    }
  }, [
    connectMyCaseIntegrationData,
    myCaseRemoveStatus,
    isConnected,
    refetchLcmsData,
    connectingToLcms,
  ])

  // Handles errors during MyCase integration setup or removal process
  // Check if the current organization is not connected to other LCMs
  useEffect(() => {
    if (
      (connectMyCaseIntegrationData?.statusCode === 400 &&
        !isConnectedToOtherLcms) ||
      (removeMyCaseData?.statusCode === 400 && !isConnectedToOtherLcms)
    ) {
      myCaseShowAlert({
        severity: 'error',
        message: 'An error occurred, please try again in a few minutes',
      })
      connectingToLcms(false)
      setConnectMyCase(false)
      bootParamsService.remove(ParameterName.MYCASE_CODE)
      bootParamsService.remove(ParameterName.SRC)
    }
  }, [
    myCaseShowAlert,
    connectMyCaseIntegrationData?.statusCode,
    isConnectedToOtherLcms,
    removeMyCaseData?.statusCode,
    connectingToLcms,
  ])

  // Handles disconnection from other LCMs
  useEffect(() => {
    if (isConnected && removeMyCaseData?.message === 'deleted') {
      setMyCaseRemoveStatus(false)
      refetchLcmsData()
    }
  }, [isConnected, removeMyCaseData?.message, refetchLcmsData])

  const removeMyCaseIntegration = () => {
    removeIntegrationItem({
      integrationName: MY_CASE.NAME,
      onSubmit: () => {
        setMyCaseRemoveStatus(true)
      },
    })
  }

  return (
    <Card
      sx={{
        margin: theme.spacing(0, 0, 4),
        padding: theme.spacing(2),
        boxShadow: 2,
      }}
    >
      <IntegrationItemCardContent
        actions={
          <IntegrationItemButtons
            shouldRenderLoadingButton={shouldRenderLoadingButton}
            shouldRenderDisconnectButton={shouldRenderDisconnectButton}
            disconnectLcms={removeMyCaseIntegration}
            connectLcms={connectMyCaseIntegration}
            isConnectedToOtherLcms={isConnectedToOtherLcms}
            disableConnectAction={isConnectedToOtherLcms}
          />
        }
        icon={icon}
        name={name}
        subtitle={subtitle}
      />
      <JSnackBar
        vertical="bottom"
        open={myCaseAlert.open}
        severity={myCaseAlert.severity}
        message={myCaseAlert.message}
        handleClose={myCaseHandleClose}
      />
    </Card>
  )
}

export default MyCaseIntegrationItem

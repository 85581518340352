import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useCatalogUsStateQuery, usePracticeAreasQuery } from '@/gql/appApi'
import { SearchProps } from '@/components'
import { useSizes } from '@/hooks/useSizes'
import { OrganizationWaterfallsContext } from '../../OrganizationWaterfallsContext'
import { TOrgWaterfallFilters } from '../../types'

export const useWaterfallFilters = () => {
  const { filters, setFilters, isFirmSettings } = useContext(
    OrganizationWaterfallsContext
  )
  const { isLessMd } = useSizes()

  // Mobile filters are in a modal, so they need to be handled separately
  const [tempFilters, setTempFilters] = useState(filters)
  const [modalOpen, setModalOpen] = useState(false)

  const { data: practiceAreasData, loading: loadingCaseTypes } =
    usePracticeAreasQuery()
  const { data: jurisdictionsData, loading: loadingJurisdictions } =
    useCatalogUsStateQuery()

  const loading = loadingCaseTypes || loadingJurisdictions

  useEffect(() => {
    if (modalOpen) {
      setTempFilters(filters)
    }
  }, [modalOpen, filters])

  const onChangeFilters = useCallback(
    (updatedFilters: Partial<TOrgWaterfallFilters>) => {
      // Store in tempFilters if modal is open, otherwise store in filters (desktop instantly applies the changes).
      // This is to prevent values from being automatically applied before the user clicks "Apply Filters"
      const setState = isLessMd ? setTempFilters : setFilters
      setState(prev => ({
        ...prev,
        ...updatedFilters,
      }))
    },
    [isLessMd, setFilters]
  )

  const applyFilters = () => {
    setFilters(prev => ({
      ...prev,
      ...tempFilters,
    }))
    setModalOpen(false)
  }

  const clearFilters = () => {
    setTempFilters({
      searchText: '',
      practiceArea: '',
      jurisdiction: '',
      integrationsOnly: false,
      alphabetical: '',
    })
  }

  // Used to display the selected values correctly in the dropdowns when the modal is open
  const filterValues = isLessMd && isFirmSettings ? tempFilters : filters

  const onAtoZClick = useCallback(() => {
    let newAlphabetical: '' | 'asc' | 'desc' = ''
    if (filterValues.alphabetical === 'asc') {
      newAlphabetical = 'desc'
    }
    if (filterValues.alphabetical === 'desc') {
      newAlphabetical = ''
    }
    if (filterValues.alphabetical === '') {
      newAlphabetical = 'asc'
    }

    if (isFirmSettings) {
      onChangeFilters({ alphabetical: newAlphabetical })
    } else {
      setFilters(prev => ({ ...prev, alphabetical: newAlphabetical }))
    }
  }, [isFirmSettings, filterValues.alphabetical, onChangeFilters, setFilters])

  const searchProps: SearchProps = {
    value: filters.searchText,
    placeholder: 'Search for Waterfalls...',
    onChange: (event: ChangeEvent<HTMLInputElement>) =>
      setFilters(prev => ({ ...prev, searchText: event.target.value })),
    'aria-label': 'Search Waterfalls',
  }

  return {
    applyFilters,
    practiceAreas: practiceAreasData?.catalog_practice_area || [],
    clearFilters,
    filters: filterValues,
    isLessMd,
    isFirmSettings,
    jurisdictions: jurisdictionsData?.catalog_us_state || [],
    loading,
    modalOpen,
    onChangeFilters,
    searchProps,
    setModalOpen,
    onAtoZClick,
  }
}

import { CircularProgress } from '@mui/material'
import { ISidebarProps } from '../Sidebar/CaseSidebar/types'
import { useNoVettedData } from './useNoVettedData'
import NonJHQMemberAlert from './NonJHQMemberAlert/NonJHQMemberAlert'

export interface ICaseBodyTag {
  title: string
  description: string
}

const NoVettedOptions = (props: ISidebarProps) => {
  const { hasAdminAccess, isCaseManager, optionsLoading } = props

  const { isloadingHasJHQMembersInMyOrg, hasJHQMembersInMyOrg } =
    useNoVettedData()

  if (
    isloadingHasJHQMembersInMyOrg ||
    optionsLoading ||
    isloadingHasJHQMembersInMyOrg
  ) {
    return (
      <CircularProgress
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
    )
  }

  return (
    <NonJHQMemberAlert
      hasJHQMembersInMyOrg={hasJHQMembersInMyOrg}
      isCaseManager={isCaseManager}
      hasAdminAccess={hasAdminAccess}
    />
  )
}

export default NoVettedOptions

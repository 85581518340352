import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useForm } from 'react-hook-form'
import LogoBar from '@/pages/CreateAccount/shared/LogoBar'

import { useSizes } from '@/hooks/useSizes'
import JSchoolSearch from '@/components/atoms/JSchoolSearch'
import ButtonBar from '../shared/ButtonBar'
import { TCreateProfileEducationForm, educationDefaultValues } from './types'

type EducationProps = {
  back: () => void
  initialEducation: TCreateProfileEducationForm
  onSubmit: (education: TCreateProfileEducationForm) => void
}

const Education = ({ back, initialEducation, onSubmit }: EducationProps) => {
  const { isLessMd } = useSizes()
  const { control, handleSubmit, getValues } =
    useForm<TCreateProfileEducationForm>({
      reValidateMode: 'onBlur',
      defaultValues: initialEducation || educationDefaultValues,
    })

  const handleOnSubmit = (formData: TCreateProfileEducationForm) => {
    onSubmit(formData)
  }

  return (
    <Box
      sx={theme => ({
        paddingTop: { xs: 3.5, md: 0 },
        pb: { md: 5 },
        height: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        [theme.breakpoints.down('md')]: {
          paddingBottom: theme.spacing(6),
        },
      })}
    >
      <LogoBar />
      <Typography
        sx={{
          textAlign: 'left',
          marginBottom: 5,
          fontSize: { xs: '20px', md: '24px' },
        }}
        variant={isLessMd ? 'h6' : 'h5'}
      >
        Education
      </Typography>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid
          container
          rowSpacing={1.25}
          columnSpacing={3}
          sx={{
            pb: 3.25,
          }}
        >
          <Grid item xs={12}>
            <JSchoolSearch<TCreateProfileEducationForm>
              control={control}
              name="lawSchool"
              label="Law School"
              value={getValues('lawSchool') || null}
            />
          </Grid>
          <Grid item xs={12}>
            <JSchoolSearch<TCreateProfileEducationForm>
              isUndergradSchool
              control={control}
              name="undergradSchool"
              label="Undergraduate School"
              value={getValues('undergradSchool') || null}
            />
          </Grid>
        </Grid>
        <ButtonBar back={back} enableFixedBar={false} />
      </form>
    </Box>
  )
}

export default Education

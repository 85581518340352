import { ALPHANUMERIC_REGEXP } from '@/constants'
import { bool, object, ref, string } from 'yup'

const createAccountSchema = object({
  email: string()
    .email()
    .max(60)
    .required('Please enter a valid email address'),
  firstName: string()
    .max(20, 'Name must not exceed 20 characters')
    .required('Please enter your first name'),
  lastName: string()
    .max(20, 'Last name must not exceed 20 characters')
    .required('Please enter your last name'),
  password: string()
    .min(8, 'Password must be at least 8 characters')
    .max(25, 'Password must be no more than 25 characters')
    .matches(ALPHANUMERIC_REGEXP, 'Must be alphanumeric')
    .required('Please enter a password'),
  phone: string()
    .matches(/^\d{10}$/, 'Must be 10 digits')
    .required('Please enter a valid phone number'),
  repeatPassword: string()
    .oneOf([ref('password')], 'Passwords do not match')
    .required('Please confirm your password'),
  smsMessages: bool().required(),
  terms: bool()
    .oneOf([true], 'Please accept the terms and conditions')
    .required(),
  token: string(),
}).required()

const defaultValues = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  phone: '',
  repeatPassword: '',
  smsMessages: false,
  terms: false,
}

export { createAccountSchema, defaultValues }

import { SxProps, useTheme } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

interface IClientSigningProps extends BoxProps {
  onClientSigned: () => void
  onClientRejected: () => void
  buttonSxProps?: SxProps
}

const ClientSigning = ({
  onClientSigned,
  onClientRejected,
  buttonSxProps: buttonProps,
  sx,
}: IClientSigningProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={[
        {
          mt: 2,
          pl: {
            xs: 2,
            md: 0,
          },
          width: '100%',
          display: 'flex',
          justifyContent: {
            xs: 'center',
            md: 'flex-end',
          },
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row' },
          gap: {
            xs: 1,
            md: 2,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
        Did you sign the potential client?
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={onClientSigned}
        size="large"
        sx={[
          {
            width: '100%',
            [theme.breakpoints.up('md')]: {
              width: 'auto',
            },
          },
          ...(Array.isArray(buttonProps) ? buttonProps : [buttonProps]),
        ]}
      >
        Yes, Client Signed
      </Button>
      <Button
        variant="text"
        color="error"
        size="large"
        onClick={onClientRejected}
        sx={[
          {
            width: '100%',
            [theme.breakpoints.up('md')]: {
              width: 'auto',
            },
          },
          ...(Array.isArray(buttonProps) ? buttonProps : [buttonProps]),
        ]}
      >
        No, Did Not Sign
      </Button>
    </Box>
  )
}

export default ClientSigning

import { useContext } from 'react'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import LockOutlined from '@mui/icons-material/LockOutlined'
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined'
import { useFieldArray } from 'react-hook-form'
import { isValidEmail } from '@/utils'
import JTextField from '@/components/atoms/JTextField'
import { CreateCaseContext } from '../../CreateCaseContext/CreateCaseContext'
import CreateCaseSectionLayout from './CreateCaseSectionLayout'

const LeadInformation = () => {
  const {
    methods: { control, register, setValue, watch, getValues },
  } = useContext(CreateCaseContext)

  const { fields, append, remove } = useFieldArray({
    name: 'leadInfo',
    control,
  })

  const watchedFields = watch('leadInfo')

  const defaultLeadInfo = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  }

  const handleRemove = (index: number) => {
    setValue(`leadInfo.${index}.firstName`, '')
    setValue(`leadInfo.${index}.lastName`, '')
    setValue(`leadInfo.${index}.email`, '')
    setValue(`leadInfo.${index}.phone`, '')

    remove(index)

    if (fields.length === 1) {
      append(defaultLeadInfo)
    }
  }

  const handleAdd = () => {
    append(defaultLeadInfo)
  }

  const isFieldFilled = (index: number) => {
    const lead = watchedFields[index]
    return lead && (lead.firstName || lead.lastName || lead.email || lead.phone)
  }

  // Show Remove Button when:
  // 1. There is 1 or more lead filled.
  // 2. There is only 1 lead, but the field is filled.
  const showRemoveButton = (index: number) =>
    fields.length > 1 || (fields.length === 1 && isFieldFilled(index))

  const subtitleAlert = () => (
    <Alert
      severity="success"
      icon={<LockOutlined fontSize="inherit" />}
      sx={{
        alignItems: 'flex-start',
        mt: 2,
        mr: {
          xs: 0,
          md: 2,
        },
        mb: {
          xs: 1,
          md: 0,
        },
      }}
    >
      <Typography variant="body2">
        Not public. This information is only shared with an attorney that you
        complete a match with.
      </Typography>
    </Alert>
  )

  return (
    <CreateCaseSectionLayout
      title="Lead Information"
      subtitle={subtitleAlert()}
      sx={{
        mb: 5,
        borderBottom: `1px solid`,
        borderColor: 'divider',
      }}
    >
      {fields.map((f, i) => {
        const { name: firstName } = register(`leadInfo.${i}.firstName`)
        const { name: lastName } = register(`leadInfo.${i}.lastName`)
        const { name: email } = register(`leadInfo.${i}.email`)
        const { name: phone } = register(`leadInfo.${i}.phone`)

        const isFieldEmpty = () =>
          !getValues(firstName) &&
          !getValues(lastName) &&
          !getValues(email) &&
          !getValues(phone)

        return (
          <Grid
            container
            rowSpacing={2.5}
            columnSpacing={2}
            key={f.id}
            sx={{ mb: 2 }}
          >
            {showRemoveButton(i) ? (
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle1">Lead #{i + 1}</Typography>
                <Button
                  sx={{ color: 'error.main' }}
                  variant="text"
                  onClick={() => handleRemove(i)}
                >
                  Remove Lead
                </Button>
              </Grid>
            ) : null}
            <Grid item xs={12} md={6}>
              <JTextField
                name={firstName}
                control={control}
                forceIsNotRequired
                label="First Name"
                placeholder="First Name"
                variant="outlined"
                fullWidth
                fitTextField
                InputProps={{ inputProps: { maxLength: 40 } }}
                rules={{
                  validate: (fName: string) => {
                    if (isFieldEmpty()) return true
                    return !!fName || 'The Lead name is required'
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <JTextField
                name={lastName}
                control={control}
                forceIsNotRequired
                label="Last Name"
                placeholder="Last Name"
                variant="outlined"
                fullWidth
                fitTextField
                InputProps={{ inputProps: { maxLength: 40 } }}
                rules={{
                  validate: (lName: string) => {
                    if (isFieldEmpty()) return true
                    return !!lName || 'The Last Name is required'
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <JTextField
                name={phone}
                forceIsNotRequired
                control={control}
                label="Lead Phone"
                placeholder="Lead Phone"
                variant="outlined"
                fullWidth
                fitTextField
                inputMode="numeric"
                type="tel"
                rules={{
                  validate: (phoneNumber: string) => {
                    const emailValue = getValues(`leadInfo.${i}.email`) ?? ''
                    if (!emailValue && !phoneNumber) return true
                    if (phoneNumber && phoneNumber.length < 10)
                      return 'Phone Number must be 10 digits'
                    return (
                      (email &&
                        (typeof isValidEmail(email) === 'string' ||
                          !isValidEmail(email))) ||
                      true
                    )
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <JTextField
                name={email}
                forceIsNotRequired
                label="Lead Email"
                placeholder="Lead Email"
                control={control}
                fullWidth
                fitTextField
                rules={{
                  validate: (emailToValidate: string) => {
                    const phoneValue = getValues(`leadInfo.${i}.phone`)
                    if (!phoneValue && !emailToValidate) return true
                    if (
                      emailToValidate &&
                      (typeof isValidEmail(emailToValidate) === 'string' ||
                        !isValidEmail(emailToValidate))
                    ) {
                      return isValidEmail(emailToValidate)
                    }
                    return true
                  },
                }}
              />
            </Grid>
          </Grid>
        )
      })}
      <Button
        onClick={handleAdd}
        startIcon={<PersonAddAlt1OutlinedIcon sx={{ fontSize: '1rem' }} />}
      >
        Add New Lead
      </Button>
    </CreateCaseSectionLayout>
  )
}

export default LeadInformation

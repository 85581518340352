import { GetOrganizationConnectedQuery } from '@/gql/appApi'
import { IconButtonProps } from '@mui/material'
import { MouseEventHandler, ReactElement } from 'react'

export type LCMS = 'Clio' | 'LeadDocket' | 'Lawmatics' | 'Litify' | 'Salesforce'

export enum PAGE {
  Integrations = 'Integrations',
  ChangePassword = 'change-password',
}

export interface IntegrationProps {
  page?: PAGE | string
}

export type TRemoveIntegrationProps = {
  integrationName: string
  onSubmit: () => void
}

export interface IntegrationItemProps {
  refetchLcmsData: () => void
  icon: string
  id?: string | undefined
  isConnectedToOtherLcms?: boolean
  name: LCMS | string
  subtitle?: string | ReactElement
  organizationId: string
  loading: boolean
  isConnected?: boolean
  removeIntegrationItem: (args: TRemoveIntegrationProps) => void
  isConnectingToLcms: boolean
  connectingToLcms: (isConnecting: boolean) => void
  // Clio specific props
  hasAdminConnectedToClio?: boolean
}

export interface ClioNonAdminIntegrationItemProps {
  icon: string
  name: LCMS | string
  subtitle?: string | ReactElement
  organizationId?: string
  isCurrentUserConnectedToClio?: boolean
  organizationData?: GetOrganizationConnectedQuery
  loading: boolean
  removeIntegrationItem: (args: TRemoveIntegrationProps) => void
  refetchLcmsData: () => void
  isConnectingToLcms: boolean
  connectingToLcms: (isConnecting: boolean) => void
}

export interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

export type LeadDocketInputIdValues =
  | 'url-input'
  | 'api-key-input'
  | 'endpoint-url-input'

export type LitifyInputIdValues =
  | 'url-input'
  | 'code'
  | 'instance_url'
  | 'code_verifier'

export interface LeadDocketContentProps {
  name: string
  organizationId: string
  updateLeadDocketConnection: (isConnected: boolean) => void
  refetchLcmsData: () => void
  connectingToLcms: (isConnecting: boolean) => void
}

export interface LitifyContentProps {
  connectIntegration: MouseEventHandler<HTMLButtonElement>
  isIntegrationLoading: boolean
  handleTextFieldValue: (event: React.ChangeEvent<HTMLInputElement>) => void
  disableAction: boolean
  codeChallenge: string
}

export interface SalesForceContentProps {
  connectIntegration: MouseEventHandler<HTMLButtonElement>
  isIntegrationLoading: boolean
  handleTextFieldValue: (event: React.ChangeEvent<HTMLInputElement>) => void
  disableAction: boolean
  codeChallenge: string
}

export interface IntegrationItemExpandableProps {
  expanded?: boolean
  setExpanded?: (expanded: boolean) => void
  isConnectedToOtherLcms?: boolean | undefined
}

export interface IntegrationItemButtonsProps
  extends IntegrationItemExpandableProps {
  shouldRenderLoadingButton: boolean
  shouldRenderDisconnectButton: boolean
  disconnectLcms: () => void
  connectLcms?: () => void
  disableConnectAction?: boolean
  isExpandable?: boolean
}

export type TIntegrationModalProps = {
  open: boolean
  onSubmit: () => void
  title: string
  description: string
}

import { useSnackBar } from '@/hooks/useSnackBar'
import { useSetupLeadDocket } from '@/utils/api/integrations'
import { SetupResponse } from '@/utils/api/integrations/types'
import { TError } from '@/utils/api/types'

export interface UseConnectIntegrationProps {
  apikeyInfo: string
  connectSetup: boolean
  endpointUrlInput: string
  name: string
  organizationId?: string
  urlInput: string
}

const useConnectLeadDocketIntegrationItem = ({
  apikeyInfo,
  connectSetup,
  endpointUrlInput,
  name,
  organizationId,
  urlInput,
}: UseConnectIntegrationProps) => {
  const lcms = name

  const config = {
    api_key: apikeyInfo,
    instance_url: urlInput,
    intake_form_url: endpointUrlInput,
  }

  const {
    data: connectIntegrationData,
    isLoading: isConnectIntegrationLoading,
  }: {
    data: SetupResponse | TError | null
    isLoading: boolean
  } = useSetupLeadDocket(
    {
      organization_id: String(organizationId),
      lcms,
      config,
    },
    connectSetup
  )

  const { alert, showAlert, handleClose } = useSnackBar()

  return {
    alert,
    handleClose,
    showAlert,
    isConnectIntegrationLoading,
    connectIntegrationData,
  }
}

export default useConnectLeadDocketIntegrationItem

import { Theme } from '@mui/material'

// Grouping and sorting the options
const groupPracticeAreas = (options: string[]) =>
  options.reduce<Record<string, string[]>>((groups, option) => {
    const letter = option.charAt(0).toUpperCase()
    if (!groups[letter]) {
      // eslint-disable-next-line no-param-reassign
      groups[letter] = []
    }
    groups[letter].push(option)
    return groups
  }, {})

export default groupPracticeAreas

export const getCleanString = (item?: string | null) =>
  item?.replace(/["]+/g, '').trim()

export const compareTwoString = (item: string, option: string): boolean =>
  getCleanString(item)?.toLowerCase() === option.trim().toLowerCase()

export const getStyleChipSelected = (
  theme: Theme,
  selected: boolean,
  isLessMd: boolean
) => ({
  '&:hover .MuiSvgIcon-root': !isLessMd
    ? {
        color: selected
          ? theme.palette.primary.dark
          : theme.palette.background.page,
      }
    : {
        color: selected
          ? theme.palette.background.page
          : theme.palette.primary.dark,
      },
  '&:hover': !isLessMd
    ? {
        backgroundColor: selected
          ? theme.palette.background.page
          : theme.palette.primary.dark,
        color: selected
          ? theme.palette.primary.dark
          : theme.palette.background.page,
        border: selected
          ? `1px solid ${theme.palette.primary.dark}`
          : `1px solid ${theme.palette.background.page}`,
      }
    : {
        backgroundColor: selected
          ? theme.palette.primary.dark
          : theme.palette.background.page,
        color: selected
          ? theme.palette.background.page
          : theme.palette.primary.dark,
      },
})

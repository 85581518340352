import { useCallback, useState } from 'react'
import { useSizes } from '@/hooks/useSizes'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import LogoBar from '@/pages/CreateAccount/shared/LogoBar'
import JRadioCard from '@/components/atoms/JRadioCard'
import NoProfessionalLicenseModal from './components/NoProfessionalLicenseModal'

type OrganizationDecisionProps = {
  next: () => void
  back: () => void
  hasLicense: boolean
  handleLicenseSelection: (hasLicense: boolean) => void
  handleNoLicense: () => Promise<void>
  loading?: boolean
}

const OrganizationDecision = ({
  next,
  back,
  hasLicense,
  handleLicenseSelection,
  handleNoLicense,
  loading,
}: OrganizationDecisionProps) => {
  const { isLessMd } = useSizes()
  const [openModal, setOpenModal] = useState<boolean>(false)

  const handleContinue = useCallback(() => {
    if (hasLicense) {
      next()
    } else {
      setOpenModal(true)
    }
  }, [hasLicense, next])

  const handleOnAcceptNoLicense = useCallback(async () => {
    await handleNoLicense()
    setOpenModal(false)
  }, [handleNoLicense])

  return (
    <Box
      sx={{
        height: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
      }}
    >
      <LogoBar />
      <Typography
        variant={isLessMd ? 'h6' : 'h5'}
        sx={{ mb: 5, textAlign: 'left' }}
      >
        Are you a lawyer?
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 3,
          flexDirection: 'column',
        }}
      >
        <JRadioCard
          label="I have a professional license"
          description="I'm a licensed attorney"
          selected={hasLicense}
          onChange={() => handleLicenseSelection(true)}
        />
        <JRadioCard
          label="I don't have a professional license"
          description="I'm not an attorney."
          selected={!hasLicense}
          onChange={() => handleLicenseSelection(false)}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          mt: 5,
        }}
      >
        <Button
          sx={{
            width: {
              xs: '40%',
              md: '50%',
            },
            height: 48,
            mr: 2,
          }}
          onClick={back}
          variant="text"
          size="large"
          disabled={loading}
        >
          Back
        </Button>
        <Button
          sx={{
            width: {
              xs: '60%',
              md: '50%',
            },
            height: 48,
          }}
          onClick={handleContinue}
          variant="contained"
          size="large"
          disabled={loading}
        >
          Continue
        </Button>
      </Box>
      {openModal && (
        <NoProfessionalLicenseModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          onAccept={handleOnAcceptNoLicense}
          loading={loading}
        />
      )}
    </Box>
  )
}

export default OrganizationDecision

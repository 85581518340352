import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Add from '@mui/icons-material/Add'
import Check from '@mui/icons-material/Check'
import { alpha } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'

export const ChipList = ({
  groupedOptions,
  sortedDefault,
  handleCheckboxClick,
}: {
  groupedOptions: Record<string, string[]>
  sortedDefault: string[]
  handleCheckboxClick: (option: string) => void
}) => {
  const theme = useTheme()

  return Object.keys(groupedOptions).map(key => (
    <Box
      key={key}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
    >
      <Typography
        variant="subtitle2"
        sx={{
          color: alpha(theme.palette.common.black, 0.6),
        }}
      >
        {key}
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="flex-start"
        alignItems="center"
        marginBottom="10px"
      >
        {groupedOptions[key].map(option => {
          const selected = sortedDefault.includes(option)
          return (
            <Chip
              key={option}
              label={option}
              icon={
                selected ? (
                  <Check
                    sx={{
                      width: 16,
                    }}
                  />
                ) : (
                  <Add sx={{ width: 14 }} />
                )
              }
              onClick={() => handleCheckboxClick(option)}
              clickable
              sx={{
                m: 0.5,
                backgroundColor: theme.palette.background.page,
                border: selected
                  ? `2px solid ${theme.palette.secondary.main}`
                  : `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
                color: alpha(theme.palette.common.black, 0.87),
                typography: 'body1',
                '& .MuiSvgIcon-root': {
                  color: selected
                    ? theme.palette.secondary.dark
                    : theme.palette.primary.dark,
                },
                '&:hover': {
                  backgroundColor: theme.palette.primary.lightest,
                },
              }}
            />
          )
        })}
      </Box>
    </Box>
  ))
}

import React from 'react'
import { TCommunicationPreferencesFormValues } from '@/components/CommunicationPreferences/types'
import { TCreateProfileEducationForm } from './Education/types'

export type CreateProfileFormState = {
  practiceAreas: string[]
  personalInfo?: {
    bio?: string
    imageUrl?: string
  }
  education: TCreateProfileEducationForm
  membershipInfo?: unknown
  communicationPreferences: TCommunicationPreferencesFormValues
  phoneNumber?: string
}

type CreateProfileFormContextType = {
  formState: CreateProfileFormState
  updateFormState: (
    key: keyof CreateProfileFormState,
    value: CreateProfileFormState[keyof CreateProfileFormState]
  ) => void
}

const CreateProfileFormContext = React.createContext<
  CreateProfileFormContextType | undefined
>(undefined)

export const CreateProfileFormProvider = CreateProfileFormContext.Provider

export default CreateProfileFormContext

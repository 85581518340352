import Layout from '@/components/Layout'
import { Container } from '@mui/material'
import CustomLoading from '@/components/Loading'
import ErrorPage from '@/components/shared/ErrorPage'
import { USER_ROLES } from '@/constants'
import AlgoliaMarketplace from './components/AlgoliaMarketplace'
import useMarketplace from './hooks/useMarketplace'
import { useAlgoliaMarketplace } from './hooks/useAlgoliaMarketplace'

const MarketplacePage = () => {
  const {
    userMarketplacePermissionsSet,
    isMarketplaceRestrictedForUser,
    marketplaceUserRoleId,
  } = useMarketplace()

  const { isAttorneyProfileLoading } = useAlgoliaMarketplace()

  const isLoading = !userMarketplacePermissionsSet || isAttorneyProfileLoading

  if (isLoading) {
    return (
      <Layout>
        <CustomLoading
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          message="Loading Marketplace..."
        />
      </Layout>
    )
  }

  if (isMarketplaceRestrictedForUser) {
    return (
      <Layout>
        <ErrorPage
          message={
            marketplaceUserRoleId?.includes(USER_ROLES.RestrictedUser)
              ? ''
              : "You don't have permissions to view Marketplace"
          }
        />
      </Layout>
    )
  }

  return (
    <Layout>
      <Container
        sx={theme => ({
          paddingBottom: theme.spacing(16),
          paddingX: theme.spacing(0),
          height: '100%',
          overflow: 'auto',
          marginTop: theme.spacing(0),
          paddingTop: {
            xs: theme.spacing(0),
            md: theme.spacing(4),
          },
          width: {
            md: '100%',
          },
          flex: {
            md: 1,
          },
          '&::-webkit-scrollbar': { display: 'none' },
        })}
      >
        <AlgoliaMarketplace />
      </Container>
    </Layout>
  )
}

export default MarketplacePage

import { Box, Paper } from '@mui/material'

import SpecialAgreements from './SpecialAgreements'
import Header from './Header'
import DataroomAccessRequests from './DataroomAccessRequests'
import CaseManagers from './CaseManagers'
import ClientContactInfo from './ClientContactInfo'
import SidebarAlerts from './SidebarAlerts'
import { useCaseContext } from '../../hooks/useCaseContext'
import SidebarSkeleton from './skeleton'
import Negotiations from './Negotiations'

const Sidebar = () => {
  const { caseData, loading } = useCaseContext()

  if (loading) return <SidebarSkeleton />

  if (!caseData) return null

  return (
    <Paper
      sx={{
        borderRadius: { xs: 0, md: 2 },
        width: { xs: '100vw', md: 'auto' },
        border: theme => `1px solid ${theme.palette.divider}`,
        overflow: 'hidden',
        position: 'relative',
        left: { xs: '50%', md: 0 },
        marginX: { xs: '-50vw', md: 0 },
      }}
    >
      <SidebarAlerts />
      <Box
        sx={{
          '& > *:not(:first-of-type)': {
            borderTop: theme => `1px solid ${theme.palette.divider}`,
          },
        }}
      >
        <SpecialAgreements />
        <Header />
        <Negotiations defaultExpanded />
        <DataroomAccessRequests defaultExpanded />
        <CaseManagers />
        <ClientContactInfo />
      </Box>
    </Paper>
  )
}

export default Sidebar

import { getImagePath } from '@/utils'
import { IDashboardOrganization } from '../types'
import AllFirmCases from './AllFirmCases'
import { IMAGE_SIZES } from '@/constants'

interface OrganizationDashboardProps {
  avatarMap: Record<string, string>
  organizationData: IDashboardOrganization
}

export const OrganizationDashboard = ({
  avatarMap,
  organizationData: {
    metricsOrganization,
    topAttorneysOrganization,
    topJurisdictionsOrganization,
    topPracticeareasOrganization,
  },
}: OrganizationDashboardProps) => (
  <AllFirmCases
    py={{
      xs: 0,
      sm: 1,
      lg: 5,
    }}
    pt={{
      xs: 0,
      sm: 0,
      lg: 0,
    }}
    mt="0 !important"
    inboundReferrals={{
      marketplace: metricsOrganization.numReferralsInboundMarketplace,
      waterfallReferrals: metricsOrganization.numReferralsInboundWaterfall,
    }}
    outboundReferrals={{
      marketplace: metricsOrganization.numReferralsOutboundMarketplace,
      waterfallReferrals: metricsOrganization.numReferralsOutboundWaterfall,
    }}
    topAttorneys={{
      data: topAttorneysOrganization.map(
        ({
          attorneyName,
          numReferralsInbound,
          numReferralsOutbound,
          attorneyId,
        }) => {
          const imageUrl =
            attorneyId in avatarMap
              ? getImagePath({
                  id: avatarMap[attorneyId],
                  size: IMAGE_SIZES.Small,
                })
              : ``

          return {
            inboundReferrals: numReferralsInbound,
            imageUrl,
            name: attorneyName,
            outboundReferrals: numReferralsOutbound,
          }
        }
      ),
    }}
    topJurisdictions={{
      data: topJurisdictionsOrganization.map(({ jurisdiction, numCases }) => ({
        jurisdiction,
        referrals: numCases,
      })),
    }}
    topPracticeAreas={{
      data: topPracticeareasOrganization.map(({ numCases, practiceArea }) => ({
        practiceArea,
        referrals: numCases,
      })),
    }}
    totalReferrals={{
      inboundCases: metricsOrganization.numReferralsInbound,
      marketplace: metricsOrganization.numReferralsMarketplace,
      outboundCases: metricsOrganization.numReferralsOutbound,
      waterfallReferrals: metricsOrganization.numReferralsWaterfall,
    }}
  />
)

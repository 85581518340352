import Box from '@mui/material/Box'
import MUITypography from '@mui/material/Typography'
import MUIList from '@mui/material/List'

import { styled } from '@mui/material'

export const Container = styled(Box)(({ onClick }) => ({
  ...(onClick && {
    '&:hover': {
      cursor: 'pointer',
    },
  }),
}))

export const Overline = styled(MUITypography)`
  margin-left: 1rem;
`
export const List = styled(MUIList)`
  padding-top: 0;
  padding-bottom: 0;

  > li {
    padding-top: 0;
  }
`

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import AttorneyCase from '@/pages/Case/components/Sidebar/components/AttorneyCase'
import isObject from 'lodash/isObject'
import has from 'lodash/has'
import { CASE_PROPOSAL_TYPES } from '@/constants'
import SidebarFee from '@/pages/Case/components/Sidebar/components/SidebarFee'
import Title from '../Title'
import Fee from '../Fee'
import JUser from '../atoms/JUser'
import { IJUser } from '../atoms/JUser/types'

type CaseProposalKeys = keyof typeof CASE_PROPOSAL_TYPES

export type ProposalType = (typeof CASE_PROPOSAL_TYPES)[CaseProposalKeys] | null

export interface CaseInformationProps {
  title?: string
  fee?: number
  profile?: IJUser
  showFinancingSource?: boolean
  isSameOrg?: boolean
  attorneyTitle?: string
}

export const CaseInformation = ({
  title,
  fee,
  profile,
  showFinancingSource = true,
  isSameOrg = false,
  attorneyTitle,
}: CaseInformationProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 1 }}>
    {isObject(profile) ? (
      <Box>
        {has(profile, 'mainText') ? (
          <JUser
            avatar={profile.avatar}
            mainText={profile.mainText}
            secondaryText={profile.secondaryText}
            isVetted={profile?.isVetted}
            isQualified={profile?.isQualified}
          />
        ) : (
          <AttorneyCase
            caseOwner={profile}
            relationshipType={isSameOrg ? 'attorney' : 'counterpart'}
            title={attorneyTitle}
          />
        )}
      </Box>
    ) : null}
    <Box sx={{ margin: '0 0 8px 76px' }}>
      {title && <Title title={title} variant="subtitle1" />}
      {fee !== undefined && fee !== null && (
        <Box>
          {showFinancingSource ? (
            <SidebarFee fee={fee} />
          ) : (
            <>
              <Fee fee={fee} variant="outlined" />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body2">
                  Referral/Co-Counsel Fee Requested
                </Typography>
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  </Box>
)

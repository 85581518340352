import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useSizes } from '@/hooks/useSizes'
import LogoBar from '@/pages/CreateAccount/shared/LogoBar'
import { Controller, useForm } from 'react-hook-form'
import PasswordField from '@/components/PasswordField/PasswordField'
import { object, ref, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ALPHANUMERIC_REGEXP } from '@/constants'
import { PasswordFormValues } from '../hooks/useForgotPassword'

const resetPasswordSchema = object().shape({
  password: string()
    .min(8, 'Password must be at least 8 characters')
    .max(25, 'Password must be no more than 25 characters')
    .matches(ALPHANUMERIC_REGEXP, 'Must be alphanumeric')
    .required('Please enter a password'),
  repeatPassword: string()
    .oneOf([ref('password')], 'Passwords do not match')
    .required('Please confirm your password'),
})

type CreateNewPasswordProps = {
  onSubmit: (formData: PasswordFormValues) => void
  loading: boolean
}

const CreateNewPassword = ({ onSubmit, loading }: CreateNewPasswordProps) => {
  const { isLessMd } = useSizes()

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(resetPasswordSchema),
  })

  const handleResetPassword = () => {
    trigger().then(isValid => {
      if (isValid) {
        handleSubmit(onSubmit)()
      }
    })
  }

  return (
    <>
      <LogoBar />
      <Box
        sx={theme => ({
          marginTop: {
            xs: 0,
          },
          maxWidth: {
            md: theme.spacing(74),
          },
          '&::-webkit-scrollbar': { display: 'none' },
        })}
      >
        <Typography
          variant={isLessMd ? 'h5' : 'h3'}
          sx={{ mb: 5, textAlign: 'left' }}
        >
          Create a New Password
        </Typography>
        <Grid container rowSpacing={1.5}>
          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <PasswordField
                  {...field}
                  error={errors.password?.message}
                  label="Password *"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="repeatPassword"
              control={control}
              render={({ field }) => (
                <PasswordField
                  {...field}
                  error={errors.repeatPassword?.message}
                  label="Repeat Password *"
                />
              )}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 4,
            display: 'flex',
            justifyContent: {
              xs: 'center',
              md: 'flex-end',
            },
          }}
        >
          <Button
            onClick={handleResetPassword}
            size="large"
            variant="contained"
            sx={{ width: 290 }}
            disabled={loading}
          >
            Reset Password
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default CreateNewPassword

import React, { useCallback, useRef, useState } from 'react'
import AuthLayout from '@/components/shared/AuthLayout'

import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert'
import Backdrop from '@mui/material/Backdrop'
import useAttorneyId from '@/hooks/useAttorneyId'
import useAttorneyEmail from '@/hooks/useAttorneyEmail'
import { getSignInRedirectionByRole } from '@/pages/SignIn/utils'
import get from 'lodash/get'
import { useGetDelegatedUsersByCaseManagerLazyQuery } from '@/gql/systemApi'
import { useStripe } from '@/hooks/useStripe'
import { communicationPreferencesDefaultValues } from '@/components/CommunicationPreferences/hooks/useCommunicationPreferences'
import {
  CreateProfileFormProvider,
  CreateProfileFormState,
} from './CreateProfileFormContext'
import { educationDefaultValues } from './Education/types'
import Education from './Education/Education'
import useCreateProfileFlow from './hooks/useCreatProfileFlow'
import PersonalInformation from './PersonalInformation/PersonalInformation'
import PracticeAreas from './PracticeAreas/PracticeAreas'
import useUpdateAttorneyProfile from './hooks/useUpdateAttorneyProfile'
import MembershipInfo from './MembershipInfo/MembershipInfo'

const CreateProfile = () => {
  const { getAttorneyId } = useAttorneyId()
  const { getAttorneyEmail } = useAttorneyEmail()
  const attorneyId = useRef(getAttorneyId())
  const attorneyEmail = useRef(getAttorneyEmail())
  const [paymentLoading, setIsPaymentLoading] = useState(false)
  const { state, next, back, send } = useCreateProfileFlow()
  const {
    updateAttorneyProfile,
    tokenRefresh,
    loading: refreshTokenLoading,
  } = useUpdateAttorneyProfile()
  const [updateProfileError, setUpdateProfileError] = useState('')
  const [formState, setFormState] = useState<CreateProfileFormState>({
    practiceAreas: [],
    personalInfo: {
      bio: '',
      imageUrl: '',
    },
    education: educationDefaultValues,
    membershipInfo: '',
    communicationPreferences: communicationPreferencesDefaultValues,
  })
  const [getUserMeWithRoleData] = useGetDelegatedUsersByCaseManagerLazyQuery()
  const { createCheckoutSubscriptionSession } = useStripe()

  const updateFormState = (
    key: keyof CreateProfileFormState,
    value: CreateProfileFormState[keyof CreateProfileFormState]
  ) => {
    setFormState(prevState => ({ ...prevState, [key]: value }))
  }

  const handlePracticeAreaSubmit = useCallback(
    (practiceAreas: string[]) => {
      updateFormState('practiceAreas', practiceAreas)
      next()
    },
    [next]
  )

  const handleOnSkip = useCallback(async () => {
    send('MEMBERSHIP_INFO')
  }, [send])

  const onPaymentClick = useCallback(async () => {
    setIsPaymentLoading(true)
    const userMe = await getUserMeWithRoleData()
    const APUpdateResult = await updateAttorneyProfile(formState)
    if (APUpdateResult.id) {
      await tokenRefresh()
      await createCheckoutSubscriptionSession({
        attorney_id: attorneyId.current || '',
        price_type: 'subscription',
        customer_email: attorneyEmail.current || '',
        redirect_url: getSignInRedirectionByRole(
          get(userMe, 'data.users_me.role.id', '')
        ),
      })
    }
    if (APUpdateResult.updateAttorneyProfileErrors) {
      setUpdateProfileError(APUpdateResult.updateAttorneyProfileErrors.base)
    }
    setIsPaymentLoading(false)
  }, [
    updateAttorneyProfile,
    getUserMeWithRoleData,
    formState,
    tokenRefresh,
    attorneyEmail,
    attorneyId,
  ])

  const renderStep = React.useMemo((): JSX.Element => {
    switch (state.value) {
      case 'practiceAreas':
        return (
          <PracticeAreas
            initialPracticeAreas={formState.practiceAreas}
            onSubmit={handlePracticeAreaSubmit}
            secondaryBtnAction={handleOnSkip}
            loading={refreshTokenLoading}
          />
        )
      case 'personalInfo':
        return <PersonalInformation back={back} next={next} />
      case 'education':
        return (
          <Education
            back={back}
            initialEducation={formState.education}
            onSubmit={data => updateFormState('education', data)}
          />
        )
      case 'membershipInfo':
        return (
          <MembershipInfo
            secondaryBtnTitle="Back"
            onSecondaryBtnClick={back}
            submitTitle="Activate Your Free Membership"
            onSubmit={onPaymentClick}
            loading={refreshTokenLoading}
          />
        )
      default:
        return <CircularProgress />
    }
  }, [
    state.value,
    formState.practiceAreas,
    formState.education,
    handlePracticeAreaSubmit,
    handleOnSkip,
    refreshTokenLoading,
    back,
    next,
    onPaymentClick,
  ])

  return (
    <AuthLayout>
      {paymentLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={paymentLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {updateProfileError && (
        <Alert severity="error" onClose={() => setUpdateProfileError('')}>
          {updateProfileError}
        </Alert>
      )}
      <CreateProfileFormProvider value={{ formState, updateFormState }}>
        {renderStep}
      </CreateProfileFormProvider>
    </AuthLayout>
  )
}

export default CreateProfile

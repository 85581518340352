import { useState, useEffect } from 'react'
import { CaseEntry } from '../../types'

export interface IUseNeedsYourAttentionProps {
  data: CaseEntry[]
  searchText?: string
}

const useNeedsYourAttention = ({
  data,
  searchText = '',
}: IUseNeedsYourAttentionProps) => {
  const [filteredData, setFilteredData] = useState<CaseEntry[]>([])

  useEffect(() => {
    if (searchText === '') {
      setFilteredData(data)
    } else {
      const newDataFiltered: CaseEntry[] = []
      data.forEach(caseEntry => {
        if (caseEntry?.name.toLowerCase().includes(searchText.toLowerCase())) {
          newDataFiltered.push(caseEntry)
        }
      })
      setFilteredData(newDataFiltered)
    }
  }, [searchText, data])

  return {
    filteredData,
  }
}

export default useNeedsYourAttention

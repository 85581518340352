import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

export type FeeVariant = 'contained' | 'outlined' | 'secondary'

export interface FeeProps {
  fee: number
  showText?: boolean
  variant?: FeeVariant
}

export interface FeeContainerProps {
  variant: FeeVariant
}

const FeeContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'variant',
})<FeeContainerProps>(({ variant, theme }) => ({
  borderRadius: '4px',
  height: '2.25rem',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1.5),
  ...(variant === 'contained' && {
    backgroundColor: theme.palette.secondary.main,
  }),
  ...(variant === 'outlined' && {
    borderWidth: '2px',
    borderColor: theme.palette.secondary.main,
  }),
  ...(variant === 'secondary' && {
    backgroundColor: theme.palette.secondary.light, // secondary.lightest
  }),
}))

const Fee = ({ fee, showText, variant = 'contained' }: FeeProps) => {
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Box
      sx={theme => ({
        display: 'flex',
        gap: theme.spacing(1.5),
        maxWidth: isLargeScreen ? '200px' : '175px',
        alignItems: 'center',
      })}
    >
      <FeeContainer variant={variant}>
        <Typography
          variant="subtitle1"
          sx={{
            color: theme.palette.primary.main,
          }}
        >
          {fee}%
        </Typography>
      </FeeContainer>
      {showText && (
        <Typography variant="overline" color="text.disabled">
          Referral/Co-Counsel Fee Requested
        </Typography>
      )}
    </Box>
  )
}

export default Fee

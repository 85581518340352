import { useState, useEffect } from 'react'

const MAINTENANCE_FLAG_URL = import.meta.env.VITE_MAINTENANCE_FLAG_URL
const OPTIMIZELY_ENV_KEY = import.meta.env.VITE_OPTIMIZELY_ENV_KEY

export const useMaintenanceMode = () => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const checkMaintenanceMode = async () => {
      if (!MAINTENANCE_FLAG_URL) {
        setIsMaintenanceMode(false)
        setIsLoading(false)
        return
      }

      try {
        const url = new URL(MAINTENANCE_FLAG_URL)
        url.searchParams.append('env_key', OPTIMIZELY_ENV_KEY)

        const response = await fetch(url.toString(), {
          method: 'GET',
          mode: 'cors',
        })

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const { isEnabled } = await response.json()
        setIsMaintenanceMode(isEnabled)
      } catch (error) {
        console.error('Failed to check maintenance mode:', error)
        setIsMaintenanceMode(false)
      } finally {
        setIsLoading(false)
      }
    }

    checkMaintenanceMode()
  }, [])

  return { isMaintenanceMode, isLoading }
}

import { useMemo } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import AuthLayout from '@/components/shared/AuthLayout'
import PracticeAreas from '@/pages/CreateProfile/PracticeAreas/PracticeAreas'
import Education from '@/pages/CreateProfile/Education/Education'
import { TTokenValidationResponse } from '@/hooks/useTokenValidation'
import ProfileCommunicationPreferences from '@/pages/CreateProfile/ProfileCommunicationPreferences/ProfileCommunicationPreferences'
import { CreateAccountData } from '../InitialStep/types'
import { useCreateInvitedAttorney } from './hooks/useCreateInvitedAttorney'
import { OrganizationCreateProfile } from '../CreateOrganization/OrganizationCreateProfile'
import ProfessionalLicenses from '../ProfessionalLicense/ProfessionalLicenses'

interface CreateInvitedAttorneyProps {
  goBack: () => void
  createAccountValues: CreateAccountData
  tokenData: TTokenValidationResponse
}

const CreateInvitedAttorney = ({
  goBack,
  createAccountValues,
  tokenData,
}: CreateInvitedAttorneyProps) => {
  const {
    state,
    formState,
    back,
    handleCreateAccount,
    handleUpdateOrgUser,
    submitAndContinue,
    goToCommunicationPreferences,
    loading,
  } = useCreateInvitedAttorney({ createAccountValues, tokenData })

  const renderStep = useMemo((): JSX.Element => {
    switch (state.value) {
      case 'professionalLicense':
        return (
          <ProfessionalLicenses
            back={goBack}
            professionalLicensesValues={formState.professionalLicenses}
            onSubmit={handleCreateAccount}
            loading={loading}
            primaryBtnText="Create My Account"
          />
        )
      case 'practiceAreas':
        return (
          <PracticeAreas
            initialPracticeAreas={formState.practiceAreas}
            onSubmit={data => submitAndContinue('practiceAreas', data)}
            secondaryBtnAction={goToCommunicationPreferences}
            loading={loading}
          />
        )
      case 'education':
        return (
          <Education
            back={back}
            initialEducation={formState.education}
            onSubmit={data => submitAndContinue('education', data)}
          />
        )
      case 'personalInfo':
        return (
          <OrganizationCreateProfile
            back={back}
            hasLicense
            profileValues={formState.personalInfo}
            onSubmit={data => submitAndContinue('personalInfo', data)}
            loading={loading}
            nextBtnText="Continue"
            prevBtnText="Back"
          />
        )
      case 'communicationPreferences':
        return (
          <ProfileCommunicationPreferences
            back={back}
            initialCommPreferences={formState.communicationPreferences}
            onSubmit={handleUpdateOrgUser}
          />
        )
      default:
        return <CircularProgress />
    }
  }, [
    state.value,
    goBack,
    formState.professionalLicenses,
    formState.practiceAreas,
    formState.education,
    formState.personalInfo,
    formState.communicationPreferences,
    handleCreateAccount,
    loading,
    goToCommunicationPreferences,
    back,
    handleUpdateOrgUser,
    submitAndContinue,
  ])

  return <AuthLayout>{renderStep}</AuthLayout>
}

export default CreateInvitedAttorney

import {
  CaseStatusFlowValue,
  CaseStatusValue,
  TimeUnitValue,
} from '@/constants'
import { Directus_Users } from '@/gql/systemApi'

export const ALL_REFERRAL_FILTER_TYPES_CATALOG = {
  REFERRAL_TYPE: 'referral_type',
  ATTORNEY: 'attorney',
  CASE_MANAGER: 'case_manager',
}

type AllReferralFilterTypesKeys = keyof typeof ALL_REFERRAL_FILTER_TYPES_CATALOG

export type AllReferralFilterType =
  | (typeof ALL_REFERRAL_FILTER_TYPES_CATALOG)[AllReferralFilterTypesKeys]
  | null

export interface AllReferralFilterItem {
  id: number | string
  name: string
  type: AllReferralFilterType
  data: string
}

export type CaseEntryFile = Record<
  string,
  {
    id: string
    filename_download: string
    metadata: {
      is_fee_agreement: boolean
      case_purchase_id: string
    }
  }
>

export type CaseEntry = {
  id: string
  needs_your_attention: boolean
  following: boolean
  name: string
  description: string
  mp_visibility: string
  fee: number
  financing_source_id: string
  financing_source: string
  type_id: string
  type: string
  jurisdiction_id: string
  jurisdiction: string
  process_stage?: null | string
  preferred_language: null | string
  incident_date: null | string
  proposal_due_date: null | string
  case_number: string
  case_alias?: string
  internal_case_number?: string
  data_room_filesize?: null | number
  data_room_filename?: null | string
  attorney_id: string
  created_ts: string
  following_count: number
  deleted: boolean
  created_by_user_id: string | null
  attorney: Attorney
  leads: Lead[]
  case_purchase: CasePurchase[]
  waterfall: Waterfall[]
  data_room: DataRoom[]
  collaborators: Attorney[]
  prospect_collaborators?: Attorney[]
  status: CaseStatusFlowValue
  /**
   * Represents user IDs of Case Managers on the seller side.
   */
  ownerships?: Directus_Users['id'][]
}

export type Attorney = {
  attorney_id: string
  is_vetted: boolean
  membership_type: string
  first_name: string
  last_name: string
  user_id: string
  avatar: string | null
  organization_id: string | null
  organization_name: string | null
  role: string
}

type Lead = {
  case_id: string
  first_name: string
  last_name: string | null
  email?: string
  phone?: string
}

export type CasePurchase = {
  id: string
  prospect_attorney_id: string
  case_id: string
  /**
    The creator of the case purchase activity, not the case itself
    */
  created_by: string
  status: CaseStatusValue
  created_ts: string
  waterfall_id?: string | null
  waterfall_order: number
  time_unit: TimeUnitValue
  timed_access_limit: number
  active_ts: string
  deleted: boolean
  created_by_user_id: string | null
  prospect_attorney: Attorney
  created_by_attorney: Attorney | undefined
  case_purchase_activities: CasePurchaseActivity
  payments?: Payment[]
  status_ts: string
  prospect_collaborators: Attorney[]
  fee_agreement_status?: string
  fee_agreement_status_ts?: string
}

type CasePurchaseActivity = {
  id: string
  case_purchase_id: string
  fee: number
  message: string
  decline_reason_id: string | null
  /**
    Status of the current activity.
    */
  activity_entry_status: CaseStatusValue
  created_by: string
  created_ts: string
  deleted: boolean
  created_by_user_id: string | null
  row_num: string
  created_by_attorney: Attorney
  files?: CaseEntryFile[]
}

type Waterfall = {
  case_purchase_id: string
  case_id: string
  prospect_attorney_id: string
  status: string
  waterfall_order: number
  item_count: number
  current_item_order: number
  current_item_id: string
  prospect_attorney: Attorney
}

export type DataRoom = {
  id: string
  attorney_id: string
  requested_case_id: string
  requested_by_user_id: string
  message: string | null
  created_ts: string
  validation_ts?: string | null
  email_sent_ts?: string | null
  is_granted: boolean | null
  target_user_id?: string
  owner_email?: string | null
  email_service_response?: string | null
  requester_name?: string | null
  requested_file_name?: string | null
  status: string
  created_by_attorney?: Attorney
}

export type Payment = {
  id: string
  case_purchase_id: string
  attorney_id: string
  /**
    Status of the current payment. Can be one of PAYMENT_STATUS ids found in the constants file.
    */
  payment_status_id: string
  /**
    The attorney appearing here is the main attorney of the case negotation,
    but might not be the same as the attorney that triggered the payment.
    This is used to check if the payment was made by the same organization of the current user.
    */
  attorney: Attorney
  amount: string
  payment_method: string
}

import { ReactNode } from 'react'
import { TPaymentStatus } from '@/pages/MyReferralsV2/NegotiationItem/types'
import {
  MembersCreditsResponse,
  MembersPricesResponse,
} from '@/utils/api/types'
import { getCurrencyString } from '@/utils'

interface ProposalPriceProps {
  pricesData: MembersPricesResponse | null
  creditsData: MembersCreditsResponse | null
  agreement?: ReactNode | null
  paymentStatus?: TPaymentStatus
  isLoading: boolean
  isCaseOwner?: boolean
  isInvited?: boolean
  isProposal?: boolean
  noPaymentRequired?: boolean
}

const AcceptProposalPrice = ({
  pricesData,
  creditsData,
  agreement,
  paymentStatus = {
    currentUserPaid: false,
    counterpartPaid: false,
    bothPartiesPaid: false,
  },
  isLoading,
  isCaseOwner,
  isInvited,
  isProposal,
  noPaymentRequired,
}: ProposalPriceProps) => {
  const credits: number | string | undefined =
    creditsData?.organization?.credits

  const getProposalType = () => {
    if (isProposal) {
      return 'Send Proposal'
    }
    if (noPaymentRequired) {
      return 'Accept'
    }
    if (!agreement) {
      return 'Accept Proposal'
    }
    if (agreement && !paymentStatus.currentUserPaid) {
      return 'Accept Agreement'
    }

    return 'Accept'
  }

  const isFreePrice = () => credits === 'unlimited'

  const hasCredits = (): boolean => {
    if (Number(credits) >= 1) {
      return true
    }
    return false
  }

  const getPrice = () => {
    if (!pricesData) {
      return 'Loading...'
    }

    const proposalType = getProposalType()

    if (noPaymentRequired && !isProposal) {
      return `${proposalType}`
    }

    if (isCaseOwner || isInvited) {
      return `${proposalType}${isInvited ? ' - FREE' : ''}`
    }

    const finalPrice = Number(pricesData?.finalPrice) || 0

    // Determine if the scenario qualifies for a 'FREE' price tag
    const qualifiesForFree = isFreePrice() || hasCredits()

    if (qualifiesForFree) {
      return `${proposalType} - FREE`
    }

    const displayedFinalPrice = getCurrencyString(Number(finalPrice))

    const hasDiscount =
      Number(pricesData?.discount ?? 0) > 0 &&
      pricesData?.discount_stripe_price_id

    if (hasDiscount) {
      const normalPriceNumber = Number(pricesData?.original_price)
      const normalRoundedPrice = Math.round(normalPriceNumber * 100) / 100
      const displayedNormalPrice = getCurrencyString(normalRoundedPrice)
      return (
        <div>
          {proposalType} - <s>{displayedNormalPrice}</s> {displayedFinalPrice}
        </div>
      )
    }

    return `${proposalType} - ${displayedFinalPrice}`
  }

  if (isLoading) {
    return <>Loading...</>
  }

  return <>{getPrice()}</>
}

export default AcceptProposalPrice

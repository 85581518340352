import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import JUser from '@/components/atoms/JUser'
import { alpha, useTheme } from '@mui/material/styles'
import { WaterfallMember } from '@/components/OrganizationWaterfalls/types'

interface IWaterfallAttorneyProps {
  attorney: WaterfallMember
  position: number
}

const WaterfallAttorney = ({ attorney, position }: IWaterfallAttorneyProps) => {
  const theme = useTheme()
  const fullName = attorney?.attorneyName ?? ''
  const organization = attorney?.attorneyOrganization ?? ''
  const avatar = attorney?.avatar
  const isVetted = attorney?.isVetted || false
  const isQualified = attorney?.isQualified || false

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
      <Typography
        variant="body2"
        color={alpha(theme.palette.common.black, 0.6)}
        sx={{ width: '20px' }}
      >
        {position}
      </Typography>
      {attorney ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            ml: 1,
          }}
        >
          <JUser
            mainText={fullName}
            secondaryText={organization}
            avatar={avatar}
            isVetted={isVetted}
            isQualified={isQualified}
          />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              border: '2px dashed',
              borderColor: alpha(theme.palette.common.black, 0.15),
              borderRadius: '50%',
              width: '36px',
              height: '36px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ml: 1,
              mr: 2.5,
              my: 0.25,
            }}
          />
          <Typography
            variant="caption"
            color={alpha(theme.palette.common.black, 0.38)}
          >
            Empty position
          </Typography>
        </>
      )}
    </Box>
  )
}

export default WaterfallAttorney

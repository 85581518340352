import { styled, Theme } from '@mui/material/styles'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface ExpandMoreButtonProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'expand',
})((props: ExpandMoreButtonProps) => ({ theme }: { theme: Theme }) => ({
  transform: !props.expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

const ExpandMoreButton = (props: ExpandMoreButtonProps) => (
  <ExpandMore {...props}>
    <ExpandMoreIcon />
  </ExpandMore>
)

export default ExpandMoreButton

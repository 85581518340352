import { useMemo } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { TCase } from '@/pages/Case/types'
import FilesViewer from '@/components/DataRoom/FilesViewer/FilesViewer'
import { Case_File } from '@/gql/systemApi'
import RequestAccess from '../RequestAccess'
import PendingAccess from '../PendingAccess'
import ErrorAccess from '../ErrorAccess'
import useDataroomNonOwner from './hooks/useDataroomNonOwner'

type TDataroomNonOwnerProps = {
  caseData: TCase
  files?: Case_File[]
  refetchCaseData: () => void
  showCaseOptions?: boolean
}

const DataroomNonOwner = ({
  caseData,
  files,
  refetchCaseData,
  showCaseOptions,
}: TDataroomNonOwnerProps) => {
  const { status, userId, handleStatusChange } = useDataroomNonOwner({
    caseData,
  })

  const renderComponent = useMemo(() => {
    switch (status) {
      case 'LOADING':
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        )
      case 'REQUEST_ACCESS':
        return (
          <RequestAccess
            caseId={caseData.id}
            userId={userId}
            handleStatusChange={handleStatusChange}
          />
        )
      case 'PENDING':
        return <PendingAccess />
      case 'ACCEPTED':
        return (
          <FilesViewer
            isOwner={false}
            caseId={caseData.id}
            caseName={caseData.name || ''}
            files={files}
            refetchCaseData={refetchCaseData}
          />
        )
      case 'DENIED':
        return (
          <RequestAccess
            caseId={caseData.id}
            userId={userId}
            handleStatusChange={handleStatusChange}
            accessDenied
          />
        )
      case 'ERROR':
        return <ErrorAccess />
      case 'CM_NO_OWNERSHIP':
      default:
        return null
    }
  }, [
    caseData.id,
    caseData.name,
    files,
    handleStatusChange,
    refetchCaseData,
    status,
    userId,
  ])

  if (!files?.length || !showCaseOptions) return null

  const hideHeader =
    status === 'ACCEPTED' ||
    status === 'LOADING' ||
    status === 'CM_NO_OWNERSHIP'

  return (
    <Grid container sx={{ mt: 1 }}>
      {!hideHeader && (
        <Grid item xs={7} sx={{ my: 1 }}>
          <Typography variant="h6">Data Room</Typography>
        </Grid>
      )}
      {renderComponent}
    </Grid>
  )
}

export default DataroomNonOwner

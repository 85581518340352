import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import { alpha, useTheme } from '@mui/material/styles'
import { IJRadioCardProps } from './types'
import {
  CustomRadio,
  RadioSubTitleTypography,
  RadioTitleTypography,
} from './styles'

const JRadioCard = ({
  label,
  description,
  selected,
  onChange,
  sx,
  ...rest
}: IJRadioCardProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={[
        {
          width: '100%',
          border: selected
            ? `2px solid ${theme.palette.secondary.main}`
            : `1px solid ${alpha(theme.palette.common.black, 0.12)}`,
          borderRadius: '4px',
          display: 'flex',
          padding: theme.spacing(2, 1.5),
          cursor: 'pointer',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onClick={onChange}
      {...rest}
    >
      <FormControlLabel
        control={<CustomRadio checked={selected} />}
        label={
          <Box>
            <RadioTitleTypography variant="subtitle1">
              {label}
            </RadioTitleTypography>
            <RadioSubTitleTypography variant="body2">
              {description}
            </RadioSubTitleTypography>
          </Box>
        }
      />
    </Box>
  )
}

export default JRadioCard

import { useEffect, useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import { alpha, useTheme } from '@mui/material/styles'
import JSnackBar from '@/components/atoms/JSnackBar'
import { FEE_AGREEMENT_STATUS } from '@/constants'
import useFeeAgreements from '../useFeeAgreements'

interface IFeeAgreementSwitchProps {
  casePurchaseId: string
  isSignedWithNoFile: boolean
}

const FeeAgreementSwitch = ({
  casePurchaseId,
  isSignedWithNoFile,
}: IFeeAgreementSwitchProps) => {
  const theme = useTheme()
  const [signedWithNoFile, setSignedWithNoFile] = useState(isSignedWithNoFile)
  const { alert, handleClose, loading, updateFeeAgreement } = useFeeAgreements()

  useEffect(() => {
    setSignedWithNoFile(isSignedWithNoFile)
  }, [isSignedWithNoFile])

  const handleSwitchChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.checked
    setSignedWithNoFile(newValue)
    await updateFeeAgreement({
      feeAgreementStatusId: newValue
        ? FEE_AGREEMENT_STATUS.SignedAndWillNotBeUploaded
        : null,
      casePurchaseId,
    })
  }

  return (
    <>
      <FormControlLabel
        sx={{ mt: 2 }}
        control={
          <Switch
            checked={signedWithNoFile}
            onChange={handleSwitchChange}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{
              alignSelf: 'flex-start',
            }}
          />
        }
        label={
          <>
            <Typography
              variant="body2"
              color={alpha(theme.palette.common.black, 0.87)}
            >
              An agreement was signed but won&apos;t be uploaded to Attorney
              Share
            </Typography>
            <Typography component="p" variant="caption" sx={{ mt: 1 }}>
              Uploading copies of the fee agreements to the platform will
              provide you with a quick and secure way to access all your
              agreement records
            </Typography>
          </>
        }
        disabled={loading}
      />
      <JSnackBar
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        horizontal="center"
        vertical="bottom"
        handleClose={handleClose}
      />
    </>
  )
}

export default FeeAgreementSwitch

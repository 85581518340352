import Alert from '@mui/material/Alert'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { JHQ_LINKS } from '@/constants'

interface INonJHQMemberAlertProps {
  hasJHQMembersInMyOrg: boolean
  isCaseManager: boolean
  hasAdminAccess: boolean
}

const NonJHQMemberAlert = ({
  hasJHQMembersInMyOrg,
  isCaseManager,
  hasAdminAccess,
}: INonJHQMemberAlertProps) => {
  const theme = useTheme()
  const isOrgMember = !hasJHQMembersInMyOrg && (isCaseManager || hasAdminAccess)
  const textVariation = isOrgMember
    ? 'at least one member of your law firm'
    : 'you'

  return (
    <Alert severity="error" sx={{ width: '100%' }} icon={false}>
      <Typography variant="body2" sx={{ color: theme.palette.error.dark }}>
        In order to submit proposals to Marketplace cases {textVariation} must
        be a{' '}
        <Link
          href={JHQ_LINKS.JusticeHQ}
          display="inline"
          target="_blank"
          sx={{ color: 'inherit' }}
        >
          JHQ Vetted
        </Link>{' '}
        or Attorney Share Qualified member. Check out our{' '}
        <Link
          href={JHQ_LINKS.UserTypes}
          display="inline"
          target="_blank"
          sx={{ color: 'inherit' }}
        >
          Help Center article
        </Link>{' '}
        on Attorney Share user types to learn more.
      </Typography>
    </Alert>
  )
}

export default NonJHQMemberAlert

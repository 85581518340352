import { ParameterName, bootParamsService } from '@/services/BootParams'
import useOrganizationId from '@/hooks/organization/useOrganizationId'
import { useGetLcmsData } from '@/utils/api/integrations'
import { caseTypes } from './types'

const useGetIntegrationData = () => {
  const { id: orgId } = useOrganizationId()
  const srcQueryParam = bootParamsService.get(ParameterName.SRC)
  const caseIdQueryParam = bootParamsService.get(ParameterName.CASE_ID)

  const srcParam = String(srcQueryParam)

  const isCaseType = Boolean(
    orgId && caseIdQueryParam && caseTypes.includes(srcParam)
  )

  let caseId = caseIdQueryParam || ''
  let shouldEnable = isCaseType

  /**
   * Special rules for Clio, as it doesn't have a case_id.
   * We need to obtain the number after /matters/ in the URL that is part of the subject_url query param and only if src=clio
   */
  if (srcParam === 'clio') {
    const subjectUrl = bootParamsService.get(ParameterName.SUBJECT_URL)
    caseId = subjectUrl?.split('/matters/')[1] || ''
    shouldEnable = Boolean(orgId && caseId && caseTypes.includes(srcParam))
  }

  const {
    isLoading,
    data,
    error: lcmsError,
  } = useGetLcmsData({
    caseId: String(caseId),
    src: srcParam,
    organizationId: orgId || '',
    shouldEnable,
  })

  const lcmsData = !isLoading && data ? data : null

  return {
    lcmsData,
    lcmsError,
  }
}

export default useGetIntegrationData

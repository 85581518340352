/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Catalog_Financing_Source,
  Catalog_Language,
  Catalog_Practice_Area,
  Catalog_Process_Stage,
  Catalog_Us_State,
  CreateCaseCatalogsQueryResult,
  useCreateCaseCatalogsQuery,
  useCreateNewCaseMutation,
  useCreateWaterfallItemMutation,
} from '@/gql/appApi'

import { useCallback, useEffect, useRef, useState } from 'react'
import {
  CASE_FILE_TYPE,
  CONTACT_TYPES,
  MP_VISIBILITY,
  NEW_DATAROOM_ENABLED,
} from '@/constants'
import { useFileUpload } from '@/hooks/useFileUpload'
import { Update_Directus_Files_Input } from '@/gql/systemApi'
import useAttorneyId from '@/hooks/useAttorneyId'
import { TLeadFormData } from '../types'

type TCaseAssignee = {
  assigneeId: string
  position: number
  timeLimit: number
  timeUnitId: string
}

type TCreateCase = {
  alias?: string
  caseNumber?: string
  dataRoomFile: File[] | File | null
  description: string
  // TODO: Make this property required after https://attorney.atlassian.net/browse/MP-1782 is delivered.
  descriptionFormatted?: string
  fee: number
  financingSource: string
  incidentDate?: string
  internalCaseNumber?: string
  jurisdiction?: string
  leadInfo?: TLeadFormData[]
  name: string
  language?: string
  processStage?: string
  proposalDueDate?: string
  type?: string
  isPrivate: boolean
  assigneeData?: TCaseAssignee[]
  onBehalfOfId?: string
  caseManagerIds?: string[]
}

type TWaterfallItem = {
  caseId: string
  assigneeData: TCaseAssignee[]
}

const DEFAULT_ERROR =
  'There appears to be a problem with your submission. Please try again.'

const useCreateNewCase = () => {
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = useRef(getAttorneyId())
  // TODO: Pass 'true' to the useFileUpload(true) hook to enable file upload into the Data Room
  // once the new Data Room is ready.
  const { upload } = useFileUpload()
  const onError = useCallback(() => {
    const errors = { base: DEFAULT_ERROR }
    return {
      baseErrors: errors,
    }
  }, [])

  const [createNewCase, { loading: createNewCaseLoading }] =
    useCreateNewCaseMutation({
      onError,
    })

  const [startWaterfallProcess, { loading: startWaterfallProcessLoading }] =
    useCreateWaterfallItemMutation({
      onError,
    })

  const {
    data: catalogData,
    loading: catalogLoading,
    error,
  }: CreateCaseCatalogsQueryResult = useCreateCaseCatalogsQuery({
    variables: {
      filter: {
        code: {
          _nin: ['AA', 'AE', 'AP', 'VI'],
        },
      },
      sort: ['sort_order'],
    },
  })

  const [financingSources, setFinancingSources] = useState<
    Catalog_Financing_Source[]
  >([])
  const [processStages, setProcessStages] = useState<Catalog_Process_Stage[]>(
    []
  )
  const [usStates, setUsStates] = useState<Catalog_Us_State[]>([])
  const [languages, setLanguages] = useState<Catalog_Language[]>([])
  const [caseTypes, setCaseTypes] = useState<Catalog_Practice_Area[]>([])

  const loading =
    createNewCaseLoading || catalogLoading || startWaterfallProcessLoading

  const sortData = useCallback(
    (data: any[], keyValue: string) =>
      data.sort((a, b) => {
        if (a[keyValue] == null && b[keyValue] == null) return 0
        if (a[keyValue] == null) return 1
        if (b[keyValue] == null) return -1

        return a[keyValue].localeCompare(b[keyValue])
      }),
    []
  )

  const cleanSupportLanguages = useCallback(
    (data: Catalog_Language[]) =>
      data.filter(
        language => language.id !== 'a181673c-14fd-11ee-8ec5-4fc84d373c2d'
      ),
    []
  )

  useEffect(() => {
    if (catalogData) {
      const rawFinancingSources = catalogData.catalog_financing_source
        ? [...catalogData.catalog_financing_source]
        : []
      const rawProcessStages = catalogData.catalog_process_stage
        ? [...catalogData.catalog_process_stage]
        : []
      const rawUsStates = catalogData.catalog_us_state
        ? [...catalogData.catalog_us_state]
        : []
      const rawLanguages = catalogData.catalog_language
        ? [...catalogData.catalog_language]
        : []
      const rawCaseTypes = catalogData.catalog_practice_area
        ? [...catalogData.catalog_practice_area]
        : []
      const supportLanguages = cleanSupportLanguages(rawLanguages)
      setFinancingSources(sortData(rawFinancingSources, 'name'))
      setProcessStages(rawProcessStages)
      setUsStates(sortData(rawUsStates, 'name'))
      setLanguages(sortData(supportLanguages, 'name'))
      setCaseTypes(sortData(rawCaseTypes, 'name'))
    }
  }, [catalogData, cleanSupportLanguages, sortData])

  const uploadDataRoomFile = useCallback(
    async (dataRoomFileToUpload: File) => {
      try {
        const value = await upload(dataRoomFileToUpload, () => null).then(
          (res: any) => res.data.data as Update_Directus_Files_Input
        )
        return value
      } catch (err) {
        return null
      }
    },
    [upload]
  )

  const createWaterfallItem = useCallback(
    async ({ caseId, assigneeData }: TWaterfallItem) => {
      // Sort by position then map to items, waterfall item takes the order of the items
      // to establish the priority
      const items = assigneeData
        .sort((a, b) => a.position - b.position)
        .map(item => ({
          prospect_attorney_id: item.assigneeId,
          time_unit: item.timeUnitId,
          timed_access_limit: item.timeLimit,
          case_id: {
            id: caseId,
          },
        }))

      const { data, errors } = await startWaterfallProcess({
        variables: {
          data: {
            items,
          },
        },
      })
      if (data?.create_attorney_waterfall_item?.id) {
        return {
          id: data.create_attorney_waterfall_item.id,
        }
      }
      if (errors) {
        return {
          createWaterfallErrors: {
            base: 'Waterfall item creation failed. Please try again.',
            server: errors[0]?.message,
          },
        }
      }
      return {}
    },
    [startWaterfallProcess]
  )

  const createCase = useCallback(
    async ({
      language,
      alias,
      caseNumber,
      dataRoomFile,
      description,
      descriptionFormatted,
      fee,
      financingSource,
      incidentDate,
      internalCaseNumber,
      jurisdiction,
      leadInfo,
      name,
      processStage,
      proposalDueDate,
      type,
      assigneeData,
      isPrivate,
      onBehalfOfId,
      caseManagerIds,
    }: TCreateCase) => {
      const filesWithError: string[] = []

      let dataRoomFileInfo: Update_Directus_Files_Input | null = null
      if (!NEW_DATAROOM_ENABLED && dataRoomFile) {
        dataRoomFileInfo = await uploadDataRoomFile(dataRoomFile as File)
      }

      const options = {
        ...(alias ? { case_alias: alias } : {}),
        ...(caseNumber ? { case_number: caseNumber } : {}),
        ...(dataRoomFileInfo?.id &&
        dataRoomFileInfo?.storage &&
        dataRoomFileInfo?.filename_download
          ? {
              files: [
                {
                  file_id: {
                    id: dataRoomFileInfo.id,
                    filename_download: dataRoomFileInfo.filename_download,
                    storage: dataRoomFileInfo.storage,
                  },
                  alias: dataRoomFileInfo?.title || '',
                  // "Data Room" case file type id
                  case_file_type: CASE_FILE_TYPE.Dataroom,
                },
              ],
            }
          : {}),
        ...(processStage ? { process_stage: processStage } : {}),
        ...(proposalDueDate ? { proposal_due_date: proposalDueDate } : {}),
        ...(type ? { type } : {}),
        ...(incidentDate ? { incident_date: incidentDate } : {}),
        ...(internalCaseNumber
          ? { internal_case_number: internalCaseNumber }
          : {}),
        ...(jurisdiction ? { jurisdiction } : {}),
        ...(language ? { preferred_language: language } : {}),
        ...(caseManagerIds && caseManagerIds.length > 0
          ? {
              ownerships: [
                ...caseManagerIds.map(id => ({
                  user_id: id,
                })),
              ],
            }
          : {}),
      }

      const getContacts = (lead: TLeadFormData) => {
        const newContacts = [
          lead.email && {
            type: CONTACT_TYPES.EmailContactType,
            value: lead.email,
          },
          lead.phone && {
            type: CONTACT_TYPES.PhoneContactType,
            value: lead.phone,
          },
        ]
        const n = newContacts.filter(contact => contact)
        return n
      }

      const leads = leadInfo
        ?.map(
          (l: TLeadFormData) =>
            l.firstName &&
            l.firstName !== '' && {
              first_name: l.firstName,
              last_name: l.lastName,
              contacts: getContacts(l),
            }
        )
        .filter(l => l)

      const { data, errors } = await createNewCase({
        variables: {
          data: {
            // Case Managers are required to select an attorney to create a case,
            // but attorneys and admin attorneys are not required to select one,
            // so we use the attorney_id from the current user if the onBehalfOfId is not provided.
            attorney_id: onBehalfOfId || attorneyId.current,
            description,
            description_formatted: descriptionFormatted,
            fee,
            financing_source: financingSource,
            // eslint-disable-next-line
            // @ts-expect-error
            leads,
            name,
            mp_visibility: isPrivate
              ? MP_VISIBILITY.Priority
              : MP_VISIBILITY.Public,
            ...options,
          },
        },
      })
      if (data?.create_case_item?.id) {
        const caseId = data.create_case_item.id

        if (NEW_DATAROOM_ENABLED && Array.isArray(dataRoomFile)) {
          // uploading new dataroom files
          const fetchData = async (dataRoomFileToUpload: File) => {
            try {
              const value = await upload(
                dataRoomFileToUpload,
                () => null,
                caseId || undefined
              ).then(
                (res: any) => res?.data?.data as Update_Directus_Files_Input
              )
              return value
            } catch (err) {
              return null
            }
          }

          await Promise.all(
            dataRoomFile.map(async file => {
              await fetchData(file).then(res => {
                if (res === null) {
                  filesWithError.push(file?.name)
                }
              })
            })
          )
        }

        if (isPrivate) {
          if (assigneeData) {
            const { id: waterfallItemId, createWaterfallErrors } =
              await createWaterfallItem({
                caseId,
                assigneeData,
              })
            if (waterfallItemId) {
              return {
                id: caseId,
                waterfallItemId,
                dataroomErrors: filesWithError,
              }
            }
            if (createWaterfallErrors) {
              return {
                createCaseErrors: {
                  base: createWaterfallErrors.base,
                  server: createWaterfallErrors.server,
                },
              }
            }
          }
        }
        return {
          id: caseId,
          dataroomErrors: filesWithError,
        }
      }
      if (errors) {
        return {
          createCaseErrors: {
            base: 'New case creation failed. Please try again.',
            server: errors[0]?.message,
          },
        }
      }
      return {}
    },
    [createNewCase, createWaterfallItem, uploadDataRoomFile, upload]
  )

  return {
    financingSources,
    processStages,
    usStates,
    languages,
    caseTypes,
    createCase,
    loading,
    error,
  }
}

export default useCreateNewCase

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Card from '@mui/material/Card'
import Button from '@mui/material/Button'

import useTheme from '@mui/material/styles/useTheme'
import { ClioNonAdminIntegrationItemProps } from '@/pages/AccountSettings/Integrations/types'
import JSnackBar from '@/components/atoms/JSnackBar'
import { ParameterName, bootParamsService } from '@/services/BootParams'
import { useSnackBar } from '@/hooks/useSnackBar'
import IntegrationItemCardContent from '../shared/IntegrationItemCardContent'
import useClioIntegrationItem, {
  ClioIntegrationSource,
} from './useClioIntegrationItem'

import textContent from '../../constants'
import { isSetupSuccessful } from '../shared/utils'

const ClioNonAdminIntegrationItem = ({
  icon,
  name,
  subtitle,
  isCurrentUserConnectedToClio,
  organizationId,
  loading,
  removeIntegrationItem,
  refetchLcmsData,
  isConnectingToLcms,
  connectingToLcms,
}: ClioNonAdminIntegrationItemProps) => {
  const {
    INTEGRATION_V2: { CLIO_GROW },
  } = textContent
  const theme = useTheme()
  const navigate = useNavigate()
  const codeQueryParam = bootParamsService.get(ParameterName.CLIO_CODE)
  const srcQueryParam = bootParamsService.get(ParameterName.SRC)

  const [clioRemoveStatus, setClioRemoveStatus] = useState(false)
  const [connectClio, setConnectClio] = useState(
    Boolean(codeQueryParam && organizationId !== '')
  )
  const clioName = CLIO_GROW.SHORT_NAME.toLowerCase()

  const {
    alert: clioAlert,
    showAlert: clioShowAlert,
    handleClose: clioHandleClose,
  } = useSnackBar()

  const {
    connectClioIntegration,
    connectClioIntegrationData,
    removeClioData,
    removeClioIsLoading,
    isClioIntegrationLoading,
  } = useClioIntegrationItem({
    code: String(codeQueryParam),
    connectClio,
    clioRemoveStatus,
    organizationId: String(organizationId),
    clioName,
    source: ClioIntegrationSource.ACCOUNT_SETTINGS,
  })

  const disconnectClioIntegration = () => {
    removeIntegrationItem({
      integrationName: CLIO_GROW.SHORT_NAME,
      onSubmit: () => {
        setConnectClio(false)
        setClioRemoveStatus(true)
      },
    })
  }

  const shouldRenderLoadingButton =
    removeClioIsLoading ||
    loading ||
    isClioIntegrationLoading ||
    isConnectingToLcms

  const renderMainAction = shouldRenderLoadingButton ? (
    <Button
      disabled
      sx={{
        [theme.breakpoints.up('md')]: {
          width: '200px',
        },
      }}
      variant="outlined"
      size="large"
    >
      Loading...
    </Button>
  ) : (
    <Button
      sx={{
        [theme.breakpoints.up('md')]: {
          width: '200px',
        },
      }}
      size="large"
      variant={isCurrentUserConnectedToClio ? 'outlined' : 'contained'}
      onClick={
        isCurrentUserConnectedToClio
          ? disconnectClioIntegration
          : connectClioIntegration
      }
    >
      {isCurrentUserConnectedToClio ? 'Disconnect' : 'Connect'}
    </Button>
  )

  // Navigate to the current window location path clearing the query params provided,
  // replacing the current entry in the browser's history.
  useEffect(() => {
    if (window.location.search !== '') {
      navigate(window.location.pathname, { replace: true })
    }
  }, [navigate])

  useEffect(() => {
    if (codeQueryParam && organizationId !== '' && srcQueryParam === clioName) {
      setConnectClio(true)
    }
  }, [clioName, codeQueryParam, organizationId, srcQueryParam])

  // Monitors the LCMs setup process.
  useEffect(() => {
    if (
      isSetupSuccessful(connectClioIntegrationData) &&
      !clioRemoveStatus &&
      !isCurrentUserConnectedToClio
    ) {
      connectingToLcms(false)
      setConnectClio(false)
      bootParamsService.remove(ParameterName.CLIO_CODE)
      bootParamsService.remove(ParameterName.SRC)
      refetchLcmsData()
    }
  }, [
    connectClioIntegrationData,
    isCurrentUserConnectedToClio,
    clioRemoveStatus,
    refetchLcmsData,
    connectingToLcms,
  ])

  // Handles errors during Clio integration setup or removal process
  // Check if the current organization is not connected to other LCMs
  useEffect(() => {
    if (
      connectClioIntegrationData?.statusCode === 400 ||
      removeClioData?.statusCode === 400
    ) {
      clioShowAlert({
        severity: 'error',
        message: 'An error occurred, please try again in a few minutes',
      })
      connectingToLcms(false)
      setConnectClio(false)
      bootParamsService.remove(ParameterName.CLIO_CODE)
      bootParamsService.remove(ParameterName.SRC)
    }
  }, [
    clioShowAlert,
    connectingToLcms,
    connectClioIntegrationData?.statusCode,
    removeClioData?.statusCode,
  ])

  // Handles disconnection
  useEffect(() => {
    if (removeClioData?.message === 'deleted') {
      setClioRemoveStatus(false)
      refetchLcmsData()
    }
  }, [refetchLcmsData, removeClioData?.message])

  return (
    <Card
      sx={{
        margin: theme.spacing(0, 0, 4),
        padding: theme.spacing(2),
        boxShadow: 2,
      }}
    >
      <IntegrationItemCardContent
        actions={renderMainAction}
        icon={icon}
        name={`${isCurrentUserConnectedToClio ? 'Connected to ' : ''}${name}`}
        subtitle={subtitle}
      />
      <JSnackBar
        vertical="bottom"
        open={clioAlert.open}
        severity={clioAlert.severity}
        message={clioAlert.message}
        handleClose={clioHandleClose}
      />
    </Card>
  )
}

export default ClioNonAdminIntegrationItem

import Box from '@mui/material/Box'
import MuiListItem, { ListItemProps } from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import JAvatar from '@/components/atoms/JAvatar'
import { getImagePath } from '@/utils'
import { TAccessRequests, TUpdateRequests } from './hooks/useAccessRequests'
import { IMAGE_SIZES } from '@/constants'

export interface ListRequestAccessItemProps extends ListItemProps {
  hasAccess?: boolean
  onGiveAccess?: (params: TUpdateRequests) => void
  onDenyAccess: (params: TUpdateRequests) => void
  request: TAccessRequests
  loading?: boolean
}

const ListRequestAccessItem = ({
  hasAccess,
  onGiveAccess,
  onDenyAccess,
  request,
  loading,
  sx,
}: ListRequestAccessItemProps) => {
  const { fileAccessId, firstName, lastName, isVetted, orgName } = request
  const avatarId = request?.avatarId || ''

  return (
    <MuiListItem
      sx={[
        theme => ({
          justifyContent: 'space-between',
          borderRadius: 1,
          p: 2,
          mt: 1,
          backgroundColor: theme.palette.background.paper,
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'flex-start', md: 'center' },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mr: 2,
        }}
      >
        <JAvatar
          key={request.id}
          src={getImagePath({ id: avatarId, size: IMAGE_SIZES.Small })}
          alt={firstName || ''}
          isVetted={isVetted}
        />
        <Typography variant="subtitle1" mr={1}>
          {firstName} {lastName}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: 300,
          }}
        >
          {orgName}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 1, mt: { xs: 1, md: 0 } }}>
        {!hasAccess && (
          <Button
            variant="outlined"
            onClick={() =>
              onGiveAccess?.({
                fileAccessId,
                targetUserName: `${firstName} ${lastName}`,
              })
            }
            size="small"
            disabled={loading}
          >
            Give Access
          </Button>
        )}
        <Button
          variant="text"
          color="error"
          onClick={() =>
            onDenyAccess({
              fileAccessId,
              targetUserName: `${firstName} ${lastName}`,
              hasAccess: !!hasAccess,
            })
          }
          size="small"
          disabled={loading}
        >
          {!hasAccess ? 'Deny Access' : 'Remove Access'}
        </Button>
      </Box>
    </MuiListItem>
  )
}

export default ListRequestAccessItem

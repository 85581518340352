import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'

interface ErrorMessageProps {
  detail?: string
}

const ErrorMessage = ({ detail }: ErrorMessageProps) => (
  <Alert
    severity="error"
    variant="outlined"
    sx={{
      alignItems: 'flex-start',
    }}
  >
    <AlertTitle>
      <Typography variant="subtitle1">
        An error occurred while trying to access the Dashboard.
      </Typography>
    </AlertTitle>
    <Typography variant="body2">Please try again later.</Typography>
    {detail ? (
      <Typography variant="body2">
        <code>{detail}</code>
      </Typography>
    ) : null}
  </Alert>
)

export default ErrorMessage

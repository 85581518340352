import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import useAttorneyId from '@/hooks/useAttorneyId'
import { USER_ADMINS_ROLES, USER_ATTORNEY_ROLES } from '@/constants'
import {
  Catalog_Communication_Preference,
  useCatalogCommunicationPreferenceQuery,
  useGetCommunicationPreferencesQuery,
  useUpdateAttorneyProfileItemMutation,
} from '@/gql/appApi'
import { useSnackBar } from '@/hooks/useSnackBar'
import {
  DEFAULT_COMM_PREFERENCES,
  NOTIFICATION_METHODS,
  TCommunicationPreferencesFormValues,
} from '../types'

export const communicationPreferencesDefaultValues: TCommunicationPreferencesFormValues =
  {
    casesToNotify: '',
    notificationMethod: NOTIFICATION_METHODS.EMAIL_AND_SMS,
    isNotificationsEnabled: true,
  }

const catalogNotificationMethods = [
  { id: NOTIFICATION_METHODS.EMAIL_AND_SMS, name: 'Email and SMS' },
  { id: NOTIFICATION_METHODS.EMAIL_ONLY, name: 'Email only' },
  { id: NOTIFICATION_METHODS.SMS_ONLY, name: 'SMS only' },
]

const useCommunicationPreferences = (
  initialCommPreferences?: TCommunicationPreferencesFormValues
) => {
  const { alert, showAlert, handleClose } = useSnackBar()
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = getAttorneyId()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isProcessingInitialValues, setIsProcessingInitialValues] =
    useState(false)
  const [communicationPreferenceId, setCommunicationPreferenceId] = useState('')
  const [updateAttorneyProfileId, setUpdateAttorneyProfileId] = useState('')
  const [casesToNotifyOptions, setCasesToNotifyOptions] = useState<
    Catalog_Communication_Preference[]
  >([])

  const {
    data: casesToNotifyCatalog,
    loading: loadingCommunicationPreferences,
  } = useCatalogCommunicationPreferenceQuery()

  const {
    data: commPreferencesData,
    loading: loadingCommPreferences,
    refetch: refetchCommPreferences,
  } = useGetCommunicationPreferencesQuery({
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        attorney_id: {
          id: {
            _eq: attorneyId,
          },
        },
      },
    },
    skip: !attorneyId,
  })

  const [updateAttorneyProfile, { loading: updateAttorneyProfileLoading }] =
    useUpdateAttorneyProfileItemMutation()

  const { control, handleSubmit, setValue, formState, watch, reset } =
    useForm<TCommunicationPreferencesFormValues>({
      mode: 'onBlur',
      reValidateMode: 'onChange',
      defaultValues:
        initialCommPreferences || communicationPreferencesDefaultValues,
    })

  const isNotificationsEnabled = watch('isNotificationsEnabled')

  useEffect(() => {
    if (
      casesToNotifyCatalog?.catalog_communication_preference?.length &&
      commPreferencesData?.attorney_profile?.[0]?.attorney_id?.user_id?.role
        ?.id &&
      commPreferencesData?.attorney_profile?.[0]?.id
    ) {
      setIsProcessingInitialValues(true)
      const preferencesData =
        commPreferencesData?.attorney_profile?.[0]
          ?.communication_preference?.[0]
      const roleId =
        commPreferencesData.attorney_profile[0].attorney_id.user_id.role.id
      const isAttorney = USER_ATTORNEY_ROLES.includes(roleId || '')
      const isAdmin = USER_ADMINS_ROLES.includes(roleId || '')
      const isAdminAttorney = isAdmin && isAttorney
      let notificationMethod: string = NOTIFICATION_METHODS.EMAIL_AND_SMS
      let casesToNotify: string =
        isAttorney || isAdminAttorney
          ? DEFAULT_COMM_PREFERENCES.Attorney
          : DEFAULT_COMM_PREFERENCES.CaseManager

      if (initialCommPreferences) {
        setValue(
          'casesToNotify',
          initialCommPreferences.casesToNotify || casesToNotify
        )
        setCasesToNotifyOptions([
          ...(casesToNotifyCatalog.catalog_communication_preference || []),
        ])
        setIsProcessingInitialValues(false)
        return
      }

      if (
        preferencesData?.is_email_enabled &&
        !preferencesData?.is_sms_enabled
      ) {
        notificationMethod = NOTIFICATION_METHODS.EMAIL_ONLY
      }

      if (
        !preferencesData?.is_email_enabled &&
        preferencesData?.is_sms_enabled
      ) {
        notificationMethod = NOTIFICATION_METHODS.SMS_ONLY
      }

      if (preferencesData?.notification_level?.id) {
        casesToNotify = preferencesData.notification_level.id
      }

      setCommunicationPreferenceId(preferencesData?.id || '')
      setUpdateAttorneyProfileId(commPreferencesData.attorney_profile[0].id)

      setValue('casesToNotify', casesToNotify)
      setValue('notificationMethod', notificationMethod)
      setValue(
        'isNotificationsEnabled',
        !!preferencesData?.is_notification_enabled
      )

      setCasesToNotifyOptions([
        ...(casesToNotifyCatalog.catalog_communication_preference || []),
      ])
      setIsProcessingInitialValues(false)
      return
    }
    // Default values set for the Case Manager account creation flow.
    // When creating a Case Manager account, the user does not have a token, which returns certain options based on role,
    // so for now we are manually filtering the catalog_communication_preference to only show the Case Manager options.
    setCasesToNotifyOptions(
      casesToNotifyCatalog?.catalog_communication_preference?.filter(
        preference => preference?.id === DEFAULT_COMM_PREFERENCES.CaseManager
      ) || []
    )
    setValue('casesToNotify', DEFAULT_COMM_PREFERENCES.CaseManager || '')
  }, [
    casesToNotifyCatalog,
    commPreferencesData,
    setValue,
    initialCommPreferences,
  ])

  const updatePreferences = async (
    data: TCommunicationPreferencesFormValues
  ) => {
    setIsSubmitting(true)
    const isPreferencesEnabled = data.isNotificationsEnabled
    const updateData = {
      communication_preference: [
        {
          id: communicationPreferenceId,
          is_email_enabled:
            data.notificationMethod === NOTIFICATION_METHODS.EMAIL_AND_SMS ||
            data.notificationMethod === NOTIFICATION_METHODS.EMAIL_ONLY,
          is_sms_enabled:
            data.notificationMethod === NOTIFICATION_METHODS.EMAIL_AND_SMS ||
            data.notificationMethod === NOTIFICATION_METHODS.SMS_ONLY,
          is_notification_enabled: isPreferencesEnabled,
          ...(isPreferencesEnabled && {
            notification_level: data.casesToNotify,
          }),
        },
      ],
      // FIXME: Remove the 'any' type once the schema is updated to include communication_preference
      // in the updateAttorneyProfile mutation
    } as any
    try {
      const response = await updateAttorneyProfile({
        variables: {
          updateAttorneyProfileItemId: updateAttorneyProfileId,
          data: updateData,
        },
      })

      if (response.errors) {
        throw new Error('Failed to update communication preferences')
      }

      await refetchCommPreferences()
      reset({
        ...data,
      })
      showAlert({
        severity: 'success',
        message: 'Communication preferences updated successfully',
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      showAlert({
        severity: 'error',
        message: 'Failed to update communication preferences',
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const loading =
    loadingCommunicationPreferences ||
    updateAttorneyProfileLoading ||
    loadingCommPreferences ||
    isProcessingInitialValues ||
    isSubmitting

  return {
    control,
    formState,
    handleSubmit,
    isNotificationsEnabled,
    updatePreferences,
    casesToNotifyOptions,
    notificationMethodOptions: catalogNotificationMethods,
    loading,
    alert,
    handleClose,
  }
}

export default useCommunicationPreferences

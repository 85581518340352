import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const MyCaseIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z"
        fill="url(#paint0_radial_32211_296740)"
      />
      <g clipPath="url(#clip0_32211_296740)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6416 9.80588L19.6567 10.6118L20.6808 11.1115C21.2441 11.3861 22.3237 11.9148 23.0794 12.2865C23.8351 12.6582 25.2785 13.3629 26.2865 13.8533C27.2944 14.3438 28.3859 14.8781 28.712 15.0407C29.0381 15.2033 30.0083 15.6793 30.868 16.098C31.7278 16.5172 32.7282 17.0092 33.0915 17.192L33.7517 17.5239L33.7652 26.3545L33.7787 35.1851L33.9673 35.2831C34.7139 35.6719 35.2561 35.9403 35.3574 35.9708L35.4766 36.0069V26.3813C35.4766 18.7423 35.4625 16.7454 35.4092 16.7056C35.344 16.6571 34.1975 16.0929 30.868 14.4713C29.9787 14.0382 28.8748 13.4976 28.4156 13.27C27.9563 13.0428 26.9613 12.5555 26.2056 12.1874C25.4499 11.8193 24.4673 11.3376 24.0227 11.1172C22.4558 10.3407 19.6944 9 19.661 9C19.6421 9 19.6335 9.36293 19.6421 9.80588H19.6416Z"
          fill="#6C73F8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3542 11.3039L16.3692 12.1087L17.4473 12.6358C18.0402 12.9259 19.2772 13.5305 20.1962 13.9801C21.1152 14.4298 22.328 15.024 22.8912 15.3012C24.9643 16.3219 25.6838 16.6745 27.7153 17.6652C28.8564 18.2217 29.9361 18.7514 30.1139 18.8422L30.4373 19.0069L30.4508 27.8267C30.4584 32.6774 30.4702 36.6583 30.4778 36.6727C30.4853 36.6872 30.7823 36.8426 31.138 37.0176C31.4938 37.1926 31.856 37.3717 31.9428 37.4151C32.0296 37.4585 32.1201 37.4822 32.1444 37.4683C32.1686 37.4543 32.1886 33.1245 32.1891 27.8473L32.1902 18.2522L32.0145 18.1505C31.918 18.0947 31.0868 17.6827 30.1678 17.2357C29.2488 16.7886 28.1573 16.2548 27.7423 16.0493C27.3273 15.8438 26.0902 15.2398 24.9934 14.7065C23.8965 14.1732 22.7322 13.6038 22.4061 13.4417C20.1622 12.325 16.4188 10.499 16.373 10.499C16.3547 10.499 16.3466 10.8614 16.3547 11.3039H16.3542Z"
          fill="#6C73F8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 21.6151V31.2361L14.6305 32.0388C15.5274 32.4802 16.7946 33.1023 17.4468 33.4203C18.099 33.7384 19.1544 34.2546 19.7915 34.567C21.5718 35.4394 23.1915 36.2324 24.6426 36.9423C25.3691 37.2974 26.5857 37.8932 27.3462 38.2659C28.1068 38.6387 28.7557 38.9433 28.7881 38.9433C28.8301 38.9433 28.843 36.2314 28.8333 29.312L28.8199 19.6807L27.3106 18.945C24.4447 17.548 22.3491 16.5233 21.5972 16.1505C21.1821 15.9445 20.1273 15.4278 19.2525 15.0013C16.7375 13.7752 15.6487 13.2419 14.3427 12.5966C13.6727 12.2647 13.0965 11.9941 13.062 11.9941C13.0151 11.9941 13 14.3725 13 21.6151Z"
          fill="#6C73F8"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_32211_296740"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24 24) rotate(90) scale(87 110.25)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#C1C1C1" />
        </radialGradient>
        <clipPath id="clip0_32211_296740">
          <rect
            width="22"
            height="30"
            fill="white"
            transform="translate(13 9)"
          />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
)

export default MyCaseIcon

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer, {
  TableContainerProps,
} from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import AnimatedNumber, { integerFormatter } from '@/components/AnimatedNumber'
import useTheme from '@mui/material/styles/useTheme'
import { useMediaQuery } from '@mui/material'
import { theme as themeDefinition } from '@/styles/theme'
import { animationDelay, animationDuration } from '../constants'

const avatarSize = 28
const sm = themeDefinition.breakpoints.up('sm')

interface TopAttorney {
  imageUrl: string
  name: string
  inboundReferrals: number
  outboundReferrals: number
}

export interface TopAttorneysProps extends TableContainerProps {
  data: TopAttorney[]
}

const TopAttorneys = ({ data, sx, ...rest }: TopAttorneysProps) => {
  const theme = useTheme()

  const isLargeScreen = useMediaQuery(sm)

  return (
    <TableContainer
      component={Paper}
      sx={[
        { px: 1.5, pt: 1, pb: 1.5, 'td, th': { py: 1.5, border: 0 } },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1">Top Attorneys</Typography>
            </TableCell>
            {isLargeScreen ? (
              <>
                <TableCell align="right">
                  <Typography
                    variant="caption"
                    color={theme.palette.text.secondary}
                  >
                    Inbound
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="caption"
                    color={theme.palette.text.secondary}
                  >
                    Outbound
                  </Typography>
                </TableCell>
              </>
            ) : null}
            <TableCell align="right">
              <Typography
                variant="caption"
                sx={{ fontWeight: theme.typography.fontWeightBold }}
              >
                {isLargeScreen ? 'Total' : 'Total Cases'}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(
            ({ imageUrl, name, inboundReferrals, outboundReferrals }) => (
              <TableRow
                key={`${imageUrl}_${name}_${inboundReferrals}_${outboundReferrals}`}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    src={imageUrl}
                    alt={name}
                    sx={{ width: avatarSize, height: avatarSize }}
                  />
                  <Typography pl={1.5}>{name}</Typography>
                </TableCell>
                {isLargeScreen ? (
                  <>
                    <TableCell
                      align="right"
                      sx={{ color: theme.palette.text.secondary }}
                    >
                      <AnimatedNumber
                        formatter={integerFormatter}
                        startingNumber={0}
                        delay={animationDelay}
                        duration={animationDuration}
                      >
                        {inboundReferrals}
                      </AnimatedNumber>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: theme.palette.text.secondary,
                      }}
                    >
                      <AnimatedNumber
                        formatter={integerFormatter}
                        startingNumber={0}
                        delay={animationDelay}
                        duration={animationDuration}
                      >
                        {outboundReferrals}
                      </AnimatedNumber>
                    </TableCell>
                  </>
                ) : null}
                <TableCell
                  align="right"
                  sx={{
                    fontWeight: theme.typography.fontWeightBold,
                  }}
                >
                  <AnimatedNumber
                    formatter={integerFormatter}
                    startingNumber={0}
                    delay={animationDelay}
                    duration={animationDuration}
                  >
                    {inboundReferrals + outboundReferrals}
                  </AnimatedNumber>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TopAttorneys

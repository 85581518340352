import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import useMediaQuery from '@mui/material/useMediaQuery'
import { FC, ReactNode, useState } from 'react'

import { theme } from '@/styles/theme'

const md = theme.breakpoints.up('md')

interface ReadMoreProps {
  children: ReactNode
  unexpandedValue?: ReactNode
}

const ReadMore: FC<ReadMoreProps> = ({ children, unexpandedValue }) => {
  const [expanded, setExpanded] = useState(false)

  const handleChange = () => {
    setExpanded(prev => !prev)
  }
  const isLargeScreen = useMediaQuery(md)
  const showFullContent = expanded || isLargeScreen

  // If typeof unexpandedValue && children is string and their length is the same,
  // don't show the expand button, since all the content is already visible.
  const showExpandButton =
    !isLargeScreen &&
    (typeof unexpandedValue !== 'string' ||
      typeof children !== 'string' ||
      (typeof unexpandedValue === 'string' &&
        typeof children === 'string' &&
        unexpandedValue.length !== children.length))

  return (
    <Box>
      {!showFullContent && <Box>{unexpandedValue}...</Box>}
      <Collapse in={showFullContent}>{children}</Collapse>

      {showExpandButton && (
        <Button onClick={handleChange}>
          {expanded ? 'Read less.' : 'Read more...'}
        </Button>
      )}
    </Box>
  )
}

export default ReadMore

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react'
import { TCase } from '@/pages/Case/types'
import { useGetUserFileAccessRequestLazyQuery } from '@/gql/appApi'
import { ACCESS_REQUEST_STATUS } from '@/constants'
import useAttorneyId from '@/hooks/useAttorneyId'
import useGetMyRoleAndOrg from '@/hooks/useGetMyRoleAndOrg'
import { DataroomNonOwnerStatus } from '../types'

type TUseDataroomNonOwnerProps = {
  caseData: TCase
}

const useDataroomNonOwner = ({ caseData }: TUseDataroomNonOwnerProps) => {
  const { isAdminAttorney, isCaseManager, userId } = useGetMyRoleAndOrg()
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = getAttorneyId()
  const [status, setStatus] = useState<DataroomNonOwnerStatus>('LOADING')
  const [getFileRequestAccess, { loading: fileRequestAccessLoading }] =
    useGetUserFileAccessRequestLazyQuery({
      fetchPolicy: 'network-only',
    })

  const statusCheck = useCallback(async () => {
    if (!caseData?.id) {
      setStatus('ERROR')
      return
    }

    /**
     * If the user is an admin attorney or case manager and the case has files and the files have a file_id
     * that is not null, then the user should be able to access the files without requesting access. This indicates the
     * user has inherited permissions from the attorney.
     *
     * If the user is an admin attorney and the attorney has no permissions yet, that can request access for themselves,
     * continuing the get file access request flow.
     *
     */
    if (isAdminAttorney || isCaseManager) {
      if (caseData?.files?.length && Boolean(caseData?.files?.[0]?.file_id)) {
        setStatus('ACCEPTED')
        return
      }
      if (isCaseManager) {
        setStatus('CM_NO_OWNERSHIP')
        return
      }
    }

    try {
      const result = await getFileRequestAccess({
        variables: {
          filter: {
            _and: [
              {
                requested_case_id: {
                  id: {
                    _eq: caseData.id,
                  },
                },
                attorney_id: {
                  id: {
                    _eq: attorneyId,
                  },
                },
              },
            ],
          },
        },
      })
      if (result?.data?.user_file_access_request[0]?.id) {
        if (
          result?.data?.user_file_access_request[0]?.status?.id ===
          ACCESS_REQUEST_STATUS.Pending
        ) {
          setStatus('PENDING')
          return
        }

        if (
          result?.data?.user_file_access_request[0]?.status?.id ===
            ACCESS_REQUEST_STATUS.Given ||
          result?.data?.user_file_access_request[0]?.status?.id ===
            ACCESS_REQUEST_STATUS.Granted
        ) {
          setStatus('ACCEPTED')
          return
        }

        if (
          result?.data?.user_file_access_request[0]?.status?.id ===
          ACCESS_REQUEST_STATUS.Rejected
        ) {
          setStatus('DENIED')
          return
        }
      }
      setStatus('REQUEST_ACCESS')
    } catch (err: any) {
      setStatus('ERROR')
    }
  }, [
    caseData?.files,
    caseData?.id,
    getFileRequestAccess,
    isAdminAttorney,
    isCaseManager,
    attorneyId,
  ])

  useEffect(() => {
    const checkStatus = async () => {
      await statusCheck()
    }

    if (!caseData.id || !userId) {
      return
    }
    checkStatus()
  }, [caseData, statusCheck, userId])

  const handleStatusChange = (newStatus: DataroomNonOwnerStatus) => {
    setStatus(newStatus)
  }

  return {
    status,
    userId,
    handleStatusChange,
    loading: fileRequestAccessLoading,
  }
}

export default useDataroomNonOwner

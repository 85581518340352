import { createContext } from 'react'
import { CASE_STATUS_FLOW } from '@/constants'
import { useReferral } from './useReferral'

export const ReferralContext = createContext<ReturnType<typeof useReferral>>({
  caseEntry: {
    attorney: {
      attorney_id: '',
      avatar: null,
      first_name: '',
      is_vetted: false,
      last_name: '',
      membership_type: '',
      organization_id: '',
      organization_name: '',
      role: '',
      user_id: '',
    },
    attorney_id: '',
    case_number: '',
    case_purchase: [],
    collaborators: [],
    created_by_user_id: null,
    created_ts: '',
    data_room: [],
    deleted: false,
    description: '',
    fee: 0,
    financing_source: '',
    financing_source_id: '',
    following: false,
    following_count: 0,
    id: '',
    incident_date: null,
    jurisdiction: '',
    jurisdiction_id: '',
    leads: [],
    mp_visibility: '',
    name: '',
    needs_your_attention: false,
    preferred_language: null,
    proposal_due_date: null,
    status: CASE_STATUS_FLOW.Available,
    type: '',
    type_id: '',
    waterfall: [],
    case_alias: undefined,
    data_room_filename: undefined,
    data_room_filesize: undefined,
  },
  caseParams: {
    bothPartiesPaid: false,
    cardUsers: [],
    caseMpPreferenceId: '',
    casePurchaseWithPayment: undefined,
    dataroom: [],
    datePosted: '',
    fee: undefined,
    isAgreement: false,
    isCaseOwner: false,
    isFollowingOnly: false,
    isInitiatingAttorney: false,
    isPrivate: false,
    leads: [],
    negotiationAmount: 0,
    negotiations: [],
    referralType: 'proposed',
    representingAttorneyId: undefined,
    showActionsForCaseManagerWithOwnership: false,
    showDataroomRequests: false,
    showInviteToCase: false,
    showLeads: false,
  },
  counterpart: undefined,
  counterpartInitials: '',
  isBuyerUsingCaseManager: false,
  isCaseManagerWithPermission: false,
  isCaseManagerWithoutPermission: false,
  isSellerUsingCaseManager: false,
  isUserSeller: false,
  noPaymentRequired: false,
  postedAtDateTimeString: '',
  wasBuyerInvited: false,
  showUserSidePrincipal: false,
  sortedCasePurchases: [],
  statesMap: {},
  statesLoading: false,
  postedAtDate: new Date(),
  postedAtDateString: '',
})

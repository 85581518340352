import React, { useMemo } from 'react'
import ErrorMessage from '../Messagges/ErrorMessage'
import ErrorPermissionsMessage from '../Messagges/ErrorPermissionsMessage'

interface IErrorPageProps {
  message?: string
}

const ErrorPage: React.FC<IErrorPageProps> = ({ message }: IErrorPageProps) => {
  const renderErrorPage = useMemo(() => {
    if (message && message.length > 0) {
      return (
        <ErrorPermissionsMessage
          content={message || `You don’t have permissions to view Marketplace`}
        />
      )
    }
    return <ErrorMessage />
  }, [message])
  return renderErrorPage
}

export default ErrorPage

import Layout from '@/components/Layout'
import Alert from '@mui/material/Alert'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import CircleIcon from '@mui/icons-material/Circle'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import ResponseModal from '@/components/Modals/ResponseModal'
import JSnackBar from '@/components/atoms/JSnackBar'
import { styled, useTheme } from '@mui/material/styles'
import { CreateCaseContext } from '../CreateCaseContext/CreateCaseContext'
import CaseOverview from './components/CaseOverview'
import LeadInformation from './components/LeadInformation'
import CaseOwnership from './components/CaseOwnership'
import FeesAndPricing from './components/FeesAndPricing'
import ExtraInformation from './components/ExtraInformation'
import CreateCaseDataroom from './components/CreateCaseDataroom/CreateCaseDataroom'
import MarketplaceVisibility from './components/MarketplaceVisibility/MarketplaceVisibility'

const StyledLink = styled(Link)(
  () => `
  text-decoration: underline;
`
)

const CaseForm = () => {
  const theme = useTheme()
  const {
    caseError,
    caseErrorRef,
    clioAlert,
    closeClioAlert,
    createdCaseId,
    dataroomErrors,
    error,
    errorMessages,
    errorRef,
    formErrorsRef,
    isCaseSubmitted,
    isEdit,
    marketplaceVisibilityError,
    mktpVisibilityErrorRef,
    modalType,
    onCloseCaseSubmittedHandler,
    title,
  } = useContext(CreateCaseContext)

  return (
    <Layout
      sx={{
        paddingTop: theme.spacing(4),
        width: {
          md: '100%',
        },
        flex: {
          md: 1,
        },
        overflow: 'auto',
        pb: {
          xs: 20,
          md: 15,
        },
        '&::-webkit-scrollbar': { display: 'none' },
      }}
    >
      <Container>
        <Typography
          variant="h5"
          sx={{ mb: 1, textAlign: 'left' }}
          id="createNewCaseTitle"
        >
          {title}
        </Typography>
        {error?.message && (
          <Alert ref={errorRef} severity="error">
            {error.message}
          </Alert>
        )}
        {caseError && (
          <Alert ref={caseErrorRef} severity="error">
            {caseError}
          </Alert>
        )}
        {marketplaceVisibilityError && (
          <Alert ref={mktpVisibilityErrorRef} severity="error">
            {marketplaceVisibilityError}
          </Alert>
        )}
        {errorMessages.length > 0 && (
          <Alert ref={formErrorsRef} severity="error">
            Please make sure to fill out all required fields. The following
            fields are missing:{' '}
            <List>
              {errorMessages.map(errorMessage => (
                <ListItem key={errorMessage} sx={{ py: 0.5, px: 1 }}>
                  <CircleIcon sx={{ height: '5px', width: '5px', mr: 1 }} />{' '}
                  {errorMessage === 'leadInfo' ? 'Lead Info' : errorMessage}
                </ListItem>
              ))}
            </List>
          </Alert>
        )}
        <Card
          sx={{
            p: 3,
            mt: 4,
          }}
        >
          <CaseOverview />
          <LeadInformation />
          <CaseOwnership />
          <MarketplaceVisibility />
          <FeesAndPricing />
          <CreateCaseDataroom />
          <ExtraInformation />
        </Card>
        <ResponseModal
          type={modalType}
          open={isCaseSubmitted}
          noteContent={
            dataroomErrors.length > 0 ? (
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.error.main,
                }}
              >
                The following files couldn&apos;t be attached:{' '}
                <b>{dataroomErrors.join(', ')}</b>
                <br />
                {createdCaseId && (
                  <>
                    Please try again in{' '}
                    <StyledLink to={`/case/${createdCaseId}`}>
                      the case details page
                    </StyledLink>
                  </>
                )}
              </Typography>
            ) : null
          }
          onClose={() => onCloseCaseSubmittedHandler(isEdit)}
        />
      </Container>
      <JSnackBar
        open={clioAlert.open}
        severity={clioAlert.severity}
        message={clioAlert.message}
        horizontal="center"
        vertical="bottom"
        handleClose={closeClioAlert}
      />
    </Layout>
  )
}

export default CaseForm

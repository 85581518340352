import React from 'react'
import { Controller, Control } from 'react-hook-form'
import { Checkbox, CheckboxOnChangeHandler } from '@/components'
import Typography from '@mui/material/Typography'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { ICaseForm } from './types'

interface ConfidentialityCheckboxProps {
  control: Control<ICaseForm, any>
  rules: object
}

const ConfidentialityCheckbox: React.FC<ConfidentialityCheckboxProps> = ({
  control,
  rules,
}) => {
  const confidentialityLabel = (
    <Typography variant="body2" component="span">
      I have not included privileged or confidential information in the public
      Case Description *
    </Typography>
  )

  return (
    <Controller
      control={control}
      name="confidentiality"
      rules={rules}
      render={({ field: { onChange, value, ...field }, fieldState }) => (
        <Checkbox
          {...field}
          checked={value}
          sx={theme => ({ pr: { xs: theme.spacing(1) } })}
          checkboxProps={{
            error: fieldState.invalid,
            helperText: fieldState.error?.message,
            label: confidentialityLabel,
            isCentered: true,
          }}
          onChange={onChange as CheckboxOnChangeHandler}
          muiCheckboxProps={{
            checkedIcon: <CheckBoxIcon color="primary" />,
          }}
        />
      )}
    />
  )
}

export default ConfidentialityCheckbox

import { useMemo } from 'react'
import CreateProfile from '../CreateProfile/CreateProfile'
import CreateOrganizationProfile from '../CreateProfile/CreateOrganizationProfile/CreateOrganizationProfile'
import { useVerification } from './hooks/useVerification'
import VerificationSuccess from './components/VerificationSuccess'
import InvalidToken from './components/InvalidToken'
import SignIn from '../SignIn/SignIn'

const Verification = () => {
  const {
    flow,
    handleContinue,
    onSignInSuccess,
    licensedAccount,
    loading,
    email,
  } = useVerification()

  const renderComponent = useMemo(() => {
    switch (flow) {
      case 'SUCCESS':
        return (
          <VerificationSuccess
            onGetMembership={handleContinue}
            licensedAccount={licensedAccount}
            loading={loading}
          />
        )
      case 'LOGIN':
        return (
          <SignIn
            onSignInSuccess={onSignInSuccess}
            loading={loading}
            verificationEmail={email}
          />
        )
      case 'INDIVIDUAL':
        return <CreateProfile />
      case 'ORGANIZATION':
        return <CreateOrganizationProfile hasLicense />
      case 'ORGANIZATION_NO_LICENSE':
        return <CreateOrganizationProfile hasLicense={false} />
      case 'INVALID_TOKEN':
        return <InvalidToken />
      case 'EXPIRED_TOKEN':
        return <InvalidToken isExpired />
      default:
        return <div>Flow not found</div>
    }
  }, [flow, handleContinue, loading, onSignInSuccess, licensedAccount, email])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{renderComponent}</>
}

export default Verification

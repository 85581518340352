import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'

const ErrorAccess = () => (
  <Alert
    severity="error"
    variant="outlined"
    sx={{
      alignItems: 'flex-start',
    }}
  >
    <AlertTitle>
      <Typography variant="subtitle1">
        An error occurred while trying to access Data Room.
      </Typography>
    </AlertTitle>
    <Typography variant="body2">
      An error occurred while trying to verify your access to the Data Room.
      Please try again later.
    </Typography>
  </Alert>
)

export default ErrorAccess

import { useEffect, useRef, useState } from 'react'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { Attorney_Profile, useAttorneyNameAndOrgLazyQuery } from '@/gql/appApi'
import { USER_ROLES } from '@/constants'
import useOrganizationId from '@/hooks/organization/useOrganizationId'
import useAttorneyId from '@/hooks/useAttorneyId'
import { useDebounceValue } from 'usehooks-ts'

type TUseSearchCaseManagersProps = {
  attorneyValue: Attorney_Profile[]
  attorneysSelected?: Attorney_Profile[]
}

const SearchCaseManagers = ({
  attorneyValue,
  attorneysSelected = [],
}: TUseSearchCaseManagersProps) => {
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = useRef(getAttorneyId())
  const [searchTerm, setSearchTerm] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [attorneyProfile, setAttorney] =
    useState<Attorney_Profile[]>(attorneyValue)
  const [options, setOptions] = useState<Attorney_Profile[]>([])
  const [filteredOptions, setFilteredOptions] = useState<Attorney_Profile[]>([])
  const { id: orgId } = useOrganizationId()

  const [getAttorneyById, { data, loading }] = useAttorneyNameAndOrgLazyQuery()

  const [debouncedSearchText] = useDebounceValue(inputValue, 500)

  useEffect(() => {
    setSearchTerm(debouncedSearchText)
  }, [debouncedSearchText])

  useEffect(() => {
    if (orgId) {
      getAttorneyById({
        variables: {
          search: searchTerm.toLowerCase(),
          sort: 'first_name',
          filter: {
            _and: [
              {
                attorney_id: {
                  user_id: {
                    current_organization: {
                      organization_id: {
                        id: {
                          _eq: orgId,
                        },
                      },
                    },
                  },
                },
              },
              {
                attorney_id: {
                  user_id: {
                    role: {
                      id: {
                        _eq: USER_ROLES.CaseManagerUser,
                      },
                    },
                  },
                },
              },
              {
                attorney_id: {
                  id: {
                    _neq: attorneyId.current,
                  },
                },
              },
            ],
          },
        },
      })
    }
  }, [orgId, getAttorneyById, searchTerm])

  useEffect(() => {
    if (data) {
      const newOptions: Attorney_Profile[] = []
      data.attorney_profile?.forEach(attorney => {
        if (attorney && attorney.first_name !== 'undefined') {
          // eslint-disable-next-line
          // @ts-expect-error
          newOptions.push(attorney)
        }
      })
      setOptions(newOptions)
      setFilteredOptions(newOptions)
    }
  }, [data])

  useEffect(() => {
    const newFilteredOptions = options.filter(
      option => !attorneysSelected.find(selected => selected.id === option.id)
    )
    setFilteredOptions(newFilteredOptions)
  }, [attorneysSelected, options])

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: Attorney_Profile) =>
      `${option.first_name} ${option.last_name}`,
  })

  return {
    attorneyProfile,
    setAttorney,
    options,
    filteredOptions,
    setFilteredOptions,
    searchTerm,
    loading,
    filterOptions,
    inputValue,
    setInputValue,
  }
}

export default SearchCaseManagers

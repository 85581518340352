export type TCreateProfileEducationForm = {
  lawSchool: string
  startYearLaw: string
  endYearLaw: string
  undergradSchool: string
  startYearGrad: string
  endYearGrad: string
}

export const educationDefaultValues: TCreateProfileEducationForm = {
  lawSchool: '',
  startYearLaw: '',
  endYearLaw: '',
  undergradSchool: '',
  startYearGrad: '',
  endYearGrad: '',
}

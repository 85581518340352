import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Catalog_Us_State } from '@/gql/appApi'

interface UseSelectJurisdictionProps {
  fieldName: string
  validJurisdictions: Catalog_Us_State[]
}

const useSelectJurisdiction = ({
  fieldName,
  validJurisdictions,
}: UseSelectJurisdictionProps) => {
  const { setValue, watch } = useFormContext()
  const [selectedJurisdiction, setSelectedJurisdiction] =
    useState<Catalog_Us_State | null>(null)

  const handleSelection = (
    _event: React.SyntheticEvent,
    newValue: Catalog_Us_State | null
  ) => {
    setSelectedJurisdiction(newValue)
    setValue(fieldName, newValue?.id || null)
  }

  // Map values coming from the server to the selectedJurisdiction
  const selectedJurisdictionId = watch(fieldName)
  useEffect(() => {
    if (selectedJurisdictionId) {
      const jurisdiction = validJurisdictions.find(
        j => j.id === selectedJurisdictionId
      )
      if (jurisdiction) {
        setSelectedJurisdiction(jurisdiction)
      }
    }
  }, [selectedJurisdictionId, validJurisdictions])

  return {
    selectedJurisdiction,
    handleSelection,
  }
}

export default useSelectJurisdiction

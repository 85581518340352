/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react'
import {
  AttorneyDocument,
  useUpdateOrganizationAndProfileMutation,
  useUpdateOrganizationUserMutation,
} from '@/gql/appApi'
import { useUsersMeOrganizationLazyQuery } from '@/gql/systemApi'
import { CONTACT_TYPES } from '@/constants'
import { Dayjs } from 'dayjs'
import {
  SessionKey,
  sessionStorageService,
} from '@/services/SessionStorage/SessionStorageService'

type TUpdateOrganizationLicenseInput = {
  barNumber: string
  isDisciplined: boolean
  isSued: boolean
  isActive: boolean
  stateId: string
  malpracticeInsuranceId: string
  insuranceExpirationDate: string | Dayjs | null
}

type TUpdateOrganizationInput = {
  // Organization profile
  organizationProfileId: string
  address: string
  caseManagementSoftwareId: string
  city: string
  companySizeId: string
  description: string
  lawFirmName: string
  lawFirmStateId: string
  zipCode: string
  websiteUrl?: string
  // Attorney profile
  profileId: string
  phoneNumber: string
  licenses: TUpdateOrganizationLicenseInput[]
}

type TUpdateOrganizationUserInput = {
  organizationUserItemId: string
  positionName: string
  positionBio?: string
}

const useOrganization = () => {
  const [updateOrgAndProfileMutation, { loading: updateOrgAndProfileLoading }] =
    useUpdateOrganizationAndProfileMutation()

  const [
    updateOrganizationUserMutation,
    { loading: updateOrganizationUserLoading },
  ] = useUpdateOrganizationUserMutation()

  const [
    getMyOrganizationData,
    { loading: getMyOrganizationDataLoading, data: organizationData },
  ] = useUsersMeOrganizationLazyQuery()

  const loading =
    updateOrgAndProfileLoading ||
    getMyOrganizationDataLoading ||
    updateOrganizationUserLoading

  const getOrganizationData = useCallback(async () => {
    const token = sessionStorageService.getItem(SessionKey.TOKEN)
    if (token) {
      try {
        const { data, error } = await getMyOrganizationData()
        if (
          data?.users_me?.current_organization?.[0]?.organization_id
            ?.profiles?.[0]?.id &&
          data?.users_me?.current_organization?.[0]?.id
        ) {
          return {
            organizationProfileId:
              data.users_me.current_organization[0].organization_id.profiles[0]
                .id,
            organizationUserItemId: data.users_me.current_organization[0].id,
            organizationUserId:
              data.users_me.current_organization[0].organization_id.id,
          }
        }
        return {
          error: error?.message || 'No organization data found',
        }
      } catch (e: any) {
        return {
          error: e?.message || 'No organization data found',
        }
      }
    }
    return { error: 'No token found to query organization data' }
  }, [getMyOrganizationData])

  const updateOrganizationAndProfile = useCallback(
    async (organizationUpdateData: TUpdateOrganizationInput) => {
      const options = {
        licenses: organizationUpdateData.licenses.map(license => ({
          bar_number: license.barNumber,
          has_been_disciplined_by_bar: license.isDisciplined,
          has_been_sued_by_malpractice: license.isSued,
          is_license_suspended: !license.isActive,
          license_state: license.stateId,
          insurances: [
            {
              insurance_coverage_id: license.malpracticeInsuranceId,
              expiration_date: license.insuranceExpirationDate,
            },
          ],
        })),
      }

      try {
        const { data, errors } = await updateOrgAndProfileMutation({
          variables: {
            updateOrganizationProfileItemId:
              organizationUpdateData.organizationProfileId,
            organizationProfileData: {
              address: organizationUpdateData.address,
              case_management_software:
                organizationUpdateData.caseManagementSoftwareId,
              city: organizationUpdateData.city,
              company_size: organizationUpdateData.companySizeId,
              description: organizationUpdateData.description,
              name: organizationUpdateData.lawFirmName,
              state: organizationUpdateData.lawFirmStateId,
              zipcode: organizationUpdateData.zipCode,
              website_url: organizationUpdateData?.websiteUrl || null,
            },
            updateAttorneyProfileItemId: organizationUpdateData.profileId,
            updateProfileData: {
              ...options,
              contacts: [
                {
                  type: CONTACT_TYPES.PhoneContactType,
                  value: organizationUpdateData.phoneNumber,
                },
              ],
            },
          },
        })
        if (
          data?.update_organization_profile_item?.id &&
          data?.update_attorney_profile_item?.id
        ) {
          return {
            organizationProfileId: data.update_organization_profile_item.id,
            updateProfileId: data.update_attorney_profile_item.id,
          }
        }
        if (errors) {
          return {
            updateOrganizationErrors: {
              base: 'Organization update failed. Please try again.',
              server: errors[0]?.message,
            },
          }
        }
        return {}
      } catch (e: any) {
        return {
          updateOrganizationErrors: {
            base: 'Organization update failed. Please try again.',
            server: e?.message,
          },
        }
      }
    },
    [updateOrgAndProfileMutation]
  )

  const updateOrganizationUser = useCallback(
    async (organizationUserData: TUpdateOrganizationUserInput) => {
      try {
        const { data, errors } = await updateOrganizationUserMutation({
          variables: {
            updateOrganizationUserItemId:
              organizationUserData.organizationUserItemId,
            data: {
              position_name: organizationUserData.positionName,
              ...(organizationUserData.positionBio && {
                position_bio: organizationUserData.positionBio,
              }),
            },
          },
          refetchQueries: [AttorneyDocument, 'attorney'],
        })
        if (data?.update_organization_user_item?.id) {
          return {
            organizationUserItemId: data.update_organization_user_item.id,
          }
        }
        if (errors) {
          return {
            updateOrganizationUserErrors: {
              base: 'Organization update failed. Please try again.',
              server: errors[0]?.message,
            },
          }
        }
        return {}
      } catch (e: any) {
        return {
          updateOrganizationUserErrors: {
            base: 'Organization update failed. Please try again.',
            server: e?.message,
          },
        }
      }
    },
    [updateOrganizationUserMutation]
  )

  return {
    getOrganizationData,
    updateOrganizationAndProfile,
    updateOrganizationUser,
    loading,
    organizationData,
  }
}

export default useOrganization

import useTheme from '@mui/material/styles/useTheme'
import Typography from '@mui/material/Typography'
import AnimatedNumber, { integerFormatter } from '@/components/AnimatedNumber'
import {
  animationDelay,
  animationDuration,
} from '@/components/Metrics/constants'

interface JChipLabelProps {
  labelText: string
  numReferrals: number
  selected: boolean
}
export const JChipLabel = ({
  labelText,
  numReferrals,
  selected,
}: JChipLabelProps) => {
  const theme = useTheme()

  return (
    <Typography
      sx={{
        lineHeight: 0,
      }}
    >
      {labelText}
      <Typography
        component="span"
        variant="caption"
        sx={{
          borderRadius: theme.shape.borderRadius,
          px: 0.5,
          ml: 1,
          ...(selected
            ? {
                color: theme.palette.common.black,
                background: theme.palette.secondary.main,
              }
            : {
                color: theme.palette.text.disabled,
              }),
        }}
      >
        <AnimatedNumber
          startingNumber={0}
          formatter={integerFormatter}
          delay={animationDelay}
          duration={animationDuration}
        >
          {numReferrals}
        </AnimatedNumber>
      </Typography>
    </Typography>
  )
}

import Grid, { GridProps } from '@mui/material/Grid'
import TotalReferrals, {
  TotalReferralsProps,
} from '@/components/Metrics/TotalReferrals'
import InboundReferrals, {
  InboundReferralsProps,
} from '@/components/Metrics/InboundReferrals'
import OutboundReferrals, {
  OutboundReferralsProps,
} from '@/components/Metrics/OutboundReferrals'
import TopAttorneys, {
  TopAttorneysProps,
} from '@/components/Metrics/TopAttorneys'
import TopJurisdictions, {
  TopJusrisdictionsProps,
} from '@/components/Metrics/TopJurisdictions'
import TopPracticeAreas, {
  TopPracticeAreasProps,
} from '@/components/Metrics/TopPracticeAreas'

interface AllFirmCasesProps extends GridProps {
  totalReferrals: TotalReferralsProps
  inboundReferrals: InboundReferralsProps
  outboundReferrals: OutboundReferralsProps
  topAttorneys: TopAttorneysProps
  topPracticeAreas: TopPracticeAreasProps
  topJurisdictions: TopJusrisdictionsProps
}

const AllFirmCases = ({
  totalReferrals,
  inboundReferrals,
  outboundReferrals,
  topAttorneys,
  topPracticeAreas,
  topJurisdictions,
  ...rest
}: AllFirmCasesProps) => (
  <Grid container columns={8} spacing={{ xs: 0.75, sm: 1, lg: 5 }} {...rest}>
    <Grid item xs={8} md={8}>
      <TotalReferrals {...totalReferrals} />
    </Grid>
    <Grid item xs={8} md={4}>
      <InboundReferrals sx={{ height: '100%' }} {...inboundReferrals} />
    </Grid>
    <Grid item xs={8} md={4}>
      <OutboundReferrals sx={{ height: '100%' }} {...outboundReferrals} />
    </Grid>
    <Grid item xs={8} md={4}>
      <TopAttorneys sx={{ height: '100%' }} {...topAttorneys} />
    </Grid>
    <Grid item xs={8} md={2}>
      <TopPracticeAreas sx={{ height: '100%' }} {...topPracticeAreas} />
    </Grid>
    <Grid item xs={8} md={2}>
      <TopJurisdictions sx={{ height: '100%' }} {...topJurisdictions} />
    </Grid>
  </Grid>
)

export default AllFirmCases

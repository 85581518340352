import { useCallback, useMemo } from 'react'
import CreateAccountForm from './InitialStep/CreateAccountForm'
import CreateOrganization from './CreateOrganization/CreateOrganization'
import CreateInvitedAttorney from './CreateInvitedAttorney/CreateInvitedAttorney'
import CreateInvitedAdmin from './CreateInvitedAdmin/CreateInvitedAdmin'
import useCreateAccount from './hooks/useCreateAccount'
import CreateCaseManager from './CreateCaseManager/CreateCaseManager'
import InvalidToken from '../Verification/components/InvalidToken'

const CreateAccount = () => {
  const {
    flow,
    accountType,
    setFlow,
    handleFlowNavigation,
    createAccountValues,
    handleCreateAccountForm,
    tokenData,
    loading,
  } = useCreateAccount()

  const goToInitialStep = useCallback(() => setFlow('INITIAL_STEP'), [setFlow])

  const renderComponent = useMemo(() => {
    switch (flow) {
      case 'INITIAL_STEP':
        return (
          <CreateAccountForm
            handleCreateAccountForm={handleCreateAccountForm}
            handleFlowNavigation={handleFlowNavigation}
            createAccountValues={createAccountValues}
            accountType={accountType}
            tokenData={tokenData}
            loading={loading}
          />
        )
      // Currently INDIVIDUAL and ACCOUNT TYPE flows are not in use
      // as specified in: https://attorney.atlassian.net/browse/MP-745
      case 'ORGANIZATION':
        return (
          <CreateOrganization
            goBack={() => setFlow('INITIAL_STEP')}
            createAccountValues={createAccountValues}
          />
        )
      case 'ADMIN':
        return (
          <CreateInvitedAdmin
            goBack={goToInitialStep}
            createAccountValues={createAccountValues}
            tokenData={tokenData}
          />
        )
      case 'INVITED':
        return (
          <CreateInvitedAttorney
            goBack={goToInitialStep}
            createAccountValues={createAccountValues}
            tokenData={tokenData}
          />
        )
      case 'CASE_MANAGER':
        return (
          <CreateCaseManager
            goBack={goToInitialStep}
            createAccountValues={createAccountValues}
            tokenData={tokenData}
          />
        )
      case 'INVALID_TOKEN':
        return <InvalidToken isInvalidToken />
      case 'EXPIRED_TOKEN':
        return <InvalidToken isExpired />
      default:
        return <div>Flow not found</div>
    }
  }, [
    flow,
    handleCreateAccountForm,
    handleFlowNavigation,
    createAccountValues,
    accountType,
    tokenData,
    loading,
    goToInitialStep,
    setFlow,
  ])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{renderComponent}</>
}

export default CreateAccount

import { TextFieldProps } from '@mui/material/TextField'
import {
  CreateAccountPhoneField,
  CreateAccountTextField,
} from './CreateAccountField.styles'

const CreateAccountField = ({ ref, ...props }: TextFieldProps) => {
  if (props.type === 'tel') {
    return <CreateAccountPhoneField {...props} textFieldRef={ref} />
  }

  return <CreateAccountTextField {...props} ref={ref} />
}

export default CreateAccountField

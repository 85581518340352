import { useMemo } from 'react'
import { useUserOrganizacionQuery } from '@/gql/systemApi'
import { OrganizationData } from './types'

const useOrganizationData = (): OrganizationData => {
  const { data: userMe } = useUserOrganizacionQuery()
  const organizationData = useMemo(() => {
    if (!userMe) {
      return {
        organizationId: '',
        currentOrganization: null,
      }
    }
    const currentOrganization = userMe?.users_me?.current_organization?.[0]
    const organizationId = currentOrganization?.organization_id?.id ?? ''
    return { organizationId, currentOrganization }
  }, [userMe])

  return organizationData
}

export default useOrganizationData

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react'
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'

interface DragAndDropSortableProps {
  items: any[]
  /** Value to access the unique identifier found within your items array. e.g. 'id' */
  valueKey: string
  setItems: (data: any[]) => void
  children: ReactNode
}

function move<T>(arr: T[], fromIndex: number, toIndex: number) {
  const result = arr.slice()
  const [removed] = result.splice(fromIndex, 1)
  result.splice(toIndex, 0, removed)
  return result
}

const DnDSortableContext = ({
  items,
  valueKey,
  setItems,
  children,
}: DragAndDropSortableProps) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  const handleDragEnd = (event: any) => {
    const { active, over } = event
    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex(item => item[valueKey] === active.id)
      const newIndex = items.findIndex(item => item[valueKey] === over.id)
      const reorderedItems = move(items, oldIndex, newIndex)
      setItems(reorderedItems)
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items.map(item => item[valueKey])}
        strategy={verticalListSortingStrategy}
      >
        {children}
      </SortableContext>
    </DndContext>
  )
}

export default DnDSortableContext

import React, {
  createContext,
  useState,
  ReactNode,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import { useClearRefinements, useSearchBox } from 'react-instantsearch'
import { useSearchParams } from 'react-router-dom'
import { useAlgoliaMarketplace } from '../hooks/useAlgoliaMarketplace'

import {
  showCasesInMyOrgQueryParamKey,
  showHiddenCasesQueryParamKey,
} from '../utils'

interface AlgoliaMarketplaceContextType {
  showCasesFromMyOrg: boolean
  setshowCasesFromMyOrg: (value: boolean) => void
  optionalFilters: string[]
  myOrganizationsFilters: string
  isAttorneyProfileLoading: boolean
  customFilters: string
  showHiddenCases: boolean
  setShowHiddenCases: (value: boolean) => void
  handleClearFilters: () => void
}

export const AlgoliaMarketplaceContext = createContext<
  AlgoliaMarketplaceContextType | undefined
>(undefined)

const DEFAULT_SHOW_CASES_FROM_MY_ORG = true
const DEFAULT_SHOW_HIDDEN_CASES = false

export const AlgoliaMarketplaceProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [customFilters, setCustomFilters] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const { refine: clearRefinements } = useClearRefinements()
  const { refine: refineSearch } = useSearchBox()
  const [showCasesFromMyOrg, setshowCasesFromMyOrg] = useState(() => {
    const urlValue = searchParams.get(showCasesInMyOrgQueryParamKey)

    return urlValue === null
      ? DEFAULT_SHOW_CASES_FROM_MY_ORG
      : urlValue === 'true'
  })

  const [showHiddenCases, setShowHiddenCases] = useState(() => {
    const urlValue = searchParams.get(showHiddenCasesQueryParamKey)

    return urlValue === null ? DEFAULT_SHOW_HIDDEN_CASES : urlValue === 'true'
  })

  const {
    optionalFilters,
    myOrganizationsFilters,
    isAttorneyProfileLoading,
    hiddenCasesFilters,
  } = useAlgoliaMarketplace()

  useEffect(() => {
    // NOTE: searchParams will not contain the query parameters set by
    // instantsearch, so location.search must be read and parsed directly to
    // ensure they are included when calling setSearchParams.
    const currentSearchParams = new URLSearchParams(window.location.search)
    const filters = []

    if (showCasesFromMyOrg) {
      // NOTE: This prevents the URL from storing the default state for
      // showCasesFromMyOrg
      currentSearchParams.delete(showCasesInMyOrgQueryParamKey)
    } else {
      currentSearchParams.set(
        showCasesInMyOrgQueryParamKey,
        showCasesFromMyOrg.toString()
      )
      filters.push(myOrganizationsFilters)
    }

    if (showHiddenCases) {
      currentSearchParams.set(
        showHiddenCasesQueryParamKey,
        showHiddenCases.toString()
      )
    } else {
      filters.push(hiddenCasesFilters)
      currentSearchParams.delete(showHiddenCasesQueryParamKey)
    }

    const newCustomFilter = filters.join(' AND ')
    setCustomFilters(newCustomFilter)
    setSearchParams(currentSearchParams, { replace: true })
  }, [
    showCasesFromMyOrg,
    showHiddenCases,
    setSearchParams,
    myOrganizationsFilters,
    hiddenCasesFilters,
  ])

  const handleClearFilters = useCallback(() => {
    setshowCasesFromMyOrg(DEFAULT_SHOW_CASES_FROM_MY_ORG)
    setShowHiddenCases(DEFAULT_SHOW_HIDDEN_CASES)
    setSearchParams(new URLSearchParams(), { replace: true })
    clearRefinements()
    refineSearch('')
  }, [clearRefinements, refineSearch, setSearchParams])

  const value = useMemo(
    () => ({
      showCasesFromMyOrg,
      setshowCasesFromMyOrg,
      optionalFilters,
      myOrganizationsFilters,
      isAttorneyProfileLoading,
      customFilters,
      handleClearFilters,
      showHiddenCases,
      setShowHiddenCases,
    }),
    [
      showCasesFromMyOrg,
      setshowCasesFromMyOrg,
      optionalFilters,
      myOrganizationsFilters,
      isAttorneyProfileLoading,
      customFilters,
      handleClearFilters,
      showHiddenCases,
      setShowHiddenCases,
    ]
  )

  return (
    <AlgoliaMarketplaceContext.Provider value={value}>
      {children}
    </AlgoliaMarketplaceContext.Provider>
  )
}

import { useContext } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import JAutocomplete from '@/components/atoms/JAutocomplete'
import JTextField from '@/components/atoms/JTextField'
import JDatePicker from '@/components/DatePicker'
import { TERMS_PRIVACY_LINKS } from '@/constants'
import { Controller } from 'react-hook-form'
import { Checkbox, CheckboxOnChangeHandler } from '@/components'
import { CreateCaseContext } from '../../CreateCaseContext/CreateCaseContext'
import CreateCaseSectionLayout from './CreateCaseSectionLayout'

const Anchor = styled('a')(({ theme }) => ({
  color: theme.palette.common.black,
  textDecoration: 'underline',
  ...theme.typography.body2,

  '&:hover': {
    color: theme.palette.secondary.dark,
  },
}))

const ExtraInformation = () => {
  const {
    handleSubmitCase,
    isExternalLoading,
    isSubmitLoading,
    languages,
    loading,
    methods: { control },
    submitButtonText,
    processStages,
  } = useContext(CreateCaseContext)

  const termsAndPrivacyLabel = (
    <Typography
      variant="body2"
      sx={{
        mt: 0.5,
      }}
    >
      Attorney Share does not vet or certify the lawyers to whom you may refer a
      case. I understand that it is my duty to evaluate any lawyer to whom I
      refer a matter. I further understand that it is solely my duty to ensure
      compliance with my jurisdiction&apos;s Rules of Professional Conduct and
      other ethical standards in advertising, making a referral, entering into a
      referral or co-counsel relationship, engaging in fee sharing, and
      conducting the representation. Attorney Share is a platform that
      facilitates attorneys connecting with other attorneys and does not advise
      or provide guidance on such issues. This is not a client to attorney
      referral service. By checking this box, I certify and warrant that I
      understand the above and will adhere to my duties accordingly. I agree to
      the{' '}
      <Anchor href={TERMS_PRIVACY_LINKS.terms} rel="noreferrer" target="_blank">
        Terms and Conditions
      </Anchor>{' '}
      outlined in our{' '}
      <Anchor
        href={TERMS_PRIVACY_LINKS.privacy}
        rel="noreferrer"
        target="_blank"
      >
        Privacy Policy
      </Anchor>
      .
    </Typography>
  )

  const submitButtonContent = isSubmitLoading ? (
    <CircularProgress size={16} color="inherit" />
  ) : (
    submitButtonText
  )

  return (
    <CreateCaseSectionLayout
      title="Extra Information"
      subtitle="Please fill out all relevant fields below. The more the merrier."
    >
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <JAutocomplete
            control={control}
            name="legalStage"
            options={processStages}
            label="Legal Process Stage"
            fullWidth
            fitTextField
            getOptionLabel={option => option.name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <JTextField
            name="caseNumber"
            control={control}
            label="Case Number"
            placeholder="Case Number"
            variant="outlined"
            fullWidth
            fitTextField
            InputProps={{
              inputProps: {
                maxLength: 40,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <JDatePicker
            name="incidentDate"
            control={control}
            label="Incident Date"
            disableFuture
            fitStyle
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <JAutocomplete
            control={control}
            name="language"
            options={languages}
            label="2nd Language Required"
            fullWidth
            fitTextField
            getOptionLabel={option => option.name}
          />
        </Grid>
      </Grid>
      <Controller
        control={control}
        name="terms"
        rules={{ required: 'You must agree to the Terms and Conditions' }}
        render={({ field: { onChange, value, ...field }, fieldState }) => (
          <Checkbox
            {...field}
            sx={{
              mt: 1,
            }}
            checked={value}
            checkboxProps={{
              error: fieldState.invalid,
              helperText: fieldState.error?.message,
              label: termsAndPrivacyLabel,
            }}
            onChange={onChange as CheckboxOnChangeHandler}
            muiCheckboxProps={{
              checkedIcon: <CheckBoxIcon color="primary" />,
            }}
          />
        )}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          mt: 4,
        }}
      >
        <Button
          variant="contained"
          size="xlarge"
          onClick={handleSubmitCase}
          fullWidth
          disabled={loading || isExternalLoading || isSubmitLoading}
          sx={{
            maxWidth: {
              xs: '100%',
              md: '200px',
            },
            mt: {
              xs: 2.5,
              md: 0,
            },
          }}
        >
          {submitButtonContent}
        </Button>
      </Box>
    </CreateCaseSectionLayout>
  )
}

export default ExtraInformation

import { PROCESS_STAGES } from '@/constants'
import { Case, CaseContextType } from '../types'
import { JohnDoe } from '../fixtures/users'

export const LABEL_WIDTH = '120px'
export const FILE_UPLOAD_MAX_SIZE = 100 * 1024 * 1024 // 10MB
export const APPROVED_FILE_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

export const mockCase: Case = {
  id: '1cd742ed-5c8d-48d7-9c04-8ed7d3d8d305',
  status: { id: '1', name: 'available' },
  alerts: [],
  mp_visibility: { id: '1', name: 'public' },
  created_by: JohnDoe,
  created_ts: new Date().toISOString(),
  name: 'Mock Case',
  mp_preferences: { is_watching: null, is_hidden: null },
  actions: {
    watch_case: false,
    hide_case: false,
    edit_case: false,
    delete_case: false,
  },
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  practice_area: { id: '1', name: 'Personal Injury' },
  process_stage: { id: PROCESS_STAGES.PRE_LITIGATION, name: 'Pre-Litigation' },
  case_number: 'CASE-001',
  jurisdiction: { id: '1', name: 'California' },
  incident_date: new Date('2023-05-15').toISOString(),
  second_language: { id: '1', name: 'Spanish' },
  dataroom: {
    access: null,
    approved_access_requests: [],
    files: [],
    download_all_url: '',
  },
  negotiation_history: [],
  sidebar: {
    alerts: [],
    special_agreements: null,
    header: {
      title: '',
      description: '',
      fee: 0,
      next_waterfall_user: null,
      counterpart: null,
      on_behalf_of: null,
      actions: {
        send_message: false,
        propose_case: false,
        invite_users: false,
        complete_payment: false,
      },
      message_channels: [],
    },
    negotiations: [],
    dataroom_access_requests: [],
    case_managers: {
      current_case_managers: [],
      case_id: '1',
      case_purchase_id: null,
    },
    client_contact_info: { leads: [] },
    fee_agreements: null,
  },
}

export const defaultContextValue: CaseContextType = {
  caseData: null,
  setCaseData: () => {},
  fetchCaseData: () => {},
  watchCase: () => {},
  hideCase: () => {},
  editCase: () => {},
  deleteCase: () => {},
  loading: false,
  setLoading: () => {},
  error: null,
  setError: () => {},
}

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'

import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import JusticeHQIcon from '@/Icons/Attorney/JusticeHQ'
import noop from 'lodash/noop'
import { useSizes } from '@/hooks/useSizes'
import Header from '../ModalHeader/ModalHeader'
import { ConfirmationModalProps } from '../ConfirmationModal'
import ModalContent from '../ModalContent/ModalContent'

export interface ICaseBodyTag {
  title: string
  description: string
}

export type NonVettedOptionsModalProps = Omit<
  ConfirmationModalProps,
  'title' | 'description'
>

const NonVettedOptionsModal = ({
  AcceptButtonProps,
  ...props
}: NonVettedOptionsModalProps) => {
  const { screenSize } = useSizes()
  const mobileLayout = screenSize !== 'desktop'

  return (
    <Dialog
      scroll="paper"
      sx={theme => ({
        height: '100vh',
        [theme.breakpoints.down('md')]: {
          height: '90vh',
        },
      })}
      {...props}
    >
      <Header title="You don’t have permissions" onClose={props.onClose} />
      <DialogContent>
        <Typography
          variant="body1"
          sx={{
            mb: 2,
          }}
        >
          Only JHQ Vetted Attorneys or users whitelisted by the case owner are
          allowed to submit proposals, request access to data room or add new
          members to their organization.
        </Typography>
        <ModalContent>
          <Grid container>
            <Paper
              variant="outlined"
              square
              sx={theme => ({
                border: '2px solid',
                borderColor: 'secondary.main',
                width: '100%',
                padding: `${theme.spacing(mobileLayout ? 3 : 8)}`,
              })}
            >
              <Box sx={{ width: '100%' }}>
                <Stack spacing={2}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: mobileLayout ? 'row' : 'column',
                      alignItems: 'center',
                    }}
                  >
                    <JusticeHQIcon
                      sx={{
                        height: '48px',
                        width: '60px',
                        alignSelf: 'center',
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      align={mobileLayout ? 'left' : 'center'}
                      sx={{
                        opacity: '0.6',
                        pt: { xs: 0, md: 2 },
                        pl: { xs: 1, md: 0 },
                      }}
                    >
                      Only Justice HQ members can submit proposals to
                      Marketplace cases
                    </Typography>
                  </Box>
                  <Link
                    href="https://justicehq.com/about-us/application-process/"
                    underline="none"
                    target="_blank"
                  >
                    <Button
                      variant="contained"
                      startIcon={null}
                      onClick={noop}
                      fullWidth
                    >
                      Join Waitlist
                    </Button>
                  </Link>
                  <Link
                    href="https://justicehq.com/"
                    underline="none"
                    target="_blank"
                  >
                    <Button startIcon={null} onClick={noop} fullWidth>
                      What is Justice HQ &#63;
                    </Button>
                  </Link>
                </Stack>
              </Box>
            </Paper>
          </Grid>
        </ModalContent>
      </DialogContent>
    </Dialog>
  )
}

export default NonVettedOptionsModal

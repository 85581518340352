import request from '@/axios/config'
import setAuthToken from '@/axios/setAuthToken'
import {
  SessionKey,
  sessionStorageService,
} from '@/services/SessionStorage/SessionStorageService'
import {
  FC,
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { ProposalSuccess } from '@/constants'
import { MODAL_TYPES } from '@/types'
import { TError } from '@/utils/api/types'
import useOnMount from '@mui/utils/useOnMount'
import useOrganization from '@/hooks/useOrganization'
import { useGetMyRoleAndOrg } from '@/hooks/useGetMyRoleAndOrg'
import { CaseEntry } from '../MyReferralsV2/types'
import { IMyReferralsContext } from './types'
import { initialContextValue } from './initialContextValue'

const mostRecentlyUpdatedCasePurchaseTime = (caseEntry: CaseEntry) =>
  caseEntry.case_purchase?.length
    ? Math.max(
        ...caseEntry.case_purchase.map(({ status_ts }) =>
          new Date(status_ts).getTime()
        )
      ) ?? 0
    : 0

const lastActiveTime = (caseEntry: CaseEntry) =>
  mostRecentlyUpdatedCasePurchaseTime(caseEntry) ||
  new Date(caseEntry.created_ts).getTime()

export const MyReferralsContext =
  createContext<IMyReferralsContext>(initialContextValue)

interface SearchableAttorney {
  first_name?: string
  last_name: string | null
  phone?: string
  email?: string
}

export const MyReferralsProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const {
    isLoading: areReferralsLoading,
    data: rawCaseEntries = [],
    error,
    refetch,
    isRefetching,
  } = useQuery<CaseEntry[], TError>('allReferrals', async () => {
    const token = sessionStorageService.getItem(SessionKey.TOKEN)
    setAuthToken(token)
    const response = await request.get('/myreferrals')
    return response.data
  })

  const { getIsSameOrg, loading: loadingOrgAndRole } = useGetMyRoleAndOrg()
  const [filters, setFilters] = useState({
    searchText: '',
    caseType: '',
    jurisdiction: '',
    counterpart: '',
  })

  const {
    getOrganizationData,
    loading: isOrganizationDataLoading,
    organizationData,
  } = useOrganization()

  useOnMount(() => {
    ;(async () => {
      try {
        await getOrganizationData()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    })()
  })

  const caseEntries = useMemo(() => {
    if (!rawCaseEntries || rawCaseEntries.length === 0) return []

    const { searchText, caseType, jurisdiction, counterpart } = filters

    const sortedCaseEntries = [...rawCaseEntries].sort((a, b) => {
      if (a.needs_your_attention !== b.needs_your_attention) {
        return a.needs_your_attention ? -1 : 1
      }
      if (a.case_purchase.length !== b.case_purchase.length) {
        return a.case_purchase.length ? -1 : 1
      }
      return lastActiveTime(b) - lastActiveTime(a)
    })

    const matchAttorneyInfoSearched = (attorney?: SearchableAttorney) => {
      if (!attorney) return false
      const fullName = `${attorney.first_name} ${attorney.last_name}`
      const found =
        fullName.toLowerCase().includes(searchText.toLowerCase()) ||
        attorney.email?.toLowerCase().includes(searchText.toLowerCase()) ||
        attorney.phone
          ?.toLowerCase()
          .replace(/[^\d]/g, '')
          .includes(searchText.toLowerCase())

      return found
    }

    return sortedCaseEntries.filter(caseEntry => {
      if (
        searchText &&
        !String(caseEntry?.name || '')
          .toLowerCase()
          .includes(searchText.toLowerCase()) &&
        !matchAttorneyInfoSearched(caseEntry.attorney) &&
        !caseEntry.leads?.some(attorney =>
          matchAttorneyInfoSearched(attorney)
        ) &&
        !matchAttorneyInfoSearched(
          caseEntry?.case_purchase?.[0]?.case_purchase_activities
            ?.created_by_attorney
        ) &&
        !caseEntry.case_purchase.some(
          casePurchase =>
            matchAttorneyInfoSearched(casePurchase.prospect_attorney) ||
            matchAttorneyInfoSearched(casePurchase.created_by_attorney)
        )
      ) {
        return false
      }

      // Filter By Case Type (Practice Areas)
      if (caseType && String(caseEntry?.type) !== caseType) {
        return false
      }

      // Filter By Jurisdiction (US States)
      if (jurisdiction && String(caseEntry?.jurisdiction) !== jurisdiction) {
        return false
      }

      // Filter By Counterpart Attorney (Seller or Buyer)
      if (
        counterpart &&
        !(
          caseEntry?.attorney?.attorney_id === counterpart ||
          caseEntry?.case_purchase?.some(
            purchase => purchase?.prospect_attorney?.attorney_id === counterpart
          )
        )
      ) {
        return false
      }

      return true
    })
  }, [rawCaseEntries, filters])

  const needsYourAttentionData: IMyReferralsContext['needsYourAttentionData'] =
    useMemo(
      () =>
        (Array.isArray(rawCaseEntries) &&
          rawCaseEntries.filter(
            (item: CaseEntry) => item.needs_your_attention === true
          )) ||
        [],
      [rawCaseEntries]
    )

  const watchedCases: IMyReferralsContext['watchedCases'] = useMemo(
    () =>
      (Array.isArray(rawCaseEntries) &&
        rawCaseEntries.filter((item: CaseEntry) => item.following === true)) ||
      [],
    [rawCaseEntries]
  )

  const [searchParams] = useSearchParams()
  const proposalSuccessType = searchParams.get('proposalSuccess')
  const [responseModal, setResponseModal] = useState<
    IMyReferralsContext['responseModal']
  >({
    type: null,
    open: false,
  })

  useEffect(() => {
    if (proposalSuccessType) {
      // 'case#: ' refers to the scenarios established in https://attorney.atlassian.net/browse/MP-1254
      // These are placeholders or suggestions, the final queryParams will be defined in the Match Fee implementation
      // case2: 1st user accepting, feeder or eater, payment needed (Payment done, waiting counterpart response)
      // case4: Eater accepting in 2nd turn, payment needed (Payment done. Agreement reached, you have acquired the case)
      // case6: Feeder accepting in 2nd turn, payment needed (Payment done. Agreement reached, you have transferred the case)
      if (proposalSuccessType === ProposalSuccess.ACCEPT_PAID_PENDING) {
        setResponseModal({ type: MODAL_TYPES.ACCEPT_PAID_PENDING, open: true })
      }

      if (proposalSuccessType === ProposalSuccess.EATER_ACCEPT_PAID_COMPLETED) {
        setResponseModal({
          type: MODAL_TYPES.EATER_ACCEPT_PAID_COMPLETED,
          open: true,
        })
      }

      if (
        proposalSuccessType === ProposalSuccess.FEEDER_ACCEPT_PAID_COMPLETED
      ) {
        setResponseModal({
          type: MODAL_TYPES.FEEDER_ACCEPT_PAID_COMPLETED,
          open: true,
        })
      }
    }
  }, [proposalSuccessType])

  const contextValue = useMemo(() => {
    const myReferralsContextValue: IMyReferralsContext = {
      caseEntries,
      error,
      filters,
      getIsSameOrg,
      isLoading:
        areReferralsLoading || isOrganizationDataLoading || loadingOrgAndRole,
      isRefetching,
      needsYourAttentionData,
      rawCaseEntries,
      refetch,
      responseModal,
      setFilters,
      watchedCases,
      organizationData,
    }

    return myReferralsContextValue
  }, [
    caseEntries,
    error,
    filters,
    getIsSameOrg,
    areReferralsLoading,
    isOrganizationDataLoading,
    isRefetching,
    loadingOrgAndRole,
    needsYourAttentionData,
    rawCaseEntries,
    refetch,
    responseModal,
    watchedCases,
    organizationData,
  ])

  return (
    <MyReferralsContext.Provider value={contextValue}>
      {children}
    </MyReferralsContext.Provider>
  )
}

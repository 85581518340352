import Box from '@mui/material/Box'
import { ReactNode } from 'react'
import { styled, Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { SystemStyleObject } from '@mui/system'

const Container = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

export interface EmptyPageProps {
  action?: ReactNode
  message?: ReactNode
  sx?: SystemStyleObject<Theme>
}
const EmptyPage = ({ action, message, sx }: EmptyPageProps) => (
  <Container
    sx={theme => ({
      width: '100%',
      p: `0 ${theme.spacing(2)}`,
      mt: {
        xs: 5,
        md: 12,
      },
      ...(sx && sx),
    })}
  >
    <Stack
      sx={{
        alignItems: 'center',
      }}
    >
      <Typography variant="subtitle1" color="text.secondary">
        {message}
      </Typography>
      {action}
    </Stack>
  </Container>
)

export default EmptyPage

import setAuthToken from '@/axios/setAuthToken'
import {
  SessionKey,
  sessionStorageService,
} from '@/services/SessionStorage/SessionStorageService'

export const validateToken = () => {
  const token = sessionStorageService.getItem(SessionKey.TOKEN)
  if (!token) {
    throw new Error('Token is missing')
  }
  setAuthToken(token)
}

import { Attorney_Profile_License, Organization_User } from '@/gql/appApi'

export type Firm = {
  id?: string
  name: string
  address?: string
  state?: CatalogType
  city?: string
  zip?: string
  size?: CatalogType
  relationship?: CatalogType
}

export type License = {
  id?: string
  bar_number: string
  license_state: CatalogType
  is_license_suspended: boolean
  has_been_disciplined_by_bar: boolean
  has_been_sued_by_malpractice: boolean
  insurances?: Attorney_Profile_License['insurances'][]
}

export type CatalogType = {
  id?: string
  name: string
  code?: string
}

export type Award = {
  id?: string
  award_id: CatalogType
}

export type Contact = {
  id?: string
  type: CatalogType
  value: string
}

export type Education = {
  id?: string
  school_id: CatalogType
  grade: CatalogType
}

export type CaseSW = {
  id?: string
  case_management_software_id: CatalogType
}

export type Language = {
  id?: string
  language_id: CatalogType
}

export type PracticeArea = {
  id?: string
  practice_area_id: CatalogType
}

export type Attorney = {
  id: string
  is_vetted?: boolean
  membership_type?: { id?: string }
  user_id: UserType
}

export type UserType = {
  id: string
  email: string
  last_access: string
  avatar: UserAvatarType
  current_organization?: Organization_User[]
}

export type UserAvatarType = {
  id: string
}

export type Avatar = {
  id: string
}

export type ProfileType = {
  first_name: string
  last_name: string
  bio?: string
  avatar?: Avatar
  firms?: Firm[]
  id?: string
  attorney_id?: Attorney
  practice_areas?: PracticeArea[]
  licenses?: License[]
  awards?: Award[]
  contacts?: Contact[]
  education?: Education[]
  case_management_sofwares?: CaseSW[]
  languages?: Language[]
  years_practice?: CatalogType
  is_vetted?: boolean
  referral_source?: string
}

export type JCaseType = {
  id: string
  name: string
  following_count?: number
}

// MODALS
export const MODAL_TYPES = {
  ACCEPT: 'Accept',
  ACCEPT_FREE_PENDING: 'AcceptFreePending',
  ACCEPT_PAID_PENDING: 'AcceptPaidPending',
  ADD_CASE_MANAGER: 'AddCaseManager',
  CASE_CREATED: 'CaseCreated',
  CASE_EDITED: 'CaseEdited',
  CASE_NOT_EDITABLE: 'CaseNotEditable',
  CHANGE_EMAIL_SUCCESS: 'ChangeEmailSuccess',
  CHANGE_PASSWORD_SUCCESS: 'ChangePasswordSuccess',
  CLIENT_SIGNED_CONFIRMATION: 'ClientSignedConfirmation',
  CLIENT_SIGNED_REJECTED: 'ClientSignedRejection',
  COUNTER_OFFER: 'Counteroffer',
  DATAROOM: 'Dataroom',
  DISCARD_CHANGES_ACCEPTED: 'DiscardChangesAccepted',
  EATER_ACCEPT_FREE_COMPLETED: 'EaterAcceptFreeCompleted',
  EATER_ACCEPT_PAID_COMPLETED: 'EaterAcceptPaidCompleted',
  EATER_PROPOSAL_WITHDRAWN: 'EaterProposalWithdrawn',
  ERROR: 'Error',
  FEEDER_ACCEPT_FREE_COMPLETED: 'FeederAcceptFreeCompleted',
  FEEDER_ACCEPT_PAID_COMPLETED: 'FeederAcceptPaidCompleted',
  REJECT: 'Reject',
  SEND: 'Send',
} as const
type ModalKeys = keyof typeof MODAL_TYPES
export type ModalTypes = (typeof MODAL_TYPES)[ModalKeys] | null

export type ModalProposalType = 'Referral' | 'Proposal' | 'Dataroom' | 'Invited'

export type PermissionsMatrixType = {
  Case: {
    create: boolean
    delete: boolean
    read: boolean
    update: boolean
  }
  Integration: {
    create: boolean
    delete: boolean
    read: boolean
    update: boolean
  }
  Organization: {
    create: boolean
    delete: boolean
    read: boolean
    update: boolean
  }
  Purchase: {
    create: boolean
    delete: boolean
    read: boolean
    update: boolean
  }
  User: {
    create: boolean
    delete: boolean
    read: boolean
    update: boolean
  }
  Favorites: {
    create: boolean
    delete: boolean
    read: boolean
    update: boolean
  }
  Marketplace: {
    create: boolean
    delete: boolean
    read: boolean
    update: boolean
  }
  Profile: {
    create: boolean
    delete: boolean
    read: boolean
    update: boolean
  }
  Agreements: {
    create: boolean
    delete: boolean
    read: boolean
    update: boolean
  }
}

export type GlobalPermissionsType = {
  inOrganization: PermissionsMatrixType
  outOrganization: PermissionsMatrixType
}

export type GlobalPermissionEntities =
  | 'User'
  | 'Permission'
  | 'Organization'
  | 'Integration'
  | 'Marketplace'
  | 'Case'
  | 'Purchase'
  | 'Sale'
  | 'Profile'
  | 'Agreements'

export type GlobalPermissionActions =
  | 'share'
  | 'create'
  | 'read'
  | 'update'
  | 'delete'

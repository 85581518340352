import Paper from '@mui/material/Paper'
import JTextField from '@/components/atoms/JTextField'
import { useSizes } from '@/hooks/useSizes'
import { Box, Grid, useTheme } from '@mui/material'
import { FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  DiscardChangesModal,
  useDiscardChangesModal,
  ResponseModal,
} from '@/components'
import { MobileAppBar } from '@/pages/AccountSettings/Shared/MobileAppBar'
import { DesktopStickyActions } from '../Shared/DesktopStickyActions'
import { IAccountSettingsTabProps } from '../types'
import TitleColumn from '../Shared/TitleColumn'

type TForm = {
  currentEmail: string
  newEmail: string
  repeatEmail: string
}

const defaultValues: TForm = {
  currentEmail: '',
  newEmail: '',
  repeatEmail: '',
}
const ChangeAccountEmail: FC<IAccountSettingsTabProps> = ({
  title,
  subtitle,
  profile,
}) => {
  const theme = useTheme()
  const newDefValues = {
    currentEmail: profile?.attorney_id?.user_id.email,
  }

  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues,
    values: newDefValues,
    mode: 'onChange',
  })
  const { textFieldSize } = useSizes()
  const { screenSize } = useSizes()
  const mobileLayout = screenSize !== 'desktop'
  const [response, setResponse] = useState({
    type: null,
    open: false,
  })

  const isValidEmail = (value: string) => {
    const emailRegex = /^\S+@\S+\.\S+$/
    if (!value) {
      return 'Email is required'
    }
    if (!emailRegex.test(value)) {
      return 'Email is invalid'
    }
    return true
  }

  const onSubmit: SubmitHandler<TForm> = form => {
    console.log('Send data', form)
  }

  const verifyEmail = (email: string) => {
    isValidEmail(email)
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      email === watch('newEmail') || 'This email does not match the new email'
    )
  }

  const {
    getConfirmationModalProps,
    setOpenModal,
    modalOpen,
    type: modalType,
    ...rest
  } = useDiscardChangesModal({
    type: 'confirm',
    onSubmit: () => {
      reset(newDefValues)
      setOpenModal(null)
    },
  })

  const handleChangeEmail = () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setResponse({ type: 'ChangeEmailSuccess', open: true })

  const handleDiscardChanges = () => {
    setOpenModal('confirmDiscardChanges')
  }

  const pSize = mobileLayout ? 2 : 0

  return (
    <Paper
      sx={{
        overflow: 'auto',
        maxHeight: '100%',
        padding: theme.spacing(2),
        mb: mobileLayout ? theme.spacing(20) : theme.spacing(0),
      }}
    >
      <form
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container direction="row" spacing={mobileLayout ? 0 : 5}>
          <TitleColumn title={title} subtitle={subtitle} />
          <Grid item mt={0} md={8} xs={12}>
            <Box sx={{ width: '100%' }} pl={pSize} pt={2} mb={2}>
              {modalOpen && (
                <DiscardChangesModal
                  ConfirmationModalProps={getConfirmationModalProps()}
                  {...rest}
                />
              )}
              {response.open && (
                <ResponseModal
                  open={response.open}
                  type={response.type}
                  onClose={() =>
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    setResponse({ showResponse: false, type: null })
                  }
                />
              )}

              <Grid container rowSpacing={2} pl={2} pr={2}>
                <Grid item xs={12}>
                  <JTextField<TForm>
                    name="currentEmail"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    control={control}
                    label="Current Email"
                    type="text"
                    size={textFieldSize}
                    rules={{ validate: (email: string) => isValidEmail(email) }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <JTextField<TForm>
                    name="newEmail"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    control={control}
                    label="New Email"
                    type="text"
                    size={textFieldSize}
                    rules={{ validate: (email: string) => isValidEmail(email) }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <JTextField<TForm>
                    name="repeatEmail"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    control={control}
                    label="Repeat New Email"
                    type="text"
                    size={textFieldSize}
                    rules={{ validate: (email: string) => verifyEmail(email) }}
                  />
                </Grid>
              </Grid>
              <MobileAppBar
                onPrimaryActionClick={handleChangeEmail}
                onSecondaryActionClick={handleDiscardChanges}
              />
            </Box>
          </Grid>
        </Grid>
        <DesktopStickyActions
          onPrimaryActionClick={handleChangeEmail}
          onSecondaryActionClick={handleDiscardChanges}
        />
      </form>
    </Paper>
  )
}

export default ChangeAccountEmail

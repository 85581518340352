import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import JAvatar from '@/components/atoms/JAvatar'
import { Link, generatePath } from 'react-router-dom'
import { RoutesData } from '@/router/RoutesData'
import { Attorney } from '@/pages/MyReferralsV2/types'
import { getImagePath } from '@/utils'
import { IMAGE_SIZES } from '@/constants'
import useTheme from '@mui/material/styles/useTheme'

type ReferralAttorneyAvatarProps = {
  attorney: Attorney
  showOrganization?: boolean
  fitToSingleLine?: boolean
}

export const ReferralAttorneyAvatar = ({
  attorney,
  showOrganization,
  fitToSingleLine = false,
}: ReferralAttorneyAvatarProps) => {
  const theme = useTheme()
  const avatar = getImagePath({
    id: attorney.avatar,
    size: IMAGE_SIZES.Small,
  })

  const avatarDimensionSmall = '24px'
  const avatarDimensionLarge = '36px'

  if (fitToSingleLine && !showOrganization) {
    // eslint-disable-next-line no-console
    console.warn(
      'ReferralAttorneyAvatar: (fitToSingleLine: true) without (showOrganization: true) has no effect'
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        maxWidth: { md: '180px', lg: '250px' },
        flexWrap: { xs: 'wrap', md: 'nowrap' },
        alignItems: 'center',
      }}
      component={Link}
      to={generatePath(RoutesData.RProfile.path, {
        id: attorney.attorney_id,
      })}
    >
      <JAvatar
        src={avatar}
        alt={`${attorney.first_name} ${attorney.last_name}`}
        isVetted={attorney.is_vetted}
        badgeSize="xsmall"
        sx={{
          avatar: {
            height: fitToSingleLine
              ? avatarDimensionSmall
              : avatarDimensionLarge,
            width: fitToSingleLine
              ? avatarDimensionSmall
              : avatarDimensionLarge,
          },
        }}
      />
      <Typography
        variant="body2"
        sx={{
          overflow: 'hidden',
          textWrap: 'nowrap',
          textOverflow: 'ellipsis',
          display: { xs: 'flex', md: 'block' },
          flexDirection: 'column',
        }}
      >
        {attorney.first_name} {attorney.last_name}
        {showOrganization && (
          <Typography
            variant="caption"
            color={theme.palette.text.secondary}
            display={fitToSingleLine ? 'inline' : 'block'}
            ml={{ xs: 0, md: fitToSingleLine ? 1 : 0 }}
          >
            {attorney.organization_name}
          </Typography>
        )}
      </Typography>
    </Box>
  )
}

import { SchoolsQuery, useSchoolsLazyQuery } from '@/gql/appApi'
import { School } from '@/gql/systemApi'
import debounce from 'lodash/debounce'
import { useEffect, useMemo, useState } from 'react'

interface IUseDebounceSchoolsProps {
  value?: School
  inputValue: string
  isUndergradSchool?: boolean
}

export const useDebounceSchools = ({
  value,
  inputValue,
  isUndergradSchool,
}: IUseDebounceSchoolsProps) => {
  const [options, setOptions] = useState<Array<School>>([])
  const [lazySchools, { data, loading, error }] = useSchoolsLazyQuery({})

  const debouncer = useMemo(() => debounce(lazySchools, 500), [lazySchools])

  useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [value] : [])
    } else {
      debouncer({
        variables: {
          search: inputValue,
          filter: {
            ...(isUndergradSchool
              ? {
                  is_undergrad_school: {
                    _eq: true,
                  },
                }
              : {
                  is_law_school: {
                    _eq: true,
                  },
                }),
          },
        },
      })
    }
  }, [value, inputValue, debouncer, data])

  const getOptions = (school: School, schools: SchoolsQuery) => [
    school,
    ...schools.school.filter(sc => sc.id !== school.id),
  ]

  useEffect(() => {
    if (data) {
      const opt = value ? getOptions(value, data) : [...data.school]
      setOptions(opt)
    }
  }, [data])

  return { options, loading, error, setOptions }
}

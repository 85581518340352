import { FC } from 'react'
import { Box } from '@mui/material'
import { useSizes } from '@/hooks/useSizes'
import AlgoliaSelect from './AlgoliaSelect'
import AlgoliaMoreFilters from './AlgoliaMoreFilters'
import { marketplaceFilters } from '../config'

const AlgoliaMarketplaceFilters: FC = () => {
  const { isLessMd } = useSizes()

  return (
    <Box sx={{ display: 'flex', gap: 2, flexGrow: 1 }}>
      {isLessMd ? null : (
        <Box sx={{ display: 'flex', flexGrow: 1, gap: 2 }}>
          {marketplaceFilters
            .filter(filter => filter.showOnDesktop)
            .map(filter => (
              <AlgoliaSelect
                key={filter.attribute}
                attribute={filter.attribute}
                renderOption={filter.renderOption}
              />
            ))}
        </Box>
      )}
      <AlgoliaMoreFilters />
    </Box>
  )
}

export default AlgoliaMarketplaceFilters

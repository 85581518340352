import { useEffect } from 'react'
import { useEditor } from '@tiptap/react'
import FormHelperText from '@mui/material/FormHelperText'
import Box, { BoxProps } from '@mui/material/Box'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuButtonBulletedList,
  MenuButtonOrderedList,
  RichTextField as MuiRichTextField,
  RichTextFieldProps,
  RichTextEditorProvider,
} from 'mui-tiptap'
import useTheme from '@mui/material/styles/useTheme'
import { FieldError } from 'react-hook-form'

export interface RichTextInputProps
  extends Partial<RichTextFieldProps>,
    Pick<BoxProps, 'sx'> {
  error?: FieldError
  onChange?: (updateProps: { plainText: string; formattedText: string }) => void
  onBlur?: () => void
  value?: string
  placeholder?: string
  minHeight?: number | string
}

export const RichTextField = ({
  error,
  onChange,
  onBlur,
  value = '',
  placeholder = '',
  minHeight,
  sx,
  ...props
}: RichTextInputProps) => {
  const theme = useTheme()

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder,
      }),
    ],
    content: value,
    onUpdate({ editor: editorInstance }) {
      const plainText = editorInstance.getText()
      const formattedText = editorInstance.getHTML()

      onChange?.({ plainText, formattedText })
    },
  })

  // NOTE: This is necessary for backfilling the rich text field when the value is provided after the first render.
  useEffect(() => {
    if (value === editor?.getHTML()) return

    editor?.commands.setContent(value)
  }, [value, editor])

  const handleBlur = () => {
    onBlur?.()
  }

  return (
    <Box
      onBlur={handleBlur}
      sx={[
        {
          // Matches placeholder color of MUI TextField
          '& .tiptap.ProseMirror p.is-empty::before': {
            color: theme.palette.text.secondary,
          },
          '& .MuiTiptap-RichTextContent-root': {
            'ol, ul': {
              pl: 4,
            },
            minHeight,
          },
          ...(error && {
            '& .MuiTiptap-FieldContainer-notchedOutline': {
              borderColor: theme.palette.error.main,
            },
          }),
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <RichTextEditorProvider editor={editor}>
        <MuiRichTextField
          controls={
            <MenuControlsContainer>
              <MenuButtonBold />
              <MenuButtonItalic />
              <MenuButtonBulletedList />
              <MenuButtonOrderedList />
            </MenuControlsContainer>
          }
          {...props}
        />
      </RichTextEditorProvider>
      {error && (
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          {error.message}
        </FormHelperText>
      )}
    </Box>
  )
}

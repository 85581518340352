/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react'
import Grid from '@mui/material/Grid'

import Typography from '@mui/material/Typography'

import JAvatar from '@/components/atoms/JAvatar/'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useSizes } from '@/hooks/useSizes'
import { useTheme } from '@mui/material'
import RemoveCaseManagerModal from '@/components/Modals/RemoveCaseManagerModal'

export interface ICaseManagerItem {
  ownership_id: string
  first_name: string
  last_name: string
  id: string
  avatar: {
    id: string
  }
  avatarSrc: string
  is_vetted?: boolean
}

const CaseManagerList = ({
  caseManagerList,
  isOwner,
}: {
  caseManagerList: ICaseManagerItem[]
  isOwner: boolean
}) => {
  const theme = useTheme()
  const { isLessMd } = useSizes()
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false)
  const [userToRemove, setUserToRemove] = useState<ICaseManagerItem | null>(
    null
  )

  const handleRemoveClick = async (collaborator: ICaseManagerItem) => {
    setUserToRemove(collaborator)
    setShowRemoveModal(true)
  }

  return (
    <>
      {userToRemove ? (
        <RemoveCaseManagerModal
          caseManager={userToRemove}
          openModal={showRemoveModal}
          setOpenModal={setShowRemoveModal}
          isOwner={isOwner}
        />
      ) : null}
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="caption"
            display="block"
            color="text.disabled"
            sx={{
              mb: '1rem',
              [theme.breakpoints.up('sm')]: {
                marginBlock: '1rem',
              },
            }}
          >
            Case Managers allowed to act on this case
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {caseManagerList.map((collaborator: ICaseManagerItem) => {
            const {
              id,
              first_name,
              last_name,
              avatarSrc,
              is_vetted = false,
            } = collaborator
            const fullName = `${first_name} ${last_name}`

            return (
              <Box
                key={`CaseManager_${id}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                  paddingBottom: isLessMd ? '.7rem' : '1rem',
                }}
              >
                <JAvatar
                  src={avatarSrc}
                  sx={{
                    avatar: {
                      width: isLessMd ? 28 : 48,
                      height: isLessMd ? 28 : 48,
                    },
                  }}
                  alt={fullName}
                  isVetted={is_vetted}
                />
                <Box>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 700, align: 'left' }}
                  >
                    {fullName}
                  </Typography>
                  {!isLessMd && (
                    <Typography variant="body2">
                      Case Manager Position
                    </Typography>
                  )}
                </Box>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleRemoveClick(collaborator)}
                  sx={{
                    marginLeft: 'auto',
                  }}
                >
                  <CloseOutlinedIcon sx={{ fontSize: '16px' }} />
                </IconButton>
              </Box>
            )
          })}
        </Grid>
      </Grid>
    </>
  )
}

export default CaseManagerList

import React from 'react'
import { Box, Skeleton } from '@mui/material'

const DataroomSkeleton: React.FC = () => (
  <Box>
    <Skeleton
      variant="rectangular"
      width="100%"
      height={200}
      sx={{ borderRadius: 1 }}
    />
  </Box>
)

export default DataroomSkeleton

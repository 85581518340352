import { useState } from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useForm } from 'react-hook-form'
import useTheme from '@mui/material/styles/useTheme'
import { ArrowForward } from '@mui/icons-material'
import { useSizes } from '@/hooks/useSizes'
import LogoBar from '@/pages/CreateAccount/shared/LogoBar'
import {
  ProfessionalLicenseFormValues,
  ProfessionalLicensesForm,
} from '@/components/ProfessionalLicensesForm'
import { alpha } from '@mui/material'

export interface ProfessionalLicensesProps {
  back: () => void
  onSubmit: (newValues: {
    professionalLicenses: ProfessionalLicenseFormValues[]
  }) => void
  professionalLicensesValues: ProfessionalLicenseFormValues[]
  loading?: boolean
  primaryBtnText?: string
}

const ProfessionalLicenses = ({
  back,
  onSubmit,
  professionalLicensesValues,
  loading,
  primaryBtnText = 'Next',
}: ProfessionalLicensesProps) => {
  const theme = useTheme()
  const { isLessMd } = useSizes()
  const [error, setError] = useState('')

  const formHookProps = useForm<{
    professionalLicenses: ProfessionalLicenseFormValues[]
  }>({
    defaultValues: { professionalLicenses: professionalLicensesValues },
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const { handleSubmit, trigger } = formHookProps

  const title = 'Professional License'
  const subtitle =
    'Please add your Bar Number and the state you are licensed in.'

  const handleNextClick = async () => {
    const isValid = await trigger()

    if (isValid) {
      handleSubmit(onSubmit)()
    }
  }

  return (
    <Box
      sx={{
        paddingTop: { xs: 1, md: 0 },
        pb: { md: 5 },
        height: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        [theme.breakpoints.down('md')]: {
          paddingBottom: theme.spacing(2),
        },
      }}
    >
      <LogoBar />
      {error && (
        <Alert severity="error" onClose={() => setError('')}>
          {error}
        </Alert>
      )}
      <Typography
        variant={isLessMd ? 'h6' : 'h5'}
        sx={{ mb: 1, textAlign: 'left' }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mb: 5,
          textAlign: 'left',
          color: alpha(theme.palette.common.black, 0.6),
        }}
      >
        {subtitle}
      </Typography>
      <ProfessionalLicensesForm
        onSubmit={onSubmit}
        formHookProps={formHookProps}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          gap: 2,
          justifyContent: 'flex-end',
          ...(isLessMd && {
            justifyContent: 'space-between',
          }),
          pb: 10,
        }}
      >
        <Button
          fullWidth
          variant="text"
          onClick={back}
          disabled={loading}
          size="large"
          sx={{
            width: {
              xs: '35%',
              md: '50%',
            },
            height: 48,
          }}
        >
          Back
        </Button>
        <Button
          size="large"
          variant="contained"
          sx={{
            width: {
              xs: '65%',
              md: '50%',
            },
            height: 48,
          }}
          onClick={handleNextClick}
          endIcon={<ArrowForward sx={{ color: theme.palette.primary.main }} />}
          disabled={loading}
        >
          {primaryBtnText}
        </Button>
      </Box>
    </Box>
  )
}

export default ProfessionalLicenses

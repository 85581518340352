import {
  GetActivityPurchaseDocument,
  GetActivityPurchaseFromMyOrgDocument,
  GetCasesDocument,
  useCaseExistsLazyQuery,
  useCreateCaseActivityPurchaseMutation,
  useCreateCreditsPaymentMutation,
} from '@/gql/appApi'
import { CASE_PURCHASE_STATUS, PAYMENT_STATUS } from '@/constants'
import { TPaymentStatus } from '@/pages/MyReferralsV2/NegotiationItem/types'
import {
  getModalResponse,
  getPaymentMethod,
  getQueryParam,
} from '@/pages/MyReferralsV2/NegotiationItem/hooks/utils'
import useAttorneyEmail from '@/hooks/useAttorneyEmail'
import useAttorneyId from '@/hooks/useAttorneyId'
import { useStripe } from '@/hooks/useStripe'
import { IOnSubmitAcceptActionProps } from '../types'

interface IUseOnAcceptActionProps {
  caseId?: string
  casePurchaseId: string
  representingAttorneyId?: string
  userId?: string
  isCaseOwner?: boolean
  setCaseErrorModalOpen: (value: boolean) => void
  paymentStatus: TPaymentStatus
  isInvited?: boolean
  /**
   * With the new case status flow, the user can accept the proposal without paying if:
   * - The user is the case owner
   * - The user is the invited attorney
   * - The user submitted a prepaid proposal (so no additional payment is required)
   */
  noPaymentRequired: boolean
  fee: number
}

const useOnAcceptAction = ({
  caseId,
  casePurchaseId,
  representingAttorneyId,
  userId,
  isCaseOwner,
  setCaseErrorModalOpen,
  paymentStatus,
  isInvited,
  noPaymentRequired,
  fee,
}: IUseOnAcceptActionProps) => {
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = getAttorneyId()
  const { getAttorneyEmail } = useAttorneyEmail()
  const attorneyEmail = getAttorneyEmail()
  const { createCheckoutSession } = useStripe()

  const [payWithCredits, { loading: payWithCreditsLoading }] =
    useCreateCreditsPaymentMutation({
      refetchQueries: [
        GetActivityPurchaseDocument,
        'case_purchase_activity',
        GetCasesDocument,
        'case',
        GetActivityPurchaseFromMyOrgDocument,
        'case_purchase_activity',
      ],
    })

  const [
    createCaseActivityPurchaseMutation,
    { loading: loadingCreateCaseActivityMutation },
  ] = useCreateCaseActivityPurchaseMutation({
    refetchQueries: [
      GetActivityPurchaseDocument,
      'case_purchase_activity',
      GetCasesDocument,
      'case',
      GetActivityPurchaseFromMyOrgDocument,
      'case_purchase_activity',
    ],
  })

  const [caseExists, { loading: caseExistsLoading }] = useCaseExistsLazyQuery({
    variables: {
      filter: {
        id: {
          _eq: caseId,
        },
      },
    },
    fetchPolicy: 'network-only',
  })

  const onAcceptWithNoPayment = async () => {
    try {
      const response = await createCaseActivityPurchaseMutation({
        variables: {
          data: {
            fee: fee || 0,
            activity_entry_status: CASE_PURCHASE_STATUS.Accept,
            created_by: representingAttorneyId || '',
            case_purchase_id: {
              id: casePurchaseId || '',
            },
          },
        },
      })

      if (response.data?.create_case_purchase_activity_item?.id) {
        return {
          type: isCaseOwner
            ? 'FeederAcceptFreeCompleted'
            : 'EaterAcceptFreeCompleted',
          open: true,
        }
      }
      return null
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return null
    }
  }

  const onSubmitAcceptAction = async ({
    stripePriceId,
    credits,
    discount,
  }: IOnSubmitAcceptActionProps) => {
    const caseExistsResult = await caseExists()
    if (!caseExistsResult.data?.case?.[0]?.id) {
      setCaseErrorModalOpen(true)
      return null
    }

    if (noPaymentRequired) {
      return onAcceptWithNoPayment()
    }

    const hasCredits =
      credits && (credits === 'unlimited' || Number(credits) > 0)
    if (hasCredits || isCaseOwner || isInvited) {
      try {
        const creditsPaymentResult = await payWithCredits({
          variables: {
            data: {
              attorney_id: representingAttorneyId,
              case_purchase_id: casePurchaseId,
              payment_method: getPaymentMethod({
                isCaseOwner: !!isCaseOwner,
                isInvited: !!isInvited,
              }),
              payment_status_id: PAYMENT_STATUS.Completed,
              amount: 0,
            },
          },
        })

        if (creditsPaymentResult.data?.create_case_purchase_payment_item?.id) {
          return getModalResponse({
            paymentStatus,
            isCaseOwner: !!isCaseOwner,
          })
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
      return null
    }
    if (userId) {
      const queryParam = getQueryParam({
        paymentStatus,
        isCaseOwner: !!isCaseOwner,
      })

      return createCheckoutSession({
        attorney_id: representingAttorneyId || String(attorneyId),
        case_purchase_id: casePurchaseId,
        customer_email: String(attorneyEmail),
        user_id: userId || '',
        stripePriceId,
        queryParam,
        ...(discount && {
          discounts: [discount],
        }),
      })
    }
    return null
  }

  return {
    onSubmitAcceptAction,
    loading:
      payWithCreditsLoading ||
      caseExistsLoading ||
      loadingCreateCaseActivityMutation,
  }
}

export default useOnAcceptAction

import React from 'react'

import { GlobalPermissionsType } from '@/types'

type GlobalContextType = {
  preventNavigation: boolean
  setPreventNavigation: (val: boolean) => void
  selectedTab: string
  setSelectedTab: (val: string) => void
  globalPermissions?: GlobalPermissionsType
  setGlobalPermissions: (val: any) => void
}

const GlobalContext = React.createContext<GlobalContextType | undefined>(
  undefined
)

export const GlobalContextProvider = GlobalContext.Provider

export default GlobalContext

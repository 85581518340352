import { useState } from 'react'
import { SubmitHandler, useForm, FieldValues } from 'react-hook-form'

import { JCaseType } from '@/types'

export interface IUseRequestDataRoomProps {
  onSubmit: () => void
  jCase: JCaseType | null
  hasDownloadAccess?: boolean
}

const useRequestDataRoomModal = ({
  onSubmit,
  jCase,
  hasDownloadAccess,
}: IUseRequestDataRoomProps) => {
  const [negotiateFee, setNegotiateFee] = useState<boolean>(false)
  const { control, handleSubmit } = useForm({
    defaultValues: {
      message: '',
    },
  })

  const downloadDataRoom = async () => {
    onSubmit()
  }

  const onSubmitAction = async (message?: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const data = { message, jCase }
  }

  const handleSubmitModal: SubmitHandler<FieldValues> = async form => {
    if (hasDownloadAccess) {
      await downloadDataRoom()
      return
    }
    await onSubmitAction(form.message)
    onSubmit()
  }

  return {
    handleSubmitModal,
    control,
    handleSubmit,
    negotiateFee,
    setNegotiateFee,
    loading: false,
    error: null,
  }
}

export default useRequestDataRoomModal

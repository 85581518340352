import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'

interface ErrorPermissionsMessageProps {
  content?: string
}

const ErrorPermissionsMessage: React.FC<ErrorPermissionsMessageProps> = ({
  content,
}: ErrorPermissionsMessageProps) => (
  <Box
    sx={theme => ({
      maxWidth: '650px',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      m: { xs: 3, md: `${theme.spacing(0)} auto` },
    })}
  >
    <Box textAlign="center" maxWidth="inherit">
      <HighlightOffOutlinedIcon
        sx={theme => ({
          height: theme.spacing(6),
          width: theme.spacing(6),
          textAlign: 'center',
          color: 'rgba(0, 0, 0, 0.38)',
        })}
      />
    </Box>
    <Typography
      variant="h6"
      mt={5}
      textAlign="center"
      color="rgba(0, 0, 0, 0.60)"
    >
      {content || 'You don’t have permissions to view this user profile'}
    </Typography>
  </Box>
)

export default ErrorPermissionsMessage

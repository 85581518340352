import { ReactElement, ReactNode } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import ClioIcon from '@/Icons/Integration/Clio'
import LawmaticsIcon from '@/Icons/Integration/Lawmatics'
import LeadDocketIcon from '@/Icons/Integration/LeadDocket'
import LitifyIcon from '@/Icons/Integration/Litify'
import SalesForceIcon from '@/Icons/Integration/SalesForce'
import MyCaseIcon from '@/Icons/Integration/MyCase/MyCase'
import textContent, { integrationsIconSizes } from '../../constants'

interface IntegrationItemCardContentProps {
  actions: ReactElement
  icon: string
  name?: string | ReactElement
  subtitle: ReactNode
}

const IntegrationItemCardContent = ({
  actions,
  icon,
  name,
  subtitle,
}: IntegrationItemCardContentProps) => {
  const { INTEGRATION_V2 } = textContent

  const getIcon = () => {
    switch (icon) {
      case INTEGRATION_V2.CLIO_GROW.ICON:
        return <ClioIcon sx={integrationsIconSizes.md} />
      case INTEGRATION_V2.LEAD_DOCKET.ICON:
        return <LeadDocketIcon sx={integrationsIconSizes.md} />
      case INTEGRATION_V2.LAWMATICS.ICON:
        return <LawmaticsIcon sx={integrationsIconSizes.md} />
      case INTEGRATION_V2.LITIFY.ICON:
        return <LitifyIcon sx={integrationsIconSizes.md} />
      case INTEGRATION_V2.SALESFORCE.ICON:
        return <SalesForceIcon sx={integrationsIconSizes.md} />
      case INTEGRATION_V2.MY_CASE.ICON:
        return <MyCaseIcon sx={integrationsIconSizes.md} />
      default:
        return null
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: {
            md: 'flex-start',
            sm: 'normal',
          },
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {getIcon()}
        </Box>
        <Box
          sx={theme => ({
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'flex-start',
            justifyContent: 'center',
            padding: theme.spacing(0, 2),
            width: '100%',
          })}
        >
          <Typography variant="subtitle1">{name}</Typography>
          <Typography
            variant="body2"
            sx={{
              display: {
                md: 'block',
                sm: 'none',
                xs: 'none',
              },
            }}
          >
            {subtitle}
          </Typography>
        </Box>
        <Box
          sx={theme => ({
            marginTop: {
              md: theme.spacing(5),
              sm: 0,
              sx: 0,
            },
          })}
        >
          {actions}
        </Box>
      </Box>
      <Typography
        variant="body2"
        sx={{
          display: {
            md: 'none',
            xs: 'block',
          },
          mt: 2,
        }}
      >
        {subtitle}
      </Typography>
    </>
  )
}

export default IntegrationItemCardContent

import { ErrorButton } from '@/components/Buttons/ErrorButton'
import { ConfirmationModal, ResponseModal } from '@/components'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { ButtonProps } from '@mui/material/Button'
import JSnackBar from '@/components/atoms/JSnackBar'
import { useWithdrawProposalButton } from './useWithdrawProposalButton'

interface WithdrawProposalButtonProps {
  buttonProps?: Partial<ButtonProps>
  casePurchaseId: string
  canProposalBeWithdrawn?: boolean
  onWithdrawSuccess?: () => void
}

export const WithdrawProposalButton = ({
  buttonProps,
  casePurchaseId,
  canProposalBeWithdrawn = true,
  onWithdrawSuccess,
}: WithdrawProposalButtonProps) => {
  const {
    alert,
    confirmationResponse,
    handleCloseSnack,
    handleConfirmation,
    handleConfirmationModalClose,
    handleConfirmationResponseModalClose,
    handleWithdrawProposalClick,
    isModalOpen,
    isPerformingCreateCaseActivityPurchaseMutation,
  } = useWithdrawProposalButton({ casePurchaseId, onWithdrawSuccess })

  return (
    <>
      {canProposalBeWithdrawn && (
        <ErrorButton
          variant="outlined"
          color="error"
          size="large"
          fullWidth
          onClick={handleWithdrawProposalClick}
          {...buttonProps}
        >
          Withdraw Proposal
        </ErrorButton>
      )}
      <ConfirmationModal
        open={isModalOpen}
        title="Withdraw Proposal Confirmation"
        onClose={handleConfirmationModalClose}
        AcceptButtonProps={{
          disabled: isPerformingCreateCaseActivityPurchaseMutation,
          children: isPerformingCreateCaseActivityPurchaseMutation ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            <>Confirm</>
          ),
          onClick: handleConfirmation,
        }}
      >
        <Typography display="inline" variant="body1">
          Please confirm you want to withdraw your proposal for this case.
        </Typography>
      </ConfirmationModal>
      <ResponseModal
        open={confirmationResponse.open}
        type={confirmationResponse.type}
        onClose={handleConfirmationResponseModalClose}
        variant="standard"
        icon={false}
      />
      <JSnackBar
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        horizontal="center"
        vertical="bottom"
        handleClose={handleCloseSnack}
      />
    </>
  )
}

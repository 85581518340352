import { JSnackBarProps } from '@/components/atoms/JSnackBar/types'
import { useMemo, useState } from 'react'

export type TAlertProps = Omit<JSnackBarProps, 'handleClose'>

export const useSnackBar = () => {
  const [alert, setAlert] = useState<TAlertProps>({
    open: false,
    severity: 'info',
    message: '',
    horizontal: 'center',
    vertical: 'top',
  })

  const showAlert = useMemo(
    () => (data: Omit<TAlertProps, 'open'>) => {
      setAlert(prevAlert => ({
        ...prevAlert,
        open: true,
        severity: data.severity,
        message: data.message,
        horizontal: data.horizontal || prevAlert.horizontal,
        vertical: data.vertical || prevAlert.vertical,
      }))
    },
    []
  )

  const handleClose = (_e?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setAlert({ ...alert, open: false })
  }

  return {
    alert,
    showAlert,
    handleClose,
  }
}

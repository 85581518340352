/* eslint-disable react-hooks/exhaustive-deps, react/no-array-index-key */
import { useCallback, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import TableRow from '@mui/material/TableRow'
import LinearProgress from '@mui/material/LinearProgress'
import dayjs from 'dayjs'

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import { Update_Directus_Files_Input } from '@/gql/systemApi'
import { formatBytes } from '@/utils/helpers'
import { useFileUpload } from '@/hooks/useFileUpload'
import { useListFilesState } from './hooks/useQueueState'

import { FileHistoryProps, FileUplingListProps } from './types'

const FilesUploadingList = ({
  dataFiles,
  caseId,
  casePurchaseId,
  refetchCaseData,
  onAddExternal,
  isExternalLoading,
}: FileUplingListProps) => {
  const [
    list,
    {
      enqueueList,
      deleteIndexList,
      setItemStatusByIndexList,
      setItemErrorByIndexList,
      length,
    },
  ] = useListFilesState([])
  const [uploadingIndex, setUploadingIndex] = useState(0)

  const { upload } = useFileUpload(true)

  const getMessage = useCallback(
    (
      error: string,
      status: Update_Directus_Files_Input | null,
      size: number
    ) => {
      if (error !== '') {
        return error
      }
      if (status !== null) {
        return `Uploaded ${dayjs(status?.uploaded_on).format(
          'HH:mm DD MMM YYYY'
        )}, ${formatBytes(size)}`
      }
      return formatBytes(size)
    },
    []
  )

  useEffect(() => {
    if (dataFiles.length > 0) {
      enqueueList(dataFiles as never[])
    }
  }, [dataFiles])

  useEffect(() => {
    const fetchData = async (dataRoomFileToUpload: File) => {
      let fileData = null
      try {
        if (casePurchaseId) {
          const value = await upload(
            dataRoomFileToUpload,
            () => null,
            caseId || undefined,
            casePurchaseId
          )

          fileData = (value as { data: { data: Update_Directus_Files_Input } })
            ?.data?.data
        } else {
          const value = await upload(
            dataRoomFileToUpload,
            () => null,
            caseId || undefined
          )

          fileData = value as { data: { data: Update_Directus_Files_Input } }
        }
        if (fileData === null) {
          setItemErrorByIndexList(
            uploadingIndex,
            'Something went wrong, please try again or contact Our Support Team.'
          )
        } else {
          setItemStatusByIndexList(uploadingIndex, fileData)
          await refetchCaseData()
        }
      } catch (err: any) {
        const errorMessage = err?.message || 'An unexpected error occurred'
        setItemErrorByIndexList(uploadingIndex, errorMessage)
      } finally {
        setUploadingIndex(uploadingIndex + 1)
      }
    }

    if (caseId !== null && uploadingIndex < length) {
      const item = list[uploadingIndex] as FileHistoryProps

      if (item.error === '' && item.file !== null) {
        fetchData(item.file)
      } else {
        setUploadingIndex(uploadingIndex + 1) // next
      }
    }
  }, [list, uploadingIndex])

  return (
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table aria-label="custom pagination table">
          <TableBody>
            {list.map((item, index) => {
              const { name, size, status, error, isHidden } = item

              const isLoading =
                isExternalLoading ||
                (caseId !== null && status === null && error === '')

              if (isHidden) return null
              return (
                <TableRow key={`fileViewer-item-${index}`}>
                  <TableCell style={{ width: 20 }}>
                    <InsertDriveFileOutlinedIcon fontSize="small" />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Box sx={{ width: '100%', mr: 1 }}>
                      <Typography variant="subtitle1" color="text.secondary">
                        {name}
                        <Typography
                          variant="caption"
                          sx={{ ml: 1 }}
                          color={error === '' ? 'text.secondary' : 'error'}
                        >
                          ({getMessage(error, status, size)})
                        </Typography>
                      </Typography>
                    </Box>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      {isLoading && <LinearProgress />}
                    </Box>
                  </TableCell>
                  <TableCell
                    align="right"
                    padding="checkbox"
                    style={{ width: 25, marginRight: '5px' }}
                  >
                    <IconButton
                      aria-label="delete"
                      onClick={async () => {
                        const newListWithErrors: FileHistoryProps[] =
                          await deleteIndexList(index)
                        onAddExternal(
                          newListWithErrors
                            .filter(fileItem => fileItem.file !== null)
                            .map(fileItem => fileItem.file) as File[],
                          true
                        )
                      }}
                      disabled={isLoading}
                    >
                      <CloseOutlinedIcon sx={{ fontSize: '16px' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

export default FilesUploadingList

import { getImagePath } from '@/utils'

import { IWaterfallProfile } from '@/pages/Case/components/OwnerCaseButtons/OwnerCaseButtons'
import { IMAGE_SIZES, USER_ROLES } from '@/constants'
import { isQualifiedMembership } from '@/utils/membership'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'

interface IProspectAttorneyData {
  attorney_id: {
    id: string
    user_id: {
      avatar: {
        id: string
      }
    }
    is_vetted: boolean
    membership_type: {
      id: string
    }
  }
  first_name: string
  last_name: string
}

interface ICaseManager {
  id: string
  user_id: {
    role: {
      id: string
    }
    first_name: string
    last_name: string
    id: string
    avatar: {
      id: string
    }
  }
}

export const getCaseManagerList = (ownerships: ICaseManager[]) =>
  ownerships
    .filter(
      (caseManager: ICaseManager) =>
        get(caseManager, 'user_id.role.id', '') === USER_ROLES.CaseManagerUser
    )
    .map((caseManager: ICaseManager) => ({
      ...caseManager.user_id,
      ownership_id: caseManager.id,
      avatarSrc: getImagePath({
        id: caseManager.user_id?.avatar?.id,
        size: IMAGE_SIZES.Small,
      }),
    }))

export const waterfallToUser = (
  waterfallId: IProspectAttorneyData
): IWaterfallProfile | undefined => {
  if (
    !isUndefined(waterfallId) &&
    !isEmpty(waterfallId) &&
    !!waterfallId?.attorney_id?.id
  ) {
    const attorneyId = waterfallId?.attorney_id || {}
    return {
      id: attorneyId?.id,
      firstName: waterfallId?.first_name,
      lastName: waterfallId?.last_name,
      avatar: attorneyId?.user_id?.avatar?.id,
      isVetted: attorneyId?.is_vetted,
      isQualified: isQualifiedMembership(attorneyId?.membership_type?.id),
      organization: get(
        attorneyId,
        'user_id.current_organization[0].organization_id.profiles[0].name',
        ''
      ),
    }
  }

  return undefined
}

import { FC, useEffect, useCallback } from 'react'

import { useNavigate } from 'react-router-dom'
import { RoutesData } from '@/router/RoutesData'
import { Typography } from '@mui/material'
import { useSignOutMutation } from '@/gql/systemApi'
import { useApolloClient, gql } from '@apollo/client'
import { useStreamChat } from '@/hooks/useStreamChat'
import {
  SessionKey,
  sessionStorageService,
} from '@/services/SessionStorage/SessionStorageService'

const DEFAULT_ERROR = 'There was an error logging out.'

const SignOutPage: FC = () => {
  const { disconnectUser } = useStreamChat()
  const client = useApolloClient()
  const onError = useCallback(() => {
    const errors = { base: DEFAULT_ERROR }
    return {
      baseErrors: errors,
    }
  }, [])
  const navigate = useNavigate()
  const [signOut] = useSignOutMutation({
    onError,
  })

  useEffect(() => {
    const handleLogOut = async () => {
      const storedRefreshToken =
        sessionStorageService.getItem(SessionKey.REFRESH_TOKEN) || ''
      await signOut({ variables: { refreshToken: storedRefreshToken } })
      disconnectUser()
      await client.clearStore()
      client.writeQuery({
        query: gql`
          query UpdateLoggedIn {
            isLoggedIn
          }
        `,
        data: {
          isLoggedIn: false,
        },
      })

      sessionStorageService.clear()
      navigate(RoutesData.RSignIn.path)
    }

    handleLogOut()
  }, [client, disconnectUser, navigate, signOut])

  return <Typography>Logging out...</Typography>
}

export default SignOutPage

import { PROCESS_STAGES_BY_NAME } from '@/constants'
import JStatusPill from '@/components/atoms/JStatusPill'
import { MarketplaceFilter } from './types'

export const ALGOLIA_DEBOUNCE_TIME_MS = 250
export const ALGOLIA_STARTING_ROWS_PER_PAGE = 25

export const ALGOLIA_HITS_PER_PAGE_OPTIONS = [
  ALGOLIA_STARTING_ROWS_PER_PAGE,
  50,
  100,
]

export const marketplaceFilters: MarketplaceFilter[] = [
  {
    attribute: 'catalog_practice_area',
    displayName: 'Practice Area',
    showOnDesktop: true,
    showInModal: false,
    showOnMobile: true,
  },
  {
    attribute: 'catalog_us_state_name',
    displayName: 'State',
    showOnDesktop: true,
    showInModal: false,
    showOnMobile: true,
  },
  {
    attribute: 'catalog_process_stage',
    displayName: 'Process Stage',
    showOnDesktop: true,
    showInModal: false,
    showOnMobile: true,
    renderOption: (option: { label: string }) => (
      <JStatusPill
        id={PROCESS_STAGES_BY_NAME[option.label] || ''}
        name={option.label}
      />
    ),
  },
]

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Card from '@mui/material/Card'

import JSnackBar from '@/components/atoms/JSnackBar'
import useTheme from '@mui/material/styles/useTheme'
import { ParameterName, bootParamsService } from '@/services/BootParams'
import { TError } from '@/utils/api/types'
import { SetupResponse } from '@/utils/api/integrations/types'
import { useSnackBar } from '@/hooks/useSnackBar'
import useLawmaticsIntegrationItem from './useLawmaticsIntegrationItem'
import { IntegrationItemProps } from '../../types'

import textContent from '../../constants'

import IntegrationItemCardContent from '../shared/IntegrationItemCardContent'
import { checkAndHandleErrors, isSetupSuccessful } from '../shared/utils'
import IntegrationItemButtons from '../shared/IntegrationItemButtons'

const LawmaticsIntegrationItem = ({
  icon,
  name,
  subtitle,
  loading,
  organizationId,
  isConnectedToOtherLcms,
  isConnected,
  refetchLcmsData,
  removeIntegrationItem,
  isConnectingToLcms,
  connectingToLcms,
}: IntegrationItemProps) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const {
    INTEGRATION_V2: { LAWMATICS },
  } = textContent
  const codeQueryParam = bootParamsService.get(ParameterName.LAWMATICS_CODE)
  const srcQueryParam = bootParamsService.get(ParameterName.SRC)

  const [lawmaticsRemoveStatus, setLawmaticsRemoveStatus] = useState(false)
  const lawMaticsName = LAWMATICS.NAME.toLowerCase()
  const [connectLawmatics, setConnectLawmatics] = useState(
    Boolean(
      codeQueryParam &&
        organizationId !== '' &&
        String(srcQueryParam) === lawMaticsName
    )
  )

  const {
    connectLawmaticsIntegration,
    connectLawmaticsIntegrationData,
    removeLawmaticsData,
    removeLawmaticsIsLoading,
  }: {
    connectLawmaticsIntegration: () => void
    connectLawmaticsIntegrationData: SetupResponse | TError | null
    removeLawmaticsData: SetupResponse | TError | null
    removeLawmaticsIsLoading: boolean
  } = useLawmaticsIntegrationItem({
    code: String(codeQueryParam),
    connectLawmatics,
    lawmaticsRemoveStatus,
    organizationId,
  })

  const {
    alert: lawmaticsAlert,
    showAlert: lawmaticsShowAlert,
    handleClose: lawmaticsHandleClose,
  } = useSnackBar()

  const shouldRenderLoadingButton =
    removeLawmaticsIsLoading || loading || isConnectingToLcms

  const shouldRenderDisconnectButton =
    (isConnected && !lawmaticsRemoveStatus && !removeLawmaticsIsLoading) ||
    removeLawmaticsData?.statusCode === 404 ||
    removeLawmaticsData?.statusCode === 500

  // Navigate to the current window location path clearing the query params provided,
  // replacing the current entry in the browser's history.
  useEffect(() => {
    if (window.location.search !== '') {
      navigate(window.location.pathname, { replace: true })
    }
  }, [navigate])

  // Trigger connection
  useEffect(() => {
    if (
      codeQueryParam &&
      organizationId !== '' &&
      srcQueryParam === lawMaticsName
    ) {
      connectingToLcms(true)
      setConnectLawmatics(true)
    }
  }, [
    lawMaticsName,
    codeQueryParam,
    connectingToLcms,
    organizationId,
    srcQueryParam,
  ])

  // Monitors the LCMs setup process.
  useEffect(() => {
    if (
      isSetupSuccessful(connectLawmaticsIntegrationData) &&
      !lawmaticsRemoveStatus &&
      !isConnected
    ) {
      connectingToLcms(false)
      setConnectLawmatics(false)
      bootParamsService.remove(ParameterName.LAWMATICS_CODE)
      bootParamsService.remove(ParameterName.SRC)
      refetchLcmsData()
    }
  }, [
    connectLawmaticsIntegrationData,
    lawmaticsRemoveStatus,
    isConnected,
    refetchLcmsData,
    connectingToLcms,
  ])

  // Handles errors during Lawmatics integration setup or removal process
  // Check if the current organization is not connected to other LCMs
  useEffect(() => {
    const hasError = checkAndHandleErrors({
      connectIntegrationData: connectLawmaticsIntegrationData,
      removeData: removeLawmaticsData,
      isConnectedToOtherLcms: Boolean(isConnectedToOtherLcms),
      showAlert: lawmaticsShowAlert,
      setConnect: setConnectLawmatics,
    })
    if (hasError) {
      connectingToLcms(false)
      bootParamsService.remove(ParameterName.LAWMATICS_CODE)
      bootParamsService.remove(ParameterName.SRC)
    }
  }, [
    connectLawmaticsIntegrationData,
    isConnectedToOtherLcms,
    lawmaticsShowAlert,
    removeLawmaticsData,
    connectingToLcms,
  ])

  // Handle disconnection from other LCMs
  useEffect(() => {
    if (isConnected && removeLawmaticsData?.message === 'deleted') {
      setLawmaticsRemoveStatus(false)
      refetchLcmsData()
    }
  }, [
    isConnected,
    setLawmaticsRemoveStatus,
    removeLawmaticsData,
    refetchLcmsData,
  ])

  const removeLawmaticsIntegration = () => {
    removeIntegrationItem({
      integrationName: LAWMATICS.NAME,
      onSubmit: () => {
        setLawmaticsRemoveStatus(true)
        setConnectLawmatics(false)
      },
    })
  }

  return (
    <Card
      sx={{
        margin: theme.spacing(0, 0, 4),
        padding: theme.spacing(2),
        boxShadow: 2,
      }}
    >
      <IntegrationItemCardContent
        actions={
          <IntegrationItemButtons
            shouldRenderLoadingButton={shouldRenderLoadingButton}
            shouldRenderDisconnectButton={shouldRenderDisconnectButton}
            disconnectLcms={() => {
              removeLawmaticsIntegration()
            }}
            connectLcms={connectLawmaticsIntegration}
            isConnectedToOtherLcms={isConnectedToOtherLcms}
          />
        }
        icon={icon}
        name={`${isConnected ? 'Connected to ' : ''}${name}`}
        subtitle={subtitle}
      />
      <JSnackBar
        vertical="bottom"
        open={lawmaticsAlert.open}
        severity={lawmaticsAlert.severity}
        message={lawmaticsAlert.message}
        handleClose={lawmaticsHandleClose}
      />
    </Card>
  )
}

export default LawmaticsIntegrationItem

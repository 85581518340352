import request from '@/axios/config'
import { useCallback } from 'react'

type TValidateToken = {
  tokenToValidate: string
  type: string
}
export type TTokenValidationResponse = {
  email: string
  role: string
  organization: string
  iat: number
  exp: number
}

export const useTokenValidation = () => {
  const validateToken = useCallback(
    async ({
      tokenToValidate,
      type,
    }: TValidateToken): Promise<TTokenValidationResponse | null> => {
      const payload = {
        token: tokenToValidate,
        type,
      }

      try {
        const response = await request.post('/token/verify', payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        return response.data
      } catch (error) {
        return null
      }
    },
    []
  )

  const isTokenExpired = useCallback((expiration: number) => {
    const currentTimeInSeconds = Math.floor(Date.now() / 1000)
    return currentTimeInSeconds >= expiration
  }, [])

  return { validateToken, isTokenExpired }
}

import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { useSearchBox, useStats } from 'react-instantsearch'
import { motion } from 'framer-motion'
import { useAlgoliaMarketplaceContext } from '../context/useAlgoliaMarketplaceContext'

const NoResultsDisplay: React.FC = () => {
  const { query } = useSearchBox()
  const { nbHits } = useStats()
  const { handleClearFilters } = useAlgoliaMarketplaceContext()

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <Box textAlign="center" p={4}>
        <Typography variant="h5" gutterBottom>
          No cases found with the selected filters
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          {query
            ? `We couldn't find any results for "${query}". Please try a different search term or adjust your filters.`
            : 'Please change your settings to view available cases.'}
        </Typography>
        {nbHits === 0 && (
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={handleClearFilters}
          >
            Clear All Filters
          </Button>
        )}
      </Box>
    </motion.div>
  )
}

export default NoResultsDisplay

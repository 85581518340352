import React from 'react'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  SubmitHandler,
  useForm,
  Controller,
  FieldValues,
} from 'react-hook-form'
import { Typography, Link } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'

import JTextField from '@/components/atoms/JTextField'
import { ModalTypes } from '@/types'
import { TERMS_PRIVACY_LINKS } from '@/constants'
import ConfirmationModal, {
  ConfirmationModalProps as IConfirmationModalProps,
} from '../ConfirmationModal'
import {
  CaseInformation,
  CaseInformationProps as ICaseConfirmationProps,
} from '../../CaseInformation'

export interface SendProposalModalProps {
  ConfirmationModalProps: Omit<IConfirmationModalProps, 'children'>
  CaseInformationProps: ICaseConfirmationProps
  type: ModalTypes
  onSubmit: (form: any) => void
  isCaseOwner: boolean
  isInitiatingAttorney: boolean
}

const MatchFeesModal = ({
  ConfirmationModalProps,
  CaseInformationProps,
  type,
  onSubmit,
  isCaseOwner,
  isInitiatingAttorney,
}: SendProposalModalProps) => {
  const theme = useTheme()
  const [termsAccepted, setTermAccepted] = React.useState(false)

  React.useEffect(() => {
    setTermAccepted(type !== 'Accept')
  }, [type])

  const { open, AcceptButtonProps, title, description, ...modalRest } =
    ConfirmationModalProps

  const { control, handleSubmit } = useForm({
    defaultValues: {
      fee: CaseInformationProps.fee ?? '',
      name: '',
      negotiateFee: '',
      offer: '',
      message: '',
      termsAndCondition: false,
    },
  })

  const handleSubmitModal: SubmitHandler<FieldValues> = form => {
    onSubmit(form)
  }

  return (
    <ConfirmationModal
      title={title}
      description={description}
      open={open}
      AcceptButtonProps={{
        ...AcceptButtonProps,
        disabled: !termsAccepted,
        onClick: handleSubmit(handleSubmitModal),
      }}
      {...modalRest}
    >
      {type !== 'Accept' && <CaseInformation {...CaseInformationProps} />}
      <Box
        sx={{
          paddingTop: 1,
          display: 'flex',
          gap: 1,
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        {type === 'Counteroffer' && (
          <>
            <Typography variant="subtitle2" mb={1}>
              Make your offer
            </Typography>
            <Box display="flex" alignItems="center" gap={2} component="label">
              <JTextField
                type="number"
                sx={{ maxWidth: 140, minWidth: 120 }}
                name="fee"
                control={control}
                inputProps={{
                  max: 100,
                  min: 0,
                }}
                rules={{
                  validate: (fee: number | string | boolean) => {
                    const feeNumber = Number(fee)
                    return feeNumber >= 0 && feeNumber <= 100
                  },
                }}
                placeholder="Proposed Fees"
                label="Proposed Fees"
                endAdornment="%"
              />
              <Typography
                variant="caption"
                color={theme.palette.text.secondary}
                mb={3}
              >
                {isCaseOwner ? (
                  <>
                    If your counteroffer is accepted, they&apos;ll receive the
                    client&apos;s contact info and we will instruct them to
                    reach out to the potential client as soon as possible.
                  </>
                ) : (
                  <>
                    If your proposal is accepted, we&apos;ll share the
                    client&apos;s contact information and you should reach out
                    to them as soon as possible.
                  </>
                )}
              </Typography>
            </Box>
          </>
        )}
        {type === 'Accept' && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControlLabel
              control={
                <Controller
                  name="termsAndCondition"
                  control={control}
                  render={({ field: { onChange, ...props } }) => (
                    <Checkbox
                      {...props}
                      onChange={(event, checked) => {
                        setTermAccepted(checked)
                        onChange(event, checked)
                      }}
                    />
                  )}
                />
              }
              sx={{
                alignItems: 'flex-start',
              }}
              label={
                <Box
                  sx={{
                    fontSize: '0.5rem',
                    lineHeight: '0.75rem',
                  }}
                >
                  <Typography
                    component="span"
                    variant="caption"
                    lineHeight="16.55px"
                  >
                    Attorney Share does not vet or certify the lawyers to whom
                    you may refer a case. I understand that it is my duty to
                    evaluate any lawyer to whom I refer a matter. I further
                    understand that it is solely my duty to ensure compliance
                    with my jurisdiction&apos;s Rules of Professional Conduct
                    and other ethical standards in advertising, making a
                    referral, entering into a referral or co-counsel
                    relationship, engaging in fee sharing, and conducting the
                    representation. Attorney Share is a platform that
                    facilitates attorneys connecting with other attorneys and
                    does not advise or provide guidance on such issues. This is
                    not a client to attorney referral service. By checking this
                    box, I certify and warrant that I understand the above and
                    will adhere to my duties accordingly. I agree to the{' '}
                    <Link
                      href={TERMS_PRIVACY_LINKS.terms}
                      display="inline"
                      target="_blank"
                    >
                      Terms and Conditions
                    </Link>{' '}
                    outlined in our{' '}
                    <Link
                      href={TERMS_PRIVACY_LINKS.privacy}
                      display="inline"
                      target="_blank"
                    >
                      Privacy Policy
                    </Link>
                    .
                  </Typography>
                </Box>
              }
            />
          </Box>
        )}
        {isInitiatingAttorney && !isCaseOwner && (
          <Alert icon={false} severity="warning" sx={{ mt: 2 }}>
            <Typography variant="body2">
              You will not be charged match fees, if applicable, unless you sign
              the client. You have 30 days to indicate the client was signed or
              not signed or you will be charged.
            </Typography>
          </Alert>
        )}
      </Box>
    </ConfirmationModal>
  )
}

export default MatchFeesModal

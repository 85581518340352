import { useAttorney_StripeLazyQuery } from '@/gql/appApi'
import Stripe from 'stripe'
import { getMyNetworkFirstLink } from '@/pages/MyNetwork/utils/constants'
import { RoutesData } from '@/router/RoutesData'
import { useLocation } from 'react-router-dom'
import useAttorneyId from './useAttorneyId'
import useAttorneyEmail from './useAttorneyEmail'

export const stripe = new Stripe(
  import.meta.env.VITE_STRIPE_SECRET_KEY as string,
  {
    // https://github.com/stripe/stripe-node#configuration
    apiVersion: '2022-11-15',
  }
)

export enum PRICES {
  owner = 'VITE_STRIPE_CASE_OWNER_PRICE_ID',
  prospect = 'VITE_STRIPE_CASE_PROSPECT_PRICE_ID',
  subscription = 'VITE_STRIPE_MEMBERSHIP_PRICE_ID',
}

export type PricesType = 'owner' | 'prospect' | 'subscription'

export interface ICheckoutSessionParam {
  user_id: string
  attorney_id: string
  case_purchase_id: string
  customer_email: string
  price_type?: PricesType
  stripePriceId?: string
  discounts?: string[]
  queryParam?: string
  customSuccessUrl?: string
  customCancelUrl?: string
}

export interface ICreditCardCaptureSessionParam {
  attorney_id: string
  case_purchase_id: string
  customer_email: string
  customCancelUrl?: string
  customSuccessUrl?: string
  queryParam?: string
  user_id: string
}

export interface ICheckoutSessionSubscriptionParam {
  attorney_id: string
  customer_email: string
  price_type: PricesType
  redirect_url?: string
}

export const useStripe = () => {
  const [getAttorneyStripe] = useAttorney_StripeLazyQuery()
  const { getAttorneyId } = useAttorneyId()
  const { getAttorneyEmail } = useAttorneyEmail()
  const attorneyEmail = getAttorneyEmail()
  const location = useLocation()

  const getStripeCustomerId = async (attorneyId: string) => {
    const { data } = await getAttorneyStripe({
      variables: {
        filter: {
          attorney_id: {
            id: {
              _eq: attorneyId,
            },
          },
        },
      },
    })

    const stripeCustomerId =
      data?.attorney_stripe?.[0]?.stripe_customer_id ?? undefined

    return stripeCustomerId
  }

  const createCheckoutSession = async ({
    attorney_id,
    user_id,
    case_purchase_id,
    customer_email,
    price_type,
    stripePriceId,
    discounts,
    queryParam,
    customSuccessUrl,
    customCancelUrl,
  }: ICheckoutSessionParam) => {
    const stripeCustomerId = await getStripeCustomerId(attorney_id)

    const urlBase = `${window.location.protocol}//${window.location.host}`

    const successUrl =
      customSuccessUrl ||
      `${urlBase}${RoutesData.RMyReferrals.path}${queryParam || ''}`

    const cancelUrl =
      customCancelUrl || `${urlBase}${RoutesData.RMyReferrals.path}`

    const stripeDiscounts: Stripe.Checkout.SessionCreateParams.Discount[] =
      discounts?.map(discount => ({
        coupon: discount,
      })) ?? []

    const checkoutSession = await stripe.checkout.sessions.create({
      line_items: [
        {
          price:
            stripePriceId ||
            (price_type ? import.meta.env[PRICES[price_type]] : ''), // For other uses of checkout session.
          quantity: 1,
        },
      ],
      discounts: stripeDiscounts,
      mode: 'payment',
      client_reference_id: attorney_id,
      metadata: {
        case_purchase_id,
        user_id,
      },
      payment_intent_data: {
        capture_method: 'automatic',
        ...(!stripeCustomerId && {
          setup_future_usage: 'on_session',
        }),
      },
      success_url: successUrl,
      cancel_url: cancelUrl,
      customer: stripeCustomerId,
      ...(!stripeCustomerId && {
        customer_creation: 'always',
        customer_email,
      }),
    })

    if (!checkoutSession.url) {
      throw new Error(`checkoutSession.url is invalid`)
    }

    window.location.href = checkoutSession.url
  }

  const createCreditCardCaptureSession = async ({
    attorney_id,
    case_purchase_id,
    customer_email,
    customCancelUrl,
    customSuccessUrl,
    queryParam,
    user_id,
  }: ICreditCardCaptureSessionParam) => {
    const stripeCustomerId = await getStripeCustomerId(attorney_id)

    const urlBase = `${window.location.protocol}//${window.location.host}`

    const successUrl =
      customSuccessUrl ||
      `${urlBase}${RoutesData.RMyReferrals.path}${queryParam || ''}`

    const cancelUrl =
      customCancelUrl || `${urlBase}${RoutesData.RMyReferrals.path}`

    const checkoutSession = await stripe.checkout.sessions.create({
      mode: 'setup',
      payment_method_types: ['card'],
      client_reference_id: attorney_id,
      metadata: {
        case_purchase_id,
        user_id,
      },
      setup_intent_data: {
        metadata: {
          case_purchase_id,
          user_id,
          client_reference_id: attorney_id,
        },
      },
      success_url: successUrl,
      cancel_url: cancelUrl,
      customer: stripeCustomerId,
      ...(!stripeCustomerId && {
        customer_creation: 'always',
        customer_email,
      }),
    })

    if (!checkoutSession.url) {
      throw new Error(`checkoutSession.url is invalid`)
    }

    window.location.href = checkoutSession.url
  }

  const createCheckoutSubscriptionSession = async ({
    attorney_id,
    customer_email,
    price_type,
    redirect_url,
  }: ICheckoutSessionSubscriptionParam) => {
    const stripeCustomerId = await getStripeCustomerId(attorney_id)

    const urlBase = `${window.location.protocol}//${window.location.host}`
    const checkoutSession: Stripe.Checkout.Session =
      await stripe.checkout.sessions.create({
        line_items: [
          {
            price: import.meta.env[PRICES[price_type]],
            quantity: 1,
          },
        ],
        mode: 'subscription',
        client_reference_id: attorney_id,
        success_url: `${urlBase}${redirect_url || getMyNetworkFirstLink()}`,
        cancel_url: `${urlBase}${redirect_url || getMyNetworkFirstLink()}`,
        customer_email,
        customer: stripeCustomerId,
        payment_intent_data: {
          ...(!stripeCustomerId && {
            setup_future_usage: 'on_session',
          }),
        },
        ...(!stripeCustomerId && {
          customer_creation: 'always',
        }),
      })

    if (!checkoutSession.url) {
      throw new Error(`checkoutSession.url is invalid`)
    }

    window.location.href = checkoutSession.url
  }

  const applyForMembership = async (redirectUrl?: string) => {
    await createCheckoutSubscriptionSession({
      attorney_id: getAttorneyId() || '',
      price_type: 'subscription',
      customer_email: attorneyEmail || '',
      redirect_url: redirectUrl || location.pathname,
    })
  }

  return {
    createCheckoutSession,
    createCreditCardCaptureSession,
    createCheckoutSubscriptionSession,
    applyForMembership,
  }
}

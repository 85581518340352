import Autocomplete from '@mui/material/Autocomplete'

import { Attorney_Profile } from '@/gql/appApi'
import Search from '@/components/Search'
import JDropdownUser from '@/components/atoms/JDropdownUser'
import { getImagePath } from '@/utils'
import { isQualifiedMembership } from '@/utils/membership'
import { IMAGE_SIZES } from '@/constants'
import useAttorneySearch from './hooks/useAttorneySearch'

export interface AttorneySearchProps {
  placeholder?: string
  id?: string
  label: string
  onChange: (value: Attorney_Profile | null) => void
  value: Attorney_Profile | null
  /** if an attorney exist here, it shouldn't appear in the list to select it */
  attorneysSelected?: Attorney_Profile[]
}

const AttorneySearch = ({
  placeholder = 'No one is selected',
  id,
  label,
  onChange,
  value = null,
  attorneysSelected = [],
}: AttorneySearchProps) => {
  const {
    attorneyProfile,
    setAttorney,
    filteredOptions,
    loading,
    filterOptions,
    searchTerm,
    setSearchTerm,
  } = useAttorneySearch({
    attorneyValue: value,
    attorneysSelected,
  })

  return (
    <Autocomplete
      options={filteredOptions}
      loading={loading}
      value={attorneyProfile}
      getOptionLabel={(att: Attorney_Profile) =>
        `${att.first_name} ${att.last_name} (${att.id})`
      }
      filterOptions={filterOptions}
      onChange={(_, newAttorney) => {
        setAttorney(newAttorney)
        if (newAttorney) {
          setSearchTerm(`${newAttorney.first_name} ${newAttorney.last_name}`)
        } else {
          setSearchTerm('')
        }
        onChange(newAttorney)
      }}
      isOptionEqualToValue={(option, v) => option.id === v.id}
      renderOption={(props, option) => (
        <JDropdownUser
          key={option.id}
          user={{
            id: option.id,
            name: `${option.first_name} ${option.last_name}`,
            picture: getImagePath({
              id: option?.attorney_id?.user_id?.avatar?.id || null,
              size: IMAGE_SIZES.Small,
            }),
            organization:
              option?.attorney_id?.user_id?.current_organization?.[0]
                ?.organization_id?.profiles?.[0]?.name || '',
            isVetted: option?.attorney_id?.is_vetted || false,
            isQualified: isQualifiedMembership(
              option?.attorney_id?.membership_type?.id
            ),
          }}
          {...props}
        />
      )}
      renderInput={params => {
        const { InputProps, inputProps, ...other } = params
        return (
          <Search
            {...other}
            InputProps={{
              ...InputProps,
              inputProps: {
                ...inputProps,
                value: searchTerm,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchTerm(e.target.value)
                },
              },
            }}
            placeholder={placeholder}
            id={id}
            label={label}
            variant="outlined"
          />
        )
      }}
    />
  )
}

export default AttorneySearch

import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme } from '@mui/material/styles'
import { Children } from 'react'
import Toolbar from '@mui/material/Toolbar'
import Layout from '../Layout'

interface IContentCaseLayout {
  children: React.ReactNode
  hideBottomBar?: boolean
}

const SideBar = styled(Grid)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    width: '100%',
    display: 'block',
  },
}))

const WrapperAppBar = styled(AppBar)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    top: 'auto',
    bottom: 0,
    padding: 0,
  },
}))

const ContentCaseLayout: React.FC<IContentCaseLayout> = ({
  children,
  hideBottomBar = false,
}: IContentCaseLayout) => {
  const theme = useTheme()
  const arrayChildren = Children.toArray(children)
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
  const singleChild = arrayChildren.length === 1
  const showBottomBar = arrayChildren.length > 1 && !hideBottomBar

  return (
    <Layout skipBottomBar={!isLargeScreen}>
      <Container maxWidth="lg" sx={{ paddingTop: '2.5rem' }}>
        <Grid container spacing={2}>
          {singleChild ? (
            <Grid item xs={12}>
              {arrayChildren[0]}
            </Grid>
          ) : (
            <>
              <Grid item xs={isLargeScreen ? 8 : 12}>
                {arrayChildren[0]}
              </Grid>
              <SideBar item xs={isLargeScreen ? 4 : 0}>
                {arrayChildren[1]}
              </SideBar>
            </>
          )}
        </Grid>
      </Container>
      {showBottomBar && (
        <WrapperAppBar color="transparent" position="fixed">
          <Toolbar sx={{ padding: 0 }}>{arrayChildren[1]}</Toolbar>
        </WrapperAppBar>
      )}
      <Toolbar />
    </Layout>
  )
}

export default ContentCaseLayout

import AuthLayout from '@/components/shared/AuthLayout'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useSizes } from '@/hooks/useSizes'
import LogoBar from '@/pages/CreateAccount/shared/LogoBar'
import { useMemo } from 'react'
import Skeleton from '@mui/material/Skeleton'

type VerificationSuccessProps = {
  onGetMembership: () => void
  licensedAccount?: boolean
  loading?: boolean
}

const VerificationSuccess = ({
  onGetMembership,
  licensedAccount,
  loading,
}: VerificationSuccessProps) => {
  const { isLessMd } = useSizes()

  const LicenseContent = useMemo(
    () => (
      <>
        <Typography
          variant="subtitle1"
          sx={{ textAlign: 'left', pl: { xs: 2, md: 0 } }}
        >
          <b>
            To get the most out of your experience, please complete your
            profile.
          </b>
        </Typography>
        <Typography
          variant="body1"
          sx={{ textAlign: 'left', mt: 2, pl: { xs: 2, md: 0 } }}
        >
          Completing your profile is an important step to highlight your areas
          of expertise and help you stand out from others. Keep in mind that you
          can always complete these details at a later time.{' '}
          <strong>It only takes a few minutes.</strong>
        </Typography>
      </>
    ),
    []
  )

  const NoLicenseContent = useMemo(
    () => (
      <Typography
        variant="body1"
        sx={{ textAlign: 'left', pl: { xs: 2, md: 0 } }}
      >
        Please click continue below.
      </Typography>
    ),
    []
  )

  const btnText = licensedAccount ? 'Complete Profile' : 'Go to Admin Panel'

  return (
    <AuthLayout>
      <LogoBar />
      <Box
        sx={theme => ({
          marginTop: {
            xs: 0,
          },
          maxWidth: {
            md: theme.spacing(74),
          },
          height: '100%',
          overflow: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
        })}
      >
        {loading ? (
          <>
            <Skeleton variant="rounded" height={80} />
            <Skeleton variant="rounded" height={250} sx={{ mt: 2 }} />
            <Skeleton
              variant="rounded"
              width={290}
              height={60}
              sx={{ mt: 2, ml: 'auto' }}
            />
          </>
        ) : (
          <>
            <Typography
              variant={isLessMd ? 'h6' : 'h5'}
              sx={{ mb: 4, textAlign: 'left', pl: { xs: 2, md: 0 } }}
            >
              Your account has been verified successfully
            </Typography>
            {licensedAccount ? LicenseContent : NoLicenseContent}
            <Box
              sx={{
                width: '100%',
                mt: { xs: 5, md: 10 },
                display: 'flex',
                justifyContent: {
                  xs: 'center',
                  md: 'flex-end',
                },
              }}
            >
              <Button
                onClick={onGetMembership}
                size="large"
                variant="contained"
                sx={{ width: { xs: '100%', md: '50%' } }}
              >
                {btnText}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </AuthLayout>
  )
}

export default VerificationSuccess

import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.between('xs', 'md')]: {
    display: 'none',
  },
}))

export default Title

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { CaseInformation } from '@/components'
import { IJUser } from '@/components/atoms/JUser/types'
import { ModalTypes } from '@/types'
import { useSizes } from '@/hooks/useSizes'
import CaseMessageButton from '@/components/GetStreamChat/CaseMessageButton'
import { useFeatureFlags } from '@/optimizely/useFeatureFlags'
import { FeatureFlag } from '@/optimizely/types'
import { ISidebarProps } from '../Sidebar/CaseSidebar/types'

export interface PrivateCasesButtonsProps {
  caseActivity: ISidebarProps['caseActivity']
  isAttorneyLastActivity: boolean
  setGenericOpenModal: React.Dispatch<React.SetStateAction<ModalTypes>>
  profile: IJUser | undefined
  loading?: boolean
  attorneyTitle?: string
  fee: number
}

const PrivateCasesButtons = ({
  caseActivity,
  isAttorneyLastActivity,
  setGenericOpenModal,
  profile,
  loading,
  attorneyTitle,
  fee,
}: PrivateCasesButtonsProps) => {
  const { isLessMd } = useSizes()
  const featureFlags = useFeatureFlags()
  const isNegotiationFlowImprovementEnabled =
    featureFlags[FeatureFlag.NEGOTIATION_FLOW_IMPROVEMENTS]

  return caseActivity?.length === 1 ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 1.5,
      }}
    >
      {!isLessMd && (
        <CaseInformation
          profile={profile}
          attorneyTitle={attorneyTitle}
          {...(isNegotiationFlowImprovementEnabled && { fee })}
        />
      )}
      <Button
        size="large"
        fullWidth
        variant="contained"
        onClick={() => {
          setGenericOpenModal('Accept')
        }}
        disabled={loading}
      >
        Accept Proposal
      </Button>
      <Button
        size="large"
        variant="outlined"
        fullWidth
        disabled={isAttorneyLastActivity || loading}
        onClick={() => {
          setGenericOpenModal('Counteroffer')
        }}
      >
        Send Counteroffer
      </Button>
      <Button
        variant="text"
        fullWidth
        size="large"
        onClick={() => {
          setGenericOpenModal('Reject')
        }}
        disabled={loading}
        color="error"
      >
        Decline Proposal
      </Button>
      {!isLessMd && (
        <CaseMessageButton variant="outlined" fullWidth size="large" />
      )}
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 1.5,
      }}
    >
      {!isLessMd && (
        <CaseInformation
          profile={profile}
          attorneyTitle={attorneyTitle}
          {...(isNegotiationFlowImprovementEnabled && { fee })}
        />
      )}
      <Button
        size="large"
        fullWidth
        variant="contained"
        disabled={isAttorneyLastActivity || loading}
        onClick={() => {
          setGenericOpenModal('Accept')
        }}
      >
        Accept Proposal
      </Button>
      {!isAttorneyLastActivity && (
        <Button
          size="large"
          variant="outlined"
          fullWidth
          disabled={isAttorneyLastActivity || loading}
          onClick={() => {
            setGenericOpenModal('Counteroffer')
          }}
        >
          Send Counteroffer
        </Button>
      )}
      {!isAttorneyLastActivity && (
        <Button
          size="large"
          variant="text"
          disabled={isAttorneyLastActivity || loading}
          fullWidth
          color="error"
          onClick={() => {
            setGenericOpenModal('Reject')
          }}
        >
          Decline Proposal
        </Button>
      )}
      {!isLessMd && (
        <CaseMessageButton variant="outlined" fullWidth size="large" />
      )}
    </Box>
  )
}

export default PrivateCasesButtons

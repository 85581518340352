import Grid from '@mui/material/Grid'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import { useSizes } from '@/hooks/useSizes'

import get from 'lodash/get'
import AddCaseManagerModal, {
  useAddCaseManagerModal,
} from '@/components/Modals/AddCaseManagerModal'
import CaseManagerList, { ICaseManagerItem } from './CaseManagerList'

interface IAllowCaseManagersProps {
  caseId: string
  collaborators: ICaseManagerItem[]
  loggedUserOrganizationId: string
  isFromSameOrganization?: boolean
  isCaseOwner?: boolean
  casePurchaseId?: string
}

const AllowCaseManagers = ({
  caseId,
  collaborators,
  loggedUserOrganizationId,
  isFromSameOrganization,
  isCaseOwner = false,
  casePurchaseId,
}: IAllowCaseManagersProps) => {
  const { isLessMd } = useSizes()
  const { setOpenModal, openModal } = useAddCaseManagerModal()
  const caseManagersListInMyOrg = collaborators.filter(
    col =>
      get(col, 'current_organization[0].organization_id.id') ===
      loggedUserOrganizationId
  )

  const hasCaseManagers = caseManagersListInMyOrg.length > 0

  const caption = isFromSameOrganization
    ? "There aren't Case Managers with permissions to act on this case"
    : "You don't have collaborators with permissions to act on your behalf"

  const handleOnClick = () => {
    setOpenModal(true)
  }

  return (
    <>
      <AddCaseManagerModal
        caseId={caseId}
        existingCaseManagers={caseManagersListInMyOrg}
        openModal={openModal}
        setOpenModal={setOpenModal}
        isCaseOwner={isCaseOwner}
        casePurchaseId={casePurchaseId}
      />
      <Grid
        container
        sx={{
          pt: {
            xs: 1,
            md: 0,
          },
        }}
      >
        <Grid item xs={12} justifyContent="center">
          {hasCaseManagers ? (
            <CaseManagerList
              isOwner={isCaseOwner}
              caseManagerList={caseManagersListInMyOrg}
            />
          ) : (
            <Typography
              variant="caption"
              display="block"
              color="text.disabled"
              sx={{
                padding: isLessMd ? '0' : '1rem 3rem',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100%',
              }}
            >
              {caption}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          justifyContent="center"
          sx={{ md: { marginTop: '1rem' } }}
        >
          <Button
            variant="text"
            size="large"
            fullWidth
            startIcon={<PersonAddAltIcon fontSize="small" />}
            onClick={handleOnClick}
          >
            Add Collaborators
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default AllowCaseManagers

import { useContext, useState } from 'react'
import { alpha, styled, useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import Button from '@mui/material/Button'
import { Controller } from 'react-hook-form'
import { FileHistoryProps } from '@/components/DataRoom/FilesUploader/types'
import FilesDragAndDropArea from '@/components/DataRoom/FilesUploader/FilesDragAndDropArea'
import FilesUploadingList from '@/components/DataRoom/FilesUploader/FilesUploadingList'
import { useSizes } from '@/hooks/useSizes'
import { CreateCaseContext } from '../../../CreateCaseContext/CreateCaseContext'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const FILE_LIMIT_100MB = 1e8

const CreateCaseFileUploader = () => {
  const theme = useTheme()
  const { isLessMd } = useSizes()
  const {
    isExternalLoading,
    methods: { control },
    onDataroomFilesChange,
  } = useContext(CreateCaseContext)

  const [dataFiles, setDataFiles] = useState<FileHistoryProps[]>([])

  const fileValidation = (file: File) => {
    if (file.size === 0 || file.size >= FILE_LIMIT_100MB) {
      return 'Invalid file size! Please select a file under 100MB.'
    }

    return true
  }

  const addFiles = (files: FileList) => {
    const tmpFilesArr: FileHistoryProps[] = []
    for (let i = 0; i < files.length; i += 1) {
      const tmpfile = files.item(i) as File
      const isValid = fileValidation(tmpfile)

      const fileObj = {
        file: isValid === true ? tmpfile : null,
        size: tmpfile?.size || 0,
        name: tmpfile?.name || '',
        isHidden: false,
        error: isValid === true ? '' : isValid,
        status: null,
      }

      tmpFilesArr.push(fileObj)
    }
    setDataFiles(tmpFilesArr)
    onDataroomFilesChange?.(
      tmpFilesArr
        .filter(item => item.file !== null)
        .map(item => item.file) as File[],
      false
    )
  }

  return (
    <Grid container gap={1}>
      <Controller
        name="dataRoomFile"
        control={control}
        defaultValue={null}
        render={({ field: { onBlur, ref } }) => (
          <>
            <FilesDragAndDropArea addFiles={addFiles}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    backgroundColor: '#F5F7FA',
                    minHeight: '150px',
                    p: 2,
                  }}
                >
                  {!isLessMd && (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '50%',
                        }}
                      >
                        <CloudUploadOutlinedIcon
                          sx={{ color: alpha(theme.palette.common.black, 0.6) }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            mt: 1,
                            color: alpha(theme.palette.common.black, 0.6),
                          }}
                        >
                          Drag and drop files here
                        </Typography>
                      </Box>
                      <Divider orientation="vertical" flexItem>
                        <Typography
                          variant="caption"
                          sx={{
                            color: alpha(theme.palette.common.black, 0.6),
                          }}
                        >
                          or
                        </Typography>
                      </Divider>
                    </>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: {
                        xs: '100%',
                        md: '50%',
                      },
                    }}
                  >
                    <Button
                      component="label"
                      variant="outlined"
                      sx={{ width: '120px' }}
                    >
                      Browse Files
                      <VisuallyHiddenInput
                        type="file"
                        multiple
                        onChange={e => {
                          const files = e.target.files || null
                          if (files !== null) {
                            addFiles(files)
                            e.target.value = ''
                          }
                        }}
                        onBlur={onBlur}
                        ref={ref}
                      />
                    </Button>
                    <Typography
                      variant={!isLessMd ? 'caption' : 'body2'}
                      sx={{
                        mt: 1,
                        color: alpha(theme.palette.common.black, 0.6),
                      }}
                    >
                      {' '}
                      Maximum file size is 100MB
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </FilesDragAndDropArea>
            {dataFiles !== null && dataFiles.length > 0 && (
              <FilesUploadingList
                dataFiles={dataFiles}
                caseId={null}
                refetchCaseData={() => Promise.resolve()}
                onAddExternal={(newDataFiles, replace) => {
                  if (onDataroomFilesChange) {
                    onDataroomFilesChange(newDataFiles, replace)
                  }
                }}
                isExternalLoading={isExternalLoading}
              />
            )}
          </>
        )}
      />
    </Grid>
  )
}

export default CreateCaseFileUploader

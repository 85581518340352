import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useContext, useState } from 'react'
import BulkInviteToCaseModal from '@/components/Modals/BulkInviteToCaseModal'
import { sleep } from '@/utils'
import { useSizes } from '@/hooks/useSizes'
import { ReferralContext } from './ReferralContext'
import { MyReferralsContext } from '../MyReferralsContext'

export const PromoteCase = () => {
  const { refetch } = useContext(MyReferralsContext)
  const { isLessMd } = useSizes()
  const { caseEntry } = useContext(ReferralContext)
  const [modalOpen, setModalOpen] = useState(false)

  const handleButtonClick = () => {
    setModalOpen(true)
  }

  const handleInviteSubmit = async () => {
    // NOTE: This sleep() is a hacky workaround for letting the back end update
    // before refetching myreferrals data. Without this, refetch() pulls stale
    // data.
    //
    // TODO: Determine why the back end returns stale data and remove this sleep()
    await sleep(150)
    await refetch()
    setModalOpen(false)
  }

  const handleModalClose = async () => {
    setModalOpen(false)
  }

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      p={2}
      justifyContent="center"
      alignItems="baseline"
      gap={2}
      textAlign="center"
    >
      <Typography m={{ xs: 'auto', md: 0 }}>
        <Typography
          component="strong"
          display={{ xs: 'block', md: 'inline' }}
          fontWeight="bold"
          mb={{ xs: 1, md: 0 }}
        >
          {isLessMd ? 'Promote this case' : 'Promote this case.'}
        </Typography>{' '}
        <Typography component="span">
          Attorneys you invite won&apos;t be charged a match fee.
        </Typography>
      </Typography>
      <Button
        variant="outlined"
        onClick={handleButtonClick}
        sx={{ m: { xs: 'auto', md: 0 } }}
      >
        Invite Attorneys to Case
      </Button>
      {modalOpen && (
        <BulkInviteToCaseModal
          jCase={{
            id: caseEntry.id,
            name: caseEntry.name,
          }}
          open={modalOpen}
          onSubmit={handleInviteSubmit}
          onClose={handleModalClose}
          onBehalfOf={caseEntry.attorney_id}
          onBehalfOfUserId={caseEntry.attorney.user_id}
        />
      )}
    </Box>
  )
}

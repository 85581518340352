import Box, { BoxProps } from '@mui/material/Box'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Control, Controller } from 'react-hook-form'
import { Catalog_Communication_Preference } from '@/gql/systemApi'
import JAutocomplete from '../atoms/JAutocomplete'
import {
  TCommunicationPreferencesFormValues,
  TNotificationMethodOption,
} from './types'

interface ICommunicationPreferencesProps extends BoxProps {
  control: Control<TCommunicationPreferencesFormValues>
  notificationMethodOptions: TNotificationMethodOption[]
  casesToNotifyOptions: Catalog_Communication_Preference[]
  fitTextField?: boolean
  loading: boolean
  isNotificationsEnabled: boolean
}

const CommunicationPreferences = ({
  control,
  casesToNotifyOptions,
  notificationMethodOptions,
  fitTextField = false,
  loading,
  isNotificationsEnabled,
  ...rest
}: ICommunicationPreferencesProps) => (
  <Box {...rest}>
    <JAutocomplete<TCommunicationPreferencesFormValues>
      control={control}
      loading={loading}
      name="casesToNotify"
      options={casesToNotifyOptions || []}
      label="Cases to Notify"
      getOptionLabel={option => option?.name}
      disabled={loading || !isNotificationsEnabled}
      fitTextField={fitTextField}
      rules={{
        validate: value =>
          value !== '' || 'Please select the cases to be notified about',
      }}
      sx={{ mb: 1 }}
    />
    <JAutocomplete<TCommunicationPreferencesFormValues>
      control={control}
      loading={loading}
      name="notificationMethod"
      options={notificationMethodOptions || []}
      label="Notification Method"
      getOptionLabel={option => option?.name}
      disabled={loading || !isNotificationsEnabled}
      fitTextField={fitTextField}
      rules={{
        validate: value =>
          value !== '' || 'Please select a notification method',
      }}
    />
    <Controller
      name="isNotificationsEnabled"
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Switch
              {...field}
              checked={field.value}
              color="primary"
              disabled={loading}
              onChange={e => field.onChange(e.target.checked)}
            />
          }
          label="Enable Notifications"
          sx={{ mb: 2 }}
        />
      )}
    />
  </Box>
)

export default CommunicationPreferences

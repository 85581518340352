import { useCallback, useState } from 'react'
import useAttorneyId from '@/hooks/useAttorneyId'
import { ModalTypes } from '@/types'
import {
  GetActivityPurchaseDocument,
  GetActivityPurchaseFromMyOrgDocument,
  GetCasesDocument,
  useCreateClientSignedOrRejectedMutation,
} from '@/gql/appApi'
import { CASE_PURCHASE_STATUS, CASE_STATUS_FLOW } from '@/constants'
import initApollo from '@/apollo'

interface IUseClientSignedConfirmationModalProps {
  onSubmit: ({ responseType }: { responseType: ModalTypes }) => void
  casePurchaseId: string
  onBehalfOfAttorneyId?: string
  fee: number
}

const useClientSignedConfirmationModal = ({
  onSubmit,
  casePurchaseId,
  onBehalfOfAttorneyId,
  fee,
}: IUseClientSignedConfirmationModalProps) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { getAttorneyId } = useAttorneyId()
  const attorneyId = getAttorneyId()
  const createdBy = String(onBehalfOfAttorneyId || attorneyId)

  const [
    createCaseActivityPurchase,
    { loading: createCaseActivityPurchaseLoading },
  ] = useCreateClientSignedOrRejectedMutation({
    fetchPolicy: 'network-only',
    variables: {
      data: {
        created_by: createdBy,
        fee,
        activity_entry_status: CASE_PURCHASE_STATUS.Signed,
        case_purchase_id: {
          id: casePurchaseId,
        },
      },
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      GetActivityPurchaseDocument,
      'case_purchase_activity',
      GetCasesDocument,
      'case',
      GetActivityPurchaseFromMyOrgDocument,
      'case_purchase_activity',
    ],
  })

  const onSignedConfirmation = useCallback(async () => {
    setError('')

    try {
      setLoading(true)
      const response = await createCaseActivityPurchase()
      if (response?.data?.create_case_purchase_activity_item?.id) {
        // If the case is not updated to Signed yet, refetch the queries to update the case status.
        if (
          response?.data?.create_case_purchase_activity_item?.case_purchase_id
            ?.case_id?.status !== CASE_STATUS_FLOW.Signed
        ) {
          const result = await initApollo().refetchQueries({
            include: [
              GetActivityPurchaseDocument,
              'case_purchase_activity',
              GetCasesDocument,
              'case',
              GetActivityPurchaseFromMyOrgDocument,
              'case_purchase_activity',
            ],
          })
          const caseResponse = result?.find(query =>
            Object.keys(query?.data).includes('case')
          )
          const caseStatus = caseResponse?.data?.case?.[0]?.status
          // If the case is not updated to Signed yet, refetch the queries to update the case status.
          if (caseStatus && caseStatus !== CASE_STATUS_FLOW.Signed) {
            // Add delay to initApollo.refetchQueries() to ensure the case status is updated.
            // TODO: This is a temporary solution until we have a better way to handle this. Probably with an endpoint.
            setTimeout(async () => {
              await initApollo().refetchQueries({
                include: [
                  GetActivityPurchaseDocument,
                  'case_purchase_activity',
                  GetCasesDocument,
                  'case',
                  GetActivityPurchaseFromMyOrgDocument,
                  'case_purchase_activity',
                ],
              })
            }, 2000)
          }
        }
        onSubmit({
          responseType: 'ClientSignedConfirmation',
        })
        setLoading(false)
        return
      }

      setError(
        'There was a problem submitting your request. Please try again later.'
      )
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      setError(
        'There was a problem submitting your request. Please try again later.'
      )
    } finally {
      setLoading(false)
    }
  }, [createCaseActivityPurchase, onSubmit])

  return {
    onSignedConfirmation,
    loading: createCaseActivityPurchaseLoading || loading,
    error,
  }
}

export default useClientSignedConfirmationModal

import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  DiscardChangesModal,
  MyPagesLayout,
  useDiscardChangesModal,
} from '@/components'
import GlobalContext from '@/context/GlobalContext'
import { PAYMENT_WALL_ENABLED } from '@/constants'
import ErrorPage from '@/components/shared/ErrorPage'
import { useUsersMeQuery } from '@/gql/systemApi'
import {
  PATHS_WITH_SECTIONS,
  getRouteLabelByPathStr,
} from '@/router/RoutesUtils'
import Layout from '@/components/Layout'
import useMembership from '@/hooks/useMembership'
import NotPaymentMessage from '@/components/Messagges/NotPayment'
import { Box, useTheme } from '@mui/material'
import CustomLoading from '@/components/Loading'
import { OrganizationWaterfalls } from '@/components/OrganizationWaterfalls'
import { useGetMyRoleAndOrg } from '@/hooks/useGetMyRoleAndOrg'
import Members from './Members/Members'
import LawInfo from './LawInfo/LawInfo'
import Integrations from '../AccountSettings/Integrations'
import {
  ADMIN_PANEL_PAGES,
  getMenuItems,
  getAdminPanelFirstLabel,
} from './utils/constants'

const AdminPanel = () => {
  const theme = useTheme()
  const { id: pageId } = useParams()
  const globalContext = useContext(GlobalContext)
  const { loading: loadingUser } = useUsersMeQuery()
  const { isAdmin, isCaseManager } = useGetMyRoleAndOrg()

  const isAllowedUser = isAdmin || isCaseManager

  const {
    isMembershipEnabled,
    isMembershipPrefLoading,
    isNeededToCheckMembership,
  } = useMembership()

  const menuItems = getMenuItems(true)

  const [page, setPage] = useState(
    getRouteLabelByPathStr(menuItems, pageId) ||
      getAdminPanelFirstLabel(menuItems)
  )
  const [nextPage, setNextPage] = useState('')
  const {
    getConfirmationModalProps,
    setOpenModal,
    modalOpen,
    type: modalType,
    ...rest
  } = useDiscardChangesModal({
    type: 'confirm',
    onSubmit: () => {
      setPage(nextPage)
      setOpenModal(null)
      globalContext?.setPreventNavigation(false)
    },
  })
  // reset prevent navigation
  useEffect(() => {
    globalContext?.setPreventNavigation(false)
  }, [])

  useEffect(() => {
    globalContext?.setSelectedTab(page)
  }, [globalContext, page])

  const onClickItem = (item: string) => {
    setNextPage(item)
    if (globalContext?.preventNavigation) {
      return setOpenModal('confirmDiscardChanges')
    }
    return setPage(item)
  }

  const renderContent = () => {
    switch (page) {
      case ADMIN_PANEL_PAGES.MEMBERS: {
        return <Members />
      }
      case ADMIN_PANEL_PAGES.LAW_FIRM_INFO: {
        return <LawInfo />
      }
      case ADMIN_PANEL_PAGES.ORGANIZATION_WATERFALL: {
        return (
          <OrganizationWaterfalls
            isFirmSettings
            containerBoxProps={{
              mb: {
                xs: theme.spacing(10),
                md: theme.spacing(2),
              },
            }}
          />
        )
      }
      case ADMIN_PANEL_PAGES.INTEGRATION: {
        return <Integrations />
      }
      default: {
        return null
      }
    }
  }

  if (loadingUser) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CustomLoading message="Loading..." />
      </Box>
    )
  }

  if (!isAllowedUser) {
    return (
      <ErrorPage message={`You don't have permissions to view Admin Panel`} />
    )
  }

  if (
    PAYMENT_WALL_ENABLED &&
    !isMembershipPrefLoading &&
    isNeededToCheckMembership &&
    !isMembershipEnabled
  ) {
    return (
      <Layout>
        <NotPaymentMessage />
      </Layout>
    )
  }

  return (
    <MyPagesLayout
      onClickItem={onClickItem}
      menuItems={menuItems}
      customOnClickItemOnly
      useLinks={PATHS_WITH_SECTIONS.ADMIN_PANEL}
    >
      <Box
        sx={{
          padding: {
            xs: theme.spacing(0),
            md: theme.spacing(5),
          },
          width: '100%',
          overflowY: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        {renderContent()}
      </Box>
      {modalOpen && (
        <DiscardChangesModal
          ConfirmationModalProps={getConfirmationModalProps()}
          {...rest}
        />
      )}
    </MyPagesLayout>
  )
}

export default AdminPanel

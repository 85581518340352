import AuthLayout from '@/components/shared/AuthLayout'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useSizes } from '@/hooks/useSizes'
import { useNavigate } from 'react-router-dom'
import LogoBar from '@/pages/CreateAccount/shared/LogoBar'

const InvalidToken = ({
  isExpired,
  isInvalidToken,
}: {
  isExpired?: boolean
  isInvalidToken?: boolean
}) => {
  const { isLessMd } = useSizes()
  const navigate = useNavigate()

  const goToSignIn = () => {
    navigate('/signIn')
  }

  let title = ''
  let subtitle: React.ReactNode = ''
  if (isExpired) {
    title = 'Your Invitation has Expired'
    subtitle =
      'Please have a firm administrator re-invite you to the platform or email support@attorneyshare.com to resolve this matter.'
  } else if (isInvalidToken) {
    title = 'Invalid Invitation'
    subtitle =
      "We're sorry, but we couldn't verify your invitation. Please reach out to our Help Center for assistance in resolving this issue."
  } else {
    title = "We're validating your bar license."
    subtitle = (
      <>
        We are currently validating your bar license number. This process is
        typically pretty quick, but can take up to one business day.
        <br />
        <br />
        <strong>
          There is no action required from you at this time. We will reach out
          to you if we need further information.
        </strong>
      </>
    )
  }

  return (
    <AuthLayout>
      <LogoBar />
      <Box
        sx={theme => ({
          marginTop: {
            xs: 0,
          },
          maxWidth: {
            md: theme.spacing(74),
          },
          height: '100%',
          overflow: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
        })}
      >
        <Typography
          variant={isLessMd ? 'h5' : 'h3'}
          sx={{ mb: 5, textAlign: 'left', pl: { xs: 2, md: 0 } }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{ textAlign: 'left', pl: { xs: 2, md: 0 } }}
        >
          {subtitle}
        </Typography>
        <Box
          sx={{
            mt: { xs: 5, md: 10 },
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-end' },
          }}
        >
          <Button
            onClick={goToSignIn}
            size="large"
            variant="contained"
            sx={{ width: 290 }}
          >
            Back to Sign In
          </Button>
        </Box>
      </Box>
    </AuthLayout>
  )
}

export default InvalidToken

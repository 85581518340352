import React from 'react'
import { Typography, Box } from '@mui/material'
import JStatusPill from '../../../../../components/atoms/JStatusPill'
import CaseInformationSkeleton from './skeleton'
import { useCaseContext } from '../../hooks/useCaseContext'
import { CaseDescription } from './CaseDescription'
import { Case } from '../../types'
import { LABEL_WIDTH } from '../../constants/case'

// Define a type for metadata field configuration
type MetadataField = {
  key: keyof Case
  label: string
  render: (value: any) => React.ReactNode
}

// Configuration for metadata fields
const metadataFields: MetadataField[] = [
  {
    key: 'case_number',
    label: 'Case Number',
    render: value => value,
  },
  {
    key: 'incident_date',
    label: 'Incident Date',
    render: value =>
      new Date(value).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      }),
  },
  {
    key: 'second_language',
    label: 'Second Language',
    render: value => value?.name,
  },
]

const MetadataLabel: React.FC<{ label: string }> = ({ label }) => (
  <Typography
    variant="caption"
    width={LABEL_WIDTH}
    mr={2}
    color="text.disabled"
  >
    {label}
  </Typography>
)

const CaseInformation: React.FC = () => {
  const { caseData, loading } = useCaseContext()

  if (loading) return <CaseInformationSkeleton />

  if (!caseData) return null

  const { process_stage } = caseData

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <CaseDescription />
      <Box display="flex" flexDirection="column" gap={2.5}>
        <Box display="flex" alignItems="center">
          <MetadataLabel label="Process Stage" />
          <JStatusPill
            id={process_stage?.id || ''}
            name={process_stage?.name || 'Not Specified'}
          />
        </Box>
        {metadataFields.map(field => {
          const value = caseData[field.key]
          if (!value) return null
          return (
            <Box key={field.key} display="flex" alignItems="center">
              <MetadataLabel label={field.label} />
              <Typography variant="body2">{field.render(value)}</Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default CaseInformation

import { CaseManager, User } from '../types'

export const JohnDoe: User = {
  id: '1',
  name: 'John Doe',
  attorney_id: '1',
  avatar_image:
    'https://images.unsplash.com/photo-1534308143481-c55f00be8bd7?q=80&w=2660&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  firm: {
    id: '101',
    name: 'Doe & Associates',
  },
  membership_type: 'vetted',
  contact: {
    email: 'john.doe@example.com',
    phone: '+1 (555) 123-4567',
  },
}

export const JaneSmith: User = {
  id: '2',
  name: 'Jane Smith',
  attorney_id: '2',
  avatar_image:
    'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fHByb2ZpbGV8ZW58MHx8MHx8fDA%3D',
  firm: {
    id: '102',
    name: 'Smith Law Group',
  },
  membership_type: 'qualified',
  contact: {
    email: 'jane.smith@example.com',
    phone: '+1 (555) 234-5678',
  },
}

export const DrMax: User = {
  id: '3',
  name: 'Dr. Maximilian Theodore Bartholomew Worthington-Hollister III, Esq.',
  attorney_id: '3',
  avatar_image:
    'https://images.unsplash.com/photo-1688167217076-190b0ec8428f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTV8fHByb2ZpbGUlMjBwaWN0dXJlfGVufDB8fDB8fHww',
  firm: {
    id: '103',
    name: 'Maxwell & Associates',
  },
  membership_type: 'vetted',
  contact: {
    email: 'dr.max@example.com',
    phone: '+1 (555) 345-6789',
  },
}

export const CaseManagerJoe: CaseManager = {
  id: '4',
  name: 'Joe Manager',
  avatar_image: null,
  ownership_id: '104',
}

export const CaseManagerSally: CaseManager = {
  id: '5',
  name: 'Sally Manager',
  avatar_image: null,
  ownership_id: '105',
}

import { useSnackBar } from '@/hooks/useSnackBar'
import { Dispatch, SetStateAction } from 'react'
import {
  useRemoveIntegrationItem,
  useSetupSalesForce,
} from '@/utils/api/integrations'
import { connectIntegration, sanitizedInstanceUrl } from '../shared/utils'

export interface UseSalesForceProps {
  code_challenge: string
  code: string
  instance_url: string
  connectSalesForce: boolean
  salesforceRemoveStatus: boolean
  organizationId?: string
  storageInstanceUrl?: string | null
  storageCodeVerifier?: string
  setInstanceUrl: Dispatch<SetStateAction<string>>
}

const useSalesForceIntegrationItem = ({
  code_challenge,
  code,
  instance_url,
  connectSalesForce,
  salesforceRemoveStatus,
  organizationId,
  storageInstanceUrl,
  storageCodeVerifier,
  setInstanceUrl,
}: UseSalesForceProps) => {
  const { alert, showAlert, handleClose } = useSnackBar()

  const connectSalesForceIntegration = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const instanceUrl = sanitizedInstanceUrl(instance_url)

    return connectIntegration({
      event,
      instanceUrl,
      codeChallenge: code_challenge,
      oauthUrl: import.meta.env.VITE_LCMS_SALESFORCE_OAUTH_URL,
      showAlert,
      setInstanceUrl,
    })
  }

  const { data: removeSalesForceData, isLoading: removeSalesForceIsLoading } =
    useRemoveIntegrationItem(String(organizationId), salesforceRemoveStatus)

  const config = {
    code,
    code_verifier: String(storageCodeVerifier),
    instance_url: String(storageInstanceUrl),
  }

  const {
    data: connectSalesForceIntegrationData,
    isLoading: isSalesForceIntegrationLoading,
  } = useSetupSalesForce(
    {
      organization_id: String(organizationId),
      lcms: 'salesforce',
      config,
    },
    salesforceRemoveStatus,
    connectSalesForce
  )

  return {
    connectSalesForceIntegration,
    connectSalesForceIntegrationData,
    isSalesForceIntegrationLoading,
    removeSalesForceData,
    removeSalesForceIsLoading,
    alert,
    handleClose,
    showAlert,
  }
}

export default useSalesForceIntegrationItem

import { forwardRef } from 'react'
import { IMaskInput } from 'react-imask'
import TextField, { TextFieldProps } from '@mui/material/TextField'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

export const TextMaskPhone = forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...rest } = props

    return (
      <IMaskInput
        {...rest}
        mask="(#00) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(_, mask) =>
          onChange({
            target: { name: props.name, value: mask.rawInputValue },
          })
        }
        overwrite
      />
    )
  }
)

type PhoneFieldProps = TextFieldProps & {
  textFieldRef?: TextFieldProps['ref']
}

const PhoneField = ({ ref, textFieldRef, ...props }: PhoneFieldProps) => (
  <TextField
    {...props}
    ref={textFieldRef ?? ref}
    InputProps={{
      ...props.InputProps,
      // @ts-expect-error: This pattern is recommended by the docs for MUI
      // text field:
      // https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
      inputComponent: TextMaskPhone,
    }}
  />
)

export default PhoneField

import { Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useTheme } from '@mui/material/styles'

const PostedOnDate = ({ date }: { date?: string }) => {
  const theme = useTheme()
  if (!date) {
    return null
  }
  const formattedDate = dayjs(date).format('MMM DD, YYYY')
  const formattedTime = dayjs(date).format('hh:mma')
  const formattedDateTime = `${formattedDate} at ${formattedTime}`

  return (
    <Typography
      variant="caption"
      sx={{
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        gap: theme.spacing(1),
        color: 'text.disabled',
      }}
    >
      Posted on {formattedDateTime}
    </Typography>
  )
}

export default PostedOnDate

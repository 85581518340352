import { useEffect, useContext, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import map from 'lodash/map'
import { getRouteLabelByPathStr } from '@/router/RoutesUtils'

import GlobalContext from '@/context/GlobalContext'
import { MenuItemType } from '@/components'

export interface UseInnerNavigationProps {
  menu: MenuItemType[]
  setPage: React.Dispatch<React.SetStateAction<string>>
  page: string
}

const useInnerNavigation = ({
  menu,
  setPage,
  page,
}: UseInnerNavigationProps) => {
  const { id: pageId } = useParams()

  const pageParam = getRouteLabelByPathStr(menu, pageId)
  const pageLabelCollection = useMemo(
    () => map(map(menu, 'items').flat(), 'label'),
    [menu]
  )

  const globalContext = useContext(GlobalContext)
  useEffect(() => {
    if (menu?.length > 0) {
      if (!pageLabelCollection.includes(page)) {
        setPage(menu[0]?.items[0]?.label)
      }
    }
  }, [menu, page, pageLabelCollection, setPage])

  const init = useCallback(() => {
    if (pageParam && pageLabelCollection.includes(pageParam)) {
      setPage(pageParam)
      globalContext?.setSelectedTab(pageParam)
    }
  }, [pageParam, pageLabelCollection, setPage, globalContext])

  useEffect(init, [init])
}

export default useInnerNavigation

import { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { MenuItemType, MyPagesLayout, ContentLayout } from '@/components'
import { useRestrictedUserPermissions } from '@/hooks/permissions/useRestrictedUserPermissions'
import ErrorPage from '@/components/shared/ErrorPage'
import CustomLoading from '@/components/Loading'
import useInnerNavigation from '@/hooks/useInnerNavigation'
import Layout from '@/components/Layout'
import {
  PATHS_WITH_SECTIONS,
  getRouteLabelByPathStr,
} from '@/router/RoutesUtils'
import NotPaymentMessage from '@/components/Messagges/NotPayment'
import useMembership from '@/hooks/useMembership'
import { PAYMENT_WALL_ENABLED } from '@/constants'
import { Box, useTheme } from '@mui/material'
import { OrganizationWaterfalls } from '@/components/OrganizationWaterfalls'
import MyHires from './MyHires'

// import Favorites from './MyFavorites'
import {
  getTitle,
  getSubtitle,
  MY_NETWORK_PAGES,
  menuItems,
  getMyNetworkFirstLabel,
} from './utils/constants'
import MemberDirectory from './MemberDirectory'

const MyNetwork = () => {
  const theme = useTheme()
  const { id: pageId } = useParams()
  const { isRestrictedUser, currentPermissionsSet } =
    useRestrictedUserPermissions('User', 'outOrganization')
  const [page, setPage] = useState<string>(
    getRouteLabelByPathStr(menuItems, pageId) || getMyNetworkFirstLabel()
  )
  const [searchText, setSearchText] = useState<string>('')
  const [menu, setMenu] = useState<MenuItemType[]>(menuItems)
  const {
    isMembershipEnabled,
    isMembershipPrefLoading,
    isNeededToCheckMembership,
  } = useMembership()

  const onClickItem = (item: string) => {
    setSearchText('')
    setPage(item)
  }

  useEffect(() => {
    if (currentPermissionsSet) {
      const data = menuItems[0].items
        .filter(f => {
          switch (f.label) {
            case MY_NETWORK_PAGES.MY_HIRES: {
              return !isRestrictedUser && currentPermissionsSet?.User?.read
            }
            case MY_NETWORK_PAGES.MY_WATERFALL_REFERRALS: {
              return !isRestrictedUser && currentPermissionsSet?.User?.read
            }
            // case MY_NETWORK_PAGES.MY_FAVORITES: {
            // return !isRestrictedUser && currentPermissionsSet?.Favorites?.read
            // }
            case MY_NETWORK_PAGES.MEMBER_DIRECTORY: {
              return !isRestrictedUser && currentPermissionsSet?.User?.read
            }
            default: {
              return true
            }
          }
        })
        .map(m => ({
          label: getTitle(m.label),
        }))
      setMenu([{ sectionTitle: '', items: data }])
    }
  }, [currentPermissionsSet, isRestrictedUser])

  useInnerNavigation({ menu, setPage, page })

  const renderContent = useCallback(() => {
    switch (page) {
      case MY_NETWORK_PAGES.MY_HIRES: {
        return <MyHires searchText={searchText} />
      }
      // case MY_NETWORK_PAGES.MY_FAVORITES: {
      // return <Favorites searchText={searchText} />
      // }
      case MY_NETWORK_PAGES.MEMBER_DIRECTORY: {
        return <MemberDirectory searchText={searchText} />
      }
      default: {
        return <MyHires searchText={searchText} />
      }
    }
  }, [page, searchText])

  if (isRestrictedUser)
    return (
      <Layout>
        <ErrorPage message="You don't have permissions to view My Referrals" />
      </Layout>
    )

  if (
    PAYMENT_WALL_ENABLED &&
    !isMembershipPrefLoading &&
    currentPermissionsSet &&
    isNeededToCheckMembership &&
    !isMembershipEnabled
  ) {
    return (
      <Layout>
        <NotPaymentMessage />
      </Layout>
    )
  }

  if (!currentPermissionsSet) {
    return <CustomLoading message="Loading..." />
  }

  const renderPageContent = () => {
    if (page === MY_NETWORK_PAGES.MY_WATERFALL_REFERRALS) {
      return (
        <OrganizationWaterfalls
          containerBoxProps={{
            sx: {
              '&::-webkit-scrollbar': { display: 'none' },
              overflowY: 'auto',
              pt: { xs: 0, md: 5 },
              pb: { xs: 8, md: 5 },
              px: {
                xs: 0,
                md: 5,
              },
              mb: {
                xs: theme.spacing(10),
                md: 0,
              },
            },
          }}
        />
      )
    }
    return (
      <ContentLayout
        title={getTitle(page)}
        subtitle={getSubtitle(page)}
        SearchProps={{
          value: searchText,
          onChange: event => setSearchText(event.target.value),
        }}
      >
        <Box
          sx={{
            width: '100%',
            '&::-webkit-scrollbar': { display: 'none' },
            overflowY: 'auto',
            marginBottom: {
              xs: theme.spacing(10),
              md: theme.spacing(2),
            },
          }}
        >
          {renderContent()}
        </Box>
      </ContentLayout>
    )
  }

  return (
    <MyPagesLayout
      menuItems={menu || []}
      onClickItem={onClickItem}
      useLinks={PATHS_WITH_SECTIONS.MY_NETWORK}
    >
      {!menu?.[0].items.length ? (
        <ErrorPage message="You don't have permissions to view My Referrals" />
      ) : (
        renderPageContent()
      )}
    </MyPagesLayout>
  )
}

export default MyNetwork

import { useState, useEffect } from 'react'
import { UseFormSetValue, FieldValues } from 'react-hook-form'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { Search } from '@mui/icons-material'
import JDropdownUser from '@/components/atoms/JDropdownUser'
import Chip from '@mui/material/Chip'

interface UserFilterItem {
  id: string
  name: string
  picture?: string
  isVetted?: boolean
}

export interface UserFilterProps {
  apiUsers: UserFilterItem[]
  label: string
  placeHolder: string
  formControlName: string
  setFormValue: UseFormSetValue<FieldValues>
  selectedFormValues: UserFilterItem[]
}

const UserFilter = ({
  apiUsers,
  label,
  placeHolder,
  formControlName,
  setFormValue,
  selectedFormValues,
}: UserFilterProps) => {
  const [selectedUsers, setSelectedUsers] =
    useState<UserFilterItem[]>(selectedFormValues)

  const handleSelection = (_event: unknown, newValue: UserFilterItem[]) => {
    setSelectedUsers(newValue)
    setFormValue(formControlName, newValue)
  }

  useEffect(() => {
    setFormValue(formControlName, selectedFormValues)
  }, [formControlName, selectedFormValues])

  return (
    <Autocomplete
      value={selectedUsers}
      multiple
      disabled={apiUsers.length === 0}
      options={apiUsers}
      getOptionLabel={option => `${option.name} (${option.id})`}
      onChange={handleSelection}
      getOptionDisabled={() => selectedUsers.length >= 3}
      filterOptions={(options, params) => {
        const filteredOptions = options.filter(option =>
          option.name.toLowerCase().includes(params.inputValue.toLowerCase())
        )
        return filteredOptions
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={placeHolder}
          placeholder={label}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <Search color="disabled" />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <JDropdownUser user={option} {...props} />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...rest } = getTagProps({ index })
          return (
            <Chip
              key={option.id}
              variant="outlined"
              label={option.name}
              {...rest}
            />
          )
        })
      }
      sx={{ marginBottom: 3 }}
    />
  )
}

export default UserFilter

import { PAYMENT_METHOD, ProposalSuccess } from '@/constants'
import { TPaymentStatus } from '../types'

interface INegotiationUtilsProps {
  paymentStatus: TPaymentStatus
  isCaseOwner: boolean
}

export const getModalResponse = ({
  paymentStatus,
  isCaseOwner,
}: INegotiationUtilsProps) => {
  if (!paymentStatus.currentUserPaid && !paymentStatus.counterpartPaid) {
    return {
      type: 'AcceptFreePending',
      open: true,
    }
  }
  if (
    !paymentStatus.currentUserPaid &&
    paymentStatus.counterpartPaid &&
    !isCaseOwner
  ) {
    return {
      type: 'EaterAcceptFreeCompleted',
      open: true,
    }
  }
  if (
    !paymentStatus.currentUserPaid &&
    paymentStatus.counterpartPaid &&
    isCaseOwner
  ) {
    return {
      type: 'FeederAcceptFreeCompleted',
      open: true,
    }
  }
  return {
    type: '',
    open: false,
  }
}

export const getQueryParam = ({
  paymentStatus,
  isCaseOwner,
}: INegotiationUtilsProps) => {
  if (!paymentStatus.currentUserPaid && !paymentStatus.counterpartPaid) {
    return `?proposalSuccess=${ProposalSuccess.ACCEPT_PAID_PENDING}`
  }

  if (
    !paymentStatus.currentUserPaid &&
    paymentStatus.counterpartPaid &&
    !isCaseOwner
  ) {
    return `?proposalSuccess=${ProposalSuccess.EATER_ACCEPT_PAID_COMPLETED}`
  }

  if (
    !paymentStatus.currentUserPaid &&
    paymentStatus.counterpartPaid &&
    isCaseOwner
  ) {
    return `?proposalSuccess=${ProposalSuccess.FEEDER_ACCEPT_PAID_COMPLETED}`
  }

  return ''
}

export const getPaymentMethod = ({
  isCaseOwner,
  isInvited,
}: {
  isCaseOwner: boolean
  isInvited: boolean
}) => {
  if (isCaseOwner) {
    return PAYMENT_METHOD.FeederFee
  }
  if (isInvited) {
    return PAYMENT_METHOD.Invited
  }
  return PAYMENT_METHOD.Credits
}

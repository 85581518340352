import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { ConfirmationModal } from '@/components'
import { FormProvider } from 'react-hook-form'
import { useSizes } from '@/hooks/useSizes'
import useCreateOrEditWaterfallModal from './useCreateOrEditWaterfallModal'
import WaterfallInfo from './components/WaterfallInfo'
import WaterfallName from './components/WaterfallName'
import WaterfallMembers from './components/WaterfallMembers/WaterfallMembers'
import { WaterfallList } from '../../types'

interface ICreateOrEditWaterfallModalProps {
  waterfall?: WaterfallList
  onSuccess: () => void
  onError: () => void
  onClose: () => void
  open: boolean
  isFirmSettings?: boolean
  organizationId?: string
  jurisdictionAndPracticeAreasConditions?: {
    practiceAreasInUsePerJurisdiction: Record<string, string[]>
    jurisdictionsWithWaterfallsWithNoPracticeAreas: Set<string>
  }
}

const CreateOrEditWaterfallModal = ({
  onSuccess,
  onError,
  waterfall,
  onClose,
  open,
  isFirmSettings,
  organizationId,
  jurisdictionAndPracticeAreasConditions,
}: ICreateOrEditWaterfallModalProps) => {
  const { isLessMd } = useSizes()

  const {
    methods,
    loading,
    onSubmit,
    usStates,
    practiceAreas,
    isSubmitting,
    isPracticeAreasRequired,
  } = useCreateOrEditWaterfallModal({
    waterfall,
    onClose,
    onSuccess,
    onError,
    isFirmSettings,
    organizationId,
    jurisdictionAndPracticeAreasConditions,
  })
  const title =
    waterfall && waterfall?.waterfallId
      ? 'Update Waterfall'
      : 'Create New Waterfall'
  const actionButtonTitle =
    waterfall && waterfall?.waterfallId
      ? 'Update Waterfall'
      : 'Create Waterfall'

  const waterfallMembers = waterfall?.waterfallMembers?.map(item => ({
    listOrder: item?.listOrder ?? 0,
    attorneyId: item?.attorneyId ?? '',
  }))

  return (
    <ConfirmationModal
      open={open}
      title={title}
      AcceptButtonProps={{
        children: actionButtonTitle,
        onClick: onSubmit,
        disabled: loading || isSubmitting,
      }}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      {...(isLessMd && { fullScreen: true })}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <FormProvider {...methods}>
          {isFirmSettings && (
            <>
              <WaterfallInfo
                jurisdictions={usStates}
                practiceAreas={practiceAreas}
                isPracticeAreasRequired={isPracticeAreasRequired}
              />
              <Divider sx={{ my: 4 }} />
            </>
          )}
          <WaterfallName />
          <Divider sx={{ my: 4 }} />
          <WaterfallMembers
            waterfallMembers={waterfallMembers}
            isFirmSettings={isFirmSettings}
          />
        </FormProvider>
      </Box>
    </ConfirmationModal>
  )
}

export default CreateOrEditWaterfallModal

import { useCallback, useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import Search from '@mui/icons-material/Search'
import { useFormContext } from 'react-hook-form'
import JDropdownUser from '@/components/atoms/JDropdownUser'
import DnDSortableContext from '@/components/DnDSortable/DnDSortableContext'
import SortableItem from '@/components/DnDSortable/SortableItem'
import { IMAGE_SIZES } from '@/constants'
import { getImagePath } from '@/utils'
import { isQualifiedMembership } from '@/utils/membership'
import { Attorney_Profile } from '@/gql/appApi'
import useWaterfallMembers from './useWaterfallMembers'
import WaterfallModalLayout from '../WaterfallModalLayout'
import EnableWaterfallSwitch from './EnableWaterfallSwitch'

interface IWaterfallMembersProps {
  waterfallMembers?:
    | {
        listOrder: number
        attorneyId: string
      }[]
  isFirmSettings?: boolean
}

const WaterfallMembers = ({
  waterfallMembers,
  isFirmSettings,
}: IWaterfallMembersProps) => {
  const {
    control: { getFieldState },
    setValue,
    watch,
  } = useFormContext()
  const [clearOnJurisdictionChange, setClearOnJurisdictionChange] =
    useState(false)
  const jurisdictionId = watch('jurisdiction')
  const fieldState = getFieldState('waterfallMembers')

  const {
    attorneyProfiles,
    setAttorneyProfiles,
    options: allOptions,
    loading,
    loadingSelectedUsers,
    filterOptions,
    setInputValue,
    setSelectedUsers,
    selectedUsers,
  } = useWaterfallMembers({
    waterfallMembers,
    jurisdictionId,
  })

  const handleSelection = useCallback(
    (newValue: Attorney_Profile[]) => {
      setSelectedUsers(newValue)
      const waterfallMembersId = newValue.map(user => user?.attorney_id?.id)
      setValue('waterfallMembers', waterfallMembersId)
    },
    [setSelectedUsers, setValue]
  )

  // Clear selected users when jurisdiction changes to avoid having users from different jurisdictions
  useEffect(() => {
    // Do not clear on first render
    if (!clearOnJurisdictionChange) {
      setClearOnJurisdictionChange(true)
      return
    }
    handleSelection([])
    setAttorneyProfiles([])
    // We also need to reset the practice areas when the jurisdiction changes, otherwise duplicate practice areas can be selected
    setValue('practiceAreas', [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSelection, jurisdictionId, setAttorneyProfiles])

  const hasMaxMembers = selectedUsers.length >= 3

  const renderSelectedUsers = () => {
    if (loadingSelectedUsers) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 100,
          }}
        >
          <CircularProgress size={48} />
        </Box>
      )
    }

    return (
      <>
        {selectedUsers.map((user, index) => (
          <SortableItem
            key={user.id}
            id={user.id}
            containerSxProps={{
              width: '100%',
              justifyContent: 'flex-start',
              mb: 2.5,
            }}
            dragIndicatorContainerSxProps={{
              mb: { sm: 0, md: 0 },
            }}
          >
            <Typography variant="body1" sx={{ mr: 2 }}>
              {index + 1}
            </Typography>
            <JDropdownUser
              key={user.id}
              user={{
                id: user?.attorney_id?.id || '',
                name: `${user?.first_name} ${user?.last_name}`,
                picture: getImagePath({
                  id: user?.attorney_id?.user_id?.avatar?.id || '',
                  size: IMAGE_SIZES.Small,
                }),
                isVetted: user?.attorney_id?.is_vetted || false,
                isQualified: isQualifiedMembership(
                  user?.attorney_id?.membership_type?.id
                ),
                organization:
                  user?.attorney_id?.user_id?.current_organization?.[0]
                    ?.organization_id?.profiles?.[0]?.name || '',
              }}
              sx={{
                avatar: { width: 36, height: 36 },
                list: { px: 0, py: 0, width: 'auto' },
              }}
              badgeSize="xsmall"
            />
          </SortableItem>
        ))}
      </>
    )
  }

  return (
    <WaterfallModalLayout
      title="Waterfall Members"
      description="Select the attorneys who will be part of the Waterfall. You can rearrange their order by dragging and dropping them."
    >
      <DnDSortableContext
        items={selectedUsers}
        valueKey="id"
        setItems={handleSelection}
      >
        <Box>
          <Autocomplete
            options={allOptions}
            loading={loading || hasMaxMembers}
            value={attorneyProfiles}
            getOptionLabel={(att: Attorney_Profile) =>
              `${att.first_name} ${att.last_name} (${att.attorney_id?.id})`
            }
            multiple
            filterOptions={filterOptions}
            onChange={(_, newAttorney) => {
              setAttorneyProfiles(newAttorney)
              handleSelection(newAttorney)
            }}
            isOptionEqualToValue={(option, v) =>
              option.attorney_id?.id === v.attorney_id?.id
            }
            getOptionDisabled={() => hasMaxMembers}
            onInputChange={(_, eInputValue) => {
              setInputValue(eInputValue)
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Waterfall Members"
                placeholder="Waterfall Members (up to 3)"
                error={!!fieldState.error && selectedUsers?.length === 0}
                helperText={
                  fieldState.error?.message && selectedUsers?.length === 0
                    ? fieldState.error?.message
                    : null
                }
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <Search color="disabled" />
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <JDropdownUser
                key={option.id}
                user={{
                  id: option.attorney_id?.id || '',
                  name: `${option?.first_name} ${option?.last_name}`,
                  picture: getImagePath({
                    id: option?.attorney_id?.user_id?.avatar?.id || '',
                    size: IMAGE_SIZES.Small,
                  }),
                  isVetted: option?.attorney_id?.is_vetted || false,
                  isQualified: isQualifiedMembership(
                    option?.attorney_id?.membership_type?.id
                  ),
                  organization:
                    option?.attorney_id?.user_id?.current_organization?.[0]
                      ?.organization_id?.profiles?.[0]?.name || '',
                }}
                sx={{
                  avatar: { width: 36, height: 36 },
                  list: { px: 0, py: 0, width: 'auto' },
                }}
                badgeSize="xsmall"
                {...props}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...rest } = getTagProps({ index })
                return (
                  <Chip
                    key={option.id}
                    variant="outlined"
                    label={`${option?.first_name} ${option?.last_name}`}
                    {...rest}
                  />
                )
              })
            }
            sx={{ mb: 2 }}
          />
          {renderSelectedUsers()}
        </Box>
      </DnDSortableContext>
      {isFirmSettings && <EnableWaterfallSwitch />}
    </WaterfallModalLayout>
  )
}

export default WaterfallMembers

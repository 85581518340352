import React from 'react'
import { Box, Skeleton } from '@mui/material'
import { LABEL_WIDTH } from '../../constants/case'

const MetadataLabel: React.FC = () => (
  <Skeleton variant="text" width={LABEL_WIDTH} height={20} sx={{ mr: 2 }} />
)

const CaseInformationSkeleton: React.FC = () => (
  <Box display="flex" flexDirection="column" gap={4.5}>
    {/* CaseDescription skeleton */}
    <Box display="flex" flexDirection="column" gap={0.75}>
      {Array(8)
        .fill(null)
        .map((_, index) => (
          <Skeleton
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            variant="text"
            width="100%"
            height={18}
            sx={{ transform: 'scale(1, 1)' }}
          />
        ))}
    </Box>

    {/* Metadata fields skeleton */}
    <Box display="flex" flexDirection="column" gap={2.5}>
      {/* Process Stage */}
      <Box display="flex" alignItems="center">
        <MetadataLabel />
        <Skeleton
          variant="rectangular"
          width={100}
          height={24}
          sx={{ borderRadius: '9999px', transform: 'scale(1, 1)' }}
        />
      </Box>

      {/* Other metadata fields */}
      {[...Array(3)].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={index} display="flex" alignItems="center">
          <MetadataLabel />
          <Skeleton
            variant="text"
            width={150}
            height={20}
            sx={{ transform: 'scale(1, 1)' }}
          />
        </Box>
      ))}
    </Box>
  </Box>
)

export default CaseInformationSkeleton

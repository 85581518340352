import Grid from '@mui/material/Grid'
import { useSizes } from '@/hooks/useSizes'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'

export interface ISidebarMessageProps {
  title: string | null
  subtitle: string | null
  showInMobile?: boolean
  finalizedAgreement?: boolean
  isMatched?: boolean
  isSigned?: boolean
}

const SidebarMessage = ({
  title,
  subtitle,
  showInMobile = false,
  finalizedAgreement,
  isMatched,
  isSigned,
}: ISidebarMessageProps) => {
  const { isLessMd } = useSizes()

  const isMatchedOrSigned = isMatched || isSigned

  return (
    <Grid container>
      <Grid item xs={12}>
        {(showInMobile && isLessMd) || finalizedAgreement || isMatched ? (
          <Alert
            severity={isMatchedOrSigned ? 'success' : 'warning'}
            icon={false}
          >
            {title && (
              <AlertTitle sx={{ fontWeight: 'bold' }}>{title}</AlertTitle>
            )}
            {subtitle}
          </Alert>
        ) : (
          <Box>
            {title && (
              <Typography
                variant="body1"
                pb={1}
                sx={{ fontWeight: 700, align: 'left' }}
              >
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography variant="body2" pt={1}>
                {subtitle}
              </Typography>
            )}
          </Box>
        )}
      </Grid>
    </Grid>
  )
}
export default SidebarMessage

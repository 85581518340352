import { useContext } from 'react'
import { timeService } from '@/services/Time/TimeService'
import { CASE_STATUS_FLOW } from '@/constants'
import { ReferralContext } from './ReferralContext'

export const useSellerReferral = () => {
  const {
    caseEntry,
    caseEntry: { waterfall, case_purchase: casePurchase, fee: caseFee },
    counterpart,
    isCaseManagerWithoutPermission,
  } = useContext(ReferralContext)

  let waterfallProposalDueDate = new Date()
  let showWaterfallProposalDueDate = false

  const currentWaterfallCasePurchaseId = waterfall.find(
    ({ current_item_order, waterfall_order }) =>
      current_item_order === waterfall_order
  )?.case_purchase_id

  const currentWaterfallCasePurchase = casePurchase.find(
    ({ id }) => id === currentWaterfallCasePurchaseId
  )

  const areNegotiationsTakingPlace = ![
    String(CASE_STATUS_FLOW.Matched),
    String(CASE_STATUS_FLOW.Signed),
  ].includes(caseEntry.status)

  if (
    currentWaterfallCasePurchase &&
    areNegotiationsTakingPlace &&
    !isCaseManagerWithoutPermission
  ) {
    const timeStart = new Date(currentWaterfallCasePurchase.active_ts)
    const timeUnit = currentWaterfallCasePurchase.time_unit
    const timedAccessLimit = currentWaterfallCasePurchase.timed_access_limit

    if (timeUnit && timedAccessLimit) {
      showWaterfallProposalDueDate = true
      waterfallProposalDueDate = timeService.getEndDate(
        timeStart,
        timeUnit,
        timedAccessLimit
      )
    }
  }

  const counterparts = casePurchase.map(
    ({ prospect_attorney }) => prospect_attorney
  )

  const isCaseExpired = caseEntry.status === CASE_STATUS_FLOW.Expired

  const showNegotiations =
    !isCaseManagerWithoutPermission && areNegotiationsTakingPlace

  const showDataRoom =
    !isCaseManagerWithoutPermission && caseEntry.data_room.length > 0

  const showContactInformation =
    Boolean(counterpart) || caseEntry.leads.length > 0

  const showCounterpartContactInformation =
    !isCaseManagerWithoutPermission &&
    !areNegotiationsTakingPlace &&
    Boolean(counterpart)

  const showContactInformationInAccordion = areNegotiationsTakingPlace

  const showPromoteCase =
    !isCaseManagerWithoutPermission &&
    !isCaseExpired &&
    caseEntry.case_purchase.length === 0

  const showCaseManagerPermissionRequest =
    isCaseManagerWithoutPermission &&
    areNegotiationsTakingPlace &&
    !isCaseExpired

  const showExpirationMessage = isCaseExpired

  const showReferralContent =
    caseEntry.case_purchase.length > 0 ||
    caseEntry.data_room.length > 0 ||
    caseEntry.leads.length > 0 ||
    showCounterpartContactInformation ||
    showCaseManagerPermissionRequest ||
    showExpirationMessage ||
    showPromoteCase

  const summaryFee = areNegotiationsTakingPlace
    ? caseFee
    : casePurchase?.[0]?.case_purchase_activities.fee ?? 0

  return {
    counterparts,
    summaryFee,
    showPromoteCase,
    showCaseManagerPermissionRequest,
    showContactInformation,
    showContactInformationInAccordion,
    showCounterpartContactInformation,
    showDataRoom,
    showExpirationMessage,
    showNegotiations,
    showReferralContent,
    showWaterfallProposalDueDate,
    waterfallProposalDueDate,
  }
}

import {
  Case_Management_Software,
  Catalog_Company_Size,
  Catalog_Us_State,
  useLawFirmCatalogsQuery,
} from '@/gql/appApi'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { TLawFirmPublicInfo, lawFirmDefaultValues } from '../../types'

type TUseOrganizationLawFirmInfoProps = {
  lawFirmPublicInfoValues: TLawFirmPublicInfo
}

const useOrganizationLawFirmInfo = ({
  lawFirmPublicInfoValues,
}: TUseOrganizationLawFirmInfoProps) => {
  const {
    data,
    loading: lawFirmCatalogLoading,
    error: queryError,
  } = useLawFirmCatalogsQuery({
    variables: {
      catalogFirmRelationshipSort2: 'name',
      catalogUsStateSort2: 'code',
      catalogYearsPracticeSort2: 'name',
      sortCaseSoftware: 'name',
    },
  })
  const [states, setStates] = useState<Array<Catalog_Us_State>>([])
  const [caseManagementSoftware, setCaseManagementSoftware] = useState<
    Array<Case_Management_Software>
  >([])
  const [companySize, setCompanySize] = useState<Array<Catalog_Company_Size>>(
    []
  )
  const { control, handleSubmit } = useForm<TLawFirmPublicInfo>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: lawFirmPublicInfoValues || lawFirmDefaultValues,
  })

  useEffect(() => {
    if (data) {
      setCaseManagementSoftware(data.case_management_software)
      setCompanySize(data.catalog_company_size)
      setStates(data.catalog_us_state)
    }
  }, [data])

  return {
    control,
    handleSubmit,
    states,
    caseManagementSoftware,
    companySize,
    loading: lawFirmCatalogLoading,
    queryError,
  }
}

export default useOrganizationLawFirmInfo

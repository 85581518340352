import { useState, useMemo } from 'react'
import { ConfirmationModal } from '@/components'
import Button from '@mui/material/Button'
import FilterListIcon from '@mui/icons-material/FilterList'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import { useSizes } from '@/hooks/useSizes'
import { FormProvider, useForm } from 'react-hook-form'
import {
  ICaseForm,
  createNewCaseDefaultValues,
} from '@/components/Case/CaseForm/types'
import { Grid } from '@mui/material'
import JAutocomplete from '@/components/atoms/JAutocomplete'
import { getImagePath } from '@/utils'
import { IMAGE_SIZES, USER_ROLES } from '@/constants/index'

import { isQualifiedMembership } from '@/utils/membership'
import UserFilter from './controls/UserFilter'

import { TYPES_CATALOG } from '../../utils/utils'
import {
  AllReferralFilterItem,
  Attorney,
  ALL_REFERRAL_FILTER_TYPES_CATALOG,
} from '../../types'

interface User {
  id: string
  name: string
  picture?: string
  isVetted?: boolean
}

export interface ModalFilters {
  currentFilters: AllReferralFilterItem[]
  setFilters: (newFilters: AllReferralFilterItem[]) => void
  apiUsers: Attorney[]
}

const FORM_ATTORNEYS_KEY = 'attorneys'
const FORM_CASEMANAGERS_KEY = 'casemanagers'

const userToControl = (users: Attorney[]): User[] =>
  users.map(
    ({
      attorney_id,
      is_vetted,
      membership_type,
      first_name,
      last_name,
      avatar,
    }) => ({
      id: attorney_id,
      isVetted: is_vetted,
      isQualified: isQualifiedMembership(membership_type),
      name: `${first_name} ${last_name}`,
      picture: getImagePath({ id: avatar || null, size: IMAGE_SIZES.Small }),
    })
  )

const ModalFilter = ({
  currentFilters,
  setFilters,
  apiUsers,
}: ModalFilters) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { isLessMd } = useSizes()

  // control collectionss
  const apiAttorneys = useMemo(
    () =>
      userToControl(
        apiUsers.filter(att =>
          [
            USER_ROLES.AdminAttorneyUser,
            USER_ROLES.SAdminAttorneyUser,
          ].includes(att.role)
        )
      ),
    [apiUsers]
  )
  const apiCaseMAnagersAndAdmins = useMemo(
    () =>
      userToControl(
        apiUsers.filter(att => att.role === USER_ROLES.CaseManagerUser)
      ),
    [apiUsers]
  )

  // selected control collections
  const attorneyFiltersIds = currentFilters
    .filter(item => item.type === ALL_REFERRAL_FILTER_TYPES_CATALOG.ATTORNEY)
    .map(item => item.id)
  const attorneySelecteds = apiAttorneys.filter(item =>
    attorneyFiltersIds.includes(item.id)
  )

  const caseManagerFiltersIds = currentFilters
    .filter(
      item => item.type === ALL_REFERRAL_FILTER_TYPES_CATALOG.CASE_MANAGER
    )
    .map(item => item.id)
  const caseManagerSelecteds = apiCaseMAnagersAndAdmins.filter(item =>
    caseManagerFiltersIds.includes(item.id)
  )

  // set form data
  const { control, handleSubmit, setValue } = useForm()
  const hadleOnSubmit = (form: any) => {
    const newFilters: AllReferralFilterItem[] = []

    if (form?.referralType) {
      const referral = TYPES_CATALOG.find(item => item.id === form.referralType)
      if (referral !== undefined && referral?.id !== 0) {
        const { id = 1, name = '', data = '' } = referral
        newFilters.push({
          id,
          name,
          data,
          type: ALL_REFERRAL_FILTER_TYPES_CATALOG.REFERRAL_TYPE,
        })
      }
    }

    const attorneys = form[FORM_ATTORNEYS_KEY] || []
    if (Array.isArray(attorneys) && attorneys.length > 0) {
      attorneys.forEach(attorney => {
        const { id, name, picture } = attorney
        newFilters.push({
          id,
          name,
          data: picture,
          type: ALL_REFERRAL_FILTER_TYPES_CATALOG.ATTORNEY,
        })
      })
    }

    const caseManagers = form[FORM_CASEMANAGERS_KEY] || []
    if (Array.isArray(caseManagers) && caseManagers.length > 0) {
      caseManagers.forEach(casemanager => {
        const { id, name, picture } = casemanager
        newFilters.push({
          id,
          name,
          data: picture,
          type: ALL_REFERRAL_FILTER_TYPES_CATALOG.CASE_MANAGER,
        })
      })
    }

    setFilters(newFilters)
    handleClose()
  }

  const methods = useForm<ICaseForm>({
    defaultValues: createNewCaseDefaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  return (
    <>
      <Button
        component="label"
        onClick={handleOpen}
        startIcon={<FilterListIcon />}
        sx={{ marginLeft: '1.5rem' }}
      >
        {isLessMd ? '' : 'Filter Cases'}
      </Button>

      {open && (
        <ConfirmationModal
          open={open}
          title="Filter Cases"
          AcceptButtonProps={{
            children: 'Apply Filters',
            onClick: handleSubmit(hadleOnSubmit),
          }}
          onClose={handleClose}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'column', gap: 2.5, mt: 4 }}
          >
            <FormProvider {...methods}>
              <FormControl sx={{ marginTop: 0, width: { md: '450px' } }}>
                <Grid item xs={12}>
                  <JAutocomplete
                    control={control}
                    name="referralType"
                    options={TYPES_CATALOG}
                    label="Referral Type"
                    fullWidth
                    getOptionLabel={option => option.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <UserFilter
                    apiUsers={apiAttorneys}
                    label="Attorney"
                    placeHolder="All Attorneys"
                    formControlName={FORM_ATTORNEYS_KEY}
                    setFormValue={setValue}
                    selectedFormValues={attorneySelecteds}
                  />
                </Grid>
                <Grid item xs={12}>
                  <UserFilter
                    apiUsers={apiCaseMAnagersAndAdmins}
                    label="Case Manager"
                    placeHolder="All Case Managers"
                    formControlName={FORM_CASEMANAGERS_KEY}
                    setFormValue={setValue}
                    selectedFormValues={caseManagerSelecteds}
                  />
                </Grid>
              </FormControl>
            </FormProvider>
          </Box>
        </ConfirmationModal>
      )}
    </>
  )
}

export default ModalFilter

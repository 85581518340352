import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pathToLabel } from '@/router/RoutesUtils'
import { RoutesData } from '@/router/RoutesData'

type TDocumentTitleProps = {
  title?: string
}

const getSubPageTitle = (pageId: string, title: string) => {
  let customSubTitle = ` - ${pathToLabel(pageId)}`
  if (title === RoutesData.RCase.title) {
    customSubTitle = ''
  }

  return pageId !== '' ? customSubTitle : ''
}

const DocumentTitle = ({ title = '' }: TDocumentTitleProps) => {
  const { id: pageId = '' } = useParams()

  useEffect(() => {
    document.title = `Attorney Share${
      title !== '' ? ` - ${title}` : ''
    }${getSubPageTitle(pageId, title)}`
  }, [pageId, title])

  return null
}

export default DocumentTitle

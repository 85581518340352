import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { useSizes } from '@/hooks/useSizes'
import LogoBar from '@/pages/CreateAccount/shared/LogoBar'
import { useForm } from 'react-hook-form'
import JTextField from '@/components/atoms/JTextField'
import { isValidEmail } from '@/utils'
import { EmailFormValues } from '../hooks/useForgotPassword'

type RecoveryFormProps = {
  onSubmit: (formData: EmailFormValues) => void
  back: () => void
  loading: boolean
}

const RecoveryForm = ({ onSubmit, back, loading }: RecoveryFormProps) => {
  const { isLessMd } = useSizes()

  const { control, handleSubmit, trigger } = useForm<{ email: string }>({
    defaultValues: {
      email: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const handleEmailSubmit = () => {
    trigger().then(isValid => {
      if (isValid) {
        handleSubmit(onSubmit)()
      }
    })
  }

  return (
    <>
      <LogoBar />
      <Box
        sx={theme => ({
          marginTop: {
            xs: 0,
          },
          maxWidth: {
            md: theme.spacing(74),
          },
          '&::-webkit-scrollbar': { display: 'none' },
        })}
      >
        <Button
          onClick={back}
          variant="text"
          size="small"
          sx={{ mb: 3, textAlign: 'left', pl: 0 }}
          startIcon={
            <ArrowBack sx={theme => ({ color: theme.palette.primary.main })} />
          }
        >
          Back
        </Button>
        <Typography
          variant={isLessMd ? 'h5' : 'h3'}
          sx={{ mb: 2.5, textAlign: 'left' }}
        >
          Password Recovery
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'left', mb: 5 }}>
          Please enter your email and we’ll send you a link to reset your
          password
        </Typography>
        <JTextField<{ email: string }>
          name="email"
          label="Email"
          placeholder="Email"
          control={control}
          fullWidth
          rules={{
            validate: (email: string | number | boolean) => {
              if (typeof email !== 'string') {
                return 'Please enter an Email Address'
              }
              return isValidEmail(email) || 'Please enter a valid Email'
            },
          }}
        />
        <Box
          sx={{
            mt: 4,
            display: 'flex',
            justifyContent: {
              xs: 'center',
              md: 'flex-end',
            },
          }}
        >
          <Button
            onClick={handleEmailSubmit}
            size="large"
            variant="contained"
            sx={{ width: 290 }}
            disabled={loading}
          >
            Send link to email
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default RecoveryForm

import { gql } from '@apollo/client'

export const VALIDATE_ACCOUNT = gql`
  mutation ValidateAccount(
    $data: update_user_email_validation_input!
    $updateUserEmailValidationItemId: ID!
  ) {
    update_user_email_validation_item(
      data: $data
      id: $updateUserEmailValidationItemId
    )
  }
`

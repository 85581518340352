/* eslint-disable @typescript-eslint/dot-notation */
import request from './config'

const setAuthToken = (token: string | null) => {
  if (token) {
    request.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    delete request.defaults.headers.common['Authorization']
  }
}
export default setAuthToken

import Dialog, { DialogProps } from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'

import Header from '@/components/Modals/ModalHeader/ModalHeader'
import Footer from '@/components/Modals/ModalFooter/ModalFooter'
import ModalContent from '@/components/Modals/ModalContent/ModalContent'
import { ModalTypes, MODAL_TYPES } from '@/types'

export interface ResponseModalProps extends DialogProps {
  type?: ModalTypes
  title?: string
  bodyContent?: string | React.ReactNode
  noteContent?: string | React.ReactNode
  icon?: React.ReactNode
  buttonText?: string
  variant?: 'outlined' | 'filled' | 'standard'
}

const getModalData = (type?: ModalTypes) => {
  switch (type) {
    case MODAL_TYPES.SEND:
      return {
        modalTitle: 'Proposal Sent',
        modalContent: (
          <>
            <AlertTitle>Your proposal was sent succesfully</AlertTitle>
            The case owner will received a notification about your proposal. We
            will notify you when your counterpart accepts, wants to negotiate,
            or rejects your offer.
          </>
        ),
      }
    case MODAL_TYPES.ACCEPT:
      return {
        modalTitle: 'Submit Proposal',
        modalContent: (
          <>
            <AlertTitle>Your proposal was sent succesfully</AlertTitle>
            The case owner will received a notification about your proposal. We
            will notify you when your counterpart accepts, wants to negotiate,
            or rejects your offer.
          </>
        ),
      }
    case MODAL_TYPES.COUNTER_OFFER:
      return {
        modalTitle: 'Submit Counteroffer',
        modalContent: (
          <>
            <AlertTitle>Your counteroffer was sent succesfully</AlertTitle>
            We&apos;ve notified the other attorney about your counter and
            we&apos;ll let you know if they decide to accept, counter or reject
            it.
          </>
        ),
      }
    case MODAL_TYPES.REJECT:
      return {
        modalTitle: 'Reject Proposal',
        modalContent: 'You rejected the proposal succesfully',
      }
    case MODAL_TYPES.DATAROOM:
      return {
        modalTitle: 'Data Room Access',
        modalContent: (
          <>
            <AlertTitle>Your request was sent successfully</AlertTitle>
            We will notify you when the case owner accepts or rejects your
            request to access the data room.
          </>
        ),
      }
    case MODAL_TYPES.DISCARD_CHANGES_ACCEPTED:
      return {
        modalTitle: 'Changes Discarded',
        modalContent: 'You have discarded the changes successfully.',
      }
    case MODAL_TYPES.CHANGE_PASSWORD_SUCCESS:
      return {
        modalTitle: 'Password Changed',
        modalContent: 'You have changed your password successfully.',
      }
    case MODAL_TYPES.CHANGE_EMAIL_SUCCESS:
      return {
        modalTitle: 'Account Email Modified',
        modalContent:
          'A verification email has been sent to your new email account. Please click the button in the email to log into the site with your new credentials.',
      }
    case MODAL_TYPES.CASE_CREATED:
      return {
        modalTitle: 'Case Created',
        modalContent: (
          <>
            <AlertTitle style={{ fontWeight: 700 }}>
              Your case has been created successfully!
            </AlertTitle>
            You will be notified once you receive proposals from potential
            candidates. You can find your case in the &quot;My Cases&quot; tab
            of the &quot;My Referrals&quot; section.
          </>
        ),
      }
    case MODAL_TYPES.CASE_EDITED:
      return {
        modalTitle: 'Case Edited',
        modalContent: (
          <>
            <AlertTitle style={{ fontWeight: 700 }}>
              You have succesfully edited your case!
            </AlertTitle>
            Your changes are saved and reflected in our system. If further
            adjustments are needed, feel free to update until a proposal is
            accepted.
          </>
        ),
      }
    case MODAL_TYPES.ADD_CASE_MANAGER:
      return {
        modalTitle: 'Case Manager Added',
        modalContent: (
          <>
            <AlertTitle style={{ fontWeight: 700 }}>
              You have successfully added Case Managers to the Case.
            </AlertTitle>
            They will be able to act on your behalf on this case. Remember you
            can revoke these permissions any time you want.
          </>
        ),
      }
    case MODAL_TYPES.CASE_NOT_EDITABLE:
      return {
        modalTitle: 'Unable to Edit Case',
        modalContent: (
          <AlertTitle style={{ fontWeight: 700 }}>
            Editing the case is disabled because a proposal has been accepted.
          </AlertTitle>
        ),
      }
    case MODAL_TYPES.ERROR:
      return {
        modalTitle: 'An unexpected error occurred',
        modalContent: (
          <>
            <AlertTitle style={{ fontWeight: 700 }}>
              There was an error processing your request.
            </AlertTitle>
            Please try again later.
          </>
        ),
      }
    case MODAL_TYPES.ACCEPT_FREE_PENDING:
      return {
        modalTitle: 'Proposal Accepted',
        modalContent: (
          <>
            <AlertTitle style={{ fontWeight: 700 }}>
              You have successfully accepted the proposal
            </AlertTitle>
            No further action is required. We will notify you when your
            counterpart responds to your proposal.
          </>
        ),
      }
    case MODAL_TYPES.ACCEPT_PAID_PENDING:
      return {
        modalTitle: 'Proposal Accepted',
        modalContent: (
          <>
            <AlertTitle style={{ fontWeight: 700 }}>
              You have successfully accepted the proposal
            </AlertTitle>
            If the other lawyer does not submit their payment in the next 7
            days, your money will be refunded. No further action is required. We
            will notify you when your counterpart responds to your proposal.
          </>
        ),
      }
    case MODAL_TYPES.EATER_ACCEPT_FREE_COMPLETED:
      return {
        modalTitle: 'Agreement Reached',
        modalContent: (
          <>
            <AlertTitle style={{ fontWeight: 700 }}>
              Congratulations! You have successfully accepted the case.
            </AlertTitle>
            You can now view the case details and contact information for the
            lead(s).
          </>
        ),
      }
    case MODAL_TYPES.EATER_ACCEPT_PAID_COMPLETED:
      return {
        modalTitle: 'Agreement Reached',
        modalContent: (
          <>
            <AlertTitle style={{ fontWeight: 700 }}>
              Congratulations! You have successfully accepted the case.
            </AlertTitle>
            Your payment has been processed. You can now view the case details
            and contact information for the lead(s).
          </>
        ),
      }
    case MODAL_TYPES.FEEDER_ACCEPT_FREE_COMPLETED:
      return {
        modalTitle: 'Agreement Reached',
        modalContent: (
          <>
            <AlertTitle style={{ fontWeight: 700 }}>
              Congratulations! You have successfully referred a case.
            </AlertTitle>
            Your counterpart can now view contact information for the lead(s).
          </>
        ),
      }
    case MODAL_TYPES.FEEDER_ACCEPT_PAID_COMPLETED:
      return {
        modalTitle: 'Agreement Reached',
        modalContent: (
          <>
            <AlertTitle style={{ fontWeight: 700 }}>
              Congratulations! You have successfully referred a case.
            </AlertTitle>
            Your payment has been processed. Your counterpart can now view
            contact information for the lead(s).
          </>
        ),
      }
    case MODAL_TYPES.CLIENT_SIGNED_CONFIRMATION:
      return {
        modalTitle: 'Congratulations on Signing the Client!',
      }
    case MODAL_TYPES.CLIENT_SIGNED_REJECTED:
      return {
        modalTitle: 'Case Status Updated',
        modalContent: (
          <Typography variant="body2" mb={1}>
            We have notified the referring attorney.
          </Typography>
        ),
      }
    case MODAL_TYPES.EATER_PROPOSAL_WITHDRAWN:
      return {
        modalTitle: 'Proposal Withdrawn',
        modalContent: (
          <Typography variant="body2">
            Your proposal was withdrawn successfully.
          </Typography>
        ),
      }

    default:
      return {
        modalTitle: '',
        modalContent: '',
      }
  }
}

const ResponseModal = ({
  type,
  onClose,
  title = '',
  bodyContent,
  noteContent,
  icon,
  buttonText = 'Close',
  variant = 'outlined',
  ...rest
}: ResponseModalProps) => {
  let modalTitle = title
  let modalContent = bodyContent
  if (type) {
    const modalProps = getModalData(type)
    modalTitle = modalProps.modalTitle
    if (modalProps.modalContent) {
      modalContent = modalProps.modalContent
    }
  }
  return (
    <Dialog onClose={onClose} {...rest}>
      <Header title={modalTitle} onClose={onClose} />
      <ModalContent sx={theme => ({ padding: theme.spacing(3, 3, 0, 3) })}>
        <Alert
          icon={icon}
          variant={variant}
          {...(type === MODAL_TYPES.ERROR && { severity: 'error' })}
        >
          {modalContent}
          {noteContent}
        </Alert>
      </ModalContent>
      <Footer>
        <Button
          onClick={event => {
            onClose?.(event, 'backdropClick') // small hack to close the modal
          }}
        >
          {buttonText}
        </Button>
      </Footer>
    </Dialog>
  )
}

export default ResponseModal

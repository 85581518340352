import { useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import { JCaseType } from '@/types'
import ErrorMessage from '@/components/Messagges/ErrorMessage'

// eslint-disable-next-line import/no-cycle
import VettedAttorneySearch from '@/pages/Case/components/CaseManagement/components/VettedAttorneySearch'
import { alpha } from '@mui/material'
import Alert from '@mui/material/Alert'
import { Attorney } from '@/pages/Case/components/CaseManagement/types'
import { useFeatureFlags } from '@/optimizely/useFeatureFlags'
import { FeatureFlag } from '@/optimizely/types'
import useTheme from '@mui/material/styles/useTheme'
import { Controller } from 'react-hook-form'
import { TERMS_PRIVACY_LINKS } from '@/constants'
import usePricesAndCredits from '@/hooks/usePricesAndCredits'
import SidebarFee from '@/pages/Case/components/Sidebar/components/SidebarFee'
import JTextField from '../../atoms/JTextField'
import ConfirmationModal from '../ConfirmationModal'
import { CaseInformation } from '../../CaseInformation'
import useSendProposalModal from './useSendProposalModal'
import AcceptProposalPrice from '../MatchFeesModal/components/AcceptProposalPrice'
import { IPendingPayment } from './types'

export interface SendProposalModalProps {
  open: boolean
  onClose: () => void
  onSubmit: ((fee?: number, message?: string) => void) | (() => void)
  jCase: JCaseType
  fee: number
  isInvited?: boolean
  vettedAttorneys?: Attorney[]
  isAdmin?: boolean
  isCaseManager?: boolean
  isPublicCase?: boolean
  onlyOnBehalf?: boolean
  loggedUserId?: string
  pendingPayment?: IPendingPayment
}

const SendProposalModal = ({
  open,
  onClose,
  onSubmit,
  jCase,
  fee,
  isInvited,
  vettedAttorneys = [],
  isAdmin = false,
  isCaseManager = false,
  isPublicCase = false,
  onlyOnBehalf = false,
  loggedUserId,
  pendingPayment,
}: SendProposalModalProps) => {
  const featureFlags = useFeatureFlags()
  const isCaseStatusFlowSimplificationEnabled =
    featureFlags[FeatureFlag.CASE_STATUS_FLOW_SIMPLIFICATION]
  const theme = useTheme()
  const [onBehalfOfAttorneyId, setOnBehalfOfAttorneyId] = useState<
    string | null
  >(null)
  const [onBehalfOfUserId, setOnBehalfOfUserId] = useState<string | null>(null)
  const [termsAccepted, setTermAccepted] = useState(false)

  const {
    creditsData,
    pricesData,
    loading: pricesAndCreditsLoading,
  } = usePricesAndCredits({
    caseId: jCase.id,
    onBehalfOfUserId: onBehalfOfUserId ?? undefined,
    isTransaction: !!isCaseStatusFlowSimplificationEnabled,
  })

  const stripeDiscountPriceId =
    Number(pricesData?.discount ?? 0) > 0 &&
    pricesData?.discount_stripe_price_id
      ? pricesData?.discount_stripe_price_id
      : null

  const {
    handleSubmitModal,
    handleSubmitPrePaidProposal,
    control,
    handleSubmit,
    negotiateFee,
    setNegotiateFee,
    loading: proposalLoading,
    error,
  } = useSendProposalModal({
    onSubmit,
    jCase,
    isInvited,
    defaultOffer: fee,
    onBehalfOfAttorneyId,
    onBehalfOfUserId,
    loggedUserId,
    credits: creditsData?.organization?.credits,
    discount: stripeDiscountPriceId,
    pendingPayment,
  })

  const loading = proposalLoading || pricesAndCreditsLoading

  const caseStatusFlowSimplificationModalTitle =
    pendingPayment?.isPendingPayment
      ? 'Complete Proposal Payment'
      : 'Send Proposal'

  const caseStatusFlowSimplificationDescription =
    pendingPayment?.isPendingPayment
      ? `Complete the payment to send your proposal. If your proposal is accepted you'll be charged the match fee shown below. This fee is 100% refundable, no questions asked, if you're unable to sign the potential client for whatever reason.`
      : null

  const modalTitle = isCaseStatusFlowSimplificationEnabled
    ? caseStatusFlowSimplificationModalTitle
    : 'Send Proposal'

  const description = isCaseStatusFlowSimplificationEnabled
    ? caseStatusFlowSimplificationDescription
    : 'To express your interest in this case and explain why you are the right person to take it, you can send a message to the attorney who uploaded it. If you do not agree with the fee structure, you may suggest an alternative one.'

  const showVettedAttorneySearch =
    vettedAttorneys?.length > 0 && (isAdmin || isCaseManager) && isPublicCase

  const showDescription = Boolean(description)

  const renderBody = () => {
    if (isCaseStatusFlowSimplificationEnabled) {
      return (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              ...(showDescription && {
                my: 4,
              }),
              ...(!showDescription && {
                mb: 4,
              }),
            }}
          >
            <Typography variant="subtitle1">{jCase.name}</Typography>
            <SidebarFee
              fee={
                pendingPayment?.isPendingPayment
                  ? pendingPayment?.pendingPaymentFee ?? 0
                  : fee
              }
              sx={{ mt: 0.5 }}
            />
          </Box>
          {!pendingPayment?.isPendingPayment && (
            <>
              <Typography variant="subtitle2">Make your offer</Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: showVettedAttorneySearch ? 2 : 4,
                }}
              >
                <JTextField
                  type="number"
                  sx={{ maxWidth: { xs: '100%', md: '16%' }, mt: 2, mr: 2.5 }}
                  name="offer"
                  control={control}
                  endAdornment="%"
                  rules={{
                    validate: (proposedFee: string | number | boolean) => {
                      const proposedFeeNumber = Number(proposedFee)
                      return proposedFeeNumber >= 0 && proposedFeeNumber <= 100
                    },
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    color: alpha(theme.palette.common.black, 0.6),
                  }}
                >
                  If your proposal is accepted, you will gain access to the
                  potential client&apos;s contact information and should reach
                  out to them as soon as possible.
                </Typography>
              </Box>
              {showVettedAttorneySearch && (
                <VettedAttorneySearch
                  control={control}
                  vettedAttorneys={vettedAttorneys}
                  isAdmin={isAdmin}
                  isCaseManager={isCaseManager}
                  onSelect={attorney => {
                    const id = attorney?.id ?? null
                    const userId = attorney?.userId ?? null
                    setOnBehalfOfAttorneyId(id)
                    setOnBehalfOfUserId(userId)
                  }}
                  onlyOnBehalf={onlyOnBehalf}
                />
              )}
            </>
          )}
          <FormControlLabel
            control={
              <Controller
                name="termsAndConditions"
                control={control}
                render={({ field: { onChange, ...props } }) => (
                  <Checkbox
                    {...props}
                    onChange={(event, checked) => {
                      setTermAccepted(checked)
                      onChange(event, checked)
                    }}
                  />
                )}
              />
            }
            sx={{
              alignItems: 'flex-start',
            }}
            label={
              <Box
                sx={{
                  fontSize: '0.5rem',
                  lineHeight: '0.75rem',
                  mb: 4,
                }}
              >
                <Typography
                  component="span"
                  variant="caption"
                  lineHeight="16.55px"
                >
                  I understand that Attorney Share is a communication platform
                  for attorneys only, does not certify its lawyer users, and
                  does not advise or provide guidance on Rules of Professional
                  Conduct and other ethical standards. I further understand that
                  it is solely my duty to vet any lawyer to whom I refer a
                  matter, ensure compliance with my jurisdiction&apos;s Rules of
                  Professional Conduct and other ethical standards regarding
                  advertising, entering a referral or co-counsel relationship,
                  engaging in fee sharing, and conducting client representation.
                  This is not a client to attorney referral service. By checking
                  this box, I certify and warrant that I understand the above
                  and will adhere to my duties accordingly. I agree to the{' '}
                  <Link
                    href={TERMS_PRIVACY_LINKS.terms}
                    display="inline"
                    target="_blank"
                  >
                    Terms and Conditions
                  </Link>{' '}
                  <Typography variant="inherit" display="inline">
                    and
                  </Typography>{' '}
                  <Link
                    href={TERMS_PRIVACY_LINKS.privacy}
                    display="inline"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                  .
                </Typography>
              </Box>
            }
          />
          {!pendingPayment?.isPendingPayment && (
            <Alert icon={false} severity="warning">
              <Typography variant="subtitle2">
                This is a Pre-Authorization Only
              </Typography>
              <Typography variant="body2">
                If your proposal is accepted, you will not be charged this match
                fee unless you sign the client. You have 30 days to indicate the
                client was signed or not signed, or else you&apos;ll be charged
                for the match, if applicable.
              </Typography>
            </Alert>
          )}
        </>
      )
    }
    return (
      <>
        <CaseInformation
          title={jCase.name}
          fee={fee}
          showFinancingSource={false}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: isAdmin ? 'column' : 'column-reverse',
            gap: isAdmin ? theme.spacing(0) : theme.spacing(2),
            mt: theme.spacing(1),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: theme.spacing(0),
              mb: theme.spacing(2),
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  onChange={(_, checked) => {
                    setNegotiateFee(checked)
                  }}
                />
              }
              sx={{ pl: theme.spacing(0.5) }}
              label={
                <Typography variant="body1">
                  I want to negotiate fees
                </Typography>
              }
            />
            <Collapse in={negotiateFee}>
              <JTextField
                type="number"
                sx={{ maxWidth: { xs: '100%', md: '60%' }, marginTop: 2 }}
                name="offer"
                control={control}
                label="Referring Attorney Fees Proposed"
                placeholder="Referring Attorney Fees Proposed"
                endAdornment="%"
                rules={{
                  validate: (proposedFee: string | number | boolean) => {
                    const proposedFeeNumber = Number(proposedFee)
                    return proposedFeeNumber >= 0 && proposedFeeNumber <= 100
                  },
                }}
              />
            </Collapse>
          </Box>
          {showVettedAttorneySearch && (
            <VettedAttorneySearch
              control={control}
              vettedAttorneys={vettedAttorneys}
              isAdmin={isAdmin}
              isCaseManager={isCaseManager}
              onSelect={attorney => {
                const id = attorney?.id ?? null
                const userId = attorney?.userId ?? null
                setOnBehalfOfAttorneyId(id)
                setOnBehalfOfUserId(userId)
              }}
              onlyOnBehalf={onlyOnBehalf}
            />
          )}
        </Box>
        <JTextField
          multiline
          name="message"
          control={control}
          minRows={3}
          sx={{ mt: 4 }}
          placeholder="Write a message to the other lawyer..."
        />
      </>
    )
  }

  const termsAcceptedAndRequired = isCaseStatusFlowSimplificationEnabled
    ? !termsAccepted
    : termsAccepted

  const buttonChildrenProps = isCaseStatusFlowSimplificationEnabled ? (
    <AcceptProposalPrice
      pricesData={pricesData}
      creditsData={creditsData}
      isCaseOwner={false}
      isLoading={loading}
      isInvited={isInvited}
      isProposal
    />
  ) : (
    'Send Proposal'
  )

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      title={modalTitle}
      description={description}
      AcceptButtonProps={{
        children: buttonChildrenProps,
        onClick: handleSubmit(
          isCaseStatusFlowSimplificationEnabled
            ? handleSubmitPrePaidProposal
            : handleSubmitModal
        ),
        disabled:
          loading ||
          (onlyOnBehalf && !onBehalfOfAttorneyId) ||
          termsAcceptedAndRequired,
      }}
    >
      {renderBody()}
      {error ? (
        <ErrorMessage
          title="There was a problem submitting your request"
          content={error}
          sx={{
            // NOTE: undefined overrides a maxWidth in ErrorMessage that is too small
            maxWidth: undefined,
            m: 'auto',
            width: '100%',
            '& .MuiAlert-root': {
              alignItems: 'flex-start',
            },
          }}
        />
      ) : null}
    </ConfirmationModal>
  )
}
export default SendProposalModal

/* eslint-disable react/no-array-index-key */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import PersonIcon from '@mui/icons-material/Person'

import JListItem from '@/components/atoms/JListItem'
import { alpha } from '@mui/material'
import { IJUser } from './types'

import * as S from './styles'
import { IJListItem } from '../JListItem/types'

interface JUserProps extends IJUser {
  jListItemProps?: Partial<IJListItem>
}

const JUser = ({
  mainText,
  secondaryText,
  tertiaryText,
  referralDateText,
  overline,
  avatar,
  label,
  onClick,
  size = 'medium',
  isVetted,
  isQualified,
  jListItemProps,
}: JUserProps) => {
  const mText = Array.isArray(mainText) ? mainText : [mainText]
  const sText = Array.isArray(secondaryText) ? secondaryText : [secondaryText]
  const tText = Array.isArray(tertiaryText) ? tertiaryText : [tertiaryText]

  return (
    <S.Container onClick={onClick}>
      {overline && <S.Overline variant="overline">{overline}</S.Overline>}
      <S.List>
        <JListItem
          size={size}
          avatar={avatar || <PersonIcon />}
          isVetted={isVetted}
          isQualified={isQualified}
          text={
            <Box display="flex" flexDirection="column">
              {label && <Typography variant="caption">{label}</Typography>}
              {mainText &&
                mText?.map((mainT, i) => (
                  <Typography
                    key={`${mainT}-${i}`}
                    variant={size === 'xLarge' ? 'h6' : 'subtitle2'}
                  >
                    {mainT}
                  </Typography>
                ))}

              {secondaryText &&
                sText?.map((secondT, i) => (
                  <Typography
                    key={`${secondT}-${i}`}
                    variant={size === 'xLarge' ? 'subtitle2' : 'body2'}
                    color="text.secondary"
                  >
                    {secondT}
                  </Typography>
                ))}

              {tertiaryText &&
                tText?.map((tertT, i) => (
                  <Typography
                    key={`${tertT}-${i}`}
                    variant="caption"
                    color="text.secondary"
                  >
                    {tertT}
                  </Typography>
                ))}
            </Box>
          }
          {...jListItemProps}
        />
      </S.List>
      {referralDateText &&
        (typeof referralDateText === 'string' ? (
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              ml: 7.5,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              color: theme => alpha(theme.palette.common.black, 0.4),
            }}
          >
            {referralDateText}
          </Typography>
        ) : (
          referralDateText
        ))}
    </S.Container>
  )
}

export default JUser

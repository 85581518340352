import { useContext } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import useTheme from '@mui/material/styles/useTheme'
import { ReferralContext } from './ReferralContext'
import { Negotiation } from './Negotiation'

export const Negotiations = () => {
  const theme = useTheme()
  const { caseEntry, sortedCasePurchases } = useContext(ReferralContext)

  if (caseEntry.case_purchase.length === 0) {
    return null
  }

  return (
    <List
      sx={{
        '& .MuiListItem-root:not(:last-child)': {
          borderBottom: { md: `solid 1px ${theme.palette.divider}` },
        },
        mb: 0,
        py: 0,
      }}
    >
      {sortedCasePurchases.map(negotiation => (
        <ListItem key={negotiation.id} sx={{ px: 0, py: { xs: 2, md: 1 } }}>
          <Negotiation casePurchase={negotiation} />
        </ListItem>
      ))}
    </List>
  )
}

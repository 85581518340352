import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  Case_Purchase_Activity,
  GetActivityPurchaseDocument,
  GetActivityPurchaseFromMyOrgDocument,
  GetCasesDocument,
  useCaseExistsLazyQuery,
  useCreateCaseActivityPurchaseMutation,
  useCreateCaseMpPreferenceMutation,
  useDeleteCaseMpPreferenceItemMutation,
  useGetCasesQuery,
  useGetMarketplacePreferencesQuery,
} from '@/gql/appApi'
import useAttorneyId from '@/hooks/useAttorneyId'
import useAttorneyData from '@/hooks/useIsAttorneyVetted'
import useProposals from '@/pages/MyReferrals/hooks/useProposals'
import { get } from 'lodash'
import useGetMyRoleAndOrg from '@/hooks/useGetMyRoleAndOrg'
import { CASE_PURCHASE_STATUS, MP_VISIBILITY } from '@/constants'
import useActiveAttorneyId from './useActiveAttorneyId'
import { NO_PURCHASE_ACTIVITY_STATUS } from '../types'

type TProposals = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export const useCaseData = () => {
  const {
    userOrgData: orgData,
    isAdmin,
    isCaseManager,
    isAttorney,
    orgId,
    loading: orgLoading,
    getIsSameOrg,
    userId: loggedUserId,
  } = useGetMyRoleAndOrg()

  const { id: caseId } = useParams()
  const [
    deleteCaseMpPreferenceItemMutation,
    { loading: deletePreferenceLoading },
  ] = useDeleteCaseMpPreferenceItemMutation({
    refetchQueries: [GetActivityPurchaseDocument, GetCasesDocument],
  })
  const [
    createCaseActivityPurchaseMutation,
    { loading: loadingCreateCaseMutation },
  ] = useCreateCaseActivityPurchaseMutation({
    refetchQueries: [
      GetActivityPurchaseDocument,
      'case_purchase_activity',
      GetCasesDocument,
      'case',
      GetActivityPurchaseFromMyOrgDocument,
      'case_purchase_activity',
    ],
  })
  const [createCaseMpPreferenceMutation, { loading: createPreferenceLoading }] =
    useCreateCaseMpPreferenceMutation({
      refetchQueries: [GetActivityPurchaseDocument, GetCasesDocument],
    })

  const [dataCase, setDataCase] = useState<{
    case_purchase_activity: Case_Purchase_Activity[]
  } | null>(null)
  const [isFinishedFetchingProposals, setIsFinishedFetchingProposals] =
    useState(false)

  const { getIsAttorneyVetted, getIsQualifiedAttorney } = useAttorneyData()
  const { getAttorneyId } = useAttorneyId()

  const isVetted = getIsAttorneyVetted()
  const isQualified = getIsQualifiedAttorney()
  const attorneyId = getAttorneyId()

  const {
    data: caseData,
    loading: getCaseDataLoading,
    refetch: refetchCaseData,
  } = useGetCasesQuery({
    variables: {
      filter: {
        id: {
          _eq: caseId,
        },
      },
    },
    fetchPolicy: 'network-only',
  })

  const isAdminAttorney = isAdmin && isAttorney

  const {
    activeAttorneyId,
    ownerOrgId,
    caseIsTakenByMyOrg,
    prospectProfileFromMyOrg,
    getActivityFromMyOrgLoading,
    hasActivity,
    hasProspectIdChanged,
  } = useActiveAttorneyId({
    isAdminAttorney,
    isCaseManager,
    attorneyId,
    caseId,
    caseData,
    dataCase,
    getIsSameOrg,
    orgId,
  })

  const isPriorityCase =
    caseData?.case[0]?.mp_visibility?.id === MP_VISIBILITY.Priority

  const { data: proposals, loading: loadingProposals } = useProposals({
    isLoadingOrganzation:
      orgLoading &&
      !orgData &&
      !caseData &&
      getActivityFromMyOrgLoading &&
      getCaseDataLoading,
    showAll: true,
    filter: {
      case_purchase_id: {
        case_id: {
          id: {
            _eq: caseId,
          },
        },
        // Case purchases with Blocked, Dismissed, Reject, DeclineAndAvailable status should be excluded
        // This is because a case purchase may be blocked, but have an activity set as Proposed, which would be incorrectly interpreted as a proposal.
        // By excluding these statuses, we allow users to send a new proposal to the case and simplify the logic.
        ...(!isPriorityCase && {
          status: {
            id: {
              _nin: [
                CASE_PURCHASE_STATUS.Blocked,
                CASE_PURCHASE_STATUS.Dismissed,
                CASE_PURCHASE_STATUS.Reject,
                CASE_PURCHASE_STATUS.DeclineAndAvailable,
                CASE_PURCHASE_STATUS.Withdrawn,
                CASE_PURCHASE_STATUS.Expired,
              ],
            },
          },
        }),
      },
      _and: [
        {
          _or: [
            {
              case_purchase_id: {
                created_by: {
                  profiles: {
                    attorney_id: {
                      id: {
                        _eq: activeAttorneyId,
                      },
                    },
                  },
                },
              },
            },
            {
              case_purchase_id: {
                prospect_attorney_id: {
                  profiles: {
                    attorney_id: {
                      id: {
                        _eq: activeAttorneyId,
                      },
                    },
                  },
                },
              },
            },
            {
              case_purchase_id: {
                case_id: {
                  attorney_id: {
                    id: {
                      _eq: activeAttorneyId,
                    },
                  },
                },
              },
            },
          ],
        },
      ],
    },
  })

  const {
    data: preferences,
    loading: preferencesLoading,
    refetch: refetchPreferences,
  } = useGetMarketplacePreferencesQuery({
    variables: {
      filter: {
        case_id: {
          id: {
            _eq: caseId,
          },
        },
        attorney_id: {
          profiles: {
            attorney_id: {
              id: {
                _eq: attorneyId,
              },
            },
          },
        },
      },
    },
    fetchPolicy: 'network-only',
  })

  const [checkCaseExists, { loading: caseExistsLoading }] =
    useCaseExistsLazyQuery({
      variables: {
        filter: {
          id: {
            _eq: caseId,
          },
        },
      },
      fetchPolicy: 'network-only',
    })

  const loadCaseData = () => {
    const dataProposals = Object.keys(proposals || {}).map(caseid => {
      const casePurchases = (proposals as TProposals)?.[caseid]
      const sortedCasePurchases = [...(casePurchases ?? [])]?.sort(
        (a: Case_Purchase_Activity[], b: Case_Purchase_Activity[]) =>
          new Date(String(b?.[0]?.created_ts)).getTime() -
          new Date(String(a?.[0]?.created_ts)).getTime()
      )
      return sortedCasePurchases[0]
    })
    if (dataProposals && dataProposals.length > 0) {
      const info = { case_purchase_activity: [...dataProposals[0]] }
      if (info.case_purchase_activity.length > 0) {
        setDataCase(info)
        return
      }
    }

    const caseWithNoActivity = {
      case_purchase_activity: [
        {
          created_by: { profiles: [{ id: null, attorney_id: { id: null } }] },
          case_purchase_id: {
            created_by: { profiles: [{ id: null, attorney_id: { id: null } }] },
            case_id: caseData
              ? { ...caseData.case[0] }
              : { mp_visibility: { name: 'Public' } },
            prospect_attorney_id: prospectProfileFromMyOrg || {
              profiles: [
                {
                  id: null,
                  attorney_id: {
                    // This small distinction (null/undefined) helps to determine if the CaseNotAvailable modal should be shown. But it is otherwise irrelevant.
                    id: !isFinishedFetchingProposals ? null : undefined,
                  },
                },
              ],
            },
            waterfall_id: null,
            status: { name: NO_PURCHASE_ACTIVITY_STATUS },
          },
        },
      ],
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setDataCase(caseWithNoActivity)
  }

  // This handles a very specific case where the user has no activity of their own as a prospect, but as a Case Manager or Admin Attorney
  // they have access to the activity from other attorneys in their organization. If they have no activity of their own but someone in their Org does,
  // the value of activeAttorneyId will change, therefore the proposals will be refetched and the case data will be updated.
  // By monitoring this, it prevents a bug where the CaseNotAvailable modal would briefly show before the case data is updated to the final state.
  useEffect(() => {
    if (hasProspectIdChanged) {
      setIsFinishedFetchingProposals(false)
    }
  }, [hasProspectIdChanged])

  useEffect(() => {
    setIsFinishedFetchingProposals(!!proposals)
    loadCaseData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    proposals,
    caseData,
    orgData,
    prospectProfileFromMyOrg,
    getActivityFromMyOrgLoading,
  ])

  const isPrivateCase =
    get(
      dataCase,
      'case_purchase_activity[0].case_purchase_id.case_id.mp_visibility.name'
    ) === 'Priority'

  const organizationIdByCase = get(
    dataCase,
    'case_purchase_activity[0].case_purchase_id.case_id.attorney_id.user_id.current_organization[0].organization_id.id',
    ''
  )

  const isCaseFromSomeoneInMyOrganization =
    getIsSameOrg(organizationIdByCase) || getIsSameOrg(ownerOrgId)

  const caseIsTakenByMyOrgAndNegotiating =
    caseIsTakenByMyOrg &&
    dataCase?.case_purchase_activity?.[0]?.activity_entry_status?.id !==
      CASE_PURCHASE_STATUS.Proposed

  const caseStatus = caseData?.case?.[0]?.status

  return {
    dataCase,
    getCaseDataLoading,
    refetchCaseData,
    preferences,
    preferencesLoading:
      preferencesLoading ||
      deletePreferenceLoading ||
      createPreferenceLoading ||
      caseExistsLoading,
    isVetted,
    isQualified,
    /**
     * This ID is the one that is going to be used to create the activity purchase and check all status of negotiations.
     *
     * If the user is an attorney, this will be the same as the `attorneyId`.
     *
     * If the user is an `adminAttorney`, this will could be the same as the `attorneyId` if the current user is directly related to the case.
     * Otherwise, this will be the `attorneyId` of the attorney that is directly related to the case and will act on his behalf.
     *
     * If the user is a case manager, this will be the `attorneyId` of the attorney that is directly related to the case and will act on his behalf.
     */
    activeAttorneyId,
    caseId,
    refetchPreferences,
    deleteCaseMpPreferenceItemMutation,
    createCaseActivityPurchaseMutation,
    createCaseMpPreferenceMutation,
    checkCaseExists,
    loadingCreateCaseMutation,
    isPrivateCase,
    isCaseFromSomeoneInMyOrganization,
    userOrgData: orgData,
    isAdmin,
    isAttorney,
    orgId,
    loggedUserId,
    loggedUserOrganizationId: orgId,
    isAdminAttorney,
    isCaseManager,
    /**
     * @description This is the attorneyId of the user that is logged in.
     */
    currentUserAttorneyId: attorneyId,
    caseIsTakenByMyOrg: caseIsTakenByMyOrgAndNegotiating,
    hasActivity,
    optionsLoading:
      orgLoading ||
      !orgData ||
      !caseData ||
      !proposals ||
      getActivityFromMyOrgLoading ||
      getCaseDataLoading ||
      loadingProposals ||
      !isFinishedFetchingProposals,
    caseStatus,
  }
}

import React from 'react'
import { Avatar, Button, Grid, Stack, Typography, Box } from '@mui/material'
import { Person } from '@mui/icons-material'

type FragmentProfilePictureProps = {
  showTitle?: boolean
  profilePhoto: string | null
  profilePhotoRef: React.MutableRefObject<HTMLInputElement | null>
  onChangeProfilePhoto: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>
  onRemoveProfilePhoto: () => void
}

const FragmentProfilePicture = ({
  showTitle = true,
  profilePhoto,
  profilePhotoRef,
  onChangeProfilePhoto,
  onRemoveProfilePhoto,
}: FragmentProfilePictureProps) => {
  return (
    <Grid container mt={1.5} direction="column" gap={{ xs: 1.25 }}>
      {showTitle ? (
        <Typography variant="subtitle1">Profile Picture</Typography>
      ) : null}
      <Grid
        container
        gap={2.5}
        pb={2}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent={{
          xs: 'center',
          sm: 'flex-start',
        }}
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={3}
          display="flex"
          justifyContent={{
            xs: 'center',
            sm: 'flex-start',
          }}
        >
          <Avatar
            src={profilePhoto || ''}
            sx={{
              width: {
                xs: '8rem',
                md: '6rem',
                lg: '8rem',
              },
              height: {
                xs: '8rem',
                md: '6rem',
                lg: '8rem',
              },
              cursor: 'pointer',
              backgroundColor: theme => theme.palette.grey[100],
            }}
            onClick={() => profilePhotoRef.current?.click()}
          >
            <Person
              sx={{
                width: '3rem',
                height: '3rem',
                color: theme => theme.palette.grey[200],
              }}
            />
          </Avatar>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Stack
            alignItems={{ xs: 'center', sm: 'flex-start' }}
            direction="column"
            justifyContent="center"
            marginX={{ xs: 'auto', sm: 0 }}
            textAlign={{ xs: 'center', sm: 'left' }}
          >
            <input
              ref={profilePhotoRef}
              hidden
              accept="image/*"
              id="profilePhoto"
              style={{ display: 'none' }}
              type="file"
              onChange={onChangeProfilePhoto}
            />
            <Box
              display="flex"
              gap={2}
              width={{ xs: '100%', sm: 'auto' }}
              justifyContent={{ xs: 'center', sm: 'flex-start' }}
            >
              <Button
                variant="outlined"
                onClick={() => profilePhotoRef.current?.click()}
              >
                {profilePhoto ? 'Change Image' : 'Upload Profile Picture'}
              </Button>
              {profilePhoto && (
                <Button
                  color="error"
                  sx={{
                    visibility: `${profilePhoto ? 'visible' : 'hidden'}`,
                  }}
                  onClick={onRemoveProfilePhoto}
                >
                  Remove Image
                </Button>
              )}
            </Box>
            <Typography
              variant="body2"
              sx={{
                width: {
                  xs: '50%',
                  sm: '100%',
                },
                color: theme => theme.palette.text.secondary,
                mt: 2,
              }}
            >
              Upload files under 10MB in .JPEG, .PNG, .GIF, or .BMP
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FragmentProfilePicture

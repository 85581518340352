import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import LeadInfoDisplay from '../LeadInfoDisplay'
import { useCaseContext } from '../../../hooks/useCaseContext'
import { Lead } from '../../../types'

const AgreementInfo: React.FC = () => {
  const theme = useTheme()
  const { caseData } = useCaseContext()

  if (!caseData) return null

  if (!caseData.sidebar.special_agreements) return null

  const leads = caseData.sidebar.special_agreements.leads ?? []

  const handleClientSigned = () => {
    console.log('Client signed')
    // Implement the logic for when the client is signed
  }

  const handleDidNotSign = () => {
    console.log('Did not sign')
    // Implement the logic for when the client did not sign
  }

  return (
    <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
      {leads.map((lead: Lead) => (
        <LeadInfoDisplay key={lead.email} lead={lead} />
      ))}

      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        p={3}
        textAlign="center"
        bgcolor={theme.palette.grey[50]}
        border="1px solid"
        borderColor={theme.palette.divider}
        borderRadius={1}
      >
        <Typography variant="subtitle1" gutterBottom>
          Did you sign the client?
        </Typography>
        <Box display="flex" gap={2} justifyContent="center">
          <Button variant="text" color="error" onClick={handleDidNotSign}>
            Did Not Sign
          </Button>
          <Button variant="contained" onClick={handleClientSigned}>
            Client Signed
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default AgreementInfo

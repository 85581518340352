import { JCaseType } from '@/types'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import { useState } from 'react'
import JTextField from '../../atoms/JTextField'
import ConfirmationModal from '../ConfirmationModal'
import useRequestDataRoomModal from './useRequestDataRoomModal'

export interface IRequestDataRoomModalProps {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  jCase: JCaseType | null
  hasDownloadAccess?: boolean
}

const RequestDataRoomModal = ({
  open,
  onClose,
  onSubmit,
  jCase,
  hasDownloadAccess,
}: IRequestDataRoomModalProps) => {
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false)
  const { handleSubmitModal, control, handleSubmit, loading } =
    useRequestDataRoomModal({ onSubmit, jCase, hasDownloadAccess })

  const title = hasDownloadAccess
    ? 'Download Data Room Files'
    : 'Data Room Access'

  const description = hasDownloadAccess
    ? 'You have been granted access to download files in the Data Room.'
    : 'You must be approved by the referring attorney to access Data Room files. Submit a request below.'

  const buttonText = hasDownloadAccess ? 'Download Files' : 'Request Access'

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      title={title}
      description={description}
      AcceptButtonProps={{
        children: buttonText,
        onClick: handleSubmit(handleSubmitModal),
        disabled: loading || !disclaimerAccepted,
      }}
    >
      {!hasDownloadAccess && (
        <JTextField
          multiline
          name="message"
          control={control}
          minRows={3}
          placeholder="Write a message to the other lawyer..."
        />
      )}
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <Checkbox
          onClick={() => setDisclaimerAccepted(prevAccepted => !prevAccepted)}
          checked={disclaimerAccepted}
          sx={theme => ({ p: 0, mr: theme.spacing(1) })}
        />
        <Typography variant="caption">
          I understand that the rules of professional conduct apply to my
          request for Data Room access, as well as to any conduct I subsequently
          take in connection with that access. As such, I agree to keep
          confidential any confidential information that is provided to me
          pursuant to my request.
        </Typography>
      </Box>
    </ConfirmationModal>
  )
}
export default RequestDataRoomModal

import { useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import JLeads from '@/components/atoms/JLeads'
import { CaseEntry } from '@/pages/MyReferralsV2/types'

interface ICardLeadsProps {
  leads: CaseEntry['leads']
}

const MyReferralsLeads = ({ leads }: ICardLeadsProps) => {
  const [showLeads, setShowLeads] = useState(false)

  const leadProfiles = useMemo(
    () =>
      leads.map((lead, idx) => (
        <Grid
          key={(lead.email ?? '') + (lead.phone ?? '')}
          item
          xs={12}
          md={6}
          sx={{
            mb: idx === leads.length - 1 ? 0 : 1,
          }}
        >
          <JLeads
            firstName={lead.first_name}
            lastName={lead.last_name}
            email={lead.email}
            phone={lead.phone}
          />
        </Grid>
      )),
    [leads]
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pl: 3,
      }}
    >
      <Button
        size="small"
        color="primary"
        endIcon={
          showLeads ? (
            <KeyboardArrowUpIcon
              sx={{
                height: '16px',
              }}
            />
          ) : (
            <KeyboardArrowDownIcon
              sx={{
                height: '16px',
              }}
            />
          )
        }
        onClick={() => setShowLeads(!showLeads)}
        sx={{
          width: 'fit-content',
          mb: showLeads ? 2 : 0,
        }}
      >
        {showLeads ? 'Hide' : 'Show'} Lead Information
      </Button>
      <Collapse in={showLeads}>
        <Grid container>{leadProfiles}</Grid>
      </Collapse>
    </Box>
  )
}

export default MyReferralsLeads

import request from '@/axios/config'
import { useCallback, useState } from 'react'

type TResendEmailVerification = {
  email: string
}

export const useResendEmailVerification = () => {
  const [isLoading, setIsLoading] = useState(false)
  const resendVerificationEmail = useCallback(
    async ({ email }: TResendEmailVerification): Promise<'success' | null> => {
      const payload = {
        email,
      }
      setIsLoading(true)
      try {
        const response = await request.post('/uservalidation/resend', payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        return response.data
      } catch (error) {
        return null
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  return { resendVerificationEmail, isLoading }
}

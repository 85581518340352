import React from 'react'
import { Box, Typography, Alert } from '@mui/material'
import parse from 'html-react-parser'
import { useCaseContext } from '../../hooks/useCaseContext'
import { Alert as AlertType } from '../../types'

const parseText = (text: string) =>
  parse(text, {
    replace: domNode => {
      if (domNode.type === 'tag' && domNode.name === 'a') {
        const childNode = domNode.children[0]
        return (
          <a
            href={domNode.attribs.href}
            target={domNode.attribs.target}
            style={{ textDecoration: 'underline' }}
          >
            {childNode.type === 'text' ? childNode.data : ''}
          </a>
        )
      }
      return domNode
    },
  })

const CustomParse: React.FC<{ text: string }> = ({ text }) => {
  const parsedText = parseText(text)
  return parsedText
}

const AlertItem: React.FC<{ alert: AlertType }> = ({ alert }) => (
  <Alert severity={alert.type} sx={{ width: '100%' }} icon={false}>
    <Typography variant="subtitle1" mb={0.5}>
      {alert.title}
    </Typography>
    <Typography variant="body2">{alert.subtitle}</Typography>
    <Typography variant="body1">
      <CustomParse text={alert.message} />
    </Typography>
  </Alert>
)

const CaseAlerts: React.FC = () => {
  const { caseData } = useCaseContext()

  if (!caseData || !caseData.alerts || caseData.alerts.length === 0) {
    return null
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {caseData.alerts.map((alert: AlertType) => (
        <AlertItem key={alert.id} alert={alert} />
      ))}
    </Box>
  )
}

export default CaseAlerts

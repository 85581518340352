import { gql } from '@apollo/client'

export const signInMutation = gql`
  mutation SignIn($email: String!, $password: String!) {
    auth_login(email: $email, password: $password) {
      access_token
      refresh_token
    }
  }
`

export const signOutMutation = gql`
  mutation SignOut($refreshToken: String) {
    auth_logout(refresh_token: $refreshToken)
  }
`

export const refreshTokenMutation = gql`
  mutation AuthRefresh($refreshToken: String, $mode: auth_mode) {
    auth_refresh(refresh_token: $refreshToken, mode: $mode) {
      access_token
      refresh_token
      expires
    }
  }
`

export const createUserMutation = gql`
  mutation CreateUser($data: create_directus_users_input!) {
    create_users_item(data: $data) {
      id
    }
  }
`

export const updatePWMutation = gql`
  mutation updatePW($data: update_directus_users_input) {
    update_users_me(data: $data) {
      id
      email
    }
  }
`

export const resetPasswordRequest = gql`
  mutation resetPasswordRequest($email: String!, $resetUrl: String) {
    auth_password_request(email: $email, reset_url: $resetUrl)
  }
`

export const resetPassword = gql`
  mutation resetPassword($token: String!, $password: String!) {
    auth_password_reset(token: $token, password: $password)
  }
`

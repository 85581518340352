import { usePhoneNumber } from '@/hooks/usePhoneNumber'

export const PhoneNumber = ({ children }: { children: string | number }) => {
  const formattedPhoneNumber = usePhoneNumber(children)

  return (
    <a href={`tel:${children}`} title={`Call ${formattedPhoneNumber}`}>
      {formattedPhoneNumber}
    </a>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react'
import { educationDefaultValues } from '@/pages/CreateProfile/Education/types'
import { useNavigate } from 'react-router-dom'
import useUpdateAttorneyProfile from '@/pages/CreateProfile/hooks/useUpdateAttorneyProfile'
import useAuthentication from '@/hooks/useAuthentication'
import { TTokenValidationResponse } from '@/hooks/useTokenValidation'
import useOrganization from '@/hooks/useOrganization'
import { ACCOUNT_TYPE } from '@/constants'
import { getSignInRedirectionByRole } from '@/pages/SignIn/utils'
import { useGetDelegatedUsersByCaseManagerLazyQuery } from '@/gql/systemApi'
import {
  ProfessionalLicenseFormValues,
  getDefaultValues as getProfessionalLicenseDefaultValues,
} from '@/components/ProfessionalLicensesForm'
import dayjs from 'dayjs'
import { sessionStorageService } from '@/services/SessionStorage/SessionStorageService'
import { communicationPreferencesDefaultValues } from '@/components/CommunicationPreferences/hooks/useCommunicationPreferences'
import { cloneDeep, merge } from 'lodash'
import useCreateInvitedAttorneyFlow from './useCreateInvitedAttorneyFlow'
import { TInvitedAttorneyFormState } from '../types'
import { CreateAccountData } from '../../InitialStep/types'

interface IUseCreateInvitedAttorneyProps {
  createAccountValues: CreateAccountData
  tokenData: TTokenValidationResponse
}

export const useCreateInvitedAttorney = ({
  createAccountValues,
  tokenData,
}: IUseCreateInvitedAttorneyProps) => {
  const {
    createAccount,
    updateAttorneyProfile,
    loading: createAccountLoading,
  } = useAuthentication()
  const {
    getOrganizationData,
    updateOrganizationUser,
    loading: orgLoading,
  } = useOrganization()
  const navigate = useNavigate()
  const { state, next, back, goToCommunicationPreferences } =
    useCreateInvitedAttorneyFlow()
  const {
    tokenRefresh,
    updateAttorneyProfile: updateProfile,
    loading: refreshTokenLoading,
  } = useUpdateAttorneyProfile()
  const [error, setError] = useState('')
  const [formState, setFormState] = useState<TInvitedAttorneyFormState>({
    professionalLicenses: [getProfessionalLicenseDefaultValues()],
    practiceAreas: [],
    personalInfo: {
      position: '',
      bio: '',
    },
    education: educationDefaultValues,
    communicationPreferences: communicationPreferencesDefaultValues,
  })
  const [getUserMeWithRoleData] = useGetDelegatedUsersByCaseManagerLazyQuery()

  const updateFormState = (
    key: keyof TInvitedAttorneyFormState,
    value: TInvitedAttorneyFormState[keyof TInvitedAttorneyFormState]
  ) => {
    setFormState(prevState => ({ ...prevState, [key]: value }))
  }

  const submitAndContinue = async (
    key: keyof TInvitedAttorneyFormState,
    data: any
  ) => {
    updateFormState(key, data)
    next()
  }

  const handleOnSkip = useCallback(async () => {
    const result = await tokenRefresh()
    if (result) {
      const userMe = await getUserMeWithRoleData()
      navigate(
        getSignInRedirectionByRole(userMe?.data?.users_me?.role?.id || '')
      )
    }
  }, [getUserMeWithRoleData, navigate, tokenRefresh])

  const handleCreateAccount = useCallback(
    async (
      formValues?:
        | ProfessionalLicenseFormValues
        | { professionalLicenses: ProfessionalLicenseFormValues[] }
    ) => {
      const licenseValuesArray: ProfessionalLicenseFormValues[] = []

      if (formValues && 'professionalLicenses' in formValues) {
        licenseValuesArray.push(...formValues.professionalLicenses)
      } else if (formValues) {
        licenseValuesArray.push(formValues)
      }

      updateFormState('professionalLicenses', licenseValuesArray)

      sessionStorageService.clear()
      const result = await createAccount({
        email: tokenData.email.toLowerCase(),
        password: createAccountValues.password,
        firstName: createAccountValues.firstName,
        lastName: createAccountValues.lastName,
        tags: JSON.stringify({
          target_role: tokenData.role,
          target_organization: tokenData.organization,
          account_type: ACCOUNT_TYPE.Organization,
        }),
      })
      if (result.id && result.profileId) {
        const updateAttorneyResult = await updateAttorneyProfile({
          licenses: licenseValuesArray.map(license => ({
            barNumber: license.barNumber,
            isActive: license.isActive,
            isDisciplined: license.isDisciplined,
            isSued: license.isSued,
            stateId: license.state,
            malpracticeInsuranceId: license.malpracticeInsuranceId,
            insuranceExpirationDate: license.insuranceExpirationDate
              ? dayjs(license.insuranceExpirationDate).format('YYYY-MM-DD')
              : null,
          })),
          profileId: result.profileId,
          phoneNumber: createAccountValues.phone,
        })
        if (updateAttorneyResult.id) {
          next()
        }
        if (updateAttorneyResult.updateAttorneyProfileErrors) {
          setError(updateAttorneyResult.updateAttorneyProfileErrors.base)
          return
        }
      }
      if (result.createAccountErrors) {
        setError(result.createAccountErrors.base)
      }
    },
    [
      createAccount,
      createAccountValues.firstName,
      createAccountValues.lastName,
      createAccountValues.password,
      createAccountValues.phone,
      next,
      tokenData.email,
      tokenData.organization,
      tokenData.role,
      updateAttorneyProfile,
    ]
  )

  const handleUpdateOrgUser = useCallback(
    async (
      communicationPreferences: TInvitedAttorneyFormState['communicationPreferences']
    ) => {
      updateFormState('communicationPreferences', communicationPreferences)

      let finalData = cloneDeep(formState)

      if (communicationPreferences) {
        finalData = merge(formState, { communicationPreferences })
      }

      const updateResult = await updateProfile({
        education: formState.education,
        practiceAreas: formState.practiceAreas,
        personalInfo: {
          bio: finalData?.personalInfo?.bio || '',
        },
        communicationPreferences,
      })

      if (!updateResult.id) {
        setError(updateResult.updateAttorneyProfileErrors?.base || '')
        return
      }

      if (finalData.personalInfo.position) {
        updateFormState('personalInfo', {
          position: finalData.personalInfo.position,
          bio: finalData.personalInfo.bio,
        })
        const organizationDataResult = await getOrganizationData()
        if (!organizationDataResult.organizationUserItemId) {
          setError(
            organizationDataResult?.error || 'Organization User ID not found'
          )
          return
        }
        const updateOrgUserResult = await updateOrganizationUser({
          organizationUserItemId:
            organizationDataResult.organizationUserItemId || '',
          positionName: finalData.personalInfo.position,
          positionBio: finalData.personalInfo.bio || '',
        })
        if (updateOrgUserResult.organizationUserItemId) {
          handleOnSkip()
          return
        }
        if (updateOrgUserResult.updateOrganizationUserErrors) {
          setError(updateOrgUserResult.updateOrganizationUserErrors.base)
          return
        }
      }
      handleOnSkip()
    },
    [
      getOrganizationData,
      handleOnSkip,
      updateProfile,
      updateOrganizationUser,
      formState,
    ]
  )

  return {
    state,
    formState,
    next,
    back,
    handleCreateAccount,
    submitAndContinue,
    handleOnSkip,
    handleUpdateOrgUser,
    loading: refreshTokenLoading || createAccountLoading || orgLoading,
    error,
    goToCommunicationPreferences,
  }
}

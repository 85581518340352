import { useState } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { alpha } from '@mui/material'
import { WaterfallReferralInfoModal } from './WaterfallReferralInfoModal'

const HowDoesItWork = () => {
  const theme = useTheme()
  const [isHowItWorksModalOpen, setIsHowItWorksModalOpen] = useState(false)

  const handleOpenHowItWorksModal = () => {
    setIsHowItWorksModalOpen(true)
  }

  const handleCloseHowItWorksModal = () => {
    setIsHowItWorksModalOpen(false)
  }

  return (
    <>
      <Typography
        component="div"
        variant="body1"
        color={alpha(theme.palette.common.black, 0.6)}
        sx={{
          mt: 2,
        }}
      >
        Offer cases exclusively to attorneys you select. Create as many groups
        as you need to meet your requirements.
        <Button
          variant="text"
          sx={{
            p: 0,
            ml: 0.25,
          }}
          onClick={handleOpenHowItWorksModal}
        >
          <Typography
            variant="body2"
            sx={{
              mb: 0.5,
              textDecoration: 'underline',
            }}
          >
            How does it work?
          </Typography>
        </Button>
      </Typography>
      <WaterfallReferralInfoModal
        open={isHowItWorksModalOpen}
        onClose={handleCloseHowItWorksModal}
      />
    </>
  )
}

export default HowDoesItWork

import { useState } from 'react'
import Typography from '@mui/material/Typography'

type ModalProps = {
  open: boolean
  type?: string
  id?: string
  title?: string
  description?: React.ReactNode
  buttonText?: string
}

const modalDefault: ModalProps = {
  open: false,
}

interface UseRemoveActionsProps {
  removeAllFunction: () => void
  removeFunction: (id: string) => void
  isFeeAgreement?: boolean
}

const useRemoveActions = ({
  removeAllFunction,
  removeFunction,
  isFeeAgreement = false,
}: UseRemoveActionsProps) => {
  const [modal, setModal] = useState<ModalProps>(modalDefault)
  const [removedFileId, setRemoveFileId] = useState('')

  const removeTitle = isFeeAgreement
    ? 'Remove Fee Agreement files confirmation'
    : 'Remove Data Room files confirmation'

  const fileType = isFeeAgreement ? 'Fee Agreement' : 'Data Room'

  const onRemove = (id: string, fileName: string) => {
    setRemoveFileId(id)
    setModal({
      open: true,
      type: 'remove',
      id,
      title: removeTitle,
      description: (
        <Typography component="span" variant="body1">
          Are you sure you want to remove <b>{fileName}</b>?
          <Typography mt={2}>
            This action cannot be undone, but you will be able to upload the
            file again at any time.
          </Typography>
        </Typography>
      ),
      buttonText: `Yes, remove the file`,
    })
  }

  const onRemoveAll = (id: string) => {
    setModal({
      open: true,
      type: 'removeAll',
      id,
      title: removeTitle,
      description: (
        <Typography variant="subtitle1">
          Are you sure you want to remove all the {fileType} files?
          <Typography mt={2}>
            This action cannot be undone, but you will be able to upload the
            files again at any time.
          </Typography>
        </Typography>
      ),
      buttonText: `Yes, remove ${fileType} files`,
    })
  }

  const onCloseModal = () => {
    setModal({ open: false })
  }

  const onSubmitModal = () => {
    const { type } = modal
    if (type === 'removeAll' && typeof removeAllFunction === 'function') {
      removeAllFunction()
    } else if (
      type === 'remove' &&
      typeof removeFunction === 'function' &&
      !!removedFileId
    ) {
      removeFunction(removedFileId)
    }
    setRemoveFileId('')
    setModal({ open: false })
  }

  return {
    modal,
    setModal,
    onRemove,
    onRemoveAll,
    onCloseModal,
    onSubmitModal,
  }
}

export default useRemoveActions

import { useCallback } from 'react'

import { useStateMachine } from '@/hooks/useStateMachine'

const useCreateInvitedAttorneyFlow = () => {
  const [state, send] = useStateMachine({})({
    initial: 'professionalLicense',
    on: {
      INITIAL_STEP: 'professionalLicense',
      COMMUNICATION_PREFERENCES: 'communicationPreferences',
    },
    states: {
      professionalLicense: {
        on: {
          NEXT: 'practiceAreas',
        },
      },
      practiceAreas: {
        on: {
          PREVIOUS: 'professionalLicense',
          NEXT: 'education',
        },
      },
      education: {
        on: {
          PREVIOUS: 'practiceAreas',
          NEXT: 'personalInfo',
        },
      },
      personalInfo: {
        on: {
          PREVIOUS: 'education',
          NEXT: 'communicationPreferences',
        },
      },
      communicationPreferences: {
        on: {
          PREVIOUS: 'personalInfo',
        },
      },
    },
  })

  const next = useCallback(() => send('NEXT'), [send])
  const back = useCallback(() => send('PREVIOUS'), [send])
  const goToCommunicationPreferences = useCallback(
    () => send('COMMUNICATION_PREFERENCES'),
    [send]
  )

  return {
    state,
    send,
    next,
    back,
    goToCommunicationPreferences,
  }
}

export default useCreateInvitedAttorneyFlow

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { theme } from '@/styles/theme'
import { IReferralStatusBarProps, ReferralType } from '../types'

const greenColorScheme = {
  backgroundColor: theme.palette.success.lightest,
  color: theme.palette.success.main,
}

const yellowColorScheme = {
  backgroundColor: theme.palette.warning.light,
  color: theme.palette.warning.dark,
}

const redColorScheme = {
  backgroundColor: theme.palette.error.light,
  color: theme.palette.error.main,
}

const getStatusText = (referralType: ReferralType) => {
  switch (referralType) {
    case 'invited':
      return 'You have been invited to a case'
    case 'assigned':
      return 'You have been assigned to a case'
    case 'proposed':
      return 'New proposal received'
    case 'counteroffer':
      return 'You have received a counteroffer'
    case 'rejected':
      return 'Your offer has been rejected'
    case 'acceptedByCounterpart':
      return 'Proposal accepted. You are one step closer to reaching an agreement'
    case 'acceptedByCurrentUser':
      return 'Proposal accepted. Waiting counterpart response to close the deal.'
    case 'accepted':
    case 'agreement':
      return 'Agreement reached. New case referred.'
    case 'waterfall':
      return 'New Waterfall Referral™ received'
    case 'matched':
      return 'You Reached an Agreement'
    case 'dataroom':
    default:
      return ''
  }
}

const getColor = (referralType: ReferralType) => {
  switch (referralType) {
    case 'rejected':
      return redColorScheme
    case 'accepted':
    case 'acceptedByCurrentUser':
    case 'acceptedByCounterpart':
    case 'agreement':
      return yellowColorScheme
    default:
      return greenColorScheme
  }
}

const ReferralStatusBar = ({
  referralType,
  dataroomRequest,
}: IReferralStatusBarProps) => {
  const { backgroundColor, color } = getColor(referralType)
  const statusText = getStatusText(referralType)

  return (
    <Box
      sx={{
        backgroundColor,
        py: 1.5,
        px: 2.5,
        alignContent: 'center',
        display: 'flex',
      }}
    >
      <Typography
        variant="captionBold"
        sx={{ color, textTransform: 'uppercase', mr: statusText ? 2 : 0 }}
      >
        {statusText}
      </Typography>
      {dataroomRequest && (
        <Typography
          variant="captionBold"
          sx={{ color, textTransform: 'uppercase' }}
        >
          Dataroom Access Request
        </Typography>
      )}
    </Box>
  )
}

export default ReferralStatusBar

import { useState } from 'react'

import { InviteToCaseModal, ResponseModal } from '@/components'
import { ProfileType } from '@/types'
import { getFirms } from '@/utils/profile'

export interface UseInviteToCaseProps {
  profile: ProfileType
  prospectAttorneyId: string
}

const useInviteToCase = ({
  prospectAttorneyId,
  profile,
}: UseInviteToCaseProps) => {
  const [openModal, setOpenModal] = useState(false)
  const [openResponseModal, setOpenResponseModal] = useState(false)
  const fullName = `${profile?.first_name} ${profile?.last_name}`

  const onInviteToCase = () => {
    setOpenModal(false)
    setOpenResponseModal(true)
  }

  const renderResponseModal = () => (
    <ResponseModal
      type={null}
      onClose={() => setOpenResponseModal(false)}
      open={openResponseModal}
      bodyContent="Your invitation was sent successfully"
      title="Invitation Sent"
    />
  )

  const renderModal = () => (
    <InviteToCaseModal
      profile={{
        avatar: profile?.avatar?.id,
        mainText: fullName,
        secondaryText: getFirms(profile.firms || []),
      }}
      attorneyId={prospectAttorneyId}
      onClose={() => setOpenModal(false)}
      open={openModal}
      onSubmit={onInviteToCase}
    />
  )

  return {
    openModal: openModal || openResponseModal,
    setOpenModal,
    renderModal: openModal ? renderModal : renderResponseModal,
  }
}

export default useInviteToCase

import { useState } from 'react'
import Button from '@mui/material/Button'
import OutlineLockOpenCheck from '@/assets/OutlineLockOpenCheck.svg'
import BulkInviteToCaseModal from '@/components/Modals/BulkInviteToCaseModal'

interface IInviteToCaseButtonProps {
  caseId: string
  name: string
  loading?: boolean
  representingAttorneyId?: {
    attorneyId: string
    userId: string
  }
}

const InviteToCaseButton = ({
  caseId,
  name,
  loading,
  representingAttorneyId,
}: IInviteToCaseButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <>
      <Button
        size="small"
        color="primary"
        startIcon={
          <img
            alt="JHQ Badge"
            src={OutlineLockOpenCheck}
            style={{ height: '16px', width: '16px' }}
          />
        }
        onClick={() => setModalOpen(true)}
        disabled={loading}
        sx={{
          m: 1,
        }}
      >
        Invite Attorneys to Case
      </Button>
      {modalOpen && (
        <BulkInviteToCaseModal
          jCase={{
            id: caseId,
            name,
          }}
          open={modalOpen}
          onSubmit={() => setModalOpen(false)}
          onClose={() => setModalOpen(false)}
          onBehalfOf={representingAttorneyId?.attorneyId}
          onBehalfOfUserId={representingAttorneyId?.userId}
        />
      )}
    </>
  )
}

export default InviteToCaseButton

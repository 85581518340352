import { useSizes } from '@/hooks/useSizes'
import SaveOutlined from '@mui/icons-material/SaveOutlined'
import CircularProgress from '@mui/material/CircularProgress'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import useTheme from '@mui/material/styles/useTheme'
import React from 'react'

interface ISubmitButtons {
  disabled?: boolean
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
  isSubmitting?: boolean
}

const SubmitButtons: React.FC<ISubmitButtons> = ({
  disabled,
  onCancel,
  isSubmitting,
}: ISubmitButtons) => {
  const theme = useTheme()
  const { isGreaterMd } = useSizes()

  const renderButtons = () => {
    if (isGreaterMd) {
      return (
        <Box>
          <Divider
            sx={{
              mt: 1,
              borderColor: '1px solid RGBA(0, 0, 0, 0.12)',
            }}
            variant="fullWidth"
          />
          <Grid
            container
            direction="row"
            spacing={2}
            sx={{
              padding: theme.spacing(1.5, 0),
            }}
          >
            <Grid item md={4} xs={12}>
              &nbsp;
            </Grid>
            <Grid item md={8} xs={12}>
              <Grid
                container
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                mb={2}
                mt={2}
                rowSpacing={1}
                justifyContent="flex_start"
              >
                <Grid item xs={4}>
                  <Button
                    sx={{ width: '100%', bgcolor: 'secondary.main' }}
                    variant="contained"
                    type="submit"
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress
                          size={theme.spacing(2)}
                          color="primary"
                        />
                      ) : (
                        <SaveOutlined />
                      )
                    }
                    disabled={disabled}
                  >
                    Save Changes
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    sx={{ width: '100%', height: '100%' }}
                    variant="text"
                    onClick={onCancel && onCancel}
                    disabled={disabled}
                  >
                    Discard Changes
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )
    }
    return (
      <AppBar
        color="inherit"
        component="div"
        position="fixed"
        sx={{
          top: 'auto',
          bottom: 0,
          pl: 3,
          pr: 3,
        }}
      >
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mb={2}
          mt={2}
          rowSpacing={1}
        >
          <Grid item xs={6}>
            <Button
              disabled={disabled}
              size="large"
              sx={{ width: '100%' }}
              variant="text"
              onClick={onCancel && onCancel}
            >
              Discard Changes
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disabled}
              size="large"
              sx={{
                width: '100%',
                bgcolor: 'secondary.main',
              }}
              variant="contained"
              type="submit"
              startIcon={
                isSubmitting ? (
                  <CircularProgress size={theme.spacing(2)} color="primary" />
                ) : null
              }
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </AppBar>
    )
  }
  return renderButtons()
}

export default SubmitButtons

import Countdown from 'react-countdown'
import { Case_Purchase } from '@/gql/appApi'
import Alert, { AlertProps } from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import { timeService } from '@/services/Time/TimeService'
import { isTimeUnitValue } from '@/constants'

interface InvitedMatchMessageProps extends AlertProps {
  waterfallCasePurchase?: Case_Purchase
}

export const InvitedMatchMessage = ({
  sx,
  waterfallCasePurchase,
}: InvitedMatchMessageProps) => {
  const timeStart = new Date(waterfallCasePurchase?.active_ts)
  const timeUnit = waterfallCasePurchase?.waterfall_id?.items?.[0]?.time_unit
  const timedAccessLimit =
    waterfallCasePurchase?.waterfall_id?.items?.[0]?.timed_access_limit

  let expirationTime: Date | null = null

  if (timeUnit && timedAccessLimit) {
    const { id: timeUnitValue } = timeUnit

    if (!isTimeUnitValue(timeUnitValue)) {
      throw TypeError(`Not a time unit value: ${timeUnitValue}`)
    }

    expirationTime = timeService.getEndDate(
      timeStart,
      timeUnitValue,
      timedAccessLimit
    )
  }

  return (
    <Alert
      severity="success"
      sx={[{ width: '100%' }, ...(Array.isArray(sx) ? sx : [sx])]}
      icon={false}
    >
      <Typography variant="body2" mb={1}>
        <Typography component="strong" variant="body2" fontWeight="bold">
          Match for Free!
        </Typography>{' '}
        {expirationTime !== null && (
          <>
            Your Priority Access expires in{' '}
            <Typography component="strong" variant="body2" fontWeight="bold">
              <Countdown date={expirationTime} />
            </Typography>
          </>
        )}
      </Typography>
      <Typography variant="body2">
        Since this attorney invited you to the case, there&apos;s no fee to
        complete this match.{' '}
      </Typography>
    </Alert>
  )
}

import { useCallback, useMemo, useState } from 'react'
import { useFeatureFlags } from '@/optimizely/useFeatureFlags'
import { FeatureFlag } from '@/optimizely/types'
import { useUsersMeOrganizationQuery } from '@/gql/systemApi'
import { ParameterName, bootParamsService } from '@/services/BootParams'
import useGetOrganizationLcmsData from '../components/shared/useGetOrganizationLcmsData'
import textContent, { LCMS_IDS } from '../constants'
import ClioIntegrationItem from '../components/Clio/ClioIntegrationItem'
import LawmaticsIntegrationItem from '../components/Lawmatics/LawmaticsIntegrationItem'
import LeadDocketIntegrationItem from '../components/LeadDocket/LeadDocketIntegrationItem'
import LitifyIntegrationItem from '../components/Litify/LitifyIntegrationItem'
import SalesForceIntegrationItem from '../components/SalesForce/SalesForceIntegrationItem'
import {
  IntegrationItemProps,
  TIntegrationModalProps,
  TRemoveIntegrationProps,
} from '../types'
import { getClioConnectionData } from '../components/Clio/utils'
import MyCaseIntegrationItem from '../components/MyCase/MyCaseIntegrationItem'

export type IntegrationItem = {
  id: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>
  props: IntegrationItemProps
  enabled: boolean
}

const initModalStatus = {
  open: false,
  title: '',
  description: '',
  onSubmit: () => {},
}

const useIntegrations = () => {
  const featureFlags = useFeatureFlags()
  const isClioEnabled = featureFlags[FeatureFlag.CLIO_INTEGRATION]
  const isMarketplaceLeadDocketEnabled =
    featureFlags[FeatureFlag.MARKETPLACE_LEAD_DOCKET]
  const isMarketplaceLawmaticsEnabled =
    featureFlags[FeatureFlag.MARKETPLACE_LAWMATICS]
  const isMarketplaceLitifyEnabled =
    featureFlags[FeatureFlag.MARKETPLACE_LITIFY]
  const isMarketplaceSalesForceEnabled =
    featureFlags[FeatureFlag.SALESFORCE_INTEGRATION]
  const isMyCaseEnabled = featureFlags[FeatureFlag.MY_CASE_INTEGRATION]
  const { data: userOrgData, loading: userOrgDataLoading } =
    useUsersMeOrganizationQuery()
  const userId = userOrgData?.users_me?.id
  const orgId =
    userOrgData?.users_me?.current_organization?.[0]?.organization_id?.id || ''
  const caseManagementSoftware =
    userOrgData?.users_me?.current_organization?.[0]?.organization_id
      ?.profiles?.[0]?.case_management_software || ''
  const [modalProps, setModalProps] =
    useState<TIntegrationModalProps>(initModalStatus)
  const { organizationData, loadingOrganizationData, refetchOrganizationData } =
    useGetOrganizationLcmsData({
      organizationId: orgId,
    })
  const codeQueryParam = bootParamsService.get(ParameterName.CLIO_CODE)
  const srcQueryParam = bootParamsService.get(ParameterName.SRC)
  const [isConnectingToLcms, setIsConnectingToLcms] = useState(
    Boolean(codeQueryParam && String(srcQueryParam))
  )

  const { isCurrentUserConnectedToClio, hasAdminConnectedToClio } =
    getClioConnectionData({
      organizationData,
      userId: userId || '',
    })

  const refetchLcmsData = useCallback(async () => {
    await refetchOrganizationData()
  }, [refetchOrganizationData])

  const removeIntegrationItem = useCallback(
    ({ integrationName, onSubmit }: TRemoveIntegrationProps) => {
      setModalProps({
        open: true,
        title: `Disconnect ${integrationName}`,
        description: `Are you sure you want to disconnect ${integrationName}?`,
        onSubmit: async () => {
          await onSubmit()
          setModalProps(initModalStatus)
        },
      })
    },
    [setModalProps]
  )

  const connectingToLcms = useCallback((isConnecting: boolean) => {
    setIsConnectingToLcms(isConnecting)
  }, [])

  const loading =
    loadingOrganizationData || !organizationData || !orgId || userOrgDataLoading

  const { INTEGRATION_V2 } = textContent

  const integrationItems: IntegrationItem[] = useMemo(() => {
    const basicProps = {
      loading,
      organizationId: orgId || '',
      refetchLcmsData,
      removeIntegrationItem,
      isConnectingToLcms,
      connectingToLcms,
    }
    return [
      {
        id: LCMS_IDS.CLIO,
        component: ClioIntegrationItem,
        props: {
          name: INTEGRATION_V2.CLIO_GROW.NAME,
          icon: INTEGRATION_V2.CLIO_GROW.ICON,
          subtitle: INTEGRATION_V2.CLIO_GROW.SUBTITLE,
          hasAdminConnectedToClio,
          ...basicProps,
        },
        enabled: !!isClioEnabled,
      },
      {
        id: LCMS_IDS.LAWMATICS,
        component: LawmaticsIntegrationItem,
        props: {
          name: INTEGRATION_V2.LAWMATICS.NAME,
          icon: INTEGRATION_V2.LAWMATICS.ICON,
          subtitle: INTEGRATION_V2.LAWMATICS.SUBTITLE,
          ...basicProps,
        },
        enabled: !!isMarketplaceLawmaticsEnabled,
      },
      {
        id: LCMS_IDS.MY_CASE,
        component: MyCaseIntegrationItem,
        props: {
          name: INTEGRATION_V2.MY_CASE.NAME,
          icon: INTEGRATION_V2.MY_CASE.ICON,
          subtitle: INTEGRATION_V2.MY_CASE.SUBTITLE,
          ...basicProps,
        },
        enabled: !!isMyCaseEnabled,
      },
      {
        id: LCMS_IDS.LEAD_DOCKET,
        component: LeadDocketIntegrationItem,
        props: {
          name: INTEGRATION_V2.LEAD_DOCKET.NAME,
          icon: INTEGRATION_V2.LEAD_DOCKET.ICON,
          subtitle: INTEGRATION_V2.LEAD_DOCKET.SUBTITLE,
          ...basicProps,
        },
        enabled: !!isMarketplaceLeadDocketEnabled,
      },
      {
        id: LCMS_IDS.LITIFY,
        component: LitifyIntegrationItem,
        props: {
          name: INTEGRATION_V2.LITIFY.NAME,
          icon: INTEGRATION_V2.LITIFY.ICON,
          subtitle: INTEGRATION_V2.LITIFY.SUBTITLE,
          ...basicProps,
        },
        enabled: !!isMarketplaceLitifyEnabled,
      },
      {
        id: LCMS_IDS.SALESFORCE,
        component: SalesForceIntegrationItem,
        props: {
          name: INTEGRATION_V2.SALESFORCE.NAME,
          icon: INTEGRATION_V2.SALESFORCE.ICON,
          subtitle: INTEGRATION_V2.SALESFORCE.SUBTITLE,
          ...basicProps,
        },
        enabled: !!isMarketplaceSalesForceEnabled,
      },
    ]
  }, [
    isClioEnabled,
    isMarketplaceLawmaticsEnabled,
    isMarketplaceLeadDocketEnabled,
    isMarketplaceLitifyEnabled,
    isMarketplaceSalesForceEnabled,
    orgId,
    organizationData,
    loading,
    refetchLcmsData,
    removeIntegrationItem,
    INTEGRATION_V2,
    isConnectingToLcms,
    connectingToLcms,
  ])

  const connectedItem = useMemo(() => {
    const connectedLcmsId =
      organizationData?.organization_connected_lcms?.[0]
        ?.case_management_software_id?.id
    const connectedIntegrationItems =
      integrationItems.find(
        item => item.id === connectedLcmsId && item.enabled
      ) || null
    if (connectedIntegrationItems?.props) {
      connectedIntegrationItems.props.isConnected =
        connectedLcmsId === LCMS_IDS.CLIO ? isCurrentUserConnectedToClio : true
    }
    return connectedIntegrationItems
  }, [organizationData, integrationItems])

  const hasConnectedItems = !!connectedItem

  const preferredIntegrationItem = useMemo(() => {
    if (hasConnectedItems || !caseManagementSoftware) {
      return null
    }
    return integrationItems.find(
      item => item.id === caseManagementSoftware && item.enabled
    )
  }, [hasConnectedItems, caseManagementSoftware, integrationItems])

  const disconnectedItems = useMemo(() => {
    const otherItems = integrationItems.filter(
      item =>
        connectedItem?.id !== item.id &&
        preferredIntegrationItem?.id !== item.id &&
        item.enabled
    )
    if (hasConnectedItems) {
      return otherItems.map(item => ({
        ...item,
        props: {
          ...item.props,
          isConnectedToOtherLcms: true,
        },
      }))
    }
    return otherItems
  }, [
    integrationItems,
    hasConnectedItems,
    connectedItem?.id,
    preferredIntegrationItem?.id,
  ])

  return {
    organizationId: orgId || '',
    organizationData,
    loading,
    connectedItem,
    disconnectedItems,
    preferredIntegrationItem,
    setModalProps,
    modalProps,
    refetchLcmsData,
    removeIntegrationItem,
    isConnectingToLcms,
    connectingToLcms,
    isClioEnabled,
    isMarketplaceLeadDocketEnabled,
    isCurrentUserConnectedToClio,
    hasAdminConnectedToClio,
  }
}

export default useIntegrations

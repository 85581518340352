/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/material/Box'
import { IJUser } from '@/components/atoms/JUser/types'
import { useSizes } from '@/hooks/useSizes'
import AgreementContent from '@/pages/MyReferrals/Agreements/AgreementContent'
import { ModalTypes } from '@/types'
import ClientSigning from '@/pages/MyReferralsV2/NegotiationItem/components/ClientSigning'
import AttorneyCase from '../Sidebar/components/AttorneyCase'
import { ISidebarProps } from '../Sidebar/CaseSidebar/types'
import SidebarFee from '../Sidebar/components/SidebarFee'

export interface AgreementCaseButtonsProps {
  profile: IJUser
  setGenericOpenModal: React.Dispatch<React.SetStateAction<ModalTypes>>
  agreements: ISidebarProps['agreementParams']
  showClientSigningConfirmation?: boolean
  handleClientSigned: () => void
  handleClientRejected: () => void
  attorneyTitle?: string
  casePurchaseId: string
  fee: number
}

const AgreementCaseButtons = ({
  profile,
  setGenericOpenModal,
  agreements,
  showClientSigningConfirmation,
  handleClientSigned,
  handleClientRejected,
  attorneyTitle,
  casePurchaseId,
  fee,
}: AgreementCaseButtonsProps) => {
  const { isLessMd } = useSizes()
  const showFee = Boolean(fee && fee > 0)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 1.5,
      }}
    >
      {!isLessMd && (
        <>
          <AttorneyCase
            caseOwner={{
              id: agreements?.profileToDisplay?.attorney_id?.id,
              firstName: agreements?.profileToDisplay?.first_name,
              lastName: agreements?.profileToDisplay?.last_name,
              avatar: profile?.avatar,
              isVetted: profile?.isVetted,
              isQualified: profile?.isQualified,
              organization: profile?.secondaryText,
            }}
            relationshipType="counterpart"
            showSendMessage
            title={attorneyTitle}
          />
          {showFee && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                py: 1,
              }}
            >
              <SidebarFee fee={fee} />
            </Box>
          )}
        </>
      )}
      {!agreements?.finalizedAgreement && (
        <AgreementContent
          currentUserWithValidPayment={Boolean(
            agreements?.currentUserWithValidPayment
          )}
          buttonLabel={agreements?.buttonLabel || ''}
          fullButtonWidth
          onPaymentClick={() => setGenericOpenModal('Accept')}
          casePurchaseId={casePurchaseId}
        />
      )}
      {showClientSigningConfirmation && (
        <ClientSigning
          sx={{
            pl: {
              xs: 0,
              md: 0,
            },
            flexDirection: { xs: 'column' },
            gap: {
              xs: 2,
            },
          }}
          buttonSxProps={{
            width: {
              xs: '100%',
            },
          }}
          onClientSigned={handleClientSigned}
          onClientRejected={handleClientRejected}
        />
      )}
    </Box>
  )
}

export default AgreementCaseButtons

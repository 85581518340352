import React from 'react'
import { Typography, Avatar, IconButton, styled } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { useCaseContext } from '../../../hooks/useCaseContext'
import { DataroomAccessRequest } from '../../../types'
import SidebarAccordion from '../SidebarAccordion'

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  width: '100%',
})

const RequestItem = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 0),
  width: '100%',
  gap: theme.spacing(1),
}))

const UserInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  minWidth: 0,
  gap: 8,
})

const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: 36,
  height: 36,
  marginRight: theme.spacing(1),
}))

const UserDetails = styled('div')({
  minWidth: 0,
})

const ActionButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}))

const ActionButton = styled(IconButton)(({ theme }) => ({
  width: 36,
  height: 36,
  border: `1px solid ${theme.palette.divider}`,
  '&:focus-visible': {
    outline: `2px solid ${theme.palette.primary.main}`,
    outlineOffset: 2,
  },
}))

const DataroomAccessRequests: React.FC<{ defaultExpanded?: boolean }> = ({
  defaultExpanded = false,
}) => {
  const { caseData } = useCaseContext()

  const dataroomAccessRequests =
    caseData?.sidebar?.dataroom_access_requests || []

  if (dataroomAccessRequests.length === 0) return null

  const handleApprove = (requestId: string) => {
    // TODO: Implement approve logic
    console.log('Approve request:', requestId)
  }

  const handleDeny = (requestId: string) => {
    // TODO: Implement deny logic
    console.log('Deny request:', requestId)
  }

  return (
    <SidebarAccordion
      title="Data Room Access Requests"
      defaultExpanded={defaultExpanded}
    >
      <List role="list">
        {dataroomAccessRequests.map((accessRequest: DataroomAccessRequest) => (
          <RequestItem key={accessRequest.id} role="listitem">
            <UserInfo>
              <UserAvatar
                src={accessRequest.user.avatar_image || ''}
                alt={accessRequest.user.name}
              />
              <UserDetails>
                <Typography variant="subtitle2" noWrap>
                  {accessRequest.user.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" noWrap>
                  {accessRequest.user.firm.name}
                </Typography>
              </UserDetails>
            </UserInfo>
            <ActionButtons>
              <ActionButton
                size="small"
                onClick={() => handleApprove(accessRequest.id)}
                aria-label={`Approve request from ${accessRequest.user.name}`}
              >
                <CheckIcon fontSize="small" color="success" />
              </ActionButton>
              <ActionButton
                size="small"
                onClick={() => handleDeny(accessRequest.id)}
                aria-label={`Deny request from ${accessRequest.user.name}`}
              >
                <CloseIcon fontSize="small" color="error" />
              </ActionButton>
            </ActionButtons>
          </RequestItem>
        ))}
      </List>
    </SidebarAccordion>
  )
}

export default DataroomAccessRequests

import useTheme from '@mui/material/styles/useTheme'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export interface ICaseBodyTag {
  title: string
  description: string
}

const CaseBodyTag: React.FC<ICaseBodyTag> = ({
  title,
  description,
}: ICaseBodyTag) => {
  const theme = useTheme()

  const labelWidth = 130

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        width: 1,
      }}
    >
      <Typography
        variant="caption"
        color={theme.palette.text.disabled}
        width={`${labelWidth}px`}
        minWidth={`${labelWidth}px`}
      >
        {title}
      </Typography>
      <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
        {description}
      </Typography>
    </Box>
  )
}

export default CaseBodyTag

import { useEffect, useState } from 'react'
import { useNavigate, generatePath } from 'react-router-dom'

import Card from '@mui/material/Card'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import useTheme from '@mui/material/styles/useTheme'
import { IntegrationItemProps } from '@/pages/AccountSettings/Integrations/types'
import JSnackBar from '@/components/atoms/JSnackBar'
import { ParameterName, bootParamsService } from '@/services/BootParams'
import { useSnackBar } from '@/hooks/useSnackBar'
import IntegrationItemCardContent from '../shared/IntegrationItemCardContent'
import IntegrationItemButtons from '../shared/IntegrationItemButtons'
import useClioIntegrationItem from './useClioIntegrationItem'

import textContent from '../../constants'
import { isSetupSuccessful } from '../shared/utils'
import { RoutesData } from '@/router/RoutesData'

const ClioIntegrationItem = ({
  icon,
  name,
  subtitle,
  organizationId,
  loading,
  isConnectedToOtherLcms,
  isConnected,
  refetchLcmsData,
  removeIntegrationItem,
  isConnectingToLcms,
  connectingToLcms,
  hasAdminConnectedToClio,
}: IntegrationItemProps) => {
  const {
    INTEGRATION_V2: { CLIO_GROW },
  } = textContent
  const theme = useTheme()
  const navigate = useNavigate()
  const codeQueryParam = bootParamsService.get(ParameterName.CLIO_CODE)
  const srcQueryParam = bootParamsService.get(ParameterName.SRC)

  const [clioRemoveStatus, setClioRemoveStatus] = useState(false)
  const clioName = CLIO_GROW.SHORT_NAME.toLowerCase()
  const [connectClio, setConnectClio] = useState(
    Boolean(
      codeQueryParam &&
        organizationId !== '' &&
        String(srcQueryParam) === clioName
    )
  )

  const {
    alert: clioAlert,
    showAlert: clioShowAlert,
    handleClose: clioHandleClose,
  } = useSnackBar()

  const {
    connectClioIntegration,
    connectClioIntegrationData,
    removeClioData,
    removeClioIsLoading,
  } = useClioIntegrationItem({
    code: String(codeQueryParam),
    connectClio,
    clioRemoveStatus,
    organizationId: String(organizationId),
    clioName,
  })

  const shouldRenderLoadingButton =
    removeClioIsLoading || loading || isConnectingToLcms

  const shouldRenderDisconnectButton =
    (isConnected && !clioRemoveStatus && !removeClioData) ||
    removeClioData?.statusCode === 404 ||
    removeClioData?.statusCode === 500

  // Navigate to the current window location path clearing the query params provided,
  // replacing the current entry in the browser's history.
  useEffect(() => {
    if (window.location.search !== '') {
      navigate(window.location.pathname, { replace: true })
    }
  }, [navigate])

  useEffect(() => {
    if (codeQueryParam && organizationId !== '' && srcQueryParam === clioName) {
      connectingToLcms(true)
      setConnectClio(true)
    }
  }, [
    clioName,
    codeQueryParam,
    organizationId,
    srcQueryParam,
    connectingToLcms,
  ])

  // Monitors the LCMs setup process.
  useEffect(() => {
    if (
      isSetupSuccessful(connectClioIntegrationData) &&
      !clioRemoveStatus &&
      !isConnected
    ) {
      connectingToLcms(false)
      setConnectClio(false)
      bootParamsService.remove(ParameterName.CLIO_CODE)
      bootParamsService.remove(ParameterName.SRC)
      refetchLcmsData()
    }
  }, [
    connectClioIntegrationData,
    clioRemoveStatus,
    isConnected,
    refetchLcmsData,
    connectingToLcms,
  ])

  // Handles errors during Clio integration setup or removal process
  // Check if the current organization is not connected to other LCMs
  useEffect(() => {
    if (
      (connectClioIntegrationData?.statusCode === 400 &&
        !isConnectedToOtherLcms) ||
      (removeClioData?.statusCode === 400 && !isConnectedToOtherLcms)
    ) {
      clioShowAlert({
        severity: 'error',
        message: 'An error occurred, please try again in a few minutes',
      })
      connectingToLcms(false)
      setConnectClio(false)
      bootParamsService.remove(ParameterName.CLIO_CODE)
      bootParamsService.remove(ParameterName.SRC)
    }
  }, [
    clioShowAlert,
    connectClioIntegrationData?.statusCode,
    isConnectedToOtherLcms,
    removeClioData?.statusCode,
    connectingToLcms,
  ])

  // Handles disconnection from other LCMs
  useEffect(() => {
    if (isConnected && removeClioData?.message === 'deleted') {
      setClioRemoveStatus(false)
      refetchLcmsData()
    }
  }, [isConnected, removeClioData?.message, refetchLcmsData])

  const removeClioIntegration = () => {
    removeIntegrationItem({
      integrationName: CLIO_GROW.SHORT_NAME,
      onSubmit: () => {
        setClioRemoveStatus(true)
      },
    })
  }

  const goToAccountSettings = () => {
    const path = generatePath(RoutesData.RSettings.path, {
      id: 'integrations',
    })

    navigate(path)
  }

  return (
    <Card
      sx={{
        margin: theme.spacing(0, 0, 4),
        padding: theme.spacing(2),
        boxShadow: 2,
      }}
    >
      <IntegrationItemCardContent
        actions={
          <IntegrationItemButtons
            shouldRenderLoadingButton={shouldRenderLoadingButton}
            shouldRenderDisconnectButton={shouldRenderDisconnectButton}
            disconnectLcms={() => {
              removeClioIntegration()
            }}
            connectLcms={connectClioIntegration}
            isConnectedToOtherLcms={isConnectedToOtherLcms}
            disableConnectAction={
              isConnectedToOtherLcms ||
              isConnectingToLcms ||
              (hasAdminConnectedToClio && !isConnected)
            }
          />
        }
        icon={icon}
        name={`${
          isConnected || hasAdminConnectedToClio ? 'Connected to ' : ''
        }${name}`}
        subtitle={subtitle}
      />
      {hasAdminConnectedToClio && !isConnected && (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            mt: 3,
          }}
        >
          Another administrator has already connected your organization to Clio
          Grow. If you need to connect to your personal Clio Grow profile, go to{' '}
          <Link
            sx={{ cursor: 'pointer' }}
            onClick={goToAccountSettings}
            color="primary"
          >
            Account Settings
          </Link>
          .
        </Typography>
      )}
      <JSnackBar
        vertical="bottom"
        open={clioAlert.open}
        severity={clioAlert.severity}
        message={clioAlert.message}
        handleClose={clioHandleClose}
      />
    </Card>
  )
}

export default ClioIntegrationItem

import {
  SessionKey,
  sessionStorageService,
} from '@/services/SessionStorage/SessionStorageService'

const useAttorneyEmail = () => {
  const getAttorneyEmail = () =>
    sessionStorageService.getItem(SessionKey.ATTORNEY_EMAIL_KEY)
  const setAttorneyEmail = (email: string) =>
    sessionStorageService.setItem(SessionKey.ATTORNEY_EMAIL_KEY, email)
  const removeAttorneyEmail = () =>
    sessionStorageService.removeItem(SessionKey.ATTORNEY_EMAIL_KEY)

  return {
    getAttorneyEmail,
    setAttorneyEmail,
    removeAttorneyEmail,
  }
}

export default useAttorneyEmail

import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import logo from '@/assets/logo.svg'

const Maintenance = () => (
  <Container maxWidth="sm">
    <Box
      sx={{
        my: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80vh',
      }}
    >
      <Box
        sx={{
          my: 4,
        }}
      >
        <img alt="Attorney Share Logo" src={logo} width={150} />
      </Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Site Under Maintenance
      </Typography>
      <Typography
        variant="h6"
        component="p"
        sx={{
          textAlign: 'center',
        }}
      >
        We're currently performing scheduled maintenance. We apologize for the
        inconvenience and appreciate your patience.
      </Typography>
    </Box>
  </Container>
)

export default Maintenance

import { history } from 'instantsearch.js/es/lib/routers'
import { liteClient as createSearchClient } from 'algoliasearch/lite'
import { FC, useEffect } from 'react'
import { useSizes } from '@/hooks/useSizes'
import { Configure, InstantSearch } from 'react-instantsearch'
import { CreateStateMapping, showCasesInMyOrgQueryParamKey } from '../utils'
import MobileMarketplaceContent from './MobileMarketplaceContent'
import DesktopMarketplaceContent from './DesktopMarketplaceContent'
import AlgoliaMarketplaceHeader from './AlgoliaMarketplaceHeader'
import { AlgoliaMarketplaceProvider } from '../context/AlgoliaMarketplaceContext'
import { useAlgoliaMarketplaceContext } from '../context/useAlgoliaMarketplaceContext'

const {
  VITE_ALGOLIA_APP_ID,
  VITE_ALGOLIA_API_KEY,
  VITE_ALGOLIA_MARKETPLACE_INDEX,
} = import.meta.env

const searchClient = createSearchClient(
  VITE_ALGOLIA_APP_ID,
  VITE_ALGOLIA_API_KEY
)

const MarketplaceContent: FC = () => {
  const { isLessMd } = useSizes()
  const { optionalFilters, customFilters } = useAlgoliaMarketplaceContext()

  return (
    <>
      <Configure optionalFilters={optionalFilters} filters={customFilters} />
      <div className="ais-InstantSearch">
        <AlgoliaMarketplaceHeader />
        {isLessMd ? (
          <MobileMarketplaceContent />
        ) : (
          <DesktopMarketplaceContent />
        )}
      </div>
    </>
  )
}

const AlgoliaMarketplace: FC = () => {
  const stateMapping = CreateStateMapping()

  useEffect(() => {
    searchClient.clearCache()
  }, [])

  const routing = {
    router: history({
      cleanUrlOnDispose: false,
      // NOTE: This is necessary because the location.search contains query
      // parameters that are mixture of what instantsearch sets as filter state
      // and also custom, bespoke filter state. This function serves to merge
      // these two sets of filter state query parameters.
      createURL: ({ qsModule, routeState, location }) => {
        const urlParts = location.href.split('?')
        const baseUrl = urlParts[0]

        // Parse existing query params
        const existingParams = qsModule.parse(location.search.slice(1))

        const { [showCasesInMyOrgQueryParamKey]: showCasesFromMyOrg } =
          existingParams

        const queryString = qsModule.stringify(
          {
            ...routeState,

            // NOTE: This is custom filter state.
            ...(showCasesFromMyOrg === 'false' && {
              [showCasesInMyOrgQueryParamKey]: showCasesFromMyOrg,
            }),
          },
          {
            encoder: value =>
              typeof value === 'string' ? value.replace(/ /g, '+') : value,
          }
        )

        return `${baseUrl}${queryString ? `?${queryString}` : ''}`
      },
    }),
    stateMapping,
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={VITE_ALGOLIA_MARKETPLACE_INDEX}
      routing={routing}
      future={{
        // Preparing for future deprecation from Algolia
        preserveSharedStateOnUnmount: true,
      }}
    >
      <AlgoliaMarketplaceProvider>
        <MarketplaceContent />
      </AlgoliaMarketplaceProvider>
    </InstantSearch>
  )
}

export default AlgoliaMarketplace

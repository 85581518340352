import React from 'react'
import {
  Box,
  Typography,
  Avatar,
  Button,
  IconButton,
  styled,
  Menu,
  MenuItem,
  ButtonProps,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Countdown from 'react-countdown'
import { useCaseContext } from '../../../hooks/useCaseContext'
import SidebarAccordion from '../SidebarAccordion'
import { Negotiation } from '../../../types'

const NegotiationItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:last-child': {
    borderBottom: 'none',
  },
}))

const CounterpartInfo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
})

const FirmInfo = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
  flex: 1,
})

const FirmName = styled(Typography)({
  color: 'text.secondary',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const HeaderRow = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  gap: '12px',
})

const FeeInfo = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  minWidth: 'fit-content',
})

const ActionButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'flex-end',
  '& .MuiButton-root': {
    maxWidth: '50%',
    flex: 1,
  },
}))

const PriorityAccess = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.dark,
}))

const NextUser = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
  color: theme.palette.text.secondary,
  width: '100%',
}))

const NextUserInfo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  width: '100%',
})

const UserTextContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  minWidth: 0,
  flex: 1,
})

const TruncatedTypography = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minWidth: 0,
})

const NegotiationAvatar = styled(Avatar)({
  width: 24,
  height: 24,
})

interface NegotiationItemProps {
  negotiation: Negotiation
}

interface Action {
  key: string
  label: string
  onClick: () => void
  primary?: boolean
  variant?: ButtonProps['variant']
  color?: ButtonProps['color']
}

const NegotiationItemComponent: React.FC<NegotiationItemProps> = ({
  negotiation,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleCounteroffer = () => {
    console.log('Counteroffer:', negotiation.id)
  }

  const handleAccept = () => {
    console.log('Accept:', negotiation.id)
  }

  const handleReject = () => {
    console.log('Reject:', negotiation.id)
  }

  const handleWithdraw = () => {
    console.log('Withdraw:', negotiation.id)
  }

  const renderActionButtons = () => {
    const primaryActions: Action[] = []
    const secondaryActions: Action[] = []

    // Priority actions first
    if (negotiation.actions.counteroffer) {
      primaryActions.push({
        key: 'counteroffer',
        label: 'Counteroffer',
        onClick: handleCounteroffer,
        primary: false,
      })
    }

    if (negotiation.actions.accept) {
      primaryActions.push({
        key: 'accept',
        label: 'Accept',
        onClick: handleAccept,
        primary: true,
      })
    }

    // Secondary actions
    if (negotiation.actions.reject) {
      secondaryActions.push({
        key: 'reject',
        label: 'Reject',
        onClick: handleReject,
        primary: false,
      })
    }

    if (negotiation.actions.withdraw) {
      secondaryActions.push({
        key: 'withdraw',
        label: 'Withdraw',
        onClick: handleWithdraw,
        primary: false,
        variant: 'text',
        color: 'error',
      })
    }

    // If we have no primary actions, show up to 2 secondary actions as buttons
    const buttonActions =
      primaryActions.length > 0 ? primaryActions : secondaryActions.slice(0, 2)

    // Put remaining secondary actions in menu
    const menuActions =
      primaryActions.length > 0 ? secondaryActions : secondaryActions.slice(2)

    return (
      <>
        <ActionButtons>
          {buttonActions.map(action => (
            <Button
              key={action.key}
              variant={
                action.variant || (action.primary ? 'contained' : 'outlined')
              }
              color={action.color || 'primary'}
              fullWidth
              sx={{ flex: buttonActions.length === 1 ? '0 1 50%' : 1 }}
              onClick={action.onClick}
            >
              {action.label}
            </Button>
          ))}
          {menuActions.length > 0 && (
            <IconButton
              size="small"
              onClick={handleMenuOpen}
              sx={{ ml: 'auto' }}
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </ActionButtons>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {menuActions.map(action => (
            <MenuItem
              key={action.key}
              onClick={() => {
                action.onClick()
                handleMenuClose()
              }}
            >
              {action.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  }

  return (
    <NegotiationItem>
      {negotiation.expiration_time && (
        <PriorityAccess variant="body2">
          Priority Access due in{' '}
          <strong>
            <Countdown
              date={new Date(negotiation.expiration_time)}
              renderer={({ hours, minutes, seconds }) =>
                `${hours}:${minutes.toString().padStart(2, '0')}:${seconds
                  .toString()
                  .padStart(2, '0')}`
              }
            />
          </strong>
        </PriorityAccess>
      )}

      <HeaderRow>
        {negotiation?.counterpart && (
          <CounterpartInfo>
            <NegotiationAvatar
              src={negotiation.counterpart.avatar_image || ''}
              alt={negotiation.counterpart.name}
            />
            <FirmInfo>
              <Typography variant="subtitle2" noWrap>
                {negotiation.counterpart.name}
              </Typography>
              <FirmName variant="body2" color="text.disabled">
                {negotiation.counterpart.firm.name}
              </FirmName>
            </FirmInfo>
          </CounterpartInfo>
        )}

        <FeeInfo>
          <Box display="flex" alignItems="baseline">
            <Typography variant="h6">{negotiation.fee}</Typography>
            <Typography variant="subtitle2">%</Typography>
          </Box>
          <Typography
            variant="caption"
            color="text.disabled"
            sx={{ whiteSpace: 'nowrap' }}
          >
            {new Date(negotiation.created_ts).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
            })}
          </Typography>
        </FeeInfo>
      </HeaderRow>

      {renderActionButtons()}

      {negotiation.next_waterfall_user && (
        <NextUser>
          <Typography variant="caption" color="text.disabled">
            Next user in Waterfall Referral™
          </Typography>
          <NextUserInfo>
            <NegotiationAvatar
              src={negotiation.next_waterfall_user.avatar_image || ''}
              alt={negotiation.next_waterfall_user.name}
            />
            <UserTextContainer>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ flexShrink: 0 }}
              >
                {negotiation.next_waterfall_user.name}
              </Typography>
              <TruncatedTypography variant="body2" color="text.disabled">
                {negotiation.next_waterfall_user.firm.name}
              </TruncatedTypography>
            </UserTextContainer>
          </NextUserInfo>
        </NextUser>
      )}
    </NegotiationItem>
  )
}

const Negotiations: React.FC<{ defaultExpanded?: boolean }> = ({
  defaultExpanded = false,
}) => {
  const { caseData } = useCaseContext()

  if (!caseData?.sidebar?.negotiations?.length) return null

  return (
    <SidebarAccordion title="Negotiations" defaultExpanded={defaultExpanded}>
      {caseData.sidebar.negotiations.map((negotiation: Negotiation) => (
        <NegotiationItemComponent
          key={negotiation.id}
          negotiation={negotiation}
        />
      ))}
    </SidebarAccordion>
  )
}

export default Negotiations

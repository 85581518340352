import React, { createContext, useState, useMemo, useCallback } from 'react'
import {
  sessionStorageService,
  SessionKey,
} from '@/services/SessionStorage/SessionStorageService'
import { datadogRum } from '@datadog/browser-rum'
import { Case, CaseContextType } from '../types'
import { defaultContextValue } from '../constants/case'

export const CaseContext = createContext<CaseContextType>(defaultContextValue)

export const CaseProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [caseData, setCaseData] = useState<Case | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const fetchCaseData = useCallback(async (caseId: string) => {
    try {
      setLoading(true)
      setError(null)

      const token = sessionStorageService.getItem(SessionKey.TOKEN)
      if (!token) {
        throw new Error('No authentication token found')
      }

      const response = await fetch(`/api/cases?caseId=${caseId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      setCaseData(data)
    } catch (e) {
      setError(e instanceof Error ? e.message : 'Failed to fetch case data')
      setCaseData(null)
      datadogRum.addError(e)
    } finally {
      setLoading(false)
    }
  }, [])

  const watchCase = useCallback((isWatching: boolean) => {
    console.log('Watching case:', isWatching)
  }, [])

  const hideCase = useCallback((isHidden: boolean) => {
    console.log('Hiding case:', isHidden)
  }, [])

  const editCase = useCallback(() => {
    console.log('Editing case')
  }, [])

  const deleteCase = useCallback(() => {
    console.log('Deleting case')
  }, [])

  const contextValue = useMemo(
    () => ({
      caseData,
      setCaseData,
      watchCase,
      hideCase,
      editCase,
      deleteCase,
      loading,
      setLoading,
      error,
      setError,
      fetchCaseData,
    }),
    [
      caseData,
      loading,
      error,
      fetchCaseData,
      watchCase,
      hideCase,
      editCase,
      deleteCase,
    ]
  )

  return (
    <CaseContext.Provider value={contextValue}>{children}</CaseContext.Provider>
  )
}

import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'
import AuthLayout from '@/components/shared/AuthLayout'
import Education from '@/pages/CreateProfile/Education/Education'
import PracticeAreas from '@/pages/CreateProfile/PracticeAreas/PracticeAreas'
import { OrganizationCreateProfile } from '@/pages/CreateAccount/CreateOrganization/OrganizationCreateProfile'
import { RoutesData } from '@/router/RoutesData'
import { PAYMENT_WALL_ENABLED } from '@/constants'
import { useOrganizationProfile } from './hooks/useOrganizationProfile'
import MembershipInfo from '../MembershipInfo/MembershipInfo'
import ProfileCommunicationPreferences from '../ProfileCommunicationPreferences/ProfileCommunicationPreferences'

interface CreateOrganizationProfileProps {
  hasLicense: boolean
}

const CreateOrganizationProfile = ({
  hasLicense,
}: CreateOrganizationProfileProps) => {
  const {
    state,
    back,
    formState,
    handleOnSkip,
    updateFormState,
    submitAndContinue,
    handleProfileSubmit,
    loading,
    paymentLoading,
    setError,
    error,
  } = useOrganizationProfile({ hasLicense })
  const navigate = useNavigate()

  const renderStep = useMemo((): JSX.Element => {
    switch (state.value) {
      case 'practiceAreas':
        return (
          <PracticeAreas
            initialPracticeAreas={formState.practiceAreas}
            onSubmit={data => submitAndContinue('practiceAreas', data)}
            secondaryBtnAction={handleOnSkip}
            loading={false}
          />
        )
      case 'education':
        return (
          <Education
            back={back}
            initialEducation={formState.education}
            onSubmit={data => submitAndContinue('education', data)}
          />
        )
      case 'personalOrgInfo':
        return (
          <OrganizationCreateProfile
            back={back}
            hasLicense={hasLicense}
            profileValues={formState.profile}
            onSubmit={data => submitAndContinue('profile', data)}
            nextBtnText="Continue"
            prevBtnText={hasLicense ? 'Back' : 'Cancel'}
          />
        )
      case 'communicationPreferences':
        return (
          <ProfileCommunicationPreferences
            back={back}
            initialCommPreferences={formState.communicationPreferences}
            onSubmit={data => {
              if (PAYMENT_WALL_ENABLED) {
                submitAndContinue('communicationPreferences', data)
              } else {
                updateFormState('communicationPreferences', data)
                handleProfileSubmit('communicationPreferences', data)
                navigate(RoutesData.RMarketplace.path)
              }
            }}
          />
        )
      case 'membershipInfo':
        return (
          <MembershipInfo
            secondaryBtnTitle="Back"
            onSecondaryBtnClick={back}
            submitTitle="Activate Your Free Membership"
            onSubmit={handleProfileSubmit}
            loading={loading}
          />
        )
      default:
        return <CircularProgress />
    }
  }, [
    state.value,
    formState.practiceAreas,
    formState.education,
    formState.profile,
    formState.communicationPreferences,
    handleOnSkip,
    back,
    hasLicense,
    handleProfileSubmit,
    loading,
    submitAndContinue,
    updateFormState,
    navigate,
  ])

  return (
    <AuthLayout>
      {paymentLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={paymentLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {error && (
        <Alert severity="error" onClose={() => setError('')}>
          {error}
        </Alert>
      )}
      {renderStep}
    </AuthLayout>
  )
}

export default CreateOrganizationProfile

import { JSnackBarProps } from '@/components/atoms/JSnackBar/types'
import { GetOrganizationConnectedQuery } from '@/gql/appApi'
import { SetupResponse } from '@/utils/api/integrations/types'
import { TError } from '@/utils/api/types'
import { AlertColor } from '@mui/material/Alert'
import { Dispatch, SetStateAction } from 'react'

export const isSetupSuccessful = (response: SetupResponse | TError | null) =>
  Boolean(response && 'status' in response && response.status === 'success')

// Start of Create a Code Verifier and a Code Challenge from Code Verifier

const dec2hex = (dec: number): string => `0${dec.toString(16)}`.substr(-2)

const generateRandomString = (): string => {
  const array = new Uint32Array(56 / 2)
  window.crypto.getRandomValues(array)
  return Array.from(array, dec2hex).join('')
}

export const verifier: string = generateRandomString()

const sha256 = async (plain: string): Promise<ArrayBuffer> => {
  const encoder = new TextEncoder()
  const data = encoder.encode(plain)
  return window.crypto.subtle.digest('SHA-256', data)
}

const base64urlencode = (a: ArrayBuffer): string => {
  let str = ''
  const bytes = new Uint8Array(a)
  const len = bytes.byteLength
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i])
  }
  return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
}

export const challengeFromVerifier = async (v: string): Promise<string> => {
  const hashed = await sha256(v)
  const base64encoded = base64urlencode(hashed)
  return base64encoded
}

// End of Create a Code Verifier and a Code Challenge from Code Verifier

type ConnectIntegrationParams = {
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  instanceUrl: string
  codeChallenge: string
  oauthUrl: string
  showAlert: (alert: { severity: AlertColor; message: string }) => void
  setInstanceUrl: Dispatch<SetStateAction<string>>
}

export const connectIntegration = ({
  event,
  instanceUrl,
  codeChallenge,
  oauthUrl,
  showAlert,
  setInstanceUrl,
}: ConnectIntegrationParams) => {
  event.preventDefault()
  if (!URL.canParse(instanceUrl)) {
    showAlert({
      severity: 'error',
      message: 'The URL is not valid',
    })
  } else {
    setInstanceUrl(instanceUrl)
    const redirectUrl = `${instanceUrl}${oauthUrl}&code_challenge=${codeChallenge}`
    window.open(redirectUrl, '_self', 'noopener,noreferrer')
  }
}

export const handleTextFieldValue = (
  event: React.ChangeEvent<HTMLInputElement>,
  setUrlInput: (vieldValue: string) => void
) => {
  const fieldValue = event.target.value
  if (fieldValue.length) {
    setUrlInput(fieldValue)
  }
}

export const disableIntegrationAction = (urlInput: string) =>
  urlInput.length <= 3

export interface SetConnectionParams {
  isCurrentUserConnected?: boolean
  isNonAdmin?: boolean
  organizationData: GetOrganizationConnectedQuery | undefined
  integrationName: string
  setIsConnected: (isConnected: boolean) => void
}

export const setConnection = ({
  isCurrentUserConnected,
  isNonAdmin,
  organizationData,
  integrationName,
  setIsConnected,
}: SetConnectionParams) => {
  if (isCurrentUserConnected) {
    return
  }
  const connectedLCMs = organizationData?.organization_connected_lcms
  if (connectedLCMs && connectedLCMs.length) {
    const organizationName = String(
      connectedLCMs[0]?.case_management_software_id?.name
    )
    if (isNonAdmin && organizationName === 'Clio' && !isCurrentUserConnected) {
      return
    }
    if (organizationName === integrationName) {
      setIsConnected(true)
    }
  }
}

export interface TriggerConnectionParams {
  codeQueryParam: string | null
  organizationId: string
  srcQueryParam: string
  integrationName: string
  setConnect: (isConnected: boolean) => void
}

export const triggerConnection = ({
  codeQueryParam,
  organizationId,
  srcQueryParam,
  integrationName,
  setConnect,
}: TriggerConnectionParams): void => {
  if (
    codeQueryParam &&
    organizationId !== '' &&
    srcQueryParam === integrationName.toLowerCase()
  ) {
    setConnect(true)
  }
}

type TAlertProps = Omit<JSnackBarProps, 'handleClose'>
interface CheckAndHandleErrorParams {
  connectIntegrationData: { statusCode: number } | null | undefined
  removeData: { statusCode: number } | null | undefined
  isConnectedToOtherLcms: boolean
  showAlert: (data: Omit<TAlertProps, 'open'>) => void
  setConnect: (isConnected: boolean) => void
}

export const checkAndHandleErrors = ({
  connectIntegrationData,
  removeData,
  isConnectedToOtherLcms,
  showAlert,
  setConnect,
}: CheckAndHandleErrorParams): boolean => {
  if (
    (connectIntegrationData?.statusCode === 400 && !isConnectedToOtherLcms) ||
    (removeData?.statusCode === 400 && !isConnectedToOtherLcms)
  ) {
    showAlert({
      severity: 'error',
      message: 'An error occurred, please try again in a few minutes',
    })
    setConnect(false)
    return true
  }
  return false
}

export const sanitizedInstanceUrl = (instanceUrl: string): string =>
  instanceUrl.endsWith('/') ? instanceUrl.slice(0, -1) : instanceUrl

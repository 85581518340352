import { useGetOrganizationConnectedQuery } from '@/gql/appApi'

export interface UseGetOrganizationLcmsDataProps {
  organizationId?: string
}

const useGetOrganizationLcmsData = ({
  organizationId,
}: UseGetOrganizationLcmsDataProps) => {
  const {
    data: organizationData,
    loading: loadingOrganizationData,
    refetch: refetchOrganizationData,
  } = useGetOrganizationConnectedQuery({
    variables: {
      search: organizationId,
    },
    fetchPolicy: 'network-only',
    skip: !organizationId,
    notifyOnNetworkStatusChange: true,
  })

  return {
    loadingOrganizationData,
    organizationData,
    refetchOrganizationData,
  }
}

export default useGetOrganizationLcmsData

import React from 'react'
import Chip from '@mui/material/Chip'
import { useTheme } from '@mui/material'
import { IJStatusPillProps } from './types'
import { getStatusProperties } from './utils'

const JStatusPill = React.memo(({ id, name, sx }: IJStatusPillProps) => {
  const theme = useTheme()
  const statusProperties = getStatusProperties(id)

  if (!statusProperties) {
    return (
      <Chip
        label="Not Specified"
        sx={[
          {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.text.secondary,
            borderRadius: '25px',
            height: '23px',
            typography: 'captionBold',
            '&:hover': {
              backgroundColor: theme.palette.grey[100],
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      />
    )
  }

  const { backgroundColor, textColor } = statusProperties

  return (
    <Chip
      label={name}
      sx={[
        {
          backgroundColor,
          color: textColor,
          borderRadius: '25px',
          height: '23px',
          typography: 'captionBold',
          '&:hover': {
            backgroundColor,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  )
})

export default JStatusPill

/* eslint-disable react/no-array-index-key */
import { useContext, useEffect, MouseEvent } from 'react'
import { styled, Theme, SxProps } from '@mui/material/styles'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ListSubheader from '@mui/material/ListSubheader'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import ListItemIcon from '@mui/material/ListItemIcon'
import GlobalContext from '@/context/GlobalContext'
import { useSizes } from '@/hooks/useSizes'
import { useNavigate } from 'react-router-dom'
import { labelToPath } from '@/router/RoutesUtils'

import { MenuItemType } from './types'

const MenuContainer = styled(MenuList)(() => ({
  overflow: 'auto',
  '&::-webkit-scrollbar': { display: 'none' },
}))

const MenuListItem = styled(MenuItem)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.text.secondary,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  borderRadius: '4px 0px 0px 4px',
  '&.Mui-selected': {
    backgroundColor: theme.palette.background.page,
    color: theme.palette.primary.main,
  },
}))

const MenuItemWrapper = styled('ul')<{ selected: boolean }>(
  ({ selected, theme }) => ({
    position: 'relative',
    ...(selected && {
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        height: '56px',
        zIndex: 2,
        borderLeft: `4px solid ${theme.palette.secondary.main}`,
      },
    }),
  })
)

const MenuListSubHeader = styled(ListSubheader)(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.text.disabled,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}))

const MenuListIcon = styled(ListItemIcon, {
  shouldForwardProp: prop => prop !== 'selected',
})<{ selected: boolean }>(({ selected, theme }) => ({
  ...(selected && {
    color: theme.palette.primary.main,
  }),
}))

export interface MenuProps {
  customOnClickItemOnly?: boolean
  onClickItem?: (
    item: string,
    event:
      | MouseEvent<HTMLLIElement, MouseEvent>
      | MouseEvent<HTMLDivElement, MouseEvent>
      | MouseEvent
  ) => void
  menuItems: MenuItemType[]
  sx?: SxProps<Theme>
  useLinks?: string
}
const Menu = ({
  customOnClickItemOnly,
  onClickItem,
  menuItems,
  sx = {},
  useLinks = '',
}: MenuProps) => {
  const navigate = useNavigate()
  const globalContext = useContext(GlobalContext)
  const itemSelected = globalContext?.selectedTab

  useEffect(() => {
    globalContext?.setSelectedTab(menuItems?.[0]?.items?.[0]?.label)
  }, [])

  const { isGreaterMd: isLargeScreen } = useSizes()

  const onClick = (
    item: string,
    event:
      | MouseEvent<HTMLLIElement, MouseEvent>
      | MouseEvent<HTMLDivElement, MouseEvent>
      | MouseEvent
  ) => {
    if (useLinks !== '') {
      navigate(`${useLinks}/${labelToPath(item)}`)
    }

    if (onClickItem && customOnClickItemOnly) {
      return onClickItem(item, event)
    }
    globalContext?.setSelectedTab(item)
    if (onClickItem) onClickItem(item, event)

    return null
  }

  return isLargeScreen ? (
    <MenuContainer sx={sx}>
      {menuItems.map((section, index) => (
        <ul key={`${section.sectionTitle}-${index}`}>
          <MenuListSubHeader disableSticky>
            {section.sectionTitle}
          </MenuListSubHeader>
          {section.items.map((item, i) => (
            <MenuItemWrapper
              key={`${item}-${i}`}
              selected={itemSelected === item.label}
            >
              <MenuListItem
                onClick={event => onClick(item.label, event)}
                selected={itemSelected === item.label}
                data-label={item.label}
              >
                {item.icon && (
                  <MenuListIcon selected={itemSelected === item.label}>
                    {item.icon}
                  </MenuListIcon>
                )}
                {item.label}
              </MenuListItem>
            </MenuItemWrapper>
          ))}
        </ul>
      ))}
    </MenuContainer>
  ) : (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        overflow: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        padding: theme => theme.spacing(3.5, 0),
      }}
    >
      {menuItems.map((section, iSections) =>
        section.items.map((item, i) => (
          <Chip
            variant="outlined"
            key={`chip-${item}-${i}`}
            label={item.label}
            data-label={item.label}
            onClick={event => onClick(item.label, event)}
            sx={theme => ({
              ...(i === 0 && {
                marginLeft: 2,
              }),
              ...(iSections === menuItems.length - 1 &&
                i === section.items.length - 1 && {
                  marginRight: `${theme.spacing(2)} !important`,
                }),
              ...(itemSelected === item.label && {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                '&.MuiChip-clickable:hover': {
                  backgroundColor: theme.palette.primary.main,
                },
              }),
            })}
          />
        ))
      )}
    </Stack>
  )
}

export default Menu

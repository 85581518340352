import ListRequestAccessItem from '@/pages/Case/components/Dataroom/components/AccessRequests/ListRequestAccessItem'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import { ConfirmationModal, ResponseModal } from '@/components'
import useDataroomRequests from './hooks/useDataroomRequests'
import { CaseEntry } from '../../types'

interface IDataroomRequestsProps {
  dataroom: CaseEntry['data_room']
}

const DataroomRequests = ({ dataroom }: IDataroomRequestsProps) => {
  const {
    requests,
    loading,
    onGiveAccess,
    onDenyAccess,
    modal,
    responseModal,
    onCloseModal,
    onSubmitModal,
    onCloseResponseModal,
  } = useDataroomRequests({ dataroom })

  return (
    <Box
      sx={{
        mt: 1,
        px: 2.5,
      }}
    >
      <Typography variant="subtitle2">Data Room Access Requests</Typography>
      <Grid container>
        {requests.map(req => (
          <Grid item xs={12} md={6} key={req.fileAccessId}>
            <ListRequestAccessItem
              request={req}
              hasAccess={false}
              onGiveAccess={onGiveAccess}
              onDenyAccess={onDenyAccess}
              loading={loading}
            />
          </Grid>
        ))}
      </Grid>
      {modal.open && (
        <ConfirmationModal
          open={modal.open}
          title={String(modal.title)}
          onClose={onCloseModal}
          AcceptButtonProps={{
            children: modal.buttonText,
            onClick: onSubmitModal,
          }}
        >
          {modal.description}
        </ConfirmationModal>
      )}
      {responseModal.open && (
        <ResponseModal
          open={responseModal.open}
          title={responseModal.title}
          bodyContent={responseModal.bodyContent}
          onClose={onCloseResponseModal}
        />
      )}
    </Box>
  )
}

export default DataroomRequests

import { useCallback } from 'react'

import { useStateMachine } from '@/hooks/useStateMachine'

interface IUseCreateInvitedAdminProps {
  hasLicense?: boolean
}

const useCreateInvitedAdminFlow = ({
  hasLicense,
}: IUseCreateInvitedAdminProps) => {
  const [state, send] = useStateMachine({})({
    initial: 'lawyerDecision',
    on: {
      INITIAL_STEP: 'lawyerDecision',
      PERSONAL_INFO: 'personalInfo',
      COMMUNICATION_PREFERENCES: 'communicationPreferences',
    },
    states: {
      lawyerDecision: {
        on: {
          NEXT: hasLicense ? 'professionalLicense' : 'personalInfo',
        },
      },
      professionalLicense: {
        on: {
          PREVIOUS: 'lawyerDecision',
          NEXT: 'practiceAreas',
        },
      },
      practiceAreas: {
        on: {
          PREVIOUS: 'professionalLicense',
          NEXT: 'education',
        },
      },
      education: {
        on: {
          PREVIOUS: 'practiceAreas',
          NEXT: 'personalInfo',
        },
      },
      personalInfo: {
        on: {
          PREVIOUS: hasLicense ? 'education' : 'lawyerDecision',
          NEXT: 'communicationPreferences',
        },
      },
      communicationPreferences: {
        on: {
          PREVIOUS: 'personalInfo',
        },
      },
    },
  })

  const next = useCallback(() => send('NEXT'), [send, hasLicense])
  const back = useCallback(() => send('PREVIOUS'), [send, hasLicense])
  const goToCommunicationPreferences = useCallback(
    () => send('COMMUNICATION_PREFERENCES'),
    [send]
  )

  return {
    state,
    send,
    next,
    back,
    goToCommunicationPreferences,
  }
}

export default useCreateInvitedAdminFlow

import { alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { PhoneNumber } from '@/components/PhoneNumber/PhoneNumber'

type TAgreementLeadProps = {
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
}

const AgreementLeads = ({
  firstName,
  lastName,
  email,
  phone,
}: TAgreementLeadProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="subtitle1">
        {firstName}
        {lastName ? ` ${lastName}` : ''}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {email && (
          <Typography
            variant="body2"
            sx={{
              color: theme => alpha(theme.palette.common.black, 0.6),
            }}
          >
            {email}
          </Typography>
        )}
        {phone && email && (
          <Typography
            variant="body2"
            sx={{
              color: theme => alpha(theme.palette.common.black, 0.6),
              mx: 0.5,
            }}
          >
            {' - '}
          </Typography>
        )}
        {phone && (
          <Typography
            variant="body2"
            sx={{
              color: theme => alpha(theme.palette.common.black, 0.6),
            }}
          >
            <PhoneNumber>{phone}</PhoneNumber>
          </Typography>
        )}
      </Box>
    </Box>
  </Box>
)

export default AgreementLeads

import { useState, useEffect } from 'react'
import useOrganizationId from '@/hooks/organization/useOrganizationId'
import { useGetOrganizationMembersLazyQuery } from '@/gql/appApi'
import { MEMBERSHIP_TYPES } from '@/constants'
import get from 'lodash/get'

export const useNoVettedData = () => {
  const [hasJHQMembersInMyOrg, setHasJHQMembersInMyOrg] = useState(false)

  const { id, loading: loadingOrg } = useOrganizationId()

  const [getOrganizationMember, { data, loading: loadingMembers }] =
    useGetOrganizationMembersLazyQuery({ fetchPolicy: 'network-only' })

  useEffect(() => {
    if (id) {
      getOrganizationMember({
        variables: {
          filter: {
            _and: [
              {
                organization_id: {
                  id: {
                    _eq: id,
                  },
                },
              },
              {
                _or: [
                  {
                    user_id: {
                      attorneys: {
                        membership_type: {
                          id: {
                            _eq: MEMBERSHIP_TYPES.Vetted,
                          },
                        },
                      },
                    },
                  },
                  {
                    user_id: {
                      attorneys: {
                        membership_type: {
                          id: {
                            _eq: MEMBERSHIP_TYPES.Qualified,
                          },
                        },
                      },
                    },
                  },
                ],
              },
            ],
          },
        },
      })
    }
  }, [getOrganizationMember, id])

  useEffect(() => {
    if (data) {
      setHasJHQMembersInMyOrg(get(data, 'organization_user', []).length > 0)
    }
  }, [data])

  return {
    isloadingHasJHQMembersInMyOrg: loadingOrg || loadingMembers,
    hasJHQMembersInMyOrg,
  }
}

import { useCallback, useContext } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { Search } from '@mui/icons-material'
import SortableItem from '@/components/DnDSortable/SortableItem'
import DnDSortableContext from '@/components/DnDSortable/DnDSortableContext'
import JDropdownUser from '@/components/atoms/JDropdownUser'
import { CreateOrEditWaterfallModal } from '@/components/OrganizationWaterfalls/components/CreateOrEditWaterfallModal'
import JSnackBar from '@/components/atoms/JSnackBar'
import { CreateCaseContext } from '@/components/Case/CreateCaseContext/CreateCaseContext'
import useAssignIndividually from './useAssignIndividually'
import { CreateCaseWaterfallMember } from '../../types'
import AttorneySortableItem from './AttorneySortableItem'

interface IAssignIndividuallyProps {
  orgId: string
}

const AssignIndividually = ({ orgId }: IAssignIndividuallyProps) => {
  const {
    methods: { setValue },
  } = useContext(CreateCaseContext)

  const {
    alert,
    handleClose,
    options: allOptions,
    users,
    selectedUsers,
    setSelectedUsers,
    setUsers,
    loading,
    setInputValue,
    editOrCreateWaterfallModalOpen,
    onCreateWaterfall,
    setEditOrCreateWaterfallModalOpen,
    onEditOrCreateWaterfallSuccess,
    onEditOrCreateWaterfallError,
  } = useAssignIndividually(orgId)

  const handleSelection = useCallback(
    (newValue: CreateCaseWaterfallMember[]) => {
      setSelectedUsers(newValue)
      const assigneesId = newValue.map(user => user.attorneyId)
      setValue('assigneesId', assigneesId)
    },
    [setSelectedUsers, setValue]
  )

  const hasMaxMembers = selectedUsers.length >= 3

  return (
    <>
      <DnDSortableContext
        items={selectedUsers}
        valueKey="attorneyId"
        setItems={handleSelection}
      >
        <Autocomplete
          options={allOptions}
          loading={loading || hasMaxMembers}
          value={users}
          getOptionLabel={option =>
            `${option.attorneyName} (${option.attorneyId})`
          }
          multiple
          filterOptions={(options, params) => {
            const filteredOptions = options.filter(option =>
              option.attorneyName
                .toLowerCase()
                .includes(params.inputValue.toLowerCase())
            )
            return filteredOptions
          }}
          onChange={(_, newAttorney) => {
            setUsers(newAttorney)
            handleSelection(newAttorney)
          }}
          isOptionEqualToValue={(option, v) =>
            option.attorneyId === v.attorneyId
          }
          getOptionDisabled={() => hasMaxMembers}
          onInputChange={(_, eInputValue) => {
            setInputValue(eInputValue)
          }}
          renderInput={params => (
            <TextField
              {...params}
              label="Select up to 3 users"
              placeholder="Select up to 3 users"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <Search color="disabled" />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <JDropdownUser
              key={option.attorneyId}
              user={{
                id: option.attorneyId,
                name: option.attorneyName,
                organization: option.attorneyOrganization,
                isVetted: option.isVetted,
                isQualified: option.isQualified,
                picture: option.avatar,
              }}
              sx={{
                avatar: { width: 36, height: 36 },
                list: { px: 0, py: 0, width: 'auto' },
              }}
              badgeSize="xsmall"
              {...props}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { key, ...rest } = getTagProps({ index })
              return (
                <Chip
                  key={option.attorneyId}
                  variant="outlined"
                  label={option.attorneyName}
                  {...rest}
                />
              )
            })
          }
          sx={{ mb: 3 }}
        />
        <Grid
          container
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Grid item xs={12} md={9.5}>
            {selectedUsers.map((user, index) => (
              <SortableItem
                key={user.attorneyId}
                id={user.attorneyId}
                containerSxProps={{
                  width: '100%',
                  justifyContent: 'flex-start',
                  mb: 2.5,
                }}
                dragIndicatorContainerSxProps={{
                  mb: { sm: 0, md: 0 },
                }}
              >
                <AttorneySortableItem member={user} position={index + 1} />
              </SortableItem>
            ))}
          </Grid>
          <Grid item xs={12} md={2.5}>
            {selectedUsers?.length > 0 && (
              <Button variant="text" size="small" onClick={onCreateWaterfall}>
                Save Waterfall
              </Button>
            )}
          </Grid>
        </Grid>
      </DnDSortableContext>
      {editOrCreateWaterfallModalOpen.open && (
        <CreateOrEditWaterfallModal
          open={editOrCreateWaterfallModalOpen.open}
          waterfall={editOrCreateWaterfallModalOpen.waterfall}
          onClose={() =>
            setEditOrCreateWaterfallModalOpen({
              open: false,
            })
          }
          onSuccess={onEditOrCreateWaterfallSuccess}
          onError={onEditOrCreateWaterfallError}
          organizationId={orgId}
        />
      )}
      <JSnackBar
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        horizontal="center"
        vertical="bottom"
        handleClose={handleClose}
      />
    </>
  )
}

export default AssignIndividually

import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

const Container = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  overflow: 'auto',
  '&::-webkit-scrollbar': { display: 'none' },
}))

export default Container

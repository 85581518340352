import { FC, useCallback, useMemo } from 'react'
import AuthLayout from '@/components/shared/AuthLayout'
import CircularProgress from '@mui/material/CircularProgress'
import PracticeAreas from '@/pages/CreateProfile/PracticeAreas/PracticeAreas'
import Education from '@/pages/CreateProfile/Education/Education'
import { TTokenValidationResponse } from '@/hooks/useTokenValidation'
import Alert from '@mui/material/Alert'
import ProfileCommunicationPreferences from '@/pages/CreateProfile/ProfileCommunicationPreferences/ProfileCommunicationPreferences'
import { CreateAccountData } from '../InitialStep/types'
import { useCreateInvitedAdmin } from './hooks/useCreateInvitedAdmin'
import { OrganizationDecision } from '../CreateOrganization/OrganizationDecision'
import { OrganizationCreateProfile } from '../CreateOrganization/OrganizationCreateProfile'
import ProfessionalLicenses from '../ProfessionalLicense/ProfessionalLicenses'

interface ICreateInvitedAdminProps {
  goBack: () => void
  createAccountValues: CreateAccountData
  tokenData: TTokenValidationResponse
}

const CreateInvitedAdmin: FC<ICreateInvitedAdminProps> = ({
  goBack,
  createAccountValues,
  tokenData,
}: ICreateInvitedAdminProps) => {
  const {
    state,
    hasLicense,
    next,
    back,
    formState,
    submitAndContinue,
    setHasLicense,
    handleCreateAccount,
    handleUpdateOrgUser,
    goToCommunicationPreferences,
    loading,
    error,
    setError,
  } = useCreateInvitedAdmin({ createAccountValues, tokenData })

  const handleGoToCreateAccount = useCallback(() => {
    goBack()
  }, [goBack])

  const renderSteps = useMemo((): JSX.Element => {
    switch (state.value) {
      case 'lawyerDecision':
        return (
          <OrganizationDecision
            back={handleGoToCreateAccount}
            next={next}
            hasLicense={hasLicense}
            handleLicenseSelection={setHasLicense}
            handleNoLicense={handleCreateAccount}
          />
        )
      case 'professionalLicense':
        return (
          <ProfessionalLicenses
            back={back}
            professionalLicensesValues={formState.professionalLicenses}
            onSubmit={handleCreateAccount}
            loading={false}
          />
        )
      case 'practiceAreas':
        return (
          <PracticeAreas
            initialPracticeAreas={formState.practiceAreas}
            onSubmit={data => submitAndContinue('practiceAreas', data)}
            secondaryBtnAction={goToCommunicationPreferences}
            loading={false}
          />
        )
      case 'education':
        return (
          <Education
            back={back}
            initialEducation={formState.education}
            onSubmit={data => submitAndContinue('education', data)}
          />
        )
      case 'personalInfo':
        return (
          <OrganizationCreateProfile
            back={back}
            hasLicense={hasLicense}
            profileValues={formState.personalInfo}
            onSubmit={data => submitAndContinue('personalInfo', data)}
            loading={loading}
            nextBtnText="Continue"
            prevBtnText={hasLicense ? 'Back' : 'Cancel'}
          />
        )
      case 'communicationPreferences':
        return (
          <ProfileCommunicationPreferences
            back={back}
            initialCommPreferences={formState.communicationPreferences}
            onSubmit={handleUpdateOrgUser}
          />
        )
      default:
        return <CircularProgress />
    }
  }, [
    state.value,
    handleGoToCreateAccount,
    next,
    hasLicense,
    setHasLicense,
    back,
    formState.professionalLicenses,
    formState.practiceAreas,
    formState.education,
    formState.personalInfo,
    formState.communicationPreferences,
    handleCreateAccount,
    handleUpdateOrgUser,
    goToCommunicationPreferences,
    submitAndContinue,
    loading,
  ])
  return (
    <AuthLayout>
      {error && (
        <Alert severity="error" onClose={() => setError('')}>
          {error}
        </Alert>
      )}
      {renderSteps}
    </AuthLayout>
  )
}

export default CreateInvitedAdmin

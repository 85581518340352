import { SellerHeadline } from './SellerHeadline'
import { useSellerReferral } from './useSellerReferral'
import { ReferralWithDrawer } from './ReferralWithDrawer'
import { SummaryHeader } from './SummaryHeader'

export const SellerReferral = () => {
  const { showReferralContent } = useSellerReferral()

  const {
    counterparts,
    summaryFee,
    showPromoteCase,
    showCaseManagerPermissionRequest,
    showContactInformation,
    showContactInformationInAccordion,
    showCounterpartContactInformation,
    showDataRoom,
    showExpirationMessage,
    showNegotiations,
    showWaterfallProposalDueDate,
    waterfallProposalDueDate,
  } = useSellerReferral()

  return (
    <ReferralWithDrawer
      headline={
        <SellerHeadline
          showWaterfallProposalDueDate={showWaterfallProposalDueDate}
          waterfallProposalDueDate={waterfallProposalDueDate}
        />
      }
      header={
        <SummaryHeader
          item
          counterparts={counterparts}
          fee={summaryFee}
          waterfallProposalDueDate={waterfallProposalDueDate}
          showWaterfallProposalDueDate={showWaterfallProposalDueDate}
        />
      }
      showPromoteCase={showPromoteCase}
      showCaseManagerPermissionRequest={showCaseManagerPermissionRequest}
      showContactInformation={showContactInformation}
      showContactInformationInAccordion={showContactInformationInAccordion}
      showCounterpartContactInformation={showCounterpartContactInformation}
      showDataRoom={showDataRoom}
      showExpirationMessage={showExpirationMessage}
      showNegotiations={showNegotiations}
      showReferralContent={showReferralContent}
    />
  )
}

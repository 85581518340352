import {
  SessionKey,
  sessionStorageService,
} from '@/services/SessionStorage/SessionStorageService'
import { useLocalStorage } from 'usehooks-ts'

const useAttorneyData = () => {
  const [isVetted, setIsVetted] = useLocalStorage(
    SessionKey.IS_VETTED_ATTORNEY_KEY,
    false
  )
  const [isQualified, setIsQualified] = useLocalStorage(
    SessionKey.IS_QUALIFIED_ATTORNEY_KEY,
    false
  )

  // isVetted
  const getIsAttorneyVetted = () => isVetted === true
  const setIsAttorneyVetted = (value: boolean) => setIsVetted(value)
  const removeIsAttorneyVetted = () =>
    sessionStorageService.removeItem(SessionKey.IS_VETTED_ATTORNEY_KEY)

  // isQualified
  const getIsQualifiedAttorney = () => isQualified === true
  const setIsQualifiedAttorney = (value: boolean) => setIsQualified(value)
  const removeIsQualifiedAttorney = () =>
    sessionStorageService.removeItem(SessionKey.IS_QUALIFIED_ATTORNEY_KEY)

  const isJusticeHQMember = () =>
    getIsQualifiedAttorney() && getIsAttorneyVetted()

  return {
    getIsAttorneyVetted,
    setIsAttorneyVetted,
    removeIsAttorneyVetted,

    getIsQualifiedAttorney,
    setIsQualifiedAttorney,
    removeIsQualifiedAttorney,

    isJusticeHQMember,
  }
}

export default useAttorneyData

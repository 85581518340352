import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  styled,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface SidebarAccordionProps {
  title: string
  subtitle?: string | React.ReactElement<typeof Typography>
  children: React.ReactNode
  defaultExpanded?: boolean
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  borderTop: `1px solid ${theme.palette.divider}`,
  borderRadius: '0 !important',
  '&.Mui-expanded': {
    borderRadius: '0 !important',
    margin: 0,
  },
}))

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginRight: theme.spacing(1),
  },
  minHeight: theme.spacing(6),
  height: theme.spacing(6),
  '&.Mui-expanded': {
    minHeight: theme.spacing(6),
    height: theme.spacing(6),
  },
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: 0,
  paddingBottom: 0,
  '& .MuiAccordionSummary-content': {
    overflow: 'hidden',
    flex: '1 1 auto',
  },
}))

const TitleBox = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
})

const TitleTypography = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const SubtitleTypography = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const SidebarAccordion: React.FC<SidebarAccordionProps> = ({
  title,
  subtitle,
  children,
  defaultExpanded = false,
}) => (
  <StyledAccordion defaultExpanded={defaultExpanded}>
    <StyledAccordionSummary expandIcon={<ExpandMoreIcon fontSize="small" />}>
      <TitleBox
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TitleTypography variant="body2">{title}</TitleTypography>
        {subtitle && typeof subtitle === 'string' ? (
          <SubtitleTypography variant="caption" color="text.secondary">
            {subtitle}
          </SubtitleTypography>
        ) : (
          subtitle
        )}
      </TitleBox>
    </StyledAccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </StyledAccordion>
)

export default SidebarAccordion

import React from 'react'
import { Box, Skeleton } from '@mui/material'
import { styled } from '@mui/system'

const HistoryContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}))

const HistoryItem = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const ItemContent = styled(Box)({
  flexGrow: 1,
  marginLeft: '16px',
})

const NegotiationHistorySkeleton: React.FC = () => (
  <HistoryContainer>
    <Skeleton
      variant="text"
      width={140}
      height={20}
      sx={{ transform: 'scale(1, 1)' }}
    />
    {[...Array(4)].map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <HistoryItem key={index}>
        <Skeleton
          variant="circular"
          width={40}
          height={40}
          sx={{ transform: 'scale(1, 1)' }}
        />
        <ItemContent>
          <Skeleton
            variant="text"
            width={100}
            height={16}
            sx={{ mb: 1, transform: 'scale(1, 1)' }}
          />
          <Skeleton
            variant="text"
            width={250}
            height={20}
            sx={{ transform: 'scale(1, 1)' }}
          />
        </ItemContent>
        <Skeleton
          variant="text"
          width={35}
          height={20}
          sx={{ transform: 'scale(1, 1)' }}
        />
      </HistoryItem>
    ))}
  </HistoryContainer>
)

export default NegotiationHistorySkeleton

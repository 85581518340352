import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { alpha } from '@mui/material/styles'
import GradeOutlined from '@mui/icons-material/GradeOutlined'
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import Grade from '@mui/icons-material/Grade'
import EditCaseButton from '@/pages/Case/components/EditCaseButton/EditCaseButton'

import {
  Attorney_Case_Mp_Preference,
  Case_Purchase,
  Case_Purchase_Activity,
  GetMarketplacePreferencesQuery,
} from '@/gql/appApi'
import { useSizes } from '@/hooks/useSizes'
import Fee from '@/components/Fee'
import { CASE_ACCESS_TYPES, CASE_STATUS_FLOW } from '@/constants'
import { theme } from '@/styles/theme'
import { InvitedMatchMessage } from '@/components/InvitedMatchMessage'
import { useFeatureFlags } from '@/optimizely/useFeatureFlags'
import { FeatureFlag } from '@/optimizely/types'
import DeleteCaseButton from '../DeleteCaseButtons/DeleteCaseButtons'
import CaseTitle from './CaseTitle'
import CaseInvitedTag from './CaseInvitedTag'
import { AccessType, MpPreferences, TCase } from '../../types'
import PostedOnDate from '../PostedOnDate.tsx/PostedOnDate'
import CaseHeaderChip from './CaseHeaderChip'
import { CaseAlert } from '../CaseBody/CaseAlert'
import { CaseProposalSubmittedAlert } from './CaseProposalSubmittedAlert'

interface ICaseHeaderProps {
  title: string
  accessType: AccessType
  fee: number
  priorityText: string
  preferences?: GetMarketplacePreferencesQuery
  preferencesLoading: boolean
  deletePreference: (preferenceId: string) => void
  createPreference: (preferenceId: MpPreferences) => void
  isSameOrganization: boolean
  caseInfo: TCase
  isAccepted?: boolean
  postedOnDate?: string
  hasAdminAccess?: boolean
  hasCaseManagerAccess?: boolean
  isCaseManager?: boolean
  caseAccepted?: boolean
  isAttorneyOwner?: boolean
  showInvitationPricing: boolean
  waterfallCasePurchase?: Case_Purchase
  alertTitle: string | null
  alertSubtitle: string | null
  caseActivity?: Case_Purchase_Activity[]
}

const CaseHeader: React.FC<ICaseHeaderProps> = ({
  accessType,
  title,
  fee,
  priorityText,
  preferences,
  preferencesLoading,
  deletePreference,
  createPreference,
  isSameOrganization,
  caseInfo,
  isAccepted = false,
  postedOnDate,
  hasAdminAccess,
  hasCaseManagerAccess,
  isCaseManager,
  caseAccepted,
  isAttorneyOwner,
  showInvitationPricing,
  waterfallCasePurchase,
  alertTitle,
  alertSubtitle,
  caseActivity,
}: ICaseHeaderProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [showCaseProposalSubmittedAlert, setShowCaseProposalSubmittedAlert] =
    useState(false)
  const [watching, setWatching] = useState<
    Attorney_Case_Mp_Preference | undefined
  >(undefined)
  const [hide, setHide] = useState<Attorney_Case_Mp_Preference | undefined>(
    undefined
  )
  const { isGreaterMd } = useSizes()
  const isOwner = accessType === CASE_ACCESS_TYPES.Owner
  const isWaterfall = accessType === CASE_ACCESS_TYPES.Waterfall

  const isCaseManagerWithAccess =
    isCaseManager && hasCaseManagerAccess && isOwner

  const isAdminAttorneyWithAccess = hasAdminAccess && isOwner

  const hideEditDeleteButtons =
    (!isAttorneyOwner &&
      !isAdminAttorneyWithAccess &&
      !isCaseManagerWithAccess) ||
    caseAccepted

  const isCaseSigned = caseInfo?.status === CASE_STATUS_FLOW.Signed

  // Watching and Hide/Show buttons
  const hidePreferencesButtons =
    isWaterfall || isOwner || isSameOrganization || isCaseSigned

  const featureFlags = useFeatureFlags()
  const isNegotiationFlowImprovementEnabled =
    featureFlags[FeatureFlag.NEGOTIATION_FLOW_IMPROVEMENTS]

  useEffect(() => {
    if (preferences) {
      setWatching(
        preferences?.attorney_case_mp_preference?.find(
          f => f.case_mp_preference_id?.name === 'Following'
        )
      )
      setHide(
        preferences?.attorney_case_mp_preference?.find(
          f => f.case_mp_preference_id?.name === 'Hidden'
        )
      )
    }
  }, [preferences])

  useEffect(() => {
    const displayAlert = searchParams.get('case_proposal_submitted')

    if (
      displayAlert &&
      !showCaseProposalSubmittedAlert &&
      caseActivity?.[0]?.fee
    ) {
      setShowCaseProposalSubmittedAlert(true)

      searchParams.delete('case_proposal_submitted')
      setSearchParams(searchParams, { replace: true })
    }

    // If there is no fee, it means there is no activity (so the proposal was either
    // withdrawn, rejected, expired, etc.), so we don't want to show the alert anymore.
    if (showCaseProposalSubmittedAlert && !caseActivity?.[0]?.fee) {
      setShowCaseProposalSubmittedAlert(false)
    }
  }, [
    searchParams,
    setSearchParams,
    showCaseProposalSubmittedAlert,
    caseActivity,
  ])

  const iconProps = {
    ...(isNegotiationFlowImprovementEnabled && {
      sx: {
        height: '14px',
      },
    }),
  }

  const renderWatchingButton = () => {
    if (hidePreferencesButtons) {
      return null
    }

    let watchingButtonText = ''
    if (isGreaterMd || isNegotiationFlowImprovementEnabled) {
      watchingButtonText = watching ? 'Stop Watching' : 'Start Watching'
    }
    const watchingIcon = watching ? (
      <Grade {...iconProps} />
    ) : (
      <GradeOutlined {...iconProps} />
    )
    const handleWatchingClick = watching
      ? () => deletePreference(watching.id)
      : () => createPreference(MpPreferences.FOLLOWING)

    return isNegotiationFlowImprovementEnabled ? (
      <CaseHeaderChip
        label={watchingButtonText}
        icon={watchingIcon}
        textColor={alpha(theme.palette.common.black, 0.87)}
        iconColor={alpha(theme.palette.common.black, 0.87)}
        onClick={handleWatchingClick}
      />
    ) : (
      <Button
        disabled={preferencesLoading}
        startIcon={watchingIcon}
        onClick={handleWatchingClick}
        size="medium"
        fullWidth
      >
        {watchingButtonText}
      </Button>
    )
  }

  const renderHideButton = () => {
    if (hidePreferencesButtons) {
      return null
    }

    let hideButtonText = ''
    if (isGreaterMd || isNegotiationFlowImprovementEnabled) {
      hideButtonText = hide ? 'Show Case' : 'Hide Case'
    }
    const hideIcon = hide ? (
      <VisibilityOffOutlined {...iconProps} />
    ) : (
      <VisibilityOutlined {...iconProps} />
    )
    const handleHideClick = hide
      ? () => deletePreference(hide.id)
      : () => createPreference(MpPreferences.HIDDEN)

    return isNegotiationFlowImprovementEnabled ? (
      <CaseHeaderChip
        label={hideButtonText}
        icon={hideIcon}
        textColor={alpha(theme.palette.common.black, 0.87)}
        iconColor={alpha(theme.palette.common.black, 0.87)}
        onClick={handleHideClick}
      />
    ) : (
      <Button
        disabled={preferencesLoading}
        startIcon={hideIcon}
        onClick={handleHideClick}
        size="medium"
        fullWidth
      >
        {hideButtonText}
      </Button>
    )
  }

  const renderDeleteButton = () => {
    if (hideEditDeleteButtons) {
      return null
    }

    return (
      <DeleteCaseButton
        variant="text"
        size="medium"
        fullWidth
        caseInfo={caseInfo}
        showText={false}
        sx={{ whiteSpace: 'nowrap' }}
      />
    )
  }

  const renderEditButton = () => {
    if (hideEditDeleteButtons) {
      return null
    }

    return <EditCaseButton caseId={caseInfo.id} isAccepted={isAccepted} />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        pb: {
          xs: 2,
          md: 0,
        },
        ...(!isNegotiationFlowImprovementEnabled && {
          borderBottom: '2px solid',
          borderBottomColor: 'secondary.main',
          pb: 2,
        }),
      }}
    >
      {priorityText && (
        <Box>
          <CaseInvitedTag title={priorityText} />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: theme.spacing(1),
          alignSelf: 'stretch',
        }}
      >
        <PostedOnDate date={postedOnDate} />
        <CaseTitle title={title} />
      </Box>
      <Box>
        <Grid
          container
          sx={{
            justifyContent: isNegotiationFlowImprovementEnabled
              ? 'flex-start'
              : 'space-between',
          }}
        >
          {!isNegotiationFlowImprovementEnabled && (
            <Grid item>
              <Fee variant="outlined" fee={fee} showText />
            </Grid>
          )}
          <Grid
            item
            sx={{
              gap: 2,
              display: 'flex',
            }}
          >
            {renderWatchingButton()}
            {renderHideButton()}
            {renderEditButton()}
            {renderDeleteButton()}
          </Grid>
        </Grid>
        {isNegotiationFlowImprovementEnabled && showInvitationPricing && (
          <InvitedMatchMessage
            sx={{ mt: 2 }}
            waterfallCasePurchase={waterfallCasePurchase}
          />
        )}
      </Box>
      {alertTitle && alertSubtitle && (
        <CaseAlert
          sx={{ my: 2 }}
          alertTitle={alertTitle}
          alertSubtitle={alertSubtitle}
        />
      )}
      {showCaseProposalSubmittedAlert && (
        <Grid item xs={12}>
          <CaseProposalSubmittedAlert
            caseActivity={caseActivity ?? []}
            sx={{ mb: 1 }}
          />
        </Grid>
      )}
    </Box>
  )
}

export default CaseHeader

import { AppBar, Button, Grid } from '@mui/material'
import { useSizes } from '@/hooks/useSizes'
import { FC } from 'react'

import { IApplicationBarProps } from './types'

export const MobileAppBar: FC<IApplicationBarProps> = ({
  onPrimaryActionClick,
  onSecondaryActionClick,
  PrimaryActionProps = {},
  SecondaryActionProps = {},
}) => {
  const { screenSize } = useSizes()
  const mobileLayout = screenSize !== 'desktop'

  return (
    <AppBar
      color="transparent"
      position="fixed"
      sx={theme => ({
        top: 'auto',
        bottom: 0,
        pl: 3,
        pr: 3,
        display: `${mobileLayout ? 'grid' : 'none'}`,
        background: theme.palette.common.white,
      })}
    >
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mb={2}
        mt={2}
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <Button
            size="large"
            sx={{ width: '100%' }}
            variant="text"
            onClick={onSecondaryActionClick}
            {...SecondaryActionProps}
          >
            Discard Changes
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            size="large"
            sx={{ width: '100%', bgcolor: 'secondary.main' }}
            variant="contained"
            type="submit"
            onClick={onPrimaryActionClick}
            {...PrimaryActionProps}
          >
            Save Changes
          </Button>
        </Grid>
      </Grid>
    </AppBar>
  )
}

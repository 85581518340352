import { Typography } from '@mui/material'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'

export interface DeleteCaseModalProps {
  open: boolean
  onClose: () => void
  caseTitle: string
  onDelete: () => void
  loading?: boolean
}

const DeleteCaseModal = ({
  open,
  onClose,
  caseTitle,
  onDelete,
  loading,
}: DeleteCaseModalProps) => (
  <ConfirmationModal
    title="Remove Case"
    open={open}
    AcceptButtonProps={{
      children: 'Yes, remove the case',
      onClick: onDelete,
      disabled: loading,
    }}
    onClose={onClose}
  >
    <Typography display="inline" component="div">
      Are you sure you want to remove{' '}
      <Typography
        display="inline"
        variant="subtitle1"
      >{`"${caseTitle}"`}</Typography>
      ?
    </Typography>
    <br />
    <Typography>
      The case cannot be restored and the open negotiations will be concluded.
    </Typography>
  </ConfirmationModal>
)

export default DeleteCaseModal

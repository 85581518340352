import { theme } from '@/styles/theme'
import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const integrationV2Title = (
  <>
    Simplify your legal workflow. Link your account to sync data on
    <br />
    cases, firm members and negotiations with other platforms.
  </>
)

export const INTEGRATION_LINKS = {
  ZENDESK_HELP_LEAD_DOCKET:
    'https://attorneyshare.zendesk.com/hc/en-us/articles/24774035890331-Lead-Docket',
  LEAD_DOCKET_INSTRUCTIONS_URL:
    'https://attorney.atlassian.net/wiki/external/OThhYjA1YzY5NGZiNDA2ZGI4ODI4NzY1MTMxZTczYTA',
  LITIFY_INSTRUCTIONS_URL:
    'https://attorney.atlassian.net/wiki/external/NWJlYjgwYzk3MWNlNDI5ODk3NzY0NmNlZWIzMzFmZGI',
  SALESFORCE_INSTRUCTIONS_URL:
    'https://attorney.atlassian.net/wiki/external/NGViZTYxMDJjY2NjNDQ1YzhjODFhZTk4YzAxN2JiYTg',
  MY_CASE_INSTRUCTIONS_URL:
    'https://attorney.atlassian.net/wiki/external/YTFiZWI4Yjg2YTE1NDUyMmE3NzI4MzJhZGU1OTRkNmI',
}

export default {
  TITLE: 'Integrations',
  LOADING: 'Loading Integrations...',
  INTEGRATION_LEGACY: {
    SUBTITLE:
      'Connect your Attorney Share account with your Case Management Software.',
  },
  INTEGRATION_V2: {
    SUBTITLE: integrationV2Title,
    CLIO_GROW: {
      NAME: 'Clio Grow',
      ICON: 'Clio',
      SHORT_NAME: 'Clio',
      SUBTITLE:
        "Combine Attorney Share with Clio to simplify tasks. Your actions in one platform are reflected in the other. You don't have to worry about keeping things in order; we take care of that for you.",
    },
    LEAD_DOCKET: {
      NAME: 'Lead Docket',
      NAME_EXPANDED: 'Connect with your Lead Docket Account',
      ICON: 'LeadDocket',
      SUBTITLE:
        'Synchronize your Lead Docket account with Attorney Share. Automate posting leads from Lead Docket onto the Attorney Share marketplace, and receive updates directly in Lead Docket.',
    },
    LAWMATICS: {
      NAME: 'Lawmatics',
      ICON: 'Lawmatics',
      SUBTITLE:
        'Link your Attorney Share with Lawmatics to ensure lead continuity across platforms. Any lead received in Attorney Share will trigger the automatic creation of an opportunity within Lawmatics.',
    },
    LITIFY: {
      NAME: 'Litify',
      NAME_EXPANDED: 'Connect with your Litify Account',
      ICON: 'Litify',
      SUBTITLE:
        'Connect your Attorney Share account to Litify. Automate sending referrals from Litify to Attorney Share, and receive updates directly in Litify.',
    },
    SALESFORCE: {
      NAME: 'Salesforce & Litify',
      NAME_EXPANDED: 'Connect with your Salesforce Account',
      ICON: 'Salesforce',
      SUBTITLE:
        'Connect your Attorney Share account to Salesforce. Automate sending referrals from Salesforce to Attorney Share, and receive updates directly in Salesforce.',
    },
    MY_CASE: {
      NAME: 'MyCase',
      ICON: 'MyCase',
      NAME_EXPANDED: 'Connect with your MyCase Account',
      SUBTITLE: (
        <>
          Connect your Attorney Share account to MyCase. Automate sending
          referrals from MyCase to Attorney Share, and receive updates directly
          in MyCase.
          <Typography variant="body2" mt={1}>
            Please follow{' '}
            <Link
              to={INTEGRATION_LINKS.MY_CASE_INSTRUCTIONS_URL}
              target="_blank"
              rel="noreferrer"
              style={{
                color: theme.palette.primary.main,
                textDecoration: 'underline',
              }}
            >
              these instructions
            </Link>{' '}
            to complete the necessary steps in MyCase to enable the integration
            with Attorney Share.
          </Typography>
        </>
      ),
    },
  },
}

export const LCMS_IDS = {
  CLIO: 'bdf9d0be-1f4a-11ee-bf7b-9bbe9eba5854',
  LAWMATICS: '32f7be80-b929-11ee-ac7f-836582a7a273',
  LEAD_DOCKET: '45452050-b929-11ee-ac7f-3b1a50530f35',
  LITIFY: '16a85c6c-267b-11ee-b8dd-27a40fa55c87',
  SALESFORCE: '6b693cc4-0489-4b1a-84e7-9a33e4c66c87',
  MY_CASE: '16a7afba-267b-11ee-b8db-db59007e3ffa',
} as const

export const integrationsIconSizes = {
  sm: { height: '32px', width: '32px' },
  md: { height: '48px', width: '48px' },
  lg: { height: '64px', width: '64px' },
}

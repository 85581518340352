import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer, {
  TableContainerProps,
} from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import AnimatedNumber, { integerFormatter } from '@/components/AnimatedNumber'
import useTheme from '@mui/material/styles/useTheme'
import { animationDelay, animationDuration } from '../constants'

interface TopJurisdiction {
  jurisdiction: string
  referrals: number
}

export interface TopJusrisdictionsProps extends TableContainerProps {
  data: TopJurisdiction[]
}

const TopJurisdictions = ({ data, sx, ...rest }: TopJusrisdictionsProps) => {
  const theme = useTheme()

  return (
    <TableContainer
      component={Paper}
      sx={[
        { px: 1.5, pt: 1, pb: 1.5, 'td, th': { py: 1.5, border: 0 } },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant="subtitle1">Top Jurisdictions</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ jurisdiction, referrals }) => (
            <TableRow key={`${jurisdiction}_${referrals}`}>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography>{jurisdiction}</Typography>
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontWeight: theme.typography.fontWeightBold,
                }}
              >
                <AnimatedNumber
                  formatter={integerFormatter}
                  startingNumber={0}
                  delay={animationDelay}
                  duration={animationDuration}
                >
                  {referrals}
                </AnimatedNumber>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TopJurisdictions

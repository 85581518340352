import { useMemo } from 'react'

import useCreateNewCase from '@/components/Case/CaseForm/hooks/useCreateNewCase'
import dayjs from 'dayjs'
import { useCase } from '@/pages/Case/hooks/useJCase'
import {
  ICaseForm,
  TLead,
  TLeadFormData,
  TContact,
} from '@/components/Case/CaseForm/types'
import { TCase } from '@/pages/Case/types'
import {
  CASE_PURCHASE_STATUS,
  DEFAULT_CASE_FEE,
  CONTACT_TYPES,
} from '@/constants'
import CustomLoading from '@/components/Loading'
import { ContentCaseLayout, ResponseModal } from '@/components'
import { MODAL_TYPES } from '@/types'
import ErrorPage from '@/components/shared/ErrorPage'
import { CommentsDisabledOutlined } from '@mui/icons-material'
import useEditCase from '@/components/Case/CaseForm/hooks/useEditCase'
import { useNavigate } from 'react-router-dom'
import { Case_Ownership } from '@/gql/appApi'
import useAttorneyId from '@/hooks/useAttorneyId'
import CreateNewCase from '..'

const EditCaseForm = () => {
  const { processStages, usStates, languages } = useCreateNewCase()
  const editCase = useEditCase()
  const navigate = useNavigate()

  const { dataCase, dataCaseLoading, isCaseFromSomeoneInMyOrganization } =
    useCase()

  const { getAttorneyId } = useAttorneyId()
  const attorneyId = getAttorneyId()

  const caseActivity = dataCase?.case_purchase_activity
  const caseInfo: TCase = caseActivity?.[0]?.case_purchase_id?.case_id as TCase

  const editCaseDefaultValues = useMemo(() => {
    const leadsInfo: TLead[] = (caseInfo?.leads as TLead[]) ?? []

    // Map leads info to lead form data, default to empty lead object if no leads
    const leads: TLeadFormData[] = leadsInfo?.length
      ? leadsInfo?.map((lead: TLead) => ({
          id: lead.id,
          firstName: lead?.first_name ?? '',
          lastName: lead?.last_name ?? '',
          email:
            lead?.contacts?.find(
              (contact: TContact) =>
                contact?.type?.id === CONTACT_TYPES.EmailContactType
            )?.value ?? '',
          phone:
            lead?.contacts?.find(
              (contact: TContact) =>
                contact?.type?.id === CONTACT_TYPES.PhoneContactType
            )?.value ?? '',
        }))
      : [
          {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
          },
        ]

    const legalStage =
      processStages.find(stage => stage.name === caseInfo?.process_stage?.name)
        ?.id ?? ''
    const jurisdiction =
      usStates.find(state => state.code === caseInfo?.jurisdiction?.code)?.id ??
      ''
    const language =
      languages.find(lang => lang.name === caseInfo?.preferred_language?.name)
        ?.id ?? ''

    const mkpVisibility = caseInfo?.mp_visibility?.name?.toLowerCase()
    const isPriority = mkpVisibility === 'priority'

    const caseManagers =
      caseInfo?.ownerships?.map(
        (ownership: Partial<Case_Ownership> | null) => ownership?.user_id?.id
      ) ?? []

    const filteredCaseManagers: string[] = caseManagers.filter(
      (managerId): managerId is string => typeof managerId === 'string'
    )

    return {
      caseName: caseInfo?.name ?? '',
      caseDescription: caseInfo?.description ?? '',
      caseDescriptionFormatted:
        (caseInfo?.description_formatted || caseInfo?.description) ?? '',
      confidentiality: true,
      leadInfo: leads ?? [
        {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        },
      ],
      fee: caseInfo?.fee?.toString() ?? DEFAULT_CASE_FEE ?? '',
      financingSource: caseInfo?.financing_source?.id ?? '',
      terms: true,
      caseType: caseInfo?.type?.id ?? '',
      legalStage,
      jurisdiction,
      caseNumber: caseInfo?.case_number ?? '',
      language,
      incidentDate: caseInfo?.incident_date
        ? dayjs(caseInfo.incident_date)
        : null,
      // proposalsDueDate: caseInfo?.proposal_due_date ?? '',
      caseAlias: caseInfo?.case_alias ?? '',
      internalCaseNumber: caseInfo?.case_number ?? '',
      isPriority,
      onBehalfOf: null,
      caseManagers: filteredCaseManagers?.length ? filteredCaseManagers : [],
    }
  }, [caseInfo, processStages, usStates, languages])

  const onSubmit = async (formData: ICaseForm) => {
    if (!caseInfo.id) return
    // eslint-disable-next-line consistent-return
    return editCase(formData, caseInfo.id)
  }

  // If case is not fully loaded, return loading page
  if (dataCaseLoading) {
    return (
      <ContentCaseLayout>
        <CustomLoading message="Loading case information..." />
      </ContentCaseLayout>
    )
  }

  const isOwner = Boolean(
    dataCase?.case_purchase_activity.find(
      f => f?.case_purchase_id?.case_id?.attorney_id?.id === attorneyId
    )
  )

  const ownerId =
    dataCase?.case_purchase_activity?.[0]?.case_purchase_id?.case_id
      ?.attorney_id?.id

  // This is to prevent case probing by changing the case id in the url
  if (
    !ownerId ||
    !isCaseFromSomeoneInMyOrganization ||
    (!dataCase && !dataCaseLoading)
  ) {
    return (
      <ContentCaseLayout>
        <ErrorPage message="You don't have permissions to view this case" />
      </ContentCaseLayout>
    )
  }

  if (
    (isOwner || isCaseFromSomeoneInMyOrganization) &&
    caseActivity?.[0]?.activity_entry_status?.id === CASE_PURCHASE_STATUS.Accept
  ) {
    return (
      <ResponseModal
        type={MODAL_TYPES.CASE_NOT_EDITABLE}
        open
        onClose={() => {
          navigate(`/case/${caseInfo.id}`)
        }}
        icon={<CommentsDisabledOutlined />}
        buttonText="View Case"
      />
    )
  }

  return (
    <CreateNewCase
      onSubmit={onSubmit}
      isEdit
      caseId={caseInfo.id}
      defaultValues={editCaseDefaultValues}
      modalType={MODAL_TYPES.CASE_EDITED}
    />
  )
}

export default EditCaseForm

import axios from 'axios'
import request from '@/axios/config'
import { useQuery } from 'react-query'
import { validateToken } from './validateToken'
import { PricesResponse, MembersPricesResponse } from './types'

const fetchMembersPrices = async ({
  caseId,
  onBehalfOfUserId,
}: {
  caseId: string
  onBehalfOfUserId?: string
}): Promise<PricesResponse | null> => {
  validateToken()
  try {
    const req = await request.get<MembersPricesResponse>(
      `/members/prices?caseId=${caseId}${
        onBehalfOfUserId ? `&forUserId=${onBehalfOfUserId}` : ''
      }`
    )
    if (req.data) {
      return {
        data: req.data,
        error: '',
      }
    }
    return { data: null, error: 'Data not found' }
  } catch (error) {
    let errorMessage = 'An unexpected error occurred'
    if (axios.isAxiosError(error)) {
      errorMessage = error.message
    }
    return {
      data: null,
      error: errorMessage,
    }
  }
}

export const useMembersPrices = ({
  caseId,
  onBehalfOfUserId,
}: {
  caseId: string
  onBehalfOfUserId?: string
}) =>
  useQuery<PricesResponse | null, Error>(
    ['membersPrices', caseId],
    () => fetchMembersPrices({ caseId, onBehalfOfUserId }),
    {
      // Used to fetch data only when needed
      enabled: false,
    }
  )

import Button from '@mui/material/Button'
import { theme } from '@/styles/theme'
import { IconButton } from '@mui/material'
import { ExpandMoreProps, IntegrationItemExpandableProps } from '../../types'

const ExpandMore = (props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return (
    <IconButton
      aria-label="expand"
      sx={{
        '&:hover': {
          background: 'none',
        },
      }}
      {...other}
    />
  )
}

const IntegrationItemExpandableButton = ({
  expanded,
  setExpanded,
  isConnectedToOtherLcms,
}: IntegrationItemExpandableProps) => {
  if (isConnectedToOtherLcms) return null

  const handleExpandClick = () => {
    setExpanded?.(!expanded)
  }

  return (
    <ExpandMore
      expand={!expanded}
      onClick={handleExpandClick}
      aria-expanded={expanded}
      aria-label={expanded ? 'Cancel' : 'Connect'}
      sx={{
        cursor: !expanded && isConnectedToOtherLcms ? 'default' : 'pointer',
        padding: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
      disabled={!expanded && isConnectedToOtherLcms}
    >
      <Button
        sx={{
          [theme.breakpoints.up('md')]: {
            width: '200px',
          },
        }}
        size="large"
        variant={expanded ? 'text' : 'contained'}
        disabled={!expanded && isConnectedToOtherLcms}
      >
        {expanded ? 'Cancel' : 'Connect'}
      </Button>
    </ExpandMore>
  )
}

export default IntegrationItemExpandableButton

import { Avatar, Box } from '@mui/material'
import { useTheme } from '@mui/system'

type AvatarDisplayProps = {
  images: string[]
}

// TODO: Pass user names to the alt attribute for fallback initial display
export const AvatarDisplay: React.FC<AvatarDisplayProps> = ({ images }) => {
  const theme = useTheme()
  if (!images || images.length === 0) {
    return <Avatar alt="Avatar" />
  }
  if (images.length === 1) {
    return <Avatar alt="Avatar" src={images[0]} />
  } else {
    return (
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Avatar
          alt="Avatar 1"
          src={images[0]}
          sx={{
            width: 36,
            height: 36,
            boxSizing: 'content-box',
            border: `3px solid ${theme.palette.common.white}`,
            zIndex: 2,
            bottom: -4,
          }}
        />
        <Avatar
          alt="Avatar 2"
          src={images[1]}
          sx={{
            width: 26,
            height: 26,
            zIndex: 1,
            position: 'relative',
            right: 12,
            top: -10,
          }}
        />
      </Box>
    )
  }
}

export default AvatarDisplay

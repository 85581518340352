import { useEffect, useState } from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'
import { sleep } from '@/utils'

import {
  animationDelay,
  animationDuration,
} from '@/components/Metrics/constants'

interface ProportionBarProps extends BoxProps {
  rangeMax: number
  value: number
}

export const ProportionBar = ({
  rangeMax,
  value,
  sx,
  ...rest
}: ProportionBarProps) => {
  const theme = useTheme()
  const prefersReducedMotion = useMediaQuery('@media (prefers-reduced-motion)')

  const [widthPercent, setWidthPercent] = useState(0)

  useEffect(() => {
    ;(async () => {
      if (!prefersReducedMotion) {
        await sleep(animationDelay)
      }

      const proportion = value / rangeMax
      const percentage = proportion * 100

      setWidthPercent(percentage)
    })()
  }, [prefersReducedMotion, rangeMax, value])

  return (
    <Box
      width={`${widthPercent}%`}
      height={theme.spacing(1.75)}
      sx={[
        {
          ...(!prefersReducedMotion && {
            transition: theme.transitions.create(['width'], {
              duration: animationDuration,
            }),
          }),
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    />
  )
}

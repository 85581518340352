import { styled } from '@mui/material'
import Badge from '@mui/material/Badge'
import Button from '@mui/material/Button'
import { MessageOutlined } from '@mui/icons-material'
import { useStreamChat } from '@/hooks/useStreamChat'

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  padding: '0.5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover, &:focus, &:active': {
    backgroundColor: 'transparent',
  },
}))

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    fontFamily: 'inherit',
    fontSize: '.875rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px 6px',
  },
}))

const MessageIcon = styled(MessageOutlined)(({ theme }) => ({
  color: theme.palette.common.white,
  transform: 'scaleX(-1)',
}))

const MessagesButton = () => {
  const { totalUnreadMessages, setMessageDrawerOpen } = useStreamChat()

  const handleMessageClick = () => {
    setMessageDrawerOpen(true)
  }

  return (
    <StyledButton onClick={handleMessageClick}>
      <StyledBadge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        variant="rounded"
        badgeContent={totalUnreadMessages}
        color="secondary"
      >
        <MessageIcon />
      </StyledBadge>
    </StyledButton>
  )
}

export default MessagesButton

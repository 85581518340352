import { useCallback, useContext, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Search } from '@mui/icons-material'
import JAvatar from '@/components/atoms/JAvatar'
import JDropdownUser from '@/components/atoms/JDropdownUser'
import { CreateCaseContext } from '@/components/Case/CreateCaseContext/CreateCaseContext'
import { CreateCaseWaterfallList, CreateCaseWaterfallMember } from '../../types'

interface ISelectSavedWaterfallProps {
  waterfallLists: CreateCaseWaterfallList[]
}

const SelectSavedWaterfall = ({
  waterfallLists,
}: ISelectSavedWaterfallProps) => {
  const {
    methods: { setValue },
  } = useContext(CreateCaseContext)
  const [selectedGroup, setSelectedGroup] =
    useState<CreateCaseWaterfallList | null>(null)
  const handleSelection = useCallback(
    (newValue: CreateCaseWaterfallMember[]) => {
      const assigneesId = newValue.map(user => user.attorneyId)
      setValue('assigneesId', assigneesId)
    },
    [setValue]
  )

  return (
    <Box>
      <Autocomplete
        options={waterfallLists.sort((a, b) => {
          const jurisdictionA = a.waterfallJurisdiction || 'Personal'
          const jurisdictionB = b.waterfallJurisdiction || 'Personal'
          return jurisdictionA.localeCompare(jurisdictionB)
        })}
        getOptionLabel={option => option.waterfallName}
        value={selectedGroup}
        groupBy={option => option.waterfallJurisdiction || 'Personal'}
        onChange={(_, newValue) => {
          setSelectedGroup(newValue)
          handleSelection(newValue?.waterfallMembers || [])
        }}
        renderInput={params => (
          <TextField
            {...params}
            label="Waterfall Name"
            placeholder="Select a saved waterfall"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="disabled" />
                </InputAdornment>
              ),
            }}
            sx={{ marginBottom: selectedGroup ? 2.5 : 0 }}
          />
        )}
        renderOption={(props, option) => (
          <ListItem
            key={option.waterfallId}
            sx={{
              display: 'flex',
              width: '100%',
              cursor: 'pointer',
            }}
            {...props}
          >
            <Box
              sx={{
                display: 'flex-column',
                alignItems: 'center',
                padding: 1.5,
                overflow: 'hidden',
                mr: 1,
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {option.waterfallName}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">
                  {option.waterfallMembers
                    .map(member => member.attorneyName)
                    .join(', ')}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                marginLeft: 'auto',
                justifyContent: 'flex-end',
              }}
            >
              {option.waterfallMembers.map((member, idx) => (
                <JAvatar
                  key={member.attorneyId}
                  src={member.avatar}
                  sx={{
                    avatar: {
                      width: '36px',
                      height: '36px',
                      marginLeft: idx === 0 ? 0 : -1.5,
                    },
                    badge: {
                      marginLeft: idx === 0 ? 0 : -1.5,
                      zIndex: option.waterfallMembers.length - idx, // ensure avatars in the front are on top
                    },
                  }}
                  alt={member.attorneyName}
                  isVetted={member?.isVetted}
                  isQualified={member?.isQualified || false}
                />
              ))}
            </Box>
          </ListItem>
        )}
      />
      {selectedGroup && (
        <>
          {selectedGroup.waterfallMembers.map((user, index) => (
            <Box
              key={user.attorneyId}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 2,
              }}
            >
              <Typography variant="body1" sx={{ mr: 2 }}>
                {index + 1}
              </Typography>
              <JDropdownUser
                user={{
                  id: user.attorneyId,
                  name: user.attorneyName,
                  organization: user.attorneyOrganization,
                  isVetted: user.isVetted,
                  isQualified: user.isQualified,
                  picture: user.avatar,
                }}
                sx={{
                  avatar: { width: 36, height: 36 },
                  list: { px: 0, py: 0 },
                  textBox: {
                    width: {
                      xs: '75%',
                      md: '100%',
                    },
                  },
                }}
                badgeSize="xsmall"
              />
            </Box>
          ))}
        </>
      )}
    </Box>
  )
}

export default SelectSavedWaterfall

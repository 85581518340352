import Chip, { ChipProps } from '@mui/material/Chip'

export interface IJChipProps extends ChipProps {
  selected?: boolean
}

const JChip = ({ selected, sx, ...rest }: IJChipProps) => (
  <Chip
    {...rest}
    sx={[
      theme => ({
        ...(selected && {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '&.MuiChip-clickable:hover': {
            backgroundColor: theme.palette.primary.main,
          },
        }),
        margin: theme.spacing(0.5),
      }),
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  />
)

export default JChip

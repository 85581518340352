export type TLawFirmPublicInfo = {
  name: string
  description: string
  address: string
  city: string
  zipcode: string
  state: string
  companySize: string
  caseManagementSoftware: string
  website?: string
}

export type TCreateOrganizationProfile = {
  position?: string
  bio?: string
}

export const lawFirmDefaultValues: TLawFirmPublicInfo = {
  name: '',
  description: '',
  address: '',
  city: '',
  zipcode: '',
  state: '',
  companySize: '',
  caseManagementSoftware: '',
  website: '',
}

export const profileDefaultValues: TCreateOrganizationProfile = {
  position: '',
  bio: '',
}

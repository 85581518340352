import { useContext } from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import { CaseChatContext } from '@/providers/CaseChatProvider'
import { MessageOutlined } from '@mui/icons-material'
import { useCaseMessageTrigger } from './useCaseMessageTrigger'

const CaseMessageButton = ({ ...buttonProps }: ButtonProps) => {
  const { isCaseOwner, isCasePage } = useContext(CaseChatContext)

  const { handleTriggerClick, disabled, triggerContent, hasMessages, loading } =
    useCaseMessageTrigger()

  if (isCasePage && isCaseOwner && !hasMessages && !loading) {
    return null
  }

  return (
    <Button
      size="large"
      variant="outlined"
      fullWidth
      onClick={() => handleTriggerClick()}
      disabled={disabled}
      startIcon={<MessageOutlined />}
      {...buttonProps}
    >
      {triggerContent()}
    </Button>
  )
}

export default CaseMessageButton

import useTheme from '@mui/material/styles/useTheme'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import Typography from '@mui/material/Typography'
import { useSizes } from '@/hooks/useSizes'
import useGetMyRoleAndOrg from '@/hooks/useGetMyRoleAndOrg'

export const NegotiationTableHeader = ({
  owningUserLabel,
}: {
  owningUserLabel: string
}) => {
  const theme = useTheme()
  const { isCaseManager, isAdmin } = useGetMyRoleAndOrg()

  const { isLessLg } = useSizes()

  if (isLessLg) {
    return null
  }

  if (isCaseManager || isAdmin) {
    return (
      <TableHead
        component={Box}
        sx={{
          display: 'flex',
          borderBottomWidth: 0,
          '& > .MuiTableCell-root': {
            borderBottomWidth: 0,
            pb: 0,
          },
        }}
      >
        <TableCell component={Box} sx={{ width: 0.315 }}>
          <Typography
            variant="body2"
            color={theme.palette.text.disabled}
            pl={1}
          >
            Case Name
          </Typography>
        </TableCell>
        <TableCell component={Box} sx={{ px: 0, width: 0.045 }}>
          <Typography
            variant="body2"
            color={theme.palette.text.disabled}
            textAlign="right"
            mr={2}
          >
            Fees
          </Typography>
        </TableCell>
        <TableCell component={Box} sx={{ px: 0, width: 0.185 }}>
          <Typography variant="body2" color={theme.palette.text.disabled}>
            {owningUserLabel}
          </Typography>
        </TableCell>
        <TableCell component={Box} sx={{ px: 0, width: 0.185 }}>
          <Typography variant="body2" color={theme.palette.text.disabled}>
            Attorney
          </Typography>
        </TableCell>
        <TableCell component={Box} sx={{ px: 0, width: 0.15 }}>
          <Typography variant="body2" color={theme.palette.text.disabled}>
            Case Type
          </Typography>
        </TableCell>
        <TableCell component={Box} sx={{ px: 0, width: 0.08 }}>
          <Typography variant="body2" color={theme.palette.text.disabled}>
            Jurisdiction
          </Typography>
        </TableCell>
      </TableHead>
    )
  }

  return (
    <TableHead
      component={Box}
      sx={{
        display: 'flex',
        borderBottomWidth: 0,
        '& > .MuiTableCell-root': {
          borderBottomWidth: 0,
          pb: 0,
        },
      }}
    >
      <TableCell component={Box} sx={{ width: 0.476 }}>
        <Typography variant="body2" color={theme.palette.text.disabled} pl={1}>
          Case Name
        </Typography>
      </TableCell>
      <TableCell component={Box} sx={{ px: 0, width: 0.045 }}>
        <Typography
          variant="body2"
          color={theme.palette.text.disabled}
          textAlign="right"
          mr={2}
        >
          Fees
        </Typography>
      </TableCell>
      <TableCell component={Box} sx={{ px: 0, width: 0.28 }}>
        <Typography variant="body2" color={theme.palette.text.disabled}>
          Counterpart
        </Typography>
      </TableCell>
      <TableCell component={Box} sx={{ px: 0, width: 0.115 }}>
        <Typography variant="body2" color={theme.palette.text.disabled}>
          Case Type
        </Typography>
      </TableCell>
      <TableCell component={Box} sx={{ px: 0, width: 0.07 }}>
        <Typography variant="body2" color={theme.palette.text.disabled}>
          Jurisdiction
        </Typography>
      </TableCell>
    </TableHead>
  )
}

import React from 'react'
import { Box, Skeleton } from '@mui/material'

const CaseHeaderSkeleton: React.FC = () => (
  <Box>
    <Skeleton
      width={135}
      height={12}
      sx={{ mt: 1, transform: 'scale(1, 1)' }}
    />
    <Skeleton
      width={400}
      height={24}
      sx={{ transform: 'scale(1, 1)', mt: 1 }}
    />
    <Skeleton
      width={150}
      height={12}
      sx={{ mt: 1, transform: 'scale(1, 1)' }}
    />

    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        mt: 2.5,
      }}
    >
      <Skeleton
        variant="rectangular"
        width={80}
        height={24}
        sx={{ transform: 'scale(1, 1)', borderRadius: '9999px' }}
      />
      <Skeleton
        variant="rectangular"
        width={80}
        height={24}
        sx={{ transform: 'scale(1, 1)', borderRadius: '9999px' }}
      />
    </Box>
  </Box>
)

export default CaseHeaderSkeleton

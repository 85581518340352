import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

import JUser from '@/components/atoms/JUser'
import { ATTORNEYS_ROLES_BY_ID, IMAGE_SIZES } from '@/constants'
import { getImagePath } from '@/utils'
import { isQualifiedMembership } from '@/utils/membership'

export interface MobileTableProps {
  data: any[]
}

const MobileTable = ({ data }: MobileTableProps) => (
  <Box
    sx={{ display: 'flex', flexDirection: 'column', height: '100%', mb: 16 }}
  >
    {data.map(d => {
      const fullName = `${d.user_id.first_name} ${d.user_id.last_name}`
      const position = d.user_id.current_organization[0].position_name
      const role =
        ATTORNEYS_ROLES_BY_ID[
          d.user_id.role.id as keyof typeof ATTORNEYS_ROLES_BY_ID
        ] || ''
      const secondaryText = position ? `${position} - ${role}` : role
      return (
        <Box key={d.id} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              padding: theme => theme.spacing(2, 0, 2, 0),
              gap: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <JUser
              mainText={fullName}
              secondaryText={secondaryText}
              avatar={getImagePath({
                id: d.user_id.avatar?.id,
                size: IMAGE_SIZES.Small,
              })}
              isVetted={d.user_id.attorneys?.[0]?.is_vetted}
              isQualified={isQualifiedMembership(
                d.user_id.attorneys?.[0]?.membership_type?.id
              )}
            />
          </Box>
          <Divider />
        </Box>
      )
    })}
  </Box>
)

export default MobileTable

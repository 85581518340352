import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { ReferralType } from '../types'

interface ICardFeeProps {
  fee: number
  referralType: ReferralType
  bothPartiesPaid?: boolean
}

const getFeeText = (referralType: ReferralType, bothPartiesPaid?: boolean) => {
  switch (referralType) {
    case 'invited':
      return ''
    case 'proposed':
    case 'counteroffer':
      return 'Referral/Co-Counsel Fee Requested'
    case 'accepted':
    case 'agreement':
    case 'matched':
    case 'signed':
      return 'Referring Attorney Fees Agreed'
    case 'acceptedByCounterpart':
    case 'acceptedByCurrentUser':
      return bothPartiesPaid
        ? 'Referring Attorney Fees Agreed'
        : 'Referral/Co-Counsel Fee Requested'
    case 'dataroom':
    default:
      return ''
  }
}

const CardFee = ({ fee, referralType, bothPartiesPaid }: ICardFeeProps) => {
  const feeCaption = getFeeText(referralType, bothPartiesPaid)

  return (
    <Grid
      item
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        mt: {
          xs: 2,
          md: 0,
        },
      }}
    >
      {feeCaption && (
        <>
          <Typography variant="subtitle1">{fee}%</Typography>
          <Typography
            variant="caption"
            sx={{
              ml: 1,
              color: theme => theme.palette.text.secondary,
              lineHeight: 1.75,
            }}
          >
            {feeCaption}
          </Typography>
        </>
      )}
    </Grid>
  )
}

export default CardFee

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import React, { ReactNode } from 'react'

import CreateAccountBG from '@/assets/createAccountBG.svg'
import { useSizes } from '@/hooks/useSizes'

type AuthLayoutProps = {
  children: ReactNode
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const { isLessMd } = useSizes()

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        height: '100%',
        flexWrap: 'initial',
        justifyContent: 'center',
        px: {
          xs: 3,
          md: 0,
        },
        marginBottom: 100,
      }}
      className="Auth-Layout"
    >
      {!isLessMd && (
        <Grid item md={6}>
          <Box
            sx={theme => ({
              backgroundColor: theme.palette.primary.dark,
              width: '100%',
              height: '100%',
              maxWidth: { md: '46vw' },
              maxHeight: { md: '100vh' },
              position: 'sticky',
              display: 'flex',
            })}
          >
            <img alt="background" src={CreateAccountBG} className="w-full" />
          </Box>
        </Grid>
      )}
      <Grid
        item
        md={6}
        xs={12}
        sx={{
          padding: isLessMd ? 0 : '0 40px',
          overflow: 'auto',
        }}
      >
        {children}
      </Grid>
    </Grid>
  )
}

export default AuthLayout

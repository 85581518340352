import { useCallback, useEffect, useState } from 'react'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'
import {
  useGetUserFileAccessRequestQuery,
  useUpdateUserFileAccessRequestItemMutation,
} from '@/gql/appApi'
import { ACCESS_REQUEST_STATUS, USER_ROLES } from '@/constants'

type ModalProps = {
  open: boolean
  type?: string
  title?: string
  description?: React.ReactNode
  buttonText?: string
  fileAccessId?: string
  targetUserName?: string
}

type ResponseModalProps = {
  open: boolean
  title?: string
  bodyContent?: React.ReactNode
}

const modalDefault: ModalProps = {
  open: false,
}

const responseModalDefault: ResponseModalProps = {
  open: false,
}

export type TUpdateRequests = {
  fileAccessId?: string
  targetUserName?: string
  hasAccess?: boolean
}

export type TAccessRequests = {
  id: string
  fileAccessId: string
  firstName?: string
  lastName?: string
  avatarId?: string | null
  isVetted: boolean
  orgName?: string
}

type TUseAccessRequestsProps = {
  caseId: string
}

interface ErrorType {
  message: string
}

const baseError = 'An unexpected error occurred. Please try again later.'

const useAccessRequests = ({ caseId }: TUseAccessRequestsProps) => {
  const [acceptedRequests, setAcceptedRequests] = useState<TAccessRequests[]>(
    []
  )
  const [pendingRequests, setPendingRequests] = useState<TAccessRequests[]>([])
  const [error, setError] = useState<ErrorType | null>(null)

  const clearRequests = useCallback(() => {
    setAcceptedRequests([])
    setPendingRequests([])
  }, [])

  const {
    data: fileAccessRequests,
    loading: fileAccessRequestsLoading,
    refetch: refetchFileAccessRequests,
  } = useGetUserFileAccessRequestQuery({
    variables: {
      filter: {
        requested_case_id: {
          id: {
            _eq: caseId,
          },
        },
        attorney_id: {
          user_id: {
            role: {
              id: {
                _neq: USER_ROLES.CaseManagerUser,
              },
            },
          },
        },
      },
      sort: 'created_ts',
    },
    fetchPolicy: 'network-only',
  })

  const [updateFileAccessRequest, { loading: updateLoading }] =
    useUpdateUserFileAccessRequestItemMutation({
      fetchPolicy: 'network-only',
    })

  const getRequestsAndUsers = useCallback(() => {
    const requests = fileAccessRequests?.user_file_access_request ?? []

    if (requests?.length > 0) {
      const accepted = requests?.filter(
        item =>
          item?.status?.id === ACCESS_REQUEST_STATUS.Granted ||
          item?.status?.id === ACCESS_REQUEST_STATUS.Given
      )
      const pending = requests.filter(
        item => item?.status?.id === ACCESS_REQUEST_STATUS.Pending
      )

      if (accepted.length > 0) {
        setAcceptedRequests(
          accepted.map(item => ({
            id: item?.id || '',
            fileAccessId: item?.id || '',
            firstName: item?.attorney_id?.profiles?.[0]?.first_name || '',
            lastName: item?.attorney_id?.profiles?.[0]?.last_name || '',
            avatarId:
              item?.attorney_id?.profiles?.[0]?.attorney_id?.user_id?.avatar
                ?.id || null,
            isVetted:
              item?.attorney_id?.profiles?.[0]?.attorney_id?.is_vetted || false,
            orgName:
              item?.attorney_id?.profiles?.[0]?.attorney_id?.user_id
                ?.current_organization?.[0]?.organization_id?.profiles?.[0]
                ?.name || '',
          }))
        )
      }

      if (pending.length > 0) {
        setPendingRequests(
          pending.map(item => ({
            id: item?.id || '',
            fileAccessId: item?.id || '',
            firstName: item?.attorney_id?.profiles?.[0]?.first_name || '',
            lastName: item?.attorney_id?.profiles?.[0]?.last_name || '',
            avatarId:
              item?.attorney_id?.profiles?.[0]?.attorney_id?.user_id?.avatar
                ?.id || null,
            isVetted:
              item?.attorney_id?.profiles?.[0]?.attorney_id?.is_vetted || false,
            orgName:
              item?.attorney_id?.profiles?.[0]?.attorney_id?.user_id
                ?.current_organization?.[0]?.organization_id?.profiles?.[0]
                ?.name || '',
          }))
        )
      }
    }
  }, [fileAccessRequests])

  useEffect(() => {
    if (Number(fileAccessRequests?.user_file_access_request?.length) > 0) {
      getRequestsAndUsers()
    }
  }, [
    fileAccessRequests?.user_file_access_request?.length,
    getRequestsAndUsers,
  ])

  const [modal, setModal] = useState<ModalProps>(modalDefault)
  const [responseModal, setResponseModal] =
    useState<ResponseModalProps>(responseModalDefault)

  const onUpdateAccess = useCallback(
    async (fileAccessId: string) => {
      const { type } = modal

      const getUpdateId = () => {
        switch (type) {
          case 'granted': {
            return ACCESS_REQUEST_STATUS.Granted
          }
          case 'denied': {
            return ACCESS_REQUEST_STATUS.Rejected
          }
          case 'removed': {
            return ACCESS_REQUEST_STATUS.Revoked
          }
          default:
            return ''
        }
      }

      try {
        const result = await updateFileAccessRequest({
          variables: {
            updateUserFileAccessRequestItemId: fileAccessId,
            data: {
              status: getUpdateId(),
            },
          },
        })
        if (result?.data?.update_user_file_access_request_item?.id) {
          clearRequests()
          setTimeout(async () => {
            await refetchFileAccessRequests()
          }, 2000)

          return true
        }

        if (result?.errors) {
          const errorMessage = result.errors[0]?.message || baseError
          setError({ message: errorMessage })
        }
        return false
      } catch (err) {
        setError({ message: baseError })
        return false
      }
    },
    [clearRequests, modal, refetchFileAccessRequests, updateFileAccessRequest]
  )

  const onGiveAccess = ({ fileAccessId, targetUserName }: TUpdateRequests) => {
    setModal({
      open: true,
      type: 'granted',
      fileAccessId,
      targetUserName,
      title: `Allow Data Room file access`,
      description: (
        <Typography component="span" variant="subtitle1">
          {`Are you sure you want to grant access to the data room to ${targetUserName}?`}
          <Typography mt={2}>
            If granted access, user will be able to view and download the files
            from the Data Room. Please note that you can remove their access
            anytime.
          </Typography>
        </Typography>
      ),
      buttonText: `Yes, grant access`,
    })
  }

  const onDenyAccess = ({
    fileAccessId,
    targetUserName,
    hasAccess,
  }: TUpdateRequests) => {
    setModal({
      open: true,
      type: hasAccess ? 'removed' : 'denied',
      fileAccessId,
      targetUserName,
      title: `${hasAccess ? 'Remove' : 'Deny'} Data Room files access`,
      description: (
        <Typography variant="subtitle1">
          {`Are you sure you want to ${
            hasAccess ? 'remove' : 'deny'
          } access to the data room to ${targetUserName}?`}
        </Typography>
      ),
      buttonText: `Yes, ${hasAccess ? 'remove' : 'deny'} access`,
    })
  }

  const onCloseModal = () => {
    setModal({ open: false })
  }

  const onSubmitModal = useCallback(async () => {
    const { type, fileAccessId, targetUserName } = modal

    const getDescription = () => {
      switch (type) {
        case 'granted': {
          return 'The user will now have the ability to view or download Data Room files. Please remember that you can revoke this permission at any time.'
        }
        case 'denied': {
          return 'The files related to the case are protected and can only be viewed by those users to whom you have granted access.'
        }
        case 'removed': {
          return `${targetUserName} will no longer have access to the files in the Data Room`
        }
        default:
          return ''
      }
    }

    const result = await onUpdateAccess(fileAccessId || '')

    if (!result) {
      onCloseModal()
      return
    }

    setModal({ open: false })
    setResponseModal({
      open: true,
      title: `Data Room access ${type}`,
      bodyContent: (
        <>
          <AlertTitle>
            {`You have successfully ${type} access to Data Room`}
          </AlertTitle>
          {getDescription()}
        </>
      ),
    })
  }, [modal, onUpdateAccess])

  const onCloseResponseModal = () => {
    setResponseModal({ open: false })
  }

  return {
    modal,
    setModal,
    responseModal,
    setResponseModal,
    onGiveAccess,
    onDenyAccess,
    onCloseModal,
    onSubmitModal,
    onCloseResponseModal,
    acceptedRequests,
    pendingRequests,
    loading: fileAccessRequestsLoading || updateLoading,
    error,
    setError,
  }
}

export default useAccessRequests

import { useCatalogLanguageQuery } from '@/gql/appApi'
import { TFormPersonalInformation, TLanguages } from '@/pages/Profile/types'
import { useProfilePicture } from '@/hooks/profile/useProfilePicture'
import { useEffect, useState } from 'react'
import { ModalTypes } from '@/types'
import { useForm } from 'react-hook-form'
import { useDiscardChangesModal } from '@/components/Modals/DiscardChangesModal/useDiscardChangesModal'
import { PREFERRED_LANGUAGE } from '@/constants'
import useProfile from '../useProfile'

const defaultValues: TFormPersonalInformation = {
  first_name: '',
  last_name: '',
  position: '',
  bio: '',
  languages: [],
}

export const useProfilePersonalInfo = () => {
  const {
    profilePhoto,
    profilePhotoRef,
    onChangeProfilePhoto,
    onRemoveProfilePhoto,
    handleCloseSnack,
    alert,
    showAlert,
    shouldDeleteProfilePhoto,
    uploadedImageData,
    onProfilePictureSave,
    onDiscardChanges,
  } = useProfilePicture()

  const [response, setResponse] = useState<{
    type: ModalTypes
    open: boolean
  }>({
    type: null,
    open: false,
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const {
    attorneyProfile: data,
    profileId,
    organizationUserItemId,
    updateAttorneyProfileItemMutation,
    updateOrganizationUser,
    loading,
  } = useProfile()
  const languages = useCatalogLanguageQuery({
    variables: {
      filter: {
        id: {
          _neq: PREFERRED_LANGUAGE.PL_English,
        },
      },
      sort: 'name',
    },
  })

  const setAPIData = () => ({
    first_name: data?.attorney?.[0]?.profiles?.[0]?.first_name || '',
    last_name: data?.attorney?.[0]?.profiles?.[0]?.last_name || '',
    bio:
      data?.attorney?.[0]?.profiles?.[0]?.bio ||
      data?.attorney?.[0]?.user_id?.current_organization?.[0]?.position_bio ||
      '',
    position:
      data?.attorney?.[0]?.user_id?.current_organization?.[0]?.position_name ||
      '',
    languages: data?.attorney?.[0]?.profiles?.[0]?.languages
      ? (data.attorney?.[0]?.profiles?.[0]?.languages as TLanguages[]).map(
          (m: TLanguages) => ({
            id: m?.language_id?.id,
            code: m?.language_id?.code,
            name: m?.language_id?.name,
          })
        )
      : [],
  })

  const resetPageData = () => {
    if (data) {
      reset(setAPIData())
    }
    onDiscardChanges()
  }
  useEffect(() => {
    resetPageData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const {
    getConfirmationModalProps,
    setOpenModal,
    modalOpen,
    type: modalType,
    ...rest
  } = useDiscardChangesModal({
    type: 'confirm',
    onSubmit: () => {
      resetPageData()
      setOpenModal(null)
      setResponse({ type: 'DiscardChangesAccepted', open: true })
    },
  })

  const validateMaxCharacter = (
    num: number,
    fieldData: string,
    fieldName: string
  ) => {
    if (fieldData.trim().length === 0) {
      return `${fieldName} is required`
    }

    if (fieldData.length > num) {
      return `${fieldName} must not exceed ${num} characters`
    }
    return true
  }

  const onSubmit = async ({
    position,
    ...profileData
  }: TFormPersonalInformation) => {
    setIsSubmitting(true)

    try {
      const [update] = await Promise.all([
        updateAttorneyProfileItemMutation({
          variables: {
            data: {
              ...profileData,
              languages:
                (profileData.languages as TLanguages[]).length === 0
                  ? []
                  : (profileData.languages as TLanguages[]).map(m => ({
                      language_id: m.id,
                    })),
            },
            updateAttorneyProfileItemId: profileId ?? '',
          },
        }),

        updateOrganizationUser({
          organizationUserItemId: organizationUserItemId || '',
          positionName: position,
          positionBio: profileData.bio,
        }),

        await onProfilePictureSave(),
      ])

      if (update.data) {
        showAlert({ severity: 'success', message: 'Data saved successfully' })
      }
    } catch (e) {
      showAlert({
        severity: 'error',
        message: 'Data was not saved successfully',
      })
    }

    setIsSubmitting(false)
  }

  return {
    disableSubmit:
      uploadedImageData === null &&
      (!isDirty || !isValid) &&
      !shouldDeleteProfilePhoto,
    alert,
    languages,
    control,
    rest,
    isDirty,
    isSubmitting,
    isValid,
    profilePhoto,
    profilePhotoRef,
    shouldDeleteProfilePhoto,
    uploadedImageData,
    response,
    modalOpen,
    validateMaxCharacter,
    handleCloseSnack,
    onProfilePictureSave,
    onSubmit,
    handleSubmit,
    onChangeProfilePhoto,
    onRemoveProfilePhoto,
    setOpenModal,
    setResponse,
    getConfirmationModalProps,
    loading,
  }
}

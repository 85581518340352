import React, { useState } from 'react'
import { Box, TextField, Button, Typography, Alert } from '@mui/material'
import { useCaseContext } from '../../hooks/useCaseContext'

const AccessPending: React.FC = () => (
  <Alert severity="success" sx={{ width: '100%' }} icon={false}>
    <Typography variant="subtitle1" mb={0.5} color="green.600">
      You have successfully submitted a request to access Data Room.
    </Typography>
    <Typography variant="body2">
      We&apos;ll notify you once the case owner has reviewed and made a decision
      regarding your request. Please stay tuned for updates, and we appreciate
      your patience in this process.
    </Typography>
  </Alert>
)

const AccessDenied: React.FC = () => (
  <Alert severity="warning" sx={{ width: '100%', my: 2 }} icon={false}>
    <Typography variant="body2" color="orange.400">
      <Typography variant="subtitle2" component="span">
        Your previous request to access Data Room has been denied.{' '}
      </Typography>
      You can request access again, but we recommend sending a message to your
      counterpart before doing so.
    </Typography>
  </Alert>
)

const AccessRequestForm: React.FC = () => {
  const [message, setMessage] = useState('')

  const handleSubmit = () => {
    // TODO: Implement access request submission, move to context/hook
    console.log('Access requested with message:', message)
  }

  return (
    <Box sx={{ mt: 2, mb: 2 }} display="flex" flexDirection="column" gap={2}>
      <TextField
        fullWidth
        multiline
        rows={4}
        variant="outlined"
        placeholder="Message (optional)"
        value={message}
        onChange={e => setMessage(e.target.value)}
        sx={{
          '& .MuiInputBase-input::placeholder': {
            color: 'text.disabled',
            typography: 'body1',
          },
          backgroundColor: 'background.paper',
        }}
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={handleSubmit}
        sx={{ alignSelf: 'flex-start' }}
      >
        Request Access
      </Button>
    </Box>
  )
}

const DataroomAccessRequest: React.FC = () => {
  const { caseData } = useCaseContext()
  const access = caseData?.dataroom?.access

  if (!access) return null

  switch (access) {
    case 'pending':
      return <AccessPending />
    case 'request':
      return <AccessRequestForm />
    case 'denied':
      return (
        <>
          <AccessDenied />
          <AccessRequestForm />
        </>
      )
    default:
      return null
  }
}

export default DataroomAccessRequest

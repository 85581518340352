import React from 'react'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
  Paper,
  Typography,
} from '@mui/material'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useCaseContext } from '../../hooks/useCaseContext'

const DataroomFileList: React.FC = () => {
  const { caseData } = useCaseContext()
  const theme = useTheme()

  if (!caseData?.dataroom) return null

  const { files, access } = caseData.dataroom

  if (!access) return null
  if (!files || files.length === 0) return null

  const hasFileAccess = ['edit', 'view'].includes(access)
  const hasFileEditAccess = access === 'edit'

  if (!hasFileAccess) return null

  const iconStyle = {
    fontSize: theme.typography.body2.fontSize,
    height: theme.typography.body2.lineHeight,
    width: 'auto',
  }

  const handleDownloadClick = (fileId: string) => {
    // TODO: move to case context/hook
    console.log('download', fileId)
  }

  const handleDeleteClick = (fileId: string) => {
    // TODO: implement delete functionality
    console.log('delete', fileId)
  }

  return (
    <Paper
      elevation={0}
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
      }}
    >
      <List disablePadding>
        {files.map((file, index) => (
          <ListItem
            key={file.id}
            divider={index !== files.length - 1}
            sx={{
              borderColor: theme.palette.divider,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              pr: 2, // Add right padding to align with Paper
            }}
          >
            <Box display="flex" alignItems="center" minWidth={0} flexGrow={1}>
              <ListItemIcon sx={{ minWidth: 'auto', mr: 1, flexShrink: 0 }}>
                <InsertDriveFileOutlinedIcon
                  sx={{ ...iconStyle, color: theme.palette.common.black }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    color="text.primary"
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {file.name}
                  </Typography>
                }
                sx={{ mx: 0, minWidth: 0 }}
              />
            </Box>
            <Box display="flex" gap={1} flexShrink={0}>
              {hasFileAccess && (
                <IconButton
                  edge="end"
                  aria-label="download"
                  size="small"
                  onClick={() => handleDownloadClick(file.id)}
                >
                  <FileDownloadOutlinedIcon color="primary" sx={iconStyle} />
                </IconButton>
              )}
              {hasFileEditAccess && (
                <IconButton
                  edge="end"
                  aria-label="delete"
                  size="small"
                  onClick={() => handleDeleteClick(file.id)}
                >
                  <DeleteOutlineIcon color="error" sx={iconStyle} />
                </IconButton>
              )}
            </Box>
          </ListItem>
        ))}
      </List>
    </Paper>
  )
}

export default DataroomFileList

import { useState, useEffect } from 'react'
import { Card } from '@mui/material'

import noop from 'lodash/noop'
import { StyledDataGrid } from './styles'
import { IJDataTableProps } from './types'

const GRID_PAGE_SIZES = [25, 50, 100]
export const DEFAULT_SETTINGS = {
  pageSize: GRID_PAGE_SIZES[0],
  page: 0,
}

const JDataTable = ({
  columns,
  rows,
  tableInitialSettings,
  onSettingsChange = noop,
  ...rest
}: IJDataTableProps) => {
  const [paginationModel, setPaginationModel] = useState(
    tableInitialSettings || DEFAULT_SETTINGS
  )

  useEffect(() => {
    onSettingsChange(paginationModel)
  }, [paginationModel, onSettingsChange])

  return (
    <Card sx={theme => ({ width: '100%', paddingX: theme.spacing(2.5) })}>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={GRID_PAGE_SIZES}
        initialState={{
          pagination: {
            paginationModel: tableInitialSettings,
          },
        }}
        {...rest}
      />
    </Card>
  )
}

export const JGrid = StyledDataGrid

export default JDataTable

import { PROCESS_STAGES } from '@/constants'
import { theme } from '@/styles/theme'
import { ChipProps } from '@mui/material/Chip'

export interface IJStatusPillProps extends ChipProps {
  id: string
  name: string
}

interface StatusColor {
  backgroundColor: string
  textColor: string
}

export const statusColors: Record<string, StatusColor> = {
  [PROCESS_STAGES.APPEAL]: {
    backgroundColor: theme.palette.error.light,
    textColor: theme.palette.error.dark,
  },
  [PROCESS_STAGES.DISCOVERY]: {
    backgroundColor: theme.palette.success.lightest || '#CFFED7',
    textColor: theme.palette.success.dark,
  },
  [PROCESS_STAGES.LEAD_INTAKE_ONLY]: {
    backgroundColor: theme.palette.info.lightest || '#D0FCFF',
    textColor: theme.palette.primary.main,
  },
  [PROCESS_STAGES.PENDING_TRIAL]: {
    backgroundColor: '#F5EBFE',
    textColor: '#300854',
  },
  [PROCESS_STAGES.PRE_LITIGATION]: {
    backgroundColor: theme.palette.warning.light,
    textColor: '#5A3601',
  },
  [PROCESS_STAGES.SIGNED_CLIENT]: {
    backgroundColor: '#FFFCBC',
    textColor: '#3F340C',
  },
}

import { useSizes } from '@/hooks/useSizes'
import { JDeleteButtonProps } from './types'
import { StyledDeleteIcon } from './styles'
import { ErrorButton } from '../ErrorButton'

const JDeleteButton = ({
  title = 'Remove Case',
  showText = true,
  ...rest
}: JDeleteButtonProps) => {
  const { isGreaterMd } = useSizes()
  const buttonText = isGreaterMd || showText ? title : ''
  return (
    <ErrorButton startIcon={<StyledDeleteIcon />} {...rest}>
      {buttonText}
    </ErrorButton>
  )
}

export default JDeleteButton

import { ProfileType } from '@/types'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Card, { CardProps } from '@mui/material/Card'
import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded'
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceRounded'
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded'
import { generatePath, useNavigate } from 'react-router-dom'

import { useChat } from '@/hooks/useChat'
import { getImagePath } from '@/utils'
import JUser from '@/components/atoms/JUser'
import { RoutesData } from '@/router/RoutesData'
import useInviteToCase from '@/hooks/inviteToCase/useInviteToCase'
import { getPracticesAreas } from '@/utils/profile'
import { Typography } from '@mui/material'
import { isQualifiedMembership } from '@/utils/membership'
import { IMAGE_SIZES } from '@/constants'

interface DirectoryCardProps extends CardProps {
  profile: ProfileType
  showInviteButton?: boolean
  showChatButton?: boolean
}

const DirectoryCard = ({
  profile,
  showInviteButton = true,
  showChatButton = true,
  sx,
  ...rest
}: DirectoryCardProps) => {
  const theme = useTheme()
  const { openChatForUser } = useChat()
  const navigate = useNavigate()

  const { renderModal, openModal, setOpenModal } = useInviteToCase({
    prospectAttorneyId: profile.attorney_id?.id || '',
    profile,
  })

  const handleProfileClick = () => {
    const path = generatePath(RoutesData.RProfile.path, {
      id: profile?.attorney_id?.id,
    })

    navigate(path)
  }

  const handleChatButtonClick = () => {
    openChatForUser(profile.attorney_id?.user_id?.id ?? '')
  }

  const handleInviteButtonClick = () => setOpenModal(true)

  const avatarId = profile.attorney_id?.user_id?.avatar?.id

  const firmName =
    profile?.attorney_id?.user_id?.current_organization?.[0]?.organization_id
      ?.profiles?.[0]?.name

  const practiceAreas = profile.practice_areas

  const jurisdictions = profile.licenses
    ?.map(({ license_state }) => license_state.name)
    .join(', ')
  return (
    <Card
      sx={[{ position: 'relative' }, ...(Array.isArray(sx) ? sx : [sx])]}
      {...rest}
    >
      <CardContent>
        <Box
          sx={{
            mb: theme.spacing(9),
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Box display="flex">
            <JUser
              size="small"
              avatar={
                avatarId &&
                getImagePath({ id: avatarId, size: IMAGE_SIZES.Small })
              }
              jListItemProps={{
                sx: {
                  alignItems: 'flex-start',
                },
              }}
              mainText={
                <Typography
                  variant="subtitle1"
                  component="span"
                  display="block"
                  mb={1}
                >
                  {profile.first_name} {profile.last_name}
                </Typography>
              }
              tertiaryText={
                <>
                  {firmName?.length ? (
                    <Typography
                      component="span"
                      fontSize={theme.spacing(1.75)}
                      display="flex"
                      alignItems="flex-start"
                    >
                      <BusinessRoundedIcon
                        fontSize="inherit"
                        sx={{
                          mr: 1,
                          mt: '5px',
                          color: theme.palette.text.disabled,
                        }}
                      />
                      {firmName}
                    </Typography>
                  ) : null}
                  {practiceAreas?.length ? (
                    <Typography
                      component="span"
                      fontSize={theme.spacing(1.75)}
                      display="flex"
                      alignItems="flex-start"
                    >
                      <AccountBalanceIcon
                        fontSize="inherit"
                        sx={{
                          mr: 1,
                          mt: '5px',
                          color: theme.palette.text.disabled,
                        }}
                      />
                      {getPracticesAreas(practiceAreas)}
                    </Typography>
                  ) : null}
                  {jurisdictions ? (
                    <Typography
                      component="span"
                      fontSize={theme.spacing(1.75)}
                      display="flex"
                      alignItems="flex-start"
                    >
                      <PlaceRoundedIcon
                        fontSize="inherit"
                        sx={{
                          mr: 1,
                          mt: '5px',
                          color: theme.palette.text.disabled,
                        }}
                      />
                      {jurisdictions}
                    </Typography>
                  ) : null}
                </>
              }
              onClick={handleProfileClick}
              isVetted={profile?.attorney_id?.is_vetted}
              isQualified={isQualifiedMembership(
                profile?.attorney_id?.membership_type?.id
              )}
            />
          </Box>
        </Box>
      </CardContent>
      <CardActions
        sx={{
          justifyContent: 'flex-end',
          position: 'absolute',
          bottom: theme.spacing(2),
          right: theme.spacing(1),
        }}
      >
        {showChatButton && (
          <Button variant="outlined" onClick={handleChatButtonClick}>
            Send Message
          </Button>
        )}
        {showInviteButton && (
          <Button variant="contained" onClick={handleInviteButtonClick}>
            Invite to Case
          </Button>
        )}
      </CardActions>
      {openModal && renderModal()}
    </Card>
  )
}

export default DirectoryCard

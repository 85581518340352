import { yupResolver } from '@hookform/resolvers/yup'
import { ArrowForward } from '@mui/icons-material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { useCallback, useEffect, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { AxiosError } from 'axios'

import { Checkbox, CheckboxOnChangeHandler } from '@/components'
import PasswordField from '@/components/PasswordField/PasswordField'
import { useSizes } from '@/hooks/useSizes'
import AuthLayout from '@/components/shared/AuthLayout'
import LogoBar from '@/pages/CreateAccount/shared/LogoBar'
import { TTokenValidationResponse } from '@/hooks/useTokenValidation'
import { TERMS_PRIVACY_LINKS } from '@/constants'
import JSnackBar from '@/components/atoms/JSnackBar'
import { useSnackBar } from '@/hooks/useSnackBar'
import { styled } from '@mui/material/styles'
import CreateAccountField from '@/components/CreateAccountField/CreateAccountField'
import { ParameterName, bootParamsService } from '@/services/BootParams'
import { useSearchParams } from 'react-router-dom'
import { createAccountSchema } from './config'
import { CreationFlowState } from '../types'
import useCreateAccount from '../hooks/useCreateAccount'
import { CreateAccountData } from './types'
import ExistingOrganizationModal from './ExistingOrganizationModal/ExistingOrganizationModal'

type CreateAccountFormProps = {
  handleCreateAccountForm: (newValues: CreateAccountData) => void
  handleFlowNavigation: (flow: CreationFlowState) => void
  createAccountValues: CreateAccountData
  accountType: CreationFlowState
  tokenData?: TTokenValidationResponse
  loading?: boolean
}

const StyledAnchor = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  ...theme.typography.body2,

  '&:hover': {
    color: theme.palette.secondary.dark,
  },
}))

const CreateAccountForm = ({
  handleFlowNavigation,
  handleCreateAccountForm,
  createAccountValues,
  accountType,
  tokenData,
  loading,
}: CreateAccountFormProps) => {
  const [searchParams] = useSearchParams()
  const paramEmail = searchParams.get('email')
  const { isLessMd } = useSizes()
  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useForm<CreateAccountData>({
    defaultValues: createAccountValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver<CreateAccountData>(createAccountSchema),
  })
  const {
    checkPhoneNumber,
    checkForInvites,
    checkForInvitesError,
    showExistingOrganizationModal,
  } = useCreateAccount()
  const { alert, showAlert, handleClose } = useSnackBar()

  useEffect(() => {
    if (tokenData?.email) {
      setValue('email', tokenData.email)
      return
    }

    // Prevent setting the email value again if it was modified by the user, submitted and
    // then clicked 'Back' to return to this initial step.
    if (paramEmail && !createAccountValues.email) {
      const decodedEmail = decodeURIComponent(paramEmail)
      const cleanEmail = decodedEmail.toLowerCase().trim()
      setValue('email', cleanEmail)
    }
  }, [tokenData?.email, setValue, paramEmail, createAccountValues.email])

  const title = `Create your Account ${
    accountType !== 'ORGANIZATION' ? 'for Law firm' : ''
  }`

  const subtitle =
    'Attorney Share streamlines the process of sending, receiving, and tracking referrals for attorneys. It\'s free to join and we never take a cut of referral fees.'


  const formRef = useRef<HTMLFormElement>(null)

  const onSubmit: SubmitHandler<CreateAccountData> = useCallback(
    async (formData: CreateAccountData) => {
      try {
        // NOTE: If there is an INVITE_TOKEN boot param, this user is known to
        // have been invited so there is no need to check again.
        if (!bootParamsService.get(ParameterName.INVITE_TOKEN)) {
          await checkForInvites({
            data: {
              email: formData.email,
              firstName: formData.firstName,
              lastName: formData.lastName,
            },
          })
        }
      } catch (error) {
        const domainExists =
          error instanceof AxiosError &&
          error.response?.data.domainExists === true

        if (!domainExists) {
          // NOTE: If this is reached, an unexpected error occurred.
          const message =
            error instanceof Error
              ? error.message
              : 'An unexpected error occurred'

          showAlert({ message, severity: 'error' })
        }

        // NOTE: If domainExists is true, the error is expected and is
        // presented by ExistingOrganizationModal. Either way, bail out of the
        // submission operation.
        return
      }

      try {
        await checkPhoneNumber(formData.phone)
        handleCreateAccountForm(formData)
        handleFlowNavigation(accountType)
        window.scrollTo(0, 0)
      } catch (error) {
        const message =
          error instanceof Error
            ? error.message
            : 'An unexpected error occurred'

        showAlert({ message, severity: 'error' })
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },
    [
      accountType,
      checkForInvites,
      checkPhoneNumber,
      handleCreateAccountForm,
      handleFlowNavigation,
      showAlert,
    ]
  )

  const handleNextClick = () => {
    trigger().then(isValid => {
      if (isValid) {
        handleSubmit(onSubmit)()
      }
    })
  }

  const checkboxTermsLabel = (
    <Typography
      variant="body2"
      sx={{
        textAlign: 'left',
      }}
    >
      By checking this box, I acknowledge that I have read and consent to
      Attorney Share&apos;s{' '}
      <StyledAnchor
        href={TERMS_PRIVACY_LINKS.terms}
        rel="noreferrer"
        target="_blank"
      >
        Terms of Service
      </StyledAnchor>{' '}
      and{' '}
      <StyledAnchor
        href={TERMS_PRIVACY_LINKS.privacy}
        rel="noreferrer"
        target="_blank"
      >
        Privacy Policy
      </StyledAnchor>{' '}
      and I consent to receive SMS and email communications related to my
      account.
    </Typography>
  )

  const checkboxSMSLabel = (
    <Typography
      variant="body2"
      sx={{
        textAlign: 'left',
      }}
    >
      By checking this box, I agree that Attorney Share may send me recurring
      and automated promotional SMS communications. Note: consent is not a
      condition of purchase and you can cancel at any time.
    </Typography>
  )

  const showLoginButton = !['INVITED', 'ADMIN', 'CASE_MANAGER'].includes(
    accountType
  )

  return (
    <AuthLayout>
      <JSnackBar
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        horizontal="center"
        vertical="bottom"
        handleClose={handleClose}
      />
      <Box
        sx={theme => ({
          pb: { md: 5 },
          overflow: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
          height: '100%',
          maxWidth: 600,
          [theme.breakpoints.down('md')]: {
            paddingBottom: theme.spacing(5),
          },
        })}
      >
        <LogoBar withActionOption={showLoginButton} />
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography
              variant={isLessMd ? 'h6' : 'h5'}
              sx={{ mb: 2.5, textAlign: 'left' }}
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              sx={{ mb: 5, textAlign: 'left' }}
              color="text.secondary"
            >
              {subtitle}
            </Typography>
            <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                rowSpacing={1.25}
                columnSpacing={2.5}
                sx={{ mb: 3 }}
              >
                <Grid item xs={12} md={6}>
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field }) => (
                      <CreateAccountField
                        {...field}
                        label="First Name *"
                        error={!!errors.firstName}
                        helperText={errors.firstName?.message || ' '}
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          maxLength: 20,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="lastName"
                    control={control}
                    render={({ field }) => (
                      <CreateAccountField
                        {...field}
                        label="Last Name *"
                        error={!!errors.lastName}
                        helperText={errors.lastName?.message || ' '}
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          maxLength: 20,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <CreateAccountField
                        {...field}
                        label="Professional Email *"
                        error={!!errors.email}
                        helperText={errors.email?.message || ' '}
                        variant="outlined"
                        fullWidth
                        disabled={!!tokenData?.email}
                        inputProps={{
                          maxLength: 60,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <CreateAccountField
                        {...field}
                        label="Cell Phone *"
                        error={!!errors.phone}
                        helperText={errors.phone?.message || ' '}
                        variant="outlined"
                        fullWidth
                        type="tel"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <PasswordField
                        {...field}
                        error={errors.password?.message}
                        label="Password *"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="repeatPassword"
                    control={control}
                    render={({ field }) => (
                      <PasswordField
                        {...field}
                        error={errors.repeatPassword?.message}
                        label="Repeat Password *"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Box sx={{ mb: 0.5 }}>
                <Controller
                  control={control}
                  name="terms"
                  render={({
                    field: { onChange, value, ...field },
                    fieldState,
                  }) => (
                    <Checkbox
                      {...field}
                      checked={value}
                      checkboxProps={{
                        error: fieldState.invalid,
                        helperText: fieldState?.error?.message,
                        label: checkboxTermsLabel,
                      }}
                      onChange={onChange as unknown as CheckboxOnChangeHandler}
                      muiCheckboxProps={{
                        sx: {
                          top: -6,
                        },
                        size: 'small',
                      }}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 1.5 }}>
                <Controller
                  control={control}
                  name="smsMessages"
                  render={({
                    field: { onChange, value, ...field },
                    fieldState,
                  }) => (
                    <Checkbox
                      {...field}
                      checked={value}
                      checkboxProps={{
                        error: fieldState.invalid,
                        helperText: fieldState?.error?.message,
                        label: checkboxSMSLabel,
                      }}
                      onChange={onChange as unknown as CheckboxOnChangeHandler}
                      muiCheckboxProps={{
                        sx: {
                          top: -6,
                        },
                        size: 'small',
                      }}
                    />
                  )}
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  sx={{
                    width: {
                      xs: 200,
                      md: '50%',
                    },
                    height: 48,
                  }}
                  onClick={handleNextClick}
                  variant="contained"
                  size="large"
                  endIcon={<ArrowForward sx={{ color: 'primary.main' }} />}
                >
                  Continue
                </Button>
              </Box>
            </form>
          </>
        )}
      </Box>
      <ExistingOrganizationModal
        organizationName={
          checkForInvitesError?.response?.data?.organization?.name ?? ''
        }
        open={showExistingOrganizationModal}
      />
    </AuthLayout>
  )
}

export default CreateAccountForm

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  TTokenValidationResponse,
  useTokenValidation,
} from '@/hooks/useTokenValidation'
import { USER_ROLES } from '@/constants'
import { useMutation } from '@apollo/client'
import { VALIDATE_ACCOUNT } from '@/gql/app/validateAccount/validateAccountMutations.graphql'
import { sessionStorageService } from '@/services/SessionStorage/SessionStorageService'
import { OrgProfileFlowState } from '../types'

export const useVerification = () => {
  const navigate = useNavigate()
  const { validateToken, isTokenExpired } = useTokenValidation()
  const [validateAccount] = useMutation(VALIDATE_ACCOUNT, {
    context: { headers: { Authorization: '' } },
  })
  const [searchParams] = useSearchParams()
  const verificationId = searchParams.get('id')
  const token = searchParams.get('token')
  const [flow, setFlow] = useState<OrgProfileFlowState>('SUCCESS')
  const [loading, setIsLoading] = useState<boolean>(true)
  const [tokenData, setTokenData] = useState<TTokenValidationResponse>(
    {} as TTokenValidationResponse
  )
  const [error, setError] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  useEffect(() => {
    const validate = async () => {
      if (!token || !verificationId) {
        navigate('/signIn')
        return
      }

      setIsLoading(true)

      try {
        sessionStorageService.clear()
        const tokenValidationResponse = await validateToken({
          tokenToValidate: token,
          type: 'account_verification',
        })
        if (
          !tokenValidationResponse ||
          isTokenExpired(tokenValidationResponse.exp)
        ) {
          setFlow('EXPIRED_TOKEN')
          return
        }

        setTokenData(tokenValidationResponse)

        const validateAccountResponse = await validateAccount({
          variables: {
            data: { token },
            updateUserEmailValidationItemId: verificationId,
          },
        })

        if (validateAccountResponse?.data?.update_user_email_validation_item) {
          setFlow('SUCCESS')
        }
        if (validateAccountResponse.errors) {
          setError(validateAccountResponse.errors[0].message)
          setFlow('INVALID_TOKEN')
        }
      } catch (err: any) {
        setError(err?.message || 'Account validation failed')
        setFlow('INVALID_TOKEN')
      } finally {
        setIsLoading(false)
      }
    }

    validate()
  }, [
    token,
    verificationId,
    navigate,
    validateToken,
    isTokenExpired,
    validateAccount,
  ])

  useEffect(() => {
    if (
      token &&
      tokenData.email &&
      tokenData.email.toLowerCase() !== email.toLowerCase()
    ) {
      setEmail(tokenData.email.toLowerCase())
    }
  }, [tokenData, token, email])

  const handleFlowNavigation = useCallback((step: OrgProfileFlowState) => {
    setFlow(step)
  }, [])

  const handleContinue = useCallback(() => {
    handleFlowNavigation('LOGIN')
  }, [handleFlowNavigation])

  const onSignInSuccess = useCallback(() => {
    if (tokenData.role === USER_ROLES.BasicUser) {
      handleFlowNavigation('INDIVIDUAL')
      return
    }
    if (tokenData.role === USER_ROLES.SAdminAttorneyUser) {
      handleFlowNavigation('ORGANIZATION')
      return
    }
    if (tokenData.role === USER_ROLES.SuperAdminUser) {
      handleFlowNavigation('ORGANIZATION_NO_LICENSE')
      return
    }
    handleFlowNavigation('INVALID_TOKEN')
  }, [handleFlowNavigation, tokenData])

  // This variable only considers these two roles, as they are the only ones
  // that have a license and require verification. Other roles with license go
  // through the Invite process (e.g. Admin attorney user).
  const licensedAccount =
    tokenData?.role === USER_ROLES.SAdminAttorneyUser ||
    tokenData?.role === USER_ROLES.BasicUser

  return {
    flow,
    token,
    loading,
    handleContinue,
    handleFlowNavigation,
    onSignInSuccess,
    licensedAccount,
    email,
    error,
  }
}

import { useOptimizely } from './useOptimizely'
import { FeatureFlag } from './types'

export const useFeatureFlags = (
  /**
   * This should only be used for testing.
   */
  overrides: Partial<Record<FeatureFlag, boolean>> = {}
): Partial<Record<FeatureFlag, boolean>> => {
  const { featureFlags } = useOptimizely()

  return { ...featureFlags, ...overrides }
}

import { useCallback, useMemo } from 'react'
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined'
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined'
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded'
import Star from '@mui/icons-material/Star'
import { CircularProgress, Grid, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import publicProfileBG from '@/assets/publicProfileBG.svg'
import useInviteToCase from '@/hooks/inviteToCase/useInviteToCase'
import { ProfileType, PermissionsMatrixType } from '@/types'
import { useChat } from '@/hooks/useChat'
import { Organization_User } from '@/gql/appApi'
import JHQBadge from '@/assets/JHQBadge.svg'
import JAvatar from '@/components/atoms/JAvatar'
import { isQualifiedMembership } from '@/utils/membership'
import { getPracticesAreas } from '@/utils/profile'
import {
  ProfileSummaryContainer,
  StyledAvatarBG,
} from './ProfileSummary.styles'
import usePublicProfile from '../hooks/usePublicProfile'

export interface IProfileSummaryProps {
  profile: ProfileType
  attorneyId: string
  loading?: boolean
  isSameOrganization: boolean
  currentPermissionsSet?: PermissionsMatrixType
  currentOrganization: Organization_User
}

const ProfileSummary = ({
  profile,
  attorneyId,
  loading,
  currentPermissionsSet,
  currentOrganization,
  isSameOrganization,
}: IProfileSummaryProps) => {
  const fullName = `${profile?.first_name} ${profile?.last_name}`
  const { renderModal, openModal, setOpenModal } = useInviteToCase({
    prospectAttorneyId: attorneyId,
    profile,
  })
  const { openChatForUser } = useChat()

  const {
    isFavorite,
    loading: loadingFavorites,
    onFavorite,
  } = usePublicProfile({
    attorneyId,
  })

  const handleFavoriteClick = useCallback(async () => {
    await onFavorite()
  }, [onFavorite])

  const renderFavoriteButton = useMemo(() => {
    const sizeProp = { width: '17px' }
    if (loadingFavorites) {
      return <CircularProgress size={17} />
    }
    if (isFavorite) {
      return <Star sx={sizeProp} />
    }
    return <StarBorderRoundedIcon sx={sizeProp} />
  }, [isFavorite, loadingFavorites])

  const positionName = currentOrganization?.position_name

  const isVetted = Boolean(profile?.attorney_id?.is_vetted)
  const isQualified = isQualifiedMembership(
    profile?.attorney_id?.membership_type?.id
  )

  return (
    <ProfileSummaryContainer>
      <Grid container>
        <Grid
          item
          alignItems="center"
          justifyContent="center"
          xs={12}
          sx={{
            padding: 0,
          }}
        >
          <StyledAvatarBG src={publicProfileBG} alt="Profile background" />
          <JAvatar
            src={profile?.avatar?.id}
            badgeSize="large"
            sx={{
              avatar: {
                width: 1,
                height: 1,
                ...(!isVetted &&
                  !isQualified && {
                    border: '8px solid white',
                  }),
              },
              badge: {
                transform: 'translateY(-50%)',
                mx: 'auto',
                display: 'block',
                width: '120px',
                height: '120px',
                mb: '-50px',
              },
              badgeContent: {
                position: 'relative',
                bottom: '5px',
                right: '5px',
              },
            }}
            isVetted={isVetted}
            isQualified={isQualified}
          />
        </Grid>
        {loading ? (
          <Grid
            item
            xs={12}
            sx={{
              p: 6,
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress size={30} />
          </Grid>
        ) : (
          <Grid item xs={12} sx={{ px: 2, pt: 2, pb: 1 }}>
            <Typography
              display="block"
              variant="h5"
              sx={theme => ({
                color: theme.palette.primary.main,
                mb: 1,
              })}
            >
              {fullName}
            </Typography>

            {profile?.firms?.[0] && (
              <>
                <Typography
                  display="block"
                  variant="subtitle1"
                  sx={{ mt: 2.5, mb: 0.75 }}
                >
                  {profile?.firms?.[0]?.name}
                </Typography>
                <Typography
                  display="block"
                  variant="body2"
                  color="text.secondary"
                >
                  {`${profile?.firms?.[0]?.address}, ${profile?.firms?.[0]?.city}, ${profile?.firms?.[0]?.state?.code} ${profile?.firms?.[0]?.zip}`}
                </Typography>
              </>
            )}
            {positionName && (
              <Typography variant="body2" color="text.secondary">
                {positionName}
              </Typography>
            )}
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                marginBottom: '1rem',
              }}
            >
              {getPracticesAreas(profile?.practice_areas ?? [])}
            </Typography>
            {/* @see https://attorney.atlassian.net/browse/MP-1409 */}
            {/* {profile?.contacts?.map(contact => (
              <Typography key={contact.value} variant="body2">
                {contact.type?.name}: <PhoneNumber>{contact.value}</PhoneNumber>
              </Typography>
            ))} */}
            {isVetted && (
              <Box sx={{ mt: 2, display: 'flex' }}>
                <img alt="JHQ Badge" src={JHQBadge} width={11} height={16} />
                <Typography variant="subtitle2" sx={{ ml: 0.5 }}>
                  Justice HQ Member
                </Typography>
              </Box>
            )}
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1} sx={{ p: 2 }}>
        {/* TODO: currentPermissionsSet should be changed and use the global permissions instead (with both options) */}
        {!isSameOrganization && currentPermissionsSet?.User?.read && (
          <Grid item xs={12}>
            <Button
              fullWidth
              startIcon={
                <HandshakeOutlinedIcon
                  sx={{
                    width: '17px',
                  }}
                />
              }
              variant="contained"
              size="large"
              disableElevation
              onClick={() => setOpenModal(true)}
              disabled={loading}
            >
              Invite to Case
            </Button>
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            fullWidth
            startIcon={
              <CommentOutlinedIcon
                sx={{
                  width: '17px',
                }}
              />
            }
            variant="outlined"
            size="large"
            onClick={() => {
              openChatForUser(profile?.attorney_id?.user_id?.id ?? '')
            }}
            disabled={loading}
          >
            Send Message
          </Button>
        </Grid>
        {currentPermissionsSet?.Favorites?.update && ( // TODO: replace for favorites entity
          <Grid item md={12} xs={12}>
            <Button
              fullWidth
              startIcon={renderFavoriteButton}
              variant="text"
              size="large"
              sx={{
                cursor: loadingFavorites ? 'not-allowed' : 'pointer',
              }}
              onClick={handleFavoriteClick}
              disabled={loading || loadingFavorites}
            >
              {!isFavorite ? 'Set as Favorite' : 'Remove from Favorites'}
            </Button>
          </Grid>
        )}
      </Grid>
      {openModal && renderModal()}
    </ProfileSummaryContainer>
  )
}

export default ProfileSummary

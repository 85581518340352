import { TableBody, Box, CircularProgress } from '@mui/material'
import { UseHitsProps, useHits, useInfiniteHits } from 'react-instantsearch'
import AlgoliaHit from '@/pages/Marketplace/components/AlgoliaHit'
import { useSizes } from '@/hooks/useSizes'
import { useEffect, useRef } from 'react'

const AlgoliaHitList = (props: UseHitsProps) => {
  const { isLessMd } = useSizes()
  const sentinel = useRef(null)
  const { items: infiniteItems, isLastPage, showMore } = useInfiniteHits(props)
  const { items } = useHits(props)

  useEffect(() => {
    let observer: IntersectionObserver | null = null

    if (isLessMd && sentinel.current) {
      observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !isLastPage) {
            showMore()
          }
        })
      })

      observer.observe(sentinel.current)
    }

    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [isLessMd, isLastPage, showMore])

  if (isLessMd) {
    return (
      <Box>
        {infiniteItems.map(hit => (
          <AlgoliaHit hit={hit} key={hit.objectID} {...props} />
        ))}
        <div ref={sentinel} />
        {!isLastPage && (
          <Box display="flex" justifyContent="center" p={2}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    )
  }

  return (
    <TableBody>
      {items.map(hit => (
        <AlgoliaHit hit={hit} key={hit.objectID} {...props} />
      ))}
    </TableBody>
  )
}

export default AlgoliaHitList

import { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import Button from '@mui/material/Button'

import { Controller, useFormContext } from 'react-hook-form'

import { useSizes } from '@/hooks/useSizes'
import { FileHistoryProps, FileUploaderProps } from './types'

import FilesUploadingList from './FilesUploadingList'
import FilesDragAndDropArea from './FilesDragAndDropArea'

const allowedExtensionsForFeeAgreement = ['pdf', 'png', 'jpg', 'jpeg']

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const FILE_LIMIT_100MB = 1e8

const FilesUploader = ({
  caseId,
  refetchCaseData,
  onAddExternal,
  isExternalLoading = false,
  casePurchaseId,
  vertical = false,
}: FileUploaderProps) => {
  const theme = useTheme()
  const { isLessMd } = useSizes()
  const { control } = useFormContext()

  const [dataFiles, setDataFiles] = useState<FileHistoryProps[]>([])

  const fileValidation = (file: File) => {
    if (file.size === 0 || file.size >= FILE_LIMIT_100MB) {
      return 'Invalid file size! Please select a file under 100MB.'
    }
    if (casePurchaseId !== undefined) {
      const fileExtension = file.name.split('.').pop()?.toLowerCase()
      if (
        !fileExtension ||
        !allowedExtensionsForFeeAgreement.includes(fileExtension)
      ) {
        return 'Invalid file type! Please select a PDF or image file (.png, .jpeg, .jpg).'
      }
    }

    return true
  }

  const addFiles = (files: FileList) => {
    const tmpFilesArr: FileHistoryProps[] = []
    for (let i = 0; i < files.length; i += 1) {
      const tmpfile = files.item(i) as File
      const isValid = fileValidation(tmpfile)

      const fileObj = {
        file: isValid === true ? tmpfile : null,
        size: tmpfile?.size || 0,
        name: tmpfile?.name || '',
        isHidden: false,
        error: isValid === true ? '' : isValid,
        status: null,
      }

      tmpFilesArr.push(fileObj)
    }
    setDataFiles(tmpFilesArr)
    onAddExternal(
      tmpFilesArr
        .filter(item => item.file !== null)
        .map(item => item.file) as File[],
      false
    )
  }

  const isFeeAgreement = casePurchaseId !== undefined

  const description = isFeeAgreement
    ? 'Please submit a signed copy of the fee agreement to confirm it'
    : `Any files you upload for this case are only viewable by you and any attorney(s) you grant access to.`

  const dragAndDropText = isFeeAgreement
    ? 'Drag and drop the document here'
    : 'Drag and drop files here'

  const buttonLabel = isFeeAgreement ? 'Upload Fee Agreement' : 'Browse Files'

  return (
    <Grid container gap={1} sx={{ marginTop: theme.spacing(2) }}>
      {!isFeeAgreement && (
        <Grid item xs={12}>
          <Typography variant="h6">Add more files to Data Room</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="body2">{description}</Typography>
      </Grid>
      <Controller
        name="dataRoomFile"
        control={control}
        defaultValue={null}
        render={({ field: { onBlur, ref } }) => (
          <>
            <FilesDragAndDropArea addFiles={addFiles}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    backgroundColor: '#F5F7FA',
                    padding: isFeeAgreement ? '10px' : '4rem 0',
                  }}
                >
                  <Stack
                    direction={vertical ? 'row' : 'column'}
                    spacing={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      color: '#646464',
                    }}
                  >
                    {!isLessMd && (
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <CloudUploadOutlinedIcon
                            fontSize={isFeeAgreement ? 'medium' : 'large'}
                          />
                          <Typography
                            variant={isFeeAgreement ? 'caption' : 'h6'}
                            textAlign="center"
                          >
                            {dragAndDropText}
                          </Typography>
                        </Box>
                        <Divider
                          flexItem={vertical}
                          sx={{
                            width: vertical ? 'auto' : '100px',
                            '& .MuiDivider-wrapper': {
                              p: vertical ? 0 : undefined,
                            },
                          }}
                          orientation={vertical ? 'vertical' : 'horizontal'}
                        >
                          <Typography
                            variant={isFeeAgreement ? 'caption' : 'body1'}
                            position="relative"
                            top={-4}
                          >
                            or
                          </Typography>
                        </Divider>
                      </>
                    )}
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Button
                        component="label"
                        variant="outlined"
                        sx={{
                          minwWidth: '120px',
                          backgroundColor: theme.palette.common.white,
                          textAlign: 'center',
                        }}
                      >
                        {buttonLabel}
                        <VisuallyHiddenInput
                          type="file"
                          multiple
                          onChange={e => {
                            const files = e.target.files || null
                            if (files !== null) {
                              addFiles(files)
                              e.target.value = ''
                            }
                          }}
                          onBlur={onBlur}
                          ref={ref}
                        />
                      </Button>
                      <Typography variant="caption" textAlign="center">
                        {' '}
                        Maximum file size is 100MB
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Grid>
            </FilesDragAndDropArea>
            {dataFiles !== null && dataFiles.length > 0 && (
              <FilesUploadingList
                dataFiles={dataFiles}
                caseId={caseId}
                casePurchaseId={casePurchaseId}
                refetchCaseData={refetchCaseData}
                onAddExternal={onAddExternal}
                isExternalLoading={isExternalLoading}
              />
            )}
          </>
        )}
      />
    </Grid>
  )
}

export default FilesUploader

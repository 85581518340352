import JAvatar from '@/components/atoms/JAvatar'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useCallback, useMemo } from 'react'
import { ICaseUsersProps, TCardUser } from '../types'

const CaseUsers = ({ users }: ICaseUsersProps) => {
  // Group users by role to display them separately
  const groups = useMemo(
    () =>
      users.reduce((acc, user) => {
        const { userRole } = user
        if (!acc[userRole]) {
          acc[userRole] = []
        }
        acc[userRole].push(user)
        return acc
      }, {} as Record<string, TCardUser[]>),
    [users]
  )

  // Function to render grouped avatars, if there are more than 2 users, it will render the
  // first name and the number of remaining users. The remaining names are shown in a tooltip.
  const renderGroup = useCallback((group: TCardUser[]) => {
    if (group.length > 2) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {group.map((member, idx) => (
            <JAvatar
              key={member.id}
              src={member.avatarUrl}
              sx={{
                avatar: {
                  width: '36px',
                  height: '36px',
                  marginLeft: idx === 0 ? 0 : -1.5,
                },
                badge: {
                  marginLeft: idx === 0 ? 0 : -1.5,
                  zIndex: group.length - idx, // ensure avatars in the front are on top
                },
              }}
              alt={member.name}
              isVetted={member?.isVetted}
              isQualified={member?.isQualified}
            />
          ))}
          <Tooltip title={group.map(user => user.name).join(', ')}>
            <Typography variant="body1">
              {group[0].name} and {group.length - 1} more
            </Typography>
          </Tooltip>
        </Box>
      )
    }
    return group.map(member => (
      <Box
        key={member.id}
        sx={{
          display: 'flex',
          alignItems: 'center',
          mr: 2,
        }}
      >
        <JAvatar
          src={member.avatarUrl}
          sx={{
            avatar: {
              width: '28px',
              height: '28px',
            },
          }}
          alt={member.name}
          isVetted={member?.isVetted}
          isQualified={member?.isQualified}
        />
        <Typography variant="body1">{member.name}</Typography>
      </Box>
    ))
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        mr: 2,
      }}
    >
      {Object.entries(groups).map(([roleName, group]) => (
        <Box
          key={roleName}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-start', md: 'center' },
            mr: 2,
            mt: {
              xs: 1,
              md: 0,
            },
          }}
        >
          <Typography
            variant="caption"
            sx={{
              mr: { xs: 0, md: 1 },
              mb: { xs: 1, md: 0 },
            }}
          >
            {roleName}
            {group.length > 1 ? 's' : ''}
          </Typography>
          {renderGroup(group)}
        </Box>
      ))}
    </Box>
  )
}

export default CaseUsers

import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import InputAdornment from '@mui/material/InputAdornment'
import Search from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import { Controller, useFormContext } from 'react-hook-form'
import { Catalog_Practice_Area, Catalog_Us_State } from '@/gql/appApi'
import JAutocomplete from '@/components/atoms/JAutocomplete'
import WaterfallModalLayout from './WaterfallModalLayout'

interface IWaterfallInfoProps {
  jurisdictions: Catalog_Us_State[]
  practiceAreas: Catalog_Practice_Area[]
  isPracticeAreasRequired: boolean
}

const WaterfallInfo = ({
  jurisdictions,
  practiceAreas,
  isPracticeAreasRequired,
}: IWaterfallInfoProps) => {
  const { control } = useFormContext()

  return (
    <WaterfallModalLayout
      title="Waterfall Info"
      description="You will only be able to add attorneys licensed in the selected jurisdiction."
    >
      <JAutocomplete
        control={control}
        name="jurisdiction"
        options={jurisdictions}
        label="Jurisdiction"
        getOptionLabel={option => `${option.code} - ${option.name}`}
        fullWidth
        rules={{
          required: 'Please select a Jurisdiction.',
        }}
        sx={{ mb: 2 }}
      />
      <Controller
        control={control}
        name="practiceAreas"
        rules={{
          required: isPracticeAreasRequired
            ? 'Please select at least one Practice Area.'
            : false,
        }}
        render={({ fieldState, field: { value: fieldValue, onChange } }) => (
          <Autocomplete
            {...fieldState}
            multiple
            disabled={practiceAreas.length === 0}
            options={practiceAreas}
            getOptionLabel={option => option.name || ''}
            value={practiceAreas.filter(option =>
              fieldValue?.includes(option.id)
            )}
            onChange={(_, optionData) =>
              onChange(optionData.map(option => option.id))
            }
            renderInput={params => (
              <TextField
                {...params}
                label="Practice Areas"
                placeholder="Practice Areas"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <Search color="disabled" />
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...rest } = getTagProps({ index })
                return (
                  <Chip
                    key={option.id}
                    variant="outlined"
                    label={option.name}
                    {...rest}
                  />
                )
              })
            }
          />
        )}
      />
    </WaterfallModalLayout>
  )
}

export default WaterfallInfo

import React from 'react'
import { Box, Chip, ChipProps, Typography } from '@mui/material'
import { alpha, useTheme } from '@mui/material/styles'
import { useCaseContext } from '../../hooks/useCaseContext'
import CaseHeaderSkeleton from './skeleton'
import { ActionConfig, actionConfigs } from './actionConfigs'

interface ActionChipProps extends Omit<ChipProps, 'onClick'> {
  icon: React.ReactElement
  label: string
  onClick: () => void
  color?: ChipProps['color']
}

const ActionChip: React.FC<ActionChipProps> = ({
  icon,
  label,
  onClick,
  color,
  ...rest
}) => {
  const theme = useTheme()
  const themeColor =
    color &&
    color in theme.palette &&
    typeof theme.palette[color as keyof typeof theme.palette] === 'object' &&
    'main' in (theme.palette[color as keyof typeof theme.palette] as object)
      ? (theme.palette[color as keyof typeof theme.palette] as { main: string })
          .main
      : undefined
  const defaultColor = alpha(theme.palette.common.black, 0.87)
  const textColor = themeColor || defaultColor

  return (
    <Chip
      icon={icon}
      label={label}
      onClick={onClick}
      variant="outlined"
      sx={{
        height: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        color: textColor,
        typography: 'body2',
        '& .MuiSvgIcon-root': {
          color: textColor,
          marginTop: '1px',
          height: theme.spacing(1.5),
        },
        '& .MuiChip-label': {
          pl: theme.spacing(0.5),
          letterSpacing: 0,
        },
      }}
      {...rest}
    />
  )
}

const ActionButtons: React.FC = () => {
  const context = useCaseContext()
  const { caseData } = context

  if (!caseData) return null
  if (!caseData.actions) return null
  if (Object.values(caseData.actions).every(value => !value)) return null

  const handleActionClick = (config: ActionConfig) => {
    config.actionHandler(caseData, context)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: { xs: 1, sm: 2 },
        mt: 2,
      }}
    >
      {actionConfigs.map(config => {
        if (!caseData.actions[config.action]) return null
        return (
          <ActionChip
            key={config.action}
            icon={config.getIcon(caseData)}
            label={config.getLabel(caseData)}
            onClick={() => handleActionClick(config)}
            color={config.color}
          />
        )
      })}
    </Box>
  )
}

const CaseHeader: React.FC = () => {
  const { caseData, loading } = useCaseContext()

  if (loading) return <CaseHeaderSkeleton />

  if (!caseData) return null

  const { created_ts, name, jurisdiction, practice_area } = caseData

  const postedDateFormatted = new Date(created_ts).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })

  return (
    <Box>
      <Typography variant="caption" color="text.disabled">
        Posted on {postedDateFormatted}
      </Typography>

      <Typography variant="h5" component="h1">
        {name}
      </Typography>

      <Typography variant="caption" color="text.secondary">
        {jurisdiction.name}, {practice_area.name}
      </Typography>

      <ActionButtons />
    </Box>
  )
}

export default CaseHeader

import { MenuItemType } from '@/components/Menu/types'
import { PATHS_WITH_SECTIONS, getSubsectionLink } from '@/router/RoutesUtils'

export const MY_NETWORK_PAGES = {
  MY_HIRES: 'My Hires',
  MY_WATERFALL_REFERRALS: 'Waterfall Referrals™',
  // MY_FAVORITES: 'My Favorites',
  MEMBER_DIRECTORY: 'Member Directory',
}

// Override the title for the page if needed
export const getTitle = (page: string) => {
  switch (page) {
    case MY_NETWORK_PAGES.MY_WATERFALL_REFERRALS: {
      return 'Waterfall Referrals™'
    }
    default: {
      return page
    }
  }
}

export const getSubtitle = (page: string) => {
  switch (page) {
    case MY_NETWORK_PAGES.MY_HIRES: {
      return `Below are the attorneys with whom you are sharing referrals. Click on an
      attorney's info to view cases related to them. You can view both inbound
      and outbound referrals as applicable.`
    }
    case MY_NETWORK_PAGES.MY_WATERFALL_REFERRALS: {
      return `Create your Waterfall Referrals to quickly assign cases to preselected
      users. Create as many groups as you need to meet your requirements. Manage
      users or change the order of groups at any time.`
    }
    // case MY_NETWORK_PAGES.MY_FAVORITES: {
    //   return `A curated list of attorneys that you've selected as your favorites. This list allows you to easily keep track of attorneys that you've found helpful or want to work with in the future.`
    // }
    case MY_NETWORK_PAGES.MEMBER_DIRECTORY: {
      return `Search for members who are already in your network or discover attorneys who are part of Attorney Share.`
    }
    default: {
      return ''
    }
  }
}

export const menuItems: MenuItemType[] = [
  {
    sectionTitle: '',
    items: [
      { label: MY_NETWORK_PAGES.MY_HIRES },
      { label: MY_NETWORK_PAGES.MY_WATERFALL_REFERRALS },
      // { label: MY_NETWORK_PAGES.MY_FAVORITES },
      { label: MY_NETWORK_PAGES.MEMBER_DIRECTORY },
    ],
  },
]

export const getMyNetworkFirstLabel = () => menuItems[0].items[0].label
export const getMyNetworkFirstLink = () =>
  getSubsectionLink(PATHS_WITH_SECTIONS.MY_NETWORK, getMyNetworkFirstLabel())

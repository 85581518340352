import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { CasePurchase } from '@/pages/MyReferralsV2/types'
import { ClientSignedConfirmationModal } from '@/components/Modals/ClientSignedConfirmationModal'
import AgreementRejectionConfirmationModal from '@/components/Modals/AgreementRejectionConfirmationModal'
import { ResponseModal } from '@/components'
import { useClientSignatureConfirmation } from './useClientSignatureConfirmation'

interface ClientSignatureConfirmationProps {
  casePurchase: CasePurchase
}

export const ClientSignatureConfirmation = ({
  casePurchase,
}: ClientSignatureConfirmationProps) => {
  const theme = useTheme()
  const {
    handleClientRejected,
    handleClientRejectedSubmit,
    handleClientSigned,
    handleClientSignedModalClose,
    handleClientSignedSubmit,
    handleRejectionModalClose,
    handleResponseModalClose,
    isClientSigningResponseModal,
    matchFeeContentOnClientSigning,
    offeredFee,
    rejectionModalIsOpen,
    representingAttorneyId,
    response,
    setResponse,
    signedModalOpen,
  } = useClientSignatureConfirmation({
    casePurchase,
  })

  return (
    <>
      <Box
        alignItems="center"
        bgcolor={theme.palette.common.white}
        border={`solid 1px ${theme.palette.divider}`}
        borderRadius={1}
        display="flex"
        flexDirection="column"
        gap={2}
        px={3}
        py={2}
      >
        <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
          Did you sign the potential client?
        </Typography>
        <Box display="flex" gap={2}>
          <Button
            variant="text"
            color="error"
            size="large"
            onClick={handleClientRejected}
            sx={{
              width: '100%',
              [theme.breakpoints.up('md')]: {
                width: 'auto',
              },
            }}
          >
            No, Did Not Sign
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClientSigned}
            size="large"
            sx={[
              {
                width: '100%',
                [theme.breakpoints.up('md')]: {
                  width: 'auto',
                },
              },
            ]}
          >
            Yes, Client Signed
          </Button>
        </Box>
      </Box>
      {signedModalOpen && (
        <ClientSignedConfirmationModal
          onClose={handleClientSignedModalClose}
          open={signedModalOpen}
          onSubmitResponse={handleClientSignedSubmit}
          casePurchaseId={casePurchase.id}
          onBehalfOfAttorneyId={representingAttorneyId ?? ''}
          fee={offeredFee ?? 0}
        />
      )}
      {rejectionModalIsOpen && (
        <AgreementRejectionConfirmationModal
          onClose={handleRejectionModalClose}
          open={rejectionModalIsOpen}
          onSubmitResponse={handleClientRejectedSubmit}
          casePurchaseId={casePurchase.id}
          onBehalfOfAttorneyId={representingAttorneyId ?? ''}
          fee={offeredFee ?? 0}
        />
      )}
      {response.open && (
        <ResponseModal
          open={response.open}
          type={response.type}
          onClose={async () => {
            await handleResponseModalClose()
            setResponse({ open: false, type: null })
          }}
          variant={isClientSigningResponseModal ? 'standard' : 'outlined'}
          {...(isClientSigningResponseModal && { icon: false })}
          bodyContent={matchFeeContentOnClientSigning}
        />
      )}
    </>
  )
}

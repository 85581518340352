import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import EditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded'
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceRounded'
import { alpha, useTheme } from '@mui/material/styles'
import { useContext } from 'react'
import { OrganizationWaterfallsContext } from '@/components/OrganizationWaterfalls/OrganizationWaterfallsContext'
import { WaterfallList } from '@/components/OrganizationWaterfalls/types'
import WaterfallAttorney from './WaterfallAttorney'

const MAX_NUMBER_OF_ATTORNEYS_PER_WATERFALL = 3

interface IJWaterfallCardProps {
  waterfall: WaterfallList
  isFirmSettings?: boolean
}

const JWaterfallCard = ({
  waterfall,
  isFirmSettings,
}: IJWaterfallCardProps) => {
  const theme = useTheme()
  const {
    hasPermissionToCreateWaterfalls,
    onEditWaterfall,
    openDeleteWaterfallModal,
  } = useContext(OrganizationWaterfallsContext)
  const showEditDelete = isFirmSettings || hasPermissionToCreateWaterfalls

  const attorneyList = [...(waterfall?.waterfallMembers ?? [])]

  // Add null to display the empty positions
  while (attorneyList.length < MAX_NUMBER_OF_ATTORNEYS_PER_WATERFALL) {
    attorneyList.push(null)
  }

  const waterfallType = (() => {
    if (isFirmSettings) {
      return waterfall?.enabledForAttorneys
        ? 'Available to Attorneys'
        : 'Integrations Only'
    }

    return 'Personal Use Only'
  })()
  const waterfallName = waterfall?.waterfallName ?? ''
  const jurisdiction = waterfall?.jurisdiction?.name ?? ''

  const practiceAreas = waterfall?.practiceAreas?.length
    ? waterfall.practiceAreas.map(practiceArea => practiceArea.name)
    : null

  return (
    <Card
      elevation={1}
      sx={{
        border: '1px solid',
        borderColor: theme.palette.grey[100],
        minHeight: isFirmSettings ? 325 : null,
      }}
    >
      <CardContent sx={{ px: 3, pb: 0 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          {waterfallType && (
            <Typography
              variant="caption"
              color={alpha(theme.palette.common.black, 0.33)}
            >
              {waterfallType}
            </Typography>
          )}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: showEditDelete ? 0 : 1,
            }}
          >
            <Tooltip title={waterfallName}>
              <Typography
                variant="subtitle1"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  lines: 1,
                }}
              >
                {waterfallName}
              </Typography>
            </Tooltip>
            {showEditDelete && (
              <Box
                sx={{
                  minWidth: 72,
                }}
              >
                <IconButton
                  aria-label="edit"
                  onClick={() => onEditWaterfall(waterfall)}
                >
                  <EditOutlinedIcon
                    sx={{
                      height: theme.spacing(2.5),
                      width: theme.spacing(2.5),
                    }}
                  />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => openDeleteWaterfallModal(waterfall)}
                >
                  <DeleteOutlineIcon
                    sx={{
                      color: theme.palette.error.main,
                      height: theme.spacing(2.5),
                      width: theme.spacing(2.5),
                    }}
                  />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
        {jurisdiction && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: showEditDelete ? 0 : 1,
            }}
          >
            <PlaceRoundedIcon
              fontSize="inherit"
              sx={{
                mr: 1,
                color: theme.palette.text.disabled,
              }}
            />
            <Typography variant="body2" color="text.secondary">
              {jurisdiction}
            </Typography>
          </Box>
        )}
        {practiceAreas?.length && (
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <AccountBalanceIcon
              fontSize="inherit"
              sx={{
                mr: 1,
                color: theme.palette.text.disabled,
              }}
            />
            <Tooltip title={practiceAreas.join(', ')}>
              <Typography variant="body2" color="text.secondary" noWrap>
                {practiceAreas.join(', ')}
              </Typography>
            </Tooltip>
          </Box>
        )}
      </CardContent>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ pb: 3, px: 3 }}>
        {attorneyList.map((attorney, index) => (
          <WaterfallAttorney
            // Since we display a placeholder for empty positions, the index is used as the key for those
            key={attorney ? attorney.attorneyId : `empty-${index}`}
            attorney={attorney}
            position={(attorney?.listOrder ?? index) + 1}
          />
        ))}
      </Box>
    </Card>
  )
}

export default JWaterfallCard

import { Case } from '@/gql/appApi'
import { MembersPricesResponse } from '@/utils/api/types'

export type CatalogType = {
  id?: string
  name: string
  code?: string
}

export type AccessType = 'waterfall' | 'owner' | 'invited' | null

export type CaseType = {
  id: string
  fee: number
  name: string
  created_ts?: string
  proposal_due_date: string
  incident_date: string
  description: string
  mp_visibility: CatalogType
  preferred_language: CatalogType
  process_stage: CatalogType
  type: CatalogType
  case_number: string
  financing_source: CatalogType
  jurisdiction: CatalogType
}

export type TCase = Omit<Case, '__typename'>

export enum MpPreferences {
  HIDDEN = '64e9f6de-1f4a-11ee-bf79-db71a8be8172',
  FOLLOWING = '72e3c8f0-1f4a-11ee-bf7a-635bab3996e7',
}

/** No purchase activity status placeholder.
 * This `no-activity` status is used as a placeholder/default value for the purchase status when there is no purchase activity
 * but still allowing to build the `dataCase` object in useJCaseData.ts
 */
export const NO_PURCHASE_ACTIVITY_STATUS = 'no-activity'

export interface IOnSubmitAcceptActionProps {
  stripePriceId: string
  credits: string | number
  discount?: MembersPricesResponse['discount_stripe_price_id'] | null
}

import React, { useState, useCallback } from 'react'
import { Typography, styled, IconButton, Tooltip, Box } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ChatIcon from '@mui/icons-material/Chat'
import { Lead } from '../../types'

const LeadName = styled(Typography)({
  fontWeight: 'bold',
})

const ContactInfo = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  flexGrow: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

const ContactInfoWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  width: '100%',
})

const ActionIcons = styled('div')({
  display: 'flex',
  gap: '4px',
  marginLeft: 'auto',
})

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:focus-visible': {
    outline: `2px solid ${theme.palette.primary.main}`,
    outlineOffset: 2,
  },
}))

const ContactIconButton = ({
  href,
  ariaLabel,
  icon: Icon,
}: {
  href: string
  ariaLabel: string
  icon: React.ElementType
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    window.location.href = href
  }

  return (
    <Tooltip title={ariaLabel}>
      <StyledIconButton
        size="small"
        aria-label={ariaLabel}
        onClick={handleClick}
      >
        <Icon sx={{ fontSize: '16px' }} />
      </StyledIconButton>
    </Tooltip>
  )
}

const CopyToClipboardButton = ({ value }: { value: string }) => {
  const defaultLabel = 'Copy'
  const copiedLabel = 'Copied!'
  const [tooltipState, setTooltipState] = useState({
    title: defaultLabel,
    open: false,
  })

  const handleCopy = useCallback(() => {
    // Copy to clipboard
    navigator.clipboard.writeText(value)

    // Display "Copied!" tooltip for UX
    setTooltipState(prev => ({ ...prev, title: copiedLabel, open: true }))

    // Close tooltip after 1 second
    setTimeout(() => {
      setTooltipState(prev => ({ ...prev, open: false }))
    }, 1000)

    // Reset tooltip title after 1.2 seconds to prevent flickering
    setTimeout(() => {
      setTooltipState(prev =>
        prev.title === copiedLabel ? { title: defaultLabel, open: false } : prev
      )
    }, 1200)
  }, [value])

  return (
    <Tooltip
      title={tooltipState.title}
      open={tooltipState.open}
      onClose={() => setTooltipState(prev => ({ ...prev, open: false }))}
      onOpen={() => setTooltipState(prev => ({ ...prev, open: true }))}
    >
      <StyledIconButton size="small" aria-label="Copy" onClick={handleCopy}>
        <ContentCopyIcon sx={{ fontSize: '16px' }} />
      </StyledIconButton>
    </Tooltip>
  )
}

const LeadInfoDisplay: React.FC<{ lead: Lead }> = ({ lead }) => (
  <Box>
    {lead.name && <LeadName variant="subtitle2">{lead.name}</LeadName>}
    {lead.email && (
      <ContactInfoWrapper>
        <ContactInfo variant="body2">{lead.email}</ContactInfo>
        <ActionIcons>
          <ContactIconButton
            href={`mailto:${lead.email}`}
            ariaLabel="Send email"
            icon={EmailIcon}
          />
          <CopyToClipboardButton value={lead.email} />
        </ActionIcons>
      </ContactInfoWrapper>
    )}
    {lead.phone && (
      <ContactInfoWrapper>
        <ContactInfo variant="body2">{lead.phone}</ContactInfo>
        <ActionIcons>
          <ContactIconButton
            href={`tel:${lead.phone}`}
            ariaLabel="Call"
            icon={PhoneIcon}
          />
          <ContactIconButton
            href={`sms:${lead.phone}`}
            ariaLabel="Send SMS"
            icon={ChatIcon}
          />
          <CopyToClipboardButton value={lead.phone} />
        </ActionIcons>
      </ContactInfoWrapper>
    )}
  </Box>
)

export default LeadInfoDisplay

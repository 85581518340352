import { useCallback } from 'react'

import { useStateMachine } from '@/hooks/useStateMachine'

const useCreateProfileFlow = () => {
  const [state, send] = useStateMachine({})({
    initial: 'practiceAreas',
    on: {
      INITIAL_STEP: 'practiceAreas',
      PERSONAL_INFO: 'personalInfo',
      EDUCATION: 'education',
      MEMBERSHIP_INFO: 'membershipInfo',
    },
    states: {
      practiceAreas: {
        on: {
          NEXT: 'personalInfo',
        },
      },
      personalInfo: {
        on: {
          PREVIOUS: 'practiceAreas',
          NEXT: 'education',
        },
      },
      education: {
        on: {
          PREVIOUS: 'personalInfo',
          NEXT: 'membershipInfo',
        },
      },
      membershipInfo: {
        on: {
          PREVIOUS: 'education',
        },
      },
    },
  })

  const next = useCallback(() => send('NEXT'), [send])
  const back = useCallback(() => send('PREVIOUS'), [send])

  return {
    state,
    send,
    next,
    back,
  }
}

export default useCreateProfileFlow

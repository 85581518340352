import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import { DialogProps } from '@mui/material/Dialog'

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 3, 0, 3),
}))

export interface ModalHeaderProps {
  title: string
  onClose?: DialogProps['onClose']
  showCloseIcon?: boolean
}

const ModalHeader = ({
  title,
  onClose,
  showCloseIcon = true,
}: ModalHeaderProps) => (
  <Header>
    <Typography variant="h6">{title}</Typography>
    {showCloseIcon && (
      <IconButton
        onClick={event => {
          onClose?.(event, 'backdropClick') // small hack to close the modal
        }}
      >
        <CloseIcon sx={{ fontSize: theme => theme.typography.h6.fontSize }} />
      </IconButton>
    )}
  </Header>
)

export default ModalHeader

import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { alpha } from '@mui/material'
import { JDropdownUserProps } from './types'
import JAvatar from '../JAvatar'

const JDropdownUser = ({
  user,
  sx,
  badgeSize,
  ...props
}: JDropdownUserProps) => (
  <ListItem
    key={user.id}
    sx={{
      display: 'flex',
      paddingX: 2.5,
      paddingY: 2.25,
      width: '100%',
      alignItems: 'center',
      cursor: 'pointer',
      ...sx?.list,
    }}
    {...props}
  >
    <JAvatar
      src={user.picture}
      sx={{
        avatar: {
          width: 48,
          height: 48,
          ...sx?.avatar,
        },
      }}
      alt={user.name}
      isVetted={user?.isVetted}
      isQualified={user?.isQualified}
      badgeSize={badgeSize}
    />
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...sx?.textBox,
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {user.name}
      </Typography>
      {user.organization && (
        <Typography
          variant="body2"
          sx={{
            color: theme => alpha(theme.palette.common.black, 0.6),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {user.organization}
        </Typography>
      )}
    </Box>
  </ListItem>
)

export default JDropdownUser

/* eslint-disable react/no-array-index-key */
import Box from '@mui/material/Box'
import { getImagePath } from '@/utils/helpers'

import CustomLoading from '@/components/Loading'
import EmptyPage from '@/components/EmptyPage/EmptyPage'
import MyHiresCard from '../components/MyHiresCard/MyHiresCard'
import useMyHires from './useMyHires'
import { theme } from '@/styles/theme'
import { IMAGE_SIZES } from '@/constants'

export interface MyHiresProps {
  searchText?: string
}

const MyHires = ({ searchText }: MyHiresProps) => {
  const { myHires, loading: myHiresLoading } = useMyHires({ searchText })
  const emptyPage = !myHires.length && !myHiresLoading
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        paddingBottom: {
          md: theme.spacing(1),
        },
      }}
    >
      {myHiresLoading && <CustomLoading message="Loading attorneys..." />}
      {emptyPage && (
        <EmptyPage message="You haven’t yet shared a referral with any member" />
      )}

      {!emptyPage &&
        myHires.map(
          user =>
            user.attorney_id?.user_id && (
              <MyHiresCard
                key={user.attorney_id.id}
                user={{
                  ...user,
                  avatar: {
                    id: getImagePath({
                      id: user.attorney_id.user_id.avatar?.id ?? null,
                      size: IMAGE_SIZES.Medium,
                    }),
                  },
                }}
              />
            )
        )}
    </Box>
  )
}

export default MyHires

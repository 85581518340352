import {
  CASE_PURCHASE_STATUS,
  CASE_STATUS_FLOW,
  CaseStatusFlowValue,
} from '@/constants'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { useContext } from 'react'
import { useSizes } from '@/hooks/useSizes'
import { useGetMyRoleAndOrg } from '@/hooks/useGetMyRoleAndOrg'
import { ReferralContext } from './ReferralContext'
import { WaterfallCountdown } from './WaterfallCountdown'

export interface BuyerHeadlineProps {
  caseStatus: CaseStatusFlowValue
  didBuyerTakeMostRecentAction: boolean
  showWaterfallProposalDueDate: boolean
  waterfallProposalDueDate: Date
}

export const BuyerHeadline = ({
  caseStatus,
  didBuyerTakeMostRecentAction,
  showWaterfallProposalDueDate,
  waterfallProposalDueDate,
}: BuyerHeadlineProps) => {
  const theme = useTheme()
  const { isAttorney, isAdmin } = useGetMyRoleAndOrg()
  const {
    caseEntry: {
      case_purchase: [casePurchase],
    },
    caseParams: { isPrivate },
    wasBuyerInvited,
    isCaseManagerWithoutPermission,
  } = useContext(ReferralContext)
  const { isLessMd } = useSizes()

  const showCounterofferMessage =
    casePurchase?.status === CASE_PURCHASE_STATUS.Counteroffer &&
    !didBuyerTakeMostRecentAction

  return (
    <Box display="flex" alignItems="baseline" flexWrap="wrap" columnGap={1}>
      {!isCaseManagerWithoutPermission && (
        <>
          {showCounterofferMessage && (
            <Typography
              color={theme.palette.success.main}
              variant="captionBold"
            >
              Counteroffer Received
            </Typography>
          )}
          {!isPrivate && wasBuyerInvited && (
            <Typography
              color={theme.palette.success.main}
              variant="captionBold"
            >
              Invitation Received
            </Typography>
          )}
          {isPrivate &&
            (!casePurchase ||
              casePurchase?.status === CASE_PURCHASE_STATUS.Proposed) && (
              <Typography
                variant="captionBold"
                color={theme.palette.success.main}
              >
                Watefall Referral™ Received
              </Typography>
            )}
          {showWaterfallProposalDueDate &&
            (!isLessMd || (isAttorney && !isAdmin)) && (
              <WaterfallCountdown
                waterfallProposalDueDate={waterfallProposalDueDate}
              />
            )}
          {caseStatus === CASE_STATUS_FLOW.Matched && (
            <Typography
              color={theme.palette.warning.dark}
              variant="captionBold"
            >
              Matched Case - Contact the client
            </Typography>
          )}
        </>
      )}
    </Box>
  )
}

import map from 'lodash/map'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import { MenuItemType } from '@/components/Menu/types'

export const PATHS_WITH_SECTIONS = {
  MY_NETWORK: '/myNetwork',
  CASE_WITH_ID: '/case',
  EDIT_PUBLIC_PROFILE: '/editPublicProfile',
  ADMIN_PANEL: '/admin',
  ACCOUNT_SETTINGS: '/accountsettings',
}

export const labelToPath = (label: string) =>
  String(label || '')
    .toLocaleLowerCase()
    .split(' ')
    .join('-')
    .replace(/[^a-z0-9-_]/g, '')

export const pathToLabel = (path: string) =>
  startCase(
    toLower(
      String(path || '')
        .split('-')
        .join(' ')
    )
  )

export const getRouteLabelByPathStr = (
  menuItems: MenuItemType[],
  path?: string
) => {
  const allItems = map(menuItems, 'items').flat()
  return allItems.find(item => labelToPath(item?.label) === path)?.label
}

export const getSubsectionLink = (base: string, section: string) =>
  `${base}/${labelToPath(section)}`

type MapValues<T extends Record<string, string>> = T[keyof T]

// NOTE: These map to the names of catalog_case_purchase_status items in the DB.
// (SELECT name from attorney_share.catalog_case_purchase_status;)
export type CaseStatusFriendlyName =
  | 'Counteroffer'
  | 'Rejected'
  | 'Accepted'
  | 'In queue'
  | 'Blocked'
  | 'Expired'
  | 'Proposed'
  | 'Signed'
  | 'PendingPaymentAuthorization'
  | 'DeclinedAndAvaliable'
  | 'DeclinedAndRejected'
  | 'Withdrawn'

export const CASE_PURCHASE_STATUS = {
  /**
   * Sent on prepaid proposals
   */
  Accept: '116c00a8-25d3-11ee-8947-33ea0a32abbb',
  Blocked: '1f365702-c6c2-11ee-9cfe-432e5f7234a1',
  Counteroffer: '116b2098-25d3-11ee-8945-832678fca859',
  DeclineAndAvailable: '23c404d6-16e0-11ef-9f43-d7bc8fc4e671',
  DeclineAndRejected: '23c721d4-16e0-11ef-9f44-a35193f61598',
  Dismissed: '44cf7eae-5429-11ef-bf0f-abb0f18bceff',
  Expired: '4493b48e-d010-11ee-ad47-3f8f25397085',
  InQueue: '7bd4c6a6-3aae-11ee-becf-e3642a2c7e0a',
  PendingPaymentAuthorization: 'd3aff260-28ec-11ef-b2de-db795b483629',
  Proposed: '11687e24-25d3-11ee-8943-77f546a6e34d',
  Reject: '116b8f10-25d3-11ee-8946-cbab1357184b',
  Signed: '87e9ebd2-16e6-11ef-9f45-43fa5dfb59e6',
  Withdrawn: '3ca5c1b0-4458-11ef-bf0f-b3a501cfeb21',
} as const

export type CaseStatusValue =
  (typeof CASE_PURCHASE_STATUS)[keyof typeof CASE_PURCHASE_STATUS]

// Case Statuses for the new Case Status Workflow Revamp Epic
// https://attorney.atlassian.net/browse/MP-1776
export const CASE_STATUS_FLOW = {
  Available: '18e4ada2-12f0-11ef-9f3a-47146f6c9a16',
  Negotiation: '18ea56bc-12f0-11ef-9f3b-73449dd05437',
  Matched: '18eb466c-12f0-11ef-9f3c-b37c5d085a10',
  Rejected: '18ec64e8-12f0-11ef-9f3d-379639bc89bc',
  Signed: '18ed0484-12f0-11ef-9f3e-cf8bca96d4ed',
  Expired: '18ee4808-12f0-11ef-9f3f-83ffe4376eb8',
} as const

export type CaseStatusFlowValue = MapValues<typeof CASE_STATUS_FLOW>

export const MP_VISIBILITY = {
  Public: 'ae68f4ee-1f50-11ee-bf7f-f367224e3b63',
  Priority: 'b23606fc-1f50-11ee-bf80-e71449fd551d',
}

export const CASE_FILE_TYPE = {
  Dataroom: 'b89740ec-4359-11ee-8113-575cf8c29d79',
}

export const CONTACT_TYPES = {
  EmailContactType: 'c41de097-a276-4d5f-942e-c2a0a2eaab52',
  PhoneContactType: 'd68fc7b7-cece-4311-9872-2f6edb9363da',
}

export const PREFERRED_LANGUAGE = {
  PL_English: 'a181673c-14fd-11ee-8ec5-4fc84d373c2d',
}

export const EDUCATION_GRADE = {
  UnderGraduate: '51fda36a-2678-11ee-b8cc-2783700798fd',
  LawSchool: '51fe2d08-2678-11ee-b8cd-43a8d813d269',
}

export const TIME_UNIT = {
  Hours: 'c0720b1a-42b1-11ee-aa5c-6be7c0215a01',
}

export const PAYMENT_STATUS = {
  Uncaptured: 'c5828858-9b81-4455-9e2a-edabb8e89fbf',
  Completed: 'e585e9bd-2168-4856-be7f-2f6779861baf',
}

export const SIGN_REQUEST_STATUS = {
  Sent: 'd09f2064-4210-11ee-8d42-c393b1a2a5dc',
  Signed: 'd0a0a592-4210-11ee-8d43-a3fce40c7a7a',
}

export const FINANCING_SOURCE = {
  default: '6b94af96-1ff0-11ee-bf81-87f80e97383e',
}

export const CASE_PREFERENCE = {
  Hidden: '64e9f6de-1f4a-11ee-bf79-db71a8be8172',
  Following: '72e3c8f0-1f4a-11ee-bf7a-635bab3996e7',
}

export const ATTORNEYS_ROLES = {
  ATTORNEY: {
    name: 'Attorney',
    /** Invite Role for an Attorney -- Same as RestrictedUser role */
    id: 'b9cbf27f-c7b7-45ff-a20d-495b31683772',
    /** BasicUser role id */
    actualId: 'f31c2eb7-2d2f-4bdd-bd56-61dd7d0ed1c8',
  },
  CASE_MANAGER: {
    name: 'Case Manager',
    id: '1d1128f4-5647-4077-9866-8577a1d99ae3',
  },
  ADMIN: {
    name: 'Admin',
    id: '9d2d63f2-c60d-40ab-b92d-fe12bc064b9a',
  },
  ADMIN_ATTORNEY: {
    name: 'Admin',
    id: 'f0489f1a-d969-4a47-bb09-06c227bda882',
  },
  ORGANIZATION_CREATOR_ADMIN_ATTORNEY: {
    name: 'Admin',
    id: 'aab99238-9982-4bc5-88ea-1af10f271a31',
  },
}

export const ATTORNEYS_ROLES_BY_ID = {
  /** Invite Role for an Attorney -- Same as RestrictedUser role */
  'b9cbf27f-c7b7-45ff-a20d-495b31683772': 'Attorney',
  /** BasicUser role id */
  'f31c2eb7-2d2f-4bdd-bd56-61dd7d0ed1c8': 'Attorney',
  '1d1128f4-5647-4077-9866-8577a1d99ae3': 'Case Manager',
  '9d2d63f2-c60d-40ab-b92d-fe12bc064b9a': 'Admin',
  /** Same as  ADMIN_ATTORNEY */
  'aab99238-9982-4bc5-88ea-1af10f271a31': 'Admin',
  /** Same as ORGANIZATION_CREATOR_ADMIN_ATTORNEY */
  'f0489f1a-d969-4a47-bb09-06c227bda882': 'Admin',
}

export const INVITATION_STATUS = {
  Sent: '82124bf6-6853-11ee-90d8-cfd826e77df7',
  Resent: '8215afb2-6853-11ee-90d9-0b411be38e8d',
  Expired: '82164c60-6853-11ee-90da-dfc9c6f10f24',
  Revoked: '8216fc78-6853-11ee-90db-cfcbf36cb5e7',
  Redeeming: '4c55f1ec-69f3-11ee-90dc-63606cd32533',
}

export const USER_ROLES = {
  /** Role Invite for an Attorney -- Same as RestrictedUser role */
  InvitedAttorney: 'b9cbf27f-c7b7-45ff-a20d-495b31683772',
  BasicUser: 'f31c2eb7-2d2f-4bdd-bd56-61dd7d0ed1c8',
  /** Admin with a license -- Attorney */
  AdminAttorneyUser: 'f0489f1a-d969-4a47-bb09-06c227bda882',
  /** Admin with no license */
  AdminUser: '9d2d63f2-c60d-40ab-b92d-fe12bc064b9a',
  CaseManagerUser: '1d1128f4-5647-4077-9866-8577a1d99ae3',
  /** Organization creator with a license -- Attorney */
  SAdminAttorneyUser: 'aab99238-9982-4bc5-88ea-1af10f271a31',
  /** Organization creator with no license */
  SuperAdminUser: 'd1bcc779-3d0b-4cab-b438-6a791bc9bee6',
  RestrictedUser: 'b9cbf27f-c7b7-45ff-a20d-495b31683772',
}

export const USER_ADMINS_ROLES = [
  USER_ROLES.AdminAttorneyUser,
  USER_ROLES.SAdminAttorneyUser,
  USER_ROLES.AdminUser,
  USER_ROLES.SuperAdminUser,
]

export const USER_ATTORNEY_ROLES = [
  USER_ROLES.BasicUser,
  USER_ROLES.AdminAttorneyUser,
  USER_ROLES.SAdminAttorneyUser,
]

export const TERMS_PRIVACY_LINKS = {
  terms: 'https://attorneyshare.com/terms-of-service',
  privacy: 'https://attorneyshare.com/privacy-policy',
}

export const PROMO_CODE = {
  SIX_MONTHS_FREE: 'AS6M23',
  CASE_FEE_MATCH: 'ASFM23',
}

export const ACCOUNT_TYPE = {
  Individual: '920dcb28-7f48-11ee-b45f-e7b031345211',
  Organization: '920f60f0-7f48-11ee-b460-df4daa1f5205',
}

export const ZENDESK_HELP_URL = 'https://attorneyshare.zendesk.com/hc/en-us'

export const DATAROOM_FOLDERS = {
  PublicFolderID: '55b5331b-1a9a-490b-95fe-c87c0326f44e',
  DataRoomFolderID: '8ca676ff-d5a6-4670-9bdb-8dd7a7c529aa',
}

export const MATCH_FEE = {
  Owner: '99.99',
  NonOwner: '299.99',
}

export const DEFAULT_CASE_FEE = '33'

export const MEMBERSHIP_FEE = '$99.99'

export const MAX_BIO_LENGTH = 2500

export const MAX_CASE_DESCRIPTION_LENGTH = 2500

export const MEMBERSHIP_PAID_STATUS_ID = 'f3fd8624-3be2-11ee-90c3-3f8fcc11f8c0'

export const ACCESS_REQUEST_STATUS = {
  Given: 'a5b52bb2-af31-11ee-bfcc-bfe445a641e7',
  Granted: 'a5b52676-af31-11ee-bfcc-6365efc6b9e5',
  Pending: 'a5b52b80-af31-11ee-bfcc-ef13acb9febb',
  Rejected: 'a5b526d0-af31-11ee-bfcc-d3a5e8576b02',
  Revoked: 'a5b4f444-af31-11ee-bfcc-a75b2b4d2956',
}

export const JHQ_LINKS = {
  JusticeHQ: 'https://justicehq.com/',
  Waitlist: 'https://justicehq.com/about-us/application-process/',
  UserTypes:
    'https://attorneyshare.zendesk.com/hc/en-us/articles/24855408783515-User-Types-and-Marketplace-Permissions',
}

export const PAYMENT_METHOD = {
  Credits: 'f80db784-cc1a-11ee-ba38-f3577ab35901',
  FeederFee: 'efa39d36-d4d8-11ee-9f07-e7ac3b13c4d8',
  Invited: 'cb40f256-e87c-11ee-bfc7-7b07e7e8e3a5',
  Stripe: 'eadef370-cc1a-11ee-ba37-af78fb496398',
}

export const MEMBERSHIP_TYPE = {
  QUALIFIED: 'Qualified',
  VETTED: 'Vetted',
  STANDARD: 'Standard',
}

export const MEMBERSHIP_TYPES = {
  Qualified: 'd552de90-cc1a-11ee-ba35-1b937d242634',
  Vetted: 'd9b842c2-cc1a-11ee-ba36-13c86e0edf40',
  Standard: 'd0d9266c-cc1a-11ee-ba34-1f3c41cdfd04',
} as const
type MembershipTypeKeys = keyof typeof MEMBERSHIP_TYPES
export type MembershipTypes =
  | (typeof MEMBERSHIP_TYPES)[MembershipTypeKeys]
  | null

export const CASE_ACCESS_TYPES = {
  Owner: 'owner',
  Waterfall: 'waterfall',
  Invited: 'invited',
  NoAccess: null,
}

export const CASE_PROPOSAL_TYPES = {
  Proposed: 'Proposed',
  Referred: 'Referred',
  Invited: 'Invited',
} as const

export const ALPHANUMERIC_REGEXP = /[a-zA-Z0-9]/

export const LICENSE_STATUS = {
  INVALID: '087c0e66-e0b8-11ee-b855-33045a1ae16f',
  PENDING_VALIDATION: '23184510-e0b7-11ee-b853-e7655f6d4cc0',
  VALIDATED: '2b99eb94-e0b7-11ee-b854-d7a662a142af',
  PENDING_VALIDATION_ADDITIONAL_INFORMATION:
    '31f726a4-e0b8-11ee-b856-cb7de9963210',
} as const

export const IMAGE_SIZES = {
  XSmall: 36,
  Small: 48,
  Medium: 96,
  Large: 360,
}

export const CATALOG_CREDIT_PRICE_TYPE = {
  NORMAL: 'd159775e-c9c2-11ee-8ccc-5b6f922a0d21',
  PROMOTIONAL: 'd5a26f1e-c9c2-11ee-8ccd-4f7fb552aece',
  COMPLIMENTARY: 'e71a5c78-ca87-11ee-a6e7-5fa99e62f7f2',
  DISCOUNT: '4793b041-1ad4-4c8c-9dc8-92cc07bf3ed6',
} as const

export enum ProposalSuccess {
  ACCEPT_PAID_PENDING = 'case2',
  EATER_ACCEPT_PAID_COMPLETED = 'case4',
  FEEDER_ACCEPT_PAID_COMPLETED = 'case6',
}

export const INSURANCE_OPTIONS_WITH_AMOUNT = {
  OPTION_100_TO_249K: 'b576fa15-d854-45b6-8709-2845ec7a5722',
  OPTION_250_TO_999K: '024bf338-df47-4264-a76f-8c86dbdc4d43',
  OPTION_1M_PLUS: 'ef357979-9a7b-4424-a5cf-887e5e2cd0a9',
} as const

export const CATALOG_TIME_UNIT = {
  HOURS: 'c0720b1a-42b1-11ee-aa5c-6be7c0215a01',
  DAYS: 'c55647ae-42b1-11ee-aa5d-b707e2cefe73',
  WEEKS: 'c91d40fe-42b1-11ee-aa5e-d3efce9379c6',
  MONTHS: 'cd4c46b6-42b1-11ee-aa5f-c3dd65e0fc29',
} as const

export type TimeUnitValue = MapValues<typeof CATALOG_TIME_UNIT>

export const isTimeUnitValue = (id: string): id is TimeUnitValue =>
  Object.values(CATALOG_TIME_UNIT).map(String).includes(id)

export const PROCESS_STAGES = {
  APPEAL: '7a92c058-2678-11ee-b8d2-332984b1bbf0',
  DISCOVERY: '7a92209e-2678-11ee-b8d0-575bc8f19f38',
  LEAD_INTAKE_ONLY: '1076df34-2de6-44e7-b560-ca526bd91958',
  PENDING_TRIAL: '7a9297d6-2678-11ee-b8d1-bb2fc0a83bea',
  PRE_LITIGATION: '7a912892-2678-11ee-b8ce-7f19554e9061',
  SIGNED_CLIENT: '91d19085-8b8d-4640-92ab-4fe00c149502',
} as const

export const PROCESS_STAGES_BY_NAME: Record<string, string> = {
  Appeal: PROCESS_STAGES.APPEAL,
  Discovery: PROCESS_STAGES.DISCOVERY,
  'Lead Intake Only': PROCESS_STAGES.LEAD_INTAKE_ONLY,
  'Pending Trial': PROCESS_STAGES.PENDING_TRIAL,
  'Pre-Litigation': PROCESS_STAGES.PRE_LITIGATION,
  'Signed Client': PROCESS_STAGES.SIGNED_CLIENT,
} as const

export const FEE_AGREEMENT_STATUS = {
  FeeAgreementUploaded: '4081b03c-76b7-11ef-9fc9-276bb012bcbe',
  SignedAndWillNotBeUploaded: '40823d0e-76b7-11ef-9fc9-b33c506a205e',
  ReminderSent: '4082730a-76b7-11ef-9fc9-ab1e20767a6a',
  CreatedPriorToFeeAgreements: '2c1874ba-776a-11ef-bf25-c35305593762',
} as const

export type FeeAgreementStatusValue =
  (typeof FEE_AGREEMENT_STATUS)[keyof typeof FEE_AGREEMENT_STATUS]

import { LICENSE_STATUS } from '@/constants'
import {
  useGetAttorneysProfileByIdLazyQuery,
  useGetOrganizationsLazyQuery,
  useCatalogUsStateQuery,
  useCatalogPracticeAreaQuery,
} from '@/gql/appApi'
import { OperationVariables } from '@apollo/client'
import { uniq, without } from 'lodash'
import { useEffect, useState } from 'react'
import { useDebounce } from 'usehooks-ts'

interface UseMemberDirectoryProps {
  searchText?: string
}

export const defaultPageSize = 20

export const useMemberDirectory = ({
  searchText = '',
}: UseMemberDirectoryProps) => {
  const debouncedSearchText = useDebounce(searchText, 500)

  const [page, setPage] = useState(0)
  const [cardsPerPage, setCardsPerPage] = useState(defaultPageSize)

  const [firmNames, setFirmNames] = useState<string[]>([])

  const [selectedLawFirms, setSelectedLawFirms] = useState<string[]>([])
  const [selectedJurisdictions, setSelectedJurisdictions] = useState<string[]>(
    []
  )
  const [selectedPracticeAreas, setSelectedPracticeAreas] = useState<string[]>(
    []
  )

  const [firstName = '', lastName = ''] = debouncedSearchText
    .trim()
    .split(/\s+/)

  const jurisdictionsQuery = useCatalogUsStateQuery()
  const practiceAreasQuery = useCatalogPracticeAreaQuery({
    variables: {
      sort: ['name'],
    },
  })

  const [getAttorneys, attorneysQuery] = useGetAttorneysProfileByIdLazyQuery()
  const [getOrganizations, organizationsQuery] = useGetOrganizationsLazyQuery()

  useEffect(() => {
    const offset = page * cardsPerPage

    const filterAndOptions: OperationVariables[] = [
      {
        licenses: {
          bar_number: {
            _nempty: true,
          },
          status: {
            id: {
              _eq: LICENSE_STATUS.VALIDATED,
            },
          },
        },
      },
      {
        attorney_id: {
          user_id: {
            status: {
              _eq: 'active',
            },
          },
        },
      },
      {
        attorney_id: {
          user_id: {
            role: {
              name: {
                _neq: 'Administrator',
              },
            },
          },
        },
      },
    ]

    if (firstName ?? lastName) {
      filterAndOptions.push({
        _or: [
          {
            first_name: {
              _icontains: firstName || lastName,
            },
          },
          {
            last_name: {
              _icontains: lastName || firstName,
            },
          },
        ],
      })
    }

    if (selectedLawFirms.length > 0) {
      filterAndOptions.push({
        attorney_id: {
          user_id: {
            current_organization: {
              organization_id: {
                profiles: {
                  name: {
                    _in: selectedLawFirms,
                  },
                },
              },
            },
          },
        },
      })
    }

    if (selectedJurisdictions.length > 0) {
      filterAndOptions.push({
        licenses: {
          license_state: {
            name: {
              _in: selectedJurisdictions,
            },
          },
        },
      })
    }

    if (selectedPracticeAreas.length > 0) {
      filterAndOptions.push({
        practice_areas: {
          practice_area_id: {
            name: {
              _in: selectedPracticeAreas,
            },
          },
        },
      })
    }

    getAttorneys({
      variables: {
        filter: {
          _and: filterAndOptions,
        },
        licenseFilter: {
          status: {
            id: {
              _eq: LICENSE_STATUS.VALIDATED,
            },
          },
        },
        limit: cardsPerPage,
        offset,
        profileSort: 'last_name',
      },
    })
  }, [
    getAttorneys,
    page,
    cardsPerPage,
    firstName,
    lastName,
    selectedLawFirms,
    selectedJurisdictions,
    selectedPracticeAreas,
  ])

  useEffect(() => {
    ;(async () => {
      const { data: organizationsData } = await getOrganizations({
        variables: {
          filter: {
            profiles: {
              name: {
                _nempty: true,
              },
            },
          },
          // TODO: This will eventually not be enough. Rather than query for
          // all organization names, paginate the results.
          // @see https://attorney.atlassian.net/browse/MP-1191?focusedCommentId=10739
          limit: 1000,
        },
      })

      const organizationNameList: string[] =
        organizationsData?.organization?.reduce(
          (acc: string[], organization) => {
            const organizationNames =
              organization?.profiles?.map(profile => profile?.name ?? '') ?? []

            acc.push(...organizationNames)
            return acc
          },
          []
        ) ?? []

      const uniqueFirmNames = uniq(organizationNameList).sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
      )

      setFirmNames(uniqueFirmNames)
    })()
  }, [getOrganizations])

  // NOTE: Resets the page whenever filter settings are changed.
  useEffect(() => {
    setPage(0)
  }, [
    debouncedSearchText,
    cardsPerPage,
    firmNames,
    selectedLawFirms,
    selectedJurisdictions,
    selectedPracticeAreas,
  ])

  const handleLawFirmChange = (
    _e: React.SyntheticEvent<Element, Event>,
    newValue: string | null
  ) => {
    if (newValue === null) {
      return
    }

    setSelectedLawFirms(uniq([...selectedLawFirms, newValue]))
  }

  const handleJurisdictionsChange = (
    _e: React.SyntheticEvent<Element, Event>,
    newValue: string | null
  ) => {
    if (newValue === null) {
      return
    }

    setSelectedJurisdictions(uniq([...selectedJurisdictions, newValue]))
  }

  const handlePracticeAreaChange = (
    _e: React.SyntheticEvent<Element, Event>,
    newValue: string | null
  ) => {
    if (newValue === null) {
      return
    }

    setSelectedPracticeAreas(uniq([...selectedPracticeAreas, newValue]))
  }

  const handleLawFirmDelete = (firmName: string) => {
    setSelectedLawFirms(without(selectedLawFirms, firmName))
  }

  const handlePracticeAreaDelete = (practiceArea: string) => {
    setSelectedPracticeAreas(without(selectedPracticeAreas, practiceArea))
  }

  const handleJurisdictionDelete = (jurisdiction: string) => {
    setSelectedJurisdictions(without(selectedJurisdictions, jurisdiction))
  }

  const handleClearFiltersClick = () => {
    setSelectedLawFirms([])
    setSelectedJurisdictions([])
    setSelectedPracticeAreas([])
  }

  const handlePageChange = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCardsPerPage(Number(event.target.value))
  }

  const jurisdictionNames =
    jurisdictionsQuery.data?.catalog_us_state.map(({ name }) => name) ?? []

  const practiceAreaNames =
    practiceAreasQuery.data?.catalog_practice_area.map(({ name }) => name) ?? []

  const numFiltersSelected = [
    ...selectedLawFirms,
    ...selectedJurisdictions,
    ...selectedPracticeAreas,
  ].length

  const attorneysCount =
    attorneysQuery.data?.attorney_profile_aggregated?.[0]?.countDistinct
      ?.attorney_id ?? 0

  return {
    attorneysCount,
    cardsPerPage,
    firmNames,
    error:
      attorneysQuery.error ||
      organizationsQuery.error ||
      jurisdictionsQuery.error ||
      practiceAreasQuery.error,
    handleChangeRowsPerPage,
    handleClearFiltersClick,
    handleJurisdictionDelete,
    handleJurisdictionsChange,
    handleLawFirmChange,
    handleLawFirmDelete,
    handlePageChange,
    handlePracticeAreaChange,
    handlePracticeAreaDelete,
    jurisdictionNames,
    jurisdictions: jurisdictionsQuery,
    loading:
      attorneysQuery.loading ||
      organizationsQuery.loading ||
      jurisdictionsQuery.loading ||
      practiceAreasQuery.loading,
    members: attorneysQuery,
    numFiltersSelected,
    page,
    practiceAreaNames,
    practiceAreas: practiceAreasQuery,
    selectedJurisdictions,
    selectedLawFirms,
    selectedPracticeAreas,
  }
}

import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { FC, useContext, useRef, useState } from 'react'
import useTheme from '@mui/material/styles/useTheme'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MatchFeesModal, {
  SendProposalModalProps,
} from '@/components/Modals/MatchFeesModal'
import { useCaseMessageTrigger } from '@/components/GetStreamChat/useCaseMessageTrigger'
import { Link, generatePath } from 'react-router-dom'
import { RoutesData } from '@/router/RoutesData'
import { MyReferralsContext } from '../MyReferralsContext'
import { ReferralContext } from './ReferralContext'

const StyledLink = styled(Link)({})

export interface WatchedReferralActionsProps {
  caseErrorModalOpen: boolean
  caseMpPreferenceId: string | undefined
  caseTaken: boolean
  isSubmittingMatchFee: boolean
  stopWatching: (deleteAttorneyCaseMpPreferenceItemId: string) => Promise<void>
  matchFeesModalOpen: boolean
  matchFeesModalProps: SendProposalModalProps
  otherSideAttorneyId: string
}

export const WatchedReferralActions: FC<WatchedReferralActionsProps> = ({
  caseMpPreferenceId,
  caseTaken,
  stopWatching,
  matchFeesModalOpen,
  matchFeesModalProps,
  otherSideAttorneyId,
}) => {
  const theme = useTheme()
  const { isRefetching } = useContext(MyReferralsContext)
  const { caseEntry } = useContext(ReferralContext)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const menuTriggerRef = useRef<HTMLButtonElement>(null)
  const { handleTriggerClick } = useCaseMessageTrigger()

  const handleMenuTriggerClick = () => {
    setIsMenuOpen(true)
  }

  const handleMenuClose = () => {
    setIsMenuOpen(false)
  }

  const handleSendMessageClick = () => {
    setIsMenuOpen(false)
    handleTriggerClick({
      membersToMessage: [otherSideAttorneyId, ...(caseEntry.ownerships ?? [])],
    })
  }

  const handleStopWatchingClick = async () => {
    if (caseMpPreferenceId) {
      await stopWatching(caseMpPreferenceId)
    }

    setIsMenuOpen(false)
  }

  return (
    <Box
      alignItems="center"
      display="flex"
      flexWrap="nowrap"
      columnGap={1}
      width={1}
    >
      <Box
        display="flex"
        columnGap={1}
        flexDirection={{ md: 'row-reverse' }}
        flexGrow={1}
      >
        <StyledLink
          to={generatePath(RoutesData.RCase.path, {
            id: caseEntry.id,
          })}
          sx={{
            width: 1,
          }}
        >
          <Button
            variant="contained"
            sx={{
              alignSelf: 'stretch',
              flexGrow: 1,
              flexBasis: { xs: 0, md: 'auto' },
            }}
            disabled={caseTaken || isRefetching}
            fullWidth
          >
            Go to Case
          </Button>
        </StyledLink>
      </Box>
      <IconButton
        aria-label="more"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleMenuTriggerClick}
        ref={menuTriggerRef}
      >
        <MoreVertIcon
          sx={{
            fontSize: theme.spacing(2.5),
          }}
        />
      </IconButton>
      <Menu
        anchorEl={menuTriggerRef.current}
        open={isMenuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          role: 'listbox',
        }}
      >
        <MenuItem
          onClick={handleSendMessageClick}
          disabled={caseTaken || isRefetching}
        >
          <Typography>Send Message</Typography>
        </MenuItem>
        <MenuItem onClick={handleStopWatchingClick}>
          <Typography color={theme.palette.error.main}>
            Stop Watching
          </Typography>
        </MenuItem>
      </Menu>
      {matchFeesModalOpen && <MatchFeesModal {...matchFeesModalProps} />}
    </Box>
  )
}

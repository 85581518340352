import { BoxProps } from '@mui/material/Box'
import { Waterfalls } from './components'
import { OrganizationWaterfallsProvider } from './OrganizationWaterfallsContext'

interface IOrganizationWaterfallsProps {
  isFirmSettings?: boolean
  containerBoxProps?: Partial<BoxProps>
}

const OrganizationWaterfalls = ({
  isFirmSettings = false,
  containerBoxProps,
}: IOrganizationWaterfallsProps) => (
  <OrganizationWaterfallsProvider isFirmSettings={isFirmSettings}>
    <Waterfalls containerBoxProps={containerBoxProps} />
  </OrganizationWaterfallsProvider>
)

export default OrganizationWaterfalls

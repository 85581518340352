import { useState, useMemo, ChangeEvent } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Search, { SearchProps } from '@/components/Search'
import intersection from 'lodash/intersection'
import { CASE_PURCHASE_STATUS, CASE_STATUS_FLOW } from '@/constants/index'
import { useFeatureFlags } from '@/optimizely/useFeatureFlags'
import { FeatureFlag } from '@/optimizely/types'
import uniqWith from 'lodash/uniqWith'
import isEqual from 'lodash/isEqual'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'

import ModalFilter from './ModalFilter/ModalFilter'
import TabsContainer from './TabsContainer/TabsContainer'

import {
  AllReferralFilterItem,
  Attorney,
  CaseEntry,
  ALL_REFERRAL_FILTER_TYPES_CATALOG,
} from '../types'
import SelectedFilters from './SelectedFilters/SelectedFilters'

const applySelectedFilters = (
  cases: CaseEntry[],
  searchText: string,
  filters: AllReferralFilterItem[]
) => {
  let filteredResults = cases

  if (searchText !== '') {
    filteredResults = filteredResults.filter(item =>
      String(item?.name || '')
        .toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase())
    )
  }

  // filter By Referral type
  const referralTypeFiter = filters.find(
    item => item?.type === ALL_REFERRAL_FILTER_TYPES_CATALOG.REFERRAL_TYPE
  )
  if (referralTypeFiter) {
    filteredResults = filteredResults.filter(
      item => String(item?.mp_visibility) === referralTypeFiter.data
    )
  }

  // filter By Attorney
  const attorneysFilter = filters.filter(
    item => item?.type === ALL_REFERRAL_FILTER_TYPES_CATALOG.ATTORNEY
  )
  if (attorneysFilter.length > 0) {
    const attorneyFilterIds = attorneysFilter.map(item => item?.id)

    filteredResults = filteredResults.filter(
      item =>
        attorneyFilterIds.includes(item?.attorney_id) ||
        intersection(
          attorneyFilterIds,
          item?.case_purchase?.map(
            caseP => caseP?.prospect_attorney?.attorney_id
          )
        ).length > 0 ||
        intersection(
          attorneyFilterIds,
          item?.collaborators?.map(col => col?.attorney_id)
        ).length > 0 ||
        intersection(
          attorneyFilterIds,
          item?.prospect_collaborators?.map(pcol => pcol?.attorney_id)
        ).length > 0
    )
  }

  // filter By Case Manager
  const caseManagersFilter = filters.filter(
    item => item.type === ALL_REFERRAL_FILTER_TYPES_CATALOG.CASE_MANAGER
  )
  if (caseManagersFilter.length > 0) {
    const caseManagersFilterIds = caseManagersFilter.map(item => item.id)

    filteredResults = filteredResults.filter(
      item =>
        intersection(
          caseManagersFilterIds,
          item?.collaborators?.map(col => col?.attorney_id)
        ).length > 0 ||
        intersection(
          caseManagersFilterIds,
          item?.prospect_collaborators?.map(pcol => pcol?.attorney_id)
        ).length > 0
    )
  }

  return filteredResults
}

// Remove cases that have concluded and reached an Agreement
const removeResolvedCases = (cases: CaseEntry[]) =>
  cases.filter(item => (item?.case_purchase?.[0]?.payments?.length ?? 0) <= 1)

const AllReferrals = ({
  cases,
  watchedCases,
  userOrganization,
  refetch,
  isRefetching,
}: {
  cases: CaseEntry[]
  watchedCases: CaseEntry[]
  userOrganization: string
  refetch?: () => Promise<any>
  isRefetching?: boolean
}) => {
  const [searchText, setSearchText] = useState('')
  const [filters, setFilters] = useState<AllReferralFilterItem[]>([])

  const searchProps: SearchProps = {
    value: searchText,
    placeholder: 'Search for Case...',
    onChange: (event: ChangeEvent<HTMLInputElement>) =>
      setSearchText(event.target.value),
  }

  // tab cases
  const allCases = useMemo(() => {
    const activeCases = removeResolvedCases(cases)
    return applySelectedFilters(activeCases, searchText, filters)
  }, [cases, filters, searchText])

  const allCasesExceptSigned = useMemo(() => {
    const activeCases = cases.filter(
      item => item.status !== CASE_STATUS_FLOW.Signed
    )

    return applySelectedFilters(activeCases, searchText, filters)
  }, [cases, filters, searchText])

  const inbound = useMemo(() => {
    const tmpInbound = cases.filter(
      item => item.attorney.organization_id !== userOrganization
    )
    const activeCases = removeResolvedCases(tmpInbound)
    return applySelectedFilters(activeCases, searchText, filters)
  }, [cases, filters, searchText, userOrganization])

  const outbound = useMemo(() => {
    const tmpOutbound = cases.filter(
      item => item.attorney.organization_id === userOrganization
    )

    const activeCases = removeResolvedCases(tmpOutbound)
    return applySelectedFilters(activeCases, searchText, filters)
  }, [cases, filters, searchText, userOrganization])

  const watched = useMemo(() => {
    const tmpWatched = watchedCases.filter(item => item?.following === true)
    return applySelectedFilters(tmpWatched, searchText, filters)
  }, [watchedCases, filters, searchText])

  const legacySigned = useMemo(() => {
    const tmpSigned = cases.filter(item =>
      item.case_purchase
        .map(casePurchase => casePurchase.status)
        .includes(CASE_PURCHASE_STATUS.Accept)
    )

    return applySelectedFilters(tmpSigned, searchText, filters)
  }, [cases, filters, searchText])

  const signed = useMemo(() => {
    const activeCases = cases.filter(
      item => item.status === CASE_STATUS_FLOW.Signed
    )

    return applySelectedFilters(activeCases, searchText, filters)
  }, [cases, filters, searchText])

  const matched = useMemo(() => {
    const matchedCases = cases.filter(
      item => item.status === CASE_STATUS_FLOW.Matched
    )

    return applySelectedFilters(matchedCases, searchText, filters)
  }, [cases, filters, searchText])

  const featureFlags = useFeatureFlags()
  const isCaseStatusFlowSimplificationEnabled =
    featureFlags[FeatureFlag.CASE_STATUS_FLOW_SIMPLIFICATION]

  const tabsData = useMemo(() => {
    let id = 0

    return isCaseStatusFlowSimplificationEnabled
      ? [
          {
            id: id++,
            title: 'All Cases',
            items: allCasesExceptSigned,
          },
          {
            id: id++,
            title: 'Watching',
            items: watched,
          },
          {
            id: id++,
            title: 'Matched',
            items: matched,
          },
          {
            id: id++,
            title: 'Signed',
            items: signed,
          },
        ]
      : [
          {
            id: id++,
            title: 'All Cases',
            items: allCases,
          },
          {
            id: id++,
            title: 'Inbound',
            items: inbound,
          },
          {
            id: id++,
            title: 'Outbound',
            items: outbound,
          },
          {
            id: id++,
            title: 'Watching',
            items: watched,
          },
          {
            id: id++,
            title: 'Signed',
            items: legacySigned,
          },
        ]
  }, [
    isCaseStatusFlowSimplificationEnabled,
    allCasesExceptSigned,
    watched,
    matched,
    signed,
    allCases,
    inbound,
    outbound,
    legacySigned,
  ])

  const allUsersInCases: Attorney[] = useMemo(() => {
    const allUsersTmp = flatten([
      cases?.map(item => item?.attorney),
      flatten(cases?.map(item => item?.collaborators)),
      flatten(cases?.map(item => item?.prospect_collaborators)),
    ])

    return compact(uniqWith(allUsersTmp, (a, b) => isEqual(a, b)))
  }, [cases])

  return (
    <Box padding={2} paddingTop={4}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={4}>
          <Typography
            variant="h5"
            sx={{ mb: 1, textAlign: 'left' }}
            id="createNewCaseTitle"
          >
            All Referrals
            <Typography variant="caption" color="text.disabled" sx={{ ml: 1 }}>
              {cases.length} cases
            </Typography>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: {
              xs: 'space-between',
              md: 'flex-end',
            },
          }}
        >
          <div>
            <Search {...searchProps} />
          </div>
          <ModalFilter
            currentFilters={filters}
            setFilters={setFilters}
            apiUsers={allUsersInCases}
          />
        </Grid>
      </Grid>
      <Grid container>
        <SelectedFilters currentFilters={filters} setFilters={setFilters} />
      </Grid>
      <TabsContainer
        tabsData={tabsData}
        refetch={refetch}
        isRefetching={isRefetching}
      />
    </Box>
  )
}

export default AllReferrals

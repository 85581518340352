import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { useSnackBar } from '@/hooks/useSnackBar'
import JSnackBar from '@/components/atoms/JSnackBar'

import { INTEGRATION_LINKS } from '@/pages/AccountSettings/Integrations/constants'
import { SetupResponse } from '@/utils/api/integrations/types'
import { TError } from '@/utils/api/types'
import { LeadDocketContentProps, LeadDocketInputIdValues } from '../../../types'
import useLeadDocketIntegrationItem from '../useLeadDocketIntegrationItem'
import { isSetupSuccessful } from '../../shared/utils'

const LeadDocketContent = ({
  refetchLcmsData,
  name,
  organizationId,
  updateLeadDocketConnection,
  connectingToLcms,
}: LeadDocketContentProps) => {
  const theme = useTheme()
  const lcms = name.replace(/\s/g, '').toLowerCase()
  const [connectSetup, setConnectSetup] = useState(false)
  const [urlInput, setUrlInput] = useState<string>('')
  const [apiKeyInfo, setApiKeyInfo] = useState<string>('')
  const [endpointUrlInput, setEndpointUrlInput] = useState<string>('')
  const [setupStatus, setSetupStatus] = useState<boolean>(false)

  const { alert, showAlert, handleClose } = useSnackBar()

  const {
    connectIntegrationData,
    isConnectIntegrationLoading,
  }: {
    connectIntegrationData: SetupResponse | TError | null
    isConnectIntegrationLoading: boolean
  } = useLeadDocketIntegrationItem({
    apikeyInfo: apiKeyInfo,
    connectSetup,
    endpointUrlInput,
    name: lcms,
    organizationId,
    urlInput,
  })

  const connectToLeadDocket = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!URL.canParse(endpointUrlInput)) {
      showAlert({
        severity: 'error',
        message: 'The Integration endpoint URL is not valid',
      })
      setConnectSetup(false)
      event.preventDefault()
    } else if (!URL.canParse(urlInput)) {
      showAlert({
        severity: 'error',
        message: 'The Lead Docket account URL is not valid',
      })
      setConnectSetup(false)
      event.preventDefault()
    } else {
      connectingToLcms(true)
      setConnectSetup(true)
    }
  }

  const handleTextFieldValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    inputId: LeadDocketInputIdValues
  ) => {
    const fieldValue = event.target.value

    const inputUpdateFunctions = {
      'url-input': setUrlInput,
      'api-key-input': setApiKeyInfo,
      'endpoint-url-input': setEndpointUrlInput,
    }

    const updateFunction = inputUpdateFunctions[inputId]

    if (updateFunction) {
      updateFunction(fieldValue)
    }
  }

  const disableAction =
    !setupStatus &&
    (!urlInput.trim() || !apiKeyInfo.trim() || !endpointUrlInput.trim())

  const renderTextField = (id: LeadDocketInputIdValues, label: string) => (
    <TextField
      id={id}
      label={label}
      variant="outlined"
      sx={{
        width: {
          md: '475px',
          xs: '100%',
        },
        marginTop: theme.spacing(3),
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        handleTextFieldValue(event, id)
      }
    />
  )

  useEffect(() => {
    if (isSetupSuccessful(connectIntegrationData) && connectSetup) {
      setSetupStatus(true)
      updateLeadDocketConnection(true)
      refetchLcmsData()
      connectingToLcms(false)
      setConnectSetup(false)
    }
    if (connectIntegrationData?.status === 'failure') {
      setSetupStatus(false)
      connectingToLcms(false)
      setConnectSetup(false)
      updateLeadDocketConnection(false)
    }
  }, [
    connectSetup,
    connectIntegrationData,
    updateLeadDocketConnection,
    refetchLcmsData,
    connectingToLcms,
  ])

  useEffect(() => {
    if (
      connectIntegrationData?.statusCode === 404 ||
      connectIntegrationData?.statusCode === 500
    ) {
      showAlert({
        severity: 'error',
        message: (
          <>
            <strong>
              We were unable to connect to your Lead Docket account
            </strong>
            <br />
            Please try again or contact us through our Help Center.
          </>
        ),
      })
    }
  }, [connectIntegrationData, showAlert])

  return (
    <Grid
      item
      md={8}
      xs={12}
      sx={{
        margin: {
          xs: theme.spacing(2, 0, 0),
          md: theme.spacing(4, 0, 2, 8),
        },
      }}
    >
      <Box
        sx={{
          marginBottom: theme.spacing(4),
          width: {
            xs: '100%',
          },
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
          Create and configure the Attorney Share integration in Lead Docket
        </Typography>
        <Typography variant="body2">
          Please follow{' '}
          <Link
            href={INTEGRATION_LINKS.LEAD_DOCKET_INSTRUCTIONS_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            these instructions
          </Link>{' '}
          to complete the necessary steps in Lead Docket to enable the
          integration with Attorney Share.
        </Typography>
      </Box>
      <Box
        sx={{
          marginBottom: theme.spacing(3),
          width: {
            xs: '100%',
          },
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
          Enter your law firm&apos;s Lead Docket account URL
        </Typography>
        <Typography variant="body2">
          For example{': '}
          <strong>https://mylawfirm.leaddocket.com</strong>
        </Typography>
        {renderTextField('url-input', 'Lead Docket account URL')}
      </Box>
      <Box
        sx={{
          marginBottom: theme.spacing(3),
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
          Enter your Lead Docket API key
        </Typography>
        <Typography variant="body2">
          From the Lead Docket left menu, go to <strong>Manage</strong>, and
          select <strong>Settings</strong>. The API key is located at the bottom
          of <strong>Account Settings</strong> section.
        </Typography>
        {renderTextField('api-key-input', 'Lead Docket API key')}
      </Box>
      <Box
        sx={{
          marginBottom: theme.spacing(3),
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
          Enter the Web Form integration Endpoint URL
        </Typography>
        <Typography variant="body2" gutterBottom>
          As part of configuring the Attorney Share integration in Lead Docket,
          you created a new Web Form integration. Copy the Endpoint URL of the
          new Web Form integration and enter it here. the Endpoint URL will look
          like:{' '}
          <strong>
            https://mylawfirm.leaddocket.com/Opportunities/FormJson/5
          </strong>
        </Typography>
        {renderTextField('endpoint-url-input', 'Integration Endpoint URL')}
      </Box>
      <Button
        variant={isConnectIntegrationLoading ? 'text' : 'contained'}
        size="large"
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          connectToLeadDocket(event)
        }
        disabled={isConnectIntegrationLoading || disableAction}
      >
        {isConnectIntegrationLoading ? 'Loading' : 'Connect'}
      </Button>
      <JSnackBar
        vertical="bottom"
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={handleClose}
        autoHideDuration={5000}
      />
    </Grid>
  )
}

export default LeadDocketContent

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useContext } from 'react'
import useTheme from '@mui/material/styles/useTheme'
import { ReferralContext } from './ReferralContext'

interface JurisdictionAndPracticeAreaProps {
  /**
   * Whether to present the jurisdiction and practice area in a condensed manner.
   */
  condense?: boolean
}

export const JurisdictionAndPracticeArea = ({
  condense,
}: JurisdictionAndPracticeAreaProps) => {
  const theme = useTheme()

  const {
    caseEntry: { jurisdiction_id: jurisdictionId, type },
    statesMap,
  } = useContext(ReferralContext)

  const { code = '', name = '' } = statesMap[jurisdictionId] ?? {}

  if (condense) {
    return (
      <Typography
        variant="caption"
        color={theme.palette.text.disabled}
        sx={{
          textOverflow: 'ellipsis',
          textWrap: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {name}, {type}
      </Typography>
    )
  }

  return (
    <Box display="flex" justifyContent="space-between" width={1}>
      <Typography
        variant="body2"
        sx={{
          textOverflow: 'ellipsis',
          textWrap: 'nowrap',
          maxWidth: '250px',
          overflow: 'hidden',
        }}
      >
        {type}
      </Typography>
      <Typography variant="body2" mr={{ lg: 4 }}>
        {code}
      </Typography>
    </Box>
  )
}

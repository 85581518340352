import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { WithdrawProposalButton } from '@/components/WithdrawProposalButton/WithdrawProposalButton'
import { Attorney, CasePurchase } from '@/pages/MyReferralsV2/types'
import { useContext, useRef, useState } from 'react'
import { useCaseMessageTrigger } from '@/components/GetStreamChat/useCaseMessageTrigger'
import useTheme from '@mui/material/styles/useTheme'
import { MyReferralsContext } from '../MyReferralsContext'
import { ReferralContext } from './ReferralContext'

interface AwaitingOfferResponseActionsProps {
  casePurchase: CasePurchase
  counterpart: Attorney
  enableWithdraw?: boolean
}

export const AwaitingOfferResponseActions = ({
  casePurchase,
  counterpart,
  enableWithdraw = true,
}: AwaitingOfferResponseActionsProps) => {
  const theme = useTheme()
  const { refetch, isRefetching } = useContext(MyReferralsContext)
  const { caseEntry } = useContext(ReferralContext)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const menuTriggerRef = useRef<HTMLButtonElement>(null)
  const { handleTriggerClick } = useCaseMessageTrigger()

  const handleMenuTriggerClick = () => {
    setIsMenuOpen(true)
  }

  const handleMenuClose = () => {
    setIsMenuOpen(false)
  }

  const handleSendMessageClick = () => {
    setIsMenuOpen(false)
    handleTriggerClick({
      membersToMessage: [counterpart.user_id, ...(caseEntry.ownerships ?? [])],
    })
  }

  const handleWithdrawSuccess = () => {
    refetch()
  }

  return (
    <Box display="flex" alignItems="center">
      {enableWithdraw && (
        <WithdrawProposalButton
          casePurchaseId={casePurchase.id}
          canProposalBeWithdrawn
          buttonProps={{
            variant: 'text',
          }}
          onWithdrawSuccess={handleWithdrawSuccess}
        />
      )}
      <IconButton
        aria-label="more"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleMenuTriggerClick}
        ref={menuTriggerRef}
      >
        <MoreVertIcon
          sx={{
            fontSize: theme.spacing(2.5),
          }}
        />
      </IconButton>
      <Menu
        anchorEl={menuTriggerRef.current}
        open={isMenuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          role: 'listbox',
        }}
      >
        <MenuItem onClick={handleSendMessageClick} disabled={isRefetching}>
          <Typography>Send Message</Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}

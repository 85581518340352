import { alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { PhoneNumber } from '@/components/PhoneNumber/PhoneNumber'
import { IJLeadsProps } from './types'

const JLeads = ({ firstName, lastName, email, phone }: IJLeadsProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: { xs: 'column', md: 'row' },
    }}
  >
    <Typography
      variant="subtitle1"
      sx={{
        mr: 2.5,
      }}
    >
      {firstName}
      {lastName ? ` ${lastName}` : ''}
    </Typography>
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      {email && (
        <Typography
          variant="body2"
          sx={{
            color: theme => alpha(theme.palette.common.black, 0.6),
            mr: 1.5,
          }}
        >
          {email}
        </Typography>
      )}
      {phone && (
        <Typography
          variant="body2"
          sx={{
            color: theme => alpha(theme.palette.common.black, 0.6),
          }}
        >
          <PhoneNumber>{phone}</PhoneNumber>
        </Typography>
      )}
    </Box>
  </Box>
)

export default JLeads

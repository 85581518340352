import { useState } from 'react'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import EmptyPage from '@/components/EmptyPage/EmptyPage'
import useNeedsYourAttention from './hooks/useNeedsYourAttention'
import { CaseEntry } from '../types'
import ReferralItems from '../components/ReferralItems'

interface INeedsYourAttentionProps {
  cases: CaseEntry[]
  refetch?: () => Promise<any>
  isRefetching?: boolean
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  background: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingBottom: '1rem',

  '&::before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  alignItems: 'baseline',
  border: 'none',

  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(270deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    display: 'block',
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const NeedsYourAttention = ({
  cases,
  refetch,
  isRefetching,
}: INeedsYourAttentionProps) => {
  const [isOpen, setIsOpen] = useState(cases.length > 0)
  const { filteredData } = useNeedsYourAttention({
    data: cases,
    searchText: '',
  })

  return (
    <Box>
      <Accordion expanded={isOpen}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          onClick={() => setIsOpen(!isOpen)}
          sx={{
            padding: {
              xs: '0',
              md: 'auto',
            },
          }}
        >
          <Typography variant="h5">
            Needs your attention
            <Typography variant="caption" color="text.disabled" sx={{ ml: 1 }}>
              {cases.length} items
            </Typography>
          </Typography>
          <Typography variant="body2" color="text.disabled" sx={{ mt: 1 }}>
            The following items require action on your behalf
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ borderTop: 'none' }}>
          {filteredData.length > 0 ? (
            <ReferralItems
              data={filteredData}
              refetch={refetch}
              isRefetching={isRefetching}
              needsYourAttention
            />
          ) : (
            <EmptyPage
              sx={{
                mb: {
                  xs: 5,
                  md: 12,
                },
              }}
              message="You don’t have any cases that need your attention."
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default NeedsYourAttention

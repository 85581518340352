import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import useTheme from '@mui/material/styles/useTheme'
import { useSizes } from '@/hooks/useSizes'
import { Link, generatePath } from 'react-router-dom'
import { RoutesData } from '@/router/RoutesData'
import { useContext } from 'react'
import { ReferralContext } from './ReferralContext'
import { ReferralAttorneyAvatar } from './ReferralAttorneyAvatar'
import { isFollowedAndAvailable } from './utils'

export interface ActionHeaderProps {
  Action: React.ReactNode
  fee: number | undefined
  showCounterpart: boolean
}

export const ActionHeader = ({
  Action,
  fee,
  showCounterpart,
}: ActionHeaderProps) => {
  const theme = useTheme()
  const { isLessMd } = useSizes()
  const { caseEntry, counterpart, postedAtDateString, postedAtDateTimeString } =
    useContext(ReferralContext)

  const showAsFollowing = isFollowedAndAvailable(caseEntry)

  return (
    <>
      <Grid
        item
        xs={12}
        lg={4}
        component={Link}
        to={generatePath(RoutesData.RCase.path, {
          id: caseEntry.id,
        })}
      >
        <Typography variant="subtitle1" mb={-0.5}>
          {caseEntry.name}
        </Typography>
        <Typography variant="caption" color={theme.palette.text.secondary}>
          {caseEntry.jurisdiction}, {caseEntry.type}
        </Typography>
      </Grid>
      <Grid container item xs={12} lg={8} spacing={2}>
        <Grid
          item
          xs={12}
          md={showAsFollowing ? 8 : 6}
          display="flex"
          alignItems="center"
          gap={2}
        >
          {showCounterpart && counterpart && (
            <Box display="flex" alignItems="center">
              <ReferralAttorneyAvatar
                attorney={counterpart}
                showOrganization={isLessMd}
                fitToSingleLine
              />
            </Box>
          )}
          <Box
            ml="auto"
            display="flex"
            alignItems="end"
            gap={{
              md: 1,
            }}
            flexDirection={{
              xs: 'column',
              md: 'row',
            }}
          >
            {typeof fee === 'number' && (
              <Typography variant="subtitle1">{fee}%</Typography>
            )}
            <Typography
              variant="overline"
              color={theme.palette.text.disabled}
              lineHeight={{
                md: theme.typography.subtitle1.lineHeight,
              }}
              textTransform="none"
              letterSpacing="normal"
              noWrap
            >
              {isLessMd ? postedAtDateString : postedAtDateTimeString}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={showAsFollowing ? 4 : 6}
          display="flex"
          alignItems="center"
          justifyContent="end"
        >
          {Action}
        </Grid>
      </Grid>
    </>
  )
}

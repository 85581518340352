import { Attorney_Profile } from '@/gql/appApi'
import useGetMyRoleAndOrg from '@/hooks/useGetMyRoleAndOrg'
import { useState } from 'react'

const useInviteMembers = () => {
  const {
    isAdmin,
    isAttorney,
    isCaseManager,
    loading: userMeLoading,
  } = useGetMyRoleAndOrg()
  const [attorneySelected, setAttorneySelected] =
    useState<Attorney_Profile | null>(null)
  const [caseManagersSelected, setCaseManagersSelected] = useState<
    Attorney_Profile[]
  >([])

  return {
    attorneySelected,
    setAttorneySelected,
    caseManagersSelected,
    setCaseManagersSelected,
    loading: userMeLoading,
    isAdmin,
    isAttorney,
    isCaseManager,
  }
}

export default useInviteMembers
